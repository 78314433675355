class EditFiberHouseReq{
    constructor(){
        this.id = null;
        this.notes = null;
        this.mapIconId = null;
        this.status = null;
        this.salesOrgId = null;
        this.servicableAreaId = null;
        this.country = null;
        this.state = null;
        this.zipCode = null;
        this.city = null;
        this.address = null;
        this.mapString = null;
        this.salesRepId = null;
        this.coordinate = {
          latitude: null,
          longitude: null,
          popUpColor: null
        };
    }
}
export default EditFiberHouseReq;