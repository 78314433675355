/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import greyUser from './../../../../assets/images/userGrey.png'
import { Rating } from '@mui/material'
import './Review.scss'
import Modal from '../../../../components/Modal'
import { IoIosCloseCircleOutline } from "react-icons/io";
const VideoReview = ({ review }) => {
    // console.log(review)
    const [open, setOpen] = useState(false)
    const videoRef = useRef(null)

    useEffect(() => {
        // play the video for 0.1 seconds to get the thumbnail
        if (videoRef.current) {
            videoRef.current.load()
        }
    },[videoRef.current, review?.video?.url])

    return (
        <>
            <Modal setOpen={setOpen} open={open}>
                <div className='expanded_image'>
                    <button onClick={() => setOpen(false)}><IoIosCloseCircleOutline color='red' size={30} /></button>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <video ref={videoRef}  controls>
                            <source src={review?.video?.url+"#t=0.001"} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </Modal>
            <div className='review_card'>
                {/* Text  */}

                <div className='review_text'>
                    <div className='header'>
                        <div className='avatar'>
                            <img alt='avatar' src={review?.fidiumCustomer?.profileImage ? review.fidiumCustomer.profileImage : greyUser} />
                        </div>
                        <div className='info'>
                            <h3>{review?.fidiumCustomer?.firstName + " " + review?.fidiumCustomer?.lastName}</h3>
                        </div>
                        <p>
                            {review?.fidiumCustomer?.address?.length > 32
                                ? review?.fidiumCustomer?.address.substring(0, 32) + "..."
                                : review?.fidiumCustomer?.address}
                        </p>
                    </div>
                    <div className='rating'>
                        <Rating name="read-only" size='small' value={review.rating} precision={0.5} readOnly />
                    </div>
                    {/* <div className='company_image'>
                    <img src='/images/NetworkTeam/fidium.png' alt='pic' />
                </div> */}
                </div>
                {/* Pic */}
                <div onClick={() => {
                    setOpen(true)
                }} className='review_image'>
                    <video controls >
                        <source src={review?.video?.url+"#t=0.001"} type="video/mp4" />
                    </video>
                </div>
            </div>
        </>
    )
}

export default VideoReview