class SearchAffiliateCustomerStatusesReq {
    constructor() {
        this.code = null;
        this.status = null;
        this.value = null;
        this.affiliatedUserId = null;
        this.affiliatedByUserId = null;
        this.affiliateCodeId = null;
        this.createdAtFrom = null;
        this.createdAtTo = null;
        this.installedAt = null;
        this.requestedByCustomerAt = null;
        this.approvedByAdminAt = null;
        this.rejectedByAdminAt = null;
        this.pagingParams = {
            pageNumber: 1,
            pageSize: 100
        };
    }
}
export default SearchAffiliateCustomerStatusesReq;