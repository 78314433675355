import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import UsersApi from '../../../API/UsersApi';
import { SearchFiltersContext, UserContext } from '../../../App';
import Layout from '../../../Layouts/Layout'; // Import Layout component
import { CircularProgress } from '@mui/material';
import UserSelector from '../../../components/UserSelector';
import "./index.scss"

import OrganizationSelector from '../../../components/OrganizationSelector'
import FiberCompanySelector from '../../../components/FiberCompanySelector';
import { set } from 'ol/transform';
import { useNavigate } from 'react-router-dom';

// Set up moment localizer
const localizer = momentLocalizer(moment);

// Functional component with Layout and Calendar
const Index = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const LoggedInUser = useContext(UserContext)
    const [orgIds, setOrgIds] = useState(null);
    const [repIds, setRepIds] = useState(null);
    const [filterBy, setFilterBy] = useState('All');
    const [toggleFilter, setToggleFilter] = useState("Team");
    const searchFilters = useContext(SearchFiltersContext) 

    const [userWithName, setUserWithName] = useState(null);
    const [orgWithName, setOrgWithName] = useState(null);

    const [fiberCompanies, setFiberCompanies] = useState([]);
    const [fiberCompanyWithName, setFiberCompanyWithName] = useState(null);
    const navigate = useNavigate();

    const fetchAppointments = async () => {
        setLoading(true);
        // Replace with the actual request object if needed
        let req = {
            userId: null,
            salesOrgId: null,
            salesOrgIds: null,
            userIds: null
        };

        if (LoggedInUser.userType === "SalesRep") {
            req.userId = LoggedInUser.id;
            req.salesOrgId = LoggedInUser.salesOrgId;
        }
        else if (LoggedInUser.userType === "SalesOrgAdmin") {
            req.salesOrgId = LoggedInUser.salesOrgId;
            if (repIds && repIds.length > 0) {
                req.userIds = repIds;
            }
        }

        if (LoggedInUser.userType === "SuperAdmin") {
            if (orgIds && orgIds.length > 0) {
                req.salesOrgIds = orgIds;
            }
            if (repIds && repIds.length > 0) {
                req.userIds = repIds;
            }
            if(fiberCompanies && fiberCompanies.length > 0) {
                req.fiberCompanyIds = fiberCompanies;
            }
        }


        if(toggleFilter === 'Me') {
            if(LoggedInUser.userType === "SuperAdmin"){
                req.userIds = [LoggedInUser.id];
                req.salesOrgIds = null;
                setRepIds(null);
                setUserWithName(null);
                setOrgIds(null);
                setOrgWithName(null);
                setFiberCompanies(null);
                setFiberCompanyWithName(null);
            }
            else if(LoggedInUser.userType === "SalesOrgAdmin"){
                req.userIds = [LoggedInUser.id];
                req.salesOrgId = LoggedInUser.salesOrgId;
                setRepIds(null);
                setUserWithName(null);
            }
        }


        req.filterBy = filterBy;



        const response = await UsersApi.SearchCalenderAppointments(req);

        if (response) {
            console.log(response.data);

            let ets = response.data.map((event) => {
                return {
                    title: event.title,
                    start: new Date(event.start),
                    end: new Date(event.end),
                    allDay: event.allDay,
                    cName: event.cName
                };
            });
            setEvents(ets); // Assuming the API response contains a list of events
        } else {
            setError('Failed to load events');
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchAppointments();
    }, [orgIds, repIds, filterBy,toggleFilter, fiberCompanies]); // Empty dependency array ensures this runs only once on component mount


    const handleEventClick = (event) => {
        if (LoggedInUser.userType === "SuperAdmin" || LoggedInUser.userType === "SalesOrgAdmin") {
            console.log(event);
            var customerName = event.cName;
            searchFilters.current = {
                ...searchFilters.current,
                contactCenter : {
                    customerName: event.cName
                }
            }
            navigate('/contact-center');
        }
    };

    // const CustomTimeSlotWrapper = ({ value, children }) => {
    //     const hour = value.getHours();

    //     // Hide timeslots before 9 AM or after 5 PM by not rendering them
    //     if (hour < 9 || hour >= 17) {
    //       return null;
    //     }

    //     return <div>{children}</div>;
    //   };
    return (
        <Layout>
            {

                <div className='main_calender_container'>
                    <div className='filters'>
                        <h1>My Calendar</h1>
                        {
                            (LoggedInUser.userType === "SuperAdmin" || LoggedInUser.userType === "SalesOrgAdmin")  &&
                            <div className="toggle-switch">
                                <label>{"Viewing " + toggleFilter}</label>
                                <input
                                    type="checkbox"
                                    value={toggleFilter}
                                    checked={toggleFilter === 'Team'}
                                    onChange={() => setToggleFilter(toggleFilter === 'Me' ? 'Team' : 'Me')}
                                />
                            </div>
                        }
                        {
                            LoggedInUser.userType === "SuperAdmin" &&
                            <FiberCompanySelector setCompanyObject={setFiberCompanyWithName} companiesWithOfferTemplateOnly={true} placeholder='Select Fiber Company' setUser={setFiberCompanies} multiple={true} />
                        }
                        {
                            LoggedInUser.userType === "SuperAdmin" && toggleFilter === 'Team' &&
                            <OrganizationSelector placeholder='Select Organizations' setUser={setOrgIds} multiple={true} />
                        }
                        {
                            (LoggedInUser.userType === "SuperAdmin" || LoggedInUser.userType === "SalesOrgAdmin") && toggleFilter === 'Team' &&
                            <UserSelector userWithName={userWithName} setUserWithName={setUserWithName} salesOrgIds={orgIds} SalesOrgId={LoggedInUser.userType === "SalesOrgAdmin" ? LoggedInUser.salesOrgId : null} placeholder="Select Sales Reps" conversationType={'Group'} setUser={setRepIds} multiple={true} />
                        }
                        <div className='selector_con'>
                            <label>Filter By</label>
                            <select
                                value={filterBy}
                                onChange={(e) => setFilterBy(e.target.value)}
                            >
                                <option value="All">All</option>
                                <option value="Appointments">Appointment</option>
                                <option value="Installation">Installations</option>
                            </select>
                        </div>

                    </div>
                    <div className='calender_container' style={{ padding: '20px', maxWidth: "97vw", overflowY: "auto" }}>
                        {loading ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CircularProgress sx={{ color: "var(--primary-color)" }} size={60} />
                            </div>
                            :
                            <Calendar
                                localizer={localizer}
                                events={events}
                                dayLayoutAlgorithm={'overlap'}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: '100%' }}
                                defaultView='month'
                                views={['month', 'day', 'agenda']}
                                min={new Date(0, 0, 0, 6, 0, 0)}
                                max={new Date(0, 0, 0, 22, 0, 0)}
                                onSelectEvent={handleEventClick}
                            // components={{
                            //     timeSlotWrapper: CustomTimeSlotWrapper, // Custom wrapper to hide certain timeslots
                            //   }}
                            />}
                    </div>
                </div>
            }
        </Layout>
    );
};

export default Index;
