class CreateUserReq {
    constructor() {
        this.email = null;
        this.password = null;
        this.firstName = null;
        this.lastName = null;
        this.phone = null;
        this.address = null;
        this.userType = null;
        this.salesOrgId = null;
    }
}
export default CreateUserReq;