import React, { useState } from 'react'
import SoftInput from '../../../components/SoftInput'
import { fabric } from 'fabric'
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'

import "./index.scss"
import SoftButton from '../../../components/SoftButton'
import Sidebar from '../../../Layouts/Sidebar'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
const Index = () => {

    const { editor, onReady } = useFabricJSEditor()
    const [selectedImage, setSelectedImage] = useState(null);
    const onAddRectangle = (file) => {
        const reader = new FileReader();
        reader.onload = (f) => {
            var data = f.target.result;
            fabric.Image.fromURL(data, (img) => {
                img.scale(0.2);
                editor?.canvas.add(img);
            });
        };
        reader.readAsDataURL(file);
    }

    editor?.canvas.on("selection:created", (options) => {
        setSelectedImage(options.selected[0]);

    })

    editor?.canvas.on("selection:cleared", (options) => {
        setSelectedImage(null);
    })

    const handleFileClick = (event) => {
        event.target.value = '';
    };
    function removeSelectedImage() {
        if (selectedImage) {
            editor.canvas.remove(selectedImage);
            editor.canvas.renderAll();
        }
    }

    return (
        <Layout>
            <div style={{
                borderRadius: "36px",
                border: "1px solid #E5E5E5",
                background: "#FFF",
                padding: "2rem",
            }}>
                <div style={{
                    backgroundImage: `url("/images/Merch/shirt.jpeg")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "min(100%,400px)",
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                }}>

                    <FabricJSCanvas style={{
                        overflow: "hidden",
                        width: "150px"
                    }} className="sample-canvas" onReady={onReady} />

                </div>
                <div style={{
                    width:"fit-content",
                    display:"flex",
                    gap:"1rem"
                }}>
                    <SoftInput placeholder="Select an Image" onClick={handleFileClick} onChange={(e) => onAddRectangle(e.target.files[0])} type="file" />
                    <SoftButton style={{
                        backgroundColor: selectedImage ? "red" : "grey",
                        color: "white"
                    }} disabled={!selectedImage} onClick={() => { removeSelectedImage() }} >Remove</SoftButton>
                </div>
            </div>
        </Layout>
    )
}

export default Index