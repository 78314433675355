import React, { useEffect, useRef, useState } from 'react'
import { useTabs } from './TabContext'
import VersatileLoader from '../../../components/VersatileLoader';
import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import MasterSheetApi from '../../../API/MasterSheetApi';
import AccountStatusChangeForm from './AccountStatusChangeForm';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { MultiColorSelector } from '../../../components/MultiSelector';
const FileContent = () => {
    const { getFileData, getListId, activeTabId, setFileData } = useTabs();
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const intervalId = useRef(null)
    const [changeAccountStatusModal, setChangeAccountStatusModal] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCustomers, setTotalCustomers] = useState(0)
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const fetchData = async (first,page) => {
        setLoading(true)
        const id = await getListId(activeTabId)
        const data = await getFileData(id,page,first)
        setData(data.list);
        setTotalCustomers(data.totalItems)
        setTotalPages(data.totalPages)
        setCurrentPage(data.pageNumber)
        setLoading(false)
    }

    useEffect(
        () => {
            fetchData(true)
        }, [activeTabId]
    )

    const sortedData = React.useMemo(() => {
        if (!sortColumn) return data;

        return [...data].sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];

            if (aValue === null && bValue === null) return 0;
            if (aValue === null) return sortDirection === 'asc' ? -1 : 1;
            if (bValue === null) return sortDirection === 'asc' ? 1 : -1;

            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });
    }, [data, sortColumn, sortDirection]);

    const headers = [
        {
            name: "#",
            key: "index"
        },
        {
            name: "Fiber Company",
            key: "fiberCompanyName"
        },
        {
            name: "Sales Org Name",
            key: "salesOrgName"
        },
        {
            name: "Sales Rep",
            key: "salesRepName"
        },
        {
            name: "Lead Type",
            key: "leadType"
        },
        {
            name: "Map Icon",
            key: "mapIconName"
        },
        {
            name: "Lightning Notes",
            key: "notes"
        },
        {
            name: "Account Status",
            key: "accountStatus"
        },
        {
            name: "Sold Timestamp",
            key: "timeStamp"
        },
        {
            name: "Contact Name",
            key: "name"
        },
        {
            name: "Deal Saved",
            key: "dealSaved"
        },
        {
            name: "Issue Type",
            key: "issueType"
        },
        {
            name: "Address",
            key: "address"
        },
        {
            name: "Original Install Date",
            key: "originalInstallDate"
        },
        {
            name: "Updated Intall Date",
            key: "updatedInstallDate"
        },
        {
            name: "Action",
            key: "action"
        },
        {
            name: "Action 2",
            key: "action2"
        },
        {
            name: "Results",
            key: "results"
        },
        {
            name: "Notes",
            key: "accountNotes"
        },
        {
            name: "Account Issue",
            key: "issueType"
        },
        {
            name: "Issue Description",
            key: "issueDescription"
        },
        {
            name: "Issue Submission Timestamp",
            key: "issueTimestamp"
        },
        {
            name: "Issue Resolved",
            key: "issueResolved"
        }
    ]

    const action1 = [
        {
            label: "Called",
            value: "Called",
            color: "bg-blue-500"
        },
        {
            label: "Send Text",
            value: "Send Text",
            color: "bg-green-500"
        },
        {
            label: "2nd Call",
            value: "2nd Call",
            color: "bg-yellow-500"
        },
        {
            label: "2nd Text",
            value: "2nd Text",
            color: "bg-red-500"
        },
        {
            label: "3rd Call",
            value: "3rd Call",
            color: "bg-blue-500"
        },
        {
            label: "3rd Text",
            value: "3rd Text",
            color: "bg-green-500"
        },
        {
            label: "Email Sent",
            value: "Email Sent",
            color: "bg-yellow-500"
        },
        {
            label: "4th Call",
            value: "4th Call",
            color: "bg-red-500"
        },
        {
            label: "4th Text",
            value: "4th Text",
            color: "bg-blue-500"
        }
    ]

    const action2 = [
        {
            label: "Answered",
            value: "Answered",
            color: "bg-blue-500"
        },
        {
            label: "Left Voicemail",
            value: "Left Voicemail",
            color: "bg-green-500"
        },
        {
            label: "Voicemail Not Set Up",
            value: "Voicemail Not Set Up",
            color: "bg-yellow-500"
        },
        {
            label: "Voicemail Full",
            value: "Voicemail Full",
            color: "bg-red-500"
        },
        {
            label: "No Answer",
            value: "No Answer",
            color: "bg-blue-500"
        },
        {
            label: "Hung Up",
            value: "Hung Up",
            color: "bg-green-500"
        },
        {
            label: "Call Not Going Through",
            value: "Call Not Going Through",
            color: "bg-yellow-500"
        }
    ]

    const results = [
        {
            label: "Canceled",
            value: "Canceled",
            color: "bg-red-500"
        },
        {
            label: "Pending Reshcedule",
            value: "Pending Reshcedule",
            color: "bg-green-500"
        },
        {
            label: "Rescheduled",
            value: "Rescheduled",
            color: "bg-yellow-500"
        },
        {
            label: "Scheduled",
            value: "Scheduled",
            color: "bg-blue-500"
        },
        {
            label: "No Response",
            value: "No Response",
            color: "bg-red-500"
        },
        {
            label: "Not Interested",
            value: "Not Interested",
            color: "bg-green-500"
        },
        {
            label: "Invalid",
            value: "Invalid",
            color: "bg-yellow-500"
        },
        {
            label: "Not Serviceable",
            value: "Not Serviceable",
            color: "bg-blue-500"
        },
        {
            label: "Install Complete",
            value: "Install Complete",
            color: "bg-red-500"
        },
        {
            label: "Replied Stop",
            value: "Replied Stop",
            color: "bg-green-500"
        }
    ]


    const TableCell = ({ children, width = 'w-40', overflow }) => {

        return (
            <div className={width + ' py-3 px-6 pl-0 text-left max-h-28 ' + (overflow ? "overflow-y-auto" : "")}>
                {children}
            </div>
        )
    }

    const onChange = async (e, id) => {
        if (intervalId.current) {
            clearInterval(intervalId.current)
        }

        const listId = await getListId(activeTabId)

        intervalId.current = setTimeout(() => {
            Swal.fire({
                title: "Saving",
                text: "Please wait",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCloseButton: false,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                position: 'bottom-right',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const req = {
                customerId: id,
                accountNotes: e.target.value
            }
            console.log(req)
            CustomerApi.EditCustomerInfo(req).then(res => {


                Swal.fire({
                    title: res.status.success ? "Success" : "Failed",
                    text: res.status.message,
                    icon: res.status.success ? "success" : "error",
                    showConfirmButton: true,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                    showCancelButton: false,
                    showDenyButton: false,
                    toast: true,
                    position: 'bottom-right',
                    timer: 2000
                })
                if (res.status.success) {

                    setData(prev => {
                        const newData = prev.map(item => {
                            if (item.customerId === id) {
                                return { ...item, accountNotes: res.data.accountNotes }
                            }
                            return item
                        })

                        return newData
                    })

                    setFileData(prev => {
                        const newData = prev[listId].map(item => {
                            if (item.customerId === id) {
                                return { ...item, accountNotes: res.data.accountNotes }
                            }
                            return item
                        })
                        return { ...prev, [listId]: newData }
                    })
                }
            })
                .catch(err => {
                    Swal.fire({
                        title: "Failed",
                        text: err,
                        icon: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        allowEnterKey: true,
                        showCloseButton: true,
                        showCancelButton: false,
                        showDenyButton: false,
                        toast: true,
                        position: 'bottom-right',
                        timer: 2000
                    })
                })


        }, 3000);



    }


    const actionChangeHandler = async (value, id, type) => {
        const listId = await getListId(activeTabId)
        const req = {
            leadId: id,
            dynamicListId: listId,
        }

        if (type === 'action') {
            req.action = value
        }

        if (type === 'action2') {
            req.action2 = value
        }

        if (type === 'results') {
            req.results = value
        }

        Swal.fire({
            title: "Saving",
            text: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCloseButton: false,
            showCancelButton: false,
            showDenyButton: false,
            toast: true,
            position: 'bottom-right',
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const res = await MasterSheetApi.EditLeadActions(req)

        if (res.status.success) {
            Swal.fire({
                title: "Success",
                icon: "success",
                showConfirmButton: false,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showCloseButton: false,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                timer: 2000,
                position: 'bottom-right'
            })

            setData(prev => {
                const newData = prev.map(item => {
                    if (item.leadId === id) {
                        return { ...item, action: res.data.action, action2: res.data.action2, results: res.data.results }
                    }
                    return item
                })

                return newData
            })

            setFileData(prev => {
                const newData = prev[listId]?.list?.map(item => {
                    if (item.leadId === id) {
                        return { ...item, action: res.data.action, action2: res.data.action2, results: res.data.results }
                    }
                    return item
                })
                return { ...prev, [listId]: newData }
            })
        }
        else {
            Swal.fire({
                title: "Failed",
                text: res.status.message,
                icon: "error",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showCloseButton: false,
                showCancelButton: false,
                showDenyButton: false,
                toast: true,
                position: 'bottom-right',
                timer: 2000
            })
        }

    }

    const getAccountStatusColor = (status) => {
        switch (status) {
            case "Sold":
                return "text-green-600"
            case "Canceled":
                return "text-red-600"
            case "Scheduled":
                return "text-blue-600"
            case "Rescheduled":
                return "text-yellow-600"
            default:
                return "text-gray-600"
        }
    }


    return (
        <div style={{
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
        }} className="flex-grow  overflow-y-auto ">
            <pre className="whitespace-pre-wrap">
                <AccountStatusChangeForm customer={selectedCustomer} open={changeAccountStatusModal} setOpen={setChangeAccountStatusModal} />
                {
                    !loading ?
                        <>
                            <div style={{
                                maxHeight: '75vh',
                            }} className='flex  flex-col  overflow-y-auto border-2 border-gray-100 rounded-lg'>
                                <div className='z-10 flex sticky top-0 gap-4 text-left bg-gray-100 text-gray-600 uppercase font-bold w-max px-6 py-3'>
                                    {
                                        headers.map(

                                            (item, index) => {
                                                let width = "w-40"
                                                if (item.key === 'action' || item.key === 'results') {
                                                    width = "w-60"
                                                }
                                                else if (item.key === 'action2') {
                                                    width = "w-72"
                                                }
                                                else if (item.key === 'notes' || item.key === 'accountNotes') {
                                                    width = "w-72"
                                                }
                                                return (
                                                    <div onClick={()=>{
                                                        if(item.key === sortColumn){
                                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
                                                        }
                                                        else{
                                                            setSortDirection('asc')
                                                        }
                                                        setSortColumn(item.key)
                                                    }} className={width} key={index}>
                                                        {item.name} {
                                                            sortColumn === item.key && (
                                                                sortDirection === 'asc' ? <span>▲</span> : <span>▼</span>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                </div>
                                <div className='w-max ' >
                                    {
                                        sortedData?.map(
                                            (lead, index1) => {

                                                return <div className='flex px-6 w-full gap-4 border-b border-gray-200 hover:bg-gray-100' key={index1}>
                                                    {
                                                        headers.map(
                                                            (header, index) => {
                                                                if (header.key === 'action') {
                                                                    let action = lead[header.key]?.split('\n') || []
                                                                    return (
                                                                        <TableCell width='w-60' key={index}>
                                                                            <MultiColorSelector id={lead.leadId} header={header.key} save={actionChangeHandler} options={action1} value={action}   />
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                else if (header.key === 'action2') {
                                                                    return (
                                                                        <TableCell width='w-72' key={index}>
                                                                            <MultiColorSelector id={lead.leadId} header={header.key} save={actionChangeHandler} options={action2} value={lead[header.key]?.split('\n') || []} />
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                else if (header.key === 'results') {
                                                                    return (
                                                                        <TableCell width='w-60' key={index}>
                                                                            <MultiColorSelector id={lead.leadId} header={header.key} save={actionChangeHandler} options={results} value={lead[header.key]?.split('\n') || []} />
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                if (header.key === 'index') {
                                                                    return (
                                                                        <TableCell key={index}>
                                                                            {((currentPage-1)*30)+index1 + 1}
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                else if (header.key === 'notes') {
                                                                    return (
                                                                        <TableCell key={index} width='w-72' overflow={true}>
                                                                            {lead[header.key]}
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                else if (header.key === 'accountNotes') {
                                                                    return (
                                                                        <TableCell key={index} width='w-72' overflow={true}>
                                                                            <textarea onChange={(e) => {
                                                                                onChange(e, lead.customerId)
                                                                            }} className='w-full border-2 border-gray-200 rounded-lg py-2 px-4' rows='2' placeholder='Account Notes' defaultValue={lead[header.key]}></textarea>
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                else if (header.key === 'accountStatus') {
                                                                    return (
                                                                        <TableCell key={index}>
                                                                            <p onClick={() => {
                                                                                setChangeAccountStatusModal(true)
                                                                                setSelectedCustomer(lead)
                                                                            }} className={' underline cursor-pointer font-bold ' + getAccountStatusColor(lead[header.key])}>{lead[header.key]}</p>
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <TableCell overflow={true} key={index}>
                                                                            {lead[header.key]}
                                                                        </TableCell>
                                                                    )
                                                                }
                                                            }
                                                        )
                                                    }
                                                </div>
                                            })
                                    }
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-sm text-gray-500">
                                    Page {currentPage} of {totalPages} | Total Customers: {totalCustomers}
                                </div>
                                <div className="flex items-center space-x-2 ">
                                    <button
                                        onClick={() => {
                                            fetchData(false,currentPage-1)
                                            setCurrentPage(prev => Math.max(prev - 1, 1))
                                        }}
                                        disabled={currentPage === 1}
                                        className="w-12 h-12 rounded-full bg-gray-100 text-gray-600 disabled:opacity-50"
                                    >
                                        <ChevronLeft size={20} />
                                    </button>
                                    <button
                                        onClick={() => {
                                            fetchData(false,currentPage+1)
                                            setCurrentPage(prev => Math.min(prev + 1, totalPages))
                                        }}
                                        disabled={currentPage === totalPages}
                                        className=" w-12 h-12 rounded-full bg-gray-100 text-gray-600 disabled:opacity-50"
                                    >
                                        <ChevronRight size={20} />
                                    </button>
                                </div>
                            </div>
                        </>
                        :
                        <VersatileLoader size='large' color='#2761CF' />
                }
            </pre>
        </div>
    )
}

export default FileContent