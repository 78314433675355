import React from 'react'
import './Disabled.scss'
import { IoIosWarning } from 'react-icons/io'
import { getAuth } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
const Index = () => {
    const navigate = useNavigate();
    return (
        <div className='disabled_container'>
            <div className='content'>
                <IoIosWarning color='red' size={100} />
                <h1>Your account has been Disabled</h1>
                <p>Please contact support for more information.</p>
                <button onClick={() => {
                    getAuth().signOut();
                    navigate("/");
                }}>Log Out</button>
            </div>
        </div>
    )
}

export default Index