class SearchSalesOrgReq{
    constructor(){
        this.id = null;
        this.name = null;
        this.parentOrgId = null;
        this.createdAtFrom = null;
        this.createdAtTo = null;
        this.status = null;
        this.pagingParams = {
          pageNumber: 1,
          pageSize: 10
        };
    }
}
export default SearchSalesOrgReq;