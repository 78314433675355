class LoaderConversationReq {
    constructor() {

        this.conversationId = 0;
        this.pagingParams = {
            "pageNumber": 1,
            "pageSize": 100
        }

    }
}

export default LoaderConversationReq