class CreateIssue{
    constructor(){
        this.issueType = '';
        this.description = '';
        this.priorityLevel = '';
        this.isResolved = false;
        this.saleId = null;
        this.file = null;
    }
}

export default CreateIssue;