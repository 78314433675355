/* eslint-disable react-hooks/exhaustive-deps */
// import { getAuth } from '@firebase/auth'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import UsersApi from '../../../../API/UsersApi'
import { UserContext } from '../../../../App'
import EditUserReq from '../../../../Requests/Users/EditUserReq'
// import Toggle from "react-toggle";
import "react-toggle/style.css"
import DarkModeToggle from "react-dark-mode-toggle";
// import StringIntReq from '../../../../Requests/StringIntReq'
// import LinkUserPhoneNumberReq from '../../../../Requests/Users/LinkUserPhoneNumberReq'
// import Modal from './../../../../components/Modal'
// import LinkUserEmailAndPassword from '../../../../Requests/Users/LinkUserEmailAndPasswordReq'
const UserDataSettings = () => {
    const user = useContext(UserContext)
    const [editMode, setEditMode] = useState(false)
    // const [editPhoneMode, setEditPhoneMode] = useState(false)
    const [address, setAddress] = useState(user.address || "")
    const [city, setCity] = useState(user.city || "")
    const [state, setState] = useState(user.state || "")
    const [country, setCountry] = useState(user.country || "")
    // const [phone, setPhone] = useState(user.phoneLinked?user.phoneNumber:"")
    // const [email,setEmail] = useState(user.emailAndPasswordLinked?user.email:"");
    // const [password,setPassword] = useState("");
    // const [confirmPassword,setConfirmPassword] = useState("");
    // const [open, setOpen] = useState(false)
    const [isDark, setIsDark] = useState(localStorage.getItem('theme' + user?.id) === 'dark' ? true : false);
    // const auth = getAuth();
    const [intialRender, setInitialRender] = useState(true)
    console.log(user.city)
    const saveAddress = async () => {
        const req = new EditUserReq();


        if(city === user.city && state === user.state && country === user.country && address === user.address){
            setEditMode(false)
            return;
        }

        req.city = city;
        req.state = state;
        req.country = country;
        req.address = address;

        Swal.fire({
            icon: 'info',
            title: 'Updating',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        req.userId = user.id;
        req.firstName = "";
        req.lastName = "";
        const res = await UsersApi.EditUser(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Address Updated',
                text: "Your address has been updated",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            setEditMode(false)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
            });
            setCity(user.city)
            setState(user.state)
            setCountry(user.country)
        }
    }




    useEffect(() => {
        if (isDark && !intialRender) {
            if (document.body.classList.contains('default'))
                document.body.classList.remove('default')
            if (!document.body.classList.contains('dark')) {
                document.body.classList.add('dark')
            }
            localStorage.setItem('theme' + user?.id, 'dark');
            window.dispatchEvent(new CustomEvent('themeChange', { detail: 'dark' }));
            console.log('dark')
        }
        else if (!isDark && !intialRender) {
            if (document.body.classList.contains('dark'))
                document.body.classList.remove('dark')
            if (!document.body.classList.contains('default')) {
                document.body.classList.add('default')
                console.log('inner light')
            }
            localStorage.setItem('theme' + user?.id, 'light');
            window.dispatchEvent(new CustomEvent('themeChange', { detail: 'light' }));
            console.log('light')
        }
        setInitialRender(false)
    }, [isDark])


    return (
        <div className='UserDataSettings'>
            <div className='edit_buttons'>
                {
                    editMode ?
                        null :
                        <button onClick={() => setEditMode(true)}>Edit</button>
                }
                {
                    editMode ? <button onClick={() => {
                        setAddress(user.address)
                        setCity(user.city)
                        setState(user.state)
                        setCountry(user.country)
                        setEditMode(false)
                    }} className='cancel'>Cancel</button> : null
                }
                {
                    editMode ? <button onClick={() => saveAddress()}>Save</button> : null
                }
            </div>
            <p className='userDataHeading' >User Data</p>
            <div className='userDataBody'>
                <h3>City:</h3>
                <div className='hello'>
                    {
                        //remove capitalization
                        editMode ? <input className='in' type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} /> : <p>{city}</p>
                    }
                </div>
                <h3>State:</h3>
                <div className='hello'>
                    {
                        editMode ? <input className='in' type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} /> : <p>{state}</p>
                    }
                    
                </div>
                <h3>Country:</h3>
                <div className='hello'>
                    {
                        editMode ? <input className='in' type="text" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} /> : <p>{country}</p>
                    }
                    
                </div>
                <h3>Address:</h3>
                <div className='hello'>
                    {
                        editMode ? <input className='in' type="text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} /> : <p>{address}</p>
                    }
                    
                </div>

                <h3>Theme</h3>
                <DarkModeToggle
                    checked={isDark}
                    onChange={setIsDark}

                />
            </div>

        </div>
    )
}

export default UserDataSettings