/* eslint-disable react-hooks/exhaustive-deps */
import React,{useContext, useEffect, useState} from 'react'
import Layout from '../../../Layouts/Layout'
import './CustomerFeedback.scss'
import { Rating } from '@mui/material'
// import Review from './components/VideoReview'
import ReviewContainer from './components/ReviewContainer'
import SearchCustomerFeedbackReq from '../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq'
import CustomerFeedbackApi from '../../../API/CustomerFeedbackApi'
import { UserContext } from '../../../App'

const Index = () => {


  const [totalReviews, setTotalReviews] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [writtenReviews, setWrittenReviews] = useState([])
  const [speedTests, setSpeedTests] = useState([])
  const [videoReviews, setVideoReviews] = useState([])
  const user = useContext(UserContext)
  async function getReviews(){
    const req = new SearchCustomerFeedbackReq();
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1000;
    req.feedbackType = null;
    // if(user?.userType === "SalesRep"){
    //     req.salesRepId = user.id;
    // }
    // else if(user?.userType === "SalesOrgAdmin"){
    //     req.salesOrgId = user.salesOrgId;
    // }

    const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
    if(res?.status?.success)
    {
      setTotalReviews(res.data.totalItems)
      let totalRating = 0;
      let writtenReviews = [];
      let speedTests = [];
      let videoReviews = [];
      res?.data?.list.forEach(
        review => {
          totalRating += review.rating;
          if(review.feedbackType === "Written"){
            writtenReviews.push(review);
          }
          else if(review.feedbackType === "SpeedTest"){
            speedTests.push(review);
          }
          else if(review.feedbackType === "Video"){
            videoReviews.push(review);
          }
        }
      )
      setAverageRating(totalRating/res.data.totalItems);
      setWrittenReviews(writtenReviews);
      setSpeedTests(speedTests);
      setVideoReviews(videoReviews);
    }
  }


  useEffect(
    ()=>{
      getReviews();
    },[]
  )

  return (
    <Layout>
        <div className='customer_feedback_container'>
            <h3>Customer Feedback Analysis</h3>
            <div className='header'>
                <h3>Overall Rating</h3>
                <h1>{isNaN(averageRating)?0:averageRating.toFixed(1)}</h1>
                <Rating name="read-only" size='large' value={averageRating} precision={0.5} readOnly />
                <p>Based on <span>{isNaN(totalReviews)?0:totalReviews} Reviews</span></p>
            </div>
            <div className='rating_metrics'>
                <h3>Written Reviews</h3>
                <h2>{writtenReviews.length}</h2>
                <h3>Speed Tests</h3>
                <h2>{speedTests.length}</h2>
                <h3>Video Reviews</h3>
                <h2>{videoReviews.length}</h2>
            </div>
            <ReviewContainer />
        </div>
    </Layout>
  )
}

export default Index