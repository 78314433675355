import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../Layouts/Layout';
import authorized from "./../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png"
import logo from "./../../TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png"
import PhoneInput from 'react-phone-input-2'
import { CircularProgress } from '@mui/material';
import StringIntReq from '../../../Requests/StringIntReq';
import CustomerApi from '../../../API/CustomerApi';
import Swal from 'sweetalert2';
import CreateLightCustomerReq from '../../../Requests/Customer/CreateLightCustomerReq';
const SignUpWithReferral = () => {
    const { AffiliateCode } = useParams();

    const [referredbyCustomer, setReferredbyCustomer] = useState(null);
    const [loading, setLoading] = useState(true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [consent, setConsent] = useState(false);

    const [registered, setRegistered] = useState(false);


    const getReferredByCustomer = async () => {
        const req = new StringIntReq();
        req.string = AffiliateCode;
        const res  = await CustomerApi.GetFidiumCustomerByAffiliateCode(req);
        if(res.status.success){
            setReferredbyCustomer(res.data);
            setLoading(false);
        }
    }

    useEffect(
        () => {
            getReferredByCustomer();
        },[]
    )

    const createCustomer  = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Creating Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!firstName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!lastName){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Last Name is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!email){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Email is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        var pattern = new RegExp(/^\S+@\S+\.\S+$/);
        if (!pattern.test(email)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }


        if (!phone) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!consent) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Consent is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req  = new CreateLightCustomerReq();
        req.firstName = firstName;
        req.lastName = lastName;
        req.phoneNumber = phone;
        req.email = email;
        req.salesRepId = referredbyCustomer?.user?.id;
        req.signedUpBy = "Referral"
        req.affiliateCode = AffiliateCode;

        const res = await CustomerApi.CreateLightCustomer(req);

        if(res.status.success){
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Thank you for your interest in Lightning OS. Our Representative will contact you in 24 to 48 hours.",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setRegistered(true);
        }
        else{
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }


    }


    return (
        <Layout className='text-center'>
            {
                loading ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}>
                        <CircularProgress sx={{ color: "var(--primary-color)" }} size={60} />
                    </div>
                    :
                    registered ?
                    <div className='custom_modal'>
                        <img alt="Logo" src={authorized} className='logo' />
                        <h1 >Thank you for your interest in Lightning OS. Our Representative will contact you in 24 to 48 hours.</h1>
                        <img alt='Logo' src={logo} style={{
                            width: "100%",
                            marginTop: "1rem"
                        }} className='logo' />
                    </div>
                    :
                    <div className='custom_modal'>
                        <img alt="Logo" src={authorized} className='logo' />
                        {/* <h1 >Customer Info</h1> */}
                        <div className='inputs'>
                            <input placeholder='*First Name' value={firstName} onChange={(e) => {
                                setFirstName(e.target.value)
                            }} />
                            <input placeholder='Last Name' value={lastName} onChange={(e) => {
                                setLastName(e.target.value)
                            }} />
                            <input placeholder='Email' value={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }} />

                            <PhoneInput placeholder='* Cellphone' country={'us'} containerStyle={{
                                width: "100%",
                                borderRadius: "10px"
                            }} inputClass='custom_input'
                                value={phone} onChange={(e) => {
                                    setPhone(e)
                                }} />
                            <div className='consent'>
                                <input type="checkbox"
                                    checked={consent}
                                    onChange={(e) => {
                                        setConsent(e.target.checked)
                                    }}
                                />
                                <label>I agree to receive email and/or sms messages from Lightning OS LLC.</label>
                            </div>
                            <button onClick={() => {
                                createCustomer()
                            }
                            }>Reserve Free Install</button>
                            <img alt='Logo' src={logo} style={{
                                width: "100%",
                                // marginTop: "1rem"
                            }} className='logo' />
                        </div>
                    </div>
            }

        </Layout>
    )
}

export default SignUpWithReferral;
