class DeleteFiberCompanyReq {
    constructor() {
        this.string = null;
        this.int = null;
        this.pagingParams = {
            pageNumber: 1,
            pageSize: 10
        
        }
    }
}
export default DeleteFiberCompanyReq;