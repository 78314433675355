import { CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import React, { useContext, useEffect } from 'react'
import FiberHouseApi from '../../../API/FiberHouseApi'
import { UserContext } from '../../../App'
import Sidebar from '../../../Layouts/Sidebar'
import SearchFiberHousesReq from '../../../Requests/FiberHouse/SearchFiberHousesReq'
import Datatable from '../../../components/Datatable'
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
const Index = () => {

    const user = useContext(UserContext)
    const [name, setName] = React.useState(null)
    const [email, setEmail] = React.useState(null)
    const [phone, setPhone] = React.useState(null)
    const [address, setAddress] = React.useState(null)
    const [leads, setLeads] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(1)



    const searchFiberhouses = () => {
        setLoading(true)
        const req = new SearchFiberHousesReq()
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = 20
        req.name = name
        req.email = email
        req.phone = phone
        req.address = address
        FiberHouseApi.SearchFiberHouse(req).then(
            (res) => {
                
                const l = []
                res?.data?.list.map(
                    (item) => {
                        if (item?.lead?.id)
                            l.push({ ...item.lead, update: <p className='text-[#2761D0] underline cursor-pointer' >Edit</p> })
                    }

                )
                setTotalPages(res?.data?.totalPages)
                setLeads(l)
                setLoading(false)
            }
        )
    }

    const headers = [
        {
            name: "Lead ID",
            key: "id"
        },
        {
            name: "Name",
            key: "name"
        },
        {
            name: "Email",
            key: "email"
        },
        {
            name: "Phone",
            key: "phone"
        },
        {
            name: "Address",
            key: "address"
        },
        {
            name: "Status",
            key: "status"
        },
        {
            name: "Notes",
            key: "notes"
        },
        {
            name: "Update",
            key: "update"
        }
    ]

    useEffect(
        () => {
            searchFiberhouses()
        }, [currentPage]
    )
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchFiberhouses();
        }
    };
    return (
        <Layout>
            <div className='manageLeadsContainer' >
                <div className='manageLeadsHeader'>
                    <h1 className='manageLeadsTitle'>Manage Leads</h1>
                    <div className='searchFiltersInput' >
                        <input value={name} onKeyDown={handleKeyPress} onChange={(e) => { setName(e.target.value) }} placeholder='Name' />
                        <input value={email} onKeyDown={handleKeyPress} onChange={(e) => { setEmail(e.target.value) }} placeholder='Email' />
                        <input value={phone} onKeyDown={handleKeyPress} onChange={(e) => setPhone(e.target.value)} placeholder='Phone' />
                        <input value={address} onKeyDown={handleKeyPress} onChange={(e) => setAddress(e.target.value)} placeholder='Address' />
                    </div>
                    <button onClick={searchFiberhouses} >Search</button>
                </div>
                {
                    loading ?
                        <div className='spinner'>
                            <CircularProgress sx={{color:"var(--color-icon)"}} />
                        </div>
                        :
                        <>
                            <Datatable headers={headers} list={leads} currentPage={currentPage} totalPages={totalPages} />
                        </>
                }
                <div className='pagination'>
                    <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                </div>
            </div>
        </Layout>
    )
}

export default Index