/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Pagination } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import UserContext from "../../../App";
// import Sidebar from '../../../Layouts/Sidebar';
import SoftButton from '../../../components/SoftButton';
import CustomerApi from './../../../API/CustomerApi';
import DeleteCustomerReq from './../../../Requests/Customer/DeleteCustomerReq';
import EditCustomerReq from './../../../Requests/Customer/EditCustomerReq';
import SearchCustomerReq from './../../../Requests/Customer/SearchCustomerReq';
import EditableDatatable from './../../../components/EditableDatatable';
// import MobileLayout from '../../../Layouts/MobileLayout';
import Layout from '../../../Layouts/Layout';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { PaginationItem } from '@mui/material';
const Index = () => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const user = useContext(UserContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [timeZone, setTimeZone] = useState('');

    const headers = [
        {
            name: "First Name",
            key: "firstName"
        },
        {
            name: "Last Name",
            key: "lastName"
        },
        {
            name: "Email",
            key: "email"
        },
        {
            name: "Phone",
            key: "cellPhone"
        },
        {
            name:"Address",
            key:"address"
        },
        {
            name: "Account Holder Full Name",
            key: "secondaryAccountHolderFullName"
        },
        {
            name: "Desired Install Date",
            key: "desiredInstallDate"
        },
        {
            name: "Desired Install Time",
            key: "desiredInstallTime"
        },
        {
            name: "Fiber PLan",
            key: "fiberPlanString"
        },
        {
            name:"Time Zone",
            key:"timeZone"
        },
        {
            name: "Current Internet Speed Test",
            key: "currentInternetSpeed"
        },
        {
            name: "Lead Id",
            key: "leadId"
        },
        {
            name: "Actions",
            key: "actions"

        }
    ];

    const editCustomer = (customer) => {
        Swal.fire({
            icon: "info",
            title: "Editing Customer",
            text: "Please Wait ...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const req = new EditCustomerReq();
        req.id = customer.id;
        req.orderNumber = customer?.orderNumber;
        req.installDateTime = customer?.installDateTime;
        req.installCopy = customer?.installCopy;
        req.timeZone = customer?.timeZone;
        req.accountStatus = customer?.accountStatus;
        req.accountNotes = customer?.accountNotes;
        req.submittedToEmbarcForm = customer?.submittedToEmbarcForm;
        req.secondaryPhoneNumber = customer?.secondaryPhoneNumber;
        req.firstName = customer?.firstName;
        req.lastName = customer?.lastName;
        req.secondaryAccountHolderFullName = customer?.secondaryAccountHolderFullName;
        req.email = customer?.email;
        req.address = customer?.address;
        req.cellPhone = customer?.cellPhone;
        req.installASAP = customer?.installASAP;
        req.desiredInstallDate = customer?.desiredInstallDate;
        req.desiredInstallTime = customer?.desiredInstallTime;
        req.fourDigitPin = customer?.fourDigitPin;
        req.securityQuestion1 = customer?.securityQuestion1;
        req.securityQuestion2 = customer?.securityQuestion2;
        req.answerSecurityQuestion1 = customer?.answerSecurityQuestion1;
        req.answerSecurityQuestion2 = customer?.answerSecurityQuestion2;
        req.fiberPlanString = customer?.fiberPlanString;
        req.fiberPlanId = customer?.fiberPlanId;
        req.notesToFiberCompanySpecialInstructions = customer?.notesToFiberCompanySpecialInstructions;
        req.promoCode = customer?.promoCode;
        req.freeMonth = customer?.freeMonth;
        req.consentChecked = customer?.consentChecked;
        req.currentInternetSpeed = customer?.currentInternetSpeed;
        req.speedTestBeforeImage = customer?.speedTestBeforeImage;

        CustomerApi.EditCustomer(req).then(
            (res) => {
                
                if (res?.status?.success) {
                    Swal.fire(
                        {
                            title: "Customer Edited",
                            text: "Customer has been edited",
                            icon: "success",
                            didOpen: () => {
                                Swal.hideLoading()
                            }
                        }
                    );

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });
                }
            }
        );

    };

    const deleteCustomer = (id) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure?",
            text: "You want to delete this Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes, I'm Sure!",

        }).then(
            (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        icon: "info",
                        title: "Deleting Customer",
                        text: "Please Wait ...",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    });

                    const req = new DeleteCustomerReq();
                    req.int = id;
                    req.pagingParams.pageNumber = 1;
                    req.pagingParams.pageSize = 10;
                    CustomerApi.DeleteCustomer(req).then(
                        (res) => {
                            
                            if (res?.status?.success) {
                                Swal.fire(
                                    {
                                        title: "Customer Deleted",
                                        text: "Customer has been deleted",
                                        icon: "success",
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    }
                                );

                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: res?.status?.message,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }
                    );
                }
                else {
                    Swal.hideLoading()
                }
            }

        );

    };

    const searchCustomers = () => {
        setLoading(true);
        const req = new SearchCustomerReq();
        if (firstName !== "") {
            req.firstName = firstName;
        }
        if (lastName !== "") {
            req.lastName = lastName;
        }
        if (email !== "") {
            req.email = email;
        }
        if (address !== "") {
            req.address = address;
        }
        if (timeZone !== "") {
            req.timeZone = timeZone;
        }
        
        req.salesOrgId = user?.salesOrgId;
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = 10;

        CustomerApi.SearchCustomers(req).then(
            (res) => {
                if (res?.status?.success) {
                    
                    setList(res?.data?.list);
                    setTotalPages(res.data.totalPages);
                    setLoading(false);
                }
                else {
                    
                }
            }
        );

    };

    useEffect(() => {
        searchCustomers();
    }, [currentPage]);

    return (
        <Layout>
            <div className='flex flex-col gap-5 justify-center align-middle border-1 border-solid border-[#E5E5E5] bg-white p-5 rounded-2xl'>
                <div className='flex gap-4 flex-wrap'>
                    <input type="text" placeholder='First Name' className='border-2 border-solid border-[#00AFFF] outline-none rounded-md p-2' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <input type="text" placeholder='Last Name' className='border-2 border-solid border-[#00AFFF] outline-none rounded-md p-2' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <input type="text" placeholder='Email' className='border-2 border-solid border-[#00AFFF] outline-none rounded-md p-2' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="text" placeholder='Address' className='border-2 border-solid border-[#00AFFF] outline-none rounded-md p-2' value={address} onChange={(e) => setAddress(e.target.value)} />
                    <input type="text" placeholder='Time Zone' className='border-2 border-solid border-[#00AFFF] outline-none rounded-md p-2' value={timeZone} onChange={(e) => setTimeZone(e.target.value)} />
                </div>
                <div className='flex justify-center items-center'>
                    <SoftButton onClick={()=>{searchCustomers()}} className="w-fit">Search</SoftButton>
                </div>
                <h3 className='text-3xl text-center'>Manage Customers</h3>
                {
                    loading ?
                        <div className='w-full flex justify-center items-center'>
                            <CircularProgress sx={{color:"var(--color-icon)"}} />
                        </div>
                        :
                        <EditableDatatable headers={headers} list={list} refresh={searchCustomers} onSave={editCustomer} onDelete={deleteCustomer} />
                }
                <div className='w-full flex justify-center align-center'>
                    <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} onChange={(e, pageNo) => {
                        setCurrentPage(pageNo)
                    }} />
                </div>
            </div>
        </Layout>
    );
};

export default Index;
