/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useContext, useEffect, useState } from 'react'
import SearchCustomerFeedbackReq from '../../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq'
import CustomerFeedbackApi from '../../../../API/CustomerFeedbackApi'
import VideoReview from './../../CustomerFeedback/components/VideoReview'
import WrittenReview from './../../CustomerFeedback/components/WrittenReview'
import SpeedTestReview from './../../CustomerFeedback/components/SpeedTestReview'

// import Swiper core and required modules
import { Navigation, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import './ReviewComponent.scss'
import 'swiper/css';
import 'swiper/css/navigation';
import { CircularProgress } from '@mui/material'
import { UserContext } from '../../../../App'

const ReviewContainer = ({ searchArea,salesRepId,type }) => {
    const [reviews, setReviews] = useState([])
    const [reviewType, setReviewType] = useState(type?type:"Written");
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [allReviews, setAllReviews] = useState(true)
    const [totalItems, setTotalItems] = useState(0)
    const user = useContext(UserContext)
    const getReviews = async () => {
        setLoading(true);
        const req = new SearchCustomerFeedbackReq();

        if (searchArea) {
            console.log(searchArea)
            req.area = searchArea
        }
       if(!allReviews && user?.userType === "SalesRep"){
           req.salesRepId = user.id;
       }
    //    else if(!allReviews && user?.userType === "SalesOrgAdmin"){
    //        req.salesOrgId = user.salesOrgId;
    //    }
       if(!allReviews &&salesRepId){
           req.salesRepId = salesRepId;
       }
        req.pagingParams.pageNumber = pageNumber;
        req.pagingParams.pageSize = 20;
        req.feedbackType = reviewType;
        const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
        console.log(res);
        if (res?.status?.success) {

            setTotalItems(res.data.totalItems);
            if (res.data.totalPages > pageNumber) {
                setHasMore(true);
            }
            else {
                setHasMore(false);
            }
            if(pageNumber === 1)
            {
                setReviews((prev)=>{
                    return res?.data?.list
                })
            }
            else{
                setReviews((prev)=>{
                    return [...prev,...res?.data?.list]
                })
            }
            setLoading(false);
        }
        else {
            console.log(res);
        }
    }

    useEffect(
        () => {
            setReviews([]);
            setTotalItems(0);
            setHasMore(true);
            if(pageNumber>1){
                setPageNumber(1);
            }
            else{
                getReviews();
            }
            // getReviews();
        }, [reviewType,allReviews]
    )

    // useEffect(
    //     () => {
    //         setReviews([]);
    //         setTotalItems(0);
    //         setHasMore(true);
    //         if(pageNumber>1)
    //         {
    //             setPageNumber(1);
    //         }
    //         else{
    //             getReviews();
    //         }
    //     },[reviewType]
    // )

    useEffect(
        () => {
            // if (pageNumber > 1) {
                getReviews();
            // }
        }, [pageNumber]
    )

    return (
        <div className='main_review'>

            <div className='filter'>
                {/* <h3>My Reviews</h3> */}
                <div style={{
                    gridTemplateColumns: '1fr 1fr',
                }} className='top_filter_buttons'>
                    <button className={`${allReviews?'active':null}`} onClick={()=>{
                        setAllReviews(true);
                    }}>Company</button>
                    <button className={`${!allReviews?'active':null}`} onClick={()=>{
                        setAllReviews(false);
                       
                    }}>My Reviews</button>
                </div>
                {/* <div className='filter_buttons'>
                    <button className={reviewType === "Video" ? 'active' : null} onClick={() => setReviewType('Video')}>Video</button>
                    <button className={reviewType === "Written" ? 'active' : null} onClick={() => setReviewType('Written')}>Written</button>
                    <button className={reviewType === "SpeedTest" ? 'active' : null} onClick={() => setReviewType('SpeedTest')}>Speed Test</button>
                </div> */}
                {!loading && <div>
                    <p style={{
                        color: 'var(--primary-color)',
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                    }}>Total Reviews: {totalItems}</p>
                </div>}
            </div>
            <Swiper

                modules={[Navigation, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={(swiper) =>{
                    if(swiper.isEnd && hasMore){
                        setPageNumber(pageNumber+1);
                    
                    }
                }}
                className='slider_review'
                // style={{
                //     padding: '0 1rem'
                // }}
            >
                {
                    reviews.map(
                        (review, index) => {
                            if (review.feedbackType === reviewType && review.feedbackType === 'Video') {
                                return <SwiperSlide key={index}><VideoReview  review={review} /></SwiperSlide>
                            }
                            else if (review.feedbackType === 'Written' && review.feedbackType === reviewType) {
                                return <SwiperSlide key={index}><WrittenReview  review={review} /></SwiperSlide>
                            }
                            else if (review.feedbackType === 'SpeedTest' && review.feedbackType === reviewType) {
                                return <SwiperSlide key={index}><SpeedTestReview  review={review} /></SwiperSlide>
                            }
                        }
                    )
                }
                {loading && hasMore && <SwiperSlide  >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }} >
                        <CircularProgress sx={{ color: 'var(--primary-color)' }} size={40} />
                    </div>
                </SwiperSlide>}
                {
                    !loading && !hasMore && reviews.length===0 && <SwiperSlide>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%'
                        }} >
                            <p>No Reviews</p>
                        </div>
                    </SwiperSlide>
                }
            </Swiper>
        </div>
    )
}

export default ReviewContainer