class AnnouncementReq {
    constructor() {
        this.id = null;
        this.title = null;
        this.message= null;
        this.announcementType= null;
        this.startDate= null;
        this.endDate= null;
        this.isHidden = false;
        this.announcementImage = null;
        this.announcementVideo = null;
        this.salesOrgIds = null;
        this.salesRepIds = null;
    }
};

export default AnnouncementReq;