import React, { useState } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import './index.scss';

function CalendlyUserSelection({ candidateId, onSchedule, firstInterviewCompleted = null, secondInterviewCompleted = null, type }) {
    const [selectedUser, setSelectedUser] = useState(null);
    const [generatedLink, setGeneratedLink] = useState("");

    // Hardcoded options for the two users
    const users = [
        {
            value: "https://calendly.com/jessica-ylwk/interview",
            label: "Jessica Man (jessica@lightningos.world)",
            type: "Recruit"
        },
        {
            value: "https://calendly.com/shasta-lightningos/interview",
            label: "Shasta Weishampel (shasta@lightningos.world)",
            type: "Company"
        }
    ];

    // Determine the interview round
    const determineInterviewRound = () => {
        if (!firstInterviewCompleted ) {
            return "1";
        } else if (!secondInterviewCompleted) {
            return "2";
        }
    };

    // Generate Calendly link
    function handleGenerateLink() {
        if (!selectedUser) {
            alert("Please select a Calendly user.");
            return;
        }

        // Determine interview round
        const interviewRound = determineInterviewRound();

        if (interviewRound === "unknown") {
            alert("Both interviews are already completed.");
            return; 
        }

        // Append candidateId and interview round to the URL
        let link = ``;
        link = `${selectedUser.value}?utm_candidate_id=${candidateId}&utm_interview_round=${interviewRound}&utm_type=${type}`;
        if(type === "Company") {
             link = `${selectedUser.value}?utm_candidate_id=${candidateId}&utm_type=${type}`;
        }
        
        setGeneratedLink(link);
    }

    // Notify parent component with the generated link
    function handleSchedule() {
        if (generatedLink) {
            onSchedule(candidateId, generatedLink);
        } else {
            alert("Please generate a link first.");
        }
    }

    return (
        <div className="calendly-user-selection">
            <div className="form-group">
                <label>Select a Calendly User</label>
                <Select
                    options={users.filter(user => user.type === type)}
                    value={selectedUser}
                    onChange={(selectedOption) => setSelectedUser(selectedOption)}
                    placeholder="Select a user"
                />
            </div>

            <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateLink}
                fullWidth
                style={{ marginTop: "1rem" }}
            >
                Generate Link
            </Button>

            {generatedLink && (
                <div className="generated-link" style={{ marginTop: "1.5rem" }}>
                    <h3>Generated Link:</h3>
                    <a href={generatedLink} target="_blank" rel="noopener noreferrer">
                        {generatedLink}
                    </a>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSchedule}
                        fullWidth
                        style={{ marginTop: "1rem" }}
                    >
                        Send Schedule Interview
                    </Button>
                </div>
            )}
        </div>
    );
}

export default CalendlyUserSelection;
