import React, { useEffect, useState, useRef } from 'react'
import './AvailabilityCheckDetail.scss'
import Layout from './../../../Layouts/Layout'
import { useParams } from 'react-router-dom'
import SearchCheckAvailabilityReq from './../../../Requests/CheckAvailability/SearchCheckAvailabilityReq'
import CustomerApi from './../../../API/CustomerApi'
import { useNavigate } from 'react-router-dom'
import { Map, View } from 'ol';
import { fromLonLat } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import StringIntReq from '../../../Requests/StringIntReq'
import Swal from 'sweetalert2'
import { CircularProgress } from '@mui/material'
import { IoArrowBack } from 'react-icons/io5'
const Index = () => {

    const { id } = useParams()
    const [availabilityCheck, setAvailabilityCheck] = useState({});
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [notes, setNotes] = useState('')
    const [contacted, setContacted] = useState('')
    const [availabilityType, setAvailabilityType] = useState('')
    const [isAvailable, setIsAvailable] = useState('')

    const [editMode, setEditMode] = useState(false)
    const navigate = useNavigate();
    const [position, setPosition] = useState([0, 0])
    const [loading, setLoading] = useState(true)
    const intialRender = useRef(true)
    const getAvailabilityCheck = async () => {
        setLoading(true)
        const req = new SearchCheckAvailabilityReq()
        req.id = id
        console.log(id)
        req.pagingParams = {
            pageNumber: 1,
            pageSize: 1
        }
        const res = await CustomerApi.SearchAvailabilityCheck(req)
        if (res && res.data) {
            console.log(res)
            setAvailabilityCheck(res?.data?.list[0])
            setFirstName(res?.data?.list[0].firstName)
            setLastName(res?.data?.list[0].lastName)
            setEmail(res?.data?.list[0].email)
            setPhone(res?.data?.list[0].phone)
            setAddress(res?.data?.list[0].address)
            setNotes(res?.data?.list[0].notes)
            setContacted(res?.data?.list[0].contacted)
            setAvailabilityType(res?.data?.list[0].availabilityType)
            setIsAvailable(res?.data?.list[0].isAvailable)
            if (res?.data?.list[0].coordinates?.latitude && res?.data?.list[0].coordinates?.longitude)
                setPosition([res?.data?.list[0].coordinates?.longitude, res?.data?.list[0].coordinates?.latitude])
            setLoading(false)
        }

    }

    const mapRef = useRef();

    useEffect(() => {
        const marker = new Feature({
            geometry: new Point(fromLonLat(position))
        });


        marker.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 10,
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: '#3399CC',
                    }),
                }),
            })
        );

        mapRef.current = new Map({
            target: 'map',
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
                new VectorLayer({
                    source: new VectorSource({
                        features: [marker]
                    })
                })
            ],
            view: new View({
                center: fromLonLat(position),
                zoom: 20
            })
        });

        return () => {
            if (mapRef.current) {
                mapRef.current.setTarget(undefined);
            }
        };
    }, [position]);

    useEffect(() => {
        if (id) {
            getAvailabilityCheck()
        }
        else {
            navigate('/manage-check-availability')
        }
    }, [])

    useEffect(() => {
        if (availabilityCheck && !availabilityCheck.seen) {
            const req = new StringIntReq()
            req.int = availabilityCheck.id
            CustomerApi.MarkAvailabilityCheckAsSeen(req).then(
                res => {
                    if (res?.status?.success) {
                        getAvailabilityCheck()
                    }
                }
            )
        }
    }, [availabilityCheck])


    const onCancel = () => {
        setEditMode(false)
        setFirstName(availabilityCheck.firstName)
        setLastName(availabilityCheck.lastName)
        setEmail(availabilityCheck.email)
        setPhone(availabilityCheck.phone)
        setAddress(availabilityCheck.address)
        setNotes(availabilityCheck.notes)
        setContacted(availabilityCheck.contacted)
        setAvailabilityType(availabilityCheck.availabilityType)

    }

    async function editContactedAndIsAvailable(contacted, isAvailable) {
        const req = new FormData();
        req.append('id', availabilityCheck.id)
        req.append('contacted', contacted)
        req.append('isAvailable', isAvailable)
        const res = await CustomerApi.EditAvailabilityCheck(req)
    }

    async function onSave() {

        if (firstName !== availabilityCheck.firstName || lastName !== availabilityCheck.lastName || email !== availabilityCheck.email || phone !== availabilityCheck.phone || address !== availabilityCheck.address || notes !== availabilityCheck.notes || contacted !== availabilityCheck.contacted || availabilityType !== availabilityCheck.availabilityType) {
            Swal.fire({
                icon: "info",
                title: "Updating Availability Check",
                text: "Please wait...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const req = new FormData();
            req.append('id', availabilityCheck.id)
            req.append('firstName', firstName)
            req.append('lastName', lastName)
            req.append('email', email)
            req.append('phone', phone)
            req.append('address', address)
            req.append('notes', notes)
            req.append('contacted', contacted)
            req.append('availabilityType', availabilityType)
            const res = await CustomerApi.EditAvailabilityCheck(req)
            if (res?.status?.success) {
                setEditMode(false)
                getAvailabilityCheck()
                Swal.fire({
                    icon: "success",
                    title: "Availability Check Updated",
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Error Updating Availability Check",
                    text: res?.status?.message
                })
            }

        }
        else {
            setEditMode(false)
        }

    }

    useEffect(() => {
        if (!intialRender.current) {
            editContactedAndIsAvailable(contacted, isAvailable)
        }
        else {
            intialRender.current = false
        }
    }, [contacted, isAvailable])    

    return (
        <Layout>
            {loading ?
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%"
                }}>
                    <CircularProgress size={50} sx={{
                        color: "var(--color-primary)",
                    }} />
                </div>
                :
                <>
                  <IoArrowBack color='black' size={30} style={{ cursor: 'pointer',marginLeft:"1rem",marginBottom:"1rem" }} onClick={() => navigate(-1)} />
                <div className='availability_check_details_container'>
                    <div className='details'>
                      {
                            (position[0] !== 0 && position[1] !== 0) ?
                       <div id='map' style={{ height: '250px', width: '100%', borderRadius: "10px", overflow: "hidden" }}></div>
                        : <h3 className='no_coordinates_found'>No Coordinates Found</h3>
                    }

                        <div className='detail_item'>
                            <label>First Name</label>
                            <input type="text" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} readOnly={!editMode} />
                        </div>
                        <div className='detail_item'>
                            <label>Last Name</label>
                            <input type="text" value={lastName} onChange={(e) => { setLastName(e.target.value) }} readOnly={!editMode} />
                        </div>
                        <div className='detail_item'>
                            <label>Email</label>
                            <input type="text" value={email} onChange={(e) => { setEmail(e.target.value) }} readOnly={!editMode} />
                        </div>
                        <div className='detail_item'>
                            <label>Phone</label>
                            <input type="text" value={phone} onChange={(e) => { setPhone(e.target.value) }} readOnly={!editMode} />
                        </div>
                        <div className='detail_item'>
                            <label>Address</label>
                            <input type="text" value={address} onChange={(e) => { setAddress(e.target.value) }} readOnly={!editMode} />
                        </div>
                        <div className='detail_item'>
                            <label>Notes</label>
                            <input type="text" value={notes} onChange={(e) => { setNotes(e.target.value) }} readOnly={!editMode} />
                        </div>

                        <div className='detail_item'>
                            <label>Contacted</label>
                            <input type="checkbox" value={contacted} checked={contacted} onChange={(e) => { setContacted(e.target.checked) }} />
                        </div>
                        <div className='detail_item'>
                            <label>Is Available</label>
                            <input type="checkbox" value={isAvailable} checked={isAvailable} onChange={(e) => { setIsAvailable(e.target.checked) }} />
                        </div>
                        <div className='detail_item'>
                            <label>Availability Type</label>
                            <input type="text" value={availabilityType} onChange={(e) => { setAvailabilityType(e.target.value) }} readOnly={!editMode} />
                        </div>

                    </div>
                    {
                        editMode ?
                            <div className='button_group'>
                                <button onClick={() => { onSave() }} >Save</button>
                                <button onClick={() => { onCancel() }}>Cancel</button>
                            </div>
                            :
                            <button className='edit_button' onClick={() => { setEditMode(!editMode) }}>Edit</button>

                    }

                </div>
                </>
                }
        </Layout>
    )
}

export default Index