import React, { useContext, useEffect } from 'react'
import { SearchFiltersContext, UserContext } from './App';
import FiberHouseApi from './API/FiberHouseApi';
import StringIntReq from './Requests/StringIntReq';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const RequestedCustomerReminder = () => {


    const user = useContext(UserContext);
    const searchFilters = useContext(SearchFiltersContext)
    const navigate = useNavigate();

    const searchRequestedCustomers = async () => {
        const req = new StringIntReq();
        req.int = user.id;
        const res = await FiberHouseApi.GetNumberOfRequestedCustomers(req)

        if (res?.status?.success) {
            if (res.data.int > 0) {
             const { value } =await  Swal.fire({
                    title: 'New Requested Customers!',
                    text: 'You have ' + res.data.int + ' new requested customers',
                    icon: 'info',
                    confirmButtonText: 'Set Them Up Now!',
                    showCancelButton: true,
                    showDenyButton: true,
                    denyButtonText: 'Ignore',
                    cancelButtonText: 'Later',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })

                if (value) {
                    searchFilters.current = {
                        ...searchFilters.current,
                        contactCenter: {
                            ...searchFilters?.current?.contactCenter,
                            leadType: "RequestedCustomer"
                        }
                    }
                    navigate('/contact-center')
                }
                if (value === false) {

                    Swal.fire({
                        title: 'Reminder',
                        text: 'You can always find your requested customers in the contact center',
                        icon: 'info',
                        confirmButtonText: 'Got it!',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    })

                    let req = new StringIntReq();
                    req.int = user.id;
                    FiberHouseApi.IgnoreRequestedCustomers(req)
                        .then(
                            res => {
                                if (res?.status?.success) {
                                    console.log("Requested Customers Ignored")
                                }
                            }
                        )
                }

            }
        }

    }


    useEffect(() => {
        searchRequestedCustomers();
    }, [])

    return (
        <></>
    )
}

export default RequestedCustomerReminder