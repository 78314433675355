import React from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import DeleteFiberCompanyReq from '../../../../Requests/FiberCompany/DeleteFIberCompanyReq'
import FiberCompanyApi from '../../../../API/FiberCompanyApi'
const FiberCompany = ({ company,searchCompany }) => {

    const navigate = useNavigate()
    const editCompany = () => {
        navigate('/manageFiberCompany/details', {
            state: {
                id: company.id,
                name: company.name,
                description: company.description,
                servicableAreaId: company.servicableAreaId,
            }
        })
    }

    const DeleteCompany = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this company!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'

        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please Wait !',
                    html: 'Company is being deleted.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                const req = new DeleteFiberCompanyReq()
                req.int = id
                FiberCompanyApi.DeleteFiberCompany(req).then(res => {
                    if (res?.status?.success) {
                        Swal.fire(
                            'Deleted!',
                            'Company has been deleted.',
                            'success'
                        )
                        searchCompany()
                    }
                    else {
                        Swal.fire(
                            'Error!',
                            'Something went wrong.',
                            'error'
                        )
                    }
                })
            }
        })
    }

    return (
        <div className='fiberCompany'>
            <div className='image'>
                {company.logo}
            </div>
            <p>{company.name}</p>
            <p>{company.description}</p>
            <p>{company.servicableAreaId}</p>
            <button className='fiberCompanyEdit' onClick={editCompany} >Edit</button>
            <button className='fiberCompanyDelete' onClick={() => { DeleteCompany(company.id) }} >Delete</button>
        </div>
    )
}

export default FiberCompany