import React from 'react'
import { FaCheckCircle } from "react-icons/fa";
import './Plan.scss'
import { Modal } from '@mui/material';
import { IoMdCloseCircle } from 'react-icons/io';
import authorizedFastwyre from './../../Offer/components/authorizedFastwyre.png'
import authorized from './../../Offer/components/authorized.png'
const Plan = ({ setColor, setSelectedPlan, setOpen, name, plan, bulletPoints, color, offer }) => {

    const [speedOpen, setSpeedOpen] = React.useState(false);
    function onClick() {
        setSelectedPlan(plan)
        setOpen(true)
        setColor(color)
    }


    function hexToRgba(hex, alpha = 1) {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return (
        <>
            <div className='plan_container'>
                <h1 className='plan_name' style={{ backgroundColor: color }} >{name}</h1>

                <p className='plan_price'><span>${plan?.price}</span> /mo*</p>

                <div style={{ backgroundColor: hexToRgba(color, 0.5), }} className='rounded-3xl px-2 py-2'>
                    <p className='plan_speedupto'><p style={{ color: "#170F49" }}>Speeds up to</p><p className='gig_speed'>{plan?.speed * 1000} Mbps</p><span className='flex items-center justify-center'>{plan?.speed} GIG</span>
                        <br />
                    </p>
                    <p 
                    className={`plan_desc${plan.speed * 1000} cursor-pointer`} onClick={() => {
                        setSpeedOpen(true)
                    }}>{offer.fiberType==="Fiber"?"Upload/":""}Download Speeds</p>
                </div>


                <ul className='plan_bullets'>
                    {bulletPoints.split('\n').map((bulletPoint, index) => <li key={index}>
                        <FaCheckCircle color={color} />
                        <p>{bulletPoint}</p>
                    </li>)}
                </ul>
                <p className='plan_desc text-base font-semibold mt-4 text-center'>
                    {
                        plan?.speed === 1 ? <>Get a $150 credit towards your <br />first two months of service.</> :
                            <>Get a $100 credit towards your <br />first two months of service.</>
                    }
                </p>
                <div className='flex w-full'>
                    <button onClick={onClick} style={{ backgroundColor: color }} className='plan_button'>Accept Offer</button>
                </div>
            </div>
            <Modal open={speedOpen} onClose={() => {
                setSpeedOpen(false)
            }}>
                <div className='custom_modal'>
                    <div style={{ width: "100%" }}>
                        <IoMdCloseCircle style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => {
                            setSpeedOpen(false)
                        }} size={30} color={color} />
                    </div>
                    {
                        offer?.fiberCompanyId === 9 && <img alt="Logo" src={authorizedFastwyre} className='logo' />
                    }
                    {
                        offer?.fiberCompanyId === 2 && <img alt="Logo" src={authorized} className='logo' />
                    }
                    <div className='speed_container'>
                        <h1>{plan?.speed} GIG</h1>
                        <div className='speed'>
                            {
                                offer.fiberType === "Fiber" ?
                                <p>Upload Speeds - <span>{offer.fiberType === "Fiber" ? plan?.speed * 1000 : 20} Mbps</span></p>
                                :
                                ""
                            }
                            <p>Download Speeds - <span>{plan?.speed * 1000} Mbps</span></p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Plan