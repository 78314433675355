import NoSalesOrgId from "../Views/Errors/NoSalesOrgId"

const ErrorRoutes = [
    {
            name: "LandingPage",
            key: "LandingPage",
            route: "/",
            component: <NoSalesOrgId />,     
    }
]

export default ErrorRoutes