import React, { useEffect, useState } from 'react';
import MapIconApi from '../../../../API/MapIconApi';
import CreateLeadStatusReq from '../../../../Requests/FiberHouse/CreateLeadStatusReq';
import Swal from 'sweetalert2';
import LeadStatus from './LeadStatus';
import StringIntReq from '../../../../Requests/StringIntReq';

const ManageLeadStatuses = () => {
    const [leadStatuses, setLeadStatuses] = useState([]);
    const [newLeadStatus, setNewLeadStatus] = useState('');

    const handleCreateLeadStatus = async () => {
        Swal.fire({
            title: 'Creating Lead Status...',
            icon: 'info',   
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        if(newLeadStatus.trim() === '') {
            Swal.fire({
                icon: 'error',
                title: 'Lead Status cannot be empty',
                showConfirmButton: true,
                allowOutsideClick: true,
                timer: 1500
            })
            return;
        }

        let req = new CreateLeadStatusReq();
        req.name = newLeadStatus;

        let res = await MapIconApi.CreateLeadStatus(req);
        if (res?.status?.success) {
            setLeadStatuses([...leadStatuses, res.data]);
            Swal.fire({
                icon: 'success',
                title: 'Lead Status Created Successfully',
                showConfirmButton: true,
                timer: 1500
            })
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Error Creating Lead Status',
                showConfirmButton: true,
                timer: 1500
            })
        }
    };

     
    const SearchLeadStatuses = async () => {
        let req = new StringIntReq();
        
        let res = await MapIconApi.SearchLeadStatuses(req);
        if (res?.status?.success) {
            setLeadStatuses(res.data);
        }
        else{
            Swal.fire({
                icon: 'error',
                title: res?.status?.message,
                showConfirmButton: true,
                timer: 1500
            })
        }
    }

    useEffect(() => {  
        if(leadStatuses.length === 0)
        {
            SearchLeadStatuses();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    return (
        <div className="ManageLeadStatuses">
            <div className="TitleDiv">
                <h2>Lead Statuses</h2>
            </div>

            <div className="CreateLeadStatus">
                {/* <input
                    type="text"
                    value={newLeadStatus}
                    onChange={(e) => setNewLeadStatus(e.target.value)}
                /> */}
                <input value={newLeadStatus} onChange={(e) => setNewLeadStatus(e.target.value)} placeholder='Enter New Lead Status' />
                <button onClick={handleCreateLeadStatus}>Create</button>
            </div>
            <div className="LeadStatuses">
                {leadStatuses.map((leadStatus, index) => (
                    <LeadStatus leadStatus={leadStatus} index={index} SearchLeadStatuses={SearchLeadStatuses} key={leadStatus.id} />
                ))}
            </div>
        </div>
    );
};

export default ManageLeadStatuses;
