import React, { useEffect, useState } from 'react';
import {  useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomerApi from '../../../../API/CustomerApi';
import StringIntReq from '../../../../Requests/StringIntReq';
import UpdateCustomerInstallDateReq from '../../../../Requests/Customer/UpdateCustomerInstallDateReq';
import Layout from '../../../../Layouts/Layout';
import { CircularProgress } from '@mui/material';
import SearchCustomerReq from '../../../../Requests/Customer/SearchCustomerReq';
const RescheduleIssue = () => {
    const { id } = useParams();
    const location  = useLocation();
    // const [issueDetails, setIssueDetails] = useState(null);
    const  [customerDetails,setCustomerDetails] = useState(null);
    const [NewSelectedDateFrom, setNewSelectedDateFrom] = useState(null);
    const [NewSelectedDateTo, setNewSelectedDateTo] = useState(null);
    const [NewAssignDateFrom, setNewAssignDateFrom] = useState(null);
    const [NewAssignDateTo, setNewAssignDateTo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [phone, setPhone] = useState("");
    // const navigate = useNavigate();

    useEffect(() => {
        
        const fetchCustomerDetails = async () =>{
            const req = new SearchCustomerReq();
            req.id = location.state.customerId;
            req.pagingParams.pageNumber = 1;
            req.pagingParams.pageSize = 1;
            const res = await CustomerApi.SearchFidiumCustomers(req);
            if(res.status.success){
                setCustomerDetails(res.data.list[0]);
                setPhone(res.data.list[0].cellPhone);
                setIsLoading(false);
            }
        }
       
        if(location?.state?.customerId){
            fetchCustomerDetails();
        }


    }, [location?.state?.customerId, setIsLoading]);

    const handleForceResolve = async () => {
        setIsLoading(true);
        let req = new StringIntReq();
        req.string = "Reschedule";
        req.int = customerDetails.id;
        const res = await CustomerApi.ForceResolveIssue(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Issue Resolved",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    window.location.reload();
                }
                
            });
        } else {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        }
    };

    const handleConfirmReschedule = async () => {
        setIsLoading(true);
        
        if(!NewAssignDateFrom || !NewAssignDateTo){
            Swal.fire({
                icon: "error",
                title: "Please select a date range",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            return
        }

        const req = new UpdateCustomerInstallDateReq();
        
        req.customerId = customerDetails.id;
        req.installDateTime = NewSelectedDateFrom;
        req.installDateTimeTo = NewSelectedDateTo;
        req.rescheduleFormId = null;
        if(NewAssignDateFrom) {
            req.installDateTime = NewAssignDateFrom;
        }
        if(NewAssignDateTo) {
            req.installDateTimeTo = NewAssignDateTo;
        }
        const res = await CustomerApi.EditCustomerInstallDate(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Reschedule Confirmed",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    // window.location.reload();
                }
            });
        } else {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    window.location.reload();
                }
            });
        }
    };

    return (
            <Layout>
                {isLoading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "100%",
                    height: "100%"
                }}>
                    <CircularProgress size={50} sx={{
                        color: "var(--primary-color)"
                    }} />
                </div>
            :
            <div className='reschedule_form_container'>
            <h1 className='title'>Reschedule Issue</h1>
            <div className='inputs'>
            <div className='inputDiv'>
                <label className='phoneLabel' htmlFor="payless">Customer Name</label>
                    <div className='inputWrapper'>
                            <input type="text" value={customerDetails?.firstName + " " + customerDetails?.lastName}  disabled={true} />
                    </div>
                </div>

                <label className='inputLabel' htmlFor="phoneNumber"> Phone Number</label>
                <PhoneInput
                country={'us'}
                value={phone}
                onChange={setPhone}
                excludeCountries={['il']}
                style={{ width: "100%" }}
                inputStyle={{ width: "100%" }}
                disabled={true}
                />

            </div>

            {customerDetails?.originallInstallDateTimeFrom &&
            <div className='inputs'>
            <label className='inputLabel' htmlFor="rescheduleDate">Original Date From</label>
            <input
                className='datePicker'
                type='datetime-local'
                value={customerDetails?.originallInstallDateTimeFrom}
                disabled={true}
            />
            </div>
            }

            {customerDetails?.originalIntstallDateTimeTo &&
            <div className='inputs'>
            <label className='inputLabel' htmlFor="rescheduleDate">Original Date To</label>
            <input
                className='datePicker'
                type='datetime-local'
                value={customerDetails?.originalIntstallDateTimeTo}
                disabled={true}
            />
            </div>
            }

            
            {customerDetails?.updatedInstallDateTimeFrom &&
            <div className='inputs'>
            <label className='inputLabel' htmlFor="rescheduleDate">Last Updated Date From</label>
            <input
                className='datePicker'
                type='datetime-local'
                value={customerDetails?.updatedInstallDateTimeFrom}
                disabled={true}
            />
            </div>
            }

            {customerDetails?.updatedInstallDateTimeTo &&
            <div className='inputs'>
            <label className='inputLabel' htmlFor="rescheduleDate">Last Updated Date To</label>
            <input
                className='datePicker'
                type='datetime-local'
                value={customerDetails?.updatedInstallDateTimeTo}
                disabled={true}
            />
            </div>
            }

            {/* {
                <div className='inputs' style={{ marginTop: "2rem" }}>
                <label className='inputLabel' htmlFor="rescheduleDate">Customer's New Desired Date From</label>
                <input
                    className='datePicker'
                    type='datetime-local'
                    value={NewSelectedDateFrom}
                    disabled={true}
                />
                </div>
            } */}

            {/* {
                <div className='inputs' style={{ marginTop: "2rem" }}>
                <label className='inputLabel' htmlFor="rescheduleDate">Customer's New Desired Date To</label>
                <input
                    className='datePicker'
                    type='datetime-local'
                    value={NewSelectedDateTo}
                    disabled={true}
                />
                </div>
            } */}


            {

                <div className='inputs' style={{ marginTop: "2rem" }}>
                <label className='inputLabel' htmlFor="rescheduleDate">Assign a New Date From</label>
                <input
                    className='datePicker'
                    type='datetime-local'
                    value={NewAssignDateFrom}
                    onChange={(e) => setNewAssignDateFrom(e.target.value)}
                    />
                </div>
            }

            {

            <div className='inputs' style={{ marginTop: "2rem" }}>
            <label className='inputLabel' htmlFor="rescheduleDate">Assign a New Date To </label>
            <input
                className='datePicker'
                type='datetime-local'
                value={NewAssignDateTo}
                onChange={(e) => setNewAssignDateTo(e.target.value)}
                />
            </div>
            }

            {/* {issueDetails?.isResolved && (
                    <div className='inputs'>
                        <label className='inputLabel' htmlFor="rescheduleDate">New Assigned Date From</label>
                        <input
                        className='datePicker'
                        value={new Date(issueDetails.fidiumCustomer.installDateTime).toLocaleString()}
                        aria-disabled={true}
                        placeholder='Select Installation Date'
                        disabled={true}
                        />
                    </div>
            )}
            {issueDetails?.isResolved && (
                    <div className='inputs'>
                        <label className='inputLabel' htmlFor="rescheduleDate">New Assigned Date To</label>
                        <input
                        id="createdAtFrom"
                        className='datePicker'
                        value={new Date(issueDetails.fidiumCustomer.updatedInstallDateTimeTo).toLocaleString()}
                            aria-disabled={true}
                            placeholder='Select Installation Date'
                            disabled={true}
                            />
                    </div>
            )} */}
            {(
                <>
                <button style={{ backgroundColor: "#2761D0" }} className='submitBtn' onClick={handleConfirmReschedule}>Confirm Reschedule</button>
                {/* <button style={{ backgroundColor: "#d32f2f" }} className='submitBtn' onClick={handleForceResolve}>Force Resolve</button> */}
                </>
            )}
            </div>
    }
        </Layout>
    );
};

export default RescheduleIssue;
