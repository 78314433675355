import React, { useContext } from 'react'
// import SearchMapIconReq from '../../../../Requests/MapIcon/SearchMapIconReq'
// import MapIconApi from '../../../../API/MapIconApi'
import { CircularProgress } from '@mui/material'
import { MapContext } from '..'

const Legends = () => {
    // const [loading, setLoading] = React.useState(false)
    const map = useContext(MapContext)
    return (
        <div className='legend_container'>
            <h1>Lead Types</h1>
            {
                map.mapIcons.length > 0 ?
                    <div className='map_icons'>
                        {map.mapIcons.map((icon, index) => {
                            return (
                                <div className='icon' key={index}>
                                    <img alt='map_icon' style={{
                                        width:"2rem",
                                        height:"2rem"
                                    }} src={"data:image/png;base64," + icon.image} />
                                    <p>{icon.name}</p>
                                </div>
                            )
                        })}

                    </div>
                    :
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%"

                    }}>
                        <CircularProgress size={50} sx={{
                            color: "var(--primary-color)"
                        }} />
                    </div>
            }
        </div>
    )
}

export default Legends