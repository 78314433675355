class DeleteCustomerReq{
        constructor(){
                this.string = null;
                this.int = null;
                this.pagingParams = {
                    pageNumber: null,
                    pageSize: null
                };
        }
}

export default DeleteCustomerReq