class CreateOfferTemplateReq{
    constructor(){
        this.id = null;
        this.name = null;
        this.discount = null;
        this.bulletPoints = null;
        this.promos = null;
        this.fiberCompanyId = null;
    }
}

export default CreateOfferTemplateReq