class LightAcceptOfferReq {
    constructor() {
        this.leadId = null;
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phoneNumber = null;
        this.salesRepId = null;
        this.companyId = null;
        this.planId = null;
        this.signedUpBy = null;
        this.offerId = null;
    }
}

export default LightAcceptOfferReq;