import React from 'react'
import Layout from '../../../Layouts/Layout'
import './MyLightningPlan.scss'
import { UserContext } from '../../../App'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
const Index = () => {
    const user = useContext(UserContext)
    const navigate = useNavigate();
    const logoBase64 = user?.fidiumCustomer?.fiberCompany?.logo;
    console.log(user)

    const accounStatusMappingForCustomer = (status) => {
        switch (status){
            case 'Sold':
                return 'Bought'
            case 'Scheduled':
                return 'Scheduled'
            case 'Rescheduled':
                return 'Scheduled'
            case 'Completed':
                return 'Installed'
            case 'Cancelled':
                return "You've cancelled your installation"
            default:
                return status
        }
    }


  return (
    <Layout>
            <div className='MyLightningPlan'>
                <div className='logo'>
                    <img alt='Logo' src='/images/nav/nav-logo.png' />
                </div>
                <div className='plan-body'>
                    <div className='my-connection'>
                        <p>My Connection</p>
                        <div className='company-logo'>
                        <div className='company-logo'>
                            {logoBase64 ? (
                                <img alt='logo' src={`data:image/png;base64,${logoBase64}`} />
                            ) : (
                                <img alt='Fidium' src='/images/Placeholder/alogo.png' />
                            )}
                            </div>
                        </div>
                    </div>
                    <div className='my-plan'>
                        <p>My Plan</p>
                        <div className='plan'>
                            <p>{user?.fidiumCustomer?.fiberPlanString}</p>
                        </div>
                    </div>
                    <div className='my-plan'>
                        <p>Account Status</p>
                        <div className='plan'>
                            <p>{accounStatusMappingForCustomer(user?.fidiumCustomer?.accountStatus)}</p>
                        </div>
                    </div>
                    {user?.fidiumCustomer?.installDateTime != null &&
                    <div className='my-plan'>
                        <p>Install Date</p>
                        <div className='plan'>
                            <p style={{textAlign: 'center'}}>{new Date(user?.fidiumCustomer?.installDateTime).toLocaleString()}</p>
                        </div>
                    </div>
                    }
                    {user?.fidiumCustomer?.issueType === 'Reschedule' &&
                        <div className='my-plan'>
                        <p>Reschedule Status</p>
                        <div className='plan'>
                            <p style={{textAlign: 'center'}}>Pending</p>
                        </div>
                        </div>
                    }
                    

                    {user?.fidiumCustomer?.issueType !== 'Reschedule' && user?.fidiumCustomer?.installDateTime ? (
                        user?.phoneNumber ? (
                            <button onClick={() => {navigate(`/rescheduleform/${user?.phoneNumber}`)}}>Reschedule Install</button>
                        ) : (
                            <button onClick={() => {navigate(`/rescheduleform/`)}}>Reschedule Install</button>
                        )
                    ) : null}


                </div>  
            </div>  
    </Layout>
  )
}

export default Index