class CreateJobApplicationReq{
    constructor(){
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = "";
        this.address = "";
        this.message = "";
        this.file = null;
        this.notes = "";
    }
}

export default CreateJobApplicationReq;