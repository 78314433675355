import React, { useEffect, useState } from 'react'
import { toLonLat } from 'ol/proj'
import Swal from 'sweetalert2'
import { MapContext } from '..'
import FiberHouseApi from '../../../../API/FiberHouseApi'
import { UserContext } from '../../../../App'
import MapHelper from '../MapHelper'
import { Icon, Style } from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Point } from 'ol/geom'
import { Feature } from 'ol'
import CreateCandidateLeadReq from '../../../../Requests/FiberHouse/CreateCandidateLeadReq'
import PhoneInput from 'react-phone-input-2'

const CreateCandidate = () => {
    const user = React.useContext(UserContext)
    const { mapObject, mapIcons, setCandidates, setIndex } = React.useContext(MapContext)
    const helper = new MapHelper(mapObject)

    const [address, setAddress] = useState("")
    const [selectedMapIcon, setSelectedMapIcon] = useState(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    // New state variables for form fields
    const [name, setName] = useState(null)
    const [personalEmail, setPersonalEmail] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [companyName, setCompanyName] = useState(null)
    const [positionTitle, setPositionTitle] = useState(null)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        return () => {
            if (mapObject) {
                const layers = mapObject.getLayers()
                layers.forEach((layer) => {
                    if (layer?.get('name') === 'markerLayer') {
                        mapObject.removeLayer(layer)
                    }
                })
            }
        }
    }, [mapObject])

    const createCandidate = (add, coor, assignedMapIcon, vectorLayer) => {
        if (!address && !add) {
            showError('Address is required', 'Please enter Address')
            return
        }

        if (!selectedMapIcon) {
            showError('Map Icon is required', 'Please select a Map Icon')
            return
        }

        if (!areAllFieldsFilled()) {
            showError('All fields are required', 'Please fill in all the fields')
            return
        }

        const req = new CreateCandidateLeadReq()
        req.mapString = add ? add : address
        req.coordinate.latitude = coor[1]
        req.coordinate.longitude = coor[0]
        req.mapIconId = assignedMapIcon.id
        req.name = name
        req.personalEmail = personalEmail
        req.phone = phoneNumber
        if (selectedMapIcon.iconType === "Company") {
            req.company = companyName
            req.title = positionTitle
        }

        console.log(req)

        FiberHouseApi.CreateCandidate(req).then((res) => {
            if (res?.status?.success) {
                console.log(res)
                mapObject.removeLayer(vectorLayer)
                setCandidates((prev) => [...prev, res.data])
                resetForm()
            }
        })
    }

    const displayDraggableMapIcon = async (coordinates) => {

        if(!areAllFieldsFilled()) {
            Swal.fire({
                icon: 'error',
                title: 'All fields are required',
                text: 'Please fill in all the fields before placing a marker on the map',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if (mapObject && selectedMapIcon) {
            const feature = new Feature({
                geometry: new Point(coordinates)
            })

            const vectorSource = new VectorSource({
                features: [feature]
            })

            const vectorLayer = new VectorLayer({
                source: vectorSource,
                name: 'markerLayer'
            })

            vectorLayer.getSource().getFeatures()[0].setStyle(new Style({
                image: new Icon({
                    src: "data:image/png;base64," + selectedMapIcon.image,
                    scale: 0.5,
                    anchor: [0.5, 1],
                })
            }))

            mapObject.addLayer(vectorLayer)

            const res = await helper.getLocationName(toLonLat(coordinates))
            createCandidate(res, toLonLat(coordinates), selectedMapIcon, vectorLayer)
            setAddress(res)
        }
    }

    useEffect(() => {
        async function displayMarkerOnMap(event) {
            if (areAllFieldsFilled()) {
                const coordinates = event.coordinate
                displayDraggableMapIcon(coordinates)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    text: 'Please fill in all the fields before placing a marker on the map',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            }
        }

        if (selectedMapIcon) {
            mapObject.on('click', displayMarkerOnMap)
        }

        return () => {
            mapObject.un('click', displayMarkerOnMap)
        }
    }, [selectedMapIcon, name, personalEmail, phoneNumber, companyName, positionTitle, mapObject])

    const showError = (title, text) => {
        Swal.fire({
            icon: 'error',
            title,
            text,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    }

    const areAllFieldsFilled = () => {
        if (selectedMapIcon?.iconType === "Recruit") {
            return name && personalEmail && phoneNumber
        } else if (selectedMapIcon?.iconType === "Company") {
            return name && personalEmail && phoneNumber && companyName && positionTitle
        }
        return false
    }

    const resetForm = () => {
        setName("")
        setPersonalEmail("")
        setPhoneNumber("")
        setCompanyName("")
        setPositionTitle("")
        setSelectedMapIcon(null)
    }

    const style = {
        borderRadius: screenWidth < 1000 ? "50%" : "0",
        padding: screenWidth < 1000 ? "0.2rem" : "0",
    }

    return (
        <div className='CreateFiberHouse'>
            {screenWidth > 1000 && (
                <div className='TitleDiv'>
                    <h3 className='text-4xl font-bold'>Create Candidate</h3>
                    {user.userType === "SuperAdmin" && <button onClick={() => setIndex(6)} className='uppercase text-[#2761D0] '>Create Using CSV</button>}
                </div>
            )}

            <div style={{
                overflow: "auto",
                width: "100%",
                margin: "0rem"
            }} className='MapIconDisplay'>
                {
                    mapIcons
                        .filter(icon => icon.iconType === "Recruit" || icon.iconType === "Company")
                        .map((assignedMapIcon) => (
                            <div
                                key={assignedMapIcon.id}
                                className={`MapIconDiv`}
                                style={{
                                    border: selectedMapIcon?.id === assignedMapIcon.id ? screenWidth > 1000 ? "2px solid #2761D0" : "none" : "none",
                                    cursor: 'pointer'
                                }}
                                onClick={() => setSelectedMapIcon(assignedMapIcon)}
                            >
                                <img
                                    style={{
                                        border: screenWidth < 1000 && selectedMapIcon?.id === assignedMapIcon.id ? "4px solid #2761D0" : "none",
                                        ...style
                                    }}
                                    src={"data:image/png;base64," + assignedMapIcon.image}
                                    alt="Map Icon"
                                />
                                <div>{assignedMapIcon.abbreviation}</div>
                            </div>
                        ))
                }
            </div>

            {selectedMapIcon && (
                <div className="CreateSingleFiberHouseForm">
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        type="email"
                        placeholder="Personal Email"
                        value={personalEmail}
                        onChange={(e) => setPersonalEmail(e.target.value)}
                    />
                        <PhoneInput
                            type="tel"
                            containerStyle={{
                                width: '100%',
                            }} inputStyle={{
                                paddingLeft: '50px',
                                width: '100%',
                            }}
                            className="phone-input-container"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e)}
                        />
                    {selectedMapIcon.iconType === "Company" && (
                        <>
                            <input
                                type="text"
                                placeholder="Company Name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Position Title"
                                value={positionTitle}
                                onChange={(e) => setPositionTitle(e.target.value)}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default CreateCandidate