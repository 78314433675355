import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CreateUsingFile from './CreateUsingCSV';
import CreateCandidateLead from './CreateCandidateLead';

const TabbedCreateLead = ({ user }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [leadType, setLeadType] = useState('Recruit');
  const [file, setFile] = useState(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleLeadTypeChange = (event) => {
    setLeadType(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="create lead tabs">
          <Tab label="Fiber Houses" />
          <Tab label="Candidates" />
        </Tabs>
      </Box>
      <Box role="tabpanel" hidden={activeTab !== 0}>
        {activeTab === 0 && <CreateUsingFile user={user} />}
      </Box>
      <Box role="tabpanel" hidden={activeTab !== 1}>
        {activeTab === 1 && (
          <CreateCandidateLead />
        )}
      </Box>
    </Box>
  );
};

export default TabbedCreateLead;