import Swal from "sweetalert2"
import MessagingApi from "../API/MessagingApi"
import CreateConversationReq from "../Requests/Messaging/CreateConversationReq"
class ContactButtons {
    constructor(_currentUser) {
        this.currentUser = _currentUser
    }
    directionsLead = (lead) => {
        if(lead.fiberHouse?.coordinates == null){
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates',
                text: 'We do not have location data for this lead',
            })
            return
        }
        let lonlat = [lead.fiberHouse.coordinates.longitude, lead.fiberHouse.coordinates.latitude]
        let url = "https://www.google.com/maps/search/?api=1&query=" + lonlat[1] + "," + lonlat[0]
        window.open(url, "_blank")
    }

    directions = (coordinates) => {
        if(coordinates == null){
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates',
                text: 'We do not have location data for this lead',
            })
            return
        }
        let lonlat = [coordinates.longitude, coordinates.latitude]
        let url = "https://www.google.com/maps/search/?api=1&query=" + lonlat[1] + "," + lonlat[0]
        window.open(url, "_blank")
    }


    callLead = (lead) => {
        if(lead.fidiumCustomer?.cellPhone != null){
            // window.open("tel:" + lead.fidiumCustomer.cellPhone)
            window.location.href = "tel:" + lead.fidiumCustomer.cellPhone
        }
        else if(lead.phone != null){
            // window.open("tel:" + lead.phone)
            window.location.href = "tel:" + lead.phone
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Phone',
                text: 'We do not have a phone number for this lead',
            })
        }
    }

    call = (phone) => {
        if(phone != null){
            // window.open("tel:" + phone)
            window.location.href = "tel:" + phone
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Phone',
                text: 'We do not have a phone number for this lead',
            })
        }
    }

    emailLead = (lead) => {
        if(lead.fidiumCustomer?.email != null){
            // window.open("mailto:" + lead.fidiumCustomer.email)
            window.location.href = "mailto:" + lead.fidiumCustomer.email
        }
        else if(lead.email != null){
            // window.open("mailto:" + lead.email)
            window.location.href = "mailto:" + lead.email
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Email',
                text: 'We do not have an email for this lead',
            })
        }
    }

    email = (email) => {
        if(email != null){
            // window.open("mailto:" + email)
            window.location.href = "mailto:" + email
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Email',
                text: 'We do not have an email for this lead',
            })
        }
    }

    smsLead = (lead) => {
        if(lead.fidiumCustomer?.cellPhone != null){
            // window.open("sms:" + lead.fidiumCustomer.cellPhone)
            window.location.href = "sms:" + lead.fidiumCustomer.cellPhone
        }
        else if(lead.phone != null){
            // window.open("sms:" + lead.phone)
            window.location.href = "sms:" + lead.phone
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Phone',
                text: 'We do not have a phone number for this lead',
            })
        }
    }

    sms = (phone,msg) => {
        
         if(phone != null){
            // window.open("sms:" + phone)
            window.location.href = "sms:" + phone
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Phone',
                text: 'We do not have a phone number for this lead',
            })
        }
    }

    whatsappLead = (lead) => {
        if(lead.fidiumCustomer?.cellPhone != null){
            window.open("https://wa.me/" + lead.fidiumCustomer.cellPhone)
        }
        else if(lead.phone != null){
            window.open("https://wa.me/" + lead.phone)
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Phone',
                text: 'We do not have a phone number for this lead',
            })
        }
    }

    whatsapp = (phone) => {
        if(phone != null){
            window.open("https://wa.me/" + phone)
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'No Phone',
                text: 'We do not have a phone number for this lead',
            })
        }
    }

    lightningMessageLead = async (lead) => {
        // console.log(lead)
        // console.log(this.currentUser)
        if(this.currentUser == null){
            Swal.fire({
                icon: 'error',
                title: 'No User',
                text: 'User is not logged in',
            })
            return
        }
        if(lead?.fidiumCustomer == null){
            Swal.fire({
                icon: 'error',
                title: 'No User',                
                text: 'This lead does not have a user account',
            })
            return
        }
        if(lead.fidiumCustomer?.user == null){
            Swal.fire({
                icon: 'error',
                title: 'No User',                
                text: 'This lead does not have a user account',

            })
            return
        }
        Swal.fire({
            icon: 'info',
            title: 'Creating/Loading Conversation',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        let req = new CreateConversationReq()
        req.salesOrgId = this.currentUser.salesOrgId
        req.creatorId = this.currentUser.id
        req.conversationType = "CustomerService"
        req.name = "Customer Service for " + lead.fidiumCustomer.user.firstName + " " + lead.fidiumCustomer.user.lastName
        req.userIds = [this.currentUser.id, lead.fidiumCustomer.user.id]

        let res = await MessagingApi.CreateConversation(req)
        // console.log(res)
        Swal.close()

        if(res?.status?.success){
            return res.data
        }
        else if(res?.status?.message === "Conversation already exists"){
            return res.data
        }
        else{
            return null
        }
    }

    lightningMessage = async (user) => {
        // console.log(this.currentUser)
        if(this.currentUser == null){
            Swal.fire({
                icon: 'error',
                title: 'No User',
                text: 'User is not logged in',
            })
            return
        }
        if(user == null){
            Swal.fire({
                icon: 'error',
                title: 'No User', 
            })
            return
        }

        Swal.fire({
            icon: 'info',
            title: 'Creating/Loading Conversation',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        let req = new CreateConversationReq()
        req.salesOrgId = this.currentUser.salesOrgId
        req.creatorId = this.currentUser.id
        req.conversationType = "Private"
        req.name = this.currentUser.firstName + " " + this.currentUser.lastName + " || " + user.firstName + " " + user.lastName
        req.userIds = [this.currentUser.id, user.id]

        let res = await MessagingApi.CreateConversation(req)

        Swal.close()

        // console.log(res)
        if(res?.status?.success){
            return res.data
        }
        else if(res?.status?.message === "Conversation already exists"){
            return res.data
        }
        else{
            return null
        }
    }
}
export default ContactButtons;