import React, { useEffect } from 'react'
import PublicLayout from './../../../Layouts/PublicLayout'
import './OurServices.scss'
import { IoIosCheckmarkCircle } from "react-icons/io";
import map from './images/map.png'
// import tv_streaming from './images/tv_streaming.png'
// import hbo from './images/hbo.png'
// import showtime from './images/showtime.png'
// import starz from './images/starz.png'
// import cinemax from './images/cinemax.png'
// import espn from './images/espn.png'
// import nfl from './images/nfl.png'
// import player from './images/player.png'
// import nhl from './images/nhl.png'
// import abc from './images/abc.png'
// import cbs from './images/cbs.png'
// import fox from './images/fox.png'
// import nbc from './images/nbc.png'
import phone from './images/phone.png'
import left_bot from './images/askbotleft.png'
import right_bot from './images/askbotright.png'
import package_light from './images/package_light.png'
import { useLocation } from 'react-router-dom';
// import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2'
import CustomerApi from '../../../API/CustomerApi';



const searchLocation = async (address) => {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;

    try {
        const response = await fetch(geocodingUrl);
        const data = await response.json();

        if (response.status === 200 && data.status === "OK") {
            return data;
        } else {
            // console.log(data.error_message)
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};


const Index = () => {

    const services = ['Free Installation', 'Unlimited - No Data Caps', 'No Contracts - Month to Month', '30 Day - Risk Free Trial']
    const tvStreaming = ['No Contracts', 'No Credit Check', 'No Hidden Fees', 'Works On Any TV - Any Device']
    const [selectedPlan, setSelectedPlan] = React.useState(1);
    const [selectedTab, setSelectedTab] = React.useState(2);
    const location = useLocation();
    const [search, setSearch] = React.useState("");


    // check availability states
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [address, setAddress] = React.useState({});
    const [cellphone, setCellphone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [consentChecked, setConsentChecked] = React.useState(false)
    const [locationOptions, setLocationOptions] = React.useState([])
    const checkAvailability = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Submitting Check Availability Request",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!firstName) {
            Swal.fire({
                icon: "error",
                title: "First Name is Required",
                text: "Please enter your first name",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lastName) {
            Swal.fire({
                icon: "error",
                title: "Last Name is Required",
                text: "Please enter your last name",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!address?.value) {
            Swal.fire({
                icon: "error",
                title: "Address is Required",
                text: "Please enter your address",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!cellphone) {
            Swal.fire({
                icon: "error",
                title: "Cellphone is Required",
                text: "Please enter your cellphone",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!email) {
            Swal.fire({
                icon: "error",
                title: "Email is Required",
                text: "Please enter your email",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        var pattern = new RegExp(/^\S+@\S+\.\S+$/);
        if (!pattern.test(email)) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Email',
            text: 'Please enter a valid email address',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
          return;
        }

        if (!consentChecked) {
            Swal.fire({
                icon: "error",
                title: "Consent is Required",
                text: "Please agree to the terms and conditions",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new FormData();
        req.append("firstName", firstName)
        req.append("lastName", lastName)
        if (address.value) {
            req.append("address", address.value)
        }
        else {
            req.append("address", search)
        }
        req.append("phone", cellphone)
        req.append("email", email)
        req.append('availabilityType', selectedTab == 1 ? "Business" : "Home")
        req.append('consentChecked', consentChecked)
        req.append('notes', '123')
        if (address.coordinates) {
            req.append('coordinates.latitude', address.coordinates.latitude)
            req.append('coordinates.longitude', address.coordinates.longitude)
        }
        else {
            const result = await searchLocation(search)
            if (result.results.length > 0) {
                req.append('coordinates.latitude', result.results[0].geometry.location.lat)
                req.append('coordinates.longitude', result.results[0].geometry.location.lng)
            }
        }
        setFirstName("");
        setLastName("");
        setCellphone("");
        setEmail("");
        setConsentChecked(false);
        const res = await CustomerApi.CreateAvailabilityCheck(req)

        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Check Availability Request Submitted",
                text: "Our team will contact you soon",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    async function getLocation() {
        if (search === "") {
            return
        }

        const data = await searchLocation(search)
        return data.results.map((item) => {
            return { value: item.formatted_address, label: item.formatted_address }
        })
    }

    async function getOptions() {
        const options = await getLocation()
        // console.log(options)
        setLocationOptions(options)
    }

    useEffect(
        () => {
            const timer = setTimeout(getOptions, 1000);
            return () => clearTimeout(timer);
        }, [search]
    )

    useEffect(() => {
        if (location?.state?.redirect) {
            const element = document.getElementById(location.state.redirect);
            element.scrollIntoView({ behavior: "instant" });
        }
    }, [])
    return (
        <PublicLayout>
            <div className='our_services_container'>
                <h1 >Explore Lightning<br />Fiber Plans</h1>
                <div className='services_banner'>
                    <div className='banner_top'>
                        <img src={package_light} className='package_light' alt='package_light' />
                        <div onClick={() => { setSelectedPlan(1) }} className={`banner_card ${selectedPlan == 1 && "active"}`}>
                            {selectedPlan == 1 && <IoIosCheckmarkCircle style={{
                                position: 'absolute',
                                top: '-5%',
                                right: '-5%'
                            }} size={40} color='#2761D0' />}
                            <p>Supreme</p>
                            <h2>1 Gig</h2>
                            <h4>$65/mo*</h4>
                        </div>
                        <div onClick={() => { setSelectedPlan(2) }} className={`banner_card ${selectedPlan == 2 && "active"}`}>
                            {selectedPlan == 2 && <IoIosCheckmarkCircle style={{
                                position: 'absolute',
                                top: '-5%',
                                right: '-5%'
                            }} size={40} color='#2761D0' />}
                            <p>Futuristic</p>
                            <h2>2 Gig</h2>
                            <h4>$85/mo*</h4>
                        </div>
                    </div>
                    <div className='custom_border'>

                    </div>
                    <div className='banner_bottom'>
                        {
                            services.map((service, index) => {
                                return (
                                    <div key={index} className='service_card'>
                                        <IoIosCheckmarkCircle size={20} color='#2761D0' />
                                        <p>{service}</p>
                                    </div>
                                )
                            })
                        }
                        <div className='service_card'>
                            <p style={{
                                fontSize:"1rem"
                            }}><span style={{
                                fontWeight: 'bold',
                                fontSize: '1.2rem'
                            }}>*</span> Offer may vary depending on your location.</p>
                        </div>
                    </div>
                </div>
                <div style={{
                    backgroundImage: `url(${map})`
                }} id="check_availability" className='availability_form'>
                    <div className='form'>
                        <h1>Check Availability</h1>
                        <div className='tabs'>
                            <button onClick={() => { setSelectedTab(2) }} className={`${selectedTab == 2 && 'active'}`}>FOR HOME</button>
                            <button onClick={() => { setSelectedTab(1) }} className={`${selectedTab == 1 && 'active'}`}>FOR BUSINESS</button>
                        </div>
                        <div className='input_container'>
                            <input value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type='text' placeholder='First Name' />
                            <input value={lastName} onChange={(e) => { setLastName(e.target.value) }} type='text' placeholder='Last Name' />
                            {/* <input type='text' placeholder='Address' /> */}
                            {/* <AsyncSelect
                                placeholder='Service Address'
                                loadOptions={getLocation}
                                defaultOptions
                                onInputChange={setSearch}
                                onChange={setAddress}
                                noOptionsMessage={() => null} // Add this line
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        outline: 'none',
                                        boxShadow: 'none',
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        textAlign: 'center',
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        textAlign: 'center',
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        textAlign: 'center',
                                        padding: "0.5rem 1rem",
                                        margin: "auto"
                                    }),
                                }}
                            /> */}
                            <div className='custom_address_selector'>
                                <input type='text' value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='Service Address' />
                                {locationOptions && locationOptions.length > 0 &&
                                    <div className='location_options'>
                                        {
                                            locationOptions?.map((option, index) => {
                                                return (
                                                    <div key={index} onClick={() => { setAddress(option); setSearch(option.value); setLocationOptions([]) }} className='option'>
                                                        <p>{option.label}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>}
                            </div>
                            <input value={cellphone} maxLength={10} onChange={(e) => { setCellphone(e.target.value) }} type='text' placeholder='Cellphone' />
                            <input value={email} onChange={(e) => { setEmail(e.target.value) }} type='email' placeholder='Email' />
                            <div className='consent'>
                                <input value={consentChecked} onChange={(e) => { setConsentChecked(e.target.checked) }} type='checkbox' id='consent' />
                                <label htmlFor='consent'>By signing up, you agree to receive emails and text messages from Lightning OS, in regards to our offers.</label>
                            </div>
                            <div>
                                <p><span style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem'
                                }}>*</span> Offer may vary depending on your location.</p>
                            </div>

                            <button onClick={() => checkAvailability()} >Check Availability</button>
                        </div>
                    </div>
                </div>
                {/* <div className='tv_streaming'>
                    <h1>Discover Lightning<br />TV Streaming</h1>
                    <img src={tv_streaming} alt='tv_streaming' />
                </div>
                <div id='tv_streaming_package' className='channel_list'>
                    <h1><span>Check Out</span> 5000+ HD<br />Live Streaming Tv <span>Service<br />For Just</span></h1>
                    <h1>$39.95/Mo!</h1>
                    <div className='channel_services'>
                        {
                            tvStreaming.map((service, index) => {
                                return (
                                    <div key={index} className='channel_service_item'>
                                        <IoIosCheckmarkCircle size={20} color='20C877' />
                                        <p>{service}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <h1>All <span>Premium</span> Channels</h1>
                    <div className='channel_icons'>
                        <img src={hbo} alt='hbo' />
                        <img src={showtime} alt='showtime' />
                        <img src={starz} alt='starz' />
                        <img src={cinemax} alt='cinemax' />
                    </div>
                    <p className='more'>1800 + More</p>
                    <h1>All <span>Sports</span> Channels</h1>
                    <div className='channel_icons'>
                        <img src={espn} alt='espn' />
                        <img src={nfl} alt='nfl' />
                        <img src={player} alt='player' />
                        <img src={nhl} alt='nhl' />
                    </div>
                    <p className='more'>1500 + More</p>
                    <h1>All <span>Major Channels</span></h1>
                    <div className='channel_icons'>
                        <img src={abc} alt='abc' />
                        <img src={cbs} alt='cbs' />
                        <img src={nbc} alt='nbc' />
                        <img src={fox} alt='fox' />
                    </div>
                    <p className='more'>1100 + More</p>
                    <h1><span>Kids, Religious, Music,<br /></span> And 3K More Channels</h1>
                    <div className='form'>
                        <h2>Inquire About TV</h2>
                        <input type='text' placeholder='First Name' />
                        <input type='text' placeholder='Last Name' />
                        <input type='text' placeholder='Cellphone' />
                        <input type='text' placeholder='Email' />
                        <button>Get TV Details</button>
                    </div>
                </div> */}
                <div id='phone_service' className='phone_service'>
                    <h1>Experience Lightning<br />Home Phone Service</h1>
                    <div className='phone_banner'>
                        <div className='pic'>
                            <img src={phone} alt='phone' />
                        </div>
                        <div className='custom_border'>

                        </div>
                        <div className='text'>
                            <h3>Add Phone Service<br /><span>$25/Mo</span></h3>
                        </div>
                        <div className='bottom'>
                            <div>
                                <IoIosCheckmarkCircle size={20} color='2761D0' />
                                <p>Unlimited local and long distance.</p>
                            </div>
                            <div>
                                <IoIosCheckmarkCircle size={20} color='2761D0' />
                                <p>Includes local, nationwide and international calls to Canada, U.S. Virgin Islands, Guam, Pureto Rico, and the Mariana Islands.</p>
                            </div>
                            <div>
                                <IoIosCheckmarkCircle size={20} color='2761D0' />
                                <p>Keep your current phone number.</p>
                            </div>
                            <div className=''>
                                <p><span style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem'
                                }}>*</span> Offer may vary depending on your location.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ask_lightning_os'>

                    <img src={left_bot} className='left_bot' alt='left_bot' />

                    <div className='text'>
                        <p>Ask Lightning OS</p>
                        <h1>Not Sure<br />Which<br />Plan Is<br />Right For<br />You?</h1>
                        <button onClick={() => {
                            window.leadConnector.chatWidget.openWidget()
                        }}>Contact Us</button>
                    </div>

                    <img className='right_bot' src={right_bot} alt='right_bot' />

                </div>
            </div>
        </PublicLayout>
    )
}

export default Index