/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SellingCardApi from '../../../API/SellingCardApi';
import CreateFile from '../../../Requests/CloudFile/CreateFileReq';
import RemoveFileReq from '../../../Requests/CloudFile/RemoveFileReq';
import AddFilesToSellingCard from '../../../Requests/SellingCard/AddFilesToSellingCard';
import EditSellingCardReq from '../../../Requests/SellingCard/EditSellingCardReq';
import SoftButton from '../../../components/SoftButton';
import SoftInput from '../../../components/SoftInput';
import OrganizationSelector from './../../../components/OrganizationSelector';

import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

// Import React FilePond
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileGet from 'filepond-plugin-get-file';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import { FilePond, registerPlugin } from 'react-filepond';
import Swal from 'sweetalert2';

import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
import 'filepond/dist/filepond.min.css';
import { UserContext } from '../../../App';
// import Sidebar from '../../../Layouts/Sidebar';
// import MobileLayout from '../../../Layouts/MobileLayout';
import Layout from '../../../Layouts/Layout';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
registerPlugin(FilePondPluginFilePoster)
registerPlugin(FilePondPluginMediaPreview)
registerPlugin(FilePondPluginFileGet);
registerPlugin(FilePondPluginPdfPreview)

const Index = () => {
    const location = useLocation();

    const [editedName, setEditedName] = useState(location?.state?.name);

    const [editedDescription, setEditedDescription] = useState(location?.state?.description);
    const [editedFiles, setEditedFiles] = useState([])
    const [salesOrgId,setSalesOrgId] = useState(location?.state?.salesOrgId);
    const [filesToBeRemoved, setFilesToBeRemoved] = useState([]);
    const [filesToBeAdded, setFilesToBeAdded] = useState([]);
    const storage = getStorage()
    const user = useContext(UserContext)

    async function editShopItem() {
        Swal.fire({
          icon: 'info',
          title: 'Editing Selling Card',
          text: 'Please wait...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        if (!editedName) {
          Swal.fire({
            icon: 'error',
            title: 'Name is required',
            text: 'Please enter Name',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
          return;
        }
    
        if (!editedDescription) {
          Swal.fire({
            icon: 'error',
            title: 'description is required',
            text: 'Please enter description',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
          return;
        }
  
        const req = new EditSellingCardReq();
        req.id = location.state.id;
        req.name = editedName;
        req.salesOrgId = salesOrgId;
        req.description = editedDescription;
        SellingCardApi.EditSellingCard(req).then(
          async (res) => {
            
            if (res?.status?.success) {

              filesToBeRemoved.map(
                (f) => {
                  const fileRef = ref(storage, f.path);
                  deleteObject(fileRef)
                    .then(() => {
                      const req = new RemoveFileReq()
                      req.int = f.id;
                      SellingCardApi.RemoveFile(req).then(
                        (res) => {
                          if (res?.status?.success) {
                            
                          }
                        }
                      )
                    })
                    .catch((error) => {
                      console.error('An error occurred!', error);
                    });
    
                }
              )
              
              const request = new AddFilesToSellingCard();
          let downloadUrls = [];
          let filePaths = [];
          request.id = location.state.id
 
          for (let index = 0; index < filesToBeAdded.length; index++) {
  
              // Function to upload file and get download URL
              const uploadAndGetData = async (file, type) => {
                // Generate a unique ID 
                const uniqueId = uuidv4();

                if (salesOrgId) {
                  const storageRef = ref(storage, `/sellingCards/salesOrg/${salesOrgId}/${user.firebaseId}/${uniqueId}`);
                  await uploadBytes(storageRef, file);
                  // Get download URL and push to downloadURLs array
                  const downloadURL = await getDownloadURL(storageRef);
                  downloadUrls.push(downloadURL);
                  filePaths.push(`/sellingCards/salesOrg/${salesOrgId}/${user.firebaseId}/${uniqueId}`)
                }
                else {
                  const storageRef = ref(storage, `/sellingCards/withOutSalesOrg/${user.firebaseId}/${uniqueId}`);
                  await uploadBytes(storageRef, file);
                  // Get download URL and push to downloadURLs array
                  const downloadURL = await getDownloadURL(storageRef);
                  downloadUrls.push(downloadURL);
                  
                  filePaths.push(`/sellingCards/withOutSalesOrg/${user.firebaseId}/${uniqueId}`)
                }
              };

              await uploadAndGetData(filesToBeAdded[index], "image");
              const createFile = new CreateFile();
              createFile.name = filesToBeAdded[index].name;
              createFile.extension = createFile.name.split('.').pop();
              createFile.url = downloadUrls[0];
              createFile.path = filePaths[0];
              createFile.fileSize = filesToBeAdded[index].size.toString();
              createFile.userId = filesToBeAdded[index].id;
              createFile.sellingCardId = 1;
              createFile.caption = "123";
              createFile.fileType = filesToBeAdded[index].type
              request.files.push(createFile);
            
          }

          SellingCardApi.AddFile(request).then(
            (response)=>{
              if(response.status.success)
              {
                
                Swal.fire({
                  icon: 'success',
                  title: 'Selling Card Edited',
                  text: "Selling Card has been Edited",
                  // allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: false,
      
                });
              }else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: res?.status?.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: true,
                  didOpen: () => {
                    Swal.hideLoading()
                  }
                });
              }
            }
          ) 
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                  Swal.hideLoading()
                }
              });
            }
    
          }
        )
      }
    useEffect(
        () => {
            

            setEditedName(location?.state?.name);
            setEditedDescription(location?.state?.description);
            const newFiles = location?.state?.files?.map(f => ({
              source: f.url,
              options: {
                type: 'local',
                file: {
                  name: f.name,
                  type: f.fileType,
                  size: f.fileSize
                },
                metadata: {
                  poster: f.url,
                }             
              },
      
            })) 
            setEditedFiles(newFiles);

        }, []
    )

    const removeFile = (path, id) => {
      setFilesToBeRemoved(
        (prev) => [...prev, { path: path, id: id }]
      )
  
    }
  
    const addFile = (file) => {
      if(!file.file.id)
      {
        
      setFilesToBeAdded(
        (prev) => [...prev, file.file]
      )
      }
      
    }

    return (
        <Layout>
            <div style={{
                borderRadius: "36px",
                border: "1px solid #E5E5E5",
                background: "#FFF",
                padding: "2rem",
            }}>
                <div style={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column"
                }}>
                    <h3>Edit Form</h3>

                    <div style={{
                        width: "max(40%,200px)",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem"
                    }}>
                        <SoftInput placeholder="Enter the Name" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
                        <SoftInput placeholder="Enter the Description" value={editedDescription} onChange={(e) => setEditedDescription(e.target.value)} />
                  
                        <OrganizationSelector setUser={setSalesOrgId}  />
                        <FilePond
                            files={editedFiles}
                            allowReorder={true}
                            allowMultiple={true}
                            onupdatefiles={setEditedFiles}
                            allowVideoPreview={true}
                            allowPdfPreview= {true}                            allowDownloadByUrl= {true}
                            onremovefile={(error, file) => {
                              if (file.file.path && file.file.id) {
                                removeFile(file.file.path, file.file.id);
                              }
                              else{
                                const filteredFiles = filesToBeAdded.filter(f => f.id !== file.file.id);
                                setFilesToBeAdded(filteredFiles);
                              }
                            }}
                            onaddfile={(error, file) => addFile(file)}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                        <SoftButton onClick={()=>{editShopItem()}} style={{backgroundImage:"linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",color:"white",marginTop:"1rem"}} >Update</SoftButton>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Index