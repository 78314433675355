import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Typography,
    Button,
    TextField,
    Card,
    CardContent,
    Avatar,
    IconButton,
    Divider,
    Pagination,
    CircularProgress,
    Box,
    Grid
} from '@mui/material';
import {
    Email,
    Message,
    Phone,
    LocationOn,
    WhatsApp,
    ContentCopy,
    Map,
    Business,
    Person,
    Send,
    MailOutline,
    GetApp,
    DoorFront
} from '@mui/icons-material';
import './CandidateStats.scss';
import { MapContext as MP } from '..';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import GetCandidatesByIdsReq from '../../../../Requests/FiberHouse/GetCandidatesByIdsReq';
import { filter } from 'jszip';
import { BiBriefcase } from 'react-icons/bi';
import { FcOrganization } from 'react-icons/fc';
import InvitationModal from './CandidateMainLeadCard/InviteForm';
import Swal from 'sweetalert2';
import { useSwipeable } from 'react-swipeable';
import ContactButtons from '../../../../Services/ContactButtons';
import { UserContext } from '../../../../App';
import { MdOutlineDirections } from 'react-icons/md';
import { IoMapOutline } from 'react-icons/io5';
import { fromLonLat } from 'ol/proj';
import ExportData from '../../../../Services/ExportData';

const CandidateStats = ({ candidates }) => {
    const map = useContext(MP);
    const [candidatesState, setCandidatesState] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterMapIconIds, setFilterMapIconIds] = useState([]);
    const [filterLeadTypes, setFilterLeadTypes] = useState(['Recruit', 'Company']);
    const [filterInviteStatus, setFilterInviteStatus] = useState(['sent', 'unsent']);
    const [filterContactInfoStatus, setFilterContactInfoStatus] = useState(['emailOnly', 'phoneOnly', 'both', 'none']);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [candidateType, setCandidateType] = useState('');
    const itemsPerPage = 10;
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    const user = useContext(UserContext);
    const contactButtons = new ContactButtons(user);
    const stopRef = React.useRef(null);
    const refPassthrough = (el) => {
        handlers.ref(el);
        stopRef.current = el;
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwipedDown: (eventData) => {
            eventData.event.stopPropagation();
        },
    })

    useEffect(() => {
        fetchData();
    }, [map.candidateIds]);

    // Fetch the candidate data when component mounts
    const fetchData = async () => {
        setLoading(true);
        try {
            let req = new GetCandidatesByIdsReq();
            req.candidateIds = map.candidateIds;
            console.log(req);
            const candidateResponse = await FiberHouseApi.GetCandidatesByIds(req);

            if (candidateResponse.status.success) {
                const candidates = candidateResponse.data;
                setCandidatesState(candidates);
                setFilteredCandidates(candidates);

                setFilterMapIconIds(map.mapIcons.map((icon) => icon.id));
            } else {
                console.error("Error fetching candidate data:", candidateResponse.status.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        filterCandidates();
    }, [filterMapIconIds, filterLeadTypes, filterInviteStatus, searchTerm, candidatesState, filterContactInfoStatus]);

    const filterCandidates = () => {
        let filtered = candidatesState.filter(candidate => {
            // Map Icon Filtering
            console.log(candidate);
            const mapIconMatch = filterMapIconIds.length === 0 || filterMapIconIds.includes(candidate.mapIconId);

            // Lead Type Filtering
            const leadTypeMatch = filterLeadTypes.length === 0 || filterLeadTypes.includes(candidate.leadType);

            let inviteStatusMatch = true; // Default to true if no filter is applied
            if (filterInviteStatus.includes('sent') && filterInviteStatus.includes('unsent')) {
                inviteStatusMatch = true;
            } else if (filterInviteStatus.includes('sent')) {
                inviteStatusMatch = candidate.candidateInvitation !== null;
            } else if (filterInviteStatus.includes('unsent')) {
                inviteStatusMatch = candidate.candidateInvitation === null;
            }

            let contactInfoMatch = true;
            if (filterContactInfoStatus.length > 0) {
                const hasEmail = !!candidate.personalEmail || !!candidate.companyEmail;
                const hasPhone = !!candidate.phoneNumber;

                if (filterContactInfoStatus.includes('emailOnly')) {
                    contactInfoMatch = hasEmail && !hasPhone;
                } else if (filterContactInfoStatus.includes('phoneOnly')) {
                    contactInfoMatch = hasPhone && !hasEmail;
                } else if (filterContactInfoStatus.includes('both')) {
                    contactInfoMatch = hasEmail && hasPhone;
                } else if (filterContactInfoStatus.includes('none')) {
                    contactInfoMatch = !hasEmail && !hasPhone;
                }
            }

            const searchTermMatch = !searchTerm || (candidate?.name && candidate.name.toLowerCase().includes(searchTerm.toLowerCase()));

            return mapIconMatch && leadTypeMatch && inviteStatusMatch && searchTermMatch && contactInfoMatch;
        });
        console.log(filtered);
        setFilteredCandidates(filtered);
        // if (filtered.length > 0) {
        //     map?.setCandidateIds(filtered.map(candidate => candidate.id));
        // } else {
        //     //map?.setCandidateIds([]); // Clear candidate IDs if no candidates match the filters
        // }
    };

    const toggleMapIcon = (iconId) => {
        setFilterMapIconIds(prev =>
            prev.includes(iconId)
                ? prev.filter(id => id !== iconId)
                : [...prev, iconId]
        );
    };



    const handleSelectAllMapIcons = () => {
        const allMapIcons = [...new Set(candidatesState.map(candidate => candidate.mapIconId).filter(id => id !== null))];
        setFilterMapIconIds(allMapIcons);
    };

    const handleDeselectAllMapIcons = () => {
        setFilterMapIconIds([]);
    };

    const toggleLeadType = (type) => {
        setFilterLeadTypes(prev =>
            prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
        );
    };

    const toggleContactInfoStatus = (status) => {
        setFilterContactInfoStatus(prev =>
            prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
        );
    };

    const toggleInviteStatus = (status) => {
        setFilterInviteStatus(prev =>
            prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
        );
    };

    const exportDataInstance = new ExportData();

    const handleExportLeads = () => {
        exportDataInstance.exportCandidateStatsLeads(filteredCandidates);
    };
    const hasValidEmailCandidates = filteredCandidates.some(candidate => candidate.personalEmail || candidate.companyEmail);
    const hasValidPhoneCandidates = filteredCandidates.some(candidate => candidate.phoneNumber);

    const getMapIconStats = () => {
        const mapIconStats = [];
        const mapIconIdsFromCandidates = [...new Set(candidatesState.map(candidate => candidate.mapIconId).filter(id => id !== null))];

        mapIconIdsFromCandidates.forEach(iconId => {
            const assignedMapIcon = map.mapIcons.find((icon) => icon.id === iconId);
            if (assignedMapIcon) {
                const count = filteredCandidates.filter(candidate => candidate.mapIconId === iconId).length;
                mapIconStats.push({ assignedMapIcons: assignedMapIcon, count });
            }
        });

        return mapIconStats;
    };

    const getRecruitCompanyStats = () => {
        const recruitCompanyStats = { recruit: 0, company: 0 };
        filteredCandidates.forEach((candidate) => {
            console.log(candidate);
            if (candidate.leadType === 'Recruit') {
                recruitCompanyStats.recruit += 1;
            } else if (candidate.leadType === 'Company') {
                recruitCompanyStats.company += 1;
            }
        });

        return recruitCompanyStats;
    };

    const getInviteStatusStats = () => {
        const inviteStats = { sent: 0, unsent: 0 };
        filteredCandidates.forEach((candidate) => {
            console.log(candidate);
            if (candidate.candidateInvitation !== null) {
                inviteStats.sent += 1;
            } else {
                inviteStats.unsent += 1;
            }
        });

        return inviteStats;
    };

    const getContactInfoStats = () => {
        const stats = {
            emailOnly: 0,
            phoneOnly: 0,
            both: 0,
            none: 0
        };

        filteredCandidates.forEach(candidate => {
            const hasEmail = !!candidate.personalEmail || !!candidate.companyEmail;
            const hasPhone = !!candidate.phoneNumber;

            if (hasEmail && hasPhone) {
                stats.both += 1;
            } else if (hasEmail) {
                stats.emailOnly += 1;
            } else if (hasPhone) {
                stats.phoneOnly += 1;
            } else {
                stats.none += 1;
            }
        });

        return stats;
    };

    const snapToLocation = (candidate) => {
        map.candidateClicked(candidate);
        let lonlat = [candidate.coordinates.longitude, candidate.coordinates.latitude]
        map.mapObject.getView().setCenter(fromLonLat(lonlat));
        map.mapObject.getView().setZoom(21);
    }

    useEffect(() => {
        if (map.searchAreaFeature.length > 0) {
            try {
                // Identify candidate IDs that should be removed
                console.log("Filtered Candidates:", filteredCandidates);
                console.log("Candidates on map:", candidatesState);
                const idsToRemove = candidatesState
                    .filter((candidate) => !filteredCandidates.some((fh) => fh.id === candidate.id))
                    .map((candidate) => candidate.id);

                console.log(idsToRemove);
                // Update the map with the candidates
                map.setCandidates(
                    candidates.map((candidate) => {
                        if (idsToRemove.includes(candidate.id)) {
                            console.log("Removing candidate from map:", candidate);
                            return {
                                ...candidate,
                                remove: true,
                            };
                        } else {
                            console.log("Updating candidate on map:", candidate);
                            const fh = filteredCandidates.filter((fh) => fh.id === candidate.id)[0];
                            return {
                                ...candidate,
                                remove: false,
                                selected: fh ? true : false,
                            };
                        }
                    })
                );
            } catch (error) {
                console.error("Error updating candidates on the map:", error);
            }
        }
    }, [filteredCandidates]);

    const contactInfoStats = getContactInfoStats();


    const recruitCompanyStats = getRecruitCompanyStats();
    const inviteStatusStats = getInviteStatusStats();

    const paginatedCandidates = filteredCandidates.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </div>
        );
    }

    const InfoRow = ({ label, value }) => (
        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 0.5 }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold', mr: 0.5, minWidth: '80px' }}>
                {label}:
            </Typography>
            <Typography variant="caption">{value}</Typography>
        </Box>
    );

    //open invitation model
    const openInvitationModel = () => {
        //if recruits and companies aer both selected return swal error
        if (filterLeadTypes.includes('Recruit') && filterLeadTypes.includes('Company')) {
            //show swal

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select only one either Recruits or Companies to send invites',
            });
            return;

        } else {
            if (filterLeadTypes.includes('Recruit')) {
                setCandidateType('Recruit');
            } else {
                setCandidateType('Company');
            }
            setIsInviteOpen(true);
        }


    }



    return (
        <div className="candidate-stats">
                        <div
                style={{ height: window.innerHeight - 260 + 'px', overflowY: 'auto' }}
                ref={refPassthrough}
            >
            <Typography variant="h4" className="title">
                Leads Management & Stats
            </Typography>

            <Typography variant="h6" className="total-leads">
                Total Leads: {filteredCandidates.length}
            </Typography>



                <div className="stats-section">
                    <div className="section-header">
                        <Typography variant="h6">Leads Status</Typography>
                        {/* <div className="select-actions">
                        <Button size="small" onClick={handleSelectAllMapIcons}>Select All</Button>
                        <Button size="small" onClick={handleDeselectAllMapIcons}>Deselect All</Button>
                    </div> */}
                    </div>

                    <div className="map-icons-grid">
                        {getMapIconStats().map((stat, index) => (
                            <div
                                key={index}
                                className={`map-icon-stat ${filterMapIconIds.includes(stat.assignedMapIcons.id) ? 'active' : ''}`}
                                onClick={() => toggleMapIcon(stat.assignedMapIcons.id)}
                                style={{
                                    borderRadius: "10px",
                                    borderWidth: "2px"
                                }}
                            >
                                <img src={"data:image/png;base64," + stat.assignedMapIcons.image} alt="Map Icon" />
                                <Typography variant="body2">{stat.assignedMapIcons.abbreviation}</Typography>
                                <Typography variant="h6" style={{
                                    fontWeight: filterMapIconIds.includes(stat.assignedMapIcons.id) ? "bold" : null
                                }}>
                                    {stat.count}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>

                <Divider className="section-divider" />

                <div className="stats-section">
                    <Typography variant="h6">Recruit & Company Stats</Typography>
                    <div className="type-stats">
                        <div
                            className={`stat-item ${filterLeadTypes.includes('Recruit') ? 'active' : ''}`}
                            onClick={() => toggleLeadType('Recruit')}
                        >
                            <Person fontSize="large" />
                            <Typography variant="body1">Recruits</Typography>
                            <Typography variant="h6">{recruitCompanyStats.recruit}</Typography>
                        </div>
                        <div
                            className={`stat-item ${filterLeadTypes.includes('Company') ? 'active' : ''}`}
                            onClick={() => toggleLeadType('Company')}
                        >
                            <Business fontSize="large" />
                            <Typography variant="body1">Companies</Typography>
                            <Typography variant="h6">{recruitCompanyStats.company}</Typography>
                        </div>
                    </div>
                </div>

                <Divider className="section-divider" />

                <div className="stats-section">
                    <Typography variant="h6">Invite Status</Typography>
                    <div className="invite-stats">
                        <div
                            className={`stat-item ${filterInviteStatus.includes('sent') ? 'active' : ''}`}
                            onClick={() => toggleInviteStatus('sent')}
                        >
                            <Send fontSize="large" />
                            <Typography variant="body1">Sent</Typography>
                            <Typography variant="h6">{inviteStatusStats.sent}</Typography>
                        </div>
                        <div
                            className={`stat-item ${filterInviteStatus.includes('unsent') ? 'active' : ''}`}
                            onClick={() => toggleInviteStatus('unsent')}
                        >
                            <MailOutline fontSize="large" />
                            <Typography variant="body1">Unsent</Typography>
                            <Typography variant="h6">{inviteStatusStats.unsent}</Typography>
                        </div>
                    </div>
                </div>
                <div className="stats-section">
                    <Typography variant="h6">Invite Status</Typography>
                    <div className="invite-stats">
                        <div
                            className={`stat-item ${filterContactInfoStatus.includes('emailOnly') ? 'active' : ''}`}
                            onClick={() => toggleContactInfoStatus('emailOnly')}
                        >
                            <Send fontSize="large" />
                            <Typography variant="body1">Has Email Only</Typography>
                            <Typography variant="h6">{contactInfoStats.emailOnly}</Typography>
                        </div>
                        <div
                            className={`stat-item ${filterContactInfoStatus.includes('phoneOnly') ? 'active' : ''}`}
                            onClick={() => toggleContactInfoStatus('phoneOnly')}
                        >
                            <MailOutline fontSize="large" />
                            <Typography variant="body1">Has Phone Only</Typography>
                            <Typography variant="h6">{contactInfoStats.phoneOnly}</Typography>
                        </div>
                        <div
                            className={`stat-item ${filterContactInfoStatus.includes('both') ? 'active' : ''}`}
                            onClick={() => toggleContactInfoStatus('both')}
                        >
                            <MailOutline fontSize="large" />
                            <Typography variant="body1">Has Both</Typography>
                            <Typography variant="h6">{contactInfoStats.both}</Typography>
                        </div>
                        <div
                            className={`stat-item ${filterContactInfoStatus.includes('none') ? 'active' : ''}`}
                            onClick={() => toggleContactInfoStatus('none')}
                        >
                            <MailOutline fontSize="large" />
                            <Typography variant="body1">Has None</Typography>
                            <Typography variant="h6">{contactInfoStats.none}</Typography>
                        </div>
                    </div>
                </div>

                <InvitationModal open={isInviteOpen} onClose={() => setIsInviteOpen(false)} isStatEmail={true} candidateType={candidateType} filteredCandidateIds={filteredCandidates.map((c) => c.id)} />

                <div className="action-buttons">
                    <Button
                        variant="contained"
                        onClick={() => openInvitationModel()}
                        startIcon={<Email />}
                        className="invite-btn"
                        disabled={!hasValidEmailCandidates}
                    >
                        Send Email Invites
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<Message />}
                        className="invite-btn"
                        disabled={!hasValidPhoneCandidates}
                    >
                        Send SMS Invites
                    </Button>
                </div>

                <Button
                    variant="contained"
                    startIcon={<GetApp />}
                    className="export-btn"
                    onClick={handleExportLeads}
                >
                    Export {filteredCandidates.length} Leads
                </Button>

                <div className="search-section">
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search candidates..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>

                <div className="candidates-list">
                    {paginatedCandidates.map(candidate => (
                        <Card key={candidate.id} className="candidate-card" sx={{ mb: 2 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={8}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <img
                                                src={`data:image/png;base64,${candidate.mapIcon.image}`}
                                                alt="Map Icon"
                                                style={{ width: 24, height: 24, marginRight: 8 }}
                                            />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1 }}>
                                                {candidate.name ? candidate.name : 'No Name'}
                                            </Typography>
                                            {candidate.leadType === "Recruit" ? <BiBriefcase /> : <FcOrganization />}
                                            {candidate.doorToDoor && <DoorFront sx={{ ml: 1, fontSize: 18 }} />}
                                        </Box>
                                        <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                                            {candidate.mapString}
                                        </Typography>
                                        {candidate.personalEmail && (
                                            <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                                                {candidate.personalEmail}
                                            </Typography>
                                        )}
                                        {candidate.leadType === "Company" && (
                                            <Box sx={{ mt: 1 }}>
                                                <InfoRow label="Company" value={candidate.companyName} />
                                                {candidate.industry && <InfoRow label="Industry" value={candidate.industry} />}
                                                {candidate.positionTitle && <InfoRow label="Title" value={candidate.positionTitle} />}
                                                {candidate.companyEmail && <InfoRow label="Company" value={candidate.companyEmail} />}
                                                {candidate.linkedIn && <InfoRow label="LinkedIn" value={candidate.linkedIn} />}
                                            </Box>
                                        )}
                                    </Grid>
                                    <div className="candidate-actions">
                                        <IconButton onClick={() => contactButtons.email(candidate.personalEmail ? candidate.personalEmail : candidate.companyEmail)}>
                                            <Email />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.whatsapp(candidate.phone)}>
                                            <WhatsApp />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.sms(candidate.phone)}>
                                            <Message />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.call(candidate.phone)}>
                                            <Phone />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.directions(candidate.coordinates)}>
                                            <MdOutlineDirections color='#81848A' size={30} />
                                        </IconButton>
                                        <IconButton onClick={() => snapToLocation(candidate)}>
                                            <IoMapOutline color='#81848A' size={30} />
                                        </IconButton>
                                        {/* <IconButton>
                                        <ContentCopy />
                                    </IconButton> */}
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </div>

                <Pagination
                    className="pagination"
                    count={Math.ceil(filteredCandidates.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(e, page) => setCurrentPage(page)}
                />
            </div>
        </div>
    );
};

export default CandidateStats;
