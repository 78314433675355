import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Typography,
    Box,
    CircularProgress,
    Pagination,
    PaginationItem,
    Modal
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import Select from 'react-select';
import { format } from 'date-fns';
import Layout from '../../../Layouts/Layout';
import Datatable from '../../../components/Datatable';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import './ManageCompanyConsultation.scss';
import GetCompanyConsultationsReq from '../../../Requests/FiberCompany/GetCompanyConsultationsReq';
import FiberHouseApi from '../../../API/FiberHouseApi';
import UsersApi from '../../../API/UsersApi';
import CandidateJobApplicationReq from '../../../Requests/Users/CandidateJobApplicationReq';
import { UserContext } from '../../../App';
import CalendlyUserSelection from '../CalendlyUserSelection';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ManageCompanyConsultation = () => {
    const [consultations, setConsultations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [industryOptions, setIndustryOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);
    //initial render
    const initialRender = useRef(true);

    const user = useContext(UserContext);
    const navigate = useNavigate();

    const statusOptions = [
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Approved' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'completed', label: 'Completed' },
    ];

    //getIndustryOptions
    const getIndustryOptions = async () => {
        let res = await FiberHouseApi.GetCandidateIndustries();
        if (res.status.success) {
            setIndustryOptions(res.data);
        }
    }



    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            getIndustryOptions();
        }
        fetchConsultations();
    }, [currentPage, searchTerm, selectedIndustries, selectedStatuses]);

    const fetchConsultations = async () => {
        try {
            setLoading(true);
            let req = new GetCompanyConsultationsReq();
            req.pagingParams.pageNumber = currentPage;
            req.search = searchTerm;
            req.industries = selectedIndustries.map(i => i.value);
            req.statuses = selectedStatuses.map(s => s.value);

            const response = await FiberCompanyApi.GetCompanyConsultations(req);
            if (response.status.success) {
                setConsultations(response.data.list);
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Failed to fetch consultations:', response.status.message);
            }
        } catch (error) {
            console.error('Error fetching consultations:', error);
        } finally {
            setLoading(false);
        }
    };

    const scheduleInterview = (id) => {
        setSelectedCandidateId(id)
        setIsModalOpen(true)
        console.log("Scheduling interview for candidate:", id)
        console.log(isModalOpen)
      }
    

    const handleSetupMeeting = async (candidateId, calendlyLink) => {
        try {
            Swal.fire({
                icon: "info",
                title: "Please Wait",
                text: "Sending Schedule",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                willOpen: () => {
                  Swal.showLoading();
                }
              });
            let req = new CandidateJobApplicationReq()
            req.candidateId = candidateId;
            req.userId = user.id;
            req.scheduleLink = calendlyLink;
            console.log("req", req)
            const response = await UsersApi.SetupConsultationMeeting(req);
            if (response.status.success) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Meeting Setup Sent successfully",
                    showConfirmButton: false,
                    timer: 1500,
                    didOpen: () => {
                      Swal.hideLoading();
                    }
                  })
                fetchConsultations();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response?.status?.message || "Failed to setup meeting",
                    showConfirmButton: true,
                    didOpen: () => {
                      Swal.hideLoading();
                    }
                })
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error || "Failed to setup meeting",
                showConfirmButton: true,
                didOpen: () => {
                  Swal.hideLoading();
                }
            })
        }
    };

    const openMap = (candidate) => {
        if (candidate.coordinates) {

            navigate('/map', {
                state: {
                    candidate: {
                        id: candidate.id,
                        mapIconId: candidate.mapIconId,
                        coordinates: candidate.coordinates,
                        selected: true
                    }
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates',
                text: 'This lead does not have any coordinates'
            })

        }

    }

    const rejectApplicant = async (id) => {

        Swal.fire({
          icon: "warning",
          title: "Are you sure?",
          text: `You are about to reject this consultation. An email will be sent to the Employee.`,
          showCancelButton: true,
          confirmButtonText: "Yes, reject it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            // loader
            Swal.fire({
              icon: "info",
              title: "Rejecting Company Consultation...",
              text: "Please wait",
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
    
            let req = new CandidateJobApplicationReq()
            req.candidateId = id;
            req.userId = user.id;
    
            let res = await UsersApi.RejectConsultation(req);
            if (res.status.success) {
              //show Swal succsess
              fetchConsultations();
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Company Consultation Rejected",
                showConfirmButton: false,
                didOpen: () => {
                  Swal.hideLoading();
                }
              });
            } else {
              //show Swal error
              Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                showConfirmButton: false,
                didOpen: () => {
                  Swal.hideLoading();
                }
              });
            }
    
          }
    
        });
    
    
    
      }

    const handleApprove = async (id) => {
        try {
            const response = await FiberCompanyApi.ApproveConsultation(id);
            if (response.status.success) {
                fetchConsultations();
            } else {
                console.error('Failed to approve consultation:', response.status.message);
            }
        } catch (error) {
            console.error('Error approving consultation:', error);
        }
    };

    const headers = [
        {
            key: "candidateName", name: "Candidate Name", render: (row) => (
                row.candidate && row.candidate.name ? row.candidate.name : 'No candidate'
            )
        },
        {
            key: "positionTitle", name: "Position Title", render: (row) => (
                row.candidate && row.candidate.positionTitle ? row.candidate.positionTitle : 'No position'
            )
        },
        {
            key: "companyName", name: "Company Name", render: (row) => (
                row.candidate && row.candidate.companyName ? row.candidate.companyName : 'No company'
            )
        },
        {
            key: "industry", name: "Industry", render: (row) => (
                row.candidate && row.candidate.industry ? row.candidate.industry : 'No Industry'
            )
        },
        {
            key: "linkedInUrl", name: "LinkedIn URL", render: (row) => (
                <a href={row.linkedInUrl} target="_blank" rel="noopener noreferrer">{row.candidate.linkedIn}</a>
            )
        },
        { key: "additionalInformationQuestion", name: "Additional Information" },
        {
            key: "prefferedDateTime", name: "Preferred Date/Time", render: (row) => (
                format(new Date(row.prefferedDateTime), 'PPpp')
            )
        },
        {
            key: "consultationDateTime", name: "Consultation Date/Time", render: (row) => (
                row.consultationDateTime ? format(new Date(row.consultationDateTime), 'PPpp') : 'Not scheduled'
            )
        },
        { key: "status", name: "Status" },
        {
            key: "actions", name: "Actions", render: (row) => (
                // more width in the column

                <div className="action-buttons" style={{ width: "400px" }}>
                    <button onClick={() => scheduleInterview(row.candidateId)} title="Setup Meeting">📅</button>
                    <button onClick={() => openMap(row.candidate)} title="Show on Map">🗺️</button>
                    <button onClick={() => rejectApplicant(row.id)} title="Reject">❌</button>
                    <button onClick={() => handleApprove(row.id)} title="Approve">✅</button>
                </div>
            )
        }
    ];

    return (
        <Layout>
            <div className="manage-company-consultation">
                <Typography variant="h4" component="h1" className="page-title">
                    Manage Company Consultations
                </Typography>

                <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    aria-labelledby="schedule-interview-modal"
                    aria-describedby="modal-to-schedule-interview"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}>
                        <h2 id="schedule-interview-modal">Schedule Interview</h2>
                        <CalendlyUserSelection
                            candidateId={selectedCandidateId}
                            onSchedule={handleSetupMeeting}
                            type={"Company"}
                        />
                    </Box>
                </Modal>
                <Box className="filters">
                    <div className="search-input-container">
                        <SearchIcon className="search-icon" />
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <Select
                        isMulti
                        options={industryOptions}
                        className="industry-select"
                        classNamePrefix="select"
                        placeholder="Select Industries"
                        value={selectedIndustries}
                        onChange={setSelectedIndustries}
                    />
                    <Select
                        isMulti
                        options={statusOptions}
                        className="status-select"
                        classNamePrefix="select"
                        placeholder="Select Statuses"
                        value={selectedStatuses}
                        onChange={setSelectedStatuses}
                    />
                </Box>
                {loading ? (
                    <div className="loader-container">
                        <CircularProgress sx={{ color: "var(--color-icon)" }} size={50} />
                    </div>
                ) : (
                    <Datatable headers={headers} list={consultations} />
                )}
                <div className="pagination-container">
                    <Pagination
                        color='light'
                        renderItem={(item) => (
                            <PaginationItem
                                components={{
                                    next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                    first: (props) => <button {...props}>First</button>,
                                    previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                }}
                                {...item}
                                sx={{ color: "white" }}
                            />
                        )}
                        count={totalPages}
                        page={currentPage}
                        onChange={(e, pageNo) => setCurrentPage(pageNo)}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default ManageCompanyConsultation;