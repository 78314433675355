/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import { useParams } from 'react-router-dom'
import GetOfferByGuidReq from '../../../Requests/Offer/GetOfferByGuidReq'
import OfferApi from '../../../API/OfferApi'
import Modal from './../../../components/Modal'
import { CircularProgress, ClickAwayListener } from '@mui/material'
// import { sendPasswordResetEmail } from 'firebase/auth';
import Swal from 'sweetalert2';
// import { getAuth } from 'firebase/auth';
import BizCard from '../../TeamAdmin/MapPage/components/DigitalBizCard/BizCard'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import SearchFiberPlanReq from '../../../Requests/FiberPlan/SearchFiberPlansReq'
import FiberPlanApi from '../../../API/FiberPlanApi'
import QRCode from 'qrcode.react'
import { FaRegCopy } from 'react-icons/fa'
import CustomerForm from './components/CustomerForm'
const Index = () => {
  const [offer, setOffer] = useState(null)
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [customerModal, setCustomerModal] = useState(false);
  const [reload, setReload] = useState(false)
  const [plans, setPlans] = useState([])
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [shareModal, setShareModal] = useState(false)
  // const auth = getAuth();
  const services = ['Symmetrical Speeds', 'Unlimited - No Data Caps', 'No Contracts', 'No Hidden Fees', 'Regular Price. Not a promotional rate!', '24/7 Local Support', '60 Day Free Trial']

  useEffect(() => {
    const getOffer = async () => {
      setLoading(true)
      const req = new GetOfferByGuidReq();
      req.string = id;
      req.int = 1;
      req.pagingParams.pageNumber = 1;
      req.pagingParams.pageSize = 10;
      const res = await OfferApi.GetOfferByGuid(req);
      // console.log(res)
      if (res?.status?.success) {
        setOffer(res.data)
        const req2 = new SearchFiberPlanReq();
        req2.fiberCompanyId = res.data.fiberCompanyId;
        const res2 = await FiberPlanApi.SearchFiberPlans(req2);
        if (res2?.status?.success) {
          setPlans(res2.data.list)
          setSelectedPlan(res2.data.list[0])
          setLoading(false)
        }

      }
    }
    getOffer()
  }, [reload]);

  // const ForgotPassword = () => {

  //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

  //   if (!pattern.test(offer.lead.email)) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Invalid Email',
  //       text: 'Please enter a valid email address',
  //     });

  //     return;
  //   }
  //   sendPasswordResetEmail(auth, offer.lead.email)
  //     .then((value) => {
  //       // Password reset email sent!
  //       // ..
  //       return
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;

  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error',
  //         text: errorMessage,
  //       });
  //     });
  // }

  function shareOffer() {
    navigator.clipboard.writeText(window.location.origin + '/share-offer/' + id)
    Swal.fire({
      icon: 'success',
      title: 'Copied',
      text: 'Link Copied to clipboard',
    });
  }

  return (
    <Layout>
      <div className='offer_container'>

        {
          !loading && offer && (
            <>
              {
                shareModal &&
                <ClickAwayListener onClickAway={() => {
                  setShareModal(false)
                }}>
                  <div className='share_container'>
                    <QRCode value={window.location.origin + '/share-offer/' + id} />
                    <h1>Share this link with your friends</h1>
                    <div onClick={() => {
                      shareOffer()
                    }} className='link_input'>
                      <input type='text' value={window.location.origin + '/share-offer/' + id} readOnly />
                      <FaRegCopy size={30} color='#2761D0' />
                    </div>
                  </div>
                </ClickAwayListener>
              }
              <div className='offer'>
                <Modal height={"80vh"} open={customerModal} setOpen={setCustomerModal}>
                  <CustomerForm setReload={setReload} fiberPlan={selectedPlan} offer={offer} setOpen={setCustomerModal} lead={offer.lead} />
                </Modal>
                <h3 className='free_install'>{offer.freeInstall && "FREE INSTALL"} {offer.freeInstall && offer.freeOffer && '&'}</h3>
                <h2 className='two_free'>{offer.freeOffer && 'TWO FREE'}</h2>
                <h1 className='month'>{offer.freeOffer && 'MONTHS'}</h1>
                <h2><span>LIMITED TIME OFFER</span></h2>
                <div className='banner'>
                  <div className='banner_top'>
                    {
                      plans.map((plan, index) => {
                        if (plan.name === "HFC")
                          return (
                            <div key={index} onClick={() => {
                              setSelectedPlan(plan)
                            }} className={`banner_card ${selectedPlan.id === plan.id && "active"}`}>
                              {selectedPlan.id === plan.id && <IoIosCheckmarkCircle style={{
                                position: 'absolute',
                                top: '-5%',
                                right: '-5%'
                              }} size={40} color='#2761D0' />}
                              <img style={{
                                height: "30px"
                              }} src={'data:image/png;base64,' + offer.fiberCompany?.logo} alt='fiber company logo' />
                              <h2>
                                {
                                  // plan.speed < 1 ?
                                  plan.speed * 1000 + " Mbps"
                                  // :
                                  // plan.speed + " Gig"
                                }
                              </h2>
                              {/* {
                                plan.name === "HFC" && <p>Hybrid Fiber Coaxial</p>
                              } */}
                              <h4>${plan.discount ? plan.price - plan?.discount : plan.price}/mo*</h4>
                              {/* <p>Lifetime Rate Lock</p> */}
                            </div>
                          )
                      })
                    }

                  </div>
                  <div className='banner_bottom'>
                    {
                      services.map((service, index) => {
                        return (
                          <div key={index} className='service_card'>
                            <IoIosCheckmarkCircle size={20} color='#2761D0' />
                            <p>{service}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                  <button className='accept_offer_button' onClick={() => {
                    if (!selectedPlan) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Select a plan',
                        text: 'Please select a plan to continue',
                      });
                      return;
                    }
                    setCustomerModal(true)
                  }} >ACCEPT OFFER</button>
                  <button onClick={() => {
                    setShareModal(true)
                  }} style={{
                    marginTop: '1rem'
                  }} className='accept_offer_button'>SHARE OFFER</button>
                </div>
              </div>
              <BizCard salesRep={offer.salesRep} />
            </>
          )
        }
        {
          loading && (
            <div>
              <CircularProgress sx={{
                color: "#2761D0"
              }} size={70} />
            </div>
          )
        }

      </div>
    </Layout>
  )
}

export default Index