import React, { useEffect, useState } from 'react'
import { IoMdCloseCircle } from 'react-icons/io'
import authorizedFastwyre from './../../Offer/components/authorizedFastwyre.png'
import authorized from './../../Offer/components/authorized.png'
import PhoneInput from 'react-phone-input-2'
import Swal from 'sweetalert2'
import OfferApi from '../../../../API/OfferApi'
import StringIntReq from '../../../../Requests/StringIntReq'

const OfferModal = ({ offer, open, setOpen }) => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState(`Hi ${firstName+ " " +lastName}, \n\n${offer?.lead?.name} thought you would love Lightcurve’s Internet Service as much as they do! They’ve sent you an exclusive referral offer:\n\nSign up today using their referral link, and you’ll get your first month absolutely free!\nIt’s super simple:\n1. Click the link: _link_\n2. Sign up and start enjoying Lightcurve’s Lightning Fast Internet! \n3. Your free month will be automatically applied.\nDon’t miss out—this special offer is only available for a limited time!\n\nSTART NOW\n\nHappy Streaming, \nLightning OS`)


    useEffect(
        () => {
            setMessage(`Hi ${firstName+ " " +lastName}, \n\n${offer?.lead?.name} thought you would love Lightcurve’s Internet Service as much as they do! They’ve sent you an exclusive referral offer:\n\nSign up today using their referral link, and you’ll get your first month absolutely free!\nIt’s super simple:\n1. Click the link: _link_ \n2. Sign up and start enjoying Lightcurve’s Lightning Fast Internet! \n3. Your free month will be automatically applied.\nDon’t miss out—this special offer is only available for a limited time!\n\nSTART NOW\n\nHappy Streaming, \nLightning OS`)
        },[firstName,lastName]
    )

    const createSharedOffer = async () => {
        Swal.fire({
            icon: "info",
            title: "Generating Offer",
            text: "Please wait while we generate the offer",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if(!firstName){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!phone){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is required",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(phone.length < 11){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone number is invalid",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = {
            id: offer?.id,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            message: message
        }
        const res = await OfferApi.CreateSharedOffer(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Offer Sent",
                text: "The offer has been Sent",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occurred while generating the offer",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    return (
        <div className='custom_modal'>
            <div style={{ width: "100%" }}>
                <IoMdCloseCircle style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => {
                    setOpen(false)
                }} size={30} color={"#2761D0"} />
            </div>
            {
                offer?.fiberCompanyId === 9 && <img alt="Logo" src={authorizedFastwyre} className='logo' />
            }
            {
                offer?.fiberCompanyId === 2 && <img alt="Logo" src={authorized} className='logo' />
            }
            <div className='inputs'>
                <input placeholder='*First Name' value={firstName} onChange={(e) => {
                    setFirstName(e.target.value)
                }} />
                <input placeholder='Last Name' value={lastName} onChange={(e) => {
                    setLastName(e.target.value)
                }} />
                <PhoneInput placeholder='* Cellphone' country={'us'} containerStyle={{
                    width: "100%",
                    borderRadius: "10px"
                }} inputClass='custom_input' value={phone} onChange={(e) => {
                    setPhone(e)
                }} />
                <textarea className='txt' style={{textAlign:"left",fontSize:"1rem"}} rows={5} placeholder='Message' value={message} onChange={(e) => {
                    setMessage(e.target.value)
                }} />
                <button style={{ backgroundColor: "#2761D0" }} onClick={() => {
                    createSharedOffer()
                }}>Share Offer</button>

            </div>
        </div>
    )
}

export default OfferModal