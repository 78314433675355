import React from 'react';
import { FaUser } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi2';
import { IoChatbubbleSharp } from 'react-icons/io5';

export const PrivateChatIcon = ({ color }) => {
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px' }}>
      <IoChatbubbleSharp size={45} style={{ position: 'absolute', zIndex: 1, color }} />
      <FaUser size={24} style={{ position: 'absolute', zIndex: 2, color: 'white' }} />
    </div>
  );
};

export const GroupChatIcon = ({ color }) => {
    return (
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px' }}>
        <IoChatbubbleSharp size={45} style={{ position: 'absolute', zIndex: 1, color }} />
        <HiUserGroup size={24} style={{ position: 'absolute', zIndex: 2, color: 'white' }} />
      </div>
    );
  };


