import { CircularProgress, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FiberCompanyApi from '../../../API/FiberCompanyApi'
import Sidebar from '../../../Layouts/Sidebar'
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq'
import ServicableAreaSelector from '../../../components/ServicableAreaSelector'
import FiberCompany from './components/FiberCompany'
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
const Index = () => {
    const [servicableAreaId, setServicableAreaId] = useState(null)
    const [companyName, setCompanyName] = useState('')
    const [description, setDescription] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
  



    const searchCompany = () => {
        setLoading(true)
        const req = new SearchFiberCompanyReq()
        if (servicableAreaId) {
            req.servicableAreaId = servicableAreaId
        }
        if (companyName) {
            req.name = companyName
        }
        if (description) {
            req.description = description
        }
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = 10
        
        FiberCompanyApi.SearchFiberCompany(req).then(res => {
            if (res?.status?.success) {
                console.log("Null check", res)
                
                setTotalPages(res?.data?.totalPages)
                res?.data?.list.forEach(element => {
                    // element.actions = <div className="flex gap-2">
                    //     <SoftButton onClick={() => { editCompany(element) }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Details</SoftButton>
                    //     <SoftButton onClick={() => { DeleteCompany(element.id) }} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Delete</SoftButton>
                    // </div>;
                    element.logo = <img src={"data:image/png;base64," + element.logo} width={"50px"} height={"50px"} alt={element.name} />
                });
                setCompanies(res?.data?.list)
                setLoading(false)
            }
            else {
                
            }

        })
    }

    const headers = [
        {
            name: "ID",
            key: "id"
        },
        {
            name: "Logo",
            key: "logo"
        },
        {
            name: "Company Name",
            key: "name"
        },
        {
            name: "Description",
            key: "description"
        },
        {
            name: "Servicable Area",
            key: "servicableAreaId"
        },
        {
            name: "Actions",
            key: "actions"
        }
    ]

    useEffect(
        () => {
            searchCompany()
        }, [currentPage]
    )

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchCompany();
        }
      };

    return (
        <Layout>
            <div className='manageFiberCompanies' >
                <div className='searchFilters' >
                    <h1 >Manage Fiber Companies</h1>
                    <div className='inputs' >
                        <input value={companyName}  onKeyDown={handleKeyPress} onChange={(e) => { setCompanyName(e.target.value) }} placeholder="Enter the Name" />
                        <input value={description} onKeyDown={handleKeyPress} onChange={(e) => setDescription(e.target.value)} placeholder="Enter the Description" />
                        <ServicableAreaSelector placeholder='Select a Servicable Area' setUser={setServicableAreaId} />
                    </div>
                    <button onClick={() => { searchCompany() }} >Search</button>
                </div>
                {
                    loading ?
                        <div className='spinner'>
                            <CircularProgress sx={{color:"var(--color-icon)"}} />
                        </div>
                        :
                        <div className='companiesContainer'>
                            {/* <Datatable headers={headers} list={companies} currentPage={currentPage} totalPages={totalPages} /> */}
                            <div className='tableHeaders'>
                                <h3>Fiber Company</h3>
                                <h3>Company Name</h3>
                                <h3>Description</h3>
                                <h3>Servicable Area</h3>
                                <h3>Update</h3>
                                <h3>Delete</h3>
                            </div>
                            <div className='tableBody'>
                                {
                                    companies.map((company, index) => {
                                        return (
                                           <FiberCompany searchCompany={searchCompany} key={index} company={company} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
                <div className='pagination'>
                    <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                </div>
            </div>

        </Layout>
    )
}

export default Index