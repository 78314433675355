import React from 'react'

const Banner = ({setOpen}) => {
  return (
    <div style={{
        backgroundImage: 'url("/images/summer/summer_banner.png")',
    }} className='summer_sales_banner'>
        <div className='text'>
            <h1>Your Summer <br/>Of Opportunity <br/>Awaits!</h1>
            <p>Unlock your Potential This Summer!</p>
            <button onClick={()=>{
                setOpen(true);
            }} >Apply Now</button>
        </div>
    </div>
  )
}

export default Banner