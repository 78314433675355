import React from 'react'
import UserSelector from '../../../../components/UserSelector'
import AddRemoveUserFromConversation from '../../../../Requests/Messaging/AddRemoveUserFromConversationReq';
import MessagingApi from '../../../../API/MessagingApi';
import Swal from 'sweetalert2';
import { Close } from '@mui/icons-material';
const AddUsersToConversation = ({ open, setOpen, conversationName, conversationId }) => {

    const [usersToAdd, setUsersToAdd] = React.useState([]);
    // const handleClose = () => {
    //     setOpen(false);
    // }

    const addUsers = async () => {
        console.log("Adding users", usersToAdd)
        Swal.fire({
            icon: 'info',
            title: 'Adding User',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });


        const req = new AddRemoveUserFromConversation();

        req.id2 = conversationId;
        for (let i = 0; i < usersToAdd.length; i++) {
            req.id1 = usersToAdd[i];
            const res = await MessagingApi.AddUserToConversation(req);
            if (res?.status?.success) {
                console.log("User added successfully")
            }
            else if (res?.status?.message === "User already exists") {
                console.log("User already exists")
            }
            else {
                console.log("Error adding user", res?.status?.message)
            }

        }
        Swal.close();
        setOpen(false);
    }

    return (

        <div className='view_members' style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "white",
            zIndex: 1000,
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
            borderRadius: "10px",
            padding: "20px",
            height: "fit-content"
        }}>
            <Close sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer"
            }} onClick={() => setOpen(false)} />
            <h1 style={{
                textAlign: "center",
                color: "black",
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "20px",
                textTransform: "uppercase"
            }}>ADD USER TO {conversationName}</h1>
            <UserSelector setUser={setUsersToAdd} conversationType={'Group'} />
            <button style={{
                padding: "10px 20px",
                backgroundColor: "#2761CF",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100%",
                marginTop: "20px",
            }} onClick={() => { addUsers() }}>Add</button>
        </div>

    )
}

export default AddUsersToConversation