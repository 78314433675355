import React from "react"
const placeholderReviews = [

    {
        firstName: "Mara ",
        lastName: "Krizman",
        content: "Fantastic service and our technician was amazing! He was patient and professional. The fidium is running faster than Xfinity and its $40 less per month!",
        feedbackType: "Written",
        type: "Customer",

    },
    {
        firstName: "Fay",
        lastName: "Johnson",
        content: "Installers were courteous and respectful to the property",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Rick",
        lastName: "Martin",
        content: "Service was great.  The install was fast and efficient and technician was incredibly personable.  Up and running in less than an hour.  Very satisfied.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Joe",
        lastName: "Pfeifer",
        content: "Excellent service! Fiber Install went very quickly and the Fidium Representative was top notch! The fiber internet speed is impressive!",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Rachel",
        lastName: "",
        content: "We're so happy to have fiber internet. The install was easy & we were consulted as to where we wanted the equipment which was great. Our download & upload speeds are 3+ times faster than our previous provider. We're off to a good start.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Tim",
        lastName: "Benson",
        content: "Technician was hilarious and did a great job. Very professional. Now my download speed at least 5x faster.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "I've been using Fidium Internet for the past month, and I couldn't be more satisfied with their service. The reliability and speed of their internet connection have exceeded my expectations. From seamless streaming to uninterrupted video calls, Fidium Internet has consistently delivered exceptional performance. Moreover, their customer service team is friendly, knowledgeable, and always ready to assist whenever I have questions or concerns. I highly recommend Fidium Internet to anyone looking for a reliable internet service provider.",
        firstName: "Javier",
        lastName: "Irizarry",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Sergio was great. We evaluated all options before installing and he made it as quick and painless as possible.  Such a relief for something to be problem free nowadays",
        firstName: "Matheau",
        lastName: "Greely",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Fidium was Fantastic!!! Salesman Wilder and Brandon doing the install was off the Charts Great!! They did exactly what they said and adding Fiber to our house was the Best Decision!! Double the Speed and save $60.00 a month!!! Thank You Fidium",
        firstName: "C.j.",
        lastName: "Regan",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Fantastic experience with knowledgeable installation crew, they answered all of my questions and provided with unparalleled insights into the system, both hardware and software. I am very happy to have gotten this service, all stemming from one canvasser.",
        firstName: "Antonio",
        lastName: "Vinzani",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "I’ve had fidium internet service (fiber optic) for over a month now. So far it has been flawless. Download speeds are comparable with my previous ISP but upload speeds are phenomenal. The support staff during and after the installation has been great, kudos to them and hope they can keep up the great service.",
        firstName: "Marcel",
        lastName: "Geraud",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Fidium installed our fiber network yesterday. Our original cables weren’t done properly so our installer had to go the extra mile to troubleshoot and fix what was preexisting. So far so good and much faster than our Xfinity service.",
        firstName: "Jen",
        lastName: "Chile",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "I just switched to fidium and so far its great service for a better price. The staff worked hard to ensure a smooth transition to their service, I’d recommend at a minimum speaking with a rep to compare, thank you.",
        firstName: "Matthew",
        lastName: "Maher",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Simple, professional, and effective installation. Speed and service has been superior to previous carrier. Very happy with my decision to switch.",
        firstName: "Keith",
        lastName: "Whittaker",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Installation was easy to schedule, flexible to accommodate changes and the installer was professional and courteous. Even wore shoe covers inside the house. And the speeds are as advertised. Much quicker than Xfinity. Finally, managing the network through the Fidium app is a breeze",
        firstName: "Pablo",
        lastName: "Addison-Smith",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Techs are knowledgeable and super nice.  Setup is pretty simple and quick.  The Internet is very fast.  I highly recommend Fidium service.",
        firstName: "Thuan",
        lastName: "Hoang",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "installation was a breeze, the installer was a professional showed up right on time. He made sure that everything he did was to my approval and my satisfaction, he also was patient with me in the process of me understanding and navigating the app.This is a serious company looking to satisfy customers.",
        firstName: "eric",
        lastName: "sanchez",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "I like this service and will refer to my friends because I have not experience any disruptions, Whereas, my previous internet provider I had experienced many disruptions. The installment was easy and hassle  free and technicians were great in communicating steps.",
        firstName: "Neelam",
        lastName: "Jan",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "The initial part of my fiber install was a little bumpy, but I was informed the communication breakdown was due to the massive amount of installs being done at the time. My Fidium Rep, Shasta, was very patient and informative about the process during our phone convos, reassuring me that everything would be taken care of. He granted me another free month of service due to the troubles with the initial fiber run/installation process- that's good business!My interior install Tech, Edgar, did an outstanding job! He ran the fiber to the exact location I requested and went a few steps beyond by repairing a broken ethernet cable of mine and installing a multi-conn wall plate for my old cable/ethernet connections and the new fiber connection. He was very courteous and hard working! Once finished, we ran a speed test and my D/L and U/L speeds are near 1Gig/s!!",
        firstName: "Richard",
        lastName: "Cotten",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Customer service has been excellent from the moment I first met the representative. Everyone from the in-person and virtual reps, to the technicians who came out for installation have been professional, courteous, and incredibly helpful.",
        firstName: "Heather",
        lastName: "S",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "The installation went well, good communication and on time.Testing my new system w no issues. 2 weeks later. Tested the speed, reliable and faster than cable. Really like this fiber. Great job! Thanks",
        firstName: "Alberto",
        lastName: "Montenegro",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "I just got Fidium internet at my house. Their customer service is so outstanding that I’m compelled to give them a five star rating! The service technician was awesome. He did everything right, and ensured that he completed the job to my satisfaction. I also got a chance to interact with their call center staff. My problem was resolved within minutes. Overall, I’m a very happy customer.",
        firstName: "Jayesh",
        lastName: "Limaye",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "I love the fiber optic internet service… can’t ever go back to the old copper service. It’s super fast and stable. Save me lots of time!!! I work from home, stable and fast internet connection is essential to me while I’m doing coaching sessions and teaching groups.. Thank you! Love this team. They are dedicated and thrive to give excellent experience for clients. Absolutely 5 stars!!! Highly recommend it!",
        firstName: "Jessica",
        lastName: "Man",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Devin Williams did an excellent job installing our Fidium fiber optic connection, he is knowledgeable and explained our best options on where to put the box and recommended how best we can set our Fidium Internet and switch over our Smart controls without disrupting our current Xfinity connection. We were going for a trial, it took longer than expected as there were some issues on their end that he needed to troubleshoot but he was patient all the way until we were connected successfully.Hewasdelayedonhisschedule to set up other customers but he did not leave us hanging despite the challenges on the set up.Overall, it was still a good experience and I give Devin a 5 star rating for his service and professionalism! Thank you, Devin!",
        firstName: "Joyce",
        lastName: "Dy-Scott",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "We're so happy to have fiber internet. The install was easy & we were consulted as to where we wanted the equipment which was great. Our download & upload speeds are 3+ times faster than our previous provider. We're off to a good start.",
        firstName: "Rachel",
        lastName: "",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        content: "Half the cost and 21% cheaper. I tried to talk to my existing carrier about cost but they were not interested. Didn't even seem to care when I called back to cancel their service. Was thrilled when I did speed tests and it was 21% faster.",
        firstName: "John",
        lastName: "Staton",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Rock",
        lastName: "Thomas",
        feedbackType: "Written",
        type: "Customer",
        content: "I have been trying out Fidium internet service for about 2 weeks. I have been very impressed with it so far. I had made the mistake of having Xfinity & Fidium at the same time, I was having trouble with the Fidium service. After I finally disconnected Xfinity, Fidium has been working great. With the rain storms we have been having lately, our internet service worked perfectly. I am very happy with the service."
    },
    {
        firstName: "Sameer",
        lastName: "Shroff",
        content: "Installation was pretty painless . Good communication throughout and the techs were all nice. Service so far is great , 100s mb/sec even on WiFi with a few walls in-between",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Marcel",
        lastName: "Geraud",
        content: "I’ve had fidium internet service (fiber optic) for over a month now. So far it has been flawless. Download speeds are comparable with my previous ISP but upload speeds are phenomenal. The support staff during and after the installation has been great, kudos to them and hope they can keep up the great service.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Rodolfo",
        lastName: "G",
        content: "Installation was what companies used to do. Before, a company just left a length of coaxial cable that I had to secure against my home to avoid mowing over it, etc. Fidium buried conduit, ran the fiber optic line along the height of my home, ran it through the attic and gave it a specific outlet! Did I mention I have a 2-story? Then, the fiber performance is so much better and faster than what we had before. A “2-bar” WiFi connection to the Fidium router is three times faster than a direct Cat-V to my old internet provider",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Peter",
        lastName: "O.",
        content: "I was very impressed with the installation and service of this fiber internet. We were with the competitor for well over 10 years and rarely had any issues. So far, we’ve been very pleased with the actual fiber internet service but also extremely happy with the customer service! I’ve had the pleasure of working with a gentleman named Shasta who has been wonderful and patient in answering my questions, even outside of “normal business hours”. So far, I HIGHLY recommend this service!",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        firstName: "Ben",
        lastName: "Brandt",
        content: "Helped me access my dedicated fiber line for our home. It's working so much better! Grateful for the team at Lightning OS.",
        feedbackType: "Written",
        type: "Customer"

    },
    {
        firstName: "Giandemir",
        lastName: "Jaen",
        content: "I’ve had Fidium fiber services for 4 days now and so far it’s noticeably/ significantly better than cable wire internet.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "M",
        "lastName": "G",
        "content": "My internet speed almost gone up 5 times since we set new fiber internet through Fidium. Techs who came for installation were very polite and professional and explained the whole setup process in simple language. Happy to switch to the new service provider.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "James",
        "lastName": "Wallace",
        "content": "Fidium did everything they said they were going to do. They communicated well. They were always on time. The service is great.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Matthew",
        "lastName": "Whalen",
        "content": "They were great and got the whole system installed and working quickly. The speed of the internet is leaps and bounds above my old XFinity!",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Mark",
        "lastName": "Purcha",
        "content": "Excellent experience, helpful staff. Everything was communicated well. Smooth and easy process. Thank you.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Wanda",
        "lastName": "Marshall",
        "content": "There’s been no interruptions of TV & movie viewing as new customers with Fidium. My family and I are very satisfied.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Nat",
        "lastName": "Gee",
        "content": "Good service, professional. Changing over has saved me quite a bit of money. Thank you!",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Chris",
        "lastName": "Harden",
        "content": "Fast and easy install. Tech was able to explain the equipment and what the setup would be like.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Sean",
        "lastName": "G",
        "content": "The installer was very professional, expedient, and knowledgeable. Very happy with my experience.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Mario",
        "lastName": "Rocha",
        "content": "We are very happy with the service. The internet signal is very good, and the customer service is excellent.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Jessica",
        "lastName": "Green",
        "content": "The installation & setup was easy & hassle-free!",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Bryan",
        "lastName": "Byrd",
        "content": "Great installation. Super fast speed. Very professional.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Andrew",
        "lastName": "Hayes",
        "content": "Friendly, knowledgeable, fast. Amazing experience. And the internet is blazing fast!",

        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Victoria",
        "lastName": "Rodrigues",
        "content": "Great experience, everyone is nice and accommodating.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Joe",
        "lastName": "Beagle",
        "content": "Friendly and knowledgeable salesman and installation. Fiber is working fine so far.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "J. Carlos",
        "lastName": "Torres",
        "content": "Gigantic speed performance both download and upload services. Thank you, Fidium.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Farid",
        "lastName": "Ahmed",
        "content": "Technician arrived on time and completed the installation quickly.",
        feedbackType: "Written",
        type: "Customer"
    },
    {
        "firstName": "Christopher",
        "lastName": "Winchester",
        "content": "My upload speed has always been a problem but Fidium definitely fixed that issue.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Ravi",
        "lastName": "Ponnusamy",
        "content": "Installation of my internet service by Fidium went painless and operating well.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Adrien",
        "lastName": "C",
        "content": "Happy customer - Fidium installed it quick and my internet is now blazing fast.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Steve",
        "lastName": "Ray",
        "content": "Excellent service. Also, tech was efficient and courteous.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Ali",
        "lastName": "Zammam",
        "content": "Good service, professional technicians.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Hector",
        "lastName": "Narez",
        "content": "Fast and great quality. Happy with my service.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Luis",
        "lastName": "Molero",
        "content": "Good service. The guys are so professional.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Rizwan",
        "lastName": "Aslam",
        "content": "Excellent quick easy install. Lightning fast speeds well worth changing.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "kcude1",
        "lastName": "",
        "content": "Very professional technicians from start to finish and lightning fast internet speeds. Thank you, Fidium!",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "A",
        "lastName": "King",
        "content": "The tech did a great job. He was very professional and very clean in the job he did.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Gonzalo",
        "lastName": "Vargas",
        "content": "Thank you! Great people and very knowledgeable. It was a breeze.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Melinda",
        "lastName": "Giangrande",
        "content": "Great experience. Everyone is very helpful & knowledgeable.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Dorian",
        "lastName": "Finn",
        "content": "Very smooth experience signing up and getting hooked up.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Xuanping",
        "lastName": "Tang",
        "content": "Every fast installation and services. The speed of Internet is even faster.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "fahad",
        "lastName": "ali",
        "content": "Great experience. Looking forward to new relationship.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Cheryl",
        "lastName": "Scott",
        "content": "Arrived on time. Installation completed without issue.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Jingyi",
        "lastName": "Liu",
        "content": "Quick service, low cost, and faster internet. Very satisfying.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Daniel",
        "lastName": "Lawrence",
        "content": "Great speed and reliability. A+ for customer service.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Roland",
        "lastName": "Schneefuss",
        "content": "Great experience, great service, great price.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Jonathan",
        "lastName": "Tennyson",
        "content": "Very smooth process. Friendly technician.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Evan",
        "lastName": "Youngblood",
        "content": "Friendly and knowledgeable staff for fiber internet consultation.",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Oscar",
        "lastName": "Martinez",
        "content": "10/10 wifi fast and reliable so far 👍",
        "type": "Customer",
        "feedbackType": "Written"
    },
    {
        "firstName": "Matthew",
        "lastName": "Thompson",
        "content": "Competitive rates and quick easy installation",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Stuart",
        "lastName": "Nelson",
        "content": "Was able to answer my questions. Polite and to the point.",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Ozgur",
        "lastName": "Senel",
        "content": "Quick clean installation by professionals.",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Marvin",
        "lastName": "Espinoza",
        "content": "Great service and on time response",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Sunny",
        "lastName": "Kim",
        "content": "Best customer service and best offered!!",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Scott",
        "lastName": "Printz",
        "content": "Everything is working smoothly as expected",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Ann",
        "lastName": "Vo",
        "content": "Fast and very professional!",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Florian",
        "lastName": "Zschunke",
        "content": "Quick and excellent service",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Rodolfo",
        "lastName": "Vela",
        "content": "Very good experience with the entire process",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Tony",
        "lastName": "Z",
        "content": "Always keep the customer updated!",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Rick",
        "lastName": "Blaylock",
        "content": "Did an awesome job",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Patricia",
        "lastName": "Mccarty",
        "content": "On time. Professional. Polite!",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Euro",
        "lastName": "Granadillo",
        "content": "100% recommended",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Sanda",
        "lastName": "Soe",
        "content": "Best service ever",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Anil",
        "lastName": "Vallabhajosyula",
        "content": "Super fast internet speed and free installation. So far so good.",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        "firstName": "Mario",
        "lastName": "Rocha",
        "content": "We are very happy with the service, the internet signal is very good and the customer service is excellent",
        "type": "Customer",
        "feedbackType": "Written"
      },
      {
        firstName: "Raza",
        lastName: "Hussain",
        content: "Excellent service, neat installation, would recommend",
        type: "Customer",
        feedbackType: "Written"
      },
      {
        firstName:"Sal",
        lastName:"Davide",
        content:"Fast easy and internet seems to work alot better than tachus",
        feedbackType:"Written",
        type:"Customer"
      },
      {
        firstName:"Mishal",
        lastName:"Abahusayn",
        content:"Installation was easy and the service is great.",
        feedbackType:"Written",
        type:"Customer"
      }

]

export default placeholderReviews