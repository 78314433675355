import { HubConnectionBuilder } from '@microsoft/signalr';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import toast, { Toaster } from 'react-hot-toast';
import Swal from "sweetalert2";
import EnvSettings from "../API/EnvSettings";
import firebaseConfig from './FirebaseConfig';


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();

var accessToken = ""

onAuthStateChanged(auth, (user) => {
    if (user) {
        accessToken = user.accessToken;
    }
});

class ConnectionService {
    GetToken() {
        return accessToken;
    }

    constructor(CurrentUser) {
        this.baseUrl = EnvSettings.url;
        this.CurrentUser = CurrentUser;


        this.Connection = null;
        this.newMessage = null;
        this.ConversationLoaders = [];
        this.newChat = null;
        this.newNotification = null;
        this.SendRepLocation = null;
    }

    async StartConnection() {

        const newConnection = new HubConnectionBuilder()
            .withUrl(this.baseUrl + 'connectionsHub')
            .withAutomaticReconnect()
            .build();


        this.Connection = newConnection;

        if (this.Connection != null) {
            await this.Connection.start()
                .then(() => {

                    this.Connection.on('ConsoleWrite', (message) => {

                    });


                    this.Connection.on('NotifyNewConversation', (creatorName, conversationName, conversationType) => {
                        // console.log("New Conversation", creatorName, conversationName, conversationType)
                        this.NotifyNewChat(creatorName, conversationName, conversationType);
                    })

                    this.Connection.on('NotifyNewMessage', (senderId, conversationName, conversationId, message, userName, profileImage) => {
                        // console.log("NotifyNewMessage", senderId, conversationName, conversationId, message, userName, profileImage)
                        if (senderId !== this.CurrentUser.id && conversationId === this.ConversationLoaders[0].conversationId) {
                            this.ReloadConversation(senderId, conversationName, conversationId, message, userName, profileImage);
                        }
                        if (senderId === this.CurrentUser.id || (this.ConversationLoaders.length > 0 && this.ConversationLoaders[0].conversationId === conversationId)) return;
                        this.alertNewMessage(senderId, conversationName, conversationId, message, userName);
                    });

                    this.Connection.on('NotifyNewChat', (senderId, conversationName, conversationId, content, userName) => {
                        // console.log("NotifyNewChat", senderId, conversationName, conversationId, content, userName)
                        if (senderId === this.CurrentUser.id) return;
                        this.NotifyNewChat(senderId, conversationName, conversationId, content, userName);
                    });

                    this.Connection.on('ReloadConversation', (conversationId, content, userName) => {
                        // console.log("ReloadConversation", conversationId, content, userName)
                        this.ReloadConversation(conversationId, content, userName);
                    });

                    this.Connection.on('SendNotification',(notify,fiberHouse)=>{
                        console.log("New Notification : ",notify)
                        this.NewNotification({
                            ...notify,
                            fiberHouse:fiberHouse
                        });
                    })

                    this.Connection.on('SendRepLocation',(data)=>{
                        console.log("New RepLocation : ",data)
                        if(this.SendRepLocation != null)
                        {
                            console.log("Sending Rep Location")
                            this.SendRepLocation(data);
                        }
                    })

                    this.Connection.invoke("Connect", this.CurrentUser.id)
                        .catch(err => {


                        }
                        );

                
                })
                .catch(err => {


                }
                );



        }
    }

    NotifyNewChat(userName, conversationName, conversationType) {
        Swal.fire({
            title: 'New Chat',
            text: userName + " added you to " + conversationName,
            icon: 'info',
            confirmButtonText: 'Ok',
            position: 'top-end',
            toast: true,
        });
        // console.log(this.newChat, conversationType)
        if (this.newChat != null) {
            this.newChat(conversationType);
        }
    }


    alertNewMessage(senderId, conversationName, conversationId, message, userName) {
        let msg = JSON.parse(message);
        // console.log("New Message",content,files,voiceNote)
        Swal.fire({
            title: conversationName,
            text: userName + " : " + msg.content,
            icon: 'info',
            confirmButtonText: 'Ok',
            position: 'top-end',
            toast: true,
        });
    }



    ReloadConversation(senderId, conversationName, conversationId, message, userName, profileImage) {
        // console.log(conversationId, this.ConversationLoaders[0].conversationId);
        let msg = JSON.parse(message);
        msg = Object.keys(msg).reduce((result, key) => {
            let newKey = key.charAt(0).toLowerCase() + key.slice(1);
            result[newKey] = msg[key];
            return result;
          }, {});
        // console.log("ReloadConversation", conversationId, msg.content, userName, this.ConversationLoaders);
        if (this.ConversationLoaders.length == 0) {
            Swal.fire({
                title: 'New Message from ' + userName,
                text: msg.content,
                icon: 'info',
                confirmButtonText: 'Ok',
                position: 'top-end',
                toast: true,
                timer: 3000,
            });
        }
        else {

            if (this.ConversationLoaders[0].conversationId === conversationId) {
                this.newMessage(senderId, conversationName, conversationId, msg, userName, profileImage);
            }
            else {

                Swal.fire({
                    title: 'New Message',
                    text: msg.content,
                    icon: 'info',
                    confirmButtonText: 'Ok',
                    position: 'top-end',
                    toast: true,
                    timer: 3000,
                });
            }

        }

    }

    NewNotification(notify){
        console.log(notify)
        this.newNotification(notify);
    }


    async StopConnection() {
        if (this.Connection != null) {
            this.Connection.invoke("Disconnect", this.CurrentUser.id);
            this.Connection.stop();
            this.Connection = null;
        }
    }
}

export default ConnectionService;