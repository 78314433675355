class SearchCustomerFeedbackReq{
    constructor(){
        this.id = null;
        this.fidiumCustomerId = null;
         this.fidiumCustomerIds = null;
        this.salesRepId = null;
        this.salesOrgId = null;
        this.content = null;
        this.timestamp = null;
        this.rating = null;
        this.feedbackType = null;
        this.videoId = null;
        this.speedTestId = null;
        this.leadId = null;
        this.video = null;
        this.speedTest = null;
        this.fromDate = null;
        this.toDate = null;
        this.pagingParams = {
            "pageNumber": null,
            "pageSize": null
        };
    }
}

export default SearchCustomerFeedbackReq;