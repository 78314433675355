import profile1 from "./salesRepImages/Chris Winchester.png"
// import profile2 from "./salesRepImages/Wilder LaLonde.png"
import profile3 from "./salesRepImages/Jessica Man.png"
// import profile4 from "./salesRepImages/Connor Mullikin.png"
// import profile5 from "./salesRepImages/Samuel Tripp.png"
import profile6 from "./salesRepImages/Brett Halliday.png"
// import profile7 from "./salesRepImages/Ben Brandt.png"
import profile8 from "./salesRepImages/Blaine Wilson.png"
// import profile9 from "./salesRepImages/Christian Perez.png"
// import DavidHawk from "./salesRepImages/David Hawk.png"
// import ElizaScott from "./salesRepImages/Eliza Scott.png"
// import GaberielRobbins from "./salesRepImages/Gaberiel Robbins.png"
// import EricGenesoto from "./salesRepImages/Eric Genesoto.png"
import FredJohns from "./salesRepImages/Fred Johns.png"
// import GarySwearingen from "./salesRepImages/Gary Swearingen.png"
import GrantGatski from "./salesRepImages/Grant Gatski.png"
// import JamesYardley from "./salesRepImages/James Yardley.png"
// import JohnOwens from "./salesRepImages/John Owens.png"
import MarkDiFerdinando from "./salesRepImages/Mark DiFerdinando.png"
// import MasonSnyder from "./salesRepImages/Mason Snyder.png"
// import NateWalton from "./salesRepImages/Nate Walton.png"
import NoahEubanks from "./salesRepImages/Noah Eubanks.png"
import NomeMcBride from "./salesRepImages/Nome McBride.png"
import RichLadellAndersen from "./salesRepImages/Rich Ladell Andersen.png"
// import ShastaWeishampel from "./salesRepImages/Shasta Weishampel.png"
const salesRepReviews = [
    {
        id : 3,
        content:"Working for Lightning OS, LLC as a fiber sales rep is an absolute blast! The money? It's great, with solid commissions and bonuses that make hitting targets feel like winning the lottery. The team support is top-notch, with colleagues who feel more like family, always there to lend a hand or share a tip. And the culture? It's vibrant and infectious, driven by a passion for excellence and a commitment to making a positive impact. Lightning OS isn't just a workplace; it's a place where you'll thrive and feel positively electrified! ",
        firstName:"Jessica",
        lastName:"M.",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:profile3
    },
    {
        id: 4,
        firstName :"Mark",
        lastName:"D.",
        content:"Just a few days ago, I was a high school grad with zero sales experience. Fast forward to now, and I'm crushing it at Lightning OS, LLC! I took a big leap, drove 12 hours to join the team, and it's been the best decision ever. The support from my colleagues is unreal, and the vibe here is electric! I'm beyond thankful for this opportunity. Lightning OS, you're awesome! ⚡️",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:MarkDiFerdinando
    },
    {
        id : 1,
        firstName :"Nome",
        lastName:'M.',
        content:"Let me share my journey with you—a journey that began with a chance encounter and led to incredible success. You see, I've known Shasta; the mastermind behind Lightning OS, LLC, for years. I had followed his continued success and wondered what would happen if we ever worked together. So when he approached me with this golden opportunity, I jumped right on board and knew I was in for something special. Now working here for nearly a full year, I've seen firsthand what Shasta is capable of. After joining Lightning OS without hesitation, I found that I was loving this work and dove headfirst into this new adventure. And what a ride it's been! In my best month so far, I raked in a cool $35,800. But I didn't stop there - While I've been consistently hitting those numbers myself I also started building a rock-solid team right here in Texas. We are all seeing massive success! Joining forces with Lightning OS was the best decision I've ever made. With Shasta at the helm, and the incredible opportunity we have right now; I know the sky's the limit. So if you're ready to turn dreams into reality, come join us. Let's make magic happen together! ✨.",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:NomeMcBride
    },
    {
        id : 2,
        firstName :"Grant",
        lastName:"G.",
        feedbackType:"Written",
        type:"Fiber Specialist",
        content:"Fresh out of college, I never imagined myself doing door-to-door sales. But Lightning OS, LLC changed all that! Thanks to a tip from a former teacher-turned-colleague, I took the plunge—and boy, am I glad I did! In just three weeks, I've pocketed over $5300.The support and training here are top-notch, making even a newbie like me feel like a pro. Lightning OS, you're the real deal! 💫",
        profileImage:GrantGatski
    },
    {
        id: 5,
        firstName:"Fred",
        lastName:"J.",
        content:"Former pilot turned door-to-door dynamo here, and let me tell you, Lightning OS, LLC is where it's at! In just five months, I've banked close to $72,000. The flexibility is unbeatable—I work hard for three weeks, then enjoy a week of well-deserved vacation.I've known Shasta, the owner, for years, and when he approached me with this opportunity, I jumped at it. His leadership is top-notch, and I'm already building my dream team.Join me at Lightning OS, where success is within reach and the potential is limitless. Let's make waves together! ⚡️",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:FredJohns
    },
    {
        id: 6,
        firstName:"Rich",
        lastName:"A.",
        content:"Let me tell you about a game-changer in my career. I've been in the door-to-door sales game for a while now, mainly selling alarms and solar. But let's be real—the turnover was killing me, and the constant stream of 'nos' was wearing thin.Then, a dear friend approached me with a fiber opportunity, and seeing the success he and his brother were having, I thought, 'Why not?'Well, let me tell you, it was the best decision I've made in a long time. On my first day, I snagged six yeses—six! And after just a week on the job, I'm consistently pulling in 10 or more yeses with less than full-time hours.The difference is like night and day. Lightning OS, LLC has transformed the way I approach sales, and I couldn't be happier. If you're tired of the grind and ready for real results, join me at Lightning OS. It's where success is not just a possibility—it's the norm. Let's make it happen! 💪",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:RichLadellAndersen
    },
    {
        id: 7,
        firstName:"Chris",
        lastName:"W.",
        content:"So, picture this: I'm cruising through the bright lights of Vegas, selling alarms and thinking I've got it all figured out. Then, out of the blue, my brother drops this fiber gig from Lightning OS, LLC in my lap.Now, I'm not one to shy away from an adventure, so I packed up my car and hit the open road, bound for the promise of fiber riches.And let me tell you, it was one heck of a ride! But when I finally rolled into town and gave this fiber thing a shot, it was like hitting the jackpot!More money, more fun, and a team that feels like family? Lightning OS, LLC has got it all. And with my brother by my side, we're tearing up the sales game like never before.So, if you're itching for a change of pace and a whole lot of excitement, Lightning OS is the place to be. Trust me, you won't regret it!",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:profile1
    },
    {
        id: 8,
        firstName:"Blaine",
        lastName:"W.",
        content:"Let me tell you about my journey with Lightning OS, LLC—it's been one wild ride! So, Shasta and I have been circling the same circus for years, and when he called me up and invited me to Texas, I jumped at the opportunity.And let me tell you, the timing couldn't have been better. I was stuck in a job I hated, barely making ends meet. Now, I'll admit, door-to-door sales were totally new to me. But with Shasta's guidance and the support of the team, I've been leading the charge since November. And get this—I've been taking vacations for a week or two every month! Who knew making money could be so darn fun?Best part? I've been able to help out my mom and my sister, and I'm about to buy myself a brand spankin' new car. Lightning OS, LLC ain't just a job—it's a game-changer.So, if you're stuck in a rut like I was, take it from me—join Lightning OS and buckle up for the adventure of a lifetime! ",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:profile8
    },
    {
        id : 22,
        firstName :"Noah",
        lastName:"E.",
        content:"I'm Noah, the tech-savvy entrepreneur that stumbled into Lightning OS by accident—or maybe it was fate! You see, I was minding my own business at a networking event when I met this guy who introduced me to Shasta.Shasta saw something in me—probably my youthful charm—and decided to give me a shot.I already had my own tech biz, but when Shasta offered me the chance to sell fiber part-time, I thought, 'Easy money, right?' Turns out, I was right!Now I'm making over 10k a month working part-time! Who knew selling fiber could be so lucrative? Plus, the friendships, leadership, and training I've received are priceless.So, if you're thinking about joining Lightningos.world, take it from me—give it a shot! You never know where it might take you. Plus, who doesn't love a little extra cash to fund their tech dreams, am I right? 😉",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:NoahEubanks
    },

    {
        id: 9,
        firstName:"Brett",
        lastName:"H.",
        content:"I've been on this incredible journey with Lightningos.world since day one. When Shasta first approached me about this fiber opportunity 10 months ago, I knew it was something special. I've known Shasta for over 15 years, and his vision and drive have always impressed me. Since then, I've been actively engaging with customers across various states and locations, spearheading our sales efforts. I must say, seeing the team's rapid expansion has been truly exhilarating. The enthusiasm, the drive—it's like catching lightning in a bottle!But it's not just about the sales for me. Thanks to this fiber opportunity, I'm able to provide so much more for my fiancée and our 4-year-old daughter back in the Philippines. And the best part? We're in the process of bringing our family together here in the USA, all because of Lightningos.world.Here's to the past 10 months and to many more ahead. Cheers to Lightningos.world, where success is the only option! ",
        feedbackType:"Written",
        type:"Fiber Specialist",
        profileImage:profile6
    },
   
   
  
]
export default salesRepReviews;