import { Modal } from '@mui/material'
import React, { useState } from 'react'
import UserSelector from '../../../components/UserSelector'
import Swal from 'sweetalert2'
import MasterSheetApi from '../../../API/MasterSheetApi'
import { CiCircleRemove } from 'react-icons/ci'
import { useTabs } from './TabContext'
const ManageAccess = ({ file, open, setOpen }) => {

    const [salesRepId, setSalesRepId] = useState(null)
    const [salesRepObjet, setSalesRepObject] = useState(null)
    const {setFiles,getAllDynamicLists} = useTabs()

    const handleAddSalesRep = async () => {
        Swal.fire({
            icon:"info",
            title:"Please Wait", 
            text:"We are adding the sales rep",
            allowOutsideClick:false,
            allowEscapeKey:false,
            allowEnterKey:false,
            showConfirmButton:false,
            showCancelButton:false,
            willOpen:()=>{
                Swal.showLoading();
            }
        })

        if(salesRepId === null || salesRepId.length === 0){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Please select a sales rep"
            })
            return
        }

        const req = {
            dynamicListId:file.id,
            userIds:salesRepId
        }

        const res = await MasterSheetApi.AssignDynamicListToUser(req)
        if(res?.status?.success){
            Swal.fire({
                icon:"success",
                title:"Success",
                text:"Sales rep added successfully"
            })

         
            getAllDynamicLists()
            setOpen(false)
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"An error occured while adding the sales rep"
            })
        }
    }

    const handleRemoveSalesRep = async (userId) => {
        Swal.fire({
            icon:"info",
            title:"Please Wait", 
            text:"We are removing the sales rep",
            allowOutsideClick:false,
            allowEscapeKey:false,
            allowEnterKey:false,
            showConfirmButton:false,
            showCancelButton:false,
            willOpen:()=>{
                Swal.showLoading();
            }
        })

        const req = {
            id1:file.id,
            id2:userId
        }

        const res = await MasterSheetApi.RemoveDynamicListFromUser(req)
        if(res?.status?.success){
            Swal.fire({
                icon:"success",
                title:"Success",
                text:"Sales rep removed successfully"
            })
            setFiles(prev=>prev.map(f=>f.id === file.id ? {...f,dynamicListUsers:f.dynamicListUsers.filter(u=>u.user.id !== userId)}:f))
            setOpen(false)
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"An error occured while removing the sales rep"
            })
        }
    
    }

    return (
        <Modal open={open} onClose={()=>{
            setOpen(false)
        }}>
            <div style={{
                width:"min(500px,80vw)"
            }} className="absolute bg-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border-t border-gray-200 p-8 rounded-lg">
                <div className="mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Manage Access</h3>
                    <p className="text-sm text-gray-500">
                        Control who has access to &quot;{file?.name}&quot;.
                    </p>
                </div>

                <div className='my-4 '>
                    <UserSelector setUserWithName={setSalesRepObject} placeholder='Select Sales Reps' setUser={setSalesRepId} conversationType={'Group'} userTypes={["SalesRep","SalesOrgAdmin"]}  />
                    <button onClick={()=>{
                        handleAddSalesRep()
                    }} className='bg-blue-500 text-white p-2 rounded-lg w-full mt-4'>Add</button>
                </div>

                <div className="space-y-4">
                    {file?.dynamicListUsers?.map(({user}) => (
                        
                        <div key={user.id} className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                                <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                                    <span className="text-sm font-medium text-gray-600">
                                        {user?.firstName?.charAt(0).toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-900">{user.firstName+" "+user.lastName}</p>
                                    <p className="text-sm text-gray-500">{user.email}</p>
                                </div>
                            </div>
                            <button
                                className="rounded-full  text-gray-500  hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                onClick={()=>{
                                    handleRemoveSalesRep(user.id)
                                }}
                            >
                               <CiCircleRemove size={30} className="text-gray-500 hover:text-white"  />
                            </button>
                        </div>
                    ))}
                </div>
                <button
                      onClick={() => setOpen(false)}
                    className="mt-4 w-full rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    Done
                </button>
            </div>
        </Modal>
    )
}

export default ManageAccess