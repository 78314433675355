import React from 'react'
import { Rating } from '@mui/material'
import greyUser from './../../../../assets/images/userGrey.png'
import Modal from '../../../../components/Modal'
import { IoIosCloseCircleOutline } from "react-icons/io";
const SpeedTestReview = ({ review }) => {
    const [beforeExpanded, setBeforeExpanded] = React.useState(false)
    // const [afterExpanded, setAfterExpanded] = React.useState(false)
    return (
        <div className='speed_test_review'>

            <Modal setOpen={setBeforeExpanded} open={beforeExpanded}>
                <div className='expanded_image'>
                    <button onClick={() => setBeforeExpanded(false)}><IoIosCloseCircleOutline color='red' size={30} /></button>
                    <div style={{
                        display:"grid",
                        gridTemplateColumns:"1fr 1fr",
                    }}>
                    <img alt='beforeImage' src={ review?.speedTest?.beforeImage?.url} />
                    <img alt='afterImage' src={review?.speedTest?.afterImage?.url} />
                    </div>
                </div>
            </Modal>
            {/* <Modal setOpen={setAfterExpanded} open={afterExpanded}>
                <div className='expanded_image'>
                    <button onClick={() => setAfterExpanded(false)}><IoIosCloseCircleOutline color='red' size={30} /></button>
                    
                </div>
            </Modal> */}
            <div className='top'>
                <div className='speed_test_header'>
                    <div className='profile'>
                        <img alt='avatar' src={review.fidiumCustomer?.profileImage ? review.fidiumCustomer?.profileImage : greyUser} />
                    </div>
                    <div className='info'>
                        <h3>{review.fidiumCustomer?.firstName + " " + review.fidiumCustomer?.lastName}</h3>
                        <p>
                            {review.fidiumCustomer?.address?.length > 32
                                ? review.fidiumCustomer?.address.substring(0, 32) + "..."
                                : review.fidiumCustomer?.address}
                        </p>
                    </div>
                </div>
                <Rating name="read-only" value={review.rating} readOnly />
                {/* <div>
                    <img src="/images/NetworkTeam/fidium.png" />
                </div> */}
            </div>
            <div className='before_after_images'>
                <div className='con'>
                    <div onClick={() => setBeforeExpanded(true)} className={`before ${beforeExpanded && 'expanded'}`}>
                        <img alt='beforeImage' src={review?.speedTest?.beforeImage?.url} />
                    </div>
                    <p>Before</p>
                </div>
                <div className='con'>
                    <div onClick={() => setBeforeExpanded(true)} className={`before ${beforeExpanded && 'expanded'}`}>
                        <img alt='afterImage' src={review?.speedTest?.afterImage?.url} />
                    </div>
                    <p>After</p>
                </div>
            </div>
        </div>
    )
}

export default SpeedTestReview