import React from 'react'
import { FaCheck } from 'react-icons/fa'

const FreshGraduates = ({setOpen}) => {

    const list = [
        'In-depth knowledge of cutting-edge fiber internet products',
        'Professional Training',
        'Effective sales techniques and customer engagement strategies',
        'Real-World Experience',
        'Networking opportunities with industry leaders',
        'Exciting Rewards',
        'Personal and professional growth through mentorship and coaching',
        'Career Growth'
    ]

    return (
        <div className='fresh_graduates'>
            <h1>Fresh graduates are <br />encouraged to apply</h1>
            <p>Already experienced in sales? Join us to boost your sales career!</p>
            <div className='main_image'>
                <img className='left' src='/images/summer/sun.png' alt='sun' />
                <img className='main' src='/images/summer/graduate.png' alt='fresh_graduates' />
                <img className='right' src='/images/summer/sidewave_full.png' alt='sidewave' />
            </div>
            <div className='what_you_learn'>
                <h1>What you'll learn?</h1>
                <div className='bullets'>
                    {
                        list.map((item, index) => {
                            return <div key={index} className='bullet'>
                                <FaCheck size={15} color='#FC1C0F' />
                                <p>{item}</p>
                            </div>
                        })
                    }
                </div>
                <button onClick={
                    () => {
                        setOpen(true)
                    }
                }>Apply Now</button>
            </div>
        </div>
    )
}

export default FreshGraduates