import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class FiberPlanApi{
    baseUrl = EnvSettings.url;
    CreateFiberPlanUrl = "CreateFiberItem";
    SearchFiberPlanUrl = "SearchFiberItems";
    DeleteFiberPlanUrl = "DeleteFiberItem";
    EditFiberPlanUrl = "EditFiberItem";
    CreateFiberCommissionUrl = "CreateFiberCommission";
    EditFiberCommissionUrl = "EditFiberCommission";
    GetFiberPlanOffersUrl = "GetFiberPlanOffers";
    async CreateFiberPlan(req)
    {
        const res = await fetch(this.baseUrl + this.CreateFiberPlanUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberPlans(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberPlanUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteFiberPlan(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteFiberPlanUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    async EditFiberPlan(req)
    {
        const res = await fetch(this.baseUrl + this.EditFiberPlanUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddFiberPlanToCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.CreateFiberCommissionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async EditFiberPlanToCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.EditFiberCommissionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async GetFiberPlanOffers(req){
        let response = await fetch(this.baseUrl + this.GetFiberPlanOffersUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();   
    }
}
const fiberPlanApi = new FiberPlanApi();
export default fiberPlanApi;