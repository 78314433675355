import React, { useContext, useEffect, useRef } from 'react'
import { UserContext } from '../../../../App'
import { ReactSortable } from 'react-sortablejs'
import SellingCardApi from '../../../../API/SellingCardApi'
import { MdSwapVerticalCircle } from 'react-icons/md'
import { IoIosAddCircle } from 'react-icons/io'
const Steps = ({ update, setUpdate, cardId, s, noOfSteps = 0, currentStep, card, setCurrentStep, searchTutorial, steps, setSteps }) => {
    // const steps = Array.from({ length: noOfSteps }, (_, i) => i + 1)
    const user = useContext(UserContext)
    // const [steps, setSteps] = React.useState(s)
    // const [steps, setSteps] = React.useState([])
    const initialRender = useRef(true)
    const sortable = useRef(null)
    React.useEffect(() => {
        console.log(steps)
        if (initialRender.current) {
            initialRender.current = false
            return
        }
        else {
            updateBlockNumber(steps)
        }

    }, [steps]);




    const updateBlockNumber = async (newList) => {
        const req = {
            sellingCardId: cardId,
            blockNumbers: {

            }
        }
        newList.forEach((step, index) => {
            req.blockNumbers[step.blockId] = index
        })
        const res = await SellingCardApi.ChangeSellingCardBlockNumbers(req)

        if (res?.status?.success) {
            console.log('Block numbers updated')
             //searchTutorial()
        }

    }



    return (
        <>
            <div className='stepsNo'>
                {
                    (user.userType === "SuperAdmin" || (user.userType === "SalesOrgAdmin" && user.salesOrgId === card.salesOrgId)) ?
                        <ReactSortable ref={sortable} className='sort_container' list={steps} setList={(newList) => { setSteps(newList) }} >
                            {
                                steps.map((step, index) => {
                                    return (
                                        <div className='step_container' key={step?.id}>
                                            <div onClick={() => setCurrentStep(step.blockNumber)} className={`step ${currentStep === step.blockNumber ? "active" : ""}`}>{index + 1}</div>
                                            {user.userType !== "SuperAdmin" && index < steps.length - 1 && <div className="line"></div>}
                                            {
                                                user.userType === "SuperAdmin" && <div className="line"></div>
                                            }
                                        </div>
                                    )
                                })

                            }
                        </ReactSortable>
                        :
                        <div className='sort_container'>
                            {
                                steps.map((step, index) => {
                                    return (
                                        <div className='step_container' key={step?.id}>
                                            <div onClick={() => setCurrentStep(step.blockNumber)} className={`step ${currentStep === step.blockNumber ? "active" : ""}`}>{index + 1}</div>
                                            {(user.userType !== "SuperAdmin") && index < steps.length - 1 && <div className="line"></div>}

                                        </div>
                                    )
                                })
                            }
                        </div>
                }
                {
                    (user.userType === "SuperAdmin" || (user.userType === "SalesOrgAdmin" && user.salesOrgId === card.salesOrgId)) && <div className="line"></div>
                }
                {

                        (user.userType === "SuperAdmin" || (user.userType === "SalesOrgAdmin" && user.salesOrgId === card.salesOrgId)) && <div onClick={() => setCurrentStep(-1)} className={`step ${currentStep === -1 ? "active" : ""}`}>+</div>
                    
                }
            </div>
            {/* {(user.userType==="SuperAdmin" || (user.userType==="SalesOrgAdmin" && user.salesOrgId === card.salesOrgId))  &&
                    <div onClick={() => setCurrentStep(-1)} className={` newStepCardContainer step ${currentStep === -1 ? "active" : ""}`}>
                            <IoIosAddCircle size={23} style={{ marginRight: 8, color: '#2761D0' }} />
                        <h2>Add New Step</h2>
                    </div>
            } */}
            {
                update && <div className="arrow_buttons">
                    <MdSwapVerticalCircle style={{
                        cursor: "pointer",
                    }} onClick={() => {
                        const newSteps = [...steps]
                        const temp = newSteps.filter(step => step.blockNumber === currentStep)[0]
                        const tempIndex = newSteps.indexOf(temp)
                        if (tempIndex > 0) {
                            newSteps[tempIndex] = newSteps[tempIndex - 1]
                            newSteps[tempIndex - 1] = temp
                            setSteps(newSteps)
                        }

                    }} color="#181E4B" size={40} />
                    <MdSwapVerticalCircle style={{
                        cursor: "pointer",
                    }} onClick={() => {

                        const newSteps = [...steps]
                        const temp = newSteps.filter(step => step.blockNumber === currentStep)[0]
                        const tempIndex = newSteps.indexOf(temp)
                        if (tempIndex < newSteps.length - 1) {
                            newSteps[tempIndex] = newSteps[tempIndex + 1]
                            newSteps[tempIndex + 1] = temp
                            setSteps(newSteps)
                        }

                    }} color="#181E4B" size={40} />
                </div>
            }
        </>
    )
}

export default Steps