class ChangeUserStatusReq{
    constructor(){
        this.int = null;
        this.string = null;
        this.pagingParams ={
            pageNumber: 1,
            pageSize: 10,
        }
    }
}

export default ChangeUserStatusReq;