import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async';
import OrgApi from './../../API/OrgApi';
import SoftTypography from './../SoftTypography';
import SearchOfferTemplatereq from '../../Requests/Offer/SearchOfferTemplateReq';
const Index = ({setUser,disabled=false,placeholder="Select a parent Organization",className,multiple}) => {
    const [UsersSearch, setUserSearch] = useState()
    const [options, setOptions] = useState([])

    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
        };
        getOptions();
    }, []);

    const GetOrg = async () => {
        let searchReq = new SearchOfferTemplatereq();
        // searchReq.userType = "company"
        if(UsersSearch)
        searchReq.name = UsersSearch;

        
        let res = await OrgApi.SearchOrg(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.name,id:item.id })
            })
            optionsArr.unshift({ value: null, label: "None", id: null });

            return optionsArr;
        }
        else {
          
        }
    }


    return (
        <div className={'selector'}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
             
                <AsyncSelect
                    defaultOptions={options} 
                    cacheOptions
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    onChange={(e) => {
                        if(multiple){
                            setUser(e.map((item)=>item.id))
                        }
                        else{
                            setUser(e.id)
                        }
                    }}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isMulti={multiple}
                />
            </SoftTypography>

        </div>
    )
}

export default Index