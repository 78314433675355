import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class FiberCompanyApi{
    baseUrl = EnvSettings.url;
    CreateFiberCompanyUrl = "CreateFiberCompany";
    SearchFiberCompanyUrl = "SearchFiberCompanies";
    DeleteFiberCompanyUrl = "DeleteFiberCompany";
    EditFiberCompanyUrl = "EditFiberCompany";
    GetCompanyFormUrl = "GetCompanyForm";
    CreateCompanyFormUrl = "CreateCompanyForm";
    EditCompanyFormUrl = "EditCompanyForm";
    UpdateSalesOrgsForFiberCompanyUrl = "UpdateSalesOrgsForFiberCompany";
    GetAssignedSalesOrgsForFiberCompanyUrl = "GetAssignedSalesOrgsForFiberCompany";
    SearchFiberCompanyBySalesOrgUrl = "GetAssignedFiberCompaniesSalesOrg"
    RemoveSalesOrgFromFiberCompanyUrl = "RemoveSalesOrgFromFiberCompany";
    AddSalesRepToFiberCompanyUrl="AddSalesRepsForFiberCompany";
    RemoveSalesRepFromFiberCompanyUrl = "RemoveSalesRepFromFiberCompany";


    GetRecruitTemplatesUrl = "GetRecruitTemplates";
    AddRecruitTemplateUrl = "AddRecruitTemplate";
    UpdateRecruitTemplateUrl = "UpdateRecruitTemplate";
    DeleteRecruitTemplateUrl = "DeleteRecruitTemplate";
    GetCompanyConsultationsUrl = "GetCompanyConsultations";
    UpdateRecruitTemplateVisibilityUrl = "UpdateRecruitTemplateVisibility";

    async EditCompanyForm(req)
    {
        const res = await fetch(this.baseUrl + this.EditCompanyFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateCompanyForm(req)
    {
        const res = await fetch(this.baseUrl + this.CreateCompanyFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetCompanyForm(req)
    {
        const res = await fetch(this.baseUrl + this.GetCompanyFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.CreateFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            },
            body:req
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.EditFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            },
            body:req
        })
        const data = await res.json();
        return data;
    }

    async UpdateSalesOrgsForFiberCompany(req) {
        try {
            const res = await fetch(this.baseUrl + this.UpdateSalesOrgsForFiberCompanyUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set content type to JSON
                    Authorization: `Bearer ${UsersApi.GetToken()}` // Bearer token
                },
                body: JSON.stringify(req) // Convert request object to JSON string
            });
    
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
    
            const data = await res.json();
            return data;
        } catch (error) {
            console.error('Error in UpdateSalesOrgsForFiberCompany:', error);
            return { status: { success: false, message: error.message } };
        }
    }

    async GetSalesOrgsForFiberCompany(req) {
        try {
            const res = await fetch(this.baseUrl + this.GetAssignedSalesOrgsForFiberCompanyUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set content type to JSON
                    Authorization: `Bearer ${UsersApi.GetToken()}` // Bearer token
                },
                body: JSON.stringify(req) // Convert request object to JSON string
            });
    
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
    
            const data = await res.json();
            return data;
        } catch (error) {
            console.error('Error in GetSalesOrgsForFiberCompany:', error);
            return { status: { success: false, message: error.message } };
        }   
    }

    async SearchFiberCompanyBySalesOrg(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberCompanyBySalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    async RemoveSalesOrgFromFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveSalesOrgFromFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    async AddSalesRepToFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.AddSalesRepToFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveSalesRepFromFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveSalesRepFromFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //get recruite templates
    async GetRecruitTemplates()
    {
        const res = await fetch(this.baseUrl + this.GetRecruitTemplatesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            }
        })
        const data = await res.json();
        return data;
    }

    // add recruite templates
    async AddRecruitTemplate(req)
    {
        const res = await fetch(this.baseUrl + this.AddRecruitTemplateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    //UpdateRecruitTemplate
    async UpdateRecruitTemplate(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateRecruitTemplateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //DeleteRecruitTemplate
    async DeleteRecruitTemplate(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteRecruitTemplateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //GetCompanyConsultations
    async GetCompanyConsultations(req)
    {
        const res = await fetch(this.baseUrl + this.GetCompanyConsultationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //UpdateRecruitTemplateVisibility
    async UpdateRecruitTemplateVisibility(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateRecruitTemplateVisibilityUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

}

export default new FiberCompanyApi();