import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import './IssueCancelDetails.scss';
import PhoneInput from 'react-phone-input-2';
import Swal from 'sweetalert2';
import CustomerApi from '../../../../API/CustomerApi';
import Layout from '../../../../Layouts/Layout';
import StringIntReq from '../../../../Requests/StringIntReq';
import { CircularProgress } from '@mui/material';
import { IoArrowBack } from 'react-icons/io5';

const CancelDetailsPage = () => {
    const { id } = useParams();
    const [issueDetails, setIssueDetails] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [subAnswer, setSubAnswer] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIssueDetails = async () => {
            console.log("issueId: ", id);
            let req = new StringIntReq();
            req.int = id;
            const res = await CustomerApi.GetCancelationFormById(req);
            console.log(res);
            if (res?.status?.success) {
                setIssueDetails(res.data);
                setPhoneNumber(res.data.phoneNumber);
                setFormOptions(res.data);
                setIsLoading(false);
            }
        };
        fetchIssueDetails();
    }, [id, setIsLoading]);

    const setFormOptions = (data) => {
        if (data.payLess) {
            setSelectedOption('PayLess');
            setSubAnswer(data.payLessSubAnswer);
        } else if (data.bundledService) {
            setSelectedOption('BundledService');
            setSubAnswer(data.bundledServiceSubAnswer);
        } else if (data.inContract) {
            setSelectedOption('InContract');
            setSubAnswer(data.inContractSubAnswer);
        } else if (data.other) {
            setSelectedOption('OtherReason');
            setSubAnswer(data.otherSubAnswer);
        } else if (data.tooBusy) {
            setSelectedOption('TooBusy');
        } else if (data.movingSoon) {
            setSelectedOption('MovingSoon');
        } else if (data.spouse) {
            setSelectedOption('DecisionMaker');
        }
    };

    const handleForceResolve = async () => {
        setIsLoading(true);
        let req = new StringIntReq();
        req.int = id;
        req.string = "Cancelation";
        const res = await CustomerApi.ForceResolveIssue(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Issue Resolved",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    window.location.reload();
                }
            });
        } else {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        }
    };

    const handleConfirmCancel = async () => {
        setIsLoading(true);

        let req = new StringIntReq();

        req.int = id
        //ConfirmCancelation

        const res = await CustomerApi.ConfirmCancelation(req);

        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Cancelation Confirmed",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    window.location.reload();

                }
            });
        } else {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {

                    Swal.hideLoading();
                }
            });
        }
    };

    const handleMainOptionChange = (option) => {
        setSelectedOption(option);
        setSubAnswer("");
    };

    return (
        <Layout>
            {isLoading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "100%",
                    height: "100%"
                }}>
                    <CircularProgress size={50} sx={{
                        color: "var(--primary-color)"
                    }} />
                </div>
                :
                <>
                    <IoArrowBack size={30} color='black' style={{cursor: 'pointer',marginLeft:"1rem"}} onClick={() => navigate(-1)} />
                    <div className='cancel_form_container'>
                        <h1 className='title'>Cancellation Issue</h1>
                        <div className='inputs'>

                            <div className='inputDiv'>
                                <label className='phoneLabel' htmlFor="payless">Customer Name</label>
                                <div className='inputWrapper'>
                                    <input type="text" value={issueDetails?.fidiumCustomer?.firstName + " " + issueDetails?.fidiumCustomer?.lastName} disabled={true} />
                                </div>
                            </div>
                            <label className='phoneLabel' htmlFor="phoneNumber"> Phone Number</label>
                            <PhoneInput
                                country={'us'}
                                value={PhoneNumber}
                                onChange={setPhoneNumber}
                                excludeCountries={['il']}
                                style={{ width: "100%" }}
                                specialLabel='Phone Number'
                                inputStyle={{ paddingLeft: "3rem", width: "100%" }}
                                disabled={true}
                            />
                            <h1 className='subtitle'>What is your reason for canceling your Free Lightcurve Installation?</h1>
                            <div className='inputDiv'>
                                <input
                                    type="checkbox"
                                    name="PayLess"
                                    id="payless"
                                    checked={selectedOption === 'PayLess'}
                                    onChange={() => handleMainOptionChange('PayLess')}
                                    disabled={issueDetails?.isResolved || selectedOption !== 'PayLess'}
                                />
                                <label htmlFor="payless">Paying Less w/ Another Internet Service Provider (ISP)</label>
                            </div>
                            {selectedOption === 'PayLess' && (
                                <div className='subInput'>
                                    <label htmlFor="ispdetails">It’s possible we can beat their price! Who is your ISP? How many mbps is your plan? And finally, what is your monthly cost?</label>
                                    <div className='inputWrapper'>
                                        <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your answers here" disabled={true} />
                                    </div>
                                </div>
                            )}
                            <div className='inputDiv'>
                                <input
                                    type="checkbox"
                                    name="BundledService"
                                    id="bundledservice"
                                    checked={selectedOption === 'BundledService'}
                                    onChange={() => handleMainOptionChange('BundledService')}
                                    disabled={issueDetails?.isResolved || selectedOption !== 'BundledService'}
                                />
                                <label htmlFor="bundledservice">Bundled Service w/ Another ISP</label>
                            </div>
                            {selectedOption === 'BundledService' && (
                                <div className='subInput'>
                                    <label htmlFor="bundledispdetails">Lightcurve also has bundled options, and can most likely beat their price! Who is your ISP? How many mbps is your plan? And finally, what is your monthly cost?</label>
                                    <div className='inputWrapper'>
                                        <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your answers here" disabled={true} />
                                    </div>
                                </div>
                            )}
                            <div className='inputDiv'>
                                <input
                                    type="checkbox"
                                    name="InContract"
                                    id="incontract"
                                    checked={selectedOption === 'InContract'}
                                    onChange={() => handleMainOptionChange('InContract')}
                                    disabled={issueDetails?.isResolved || selectedOption !== 'InContract'}
                                />
                                <label htmlFor="incontract">We’re in Contract w/ Another ISP.</label>
                            </div>
                            {selectedOption === 'InContract' && (
                                <div className='subInput'>
                                    <label htmlFor="contractdetails">We often assist with early termination fees! What ISP are you in contract with? How many months are remaining on your contract? Do you know the early termination fee? If yes, how much to end your contract early?</label>
                                    <div className='inputWrapper'>
                                        <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your answers here" disabled={true} />
                                    </div>
                                </div>
                            )}
                            <div className='inputDiv'>
                                <input
                                    type="checkbox"
                                    name="DecisionMaker"
                                    id="decisionmaker"
                                    checked={selectedOption === 'DecisionMaker'}
                                    onChange={() => handleMainOptionChange('DecisionMaker')}
                                    disabled={issueDetails?.isResolved || selectedOption !== 'DecisionMaker'}
                                />
                                <label htmlFor="decisionmaker">Spouse or Another Decision Maker Doesn’t Want to Switch Providers.</label>
                            </div>
                            <div className='inputDiv'>
                                <input
                                    type="checkbox"
                                    name="MovingSoon"
                                    id="movingsoon"
                                    checked={selectedOption === 'MovingSoon'}
                                    onChange={() => handleMainOptionChange('MovingSoon')}
                                    disabled={issueDetails?.isResolved || selectedOption !== 'MovingSoon'}
                                />
                                <label htmlFor="movingsoon">Moving Soon.</label>
                            </div>
                            <div className='inputDiv'>
                                <input
                                    type="checkbox"
                                    name="TooBusy"
                                    id="toobusy"
                                    checked={selectedOption === 'TooBusy'}
                                    onChange={() => handleMainOptionChange('TooBusy')}
                                    disabled={issueDetails?.isResolved || selectedOption !== 'TooBusy'}
                                />
                                <label htmlFor="toobusy">Too Busy, Not Worth the Time.</label>
                            </div>
                            <div className='inputDiv'>
                                <input
                                    type="checkbox"
                                    name="OtherReason"
                                    id="otherreason"
                                    checked={selectedOption === 'OtherReason'}
                                    onChange={() => handleMainOptionChange('OtherReason')}
                                    disabled={issueDetails?.isResolved || selectedOption !== 'OtherReason'}
                                />
                                <label htmlFor="otherreason">OTHER REASON</label>
                            </div>
                            {selectedOption === 'OtherReason' && (
                                <div className='subSubInput'>
                                    <label htmlFor="otherdetails">Please specify your reason(s):</label>
                                    <div className='inputWrapper'>
                                        <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your reason(s) here" disabled={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                        {!issueDetails?.isResolved && (
                            <>
                                <button style={{ backgroundColor: "#2761D0" }} className='submitBtn' onClick={handleConfirmCancel}>Confirm Cancellation</button>
                                <button style={{ backgroundColor: "#d32f2f" }} className='submitBtn' onClick={handleForceResolve}>Force Resolve</button>
                            </>
                        )}
                    </div>
                </>
            }
        </Layout>
    );
};

export default CancelDetailsPage;
