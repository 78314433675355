import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import FiberPlanApi from '../../API/FiberPlanApi';
import SearchFiberPlanReq from '../../Requests/FiberPlan/SearchFiberPlansReq';
import SoftTypography from './../SoftTypography';
const Index = ({setUser,disabled=false,placeholder="Select a parent Organization",className,multiple,fiberCompany}) => {
    const [UsersSearch, setUserSearch] = useState()
    const [options, setOptions] = useState([])
    const GetOrg = async () => {
        let searchReq = new SearchFiberPlanReq();

        // searchReq.userType = "company"
        if(UsersSearch)
        searchReq.name = UsersSearch;

        searchReq.fiberCompanyId = fiberCompany;
        let res = await FiberPlanApi.SearchFiberPlans(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];
            console.log(res?.data?.list)
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.name + " | " + item.fiberCompany.name ,id:item.id })
            })
            return optionsArr;
        }
      
    }

    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
        };
        getOptions();
    }, [disabled,fiberCompany]);

    return (
        <div className={className}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
             
                <AsyncSelect
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    onChange={(e) => {setUser({id:e.id,name:e.value.name})}}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isMulti={multiple}
                    defaultOptions={options}
                    classNamePrefix={'async_select'}
                />
            </SoftTypography>

        </div>
    )
}

export default Index