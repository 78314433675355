class CreateFiberPlanReq{
    constructor(){
        this.name = null
        this.description = null
        this.fiberCompanyId= null;
        this.speed =  null;
        this.price =  null;
        this.fiberItemType = null
    }
}

export default CreateFiberPlanReq;