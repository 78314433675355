import AdminMenuItems from '../MenuItems/AdminMenuItems';
import Dashboard from '../Views/Shared/Dashboard';
import ManageSellingCards from '../Views/Shared/ManageSellingCards';
import SellingCardTutorial from '../Views/Shared/SellingCardTutorial';
import EditAccountDetails from '../Views/Shared/EditAccountDetails';
import ChatNew from '../Views/Shared/ChatNew';
import ChatList from '../Views/Shared/ChatList';
import DigitalBusinessCenter from '../Views/Reps/DigitalBusinessCenter';
import SellingCardDetails from '../Views/Shared/ManageSellingCards/SellingCardDetails';
import SellingCardDetailsNew from '../Views/Shared/ManageSellingCards/SellingCardDetails';
import CreateBugTicketForm from '../Views/Public/BugTicket';
import SalesReport from '../Views/Shared/SalesReport';
const AdminRoutes = [
    {
        name: "Dashboard",
        key: "Dashboard",
        route: "/",
        component: <Dashboard routes={AdminMenuItems} />,
    },
    {
        name: "Chat",
        key: "privatechatpage",
        route: '/chats',
        component: <ChatList menuItems={AdminMenuItems} />
    },
    {
        name: "Manage Selling Cards",
        key: "ManageSellingCards",
        route: "manage-training-modules/training-module-tutorial/:trainingModuleId/:trainingModuleName",
        component: <ManageSellingCards />
    },
    {
        name: "Selling Card Tutorial",
        key: "SellingCardTutorial",
        route: "/training-module-tutorial",
        component: <SellingCardTutorial />
    },
    {
        name: "Edit Account Details",
        key: "EditAccountDetails",
        route: "/edit-account-details",
        component: <EditAccountDetails />
    },
    {
        name: "Chat",
        key: "chat",
        route: "/chat",
        component: <ChatNew />
    },
    {
        name: "Digital Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center/:id",
        component: <DigitalBusinessCenter />
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        component:<CreateBugTicketForm/>
    }

]

export default AdminRoutes;