import React from 'react'

const ServiceUpDown = () => {
    
     const [customerName, setCustomerName] = React.useState('')
    const [currentPackage, setCurrentPackage] = React.useState('')
    return (
    <div className='form'>
        <h2>Service Upgrade/Downgrade</h2>
        <div className='input_div'>
            <label>Customer Name</label>
            <input type='text' value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
        </div>
        <div className='input_div'>
            <label>Current Package</label>
            <select>
                <option value={''}></option>
                <option value={'Basic'}>Basic</option>
                <option value={'Standard'}>Standard</option>
                <option value={'Premium'}>Premium</option>
            </select>
        </div>
        <div className='input_div'>
            <label>New Package</label>
            <select>
                <option value={''}></option>
                <option value={'Basic'}>Basic</option>
                <option value={'Standard'}>Standard</option>
                <option value={'Premium'}>Premium</option>
            </select>
        </div>
        <div className='input_div'>
            <label>Effective Date</label>
            <input type='date' />
        </div>
        <div className='input_div'>
            <label>Reason For Change</label>
            <input type='text' />
        </div>
        <div className='input_div'>
            <label>Comments/Notes (Optional)</label>
            <textarea placeholder='' rows={3} />
        </div>
        <button>Submit</button>
    </div>
  )
}

export default ServiceUpDown