import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";
class OrgApi{
    baseUrl = EnvSettings.url;
    CreateOrgUrl = "CreateSalesOrg";
    SearchSalesOrgUrl = "SearchSalesOrgs";
    GetSalesOrgTree = "GetSalesOrgTree";
    DeleteSalesOrgUrl = "DeleteSalesOrg";
    AddUsersToSalesOrgUrl = "AddUsersToSalesOrg";
    RemoveUsersFromOrgUrl = "RemoveUserFromSalesOrg"
    SearchSalesOrgLeaderboardUrl = "SearchSalesOrgLeaderboard";
    SearchSalesRepLeaderboardUrl = "SearchSalesRepLeaderboard";
    GetSalesReportDataUrl = "GetSalesReportData";
    GetGeneralSalesReportDataUrl = "GetGeneralSalesReportData";
    GetOffersReportDataUrl = "GetOffersReportData";
    GetAssignedSalesOrgsForFiberCompanyUrl = "GetAssignedSalesOrgsForFiberCompany";
    AssignFiberCompanyToSalesRepUrl = "AddFiberCompanyToSalesRep";
    RemoveFiberCompanyFromSalesRepUrl = "RemoveFiberCompanyFromSalesRep";

    async SearchSalesOrgLeaderboard(req) {
        const res = await fetch(this.baseUrl + this.SearchSalesOrgLeaderboardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchSalesRepLeaderboard(req) {
        const res = await fetch(this.baseUrl + this.SearchSalesRepLeaderboardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateOrg(req)
    {
        const res = await fetch(this.baseUrl + this.CreateOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async SearchOrg(req) {
        const res = await fetch(this.baseUrl + this.SearchSalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async GetOrgTree() {
        const res = await fetch(this.baseUrl + this.GetSalesOrgTree, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            }
        })
        const data = await res.json();
        return data;
    }

    async DeleteSalesOrg(req) {
        const res = await fetch(this.baseUrl + this.DeleteSalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddUsersToSalesOrg(req) {
        const res = await fetch(this.baseUrl + this.AddUsersToSalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveUsersFromOrg(req) {
        const res = await fetch(this.baseUrl + this.RemoveUsersFromOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //GetSalesReportData

    async getReportData(req) {
        const res = await fetch(this.baseUrl + this.GetSalesReportDataUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async getGeneralSalesReportData(req) {
        const res = await fetch(this.baseUrl + this.GetGeneralSalesReportDataUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async getOffersReportData(req) {
        const res = await fetch(this.baseUrl + this.GetOffersReportDataUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async getAssignedSalesOrgsForFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.GetAssignedSalesOrgsForFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AssignFiberCompanyToSalesRep(req)
    {
        const res = await fetch(this.baseUrl + this.AssignFiberCompanyToSalesRepUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveFiberCompanyFromSalesRep(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveFiberCompanyFromSalesRepUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

}


export default new OrgApi;