class SearchFiberPlanReq{
    constructor(){
        this.id = null;
        this.name = null;
        this.description = null;
        this.fiberCompanyId = null;
        this.speed = null;
        this.price = null;
        this.fiberCompany = null;
        this.pagingParams = {
            pageNumber: 1,
            pageSize: 30
        };
    }
}

export default SearchFiberPlanReq;