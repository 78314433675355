import { fromLonLat, toLonLat } from 'ol/proj';
import React, { useContext } from 'react'
import { FaEdit } from 'react-icons/fa'
import { FaCopy, FaPhoneSlash, FaWhatsapp } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import ContactButtons from '../../../../Services/ContactButtons';
import { UserContext } from '../../../../App';
import EditFiberHouseReq from '../../../../Requests/FiberHouse/EditFiberHouseReq';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import { TbStackPop } from 'react-icons/tb';
import { MdOutlineDirections, MdOutlineMail, MdOutlineMessage, MdOutlinePhone } from 'react-icons/md';
import { IoMapOutline } from 'react-icons/io5';
import { MapContext } from '..';

const StatsLeadCard = ({ fh, setAnchorEl, setSelectedLead, setSelectedLeadIds }) => {

    const user = useContext(UserContext)
    const map = useContext(MapContext)
    const contactButtons = new ContactButtons(user);
    console.log(fh)
    const snapToLocation = (fiberHouse) => {
        map.fiberHouseClicked(fiberHouse);
        let lonlat = [fiberHouse.coordinates.longitude, fiberHouse.coordinates.latitude]
        map.mapObject.getView().setCenter(fromLonLat(lonlat));
        map.mapObject.getView().setZoom(21);
    }

    const getCustomerLine = (fh) => {
        let str = "";
        if (fh.customer != null) {
            if (fh.customer.orderNumber != null) {
                str += "✔ Verified Customer";
            }
            else {
                str += "✔ Customer";
            }
        }

        return str;
    }

    const getName = (fh) => {
        if (fh.name) {
            return fh.name
        }
        else{
            return "No Name"
        }
    }


    const getSalesRepName = (fh) => {
        if(fh.owners?.length === 1){
            return " | "+fh.owners[0].name?.split(" ")[0] + " " + fh.owners[0].name?.split(" ")[1][0]+"."
        }
    }


    const copyAddress = (fiberHouse) => {
        if (fiberHouse.address) {
            navigator.clipboard.writeText(fiberHouse.address)
            Swal.fire({
                icon: 'success',
                title: 'Copied',
                text: 'Address Copied to Clipboard',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                toast: true
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to Copy',
                text: 'Address Not Found',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                toast: true
            })
        }
    }

    const sendEmail = (fiberHouse) => {
        if (fiberHouse.email) {
            contactButtons.email(fiberHouse.email)
        }
        else if (fiberHouse.customer?.email) {
            contactButtons.email(fiberHouse.customer.email)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Email Found',
                text: 'No email found for this fiber house',
            })
        }
    }

    const sendText = (fiberHouse) => {
        if (fiberHouse.phone) {
            contactButtons.sms(fiberHouse.phone)
        }
        else if (fiberHouse.customer?.cellPhone) {
            contactButtons.sms(fiberHouse.customer.cellPhone)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Phone Number Found',
                text: 'No phone number found for this fiber house',
            })
        }
    }

    const call = (fiberHouse) => {
        if (fiberHouse.phone) {
            contactButtons.call(fiberHouse.phone)
        }
        else if (fiberHouse.customer?.cellPhone) {
            contactButtons.call(fiberHouse.customer.cellPhone)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Phone Number Found',
                text: 'No phone number found for this fiber house',
            })
        }
    }

    const whatsapp = (fiberHouse) => {
        if (fiberHouse.phone) {
            contactButtons.whatsapp(fiberHouse.phone)
        }
        else if (fiberHouse.customer?.cellPhone) {
            contactButtons.whatsapp(fiberHouse.customer.cellPhone)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Phone Number Found',
                text: 'No phone number found for this fiber house',
            })
        }
    }

    const direction = (fiberHouse) => {
        if (fiberHouse.coordinates) {
            contactButtons.directions(fiberHouse.coordinates)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates Found',
                text: 'No coordinates found for this fiber house',
            })
        }
    }

    const handleLeadPop = (fiberHouse) => {
        map.mapObject.getLayers().forEach((layer) => {
            if (layer.get('name') === 'fiberHouses') {
                layer.getSource().getFeatures().forEach((feature) => {
                    if (feature.get('features')?.length > 0) {
                        feature.get('features').forEach((f) => {
                            if (f.get('id') === fiberHouse.id) {
                                f.getGeometry().translate(5, 5)
                                // map.setSelectedFiberHouseFeature(f)
                                map.mapObject.getView().setCenter(f.getGeometry().getCoordinates())
                                map.mapObject.getView().setZoom(map.mapObject.getView().getZoom() + 0.1)
                                setSelectedLeadIds(
                                    (prev) => {
                                        return prev.filter((fh) => fh.id !== fiberHouse.id)
                                    }
                                )

                                let req = new EditFiberHouseReq()
                                req.id = fiberHouse.id
                                let coordinate = toLonLat(f.getGeometry().getCoordinates())
                                req.coordinate = {
                                    latitude: coordinate[1],
                                    longitude: coordinate[0]
                                }
                                FiberHouseApi.EditFiberHouse(req).then((res) => {
                                    if (res?.status?.success) {
                                        console.log(res)
                                    }
                                    else {
                                        console.log(res)
                                    }
                                })
                                console.log(f)
                            }
                        })
                    }
                })
            }
        })

    }

    return (
        <div className="LeadCard">

            <div className='ImagesDiv'>
                <div className='Image'>
                    <img src={"data:image/png;base64," + map?.mapIcons?.find(assignedMapIcons => assignedMapIcons.id === fh?.mapIconId)?.image} alt="Map Icon" />
                </div>
                {fh.customer === null &&
                    <div style={{
                        backgroundColor: "#2B92D5",
                        borderRadius: "50%",
                        padding: "0.5rem",
                        display: "flex",
                        justifyContent: "center",
                        right: "-10%",
                        bottom: "-10%",
                    }} className='MapIcon' onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                        setSelectedLead(fh)
                    }}>
                        <FaEdit color='#fff' size={15} />
                    </div>
                }
            </div>
            <div className='DataDiv'>
                <div onClick={() => { snapToLocation(fh) }} style={{ cursor: "pointer" }} className='Name'>
                    <h3>{getName(fh)} <span style={{
                        fontSize: "0.8rem",
                        color: "#2761D0",

                    }}>{getSalesRepName(fh)}</span></h3>
                </div>
                <div className='Phone'>
                    <p>{
                       fh.customer?.fiberCompany?.name ? fh.customer.fiberCompany.name : "" 
                    }</p>
                </div>
                <div className='Address'>
                    <p  >{
                        fh.address ?
                            fh.address : fh.mapstring
                    }</p>
                </div>
                <div className='additional_info'>
                    {
                        (fh.customer !== null) &&
                        <p>Customer Status | {fh.customer.accountStatus}</p>
                    }
                    {
                        fh?.lead?.offer &&
                        <p>Offer Sent | {new Date(fh.lead.offer.timeStamp).toLocaleDateString()}</p>
                    }
                    {
                        fh?.lead?.offer && fh.lead.offer.accepted &&
                        <p>Offer Accepted | {new Date(fh.lead.offer.acceptedAt).toLocaleDateString()}</p>
                    }
                    {
                        fh?.lead?.appointmentDateTime &&
                        <p>Follow-Up | {new Date(fh.lead.appointmentDateTime).toLocaleDateString()}</p>
                    }
                    {
                        fh.customer &&
                        <p>Sold | {new Date(fh.customer.timeStamp).toLocaleDateString()}</p>
                    }
                    {
                        fh.customer !== null && fh.customer.installDateTime &&
                        <p>Insall Date : {new Date(fh.customer.installDateTime).toLocaleDateString()}</p>
                    }

                </div>
            </div>
            {
                map?.mapObject?.getView()?.getZoom() >= 15.6 && map.searchAreaFeature.length === 0 &&
                <div className='dbt_1' style={{
                    // display:"flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    flexDirection: "column"
                }}>

                    <div className='dbt_1' style={{
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: "2rem",
                        height: "2rem",

                    }} onClick={() => {
                        handleLeadPop(fh)
                    }}>
                        <TbStackPop size={30} color='#2761D0' />
                    </div>
                </div>
            }

            <div className="MainLeadCard_Buttons">

                {
                    map?.mapObject?.getView()?.getZoom() >= 15.6 && map.searchAreaFeature.length === 0 &&
                    <div className="dbt_2" style={{
                        width: "2rem",
                        height: "2rem",
                        cursor: 'pointer'
                    }}  >
                        <div className='dbt_2' style={{
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: "2rem",
                            height: "2rem",

                        }} onClick={() => {
                            handleLeadPop(fh)
                        }}>
                            <TbStackPop size={30} color='#2761D0' />
                        </div>
                    </div>
                }

                <div className="MainLeadCard_Button"
                    onClick={() => sendEmail(fh)}
                >
                    <MdOutlineMail color='#81848A' size={30} />
                </div>
                <div className="MainLeadCard_Button"
                    onClick={() => whatsapp(fh)}
                >
                    <FaWhatsapp color='#81848A' size={30} />
                </div>
                <div className="MainLeadCard_Button"
                    onClick={() => sendText(fh)}
                >
                    <MdOutlineMessage color='#81848A' size={30} />

                </div>
                <div className="MainLeadCard_Button"
                    onClick={() => call(fh)}
                >
                    <MdOutlinePhone color='#81848A' size={30} />

                </div>
                <div className="MainLeadCard_Button"
                    onClick={() => direction(fh)}
                >
                    <MdOutlineDirections color='#81848A' size={30} />
                </div>
                <div
                    onClick={() => { snapToLocation(fh) }}
                    className='MainLeadCard_Button'>
                    <IoMapOutline color='#81848A' size={30} />
                </div>
                <div onClick={() => copyAddress(fh)} className='MainLeadCard_Button'>
                    <FaCopy color='#81848A' size={30} />
                </div>
                {/* {
    user.userType === 'SuperAdmin' && lead.fidiumCustomer != null &&
    <div onClick={()=>{
        markCustomerAsInvalid(lead)
    }} className='MainLeadCard_Button'>
        <IoIosRemoveCircle color='#81848A' size={30} />
    </div>
} */}
            </div>

        </div>
    )
}

export default StatsLeadCard