import React, { useContext, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import CreateCustomerReq from '../../../../Requests/Customer/CreateCustomerReq';
// import CustomerApi from '../../../../API/CustomerApi';
import Swal from 'sweetalert2';
import { UserContext } from '../../../../App';
import OfferApi from '../../../../API/OfferApi';
// import FiberPlanSelector from '../../../../components/FiberPlanSelector';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import CreateFile from '../../../../Requests/CloudFile/CreateFileReq';
const CustomerForm = ({ lead, offer, setOpen, setReload, fiberPlan }) => {



    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [secondaryAccountHolderName, setSecondaryAccountHolderName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [fourDigitPin, setFourDigitPin] = React.useState("");
    const [securityQuestion1, setSecurityQuestion1] = React.useState("");
    const [answer1, setAnswer1] = React.useState("");
    const [securityQuestion2, setSecurityQuestion2] = React.useState("");
    const [answer2, setAnswer2] = React.useState("");
    const [specialInstructions, setSpecialInstructions] = React.useState("");
    const [schedulingNotes, setSchedulingNotes] = React.useState("");
    const [promoCode, setPromoCode] = React.useState("");
    const [currentInternetSpeed, setCurrentInternetSpeed] = React.useState("");
    const [selectedInstallDates, setSelectedInstallDates] = React.useState([]);
    const [customInstallDate, setCustomInstallDate] = React.useState("");
    const [selectedInstallTimes, setSelectedInstallTimes] = React.useState([]);
    const [customInstallTime, setCustomInstallTime] = React.useState("");

    const [timezone, setTimezone] = React.useState("");
    const [consentChecked, setConsentChecked] = React.useState(false);
    const [VerificationPhrase, setVerificationPhrase] = useState('')

    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [appartment, setAppartment] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [goPaperless, setGoPaperless] = useState(false);
    const [fiberLeasedDeviceWithPromo, setFiberLeasedDeviceWithPromo] = useState(false);
    const [serviceAgreement, setServiceAgreement] = useState("");
    const [fiberONTLease, setFiberONTLease] = useState(false);
    const [boostRouterLease, setBoostRouterLease] = useState(false);
    const [installServiceCharge, setInstallServiceCharge] = useState(false);
    const [addressLine2, setAddressLine2] = useState("");
    const [connectReason, setConnectReason] = useState("");

    const uploadFileToFirebase = async (file, path) => {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        return url;
    }

    const user = useContext(UserContext);
    const createCustomer = async (e) => {
        e.preventDefault();
        Swal.fire({
            icon: "info",
            title: "Creating Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (offer?.schedulingNotesRequired && !schedulingNotes) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Scheduling Notes",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }



        if (offer?.fiberCompany?.companyForm?.firstNameRequired && offer?.fiberCompany.companyForm.firstName && !firstName) {
            // setStatusText("Please Enter First Name")
            Swal.fire({
                icon: "error",
                title: "Please Enter First Name",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.lastNameRequired && offer?.fiberCompany.companyForm.lastName && !lastName) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Last Name",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.secondaryAccountHolderNameRequired && offer?.fiberCompany.companyForm.secondaryAccountHolderName && !secondaryAccountHolderName) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Secondary Account Holder Full Name",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.addressRequired && offer?.fiberCompany.companyForm.address && !address) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Address",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'

                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.cellPhoneRequired && offer?.fiberCompany.companyForm.cellPhone && !phone) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Phone Number",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.emailRequired && offer?.fiberCompany.companyForm.email && !email) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Email",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (offer?.fiberCompany?.companyForm?.emailRequired && offer?.fiberCompany.companyForm.email && !emailRegex.test(email)) {
            // setStatusText("Invalid Email")
            Swal.fire({
                icon: "error",
                title: "Invalid Email",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.fourDigitPinRequired && offer?.fiberCompany.companyForm.fourDigitPin && !fourDigitPin) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Four Digit Pin",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.securityQuestion1Required && offer?.fiberCompany.companyForm.securityQuestion1 && !securityQuestion1) {
            Swal.fire({
                icon: "error",
                title: "Please Select Security Question 1",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.answerSecurityQuestion1Required && offer?.fiberCompany.companyForm.answerSecurityQuestion1 && !answer1) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Answer To Security Question 1",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.securityQuestion2Required && offer?.fiberCompany.companyForm.securityQuestion2 && !securityQuestion2) {
            Swal.fire({
                icon: "error",
                title: "Please Select Security Question 2",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.answerSecurityQuestion2Required && offer?.fiberCompany.companyForm.answerSecurityQuestion2 && !answer2) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Answer To Security Question 2",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.timeZoneRequired && offer?.fiberCompany.companyForm.timeZone && !timezone) {
            Swal.fire({
                icon: "error",
                title: "Please Select Timezone",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.promoCodeRequired && offer?.fiberCompany.companyForm.promoCode && !promoCode) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Promo Code",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.speedTestBeforeImageRequired && offer?.fiberCompany.companyForm.speedTestBeforeImage && !currentInternetSpeed) {
            Swal.fire({
                icon: "error",
                title: "Please Upload Current Internet Speed Test Image",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.streetAddressRequired && offer?.fiberCompany.companyForm.steetAddress && !streetAddress) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Street Address",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.cityRequired && offer?.fiberCompany.companyForm.city && !city) {
            Swal.fire({
                icon: "error",
                title: "Please Enter City",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }

            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.appartmentRequired && offer?.fiberCompany.companyForm.appartment && !appartment) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Appartment",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.zipCodeRequired && offer?.fiberCompany.companyForm.zipCode && !zipCode) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Zip Code",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }

            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.stateRequired && offer?.fiberCompany.companyForm.state && !state) {
            Swal.fire({
                icon: "error",
                title: "Please Enter State",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.serviceAgreementRequired && offer?.fiberCompany.companyForm.serviceAgreement && !serviceAgreement) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Service Agreement",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }

            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.freeLeasedDeviceWithPromoRequired && offer?.fiberCompany.companyForm.freeLeasedDeviceWithPromo && !fiberLeasedDeviceWithPromo) {
            Swal.fire({
                icon: "error",
                title: "Please Check Fiber Leased Device With Promo",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.fiberONTLeaseRequired && offer?.fiberCompany.companyForm.fiberONTLease && !fiberONTLease) {
            Swal.fire({
                icon: "error",
                title: "Please Check Fiber ONT Lease",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.boostRouterLeaseRequired && offer?.fiberCompany.companyForm.boostRouterLease && !boostRouterLease) {
            Swal.fire({
                icon: "error",
                title: "Please Check Boost Router Lease",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.installServiceChargeRequired && offer?.fiberCompany.companyForm.installServiceCharge && !installServiceCharge) {
            Swal.fire({
                icon: "error",
                title: "Please Check Install Service Charge",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },

                didOpen: () => {
                    Swal.hideLoading()
                }

            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.verificationPhraseRequired && offer?.fiberCompany.companyForm.verificationPhrase && !VerificationPhrase) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Verification Phrase",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.addressLine2Required && offer?.fiberCompany.companyForm.addressLine2 && !addressLine2) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Address Line 2",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }

            })
            return;
        }

        if (offer?.fiberCompany?.companyForm?.goPaperlessRequired && offer?.fiberCompany.companyForm.goPaperless && !goPaperless) {
            Swal.fire({
                icon: "error",
                title: "Please Check Go Paperless",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.notesToFiberCompanySpecialInstructionsRequired && offer?.fiberCompany.companyForm.notesToFiberCompanySpecialInstructions && !specialInstructions) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Special Instructions For The Company",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        if (offer?.fiberCompany?.companyForm?.fiberPlanIdRequired && offer?.fiberCompany.companyForm.fiberPlanId && !fiberPlan) {
            Swal.fire({
                icon: "error",
                title: "Please Select a Fiber Plan",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if(offer?.fiberCompany?.companyForm?.connectReasonRequired && offer?.fiberCompany.companyForm.connectReason && !connectReason){
            Swal.fire({
                icon: "error",
                title: "Please Enter Connect Reason",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }
        

        let req = new CreateCustomerReq()
        req.FirstName = firstName;
        req.LastName = lastName;
        req.SecondaryAccountHolderFullName = secondaryAccountHolderName;
        req.Address = address;
        req.CellPhone = phone;
        req.Email = email;
        req.FiberCompanyId = offer.fiberCompanyId;
        let installDate = "";
        if (selectedInstallDates.length > 0) {
            installDate = selectedInstallDates.join(",");
        }
        if (customInstallDate) {
            installDate = installDate ? installDate + "," + customInstallDate : customInstallDate;
        }
        req.DesiredInstallDateString = installDate;
        let installTime = "";
        if (selectedInstallTimes.length > 0) {
            installTime = selectedInstallTimes.join(",");
        }
        if (customInstallTime) {
            installTime = installTime ? installTime + "," + customInstallTime : customInstallTime;
        }
        req.DesiredInstallTime = installTime;
        req.FourDigitPin = fourDigitPin;
        req.SecurityQuestion1 = securityQuestion1;
        req.AnswerSecurityQuestion1 = answer1;
        req.SecurityQuestion2 = securityQuestion2;
        req.AnswerSecurityQuestion2 = answer2;
        req.FiberPlanString = fiberPlan?.name;
        req.fiberCompanyId = offer.fiberCompanyId;
        req.fiberPlanId = fiberPlan.id;
        req.TimeZone = timezone;
        req.SalesRepId = offer?.salesRepId;
        req.NotesToFiberCompanySpecialInstructions = specialInstructions;
        req.PromoCode = promoCode;
        req.FreeMonth = null;
        req.ConsentChecked = consentChecked;
        req.FreeOffer = offer.freeOffer;
        if (currentInternetSpeed) {
            const req2 = new CreateFile();
            req2.name = currentInternetSpeed.name;
            req2.extension = currentInternetSpeed.name.split('.').pop();
            req2.caption = "Speed Test Before Image";
            req2.fileSize = currentInternetSpeed.size.toString();
            req2.fileType = currentInternetSpeed.type;
            const uniqueId = Date.now();
            const url = await uploadFileToFirebase(currentInternetSpeed, `speedTestBefore/${uniqueId}/${req.name}`);
            req2.url = url;
            req2.path = `speedTestBefore/${uniqueId}/${req.name}`;
            req.SpeedTestBeforeImage = req2;
        }
        req.SchedulingNotes = schedulingNotes;
        req.AffiliateCode = "N/A"
        req.IsHotLead = false;
        // req.installASAP = installASAP;
        req.LeadId = null;
        req.SalesOrgId = user?.salesOrgId ? user?.salesOrgId : null;
        req.offerId = null;
        req.UserId = null;
        req.VerificationPhrase = VerificationPhrase

        req.GoPaperless = goPaperless;
        req.FreeLeasedDeviceWithPromo = fiberLeasedDeviceWithPromo;
        req.ServiceAgreement = serviceAgreement;
        req.FiberONTLease = fiberONTLease;
        req.BoostRouterLease = boostRouterLease;
        req.InstallServiceCharge = installServiceCharge;
        req.AddressLine2 = addressLine2;
        req.SteetAddress = streetAddress;
        req.City = city;
        req.Appartment = appartment;
        req.ZipCode = zipCode;
        req.State = state;
        req.ConnectReason = connectReason;
        let res = await OfferApi.AcceptSharedOffer(req);
        if (res?.status?.success) {
            // setStatusText("Customer Created Successfully");
            Swal.fire({
                icon: "success",
                title: "Customer Created Successfully",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setReload(true);
            setOpen(false);
        }
        else {
            // setStatusText(res?.status?.message);
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

        }

    }

    const installDateOptions = ["ASAP", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Next Week"];
    const installTimeOptions = ["Anytime", "Mornings", "Afternoons", "8AM-10AM", "9AM-11AM", "10AM-12PM", "11AM-1PM", "12PM-2PM", "1PM-3PM", "2PM-4PM", "3PM-5PM", "4PM-6PM", "5PM-7PM"];

    const handleInstallDateChange = (option) => {
        if (selectedInstallDates.includes(option)) {
            setSelectedInstallDates(selectedInstallDates.filter((date) => date !== option));
        } else {
            setSelectedInstallDates([...selectedInstallDates, option]);
        }
    };

    const handleInstallTimeChange = (option) => {
        if (selectedInstallTimes.includes(option)) {
            setSelectedInstallTimes(selectedInstallTimes.filter((time) => time !== option));
        } else {
            setSelectedInstallTimes([...selectedInstallTimes, option]);
        }
    };


    return (
        <div className='customer_model'>
            <h1>Become A <span>Customer</span></h1>
            {/* <div className='fiber_type_container'>
                <p>{offer.fiberType}</p>
            </div> */}
            {
                offer?.fiberCompany.companyForm &&
                <form onSubmit={(e) => createCustomer(e)}>
                    {
                        offer?.fiberCompany.companyForm.firstName &&
                        <div className='form_group'>
                            <label>First Name</label>
                            <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.lastName &&
                        <div className='form_group'>
                            <label>Last Name</label>
                            <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    }

                    {
                        offer?.fiberCompany.companyForm.secondaryAccountHolderName &&
                        <div className='form_group'>
                            <label>Secondary Account Holder Full Name</label>
                            <input type='text' value={secondaryAccountHolderName} onChange={(e) => setSecondaryAccountHolderName(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.email &&
                        <div className='form_group'>
                            <label>Email</label>
                            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.cellPhone &&
                        <div className='form_group'>
                            <label>Phone Number</label>
                            <PhoneInput containerStyle={{ width: "100%" }} inputStyle={{
                                paddingLeft: "50px",
                                width: "100%",
                            }} country={'us'} excludeCountries={['IL']} value={phone} onChange={setPhone} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.address &&
                        <div className='form_group'>
                            <label>Address</label>
                            <input type='text' value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.steetAddress &&
                        <div className='form_group'>
                            <label>Street Address</label>
                            <input type='text' value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.addressLine2 &&
                        <div className='form_group'>
                            <label>Address Line 2</label>
                            <input type='text' value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.city &&
                        <div className='form_group'>
                            <label>City</label>
                            <input type='text' value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.appartment &&
                        <div className='form_group'>
                            <label>Appartment</label>
                            <input type='text' value={appartment} onChange={(e) => setAppartment(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.zipCode &&
                        <div className='form_group'>
                            <label>Zip Code</label>
                            <input type='text' value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.state &&
                        <div className='form_group'>
                            <label>State</label>
                            <input type='text' value={state} onChange={(e) => setState(e.target.value)} />
                        </div>
                    }


                    {/* <FiberPlanSelector fiberCompany={offer.fiberCompanyId} placeholder='Select a Fiber Plan' setUser={setFiberPlan} disabled={!offer.fiberCompanyId} /> */}
                    {
                        offer?.fiberCompany.companyForm.desiredInstallDateString &&
                        <div className="DesiredInstallDiv">
                            <h3>Desired Install Dates:</h3>
                            <div className="OptionsDiv">
                                {installDateOptions.map((option) => (
                                    <div key={option}>
                                        <input
                                            type="checkbox"
                                            id={option}
                                            checked={selectedInstallDates.includes(option)}
                                            onChange={() => handleInstallDateChange(option)}
                                        />
                                        <label htmlFor={option}>{option}</label>
                                    </div>
                                ))}
                                <div className="OtherDiv">

                                    <label htmlFor="customDate">Other Date</label>

                                    <input
                                        type="text"
                                        className="w-full border-2 border-gray-200 rounded-lg p-2"
                                        value={customInstallDate}
                                        onChange={(e) => setCustomInstallDate(e.target.value)}
                                        placeholder='Enter Desired Install Date'
                                    />

                                </div>
                            </div>
                        </div>
                    }

                    {
                        offer?.fiberCompany.companyForm.desiredInstallTimeString &&
                        <div className="DesiredInstallDiv">
                            <h3>Desired Install Times:</h3>
                            <div className="OptionsDiv">
                                {installTimeOptions.map((option) => (
                                    <div key={option}>
                                        <input
                                            type="checkbox"
                                            id={option}
                                            checked={selectedInstallTimes.includes(option)}
                                            onChange={() => handleInstallTimeChange(option)}

                                        />
                                        <label htmlFor={option}>{option}</label>
                                    </div>
                                ))}
                                <div className="OtherDiv">

                                    <label htmlFor="customTime">Other Time</label>

                                    <input
                                        type="text"
                                        className="w-full border-2 border-gray-200 rounded-lg p-2"
                                        value={customInstallTime}
                                        onChange={(e) => setCustomInstallTime(e.target.value)}
                                        placeholder='Enter Desired Install Time'
                                    />

                                </div>
                            </div>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.fourDigitPin &&
                        <div className='form_group'>
                            <label>Four Digit Pin</label>
                            <input type='text' value={fourDigitPin} onChange={(e) => setFourDigitPin(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.verificationPhrase &&
                        <div className='form_group'>
                            <label>Verification Phrase</label>
                            <input placeholder="Enter Verification Phrase" value={VerificationPhrase} onChange={(e) => setVerificationPhrase(e.target.value)} />
                        </div>
                    }
                    {/* <SoftInput label="SQ1" placeholder="Enter Security Question 1" value={securityQuestion1} onChange={(e) => setSecurityQuestion1(e.target.value)} /> */}
                    {
                        offer?.fiberCompany.companyForm.securityQuestion1 &&
                        <div>
                            <select className="w-full border-2 border-gray-200 rounded-lg p-2" value={securityQuestion1} onChange={(e) => setSecurityQuestion1(e.target.value)}>
                                <option value="">Select Security Question 1</option>
                                {/* <option value="First or Favorite Pet Name:">First or Favorite Pet Name</option>
                        <option value="First Car:">First Car</option>
                        <option value="Sports Team:">Sports Team</option>
                        <option value="Hobby:">Hobby</option> */}
                                <option value="Your Pet's Name:">Your Pet's Name?</option>
                                <option value="Where were you born:">Where were you born?</option>
                                <option value="What is your favorite color:">What is your favorite color?</option>
                                <option value="What is your High School Mascot:">What is your High School Mascot?</option>
                            </select>
                        </div>}
                    {
                        offer?.fiberCompany.companyForm.answerSecurityQuestion1 &&
                        <div className='form_group'>
                            {/* <label>Answer</label> */}
                            <input type='text' placeholder='Answer To Security Question 1' value={answer1} onChange={(e) => setAnswer1(e.target.value)} />
                        </div>
                    }

                    {
                        offer?.fiberCompany.companyForm.securityQuestion2 &&
                        <div className='form_group'>
                            <label>Security Question 2</label>
                            <select className="w-full border-2 border-gray-200 rounded-lg p-2" value={securityQuestion2} onChange={(e) => setSecurityQuestion2(e.target.value)}>
                                <option value="">Select Security Question 2</option>
                                <option value="Favorite Athlete:">Favorite Athlete</option>
                                <option value="Personal Hero:">Personal Hero</option>
                                <option value="First Crush:">First Crush</option>
                                <option value="Least Favorite Veggie:">Least Favorite Veggie</option>
                                <option value="NA">NA</option>
                            </select>
                        </div>}
                    {
                        offer?.fiberCompany.companyForm.answerSecurityQuestion2 &&
                        <div className='form_group'>
                            <input type='text' value={answer2} placeholder='Answer To Security Question 2' onChange={(e) => setAnswer2(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.timeZone &&
                        <div className='form_group'>
                            <label>Timezone</label>
                            <select className="w-full border-2 border-gray-200 rounded-lg p-2" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                                <option value="">Select Timezone</option>
                                <option value="EST">EST</option>
                                <option value="CST">CST</option>
                                <option value="MST">MST</option>
                                <option value="PST">PST</option>
                            </select>

                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.notesToFiberCompanySpecialInstructions &&
                        <div className='form_group'>
                            <label>Special Intructions For the Company</label>
                            <textarea rows='5' value={specialInstructions} onChange={(e) => setSpecialInstructions(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.schedulingNotes &&
                        <div className='form_group'>
                            <label>Scheduling Notes</label>
                            <input type='text' value={schedulingNotes} onChange={(e) => setSchedulingNotes(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.promoCode &&
                        <div className='form_group'>
                            <label>Promo Code</label>
                            <input type='text' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.speedTestBeforeImage &&
                        <div className='form_group'>
                            <label>Current Internet Speed Test</label>
                            <input type='file' onChange={(e) => setCurrentInternetSpeed(e.target.files[0])} />
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.goPaperless &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="goPaperless" id="goPaperless" checked={goPaperless} onChange={(e) => setGoPaperless(e.target.checked)} />
                                <label htmlFor="goPaperless">Go Paperless</label>
                            </div>
                            <p className='details'>If you choose to receive a paper statement a $2 monthly statement fee will apply.</p>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.freeLeasedDeviceWithPromo &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="fiberLeasedDeviceWithPromo" id="fiberLeasedDeviceWithPromo" checked={fiberLeasedDeviceWithPromo} onChange={(e) => setFiberLeasedDeviceWithPromo(e.target.checked)} />
                                <label htmlFor="fiberLeasedDeviceWithPromo">Free Leased Device With Promo</label>
                            </div>
                            <p className='details'>$0.00 / Month</p>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.serviceAgreement &&
                        <div className='form_group'>
                            <label htmlFor="serviceAgreement">Service Agreement</label>
                            {/* <input type="text" name="serviceAgreement" id="serviceAgreement" value={serviceAgreement} onChange={(e) => setServiceAgreement(e.target.value)} /> */}
                            <select value={serviceAgreement} onChange={(e) => setServiceAgreement(e.target.value)}>
                                <option value={'No Service Agreement'}>No Service Agreement</option>
                                <option value={'Commecial Employee Offer'}>Commecial Employee Offer</option>
                                <option value={'Free Equip For Life + 1 Promo'}>Free Equip For Life + 1 Promo</option>
                            </select>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.fiberONTLease &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="fiberONTLease" id="fiberONTLease" checked={fiberONTLease} onChange={(e) => setFiberONTLease(e.target.checked)} />
                                <label htmlFor="fiberONTLease">Fiber ONT Lease</label>
                            </div>
                            <p className='details'>$9.00 / Month</p>
                            <p className='details'>ONT lease is required for Fiber services.</p>
                            <p className='details'>*Purchase option also available</p>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.boostRouterLease &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="boostRouterLease" id="boostRouterLease" checked={boostRouterLease} onChange={(e) => setBoostRouterLease(e.target.checked)} />
                                <label htmlFor="boostRouterLease">Boost Router Lease W/1 Acc Point</label>
                            </div>
                            <p className='details'>BOOST by Lightcurve, formerly Rainier Connect, lets you enjoy maximum Wi-fi coverage and performance with a wireless mesh network. Includes one (1) access point to extend Wi-fi to those hard-to-get places in your home.</p>
                            <p className='details'>*$50 one-time technician install required for set-up.</p>
                            <p className='details'>Learn more about Boost Wi-Fi</p>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.installServiceCharge &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type='checkbox' checked={installServiceCharge} onChange={(e) => setInstallServiceCharge(e.target.checked)} />
                                <label>Install/Service Charge Fiber</label>
                            </div>
                            <p className='details'>$50.00 (One-Time Charge)</p>
                            <p className='details'>Installation Charges for connecting service</p>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.connectReason &&
                        <div className='form_group'>
                            <label>Please tell us why you are adding new service today.</label>
                            <select value={connectReason} onChange={(e) => e.target.value === "" ? setConnectReason(null) : setConnectReason(e.target.value)}>
                                <option value="">Select Connect Reason</option>
                                <option value="REFERRAL">REFERRAL</option>
                                <option value="CAMPAIGN PROMOTION">CAMPAIGN PROMOTION</option>
                                <option value="LINKEDIN">LINKEDIN</option>
                                <option value="ADVERTISEMENT" >ADVERTISEMENT</option>
                                <option value="INSTAGRAM">INSTAGRAM</option>
                                <option value="LIGHTCURVE WEBSITE">LIGHTCURVE WEBSITE</option>
                                <option value="INTERNET / GOOGLE SEARCH">INTERNET / GOOGLE SEARCH</option>
                                <option value="TWITTER" >TWITTER</option>
                                <option value="FACEBOOK">FACEBOOK</option>
                            </select>
                        </div>
                    }
                    {
                        offer?.fiberCompany.companyForm.consentChecked &&
                        <div className='flex items-center gap-4'>
                            <input type="checkbox" name="consentChecked" id="consentChecked" checked={consentChecked} onChange={(e) => setConsentChecked(e.target.checked)} />
                            <label htmlFor="consentChecked">I consent to text and/or email communications to ensure the free Fidium Fiber connection.</label>
                        </div>
                    }


                    <button type='submit'>Submit</button>
                </form>
            }
        </div>
    )
}

export default CustomerForm
