/* eslint-disable react-hooks/exhaustive-deps */
import QRCode from 'qrcode.react'
import React, { useContext, useEffect } from 'react'
import UsersApi from '../../../API/UsersApi.js'
import { UserContext } from '../../../App'
import GenerateAffiliateCodeReq from '../../../Requests/ReferralCode/GenerateAffiliateCodeReq.js'
import Layout from '../../../Layouts/Layout.js'
import { CircularProgress } from '@mui/material'
import StringIntReq from '../../../Requests/StringIntReq.js'
import { FaCopy } from "react-icons/fa6";
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { FaDollarSign } from "react-icons/fa";
import { FaGift } from "react-icons/fa";
const ReferralProgram = () => {
    const user = useContext(UserContext)
    const [loaded, setLoaded] = React.useState(false)
    const [BalanceData, setBalanceData] = React.useState(null)
    const [userData, setUserData] = React.useState(null)
    const navigate = useNavigate() 
    async function UpdateUserCode() {
        let req = new GenerateAffiliateCodeReq();
        req.userId = user.id;
        let response = await UsersApi.GenerateAffiliateCode(req);

        console.log(response)
        if (response.status.success) {
            setUserData(response.data)
        }

        console.log(userData)
    }
    async function getBalanceData() {
        let req = new StringIntReq();
        req.int = user.id;
        let response = await UsersApi.CalculateUserAffiliateBalance(req);
        console.log(response)
        if (response.status.success) {
            setBalanceData(response.data)
        }
    }


    useEffect(() => {
        if (user?.affiliateCode == null) {
            UpdateUserCode();
        }
        else {
            setUserData(user)
        }
    }, []);

    useEffect(() => {
        if ((BalanceData != null) && (userData != null)) {
            setLoaded(true)
        }
        else {
            setLoaded(false)
        }
        console.log(BalanceData)
        console.log(userData)
    }, [BalanceData, userData]);

    useEffect(() => {
        getBalanceData();
    }, []);






    if (!loaded) {
        return (
            <Layout>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <CircularProgress size={60} sx={{
                        color: "#181E4B"
                    }} />
                </div>
            </Layout>
        )
    }

    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(`${window.location.origin}` + (userData && userData.affiliateCode ? `/signup/${userData.affiliateCode.code}` : ''));
        Swal.fire({
            title: "Link Copied",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: "bottom-right",
            timerProgressBar: true,
        })
    }

    const shareReferralLink = () => {
        const affiliateCodePath = userData && userData.affiliateCode ? `/signup/${userData.affiliateCode.code}` : '';
        const fullUrl = `${window.location.origin}${affiliateCodePath}`;
    
        if (navigator.share) {
            navigator.share({
                title: 'Join me on LightningOS!',
                text: 'Here is my referral link:',
                url: fullUrl
            }).then(() => {
                console.log('Referral link shared successfully');
            }).catch((error) => {
                console.error('Error sharing the referral link:', error);
            });
        } else {
            console.log('Web Share API not supported in this browser.');
        }
    };

    return (
        <Layout>

            <div className='customerDashboard'>
                

                    <div style={{
                        padding: "1rem",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px 0px #0000001a",
                        backgroundColor: "#fff",
                        width: "fit-content",
                        margin: "auto"
                    }} onClick={() => { navigate('/CreateNewReferral') }}>
                        <QRCode size={200} value={`signup/${userData.affiliateCode.code}`} />
                        <h3 style={{fontWeight: "550", fontSize: "1.2rem", marginTop: "0.5rem", borderRadius: "15px", textAlign: "center"}}>Qr Code</h3>
                </div>
                <div onClick={() => { copyUrlToClipboard() }} style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0.5rem 1rem",
                    alignItems: "center",
                    color: "white",
                    backgroundColor: "var(--color-primary)",
                    border: "none",
                    borderRadius: "5px",
                }}>
                    <h5 style={{fontWeight: "550", fontSize: "1.2rem", marginRight: "1rem", borderRadius: "15px"}}>Copy Link</h5>
                    <button ><FaCopy size={20} /></button>
                </div>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1rem",
                    placeItems: "center",
                    marginTop: "1rem"
                }}>
                    <button style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "var(--color-primary)",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "100%",
                        height:"100%"
                    }} onClick={() => {
                        shareReferralLink()
                    }} >Send Referral Link</button>
                    <button style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "var(--color-primary)",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "100%",
                        height:"100%"
                    }} onClick={() => {
                        navigate('/referral-code-statuses')
                    }}>Your Referrals</button>
                </div>
                {/* <div className='dashboardBody'>
                    {
                        data.map((item, index) => {
                            return (
                                <DashboardCard item={item} user={user} />
                            )
                        })
                    }
                </div> */}
                <br />

                    <h1 className='referral-heading'>Refferal Earnings</h1>
                    {/* <div className='balance-container'>
                        {
                            earnings.map((item, index) => {
                                return <div className='balance-card' key={item.text}>
                                    <h1>{item.text}</h1>
                                    <p>{item.value}</p>
                                </div>
                            })
                            }
                    </div> */}
                    <div className='stat_header_container'>
                        <div className='stat_header'>
                            <FaGift size={50} />
                            <div>
                                <h1>Total Referrals Count</h1>
                                <p>{BalanceData?.totalReferrals}</p>
                            </div>
                        </div>
                        <div className='stat_header'>
                            <FaDollarSign size={50} />
                            <div>
                            <h1>Total Referrals Count</h1>
                            <p>{BalanceData?.totalReferrals}</p>
                        </div>
                    </div>
                </div>
                <div className='stats_body'>
                    <div className='stat_item'>
                        <h1>Pending</h1>
                        <p>${BalanceData?.pending}</p>
                    </div>
                    <div className='stat_item'>
                        <h1>Income Generated</h1>
                        <p>${BalanceData?.received}</p>
                    </div>
                    {/* <div className='stat_item'>
                        <h1>Rejected</h1>
                        <p>${BalanceData?.rejected}</p>
                    </div> */}
                    <div className='stat_item'>
                        <h1>Canceled Installs</h1>
                        <p>${BalanceData?.canceled}</p>
                    </div>
                </div>

                    <div className='stats_body'>
                        <div className='stat_item'>
                            <h1>$ Donated To Charity </h1>
                            <p>$50</p>
                        </div>
                    </div>
                </div>

        </Layout>
    );
};

export default ReferralProgram;
