import React, { useState } from 'react'
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import UsersApi from '../../API/UsersApi';

import SoftTypography from './../SoftTypography';
import SearchUserReq from '../../Requests/Users/SearchUserReq';
const Index = ({setUser,disabled=false,placeholder="Select a parent Organization",salesOrgId}) => {
    const [UsersSearch, setUserSearch] = useState()

    const GetOrg = async () => {
        let searchReq = new SearchUserReq();
        // searchReq.userType = "company"
        searchReq.userType = "SalesRep"
        searchReq.salesOrgId = salesOrgId
        if(UsersSearch)
        {
            let ar  = UsersSearch.split(" ")   
            if(ar.length>0)
            {
                searchReq.firstName = ar[0]
            }
            if(ar.length>1)
            {
                searchReq.lastName = ar[1]
            }
        }

        
        let res = await UsersApi.SearchUser(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.firstName +" "+ item.lastName,id:item.id })
            })
            return optionsArr;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }


    return (
        <div>
            <SoftTypography variant="button" fontWeight="regular" color="text">
             
                <AsyncSelect
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    onChange={(e) => {setUser(e.id)}}
                    placeholder={placeholder}
                    isDisabled={disabled}
                />
            </SoftTypography>

        </div>
    )
}

export default Index