class SearchServicableAreaReq{
    constructor(){
        this.latitudeFrom = null;
        this.longitudeFrom = null;
        this.latitudeTo = null;
        this.longitudeTo = null;
        this.name = null;
        this.status = null;
        this.salesOrgId = null;
        this.salesRepId = null;
        this.pagingParams = {
          "pageNumber": 0,
          "pageSize": 0
        };
    }
}
export default SearchServicableAreaReq;