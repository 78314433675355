import React, { useContext, useEffect, useState } from 'react';
import CustomerApi from '../../../../API/CustomerApi';
import { UserContext } from '../../../../App';
import CreateCustomerReq from '../../../../Requests/Customer/CreateCustomerReq';
// import SoftInput from '../../../../components/SoftInput';
// import FreeMonthButton from '../Images/FreeMonthButton.png';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import FiberCompanySelector from '../../../../components/FiberCompanySelector'
import FiberPlanSelector from '../../../../components/FiberPlanSelector'
// import CreateFile from '../../../../Requests/CloudFile/CreateFileReq';
// import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { uploadFileToFirebase } from '../../../../Services/DisplayFile';
import StringIntReq from '../../../../Requests/StringIntReq';
import FiberCompanyApi from '../../../../API/FiberCompanyApi';
import { CircularProgress } from '@mui/material';
const CreateCustomer = ({ lead, fiberHouse, setIndex }) => {

    console.log("fiberHouse", fiberHouse)
    console.log("lead", lead)

    const [firstName, setFirstName] = useState(lead?.name);
    const [lastName, setLastName] = useState("");
    const [secondaryAccountHolder, setSecondaryAccountHolder] = useState("");
    const [address, setAddress] = useState(fiberHouse?.mapstring);
    const [phone, setPhone] = useState(lead?.phone);
    const [email, setEmail] = useState(lead?.email);
    const [fourDigitPin, setFourDigitPin] = useState("");
    const [securityQuestion1, setSecurityQuestion1] = useState("");
    const [securityAnswer1, setSecurityAnswer1] = useState("");
    const [securityQuestion2, setSecurityQuestion2] = useState("");
    const [securityAnswer2, setSecurityAnswer2] = useState("");
    const [fiberPlan, setFiberPlan] = useState("");
    const [timezone, setTimezone] = useState("");
    const [instructions, setInstructions] = useState("");
    const [schedulingNotes, setSchedulingNotes] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [freeMonth, setFreeMonth] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const [currentInternetSpeed, setCurrentInternetSpeed] = useState(null);
    const [fiberCompanyId, setFiberCompanyId] = useState(null);
    const [fiberPlanId, setFiberPlanId] = useState()
    const [selectedInstallDates, setSelectedInstallDates] = useState([]);
    const [selectedInstallTimes, setSelectedInstallTimes] = useState([]);
    const [customInstallDate, setCustomInstallDate] = useState("");
    const [customInstallTime, setCustomInstallTime] = useState("");
    const [VerificationPhrase, setVerificationPhrase] = useState("");
    const [formConfig, setFormConfig] = useState(null);
    const installDateOptions = ["ASAP", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Next Week"];
    const installTimeOptions = ["Anytime", "Mornings", "Afternoons", "8AM-10AM", "9AM-11AM", "10AM-12PM", "11AM-1PM", "12PM-2PM", "1PM-3PM", "2PM-4PM", "3PM-5PM", "4PM-6PM", "5PM-7PM"];
    const [secondaryPhone, setSecondaryPhone] = useState("");

    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [appartment, setAppartment] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [goPaperless, setGoPaperless] = useState(false);
    const [fiberLeasedDeviceWithPromo, setFiberLeasedDeviceWithPromo] = useState(false);
    const [serviceAgreement, setServiceAgreement] = useState("");
    const [fiberONTLease, setFiberONTLease] = useState(false);
    const [boostRouterLease, setBoostRouterLease] = useState(false);
    const [installServiceCharge, setInstallServiceCharge] = useState(false);
    const [addressLine2, setAddressLine2] = useState("");
    const [connectReason, setConnectReason] = useState("");

    const getCompanyform = async () => {
        const req = new StringIntReq();
        req.int = fiberCompanyId;
        const res = await FiberCompanyApi.GetCompanyForm(req);
        if (res?.status?.success) {
            setFormConfig(res?.data);
        }
    }

    useEffect(() => {
        if (fiberCompanyId) {
            getCompanyform();
        }
    }, [fiberCompanyId])
    const handleInstallDateChange = (option) => {
        if (selectedInstallDates.includes(option)) {
            setSelectedInstallDates(selectedInstallDates.filter((date) => date !== option));
        } else {
            setSelectedInstallDates([...selectedInstallDates, option]);
        }
    };

    const handleInstallTimeChange = (option) => {
        if (selectedInstallTimes.includes(option)) {
            setSelectedInstallTimes(selectedInstallTimes.filter((time) => time !== option));
        } else {
            setSelectedInstallTimes([...selectedInstallTimes, option]);
        }
    };


    useEffect(() => {

    }, [selectedInstallDates, selectedInstallTimes, customInstallDate, customInstallTime]);

    const user = useContext(UserContext);
    const createCustomer = async (e) => {
        e.preventDefault();
        Swal.fire({
            icon: "info",
            title: "Creating Customer",
            text: "Please Wait",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading()
            }
        })
        if (formConfig.firstName && formConfig.firstNameRequired && !firstName) {
            Swal.fire({
                icon: "error",
                title: "Please Enter First Name",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.lastName && formConfig.lastNameRequired && !lastName) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Last Name",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.secondaryAccountHolderFullName && formConfig.secondaryAccountHolderFullNameRequired && !secondaryAccountHolder) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Secondary Account Holder",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.address && formConfig.addressRequired && !address) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Address",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.cellPhone && formConfig.cellPhoneRequired && !phone) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Phone Number",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.email && formConfig.emailRequired && !email) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Email",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (formConfig.email && formConfig.emailRequired && !emailRegex.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Invalid Email",
                text: "Please Enter a valid email address",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        const pinRegex = /^\d{4}$/;
        if (formConfig.fourDigitPin && formConfig.fourDigitPinRequired && fourDigitPin != null && !pinRegex.test(fourDigitPin)) {
            Swal.fire({
                icon: "error",
                title: "Invalid Pin",
                text: "Please Enter a 4 digit pin",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (lead.fidiumCustomer != null) {
            Swal.fire({
                icon: "error",
                title: "Customer Already Exists",
                text: "This lead already has a customer associated with it",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.fiberCompanyId && formConfig.fiberCompanyIdRequired && !fiberCompanyId) {
            Swal.fire({
                icon: "error",
                title: "Please Select Fiber Company",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.fiberPlanId && formConfig.fiberPlanIdRequired && !fiberPlanId) {
            Swal.fire({
                icon: "error",
                title: "Please Select Fiber Plan",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.timeZone && formConfig.timeZoneRequired && !timezone) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Timezone",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.desiredInstallDateString && formConfig.desiredInstallDateStringRequired && selectedInstallDates.length === 0 && !customInstallDate) {
            Swal.fire({
                icon: "error",
                title: "Please Select Desired Install Date",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.desiredInstallTimeString && formConfig.desiredInstallTimeStringRequired && selectedInstallTimes.length === 0 && !customInstallTime) {
            Swal.fire({
                icon: "error",
                title: "Please Select Desired Install Time",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.verificationPhrase && formConfig.verificationPhraseRequired && !VerificationPhrase) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Verification Phrase",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.securityQuestion1 && formConfig.securityQuestion1Required && !securityQuestion1) {
            Swal.fire({
                icon: "error",
                title: "Please Select Security Question 1",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.answerSecurityQuestion1 && formConfig.answerSecurityQuestion1Required && !securityAnswer1) {
            Swal.fire({
                icon: "error",
                title: "Please Answer Security Question 1",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.securityQuestion2 && formConfig.securityQuestion2Required && !securityQuestion2) {
            Swal.fire({
                icon: "error",
                title: "Please Select Security Question 2",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.answerSecurityQuestion2 && formConfig.answerSecurityQuestion2Required && !securityAnswer2) {
            Swal.fire({
                icon: "error",
                title: "Please Answer Security Question 2",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.schedulingNotes && formConfig.schedulingNotesRequired && !schedulingNotes) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Scheduling Notes",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.goPaperless && formConfig.goPaperlessRequired && !goPaperless) {
            Swal.fire({
                icon: "error",
                title: "Please Select Go Paperless",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.freeLeasedDeviceWithPromo && formConfig.freeLeasedDeviceWithPromoRequired && !fiberLeasedDeviceWithPromo) {
            Swal.fire({
                icon: "error",
                title: "Please Select Fiber Leased Device With Promo",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.serviceAgreement && formConfig.serviceAgreementRequired && !serviceAgreement) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Service Agreement",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.fiberONTLease && formConfig.fiberONTLeaseRequired && !fiberONTLease) {
            Swal.fire({
                icon: "error",
                title: "Please Select Fiber ONT Lease",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.boostRouterLease && formConfig.boostRouterLeaseRequired && !boostRouterLease) {
            Swal.fire({
                icon: "error",
                title: "Please Select Boost Router Lease",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.installServiceCharge && formConfig.installServiceChargeRequired && !installServiceCharge) {
            Swal.fire({
                icon: "error",
                title: "Please Select Install Service Charge",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.addressLine2 && formConfig.addressLine2Required && !addressLine2) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Address Line 2",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.steetAddress && formConfig.steetAddressRequired && !streetAddress) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Street Address",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.city && formConfig.cityRequired && !city) {
            Swal.fire({
                icon: "error",
                title: "Please Enter City",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.appartment && formConfig.appartmentRequired && !appartment) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Appartment",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.zipCode && formConfig.zipCodeRequired && !zipCode) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Zip Code",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (formConfig.state && formConfig.stateRequired && !state) {
            Swal.fire({
                icon: "error",
                title: "Please Enter State",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }


        if (formConfig.connectReason && formConfig.connectReasonRequired && !connectReason) {
            Swal.fire({
                icon: "error",
                title: "Please Enter Connect Reason",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }


        // const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        // if (!emailRegex.test(email)) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Invalid Email",
        //         text: "Please Enter a valid email address",
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         allowEnterKey: false,
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return;
        // }

        // const pinRegex = /^\d{4}$/;
        // if (fourDigitPin != null && !pinRegex.test(fourDigitPin)) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Invalid Pin",
        //         text: "Please Enter a 4 digit pin",
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         allowEnterKey: false,
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return;
        // }






        let req = new CreateCustomerReq()
        req.FirstName = firstName;
        req.LastName = lastName;
        req.SecondaryAccountHolderFullName = secondaryAccountHolder;
        req.Address = address;
        req.CellPhone = phone;
        req.Email = email;
        let installDate = "";
        if (selectedInstallDates.length > 0) {
            installDate = selectedInstallDates.join(",");
        }
        if (customInstallDate) {
            installDate = installDate ? installDate + "," + customInstallDate : customInstallDate;
        }
        req.DesiredInstallDateString = installDate;
        let installTime = "";
        if (selectedInstallTimes.length > 0) {
            installTime = selectedInstallTimes.join(",");
        }
        if (customInstallTime) {
            installTime = installTime ? installTime + "," + customInstallTime : customInstallTime;
        }
        req.fiberCompanyId = fiberCompanyId;
        req.DesiredInstallTime = installTime;
        req.FourDigitPin = fourDigitPin;
        req.SecurityQuestion1 = securityQuestion1;
        req.AnswerSecurityQuestion1 = securityAnswer1;
        req.SecurityQuestion2 = securityQuestion2;
        req.AnswerSecurityQuestion2 = securityAnswer2;
        req.FiberPlanString = fiberPlanId?.name;
        req.FiberPlanId = fiberPlanId?.id;
        req.TimeZone = timezone;
        req.SalesRepId = user?.id;
        req.NotesToFiberCompanySpecialInstructions = instructions;
        req.PromoCode = promoCode;
        req.FreeOffer = freeMonth;
        req.ConsentChecked = consentChecked;

        if (currentInternetSpeed) {
            const uniqueId = Date.now();
            req.SpeedTestBeforeImage = await uploadFileToFirebase(currentInternetSpeed, `speedTestBefore/${uniqueId}/${req.name}`);
        }
        req.SchedulingNotes = schedulingNotes;
        // req.installASAP = installASAP;
        req.LeadId = lead?.id;
        req.SalesOrgId = user?.salesOrgId ? user.salesOrgId : null;
        req.offerId = null
        req.IsHotLead = null;
        req.UserId = null;

        req.GoPaperless = goPaperless;
        req.FreeLeasedDeviceWithPromo = fiberLeasedDeviceWithPromo;
        req.ServiceAgreement = serviceAgreement;
        req.FiberONTLease = fiberONTLease;
        req.BoostRouterLease = boostRouterLease;
        req.InstallServiceCharge = installServiceCharge;
        req.AddressLine2 = addressLine2;
        req.SteetAddress = streetAddress;
        req.City = city;
        req.Appartment = appartment;
        req.ZipCode = zipCode;
        req.State = state;
        req.SecondaryPhone = secondaryPhone;

        req.VerificationPhrase = VerificationPhrase;
        req.Connectreason  = connectReason;
        let res = await CustomerApi.CreateFidiumCustomer(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Customer Created Successfully",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            // searchFiberHouses();
        }
        else {

            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    const moveFocus = (event) => {
        const target = event.target;
        if (target.value.length === target.maxLength) {
            const nextInput = target.nextElementSibling;
            if (nextInput) {
                nextInput.focus();
            }
        }
    }
    const handleBackspace = (event) => {
        const target = event.target;
        if (event.key === 'Backspace' && target.value.length === 0) {
            const prevInput = target.previousElementSibling;
            if (prevInput) {
                prevInput.focus();
                prevInput.value = ''; // Clear the value of the previous input
            }
        }
    }
    const goToFreeMonth = async () => {
        // setOpen(false);
        // show an input for a secret code "1234" and a button to submit. 
        // if the code is correct, then the user can proceed to the free month page. (setIndex(12))
        let confirmation = await Swal.fire({
            title: "ENTER 4 DIGIT CODE",
            text: "FOR FIBER SPECIALIST ONLY",
            // input: "password",
            html: `
            <h2 class="CodeInputsHeader">FOR FIBER SPECIALIST ONLY</h2>
            <div class="CodeInputs">
            <input type="password" id="code1" maxlength="1" autofocus>
            <input type="password" id="code2" maxlength="1">
            <input type="password" id="code3" maxlength="1">
            <input type="password" id="code4" maxlength="1">
            </div>
        `,
            showCancelButton: true,
            confirmButtonText: "Submit",
            confirmButtonColor: "#2761D0", // Change the color here
            cancelButtonText: "Cancel",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.hideLoading();
                // Add event listeners to move focus to the next input field
                document.getElementById('code1').addEventListener('input', moveFocus);
                document.getElementById('code2').addEventListener('input', moveFocus);
                document.getElementById('code3').addEventListener('input', moveFocus);
                // Add event listeners to handle backspace key
                document.getElementById('code2').addEventListener('keydown', handleBackspace);
                document.getElementById('code3').addEventListener('keydown', handleBackspace);
                document.getElementById('code4').addEventListener('keydown', handleBackspace);
            },
            willClose: () => {
                // Remove event listeners when the Swal modal closes
                document.getElementById('code1').removeEventListener('input', moveFocus);
                document.getElementById('code2').removeEventListener('input', moveFocus);
                document.getElementById('code3').removeEventListener('input', moveFocus);
                // Remove event listeners for backspace key
                document.getElementById('code2').removeEventListener('keydown', handleBackspace);
                document.getElementById('code3').removeEventListener('keydown', handleBackspace);
                document.getElementById('code4').removeEventListener('keydown', handleBackspace);
            }
        })

        if (!confirmation.isConfirmed) {
            Swal.fire({
                icon: "info",
                title: "Cancelled",
                text: "You have cancelled the process",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        const code1 = document.getElementById("code1").value;
        const code2 = document.getElementById("code2").value;
        const code3 = document.getElementById("code3").value;
        const code4 = document.getElementById("code4").value;

        const enteredCode = code1 + code2 + code3 + code4;

        if (enteredCode === "1234") {
            setIndex(12);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Invalid Code",
                text: "The code you entered is incorrect",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    return (
        <div className='customer_model'
        >
            <h1 >Create Customer</h1>
            {
                !formConfig
                &&
                !fiberCompanyId
                &&
                <div className='form_group'>
                    <label>Fiber Company</label>
                    <FiberCompanySelector placeholder='Select Fiber Company' setUser={setFiberCompanyId} />
                </div>
            }
            {
                fiberCompanyId
                &&
                !formConfig
                &&
                <CircularProgress
                    // color="primary"
                    size={40}
                    style={{
                        margin: "auto",
                        color: "rgb(37, 99, 235)"
                    }} />
            }
            {
                formConfig &&
                <form onSubmit={(e) => createCustomer(e)}>
                    <div className='form_group'>
                        <label>Fiber Company</label>
                        <FiberCompanySelector placeholder='Select Fiber Company' setUser={setFiberCompanyId} />
                    </div>
                    {
                        formConfig.fiberPlanId &&
                        <div className='form_group'>
                            <label>Fiber Plan</label>
                            <FiberPlanSelector fiberCompany={fiberCompanyId} placeholder='Select a Fiber Plan' setUser={setFiberPlan} disabled={!fiberCompanyId} />
                        </div>
                    }
                    {
                        formConfig.firstName &&
                        <div className='form_group'>
                            <label>First Name</label>
                            <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.lastName &&
                        <div className='form_group'>
                            <label>Last Name</label>
                            <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    }

                    {
                        formConfig.secondaryAccountHolderName &&
                        <div className='form_group'>
                            <label>Secondary Account Holder Full Name</label>
                            <input type='text' value={secondaryAccountHolder} onChange={(e) => setSecondaryAccountHolder(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.email &&
                        <div className='form_group'>
                            <label>Email</label>
                            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.cellPhone &&
                        <div className='form_group'>
                            <label>Phone Number</label>
                            <PhoneInput containerStyle={{ width: "100%" }} inputStyle={{
                                paddingLeft: "50px",
                                width: "100%",
                            }} country={'us'} excludeCountries={['IL']} value={phone} onChange={setPhone} />
                        </div>
                    }
                    {
                        formConfig.address &&
                        <div className='form_group'>
                            <label>Address</label>
                            <input type='text' value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.steetAddress &&
                        <div className='form_group'>
                            <label>Street Address</label>
                            <input type='text' value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.addressLine2 &&
                        <div className='form_group'>
                            <label>Address Line 2</label>
                            <input type='text' value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.city &&
                        <div className='form_group'>
                            <label>City</label>
                            <input type='text' value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.appartment &&
                        <div className='form_group'>
                            <label>Appartment</label>
                            <input type='text' value={appartment} onChange={(e) => setAppartment(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.zipCode &&
                        <div className='form_group'>
                            <label>Zip Code</label>
                            <input type='text' value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.state &&
                        <div className='form_group'>
                            <label>State</label>
                            <input type='text' value={state} onChange={(e) => setState(e.target.value)} />
                        </div>
                    }
                    {/* <FiberPlanSelector fiberCompany={offer.fiberCompanyId} placeholder='Select a Fiber Plan' setUser={setFiberPlan} disabled={!offer.fiberCompanyId} /> */}
                    {
                        formConfig.desiredInstallDateString &&
                        <div className="DesiredInstallDiv">
                            <h3>Desired Install Dates:</h3>
                            <div className="OptionsDiv">
                                {installDateOptions.map((option) => (
                                    <div key={option}>
                                        <input
                                            type="checkbox"
                                            id={option}
                                            checked={selectedInstallDates.includes(option)}
                                            onChange={() => handleInstallDateChange(option)}
                                        />
                                        <label htmlFor={option}>{option}</label>
                                    </div>
                                ))}
                                <div className="OtherDiv">

                                    <label htmlFor="customDate">Other Date</label>

                                    <input
                                        type="text"
                                        className="w-full border-2 border-gray-200 rounded-lg p-2"
                                        value={customInstallDate}
                                        onChange={(e) => setCustomInstallDate(e.target.value)}
                                        placeholder='Enter Desired Install Date'
                                    />

                                </div>
                            </div>
                        </div>
                    }

                    {
                        formConfig.desiredInstallTimeString &&
                        <div className="DesiredInstallDiv">
                            <h3>Desired Install Times:</h3>
                            <div className="OptionsDiv">
                                {installTimeOptions.map((option) => (
                                    <div key={option}>
                                        <input
                                            type="checkbox"
                                            id={option}
                                            checked={selectedInstallTimes.includes(option)}
                                            onChange={() => handleInstallTimeChange(option)}

                                        />
                                        <label htmlFor={option}>{option}</label>
                                    </div>
                                ))}
                                <div className="OtherDiv">

                                    <label htmlFor="customTime">Other Time</label>

                                    <input
                                        type="text"
                                        className="w-full border-2 border-gray-200 rounded-lg p-2"
                                        value={customInstallTime}
                                        onChange={(e) => setCustomInstallTime(e.target.value)}
                                        placeholder='Enter Desired Install Time'
                                    />

                                </div>
                            </div>
                        </div>
                    }
                    {
                        formConfig.fourDigitPin &&
                        <div className='form_group'>
                            <label>Four Digit Pin</label>
                            <input type='text' value={fourDigitPin} onChange={(e) => setFourDigitPin(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.verificationPhrase &&
                        <div className='form_group'>
                            <label>Verification Phrase</label>
                            <input placeholder="Enter Verification Phrase" value={VerificationPhrase} onChange={(e) => setVerificationPhrase(e.target.value)} />
                        </div>
                    }
                    {/* <SoftInput label="SQ1" placeholder="Enter Security Question 1" value={securityQuestion1} onChange={(e) => setSecurityQuestion1(e.target.value)} /> */}
                    {
                        formConfig.securityQuestion1 &&
                        <div>
                            <select className="w-full border-2 border-gray-200 rounded-lg p-2" value={securityQuestion1} onChange={(e) => setSecurityQuestion1(e.target.value)}>
                                <option value="">Select Security Question 1</option>
                                {/* <option value="First or Favorite Pet Name:">First or Favorite Pet Name</option>
                        <option value="First Car:">First Car</option>
                        <option value="Sports Team:">Sports Team</option>
                        <option value="Hobby:">Hobby</option> */}
                                <option value="Your Pet's Name:">Your Pet's Name?</option>
                                <option value="Where were you born:">Where were you born?</option>
                                <option value="What is your favorite color:">What is your favorite color?</option>
                                <option value="What is your High School Mascot:">What is your High School Mascot?</option>
                            </select>
                        </div>}
                    {
                        formConfig.answerSecurityQuestion1 &&
                        <div className='form_group'>
                            {/* <label>Answer</label> */}
                            <input type='text' placeholder='Answer To Security Question 1' value={securityAnswer1} onChange={(e) => setSecurityAnswer1(e.target.value)} />
                        </div>
                    }

                    {
                        formConfig.securityQuestion2 &&
                        <div className='form_group'>
                            <label>Security Question 2</label>
                            <select className="w-full border-2 border-gray-200 rounded-lg p-2" value={securityQuestion2} onChange={(e) => setSecurityQuestion2(e.target.value)}>
                                <option value="">Select Security Question 2</option>
                                <option value="Favorite Athlete:">Favorite Athlete</option>
                                <option value="Personal Hero:">Personal Hero</option>
                                <option value="First Crush:">First Crush</option>
                                <option value="Least Favorite Veggie:">Least Favorite Veggie</option>
                                <option value="NA">NA</option>
                            </select>
                        </div>}
                    {
                        formConfig.answerSecurityQuestion2 &&
                        <div className='form_group'>
                            <input type='text' value={securityAnswer2} placeholder='Answer To Security Question 2' onChange={(e) => setSecurityAnswer2(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.timeZone &&
                        <div className='form_group'>
                            <label>Timezone</label>
                            <select className="w-full border-2 border-gray-200 rounded-lg p-2" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                                <option value="">Select Timezone</option>
                                <option value="EST">EST</option>
                                <option value="CST">CST</option>
                                <option value="MST">MST</option>
                                <option value="PST">PST</option>
                            </select>

                        </div>
                    }
                    {
                        formConfig.notesToFiberCompanySpecialInstructions &&
                        <div className='form_group'>
                            <label>Special Intructions For the Company</label>
                            <textarea rows='5' value={instructions} onChange={(e) => setInstructions(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.schedulingNotes &&
                        <div className='form_group'>
                            <label>Scheduling Notes</label>
                            <input type='text' value={schedulingNotes} onChange={(e) => setSchedulingNotes(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.promoCode &&
                        <div className='form_group'>
                            <label>Promo Code</label>
                            <input type='text' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        </div>
                    }
                    {
                        formConfig.speedTestBeforeImage &&
                        <div className='form_group'>
                            <label>Current Internet Speed Test</label>
                            <input type='file' onChange={(e) => setCurrentInternetSpeed(e.target.files[0])} />
                        </div>
                    }


                    {
                        formConfig.serviceAgreement &&
                        <div className='form_group'>
                            <label htmlFor="serviceAgreement">Service Agreement</label>
                            {/* <input type="text" name="serviceAgreement" id="serviceAgreement" value={serviceAgreement} onChange={(e) => setServiceAgreement(e.target.value)} /> */}
                            <select value={serviceAgreement} onChange={(e) => setServiceAgreement(e.target.value)}>
                                <option value={'No Service Agreement'}>No Service Agreement</option>
                                <option value={'Commecial Employee Offer'}>Commecial Employee Offer</option>
                                <option value={'Free Equip For Life + 1 Promo'}>Free Equip For Life + 1 Promo</option>
                            </select>
                        </div>
                    }
                    {
                        formConfig.goPaperless &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="goPaperless" id="goPaperless" checked={goPaperless} onChange={(e) => setGoPaperless(e.target.checked)} />
                                <label htmlFor="goPaperless">Go Paperless</label>
                            </div>
                            <p className='details'>If you choose to receive a paper statement a $2 monthly statement fee will apply.</p>
                        </div>
                    }
                    {
                        formConfig.freeLeasedDeviceWithPromo &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="fiberLeasedDeviceWithPromo" id="fiberLeasedDeviceWithPromo" checked={fiberLeasedDeviceWithPromo} onChange={(e) => setFiberLeasedDeviceWithPromo(e.target.checked)} />
                                <label htmlFor="fiberLeasedDeviceWithPromo">Free Leased Device With Promo</label>
                            </div>
                            <p className='details'>$0.00 / Month</p>
                        </div>
                    }
                    {
                        formConfig.fiberONTLease &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="fiberONTLease" id="fiberONTLease" checked={fiberONTLease} onChange={(e) => setFiberONTLease(e.target.checked)} />
                                <label htmlFor="fiberONTLease">Fiber ONT Lease</label>
                            </div>
                            <p className='details'>$9.00 / Month</p>
                            <p className='details'>ONT lease is required for Fiber services.</p>
                            <p className='details'>*Purchase option also available</p>
                        </div>
                    }
                    {
                        formConfig.boostRouterLease &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type="checkbox" name="boostRouterLease" id="boostRouterLease" checked={boostRouterLease} onChange={(e) => setBoostRouterLease(e.target.checked)} />
                                <label htmlFor="boostRouterLease">Boost Router Lease W/1 Acc Point</label>
                            </div>
                            <p className='details'>BOOST by Lightcurve, formerly Rainier Connect, lets you enjoy maximum Wi-fi coverage and performance with a wireless mesh network. Includes one (1) access point to extend Wi-fi to those hard-to-get places in your home.</p>
                            <p className='details'>*$50 one-time technician install required for set-up.</p>
                            <p className='details'>Learn more about Boost Wi-Fi</p>
                        </div>
                    }
                    {
                        formConfig.installServiceCharge &&
                        <div className='checkbox_container'>
                            <div className='checkbox'>
                                <input type='checkbox' checked={installServiceCharge} onChange={(e) => setInstallServiceCharge(e.target.checked)} />
                                <label>Install/Service Charge Fiber</label>
                            </div>
                            <p className='details'>$50.00 (One-Time Charge)</p>
                            <p className='details'>Installation Charges for connecting service</p>
                        </div>
                    }
                    {
                        formConfig.connectReason &&
                        <div className='form_group'>
                            <label>Please tell us why you are adding new service today.</label>
                            <select value={connectReason} onChange={(e) => e.target.value === "" ? setConnectReason(null) : setConnectReason(e.target.value)}>
                                <option value="">Select Connect Reason</option>
                                <option value="REFERRAL">REFERRAL</option>
                                <option value="CAMPAIGN PROMOTION">CAMPAIGN PROMOTION</option>
                                <option value="LINKEDIN">LINKEDIN</option>
                                <option value="ADVERTISEMENT" >ADVERTISEMENT</option>
                                <option value="INSTAGRAM">INSTAGRAM</option>
                                <option value="LIGHTCURVE WEBSITE">LIGHTCURVE WEBSITE</option>
                                <option value="INTERNET / GOOGLE SEARCH">INTERNET / GOOGLE SEARCH</option>
                                <option value="TWITTER" >TWITTER</option>
                                <option value="FACEBOOK">FACEBOOK</option>
                            </select>
                        </div>
                    }
                    {
                        formConfig.consentChecked &&
                        <div className='flex items-center gap-4'>
                            <input type="checkbox" name="consentChecked" id="consentChecked" checked={consentChecked} onChange={(e) => setConsentChecked(e.target.checked)} />
                            <label htmlFor="consentChecked">I consent to text and/or email communications to ensure the free Fidium Fiber connection.</label>
                        </div>
                    }


                    <button type='submit'>Submit</button>
                </form>
            }

        </div>
    )
}

export default CreateCustomer