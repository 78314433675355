import { CardMembership, Dashboard } from "@mui/icons-material";
import { FcCustomerSupport } from "react-icons/fc";
import { MdDashboard, MdOutlineBugReport } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import { LuFolderCog } from "react-icons/lu";
import { VscReferences } from "react-icons/vsc";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { HiOutlineCreditCard } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";
const CustomerMenuItems = [
    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color,size=40)=><MdDashboard size={size} color={color} />,
    },
    {
        name: "My Lightning Plan",
        key: "MyLightningPlan",
        route: "/my-lightning-plan",
        icon: (color,size=40)=><HiOutlineClipboardDocumentList size={size} color={color} />,
    },
    {
        name: "Add Feedback",
        key: "AddFeedback",
        route: "/add-feedback",
        icon: (color,size=40)=><VscFeedback size={size} color={color} />,
    },
    {
        name: "Referral Program",
        key: 'referralProgram',
        route: '/referral-program',
        icon:(color,size=40)=> <VscReferences size={size} color={color} />,
    },
    {
        name: "My Lightning Rep",
        key: 'myLightningRep',
        route: '/my-lightning-rep',
        icon: (color,size=40) => <HiOutlineCreditCard size={size} color={color} />,
    },
    {
        name:"Support Center",
        key: 'supportCenter',
        route: '/support-center',
        icon: (color,size=40) => <BiSupport size={size} color={color} />,
        disabled: true
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        icon: (color,size=40) => <MdOutlineBugReport size={size} color={color} />,
    },
    
]

export default CustomerMenuItems;