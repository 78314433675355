class CreateServicableAreaReq{
    constructor(){
        this.name = null;
        this.color = null;
        this.status = null;
        this.notes = null;
        this.salesOrgId = null;
        this.createdById = null;    
        this.coordinates = [
          
        ];
    }
}
export default CreateServicableAreaReq;