import { Cancel } from "@mui/icons-material";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import ShopItemsApi from "../../../API/ShopItemsApi";
import { UserContext } from "../../../App";
import Sidebar from "../../../Layouts/Sidebar";
import CreateFile from "../../../Requests/CloudFile/CreateFileReq";
import CreateShopItem from "../../../Requests/ShopItems/CreateShopItem";
import CreateShopItemCategory from "../../../Requests/ShopItems/CreateShopItemCategory";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import ShopCategorySelector from "./../../../components/ShopCategorySelector";
import "./index.scss";
import MobileLayout from "../../../Layouts/MobileLayout";
import Layout from "../../../Layouts/Layout";
const Index = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("")
  const [files, setFiles] = useState([]);
  const storage = getStorage();
  const user = useContext(UserContext);
  const [categoryName, setCategoryName] = useState("")
  const [categoryId, setCategoryId] = useState(null);

  async function createShopItem() {

    Swal.fire({
      icon: 'info',
      title: 'Creating Shop Item',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    if (!name) {
      Swal.fire({
        icon: 'error',
        title: 'Name is required',
        text: 'Please enter Name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    if (!description) {
      Swal.fire({
        icon: 'error',
        title: 'Description is required',
        text: 'Please enter Description',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (!categoryId) {
      Swal.fire({
        icon: 'error',
        title: 'Category is required',
        text: 'Please Select Category',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (!price) {
      Swal.fire({
        icon: 'error',
        title: 'Price is required',
        text: 'Please enter Price',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (isNaN(price) || price <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Price',
        text: 'Please enter a valid Price',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    const req = new CreateShopItem();

    req.name = name;
    req.description = description;
    req.price = price;
    req.shopCategoryId = categoryId
    const totalFiles = files.length;
    let downloadUrls = [];
    let filePaths = [];
    let index = 0;
    // Function to upload file and get download URL
    const uploadAndGetData = async (file, type) => {
      // Generate a unique ID 
      const uniqueId = uuidv4();

      const storageRef = ref(storage, `/ShopItems/${uniqueId}`);
      await uploadBytes(storageRef, file);
      // Get download URL and push to downloadURLs array
      const downloadURL = await getDownloadURL(storageRef);
      downloadUrls.push(downloadURL);
      
      filePaths.push(`/ShopItems/${uniqueId}`)
    };

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      Swal.update({
        title: `Uploading Images... ${i + 1}/${totalFiles}`,
      });
      await uploadAndGetData(file.file, "image");
    }


    for (let index = 0; index < totalFiles; index++) {
      const createFile = new CreateFile();
      createFile.name = files[index].file.name;
      createFile.extension = createFile.name.split('.').pop();
      createFile.url = downloadUrls[index];
      createFile.path = filePaths[index];
      createFile.fileSize = files[index].file.size.toString();
      createFile.userId = user.id;
      createFile.sellingCardId = 1;
      createFile.caption = "123";
      createFile.fileType = files[index].file.type
      req.files.push(createFile);
    }
    setFiles([])

    ShopItemsApi.CreateShopItems(req).then(
      (res) => {
        if (res?.status?.success) {
          Swal.fire({
            icon: 'success',
            title: 'Shop Item Created',
            text: "Shop Item has been created",
            // allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,

          });
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res?.status?.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
        }
      })



  }

  async function createShopItemCategory() {
    Swal.fire({
      icon: 'info',
      title: 'Creating Shop Item Category',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    if (!categoryName) {
      Swal.fire({
        icon: 'error',
        title: 'Category Name is required',
        text: 'Please enter Name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    const req = new CreateShopItemCategory();
    req.string = categoryName;
    ShopItemsApi.CreateShopItemsCategory(req).then(
      (res) => {
        if (res?.status?.success) {
          Swal.fire({
            icon: 'success',
            title: 'Shop Item Category Created',
            text: "Shop Item Category has been created",
            // allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,

          });
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res?.status?.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
        }
      }
    )

  }

  function addFile(file, id) {
    setFiles((prev) => [...prev, { file: file, id: id }])
  }

  function removeFile(file, id) {
    setFiles(files.filter(f => f.id != id))
  }

  useEffect(() => {
    
  }, [files]
  )

  const onDrop = (acceptedFiles) => {
    setFiles(
      (prevState) => [...prevState, ...acceptedFiles]
    )
  }

  const onCancel = (file) => {
    setFiles(
      (prevState) => prevState.filter(
        (f) => f !== file
      )
    )
  }
  return (
    <Layout>
      <div className="createShopItemContainer" >
        <div className="createShopItemForm" >
          <h1 >Create Shop Items</h1>
          <input placeholder="Enter the Name" value={name} onChange={e => setName(e.target.value)} />
          <input placeholder="Enter the Description" value={description} onChange={e => setDescription(e.target.value)} />
          <input placeholder="Enter the Price" value={price} onChange={e => setPrice(e.target.value)} />
          <Dropzone onDrop={onDrop} >
            {({ getRootProps, getInputProps }) => (
              <section style={{
                height: "40vh",
                width: "100%",
              }}>
                <div className="createShopItemDropzone"  {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p >Drag & drop<br />
                    your files or browse</p>
                </div>
              </section>
            )}
          </Dropzone>
          <div className="createShopItemImagePreviewContainer" >
            {
              files.map((file) => {
                return (
                  <div className="createShopItemImagePreview">
                    <Cancel onClick={() => { onCancel(file) }} className='absolute top-0 right-0 text-red-800 cursor-pointer z-50' />
                    {file.type.includes('image') && <img src={URL.createObjectURL(file)} className='h-full w-full' alt="" />}
                    {file.type.includes('video') && <video src={URL.createObjectURL(file)} className='h-full w-full' alt="" />}
                    {file.type.includes('audio') && <audio src={URL.createObjectURL(file)} className='h-full w-full' alt="" />}
                    {file.type.includes('application') && <img src="/file.png" className='h-full w-full' alt="" />}
                  </div>
                )
              })
            }
          </div>
          <ShopCategorySelector  placeholder={"Select a Shop Category"} setUser={setCategoryId} />
          <button onClick={createShopItem} >Create</button>
        </div>

        <div className="createShopCategoryForm" >
          <h1 >Create Item Category</h1>
          <Input placeholder="Enter the Category Name" value={categoryName} onChange={e => setCategoryName(e.target.value)} />
          <Button onClick={createShopItemCategory} >Create</Button>
        </div>

      </div>
    </Layout>
  );
}

export default Index;