class BugTicketReq {
    constructor() {
      this.title = '';
      this.description = '';
      this.userType = '';
      this.userName = ''
      this.isResolved = null;
      this.pagingParams = { pageNumber: 1, pageSize: 10 };
    }
};

export default BugTicketReq;