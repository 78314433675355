import React, { useState } from 'react'
import Swal from 'sweetalert2'
import CustomerApi from '../../../../API/CustomerApi';
const EditCheckAvailability = ({checkAvailability,setOpen,reload}) => {
    const [firstName, setFirstName] = useState(checkAvailability.firstName);
    const [lastName, setLastName] = useState(checkAvailability.lastName);
    const [email, setEmail] = useState(checkAvailability.email);
    const [phone, setPhone] = useState(checkAvailability.phone);
    const [address, setAddress] = useState(checkAvailability.address);
    const [notes, setNotes] = useState(checkAvailability.notes);
    const [seen, setSeen] = useState(checkAvailability.seen);
    const [contacted, setContacted] = useState(checkAvailability.contacted);
    const [availabilityType, setAvailabilityType] = useState(checkAvailability.availabilityType);
    const [isAvailable, setIsAvailable] = useState(checkAvailability.isAvailable?true:false);

    const editCheckAvailability = async () => {
        Swal.fire({
            icon:"info",
            title:"Editing Check Availability",
            text:"Please Wait...",
            showConfirmButton:false,
            customClass:{
                container:"custom-swal"
            },
            willOpen:() => {
                Swal.showLoading();
            }

        })
        if(!firstName){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"First Name is required",
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }

        if(!lastName){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Last Name is required",
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }

        if(!email){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Email is required",
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }

        if(!phone){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Phone is required",
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }

        if(!address){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Address is required",
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }

        if(!notes){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Notes is required",
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }

        if(!availabilityType){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Availability Type is required",
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }

        const req = new FormData();
        req.append('id', checkAvailability.id);
        req.append('firstName', firstName);
        req.append('lastName', lastName);
        req.append('email', email);
        req.append('phone', phone);
        req.append('address', address);
        req.append('notes', notes);
        req.append('seen', seen);
        req.append('contacted', contacted);
        req.append('availabilityType', availabilityType);
        req.append('isAvailable', isAvailable);

        const res = await CustomerApi.EditAvailabilityCheck(req);
        if(res?.status?.success){
            Swal.fire({
                icon:"success",
                title:"Success",
                text:res?.status?.message,
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
            setOpen(false)
            reload()
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Error",
                text:res?.status?.message,
                showConfirmButton:false,
                customClass:{
                    container:"custom-swal"
                },
                didOpen:() => {
                    Swal.hideLoading();
                }
            })
        }
    }


    return (
        <div className='edit_check_availability_form'>
            <h1>Edit Check Availability</h1>
            <div className='inputs'>
                <input type='text' placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <input type='text' placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <input type='text' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type='text' placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                <input type='text' placeholder='Address' value={address} onChange={(e) => setAddress(e.target.value)} />
                <input type='text' placeholder='Notes' value={notes} onChange={(e) => setNotes(e.target.value)} />
                <div className='checkbox'>
                    <input type='checkbox' checked={seen} onChange={(e) => setSeen(e.target.checked)} />
                    <label>Seen</label>
                </div>
                <div className='checkbox'>
                    <input type='checkbox' checked={contacted} onChange={(e) => setContacted(e.target.checked)} />
                    <label>Contacted</label>
                </div>
                <input type='text' placeholder='Availability Type' value={availabilityType} onChange={(e) => setAvailabilityType(e.target.value)} />
                <div className='checkbox'>
                    <input type='checkbox' checked={isAvailable} onChange={(e) => setIsAvailable(e.target.checked)} />
                    <label>Is Available</label>
                </div>
            </div>
            <button onClick={editCheckAvailability}>Save</button>
        </div>
    )
}

export default EditCheckAvailability