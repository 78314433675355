class CancelationFormReq {
    constructor() {
        this.payLess = null;
        this.payLessSubAnswer = null;
        this.bundledService = null;
        this.bundledServiceSubAnswer = null;
        this.inContract = null;
        this.inContractSubAnswer = null;
        this.other = null;
        this.otherSubAnswer = null;
        this.spouse = null;
        this.movingSoon = null;
        this.tooBusy = null;
        this.fidiumCustomerId = null;
        this.phoneNumber = null;
        this.urlData = null;
    }

}

export default CancelationFormReq;