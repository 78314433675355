import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import SellingCard from '../../../Shared/ManageSellingCards/components/SellingCard'
import Dropzone from 'react-dropzone'
import { Cancel } from '@mui/icons-material'
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage"
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'
import SellingCardApi from '../../../../API/SellingCardApi'
import { UserContext } from '../../../../App'
import CreateFile from '../../../../Requests/CloudFile/CreateFileReq'
import CreateSellingCardReq from '../../../../Requests/SellingCard/CreateSellingCardReq'
import FileUpload from '../../../../components/FileUpload'
import { useNavigate } from 'react-router-dom'
import '../index.scss'
const CreateThumbnail = ({ card, setCard, search }) => {

    const [files, setFiles] = useState(null)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    let storage = getStorage();
    let user = useContext(UserContext);
    const navigate = useNavigate();
    const handleSubmit = async () => {

        Swal.fire({
            icon: 'info',
            title: 'Creating',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: { container: 'custom-swal' },
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (!name) {
            Swal.fire({
                icon: 'error',
                title: 'Name is required',
                text: 'Please enter Name',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                customClass: { container: 'custom-swal' },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!description) {
            Swal.fire({
                icon: 'error',
                title: 'description is required',
                text: 'Please enter description',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                customClass: { container: 'custom-swal' },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        const totalFiles = files.length;
        let downloadURLs = [];
        let filePaths = [];
        let index = 0;
        // Function to upload file and get download URL
        const uploadAndGetData = async (file, type) => {
            // Generate a unique ID 
            const uniqueId = uuidv4();


            let p = `/sellingCards/${user.firebaseId}/${uniqueId}`

            // Update storageRef to use the unique ID

            const storageRef = ref(storage, p);
            const task = uploadBytesResumable(storageRef, file);

            task.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    Swal.update({
                        text: `Uploading File ${progress.toFixed(0)}%`,
                        customClass: { container: 'custom-swal' }
                    })
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    console.log(error)
                },
                async () => {
                    console.log('Upload is completed');
                    const downloadURL = await getDownloadURL(storageRef);
                    downloadURLs.push(downloadURL);

                    filePaths.push(p)
                    const req = new CreateSellingCardReq();
                    req.name = name;
                    req.salesOrgId = user?.salesOrgId;
                    req.description = description;

                    for (let index = 0; index < totalFiles; index++) {
                        const createFile = new CreateFile();
                        createFile.name = files[index].name;
                        createFile.extension = createFile.name.split('.').pop();
                        createFile.url = downloadURLs[index];
                        createFile.path = filePaths[index];
                        createFile.fileSize = files[index].size.toString();
                        createFile.userId = user.id;
                        createFile.sellingCardId = 1;
                        createFile.caption = "123";
                        createFile.fileType = files[index].type
                        req.files.push(createFile);
                    }
                    // req.files.push(file);

                    SellingCardApi.CreateSellingCard(req).then(
                        (res) => {

                            if (res?.status?.success) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Selling Card Created',
                                    text: "Selling Card has been created",
                                    // allowOutsideClick: false,
                                    customClass: { container: 'custom-swal' },
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: false,
                                    didClose: () => {
                                        navigate(`/manage-training-modules/selling-card-details/${res.data.id}`)
                                    }

                                });
                                // setCard(res.data);
                                // search()


                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: res?.status?.message,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    customClass: { container: 'custom-swal' },
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }

                        }
                    )
                }
            );

        };

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            await uploadAndGetData(file, "image");
        }


    }

    const onDrop = (acceptedFiles) => {
        if (files.length == 0)
            setFiles(
                (prevState) => [...prevState, ...acceptedFiles]
            )
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "You can only add one file at the time of creation",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                customClass: { container: 'custom-swal' },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }
    }

    const onCancel = (file) => {
        setFiles(
            (prevState) => prevState.filter(
                (f) => f !== file
            )
        )
    }

    useEffect(
        () => {
            console.log(files)
        }, [files]
    )
    return (
        <div className='createSellingCardInnerContainer'>
            <div className='createSellingCardForm' >
                <input value={name} onChange={(e) => { setName(e.target.value.slice(0, 20)) }} placeholder='Name' />
                <FileUpload acceptOnly={'image/*,video/*'} multiple={true} file={files} setFile={setFiles} />
                {/* <div className='createSellingCardImagePreviewContainer' >
                    {
                        files.map((file) => {
                            return (
                                <div >
                                    <Cancel onClick={() => { onCancel(file) }} />
                                    {
                                        file.type.startsWith('image/') ?
                                            <img src={URL.createObjectURL(file)} alt="" /> :
                                            file.type.startsWith('video/') ?
                                                <video src={URL.createObjectURL(file)} controls /> : null
                                    }
                                </div>
                            )
                        })
                    }
                </div> */}
                <textarea onChange={(e) => { setDescription(e.target.value.slice(0, 100)) }} placeholder='Enter selected file’s Description' />

                <Button onClick={() => {
                    handleSubmit();
                }}>Create Training Module</Button>
            </div>
            {/* <div className='thumbnailPreviewContainer' >
                <h1 >Thumbnail Preview</h1>
                <div >
                    <SellingCard sellingCard={{
                        name: name ? name : "Training Module Name",
                        description: description ? description : "Training Module Description",
                        files: files?files.map((file) => {
                            return {
                                url: URL.createObjectURL(file),
                                description: "File Description",
                                fileType: file.type
                            }
                        }):[]

                    }} sample={true} />
                </div>
                <Button onClick={() => {
                    handleSubmit();
                }}>Create Training Module</Button>
            </div> */}
            {/* <img className='absolute bottom-0 right-0' src='/images/CreateSellingCard/right.png' /> */}
        </div>
    )
}

export default CreateThumbnail