import React from 'react'
import "./FileUpload.scss"
import { IoMdCloseCircle } from 'react-icons/io'



const getIcon = (file) => {
    if (file.type.startsWith('image/')) {
        return <img style={{
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }} src={URL.createObjectURL(file)} alt="" />
    }
    else if (file.type.startsWith('video/')) {
        return <video style={{
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }} src={URL.createObjectURL(file)} controls />
    }
    else {

        let extension = file.name.split('.').pop()
        return <div className='icon' style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <span className={`fiv-cla fiv-icon-${extension}`} ></span>
            <p style={{
                textAlign: "center",
                fontSize: "0.8rem",
                color: "blue",
                marginTop: "0.5rem"
            }} >{file.name}</p>
        </div>
    }
}

const Index = ({ placeholder = "Upload File", file, setFile, multiple, acceptOnly }) => {




    return (
        <label htmlFor='file_upload' className='file_upload_container'>
            {file && <IoMdCloseCircle className='close' color='red' style={{ cursor: "pointer" }} size={30} onClick={(e) => { e.preventDefault(); setFile(null) }} />}
            <input onChange={(e) => {
                // console.log(e.target.files);
                if (multiple) {
                    if (e.target.files.length > 0)
                        setFile([e.target.files[0]]);
                }
                else {
                    setFile(e.target.files[0]);
                }
            }} type='file' id='file_upload' accept={acceptOnly} />
            {
                file ?
                    (multiple ?
                        getIcon(file[0]) : getIcon(file))
                    :
                    <span>{placeholder}</span>
            }
        </label>
    )
}

export default Index