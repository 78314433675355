/* eslint-disable react-hooks/exhaustive-deps */
import OlLayerTile from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import React, { useEffect, useState } from 'react'
const SatelliteLayer = ({satelliteMode,map}) => {
const [satelliteLayer, setSatelliteLayer] = useState(null)
const [labelLayer, setLabelLayer] = useState(null)
useEffect(
    ()=>{
        console.log(map)
        console.log(satelliteMode,"satelliteMode")
        console.log(satelliteLayer,"satelliteLayer")
        if(!satelliteMode && satelliteLayer){
            map.removeLayer(satelliteLayer)
            map.removeLayer(labelLayer)
            setSatelliteLayer(null)
            setLabelLayer(null)
        }

        if(satelliteMode && !satelliteLayer){
            console.log("adding satellite layer")
            const layer = new OlLayerTile({
                source: new XYZ({
                    url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
                })
            })
            const labelsLayer = new OlLayerTile({
                source: new XYZ({
                    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
                    attributions: 'Powered by Esri',
                    attributionsCollapsible: false,
                    maxZoom: 23
                })
            });

            map.addLayer(layer)
            map.addLayer(labelsLayer)
            setSatelliteLayer(layer)
            setLabelLayer(labelsLayer)
        }
    },[satelliteMode]
)

  return (
    <>
    </>
  )
}

export default SatelliteLayer