import React from 'react'



export default function Index({ 
  size = 'medium', 
  color = '#181e4b',
  showText = false 
}) {
  const sizeClasses = {
    small: 'w-8 h-8',
    medium: 'w-16 h-16',
    large: 'w-24 h-24'
  }

  const svgSizeClasses = {
    small: 'w-4 h-4',
    medium: 'w-8 h-8',
    large: 'w-12 h-12'
  }

  const orbitSizeClasses = {
    small: 'w-1 h-1',
    medium: 'w-2 h-2',
    large: 'w-3 h-3'
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`relative ${sizeClasses[size]}`}>
        {/* Lightning bolt */}
        <div className="absolute inset-0 flex items-center justify-center">
          <svg
            className={`${svgSizeClasses[size]} animate-pulse`}
            fill="none"
            stroke={color}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
        </div>

        {/* Orbiting circles */}
        {[0, 1, 2, 3, 4].map((index) => (
          <div
            key={index}
            className={`absolute ${orbitSizeClasses[size]} rounded-full opacity-60`}
            style={{
              backgroundColor: color,
              animation: `orbitLightning${size.charAt(0).toUpperCase() + size.slice(1)} 3s linear infinite`,
              animationDelay: `${index * 0.6}s`,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ))}

        {/* Pulsing ring */}
        <div 
          className="absolute inset-0 rounded-full animate-ping opacity-30" 
          style={{ borderWidth: size === 'small' ? '1px' : '2px', borderColor: color }}
        />

        {/* Rotating ring */}
        <div
          className="absolute inset-0 rounded-full opacity-50"
          style={{
            borderWidth: size === 'small' ? '1px' : '2px',
            borderColor: color,
            borderRightColor: 'transparent',
            borderLeftColor: 'transparent',
            animation: 'spin 3s linear infinite',
          }}
        />
      </div>

      {showText && (
        <div className={`mt-2 text-${color} text-sm font-bold tracking-wider animate-pulse`}>
          Loading...
        </div>
      )}

      <span className="sr-only">Loading...</span>
    </div>
  )
}