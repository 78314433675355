/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ComissionApi from "../../../API/ComissionApi";
import FiberPlanApi from "../../../API/FiberPlanApi";
import anonUser from "../../../assets/images/userGrey.png";
import FiberPlanSelector from '../../../components/FiberPlanSelector';
import UserSelector from "../../../components/UserSelector";
import Layout from "../../../Layouts/Layout";
import AddFiberPlanToCommissionPlan from "../../../Requests/ComissionPlan/AddFiberPlanToCommissionPlan";
import AddUserToCommissionPlanReq from "../../../Requests/ComissionPlan/AddUserToCommissionPlanReq";
import GetCommissionPlanReq from "../../../Requests/ComissionPlan/GetCommissionPlanReq";
import SearchFiberCommissionPlanReq from "../../../Requests/ComissionPlan/SearchFiberCommissionPlanReq";
import StringIntReq from "../../../Requests/StringIntReq";
import "./CommissionPlanDetails.scss";
import { IoArrowBack } from "react-icons/io5";
const Index = () => {
  const { id } = useParams();
  const [commissionPlan, setCommissionPlan] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [fiberPl, setFiberPl] = React.useState(null);

  const [level1Pay, setLevel1Pay] = React.useState(null);
  const [level2Pay, setLevel2Pay] = React.useState(null);
  const [level2Count, setLevel2Count] = React.useState(null);
  const [overrides, setOverrides] = React.useState(null);
  const [fiberCommissionPlans, setFiberCommissionPlans] = React.useState(null);
  const [commissionName, setCommissionName] = React.useState(null);
  const [editNameMode, setEditNameMode] = React.useState(false);

  const [editPlanId, setEditPlanId] = React.useState(null);
  const [editValues, setEditValues] = React.useState({});
  const navigate = useNavigate();
  const toggleEdit = (plan) => {
    if (editPlanId === plan.id) {
      setEditPlanId(null);
    } else {
      setEditPlanId(plan.id);
      setEditValues({
        commissionPlanId: commissionPlan.id,
        level1Pay: plan.level1Pay,
        level2Pay: plan.level2Pay,
        level2Count: plan.level2Count,
        overrides: plan.overrides,
      });
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const saveEdits = async (planId) => {
    editValues.FiberCommissionId = planId;
    console.log(editValues)
    if (!editValues.commissionPlanId) {
      Swal.fire({
        icon: 'error',
        title: 'Commission Plan ID is required',
        text: 'Please select a Commission Plan ID',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!editValues.FiberCommissionId) {
      Swal.fire({
        icon: 'error',
        title: 'Fiber Plan ID is required',
        text: 'Please select a Fiber Plan ID',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (editValues.level1Pay === null || editValues.level1Pay === '') {
      Swal.fire({
        icon: 'error',
        title: 'Level 1 Pay is required',
        text: 'Please enter Level 1 Pay',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (editValues.level2Pay === null || editValues.level2Pay === '') {
      Swal.fire({
        icon: 'error',
        title: 'Level 2 Pay is required',
        text: 'Please enter Level 2 Pay',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (editValues.level2Count === null || editValues.level2Count === '') {
      Swal.fire({
        icon: 'error',
        title: 'Level 2 Count is required',
        text: 'Please enter Level 2 Count',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (editValues.overrides === null || editValues.overrides === '') {
      Swal.fire({
        icon: 'error',
        title: 'Overrides is required',
        text: 'Please enter Overrides',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const res = await FiberPlanApi.EditFiberPlanToCommissionPlan(editValues);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Fiber Plan Edited successfully",
        didOpen: () => {
          Swal.hideLoading();
          setLevel1Pay(null);
          setLevel2Pay(null);
          setLevel2Count(null);
          setOverrides(null);
          getFiberCommissionPlans();
        },
      });
      search();

    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        didOpen: () => {
          Swal.hideLoading();
          setLevel1Pay(null);
          setLevel2Pay(null);
          setLevel2Count(null);
          setOverrides(null);
          getFiberCommissionPlans();
        },
      });
    }

  };

  useEffect(() => {
    setLevel1Pay(null)
    setLevel2Pay(null)
    setLevel2Count(null)
    setOverrides(null)
  }, [fiberPl]);


  const search = async () => {
    const req = new GetCommissionPlanReq();
    req.id = id;
    req.name = null;
    req.description = null;
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1;
    const res = await ComissionApi.SearchCommissionPlans(req);
    if (res?.status?.success) {
      console.log(res.data);
      setCommissionPlan(res?.data?.list[0]);
      // setNewCommissionPlan(res?.data?.list[0]);
      setCommissionName(res?.data?.list[0]?.name);
      setLoading(false);

    }
  };

  const addUser = async () => {
    Swal.fire({
      icon: "info",
      title: "Adding User",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!userId) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a user",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const req = new AddUserToCommissionPlanReq();
    req.id2 = userId;
    req.id1 = commissionPlan.id;
    const res = await ComissionApi.AddUserToCommissionPlan(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "User added successfully",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      search();

    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };


  const getFiberCommissionPlans = async () => {
    let req = new SearchFiberCommissionPlanReq();
    req.commissionId = id;
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 100;

    const res = await ComissionApi.SearchFiberCommissionPlans(req);
    if (res?.status?.success) {
      console.log(res);
      setFiberCommissionPlans(res.data.list);
    }
  }



  const addFiberCommission = async () => {
    Swal.fire({
      icon: "info",
      title: "Adding Fiber Plan to Commission Plan",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!fiberPl) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a user",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const req = new AddFiberPlanToCommissionPlan();

    if (!commissionPlan.id) {
      Swal.fire({
        icon: 'error',
        title: 'Commission Plan ID is required',
        text: 'Please select a Commission Plan ID',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!fiberPl) {
      Swal.fire({
        icon: 'error',
        title: 'Fiber Plan ID is required',
        text: 'Please select a Fiber Plan ID',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (level1Pay === null || level1Pay === '') {
      Swal.fire({
        icon: 'error',
        title: 'Level 1 Pay is required',
        text: 'Please enter Level 1 Pay',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (level2Pay === null || level2Pay === '') {
      Swal.fire({
        icon: 'error',
        title: 'Level 2 Pay is required',
        text: 'Please enter Level 2 Pay',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (level2Count === null || level2Count === '') {
      Swal.fire({
        icon: 'error',
        title: 'Level 2 Count is required',
        text: 'Please enter Level 2 Count',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (overrides === null || overrides === '') {
      Swal.fire({
        icon: 'error',
        title: 'Overrides is required',
        text: 'Please enter Overrides',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }


    req.CommisionPlanId = commissionPlan.id;
    req.fiberPlanId = fiberPl.id;
    req.Level1Pay = level1Pay;
    req.Level2Pay = level2Pay;
    req.Level2Count = level2Count;
    req.Overrides = overrides;
    console.log(req)
    const res = await FiberPlanApi.AddFiberPlanToCommissionPlan(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Fiber Plan added successfully",
        didOpen: () => {
          Swal.hideLoading();
          setLevel1Pay(null);
          setLevel2Pay(null);
          setLevel2Count(null);
          setOverrides(null);
          getFiberCommissionPlans();
        },
      });
      search();

    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        didOpen: () => {
          Swal.hideLoading();
          setLevel1Pay(null);
          setLevel2Pay(null);
          setLevel2Count(null);
          setOverrides(null);
          getFiberCommissionPlans();
        },
      });
    }


  };

  const editCommissionPlan = async () => {
    Swal.fire({
      icon: "info",
      title: "Updating Commission Plan",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new GetCommissionPlanReq();
    req.id = commissionPlan.id;
    req.name = commissionName;
    const res = await ComissionApi.EditCommissionPlan(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Commission Plan updated successfully",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      search();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const removeUser = async (userId) => {
    alert(userId);
    const selected = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (!selected.isConfirmed) {
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Removing User",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = userId;
    const res = await ComissionApi.RemoveUserFromCommissionPlan(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "User removed successfully",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      search();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    search();
    getFiberCommissionPlans();
  }, []);

  return (
    <Layout>
      <IoArrowBack color='black' size={30} style={{ cursor: 'pointer', marginLeft: "1rem", marginBottom: "1rem" }} onClick={() => navigate(-1)} />
      <div className="commission_plan_details">
        <h1 className="title">
          Commission Plan Details | {commissionPlan?.fiberCompany?.name ? commissionPlan?.fiberCompany?.name : "No fiber company"}
        </h1>
        {
          commissionPlan &&
          <div className="details2 col-span-2">
            <div className="add_users">
              <div className="grid grid-cols-2">
                <p className="font-bold text-lg">Commission Name</p>
                <input
                  className="border border-gray-300 p-2 rounded-md" disabled={!editNameMode}
                  type="text" value={commissionName} onChange={(e) => setCommissionName(e.target.value)} />
              </div>
              {
                editNameMode ?
                  <div className="grid grid-cols-2 gap-4">
                    <button className="add_user_btn" onClick={() => {
                      setEditNameMode(false)
                      setCommissionName(commissionPlan.name)
                    }}>Cancel</button>
                    <button className="add_user_btn" onClick={() => {
                      setEditNameMode(false)
                      editCommissionPlan()
                    }}>Save</button>
                  </div>
                  :
                  <button className="add_user_btn" onClick={() => {
                    setEditNameMode(true)
                  }}>Edit</button>
              }
            </div>
          </div>
        }
        {loading ? (
          <div className="spinner">
            <CircularProgress
              size={40}
              sx={{
                color: "var(--color-primary)",
              }}
            />
          </div>
        ) : (
          <>
            <div className="details2">
              <div className="add_users">
                <h3 className="add_users_title">Add Users</h3>
                <UserSelector placeholder="Search for users" setUser={setUserId} userTypes={["SalesOrgAdmin", "SalesRep"]} />
                <button
                  onClick={() => {
                    addUser();
                  }}
                  className="add_user_btn"
                >
                  Add User
                </button>
              </div>

              <div className="current_users">
                <h3 className="current_users_title">Current Users</h3>
                <div className="current_users_list">
                  {
                    commissionPlan?.users.length > 0 ?
                      commissionPlan?.users.map((user, index) => (
                        <div key={index} className="current_users_item">
                          <div className="info">
                            <div className="avatar">
                              <img
                                src={
                                  user?.user?.profileImage
                                    ? user?.user?.profileImage?.url
                                    : anonUser
                                }
                                alt=""
                              />
                            </div>
                            <div className="user_info">
                              <p className="user_name">
                                {user?.user?.firstName} {user?.user?.lastName}
                              </p>
                              <p className="user_email">{user?.user?.email}</p>
                            </div>
                          </div>
                          <div className="minus">
                            <MdOutlineDelete
                              className="cursor-pointer"
                              size={30}
                              onClick={() => {
                                removeUser(user.id);
                              }}
                              color="white"
                            />
                          </div>
                        </div>
                      ))
                      :
                      <p className="no_users">No Users Added</p>
                  }

                </div>
              </div>
            </div>





            <div className="details2">
              <div className="add_users">
                <h3 className="add_users_title">Add Fiber Plan</h3>

                <div className="fiber_plan_form">
                  <FiberPlanSelector fiberCompany={commissionPlan.fiberCompanyId} placeholder='Select a Fiber Plan' setUser={setFiberPl} />

                  {fiberPl != null && (
                    <>
                      <input
                        className="fiber_plan_input"
                        type='number'
                        placeholder='Level 1 Pay'
                        onChange={(e) => setLevel1Pay(Number(e.target.value))}
                      />
                      <input
                        className="fiber_plan_input"
                        type='number'
                        placeholder='Level 2 Pay'
                        onChange={(e) => setLevel2Pay(Number(e.target.value))}
                      />
                      <input
                        className="fiber_plan_input"
                        type='number'
                        placeholder='Level 2 Count'
                        onChange={(e) => setLevel2Count(Number(e.target.value))}
                      />
                      <input
                        className="fiber_plan_input"
                        type='number'
                        placeholder='Overrides'
                        onChange={(e) => setOverrides(Number(e.target.value))}
                      />
                    </>
                  )}

                  <button
                    onClick={() => {
                      addFiberCommission();
                    }}
                    className="add_user_btn"
                  >
                    Add Fiber plan
                  </button>

                </div>
                <div className="fiber_commision_plans_list">
                  {fiberCommissionPlans && fiberCommissionPlans.map(plan => (
                    <div key={plan.id} className="fiber_commission_plan">
                      <h2 className="fiber_plan_title">Fiber Commission Plan {plan.id}</h2>
                      <div className="fiber_plan_details" >
                        <h1>Fiber Plan Details</h1>
                        <div>
                          <p>Name: <span>{plan.fiberPlan.name}</span></p>
                          <p>Speed: <span>{plan.fiberPlan.speed}</span> Gbps</p>
                          <p className="single">Description: <span>{plan.fiberPlan.description}</span></p>
                          <p>Price: <span>${plan.fiberPlan.price}</span></p>
                        </div>
                      </div>
                      <div className="fiber_plan_details">
                        <h1>Deal Saved</h1>
                        <div>
                          <p>Deal Saved Percentage: <span>{commissionPlan.dealSavedPercentage}%</span></p>
                          <p>Deal Saved Pay: <span>${commissionPlan.dealSavedPay}</span></p>
                        </div>
                      </div>
                      <div className="fiber_plan_details" >
                        <h1>Commission Details</h1>
                        {editPlanId === plan.id ? (
                          <div>
                            <input
                              type='number'
                              name='level1Pay'
                              value={editValues.level1Pay}
                              onChange={handleEditChange}
                            />
                            <input
                              type='number'
                              name='level2Pay'
                              value={editValues.level2Pay}
                              onChange={handleEditChange}
                            />
                            <input
                              type='number'
                              name='level2Count'
                              value={editValues.level2Count}
                              onChange={handleEditChange}
                            />
                            <input
                              type='number'
                              name='overrides'
                              value={editValues.overrides}
                              onChange={handleEditChange}
                            />

                          </div>
                        ) : (
                          <div>
                            <p>Level 1 Pay: <span>${plan.level1Pay}</span></p>
                            <p>Level 2 Pay: <span>${plan.level2Pay}</span></p>
                            <p>Level 2 Count: <span>{plan.level2Count}</span></p>
                            <p>Overrides: <span>${plan.overrides}</span></p>

                          </div>
                        )}
                        <div className="buttons">
                          <button onClick={() => toggleEdit(plan)}>
                            {editPlanId === plan.id ? 'Cancel' : 'Edit'}
                          </button>
                          {
                            editPlanId === plan.id &&
                            <button onClick={() => saveEdits(plan.id)}>Save</button>
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </div>



          </>
        )}
      </div>
    </Layout>
  );
};

export default Index;
