import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";


class SellingCardApi{
    baseUrl = EnvSettings.url;
    CreateSellingCardUrl = "CreateSellingCard"
    SearchSellingCardUrl = "SearchSellingCards"
    DeleteSellingCardUrl = "DeleteSellingCard"  
    EditSellingCardUrl = "EditSellingCard"  
    RemoveFileUrl = "DeleteCloudFile"
    AddFileUrl = "AddFilesToSellingCard"
    CreateSellingCardBlockUrl = "CreateSellingCardBlock"
    GetSellingCardUrl = "GetSellingCard"
    DeleteSellingCardBlockUrl = "DeleteSellingCardBlock"
    EditSellingCardBlockUrl = "EditSellingCardBlock"
    ChangeSellingCardBlockNumbersUrl = "ChangeSellingCardBlockNumbers"
    AddSalesOrgsToSellingCardUrl = "AddSalesOrgsToSellingCard"
    RemoveSalesOrgFromSellingCardUrl = "RemoveSalesOrgFromSellingCard"
    AssignAllSalesOrgsToSellingCardUrl = "AssignAllSalesOrgsToSellingCard"
    DeleteAllSalesOrgsFromSellingCardUrl = "DeleteAllSalesOrgsFromSellingCard"
    async RemoveSalesOrgFromSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveSalesOrgFromSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddSalesOrgsToSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.AddSalesOrgsToSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async ChangeSellingCardBlockNumbers(req)
    {
        const res = await fetch(this.baseUrl + this.ChangeSellingCardBlockNumbersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.CreateSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async SearchSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.SearchSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async DeleteSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.EditSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveFile(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveFileUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddFile(req)
    {
        const res = await fetch(this.baseUrl + this.AddFileUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateSellingCardBlock(req)
    {
        const res = await fetch(this.baseUrl + this.CreateSellingCardBlockUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteSellingCardBlock(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteSellingCardBlockUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditSellingCardBlock(req)
    {
        const res = await fetch(this.baseUrl + this.EditSellingCardBlockUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.GetSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //DeleteAllSalesOrgsFromSellingCard
    async DeleteAllSalesOrgsFromSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteAllSalesOrgsFromSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //AssignAllSalesOrgsToSellingCard
    async AssignAllSalesOrgsToSellingCard(req)
    {
        const res = await fetch(this.baseUrl + this.AssignAllSalesOrgsToSellingCardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    
}

export default new SellingCardApi;