/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import UsersApi from '../../../API/UsersApi';
import { UserContext } from '../../../App';
// import UpdateReferralCodeStatusReq from '../../../Requests/ReferralCode/UpdateReferralCodeStatusReq';
import { AffiliateCodeStatuses } from './AffiliateCodeStatuses';
import Layout from '../../../Layouts/Layout';
import userGrey from './../../../assets/images/userGrey.png'
import './ReferralCodeStatuses.scss'
import Swal from 'sweetalert2';
import StringIntReq from '../../../Requests/StringIntReq';
import Pagination from '@mui/material/Pagination';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { PaginationItem } from '@mui/material';

const ReferralCodeStatuses = () => {
    const [affiliateStatuses, setAffiliateStatuses] = useState();
    const [loaded, setLoaded] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const user = useContext(UserContext)
    useEffect(
        () => {
            fetchData()
        }, [currentPage])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (affiliateStatuses) {
            setLoaded(true)
        }
        else {
            setLoaded(false)
        }
    }, [affiliateStatuses])





    async function fetchData() {
        let req = new StringIntReq();
        req.int = user.id;
        req.pagingParams.pageSize = 20;
        req.pagingParams.pageNumber = currentPage;

        let response = await UsersApi.GetUserAffiliateStatuses(req);
        console.log(response)
        if (response.status.success) {
            setAffiliateStatuses(response.data.list)
            setTotalPages(response.data.totalPages)

        }
        else {
            console.log(response.status.message)
        }
    }


    // const changeReferralCodeStatus = async (ReferralCodeId, NewStatus) => {
    //     let req = new UpdateReferralCodeStatusReq();
    //     req.newStatus = NewStatus;
    //     req.referralCodeId = ReferralCodeId;
    //     console.log(req)
    //     let response = await UsersApi.ChangeReferralCodeStatus(req);

    //     if (response.status.success) {
    //         fetchData();
    //     }
    //     else {
    //         console.log(response.status.message)
    //     }
    // }

    const RequestReward = async (ReferralCodeId) => {
        Swal.fire({
            icon: 'info',
            title: 'Requesting Reward',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        let req = new StringIntReq();
        req.int = ReferralCodeId;

        let response = await UsersApi.RequestReward(req);

        if (response.status.success) {
            fetchData();

            Swal.fire({
                icon: 'success',
                title: 'Reward Sent Successfully',
                text: 'Check your phone for an sms containting the reward',
                showConfirmButton: true,
                allowOutsideClick: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: response.status.message,
                showConfirmButton: true,
                allowOutsideClick: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    const handleStatus = (status, id) => {
        switch (status) {
            case AffiliateCodeStatuses.SignedUpbutNotInstalledyet:
                return <p>Signed Up but Not Installed yet</p>
            case AffiliateCodeStatuses.InstalledWaitingCustomerRequest:
                return <>
                    <p>Installed Waiting your request</p>
                    <button onClick={() => RequestReward(id)}
                        className='py-2 px-4 rounded-lg bg-red-500 hover:bg-red-700 text-white'>request</button>
                </>
            case AffiliateCodeStatuses.RequestedByCustomer:
                return <p>There was a previous request that didnt go through. Contact customer service.</p>
            case AffiliateCodeStatuses.ReceivedByCustomerRewardSent:
                return <p>Reward Sent</p>
            case AffiliateCodeStatuses.ErrorDuringSendingReward:
                return <p>Error During Sending Reward</p>
            case AffiliateCodeStatuses.CancelledInstallation:
                return <p>Cancelled Installation</p>

            case AffiliateCodeStatuses.RejectedByAdmin:
                return <p>This reward has been rejected</p>
            default:
                return <p>Unknown Status</p>
        }
    }

    return (

        <Layout>
            {/* <div className='Statistics'>
                <h1>Referral Code Statuses</h1>
            </div> */}

            {
                !loaded ?
                    <div className='spinner'>
                        <CircularProgress sx={{
                            color: "#181E4B"
                        }} />
                    </div>
                    :
                    <div className='my-referrals-container'>

                        <h1>My Referrals</h1>
                        <div className='my-referrals'>
                            {
                                affiliateStatuses.map((item, index) => {
                                    return <div className='referral-card' key={item}>
                                        <div className='header'>
                                            <div className='image'>
                                                <img src={item.affiliatedUser?.profilePicture?.url ? item.affiliatedUser?.profilePicture?.url : userGrey} alt="" />
                                            </div>
                                            <div className='info'>
                                                <h1>{item.affiliatedUser.firstName + " " + item.affiliatedUser.lastName}</h1>
                                                <p>{item.affiliatedUser.email}</p>
                                            </div>
                                        </div>
                                        <div className='status'>
                                            {handleStatus(item.status, item.id)}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }} className='pagination'>
                            <Pagination color='light' renderItem={(item) => (
                                <PaginationItem components={{

                                    next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                    first: (props) => <button {...props}>First</button>,
                                    previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                }}  {...item} sx={{ color: "white" }} />
                            )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                        </div>
                    </div>

            }
        </Layout>
    );
};

export default ReferralCodeStatuses;
