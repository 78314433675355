/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Layout from "./../../../Layouts/Layout"
import "./Chat.scss"
import { ConversationHeader } from "./Components/ConversationHeader";
import ConversationBody from "./Components/ConversationBody";
import ChatSender from "./Components/ChatSender";
import { useLocation } from "react-router-dom";
import { ClickAwayListener } from "@mui/material";
import { UserContext } from "../../../App";
import { MdModeEdit } from "react-icons/md";
import StringIntReq from "../../../Requests/StringIntReq";
import MessagingApi from "../../../API/MessagingApi";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import UpdateConversationImageReq from "../../../Requests/Messaging/UpdateConversationImageReq";
import CreateFile from "../../../Requests/CloudFile/CreateFileReq";
import Swal from "sweetalert2";
import anonUser from "./../../../assets/images/userGrey.png";
import { getStorage } from "firebase/storage";
import FavouriteMessageReq from "../../../Requests/Messaging/FavouriteMessageReq";

const ChatNew = () => {

    const [messages, setMessages] = useState([]);
    const [pinnedMessages, setPinnedMessages] = useState([])
    const [editMessageId, setEditMesageId] = useState(null);
    const [openEditImage, setOpenEditImage] = useState(false);
    const [selectedTab, setSelectedTab] = useState("Media");
    const [cloudFiles, setCloudFiles] = useState([]);
    const [conversationLinks, setConversationLinks] = useState([])
    const location = useLocation();
    const user = useContext(UserContext);
    const conversation = location.state.conversation;
    const storage = getStorage();
    // const [expandImage, setExpandImage] = useState(false);
    // const [file, setFile] = useState(null);
    const [favouriteMessages, setFavouriteMessages] = useState([]);
    const [adminFavouriteMessages, setAdminFavouriteMessages] = useState([]);

    const getConversationLinks = async () => {
        const req = new StringIntReq();
        req.int = conversation.id;
        const res = await MessagingApi.GetConversationLinks(req);
        if (res?.status?.success) {
            setConversationLinks(res.data)
            console.log(res.data)
        }
        else {
            console.log(res?.status?.message)
        }
    }

    const getPinnedMessages = async () => {
        const req = new StringIntReq()
        req.int = conversation.id
        const res = await MessagingApi.GetConversationPinnedMessages(req)
        if (res?.status?.success) {
            setPinnedMessages(res.data)
        }
    }

    const getCloudFiles = async () => {
        const req = new StringIntReq();
        req.int = conversation.id;
        const res = await MessagingApi.GetConversationCloudFiles(req);
        if (res?.status?.success) {
            setCloudFiles(res.data)
        }
        else {
            console.log(res?.status?.message)
        }
    }

    // const expandedImage = (file) => {
    //     return <div style={{
    //         position: "fixed",
    //         top: "0",
    //         left: "0",
    //         width: "80%",
    //         height: "80%",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         backgroundColor: "rgba(0,0,0,0.5)",
    //         zIndex: "100"

    //     }} onClick={() => {
    //         setExpandImage(false)
    //     }} >
    //         <img src={file.url} />
    //     </div>
    // }

    const getConvImage = () => {
        console.log(conversation)
        if (conversation.conversationType === 'Private' || conversation.conversationType === 'CustomerService') {
            let otherUser = conversation.conversationUsers.filter((item) => item.userId !== user.id)
            console.log(otherUser)
            if (otherUser[0]?.user?.profileImage !== null) {
                return <img src={otherUser[0]?.user?.profileImage?.url} alt="avatar" />
            }
            else {
                return <img src={anonUser} alt="avatar" />
            }
        }
        else {
            if (conversation.image) {
                return <img src={conversation.image.url} alt="avatar" />
            }
        }
        return <img src={anonUser} alt="avatar" />;
    }


    useEffect(() => {
        getCloudFiles()
        getConversationLinks()
        getUserFavouriteMessages()
        if (conversation.conversationType !== "Private" && conversation.conversationType !== "CustomerService") {
            getAdminFavouriteMessages()
        }
    }, [location.state])

    const handleImageChange = async (e) => {

        Swal.fire({
            icon: 'info',
            title: 'Uploading Image',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
            toast: true,
            position: 'bottom-end',
        });

        const file = e.target.files[0];
        const downloadUrls = [];
        const filePaths = [];
        const uploadAndGetData = async (file, type) => {
            // Generate a unique ID 
            const uniqueId = uuidv4();

            const storageRef = ref(storage, `/ConversationsProfile/${conversation.name}/${user.firebaseId}/${uniqueId}/${file.name}`);
            await uploadBytes(storageRef, file);
            // Get download URL and push to downloadURLs array
            const downloadURL = await getDownloadURL(storageRef);
            downloadUrls.push(downloadURL);
            console.log(downloadURL);
            filePaths.push(`/ConversationsProfile/${conversation.name}/${user.firebaseId}/${uniqueId}/${file.name}`)
        };
        await uploadAndGetData(file, 'image');
        console.log(downloadUrls)
        const req = new UpdateConversationImageReq();
        req.conversationId = conversation.id;
        const image = new CreateFile();
        image.name = file.name;
        image.extension = image.name.split('.').pop();
        image.url = downloadUrls[0];
        image.path = filePaths[0];
        image.fileSize = file.size.toString();
        image.userId = user.id;
        image.sellingCardId = 1;
        image.caption = "Conversation Image";
        image.fileType = file.type
        req.image = image;
        console.log(req)
        const res = await MessagingApi.UpdateConversationImage(req);
        if (res?.status?.success) {
            setOpenEditImage(false)
            Swal.fire({
                icon: 'success',
                title: 'Image Updated',
                text: 'Image has been updated successfully',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                toast: true,
                position: 'bottom-end',
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                toast: true,
                position: 'bottom-end',
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        }

    }

    const getIcon = (file) => {
        if (file.fileType.includes('image')) {
            return <img onClick={() => {
                // setExpandImage(true)
                // setFile(file)
            }} src={file.url} alt="file" width={300} />
        }
        else if (file.fileType.includes('video')) {
            return <video controls src={file.url + "#t=0.001"} ></video>
        }
        else {
            return <div style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
                gap: "0.5rem",
                border: "1px solid #ccc",
                padding: "0.5rem",
                borderRadius: "10px",
                cursor: "pointer"
            }} onClick={() => {
                downloadFile(file)
            }}>
                <span className={`fiv-cla fiv-icon-${file.extension}`} ></span>
                <p style={{
                    // textAlign: "center",
                    fontSize: "0.8rem",
                    color: "blue",
                    marginTop: "0.5rem"
                }} >{file.name}</p>
            </div>
        }
    }

    const downloadFile = async (file) => {
        try {

            const link = document.createElement('a');
            link.href = file.url;
            link.target = "_blank";
            link.download = file.name; // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error downloading file:', error.message);
        }
    };

    const getUserFavouriteMessages = async () => {
        const req = new FavouriteMessageReq();
        req.id1 = user?.id;
        req.id2 = conversation.id;
        const res = await MessagingApi.GetUserFavouriteMessages(req);
        if (res?.status?.success) {
            setFavouriteMessages(res.data)
            console.log(res.data)
        }
        else {
            console.log(res?.status?.message)
        }
    }

    const getAdminFavouriteMessages = async () => {
        const req = new FavouriteMessageReq();
        req.id1 = conversation?.creatorId;
        req.id2 = conversation.id;
        const res = await MessagingApi.GetUserFavouriteMessages(req);
        if (res?.status?.success) {
            setAdminFavouriteMessages(res.data)
            console.log(res.data)
        }
        else {
            console.log(res?.status?.message)
        }
    }

    return <Layout>
        <div className="chat_container">
            {/* {expandImage && expandedImage(file)} */}
            {
                openEditImage && <ClickAwayListener onClickAway={() => {
                    setOpenEditImage(false)
                }}>
                    <div className='chat_conversation_header_edit_image'>
                        <div className='current_image'>
                            {getConvImage()}
                            {user.id === conversation.creatorId && (conversation.conversationType === "SalesOrg" || conversation.conversationType === "Group" || conversation.conversationType === "Global") && <label htmlFor='new_image' className='edit_button'>
                                <MdModeEdit size={30} color='#fff' />
                            </label>}
                        </div>
                        <input onChange={(event) => {
                            handleImageChange(event)
                        }} type='file' style={{
                            display: 'none'
                        }} id='new_image' />
                        <div className='conversation_files' >
                            <div className='tabs'>
                                <div onClick={() => { setSelectedTab("Media") }} className={`tab ${selectedTab === "Media" && "active"}`}>Media</div>
                                <div onClick={() => { setSelectedTab("Files") }} className={`tab ${selectedTab === "Files" && "active"}`}>Files</div>
                                <div onClick={() => { setSelectedTab("Links") }} className={`tab ${selectedTab === "Links" && "active"}`}>Links</div>
                                <div onClick={() => { setSelectedTab('YFavourite') }} className={`tab ${selectedTab === "YFavourite" && "active"}`} >Your Favourite</div>
                                {conversation.conversationType !== "Private" && conversation.conversationType !== "CustomerService" && <div onClick={() => { setSelectedTab('AFavourite') }} className={`tab ${selectedTab === "AFavourite" && "active"}`}>Admin's Favourite Messages</div>}

                            </div>
                            <div style={{
                                display: selectedTab === "Media" ? "flex" : "grid",
                                flexWrap: "wrap",
                                gap: "1rem",
                            }} className='list'>
                                {
                                    selectedTab !== "Links" &&
                                    cloudFiles.map((item, index) => {

                                        if (selectedTab === "Media" && (item.fileType.includes('image') || item.fileType.includes('video')))
                                            return <div key={index} className='file'>
                                                <div className='file_icon'>
                                                    {getIcon(item)}

                                                </div>
                                                {/* <p>{item.name}</p> */}
                                            </div>
                                        else if (selectedTab === "Files" && !(item.fileType.includes('image') || item.fileType.includes('video'))) {
                                            return <div key={index} className='file'>
                                                <div className='file_icon'>
                                                    {getIcon(item)}
                                                </div>
                                                {/* <p>{item.name}</p> */}
                                            </div>
                                        }

                                    }
                                    )
                                }
                                {
                                    selectedTab === "Links" &&
                                    conversationLinks.map((item, index) => {
                                        console.log(item)
                                        return <div style={{
                                            border: "1px solid #ccc",
                                            padding: "0.5rem",
                                            borderRadius: "10px"
                                        }} key={index} className='file'>
                                            <p>{
                                                item.content
                                            }</p>
                                        </div>
                                    }
                                    )
                                }
                                {
                                    selectedTab === "YFavourite" &&
                                    favouriteMessages.map((item, index) => {
                                        if (item.senderId === user.id)
                                            return <div style={item.content !== '' ? {
                                                border: "1px solid #ccc",
                                                padding: "0.5rem",
                                                borderRadius: "10px"
                                            } : {}} key={index} className='file'>
                                                <p>{
                                                    item.content
                                                }</p>
                                                {
                                                    item.files.length > 0 &&
                                                    getIcon(item.files[0])
                                                }
                                                {/* <p>{item.files.length>0 && !item.files[0].fileType.includes('image') && item.files[0].name }</p> */}
                                            </div>
                                    }
                                    )
                                }
                                {
                                    conversation.conversationType !== "Private" &&
                                    conversation.conversationType !== "CustomerService" &&
                                    selectedTab === "AFavourite" &&
                                    adminFavouriteMessages.map((item, index) => {
                                        if (item.senderId !== user.id)
                                            return <div style={{
                                                border: "1px solid #ccc",
                                                padding: "0.5rem",
                                                borderRadius: "10px"
                                            }} key={index} className='file'>
                                                <p>{
                                                    item.content
                                                }</p>
                                            </div>
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            }
            <div className='chat_conversation_container'>
                <ConversationHeader getIcon={getIcon} getPinnedMessages={getPinnedMessages} pinnedMessages={pinnedMessages} setOpenEditImage={setOpenEditImage} getConvImage={getConvImage} conversation={conversation} />
                <ConversationBody getPinnedMessages={getPinnedMessages} pinnedMessages={pinnedMessages} getUserFavouriteMessages={getUserFavouriteMessages} setEditMesageId={setEditMesageId} conversation={conversation} messages={messages} setMessages={setMessages} />
                {
                    (conversation.conversationType !== "Global" || user.userType === "SuperAdmin") && 
                    <ChatSender getCloudFiles={getCloudFiles} getConversationLinks={getConversationLinks} editMessageId={editMessageId} setEditMesageId={setEditMesageId} conversation={conversation} messages={messages} setMessages={setMessages} />
                }
            </div>
        </div>
    </Layout>;
}
export default ChatNew;
