import React, { useContext, useEffect, useState } from 'react';
// import Sidebar from '../../../Layouts/Sidebar';

import Swal from 'sweetalert2';
import UsersApi from '../../../API/UsersApi';
import { UserContext } from '../../../App';
import UpdateReferralCodeReq from '../../../Requests/ReferralCode/UpdateReferralCodeReq';
import Layout from '../../../Layouts/Layout';

const ChangeReferralCode = () => {
    const [referralCode, setReferralCode] = useState('');
    const user = useContext(UserContext);
    useEffect(() => {
        setReferralCode(user.affiliateCode.code);
    }, [user.affiliateCode.code]);


    const updateReferralCode = () => {

        if (referralCode.length < 6) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Referral code must be at least 6 characters long'
            });
            return;
        }
        if (referralCode.length > 200) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Referral code must be at most 200 characters long'
            });
            return;
        }
        if (referralCode.includes(' ')) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Referral code cannot contain spaces'
            });
            return;
        }

        var req = new UpdateReferralCodeReq();
        req.customerId = user.id;
        req.referralCode = referralCode;
        console.log(req);
        UsersApi.UpdateReferralCode(req).then((res) => {
            console.log(res);
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Referral code updated successfully'
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message
                });
            }

        });

    }

    const handleInputChange = (event) => {
        if (event.target.value.includes(' ')) {
            setReferralCode(event.target.value.replace(/\s/g, ''));
        } else {
            setReferralCode(event.target.value);
        }
    };

    return (

        <Layout>
            <div className='updateReferralCode'>
                <label htmlFor="referralCodeInput">Referral Code</label>
                <input
                    type="text"
                    id="referralCodeInput"
                    value={referralCode}
                    onChange={handleInputChange}
                />
                <button onClick={() => updateReferralCode()}>Update Referral Code</button>
            </div>
        </Layout >

    );
};

export default ChangeReferralCode;
