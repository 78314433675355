import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class MapIconApi {
    baseUrl = EnvSettings.url;
    CreateMapIconUrl = "CreateMapIcon";
    SearchMapIconUrl = "SearchMapIcons";
    GetAllMapIconsUrl = "GetAllMapIcons";
    GetSalesOrgMapIconsUrl = "GetSalesOrgMapIcons";
    EditMapIconUrl = "EditMapIcon";
    DeleteMapIconUrl = "DeleteMapIcon";
    SearchActivityHistoriesUrl = "SearchActivityHistories";
    GetLeadActivityHistoryUrl = "GetLeadActivityHistory";
    GetCandidateActivityHistoryUrl = "GetCandidateActivityHistory";
    CreateLeadStatusUrl = "CreateLeadStatus";
    SearchLeadStatusesUrl = "SearchLeadStatuses";
    EditLeadStatusUrl = "EditLeadStatus";
    DeleteLeadStatusUrl = "DeleteLeadStatus";
    SearchLeadsForContactCenterUrl = "SearchLeadsForContactCenter";
    AddTagToMapIconUrl = "AddTagToMapIcon";
    RemoveTagFromMapIconUrl = "RemoveTagFromMapIcon";
    GetAllTagsUrl = "GetAllTags";
    BulkChangeFiberHouseMapIconsUrl = "BulkChangeFiberHouseMapIcons";
    mapSearchPlacesUrl = "GetPlaces"
    mapIdDetailsUrl = "GetPlaceDetails"
    SearchLeadsForContactCenterExportUrl = "SearchLeadsForContactCenterExport";

    async SearchLeadsForContactCenterExport(req) {
        const res = await fetch(this.baseUrl + this.SearchLeadsForContactCenterExportUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
            const data = await res.blob();
            return data;
    }

    async SearchMapPlacesUrl(input) {
        const res = await fetch(this.baseUrl + this.mapSearchPlacesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(input)
        })
        const data = await res.json();
        return data;
    }

    async GetMapIdDetails(id) {
        const res = await fetch(this.baseUrl + this.mapIdDetailsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(id)
        })
        const data = await res.json();
        return data;
    }

    async BulkChangeFiberHouseMapIcons(req) {
        const res = await fetch(this.baseUrl + this.BulkChangeFiberHouseMapIconsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateMapIcon(req) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(req)) {
            formData.append(key, value);
        }
        const res = await fetch(this.baseUrl + this.CreateMapIconUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            },
            body: formData
        })
        const data = await res.json();
        return data;
    }

    async SearchMapIcon(req) {
        const res = await fetch(this.baseUrl + this.SearchMapIconUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetAllMapIcons() {
        const res = await fetch(this.baseUrl + this.GetAllMapIconsUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            }
        })
        const data = await res.json();
        return data;
    }

    async GetSalesOrgMapIcons(req) {
        const res = await fetch(this.baseUrl + this.GetSalesOrgMapIconsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditMapIcon(req) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(req)) {
            formData.append(key, value);
        }
        const res = await fetch(this.baseUrl + this.EditMapIconUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            },
            body: formData
        })
        const data = await res.json();
        return data;
    }

    async DeleteMapIcon(req) {
        const res = await fetch(this.baseUrl + this.DeleteMapIconUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchActivityHistories(req) {
        const res = await fetch(this.baseUrl + this.SearchActivityHistoriesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetLeadActivityHistory(req) {
        const res = await fetch(this.baseUrl + this.GetLeadActivityHistoryUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateLeadStatus(req) {
        const res = await fetch(this.baseUrl + this.CreateLeadStatusUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchLeadStatuses(req) {
        const res = await fetch(this.baseUrl + this.SearchLeadStatusesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditLeadStatus(req) {
        const res = await fetch(this.baseUrl + this.EditLeadStatusUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteLeadStatus(req) {
        const res = await fetch(this.baseUrl + this.DeleteLeadStatusUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchLeadsForContactCenter(req) {
        const res = await fetch(this.baseUrl + this.SearchLeadsForContactCenterUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
            const data = await res.json();
            return data;
    }

    async AddTagToMapIcon(req) {
        const res = await fetch(this.baseUrl + this.AddTagToMapIconUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveTagFromMapIcon(req) {
        const res = await fetch(this.baseUrl + this.RemoveTagFromMapIconUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetAllTags() {
        const res = await fetch(this.baseUrl + this.GetAllTagsUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            }
        })
        const data = await res.json();
        return data;
    }

    async GetCandidateActivityHistory(req) {
        const res = await fetch(this.baseUrl + this.GetCandidateActivityHistoryUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

}

export default new MapIconApi();