import React from 'react'

const WelcomeSummer = ({setOpen}) => {
    return (
        <>
            <div className='welcome_summer'>
                <img className='left' src='/images/summer/sidewave.png' alt='sidewave' />
                <img className='right' src='/images/summer/sidewave.png' alt='sidewave' />
                <h1>Welcome to the Lightning OS <br />Summer Sales Program</h1>
                <p>a thrilling opportunity designed for ambitious individuals ready to skyrocket their <br />careers in sales! Whether you're a fresh graduate or an aspiring sales superstar, <br />this is your chance to shine and make this summer unforgettable.</p>

            </div>
            <div className='large_image'>
                <div className='con'> 
                    <img className='large' src='/images/summer/donut_girl.png' alt='summer_sales' />
                    <img className='left' src='/images/summer/left_pine.png' alt='left_pineapple' />
                    <img className='right' src='/images/summer/right_pine.png' alt='right_pineapple' />
                </div>
                <button onClick={()=>{
                    setOpen(true);
                }}>Apply Now</button>
            </div>
        </>
    )
}

export default WelcomeSummer