import React from 'react'
import { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import MobileLayout from './MobileLayout';
const Layout =
  ({ children }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
        // console.log(screenWidth, screenHeight)
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
  
  
    return (

      screenWidth > 1000 ?
        <Sidebar>
          {children}
        </Sidebar>
        :
        <MobileLayout>
          {children}
        </MobileLayout>

    )
  }


export default Layout