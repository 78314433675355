import React, { useState } from 'react'
import Swal from 'sweetalert2'
import ComissionApi from '../../../API/ComissionApi'
import Layout from '../../../Layouts/Layout'
import CreateComissionPlanReq from '../../../Requests/ComissionPlan/CreateComissionPlanReq'
import FiberCompanySelector from '../../../components/FiberCompanySelector'

import "./CreateCommissionPlan.scss"
const Index = () => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [dealSavedPercentage, setDealSavedPercentage] = useState('')
    const [dealSavedPay, setDealSavedPay] = useState('') 
    const [fiberCompanyId, setFiberCompanyId] = useState('') 

     
    const createComissionPlan = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Creating Comission Plan',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if(!name){
            Swal.fire({
                icon: 'error',
                title: 'Name is required',
                text: 'Please enter name',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if(!description){
            Swal.fire({
                icon: 'error',
                title: 'Description is required',
                text: 'Please enter description',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

       

        if(!dealSavedPercentage){
            Swal.fire({
                icon: 'error',
                title: 'Deal Saved Percentage is required',
                text: 'Please enter deal saved percentage',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if(!fiberCompanyId){
            Swal.fire({
                icon: 'error',
                title: 'fiber Company is required',
                text: 'Please select fiber Company',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        // check if deal saved percentage is number
        if(isNaN(dealSavedPercentage)){
            Swal.fire({
                icon: 'error',
                title: 'Deal Saved Percentage must be a number',
                text: 'Please enter a number for deal saved percentage',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if(!dealSavedPay){
            Swal.fire({
                icon: 'error',
                title: 'Deal Saved Pay is required',
                text: 'Please enter deal saved pay',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        // check if deal saved pay is number
        if(isNaN(dealSavedPay)){
            Swal.fire({
                icon: 'error',
                title: 'Deal Saved Pay must be a number',
                text: 'Please enter a number for deal saved pay',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        const req = new CreateComissionPlanReq();
        req.name = name;
        req.description = description;
        req.dealSavedPercentage = dealSavedPercentage;
        req.dealSavedPay = dealSavedPay;
        req.fiberCompanyId = fiberCompanyId;

        const res = await ComissionApi.CreateCommissionPlan(req);

        if(res?.status?.success){
            Swal.fire({
                icon: 'success',
                title: 'Comission Plan Created',
                text: 'Comission Plan has been created successfully',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Failed to Create Comission Plan',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });

        }


    }

    return (
        <Layout>
            <div className='create_comission_plan'>
                <div className='form' >
                    <h1>Create Comission Plan</h1>
                    <input type='text' placeholder='Comission Plan Name' value={name} onChange={(e)=>{setName(e.target.value)}} />
                    <textarea rows={3} type='text' placeholder='Comission Plan Description' value={description} onChange={(e)=>{setDescription(e.target.value)}} />
                    <input type='number' placeholder='Deal Saved Percentage' value={dealSavedPercentage} onChange={(e)=>{setDealSavedPercentage(e.target.value)}} />
                    <input type='number' placeholder='Deal Saved Pay' value={dealSavedPay} onChange={(e)=>{setDealSavedPay(e.target.value)}} />
                    <FiberCompanySelector placeholder='Select Fiber Company' setUser={setFiberCompanyId}/>
                    <button onClick={()=>{createComissionPlan()}}>Create</button>
                </div>
            </div>
        </Layout>
    )
}

export default Index