
import { deleteObject, getStorage, ref } from "firebase/storage"
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import SellingCardApi from '../../../API/SellingCardApi'
import ShopItemsApi from '../../../API/ShopItemsApi'
import { UserContext } from '../../../App'
import RemoveFileReq from '../../../Requests/CloudFile/RemoveFileReq'
import DeleteSellingCardBlockReq from '../../../Requests/SellingCard/DeleteSellingCardBlockReq'
import GetSellingCardReq from '../../../Requests/SellingCard/GetSellingCardReq'

import StepCard from './component/StepCard'
import Steps from './component/Steps'
import './index.scss'
import Layout from '../../../Layouts/Layout'
import { CircularProgress } from "@mui/material"
import { IoMdArrowDropdownCircle, IoMdArrowDropleftCircle, IoMdArrowDroprightCircle, IoMdArrowDropupCircle, IoMdArrowRoundBack, IoMdCloseCircle } from "react-icons/io"
import Modal from '../../../components/Modal'
import OrganizationSelector from '../../../components/OrganizationSelector'
import StringIntReq from "../../../Requests/StringIntReq"
import { useSwipeable } from "react-swipeable"
const Index = () => {
    const [list, setList] = useState([])
    const user = useContext(UserContext)
    const [cardName, setCardName] = useState('')
    const storage = getStorage();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const { trainingModuleId, trainingModuleName, index } = useParams()
    console.log(trainingModuleId)
    const [currentStep, setCurrentStep] = useState(Number(index))
    const [selectedBlock, setSelectedBlock] = useState(null)
    const [steps, setSteps] = useState([])
    const [card, setCard] = useState(null)
    const [organizationIds, setOrganizationIds] = useState([])
    const [update, setUpdate] = useState(false)
    const navigate = useNavigate();


    const swipeable = useSwipeable({
        onSwipedLeft: () => {
            setCurrentStep((prevStep) => {
                if (prevStep === -1) {
                    return steps.length > 0 ? steps[0].blockNumber : -1;
                } else {
                    const currentIndex = steps.findIndex(step => step.blockNumber === prevStep);
                    if (currentIndex < steps.length - 1) {
                        return steps[currentIndex + 1].blockNumber;
                    } else {
                        return -1;
                    }
                }
            });
        },
        onSwipedRight: () => {
            setCurrentStep((prevStep) => {
                if (prevStep === -1) {
                    return steps.length > 0 ? steps[steps.length - 1].blockNumber : -1;
                } else {
                    const currentIndex = steps.findIndex(step => step.blockNumber === prevStep);
                    if (currentIndex > 0) {
                        return steps[currentIndex - 1].blockNumber;
                    } else {
                        return steps.length > 0 ? steps[steps.length - 1].blockNumber : -1;
                    }
                }
            });
        }
    });

    const refPassthrough = (el) => {
        swipeable.ref(el);
    };

    // const updateVisibility = async () => {

    //     if(!organizationIds.length){
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: 'Please select at least one organization',
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: true,
    //             customClass: {
    //                 container: 'custom-swal'
    //             },
    //             didOpen: () => {
    //                 Swal.hideLoading()
    //             }
    //         });
    //         return;
    //     }
    //     Swal.fire({
    //         icon: 'info',
    //         title: 'Updating Visibility',
    //         text: 'Please wait...',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         showConfirmButton: false,
    //         customClass: {
    //             container: 'custom-swal'
    //         },
    //         didOpen: () => {
    //             Swal.showLoading()
    //         }
    //     });


    //     const req = {
    //         sellingCardId: trainingModuleId,
    //         salesOrgIds: [...organizationIds]
    //     }
    //     console.log(req)
    //     const res = await SellingCardApi.AddSalesOrgsToSellingCard(req)
    //     if (res?.status?.success) {
    //         searchTutorial(false)
    //         setOpen(false)
    //         Swal.fire(
    //             {
    //                 title: "Visibility Updated",
    //                 text: "Visibility Updated Successfully",
    //                 icon: "success",
    //                 customClass: {
    //                     container: 'custom-swal'
    //                 },
    //                 didOpen: () => {
    //                     Swal.hideLoading()
    //                 }
    //             }
    //         );
    //     }
    //     else {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: res?.status?.message,
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: true,
    //             customClass: {
    //                 container: 'custom-swal'
    //             },
    //             didOpen: () => {
    //                 Swal.hideLoading()
    //             }
    //         });
    //     }
    // }


    // const updateAllVisibility = async () => {
    //     Swal.fire({
    //         icon: 'info',
    //         title: 'Updating Visibility',
    //         text: 'Please wait...',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         showConfirmButton: false,
    //         customClass: {
    //             container: 'custom-swal'
    //         },
    //         didOpen: () => {
    //             Swal.showLoading()
    //         }
    //     });

    //     const req = {
    //         sellingCardId: trainingModuleId,
    //         salesOrgIds: [],
    //         updateAll: true
    //     }

    //     const res = await SellingCardApi.AddSalesOrgsToSellingCard(req)
    //     if (res?.status?.success) {
    //         searchTutorial(false)
    //         setOpen(false)
    //         Swal.fire(
    //             {
    //                 title: "Visibility Updated",
    //                 text: "Visibility Updated Successfully",
    //                 icon: "success",
    //                 customClass: {
    //                     container: 'custom-swal'
    //                 },
    //                 didOpen: () => {
    //                     Swal.hideLoading()
    //                 }
    //             }
    //         );
    //     }
    //     else {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: res?.status?.message,
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: true,
    //             customClass: {
    //                 container: 'custom-swal'
    //             },
    //             didOpen: () => {
    //                 Swal.hideLoading()
    //             }
    //         });
    //     }
    // }


    // const removeSalesOrg = async (id) => {
    //     Swal.fire({
    //         icon: 'info',
    //         title: 'Deleting Sales Org',
    //         text: 'Please wait...',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         showConfirmButton: false,
    //         customClass: {
    //             container: 'custom-swal'
    //         },
    //         didOpen: () => {
    //             Swal.showLoading()
    //         }
    //     });

    //     const req = new StringIntReq();
    //     req.int = id;

    //     const res = await SellingCardApi.RemoveSalesOrgFromSellingCard(req)
    //     if (res?.status?.success) {
    //         searchTutorial(false)
    //         setOpen(false)
    //         Swal.fire(
    //             {
    //                 title: "Sales Org Removed",
    //                 text: "Sales Org Removed Successfully",
    //                 icon: "success",
    //                 customClass: {
    //                     container: 'custom-swal'
    //                 },
    //                 didOpen: () => {
    //                     Swal.hideLoading()
    //                 }
    //             }
    //         );

    //     }
    //     else {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: res?.status?.message,
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: true,
    //             customClass: {
    //                 container: 'custom-swal'
    //             },
    //             didOpen: () => {
    //                 Swal.hideLoading()
    //             }
    //         });
    //     }
    // }


    const searchTutorial = (load = true) => {
        if (load) {
            setLoading(true)
        }
        const req = new GetSellingCardReq();

        req.int = trainingModuleId;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 10;
        SellingCardApi.GetSellingCard(req).then(
            (response) => {
                if (response.status.success) {

                    const sortedBlocks = response.data.blocks.sort((a, b) => a.blockNumber - b.blockNumber);
                    setList(sortedBlocks);
                    setSelectedBlock(sortedBlocks[Number(index)])
                    setCard(response.data)
                    console.log(sortedBlocks)
                    setLoading(false)
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.status.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        customClass: {
                            container: 'custom-swal'
                        },
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });

                }
            }
        );
    }

    useEffect(
        () => {
            console.log(organizationIds)
        }, [organizationIds]
    )

    useEffect(() => {
        setCardName(trainingModuleName)
        searchTutorial()
    }, [index])

    const removeStep = (id) => {

        Swal.fire({
            icon: 'info',
            title: 'Deleting Step',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        const req = new DeleteSellingCardBlockReq();
        req.int = id;
        const deleteFiles = async (f) => {
            const fileRef = ref(storage, f.path);
            try {
                await deleteObject(fileRef);
                const req = new RemoveFileReq();
                req.int = f.id;
                const res = await ShopItemsApi.RemoveFile(req);
                if (res?.status?.success) {

                }
            } catch (error) {
                console.error('An error occurred!', error);
            }
        }

        const item = list.filter(i => i.id === id)[0]
        if (item.file)
            deleteFiles(item.file).then(() => {
                SellingCardApi.DeleteSellingCardBlock(req).then(
                    (response) => {

                        if (response.status.success) {

                            Swal.fire({
                                title: "Step Deleted",
                                text: "Step Deleted Successfully",
                                icon: "success",
                                didOpen: () => {
                                    Swal.hideLoading()

                                },
                                didClose: () => {
                                    if (list.length === 1) {
                                        navigate(-1)
                                    } else {
                                        window.location.reload()
                                    }
                                }
                            });

                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.status.message,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                showConfirmButton: true,
                                didOpen: () => {
                                    Swal.hideLoading()
                                }
                            });
                        }
                    }
                );
            })
        else {
            SellingCardApi.DeleteSellingCardBlock(req).then(
                (response) => {
                    if (response.status.success) {
                        Swal.fire(
                            {
                                title: "Step Deleted",
                                text: "Step Deleted Successfully",
                                icon: "success",
                                didOpen: () => {
                                    Swal.hideLoading()
                                }
                            }
                        );
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.status.message,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: true,
                            didOpen: () => {
                                Swal.hideLoading()
                            }
                        });
                    }
                }
            );
        }
        const newList = list.filter((item) => item.id !== id)
        setList(newList)

    }


    useEffect(() => {
        setSelectedBlock(list.filter(i => i.blockNumber === currentStep)[0])
    }, [currentStep])

    useEffect(
        () => {
            if (list) {
                setSteps(list.map((i, index) => {
                    return {
                        id: index + 1,
                        blockId: i.id,
                        blockNumber: i.blockNumber
                    }
                }))
            }
        }, [list]
    )

    return (
        <Layout>
            {loading ?
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                    width: "100%"
                }}>
                    <CircularProgress sx={{
                        color: "var(--primary-color)"
                    }} size={70} />
                </div>
                :
                <div className='sellingCardTutorialContainer' >
                    <div className="sellingCardHeader">
                        <h1 className='backBtn' ><IoMdArrowRoundBack size={30} onClick={() => { navigate(-1) }} /></h1>
                        <h1 className='sellingCardTutorialHeading' >{cardName}</h1>
                        <div></div>
                    </div>
                    <div className='innerContainer' >
                        <Steps update={update} setUpdate={setUpdate} card={card} searchTutorial={searchTutorial} cardId={trainingModuleId} steps={steps} setSteps={setSteps} noOfSteps={list?.length > 0 ? list.length : 0} currentStep={currentStep} setCurrentStep={setCurrentStep} />
                        <div style={{
                            width: "100%",
                        }} ref={refPassthrough}>
                            <StepCard update={update} setUpdate={setUpdate} card={card} searchTutorial={searchTutorial} cardId={trainingModuleId} totalSteps={list?.length > 0 ? list.length : 0} blockId={selectedBlock?.id} removeStep={removeStep} index={currentStep} create={(user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") && currentStep === -1} title={selectedBlock?.title} description={selectedBlock?.content} files={selectedBlock?.file} />
                        </div>
                    </div>
                    <div className="upAndDownArrows">
                        <IoMdArrowDropleftCircle
                            style={{ cursor: "pointer" }}
                            color="#2761D0"
                            size={40}
                            onClick={() => {
                                setCurrentStep((prevStep) => {
                                    if (prevStep === -1) {
                                        return steps.length > 0 ? steps[steps.length - 1].blockNumber : -1;
                                    } else {
                                        const currentIndex = steps.findIndex(step => step.blockNumber === prevStep);
                                        if (currentIndex > 0) {
                                            return steps[currentIndex - 1].blockNumber;
                                        } else {
                                            return steps.length > 0 ? steps[steps.length - 1].blockNumber : -1;
                                        }
                                    }
                                });
                            }}
                        />

                        <IoMdArrowDroprightCircle
                            style={{ cursor: "pointer" }}
                            color="#2761D0"
                            size={40}
                            onClick={() => {
                                setCurrentStep((prevStep) => {
                                    if (prevStep === -1) {
                                        return steps.length > 0 ? steps[0].blockNumber : -1;
                                    } else {
                                        const currentIndex = steps.findIndex(step => step.blockNumber === prevStep);
                                        if (currentIndex < steps.length - 1) {
                                            return steps[currentIndex + 1].blockNumber;
                                        } else {
                                            return -1;
                                        }
                                    }
                                });
                            }}
                        />
                    </div>


                </div>
            }
        </Layout>
    )
}

export default Index