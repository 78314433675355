import React,{useState,useEffect} from 'react'
import UserSelector from '../../../../components/UserSelector'
import Swal from 'sweetalert2'
import CreateConversationReq from '../../../../Requests/Messaging/CreateConversationReq'
import MessagingApi from '../../../../API/MessagingApi'
import SearchUserReq from '../../../../Requests/Users/SearchUserReq'
import UsersApi from '../../../../API/UsersApi'
import { UserContext } from '../../../../App'

const CreateConversation = ({type,getUserConversations}) => {

    const [name, setName] = React.useState('');
    const [usersToAdd, setUsersToAdd] = React.useState([]);
    const [isConversationNameVisible, setIsConversationNameVisible] = useState(false);
    const [isUserSelectorVisible, setIsUserSelectorVisible] = useState(false);
    const [isCreateButtonVisible, setIsCreateButtonVisible] = useState(false);
    const [conversationType, setConversationType] = useState('Private');
    const user = React.useContext(UserContext);
    async function createConversation() {
        try {

            // Show loading spinner while creating conversation
            Swal.fire({
                icon: 'info',
                title: 'Creating Conversation',
                text: 'Please wait...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            // Create request object
            const req = new CreateConversationReq();

            req.creatorId = user.id;
            req.conversationType = type;
            req.name = name;
            // Customize request properties based on conversation type
            if(type==="All"){
                req.conversationType = conversationType;
            }

            // Validate name input for non-private conversations
            if (!name && req.conversationType !== "Private") {
                Swal.fire({
                    icon: 'error',
                    title: 'Name is required',
                    text: 'Please enter Name',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            // Validate user input for private conversations
            if (!usersToAdd && req.conversationType === "Private") {
                Swal.fire({
                    icon: 'error',
                    title: 'User is required',
                    text: 'Please select a user',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }

            // Set common properties for all types of conversations
           
            if (req.conversationType === "Group") {
                req.userIds = [user.id, ...usersToAdd];
            } else if (req.conversationType === "Private") {
                req.name = await buildPrivateConversationName();
                req.userIds = [user.id,usersToAdd];
            } else if (type === "SalesOrg") {
                req.salesOrgId = usersToAdd;
            } else if (req.conversationType === "Global") {
                req.userIds = [user.id];
            } else {
                req.salesOrgId = user.salesOrgId;
               if(user.userType === "Customer")
               {
                    if(user.fidiumCustomer && user.fidiumCustomer.salesRepId){
                        req.userIds = [user.id,user.fidiumCustomer.salesRepId]
                    }
                    else{
                        req.userIds = [user.id]
                    }
               }
               else
               {
                   req.userIds = [user.id,usersToAdd]
               }

            }
            // Validate sales organization input
            if (!usersToAdd && req.conversationType === "SalesOrg") {
                Swal.fire({
                    icon: 'error',
                    title: 'Sales Organization is required',
                    text: 'Please select an Organization',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
            }
            // Send request to create conversation
            let res = await MessagingApi.CreateConversation(req);


            // Handle create conversation response
            if (res?.status?.success) {
                console.log('Conversation created:', res);
                Swal.fire({
                    icon: 'success',
                    title: 'Conversation Created',
                    text: "Conversation has been created",
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                });
                setName("");
                getUserConversations();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        } catch (error) {
            console.error('Error creating conversation:', error);
        }
    }

    // Function to build private conversation name
    async function buildPrivateConversationName() {
        const searchreq = new SearchUserReq();
        searchreq.id = usersToAdd;
        const res = await UsersApi.SearchUser(searchreq);
        if (res.data && res?.data?.list && res?.data?.list.length > 0) {
            return `${user.firstName} ${user.lastName} ${res?.data?.list[0].firstName} ${res?.data?.list[0].lastName}`;
        } else {
            return `Private Conversation with ${user.name}`;
        }
    }


    useEffect(() => {
        setIsConversationNameVisible(true);
        setIsUserSelectorVisible(true);
        setIsCreateButtonVisible(true);
        if (type === "Private" || conversationType==="Private") {
            setIsConversationNameVisible(false);
            if (user.userType === "Customer" ) {
                setIsUserSelectorVisible(false);
                setIsCreateButtonVisible(false);
            }
        } else if (type === "Group" || conversationType==="Group") {
            if (user.userType !== "Customer") {
                setIsConversationNameVisible(true);
                setIsUserSelectorVisible(true);
                setIsCreateButtonVisible(true);
            }
        } else if (type === "SalesOrg" || conversationType==="SalesOrg") {
            if (user.userType !== "SuperAdmin" && user.userType !== "SalesOrgAdmin") {
                setIsConversationNameVisible(false);
                setIsUserSelectorVisible(false);
                setIsCreateButtonVisible(false);
            }

        } else if (type === "Global" || conversationType==="Global") {
            if (user.userType !== "SuperAdmin") {
                setIsConversationNameVisible(false);
                setIsUserSelectorVisible(false);
                setIsCreateButtonVisible(false);
            }
        }
        else if(type === "CustomerService" || conversationType==="CustomerService"){
            setIsConversationNameVisible(true);
            if(user.userType === "Customer"){
                setIsUserSelectorVisible(false);
            }
            setIsCreateButtonVisible(true);
        }

    }, [type, user.userType,conversationType])
    return (
        <div className='createConversationForm'>
            <h1 style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
            }}>Create a New Chat</h1>
            {isConversationNameVisible && (
                <input
                    placeholder='Enter Conversation Name'
                    onChange={(e) => { setName(e.target.value) }}
                    value={name}
                    className='conversationNameInput'
                />
            )}

            {isUserSelectorVisible && 
                <UserSelector conversationType={type==="All"?conversationType:type} placeholder='Select User To Add' setUser={setUsersToAdd} />
            }
            {
                type === "All" 
                &&
                <select onChange={(e)=>{
                    setConversationType(e.target.value)
                }} >

                    <option value="Private">Private</option>
                    <option value="Group">Group</option>
                    {user.userType==="SuperAdmin" && <option value="SalesOrg">Sales Organization</option>}
                    <option value="CustomerService">Customer Service</option>
                    <option value="Global">Global</option>

                </select>
            }
            {isCreateButtonVisible && (
                <button onClick={() => { createConversation() }}>Create</button>
            )}

        </div>
    )
}

export default CreateConversation