class GetCommissionPlanReq{
    constructor() {
        this.id = null;
        this.name = null;
        this.description = null;
        this.dealSavedPercentage = null;
        this.dealSavedPay = null;
        this.pagingParams = {
            pageNumber: 1,
            pageSize: 1
        };
    }
}

export default GetCommissionPlanReq;