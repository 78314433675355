/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Delete } from '@mui/icons-material'
import { CircularProgress, IconButton, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import OrgApi from '../../../API/OrgApi'
import UsersApi from '../../../API/UsersApi'
import AddMemberOrgReq from "../../../Requests/Organizations/AddMemberOrgReq"
import RemoveMemberOrgReq from '../../../Requests/Organizations/RemoveMemberOrgReq'
import SearchUserReq from '../../../Requests/Users/SearchUserReq'
import Button from '../../../components/Button'
import DataTable from "../../../components/Datatable"
import UserSelector from '../../../components/UserSelector'
import { IoCloseCircle } from 'react-icons/io5'
// import { BiRightArrow,BiLeftArrow } from 'react-icons/bi'
// import { PaginationItem } from '@mui/material'
const Index = ({ orgId, name, setModelOpen }) => {


    const [totalPages2, setTotalPages2] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [users2, setUsers2] = useState([]);
    const [loading, setLoading] = useState(false)

    const [update, setUpdate] = useState(1);
    const [user, setUser] = useState()

    const headers = [
        {
            key: "id",
            name: "ID"
        },
        {
            key: "firstName",
            name: "First Name"
        },
        {
            key: "lastName",
            name: "Last Name"
        },
        {
            key: "email",
            name: "Email Address"
        },
        {
            key: "phoneNumber",
            name: "Phone Number"
        },
        {
            key: "address",
            name: "Address"
        },
        {
            key: "userType",
            name: "User Type"
        },
        {
            key: "status",
            name: "Status"
        },
        {
            key: "AddMember",
            name: "Add Member"
        }

    ]

    let headers2 = headers.slice()
    headers2[headers2.length - 1] = {
        key: "AddMember",
        name: "Remove Member"
    }
    function addMember(id) {
        Swal.fire({
            title: "Adding Member",
            text: "Please wait while we add the member",
            icon: "info",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                container: "custom-swal"
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })
        let req = new AddMemberOrgReq();
        req.id1 = orgId;
        req.id2 = id
        OrgApi.AddUsersToSalesOrg(req).then(
            (res) => {
                if (res?.status?.success) {
                    Swal.fire(
                        {
                            title: "Member Added",
                            text: "Member has been Added",
                            icon: "success", customClass: {
                                container: "custom-swal"
                            },
                            didOpen: () => {
                                Swal.hideLoading()
                            }
                        }

                    )
                    // setModelOpen(false)
                    setUpdate(update + 1)
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true, customClass: {
                            container: "custom-swal"
                        },
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });
                }
            }
        )


    }

    function deleteUsers(id) {
        setModelOpen(false)
        Swal.fire(
            {
                title: 'Are you sure?',
                text: `You want to Delete a member`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Yes, I'm Sure!",

            }
        ).then(
            (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Deleting Member",
                        text: "Please wait while we remove the member",
                        icon: "info",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    })
                    let req = new RemoveMemberOrgReq();

                    req.int = id
                    OrgApi.RemoveUsersFromOrg(req).then(
                        (res) => {
                            if (res?.status?.success) {

                                Swal.fire(
                                    {
                                        title: "Member Removed",
                                        text: "Member has been removed",
                                        icon: "success",
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    }

                                )
                                setUpdate(update + 1)
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: res?.status?.message,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }
                    )
                }
            }
        )
    }

    function getAddedUsers() {
        setLoading(true)
        let req = new SearchUserReq()
        req.userTypes = ["SalesRep", "SalesOrgAdmin"]
        req.salesOrgId = orgId
        req.pagingParams.pageNumber = currentPage2
        req.pagingParams.pageSize = 5
        UsersApi.SearchUser(req).then(
            (res) => {
                console.log(res)

                let userTemp = []
                res?.data?.list.map(
                    (user, index) => {
                        // if (user.salesOrgId === orgId)
                        userTemp.push({
                            ...user, AddMember: <IconButton onClick={() => { deleteUsers(user.id) }} sx={{
                                "&:hover": {
                                    color: "red"
                                }
                            }} ><Delete /></IconButton>
                        })
                    }
                )
                // setTotalPages2(Math.ceil(userTemp.length / 5))
                setTotalPages2(res.data.totalPages)
                setUsers2(userTemp)
                setLoading(false)

            }
        )
    }


    useEffect(
        () => {
            getAddedUsers()
        }, [currentPage2, update]
    )
    return (
        <div style={{
            borderRadius: "10px",
            border: "1px solid #E5E5E5",
            background: "#FFF",
            padding: "2rem",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "90%",
            overflow: "auto"
        }}>
            <IoCloseCircle onClick={() => {
                setModelOpen(false)
            }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
            <div className='flex flex-col gap-4 w-full'>
                <h1 className='text-2xl text-center'>Add/Remove a Member</h1>
                <UserSelector excludeSalesOrgId={orgId} className="w-full" placeholder='Select a user' userTypes={['SalesRep', 'SalesOrgAdmin']} setUser={setUser} />
                <Button disabled={!user} onClick={() => { addMember(user) }} >
                    Add Member
                </Button>
            </div>
            <h3 style={{
                marginBottom: "1rem",
                marginTop: "1rem"
            }}>Current Members of {name}</h3>
            {loading ? <div className='flex justify-center align-middle mt-5'><CircularProgress sx={{ color: "var(--color-icon)" }} size={50} /></div> : <DataTable currentPage={currentPage2} setCurrentPage={setCurrentPage2} totalPages={totalPages2} headers={headers2} list={users2} />
            }
            {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem " }}>
                <Pagination renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages2} onChange={(e, pageNo) => {
                    setCurrentPage2(pageNo)
                }} />
            </div> */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem " }}>
                <Pagination count={totalPages2} page={currentPage2} onChange={(e, pageNo) => {
                    setCurrentPage2(pageNo)
                }} />
            </div>
        </div>
    )
}

export default Index