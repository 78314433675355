/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Modal, Rating } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsClock, BsFire } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { UserContext } from '../../../App';
import Layout from '../../../Layouts/Layout';
import SearchCustomerFeedbackReq from '../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq';
import FeedbackForm from './FeedbackForm';
import rewardPic from './images/reward.png';
import './index.scss';

import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import CustomerFeedbackApi from '../../../API/CustomerFeedbackApi';
import StringIntReq from '../../../Requests/StringIntReq';
import ClappingHands from '../../../assets/animation/ClappingHands';
const Index = () => {
    const user = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [feedbackType, setFeedbackType] = useState('')
    const [feedback, setFeedback] = useState([])
    const [loading, setLoading] = useState(false);
    const [showClappingHands, setShowClappingHands] = useState(false);
    const videoRef = useRef(null);
    console.log(feedback)
    useEffect(
        () => {
            if (videoRef.current) {
                videoRef.current.load()
            }
        }
        , [videoRef.current])
    useEffect(
        () => {
            const getFeedback = async () => {
                setLoading(true)
                const req = new SearchCustomerFeedbackReq();
                req.fidiumCustomerId = user.fidiumCustomer.id;
                req.pagingParams.pageNumber = 1;
                req.pagingParams.pageSize = 10;

                const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req)
                // console.log(res)
                setFeedback(res?.data?.list)
                setLoading(false)
            }
            getFeedback()
        }, []
    )


    const requestReward = async (id) => {
        Swal.fire({
            title: 'Claiming Reward',
            text: 'Please do not close the window',
            customClass: {
                container: 'custom-swal',
            },
            didOpen: () => {
                Swal.showLoading();
            },
        });
        const req = new StringIntReq();
        req.int = id;
        const res = await CustomerApi.RequestFeedbackReward(req);

        if (res?.status?.success) {
             // Show clapping hands animation
             Swal.getPopup().style.display = 'none';

             // Show the clapping hands animation
             setShowClappingHands(true);
            setTimeout(() => {
                Swal.fire({
                    title: 'Reward Claimed',
                    text: 'Your reward has been Claimed',
                    icon: 'success',
                    customClass: {
                        container: 'custom-swal',
                    },
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }, 2000); // Delay SweetAlert by 2 seconds (same duration as the animation)
        } else {

            Swal.fire({
                title: 'Failed to Claim Reward',
                text: res?.status?.message,
                icon: 'error',
                customClass: {
                    container: 'custom-swal',
                },
                didOpen: () => {
                    Swal.hideLoading();
                },

            });
        }
    };
    
    

   
    return (
        <Layout>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <CircularProgress
                        size={50}
                        sx={{
                            color: 'var(--primary-color)',
                        }}
                    />
                </div>
            ) : (
                <div className="my-feedback">
                    <h1>YOUR FEEDBACK</h1>
                    {feedback.length > 0 ? (
                        <div className="feedback-list">
                            {feedback.map((item, index) => (
                                <div key={index} className="feedback-item">
                                    <p className="feedback_type">{item.feedbackType}</p>
                                    <Rating
                                        className="feedback_rating"
                                        size="large"
                                        value={item.rating}
                                        readOnly
                                    />
                                    {item.content && (
                                        <p className="feedback_content">{item.content}</p>
                                    )}
                                    {item.video && (
                                        <video
                                            ref={videoRef}
                                            src={item.video.url + '#t=0.001'}
                                            className="feedback_video"
                                            controls
                                        />
                                    )}
                                    {item.speedTest && (
                                        <div className="feedback_image">
                                            <img
                                                src={item?.speedTest?.beforeImage?.url}
                                                alt="before"
                                            />
                                            <img
                                                src={item?.speedTest?.afterImage?.url}
                                                alt="after"
                                            />
                                        </div>
                                    )}
                                    {item.rewardApproved && !item.rewarded && (
                                        <button
                                            onClick={() => {
                                                requestReward(item.id);
                                            }}
                                        >
                                            Claim Reward
                                        </button>
                                    )}
                                    {!item.rewardApproved && (
                                        <p className="reward_pending">Waiting For Approval</p>
                                    )}

                                    {item.rewarded && (
                                        <p className="reward_claimed">Reward Claimed</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="no_feedback">You Haven't Left Any Fedback Yet</p>
                    )}
                    {(feedback.filter((item) => item.feedbackType === 'Video').length === 0 ||
                        feedback.filter((item) => item.feedbackType === 'Written').length === 0 ||
                        feedback.filter((item) => item.feedbackType === 'SpeedTest').length === 0) && (
                        <h3>
                            Provide Your Feedback <br />
                            and Earn Rewards
                        </h3>
                    )}
                    <div className="card-container">
                        {feedback.filter((item) => item.feedbackType === 'SpeedTest').length ===
                            0 && (
                            <div
                                className="speedTest-feedback"
                                onClick={() => {
                                    setOpen(true);
                                    setFeedbackType('speedTest');
                                }}
                            >
                                <p>Speed Test</p>
                                <div>
                                    <div>
                                        <img src={rewardPic} alt="reward" />
                                    </div>
                                    <p>Leave A Speed Test</p>
                                    <div>
                                        <p className="time">
                                            1d 17h 20m<BsClock color="#022780" />
                                        </p>
                                        <FaArrowRight
                                            style={{
                                                marginRight: '1rem',
                                            }}
                                            color="#022780"
                                            size={50}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {feedback.filter((item) => item.feedbackType === 'Written').length ===
                            0 && (
                            <div
                                className="written-feedback"
                                onClick={() => {
                                    setOpen(true);
                                    setFeedbackType('written');
                                }}
                            >
                                <p>Written</p>
                                <div>
                                    <p>HOT</p>
                                    <p>Written Review</p>
    
                                    <BsFire size={80} color="white" />
                                </div>
                            </div>
                        )}
                        {feedback.filter((item) => item.feedbackType === 'Video').length ===
                            0 && (
                            <div
                                className="video-feedback"
                                onClick={() => {
                                    setOpen(true);
                                    setFeedbackType('video');
                                }}
                            >
                                <p>Video</p>
                                <div>
                                    <p>
                                        1d 17h 20m<BsClock />
                                    </p>
                                    <h3>
                                        Get
                                        <br />
                                        rewarded
                                        <br />
                                        for sharing
                                        <br />
                                        experience
                                    </h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        className="feedback-modal"
                    >
                        <FeedbackForm feedbackType={feedbackType} setOpen={setOpen} />
                    </Modal>
                    {showClappingHands && (
                        <ClappingHands onAnimationEnd={() => setShowClappingHands(false)} />
                    )}
                </div>
            )}
        </Layout>
    );
    
}

export default Index