import React from 'react'
import PublicLayout from './../../../Layouts/PublicLayout';
import { FaCheck } from "react-icons/fa";
import lightningBot from "./images/LightningBot.png"
import family from "./images/FamilyUsingInternet.png"
import copperInternet from './images/copper_internet.png'
import fiberInternet from './images/updated_after.png'
import side_light from './images/side_light.png'
import fire from './images/fire.png'
import comparison_light from './images/comparison_light.png'
import './FiberTechnology.scss'
const Index = () => {

    const points = ["4k UHD Streaming", "No More Buffering When Streaming", "Uninterrupted Gaming Experience", "Uninterrupted Video Calling", "More Productive Home Ofifce", "Supports Multiple Devices Simultaneously", "Say goodbye to lag time on your security cameras"]
    const benefits = ["Dedicated Fiber Line", "Enhanced Reliability", "Low Latency", "Symmetrical Speeds", "Unlimited Bandwidth", "Future-Proof Technology", "Secure Connection", "Improved Overall Performance", "Increased Productivity"]

    return (
        <PublicLayout>
            <div className='fiber_technology_container'>
                <div className='fiber_banner'>
                    <div className='text'>
                        <h1>Experience <br />Internet Like Never <br />Before</h1>
                        <div className='lightningBot'>
                            <img src={lightningBot} alt='bot' />
                        </div>
                        <div>
                        {
                            points.map((point, index) => {
                                return (
                                    <div className='point' key={index}>
                                        <FaCheck color='var(--check-color)' />
                                        <p>{point}</p>
                                    </div>
                                )
                            })
                        }
                        </div>

                    </div>

                </div>
                <div className='at_light_container'>
                    <img src={side_light} className='side_light' alt='side light' />
                    <h1 className='at_lightning'>At Lightning OS Internet, we bring<br />you the future of connectivity<br />through advanced fiber technology.</h1>
                </div>
                <div className='key_benefits_container'>
                    <div className='key_benefits_header'>
                        <img src={fire} alt='fire' />
                        <h1>Key Benefits</h1>
                    </div>
                    <div className='key_benefits'>
                        {
                            benefits.map((benefit, index) => {
                                return (
                                    <div className='benefit' key={index}>
                                        <FaCheck color='var(--check-color)' />
                                        <p>{benefit}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='fiber_family'>
                    <img src={family} alt='fiber users' />
                </div>
                <div className='speed_comparison'>
                    <img src={comparison_light} className='comparison_light' alt='comparison light' />
                    <h1>Discover The Power Of<br /> Fiber Optic Internet</h1>
                    <div className='image_vs'>
                        <div className='internet'>
                            <p>Old Copper</p>
                            <img src={copperInternet} alt='copper internet' />
                        </div>
                        <div>
                            <h2>VS</h2>
                        </div>
                        <div className='internet'>
                            <p>New Fiber</p>
                            <img src={fiberInternet} alt='fiber internet' />
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    )
}

export default Index