
class SearchDebtSheetReq {
    constructor() {
        this.id = null;
        this.amountFrom = null;
        this.amountTo = null;
        this.commissionTrackerId = null;
        this.accountingEntryId = null;
        this.userId = null;
        this.paid = true;
        this.timeStampFrom = null;
        this.timeStampTo = null;
        this.type = null;
        this.userIds = [];
        this.accountingEntryIds = [];
        this.commissionTrackerIds = [];
        this.types = [];
        this.pagingParams = {
            pageNumber: null,
            pageSize: null
        };
    }
}
export default SearchDebtSheetReq;