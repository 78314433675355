import React from 'react'
import './index.scss'
import ReviewCard from './component/ReviewCard'
import Layout from '../../../Layouts/Layout'
const Index = () => {

    const reviews = [
        {
            name: 'John Doe',
            rating: 4,
            review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, molestiae.',
            address: 'Los Angeles, CA'
        },
        {
            name: 'John Doe',
            rating: 4,
            review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, molestiae.',
            address: 'Los Angeles, CA'
        },
        {
            name: 'John Doe',
            rating: 4,
            review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, molestiae.',
            address: 'Los Angeles, CA'
        },
        {
            name: 'John Doe',
            rating: 4,
            review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, molestiae.',
            address: 'Los Angeles, CA'
        },
        {
            name: 'John Doe',
            rating: 4,
            review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, molestiae.',
            address: 'Los Angeles, CA'
        },
    ]



  return (
    <Layout>
        <div className='fiberFeedBackContainer'>
            <h1 className='fiberFeedBackHeading'>Fiber Feedback</h1>
            <div className='fiberFeedbackBody'>
                <div className='bodyHeader'>
                    <h3>Reviews</h3>
                    <p className='count'>(2343)</p>
                    <p className='viewAll'>View All</p>
                </div>
                <div className='body' >
                        {
                            reviews.map((review, index) => (
                                <ReviewCard key={index} review={review} />
                            ))
                        }
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default Index