class UpdateCustomerInstallDateReq {
    constructor() {
        this.customerId = null;
        this.installDateTime = null;
        this.installDateTimeTo = null;
        this.rescheduleFormId = null;
        this.fiberPlanId = null;
        this.fiberCompanyId = null;
    }
}

export default UpdateCustomerInstallDateReq;