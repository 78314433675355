class MarkConversationUserAsSeenReq {
    constructor() {

        this.string = null;
        this.int = null;
        this.pagingParams = {
            pageNumber: 1,
            pageSize: 100
        }

    }
}

export default MarkConversationUserAsSeenReq