class EditCustomerReq {

    constructor() {
        this.Id = "";
        this.OrderNumber = "";
        this.SchedulingNotes = "";
        this.InstallDateTime = "";
        this.InstallCopy = "";
        this.TimeZone = "";
        this.AccountStatus = "";
        this.AccountNotes = "";
        this.SubmittedToEmbarcForm = "";
        this.SecondaryPhoneNumber = "";
        this.FirstName = "";
        this.LastName = "";
        this.SecondaryAccountHolderFullName = "";
        this.Email = "";
        this.Address = "";
        this.CellPhone = "";
        this.DesiredInstallDateString = "";
        this.DesiredInstallTimeString = "";
        this.FourDigitPin = "";
        this.SecurityQuestion1 = "";
        this.SecurityQuestion2 = "";
        this.AnswerSecurityQuestion1 = "";
        this.AnswerSecurityQuestion2 = "";
        this.FiberPlanString = "";
        this.FiberPlanId = "";
        this.NotesToFiberCompanySpecialInstructions = "";
        this.PromoCode = "";
        this.FreeMonth = "";
        this.ConsentChecked = "";
        this.CurrentInternetSpeed = "";
        this.IsHotLead = "";
        this.SpeedTestBeforeImage = "";
        this.SpeedTestAfterImage = "";
    }
}

export default EditCustomerReq