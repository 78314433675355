import React, { useEffect, useState, useRef, useCallback, useContext } from 'react'
import VideoReview from './VideoReview'
import CustomerFeedbackApi from '../../../../API/CustomerFeedbackApi';
import SearchCustomerFeedbackReq from '../../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq';
import { CircularProgress } from '@mui/material';
import WrittenReview from './WrittenReview';
import SpeedTestReview from './SpeedTestReview';
import { UserContext } from '../../../../App';
import { useParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import RContainer from './../../DigitalBusinessCenter/components/ReviewContainer'
import { IoCloseCircle } from 'react-icons/io5';
const ReviewContainer = ({writtenOnly}) => {

    const user = useContext(UserContext)
    const { id } = useParams();
    const [reviews, setReviews] = useState([]);
    const [reviewType, setReviewType] = useState("Written") // ['video', 'written', 'speedTest'
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [viewCompanyReviews, setViewCompanyReviews] = useState(true);
    const [openReviewModel, setOpenReviewModel] = useState(false);
    // const lastReviewElementRef = useRef(null);
    const observer = useRef(); // (*)
    // const [firstRender, setFirstRender] = useState(true);
    const lastCard = useCallback(  // (*)
        (node) => {
            if (loading) {
                // console.log("Skip due to loading");
                return;
            }
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {

                if (entries[0].isIntersecting && hasMore) {
                    setPageNumber((prev) => prev + 1);

                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]  // (*) Add firstRender to the dependency array
    );
    const getReviews = async () => {
        setLoading(true);
        const req = new SearchCustomerFeedbackReq();
        req.pagingParams.pageNumber = pageNumber;
        req.pagingParams.pageSize = 20;
        req.feedbackType = reviewType;
        if(id !== null && viewCompanyReviews === false){
            
            req.salesRepId = id;
        }
        console.log(req);
        const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
        // console.log(res);
        if (res?.status?.success) {
            if (res.data.totalPages > pageNumber) {
                setHasMore(true);
            }
            else {
                setHasMore(false);
            }

            if (pageNumber === 1)
                setReviews(res?.data?.list);
            else {
                setReviews(prevReviews => [...prevReviews, ...res?.data?.list]);
            }
            setLoading(false);
        }
        else {
            // console.log(res);
        }
    }

    useEffect(
        () => {
            getReviews();
        }, [pageNumber]
    )

    useEffect(() => {
        setPageNumber(1);
        setReviews([]);
        getReviews();
    }, [reviewType, viewCompanyReviews])

    function returnReviews() {
        if (reviews.length > 0) {
            if (reviewType === 'Video') {
                return reviews.map(
                    (review, index) => {
                        return <div onClick={() => { setOpenReviewModel(true) }} style={{ height: "100%", width: "100%" }} ref={index + 1 === reviews.length ? lastCard : null} ><VideoReview key={index} review={review} /></div>
                    }
                )
            }
            else if (reviewType === 'Written') {
                return reviews.map(
                    (review, index) => {
                        return <div onClick={() => { setOpenReviewModel(true) }} style={{ height: "100%", width: "100%" }} ref={index + 1 === reviews.length ? lastCard : null}><WrittenReview key={index} review={review} /></div>
                    }
                )
            }
            else if (reviewType === 'SpeedTest') {
                return reviews.map(
                    (review, index) => {
                        return <div onClick={() => { setOpenReviewModel(true) }} style={{ height: "100%", width: "100%" }} ref={index + 1 === reviews.length ? lastCard : null}><SpeedTestReview key={index} review={review} /></div>
                    }
                )
            }
        }
        else if (!loading) {
            return <p className='noReviews'>No Reviews Found</p>
        }

    }


    return (
        <>
            <Modal open={openReviewModel} onClose={() => {
                setOpenReviewModel(false)
            }}>
                <div style={{
                    padding: "1rem",
                    maxWidth: "90%",
                    minWidth: "50%"
                }} className='assign_leads_container'>
                    <div style={{
                        height:"30px"
                    }}>
                        <IoCloseCircle onClick={() => {
                            setOpenReviewModel(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                    </div>
                    <RContainer type={reviewType} />
                </div>
            </Modal>
            <div className='review_container'>
                <div className='container_header'>
                    <h3>Reviews</h3>
                    {/* <p>View all</p> */}
                </div>
                <div className='container_filter'>
                    <button className={reviewType === "Video" ? "active" : null} onClick={() => { setReviewType('Video') }} >Video</button>
                    <button className={reviewType === "Written" ? "active" : null} onClick={() => { setReviewType('Written') }} >Written</button>
                    <button className={reviewType === "SpeedTest" ? "active" : null} onClick={() => { setReviewType("SpeedTest") }} >Speed Test</button>
                </div>
                {


                    returnReviews()
                }
                {
                    loading &&
                    <div className='spinner'>
                        <CircularProgress size={50} sx={{
                            color: "var(--primary-color)"
                        }} />
                    </div>
                }
            </div>
        </>
    )
}

export default ReviewContainer