import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import Select from 'react-select';
import { MapContext } from '..';
import Swal from 'sweetalert2';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import MapHelper from '../MapHelper';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Icon, Style } from 'ol/style';
import { Collection, Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import Modify from 'ol/interaction/Modify';
import CreateFiberHouseReq from '../../../../Requests/FiberHouse/CreateFiberHouseReq';
import CreateCandidateLeadReq from '../../../../Requests/FiberHouse/CreateCandidateLeadReq';
import BatchCreateCandidateLeadReq from '../../../../Requests/FiberHouse/BatchCreateCandidateLeadReq';
import FiberHouseApi from '../../../../API/FiberHouseApi';


const CreateCandidateLead = () => {
    const [file, setFile] = useState(null);
    const [leadType, setLeadType] = useState(        { value: 'Company', label: 'Company' });
    const [selectedMapIcon, setSelectedMapIcon] = useState(null);
    const [mapStrings, setMapStrings] = useState([]);
    const [mapStringObjects, setMapStringObjects] = useState([]);
    const [mapStringObjectsGood, setMapStringObjectsGood] = useState([]);
    const [mapStringObjectsBad, setMapStringObjectsBad] = useState([]);
    const [mapStringObjectsWithServicableArea, setMapStringObjectsWithServicableArea] = useState([])
    const [housesWithSameCoordinates, setHousesWithSameCoordinates] = useState([])
    const [newLeadsLayer, setNewLeadsLayer] = useState(null)
    const { mapIcons, mapObject, setIndex } = React.useContext(MapContext);
    const helper = new MapHelper(mapObject)

    const leadTypeOptions = [
        { value: 'Recruit', label: 'Recruit' },
        { value: 'Company', label: 'Company' },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#C4C4C4',
            borderRadius: '0.5rem',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#2761D0',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#2761D0' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: '#E6F0FF',
                color: '#2761D0',
            },
        }),
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setMapStringObjects([]);
        setMapStringObjectsGood([]);
        setMapStringObjectsBad([]);
        setHousesWithSameCoordinates([])
    };

    const handleLeadTypeChange = (selectedOption) => {
        setLeadType(selectedOption);
        setSelectedMapIcon(null); // Reset selected map icon when lead type changes
    };

    useEffect(() => {
        if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
            getHousesWithSameCoordinates()
        }
    },[mapStringObjectsBad])

    const getHousesWithSameCoordinates = () => {
        // Filter out entries with valid [longitude, latitude] coordinates
        const validCoordinates = mapStringObjectsGood
            .filter((ms) => Array.isArray(ms.coordinates) && ms.coordinates.length === 2 && ms.coordinates[0] !== null && ms.coordinates[1] !== null)
            .map((ms) => ms.coordinates);
    
        console.log('Valid Coordinates:', validCoordinates);
    
        let duplicates = [];
        let uniqueCoordinates = new Set();
    
        validCoordinates.forEach((coord, index) => {
            const coordKey = `${coord[0]},${coord[1]}`; // Unique key for each [longitude, latitude]
    
            if (uniqueCoordinates.has(coordKey)) {
                // Mark as duplicate
                setMapStringObjectsGood((prevList) =>
                    prevList.map((ms, i) => {
                        if (ms.coordinates && ms.coordinates[0] === coord[0] && ms.coordinates[1] === coord[1]) {
                            return { ...ms, duplicate: true };
                        }
                        return ms;
                    })
                );
                duplicates.push(coord);
            } else {
                uniqueCoordinates.add(coordKey);
            }
        });
    
        setHousesWithSameCoordinates(duplicates);
        console.log('Duplicates:', duplicates);
    };
    

    const parseFile = () => {
        if (!file) {
            Swal.fire({
                icon: 'error',
                title: 'No File Selected',
                text: 'Please upload a file to proceed.',
                showConfirmButton: true,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;

            if (file.name.endsWith('.csv')) {
                const { data, errors } = Papa.parse(text, {
                    header: true,
                    skipEmptyLines: true,
                });

                if (errors.length) {
                    Swal.fire({
                        icon: 'error',
                        title: 'CSV Parsing Error',
                        text: errors[0].message,
                        showConfirmButton: true,
                    });
                    return;
                }

                processParsedData(data);
            } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetData = XLSX.utils.sheet_to_json(
                    workbook.Sheets[workbook.SheetNames[0]],
                    { header: 1 }
                );

                const headers = sheetData[0];
                const rows = sheetData.slice(1).map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        obj[header] = row[index];
                    });
                    return obj;
                });

                processParsedData(rows);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Unsupported File Type',
                    text: 'Please upload a CSV or Excel file.',
                    showConfirmButton: true,
                });
            }
        };

        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
            reader.readAsArrayBuffer(file);
        } else {
            reader.readAsText(file);
        }
    };

    const processParsedData = (data) => {
        const parsedData = data.map((row) => ({
            fullName: row['Full Name'] || '',
            personalEmail: row['Email 2'] || '',
            companyEmail: row['Email'] || '',
            linkedIn: row['Linkedin Links'] || '',
            doorToDoor: row['Door to Door sell? Yes/No'] === "Yes" ? true : (row['Door to Door sell? Yes/No'] ? false : null),
            address: row['Address'] || '',
            city: row['City'] || '',
            state: row['State'] || '',
            zip: row['Zip Code'] || '',
            title: row['Title'] || '',
            industry: row['Industry'] || '',
            company: row['Company Name'] || '',
            country: row['Country'] || '',
            mapString: row['Address'] + " " + row['City'] + " " + row['State'],
        }));
        console.log("PARSED DATA", parsedData)
        setMapStrings(parsedData);
    };


    const mapStringsChange = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Fetching Coordinates',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const list = [];

        // use a for loop to wait for each promise to resolve
        for (let i = 0; i < mapStrings.length; i++) {
            var location = mapStrings[i];
            try {

                //update swal to show progress
                Swal.update({
                    title: 'Fetching Coordinates',
                    html: `Please wait...<br>Progress: ${i + 1}/${mapStrings.length}`,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                var details = await helper.searchLocationDetails(location.address);
                console.log("Location", location)
                list.push({
                    mapString: details.address,
                    address: location.address,
                    coordinates: details.coordinates,
                    city: location.city,
                    state: location.state,
                    zipCode: location.zip,
                    name: location.fullName,
                    industry: location.industry,
                    title: location.title,
                    companyEmail: location.companyEmail,
                    personalEmail: location.personalEmail,
                    linkedIn: location.linkedIn,
                    doorToDoor: location.doorToDoor,
                    company: location.company,
                    country: location.country,
                    index: i
                });
            }
            catch (e) {
                console.log(e)
            }
        }

        // Now that all promises are resolved, update the state
        console.log("LIST", list)
        setMapStringObjects(list);

        Swal.fire({
            icon: 'success',
            text: 'Coordinates Fetched',
            title: 'Success',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: true,
            showConfirmButton: true,

            didOpen: () => {
                // Add your code here to handle the appropriate function
                // For example, you can call a function to display a success message or perform any other action
                Swal.hideLoading();
            }
        });
    }

    const filterMapStringObjects = () => {
        const good = [];
        const bad = [];
    
        mapStringObjects.forEach((entry) => {
            const hasValidCoordinates = Array.isArray(entry.coordinates) && 
                                        entry.coordinates.length === 2 && 
                                        entry.coordinates[0] !== null && 
                                        entry.coordinates[1] !== null;
    
            if (entry.address && entry.city && entry.state && hasValidCoordinates) {
                good.push(entry);
            } else {
                bad.push(entry);
            }
        });
    
        setMapStringObjectsGood(good);
        setMapStringObjectsBad(bad);
    };

    useEffect(() => {
        if (mapStringObjects.length > 0) {
            console.log(mapStringObjects);
            filterMapStringObjects();
        }
    }, [mapStringObjects]);

    useEffect(() => {
        console.log("MAP STRINGS", mapStrings)
        if (mapStrings && mapStrings.length > 0) {
            mapStringsChange()
        }
    }, [mapStrings])

    useEffect(() => {
        if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
            console.log("MAP STRING OBJECTS GOOD", mapStringObjectsGood)
            showFiberHousesOnMap()
            UpdateMapStringObjectsWithServicableArea()

        }
    }, [mapStringObjectsGood])


    const UpdateMapStringObjectsWithServicableArea = () => {


        let updatedList = [];
        mapStringObjectsGood.forEach(fa => {
            let newListObject = fa;
            updatedList.push(newListObject)
        });

        console.log("UPDATED LIST", updatedList)
        setMapStringObjectsWithServicableArea(updatedList)
    }


    const formatPhoneNumber = (phone) => {
        if (!phone) return ""; // Handle case where phone number is missing
        // Remove non-digit characters
        let cleanedPhone = phone.replace(/\D+/g, '');
        // Add +1 for US code
        return `+1${cleanedPhone}`;
    };

    useEffect(() => {
        if (file) {
            parseFile()
        }
    }, [file])

    const CreateLead = async () => {
        if (!selectedMapIcon) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select a map icon.',
                showConfirmButton: true,
            });
            return;
        }

        if (!leadType) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select a lead type.',
                showConfirmButton: true,
            });
            return;
        }

        //swal loading
        Swal.fire({
            icon: 'info',
            title: 'Creating Leads',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        var list = [];
        mapStringObjectsWithServicableArea.forEach((mapStringObject, index) => {
            console.log("MAP STRING OBJECT", mapStringObject)
            const req = new CreateCandidateLeadReq();
            req.name = mapStringObject.name;
            req.phone = null
            req.companyEmail = mapStringObject.companyEmail;
            req.personalEmail = mapStringObject.personalEmail;
            req.state = mapStringObject.state;
            req.city = mapStringObject.city;
            req.zipCode = String(mapStringObject.zipCode);
            req.address = mapStringObject.address;
            req.country = mapStringObject.country;
            req.mapString = mapStringObject.mapString;
            req.mapIconId = selectedMapIcon.id;
            req.industry = mapStringObject.industry;
            req.title = mapStringObject.title;
            req.company = mapStringObject.company;
            req.doorToDoor = mapStringObject.doorToDoor;
            req.linkedIn = mapStringObject.linkedIn;
            req.status = "New";
            req.coordinate = {
                latitude: mapStringObject.coordinates[1],
                longitude: mapStringObject.coordinates[0]
            };
            list.push(req);
        });

    

        let req = new BatchCreateCandidateLeadReq();
        req.candidateType = leadType.value;
        req.candidates = list;
        console.log("REQ", req)

         let res = await FiberHouseApi.BatchCreateCandidateLeads(req);
        if(res?.status?.success){
            Swal.fire({
                title: "Leads Created",
                text: res?.status?.message,
                icon: "success",
                allowEnterKey: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }
    };


    const showFiberHousesOnMap = () => {
        //show each mapStringObject on the map with a marker and popup
        // Create a vector source and vector layer for the markers

        const oldZoom = mapObject.getView().getZoom();
        //remove the newHousesLayer from the map
        if (newLeadsLayer) {
            mapObject.removeLayer(newLeadsLayer)
        }
        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        // Create a style for the markers
        const markerStyle = new Style({
            image: new Icon({
                src: 'https://openlayers.org/en/latest/examples/data/icon.png', // Blue-dot icon
                scale: 0.9, // Adjust the scale as needed
                zIndex: 10000000, // Make sure the icon stays on top of other map features
            }),
        });

        // Add the vector layer to the map
        mapObject.addLayer(vectorLayer);
        mapObject.getView().setZoom(oldZoom);
        // Add a point feature for each coordinate with the marker style
        // mapStringObjects.forEach(ms => {
        const features = new Collection();
        const validEntries = mapStringObjectsGood.filter(
            (ms) => Array.isArray(ms.coordinates) && ms.coordinates.length === 2 && ms.coordinates[0] !== null && ms.coordinates[1] !== null
        );
        validEntries.map(ms => {
            const point = new Point(fromLonLat(ms.coordinates));
            const feature = new Feature(point);
            feature.set('mapString', ms.mapString);
            feature.set('index', ms.index);
            feature.set('coordinates', ms.coordinates);
            feature.set('bulk_mark', 1)
            feature.setStyle(markerStyle);
            vectorSource.addFeature(feature);
            features.push(feature);
        });

        const modify = new Modify({ features: features });
        mapObject.addInteraction(modify);
        modify.on('modifyend', function (e) {
            const features = e.features.getArray();
            console.log("MODIFY END", features)
            features.forEach((feature) => {
                const coordinates = feature.getGeometry().getCoordinates();
                const index = feature.get('index');
                setMapStringObjectsGood((prevList) => {
                    return prevList.map((ms) => {
                        if (ms.index === index) {
                            return { ...ms, coordinates: toLonLat(coordinates) }
                        }
                        return ms;
                    })
                });
            });
        })
        vectorLayer.setZIndex(1000)

        // Zoom to the extent of the vector layer
        if (!newLeadsLayer) {
            mapObject.getView().fit(vectorSource.getExtent());
        }
        setNewLeadsLayer(vectorLayer)

        // Add a click handler to the map to render the popup.
        mapObject.on('click', function (evt) {

            const feature = mapObject.forEachFeatureAtPixel(evt.pixel,
                function (feature) {
                    return feature;
                });
            if (feature?.get('bulk_mark')) {
                // const coordinates = feature.getGeometry().getCoordinates();
                // Set the position and content of the popup
                Swal.fire({
                    title: 'Popup Title',
                    html: `<div><p>${feature.get('mapString')}</p></div>`,
                    footer: '<button id="customButton">Remove House</button>',
                    confirmButtonText: 'OK',
                });

                // Add a click event listener to the custom button in the Swal popup
                document.getElementById('customButton').addEventListener('click', () => {
                    // Perform the desired action when the button is clicked

                    // Add your logic here
                    removeEntryFromMapStringObjects(feature.get('index'))
                });


            }
        });
    }


    const removeEntryFromMapStringObjects = (index) => {

        console.log(index,"INDEX")
        console.log(mapStringObjects)
        //console.log(metaData)
        setMapStringObjectsGood((prevList) => {
            // Remove the entry from mapStringObjects with the given index
            return prevList.filter((ms) => ms.index !== index);
        });
        setMapStringObjectsWithServicableArea((prevList) => {
            // Remove the entry from mapStringObjects with the given index
            return prevList.filter((ms) => ms.index !== index);
        });
    };

    const downloadBadAddresses = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        mapStringObjectsBad.forEach((ms) => {
            csvContent += ms.mapString + "\n";
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "badAddresses.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    const snapToLocation = (fiberHouse) => {
        let lonlat = [fiberHouse.coordinates.longitude, fiberHouse.coordinates.latitude]
        mapObject.getView().setCenter(fromLonLat(lonlat));
        mapObject.getView().setZoom(21);
    }

    return (
        <div className='FiberHousefileDiv'>
            <div className='TitleDiv'>
                <Typography variant="h5">Bulk Create Candidate Leads</Typography>
                <button onClick={() => setIndex(21)} className='uppercase text-[#2761D0]'>
                    Create Manually
                </button>
            </div>
            <div className='FormDiv'>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className='hidden'
                    id="fileUpload"
                />
                <label
                    htmlFor="fileUpload"
                    className="cursor-pointer overflow-hidden rounded-lg border-2 border-[#C4C4C4] bg-white text-center text-black text-base focus:outline-[#181E4B] flex justify-between items-center px-8 py-2"
                >
                    <div className='flex items-center'>
                        <Typography className="mr-2">{file ? file.name : 'Upload a File'}</Typography>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                        <path d="M5 12.9987H9C9.55 12.9987 10 12.5487 10 11.9987V6.99869H11.59C12.48 6.99869 12.93 5.91869 12.3 5.28869L7.71 0.698694C7.61749 0.60599 7.5076 0.532443 7.38662 0.482261C7.26565 0.43208 7.13597 0.40625 7.005 0.40625C6.87403 0.40625 6.74435 0.43208 6.62338 0.482261C6.5024 0.532443 6.39251 0.60599 6.3 0.698694L1.71 5.28869C1.08 5.91869 1.52 6.99869 2.41 6.99869H4V11.9987C4 12.5487 4.45 12.9987 5 12.9987ZM1 14.9987H13C13.55 14.9987 14 15.4487 14 15.9987C14 16.5487 13.55 16.9987 13 16.9987H1C0.45 16.9987 0 16.5487 0 15.9987C0 15.4487 0.45 14.9987 1 14.9987Z" fill="#2761D0" />
                    </svg>
                </label>

                <Box sx={{ mt: 2 }}>
                    <Select
                        options={leadTypeOptions}
                        value={leadType}
                        onChange={handleLeadTypeChange}
                        placeholder="Select Lead Type"
                        styles={customStyles}
                    />
                </Box>

                <div className='MapIconsDiv'>
                    {mapIcons
                        .filter((icon) =>
                            leadType?.value === 'Recruit'
                                ? icon.iconType === 'Recruit'
                                : icon.iconType === 'Company'
                        )
                        .map((assignedMapIcons, index) => (
                            <div
                                className='MapIconDiv'
                                key={index}
                                style={{
                                    border: selectedMapIcon?.id === assignedMapIcons.id ? '2px solid #2761D0' : 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setSelectedMapIcon(assignedMapIcons)}
                            >
                                <div className='MapIconImageDiv'>
                                    <img src={`data:image/png;base64,${assignedMapIcons.image}`} alt="Map Icon" />
                                </div>
                                <p className='MapIconName'>{assignedMapIcons.abbreviation}</p>
                            </div>
                        ))}
                </div>

                <button
                    onClick={CreateLead}
                    className='bg-[#2761D0] text-white px-4 py-2 rounded hover:bg-[#1E4C9A] transition-colors'
                >
                    Process File
                </button>

                {
                    mapStringObjectsWithServicableArea != null && mapStringObjectsWithServicableArea.length > 0 &&
                    <div className='MapStringObjectDiv'>
                        <div className='MapStringObjectMapStringDiv'>
                            <p className='MapStringObjectMapString'>
                                {mapStringObjectsWithServicableArea.length} leads Detected from {mapStringObjects.length} Addresses
                                and {mapStrings.length} Lines of Data

                            </p>
                            <p className='MapStringObjectMapString'>
                                {mapStringObjectsGood.length} Good Addresses
                            </p>
                            <p className='MapStringObjectMapString'>
                                {housesWithSameCoordinates.length} Duplicate Coordinates
                            </p>
                            <p className='MapStringObjectMapString' style={{ color: mapStringObjectsBad.length > 0 ? "red" : "green" }}>
                                {mapStringObjectsBad.length} Bad Addresses
                                <button onClick={downloadBadAddresses} className='DownloadButton'>Download</button>
                            </p>
                        </div>
                        <div className='MapStringObjectServicableAreaDiv'>
                            <p className='MapStringObjectServicableArea'>
                                {
                                    mapStringObjectsWithServicableArea.filter(fa => fa.servicableArea !== null).length
                                } Inside Serviceable Areas
                            </p>
                        </div>
                    </div>

                }
                {
                    mapStringObjectsWithServicableArea != null && mapStringObjectsWithServicableArea.length > 0 ?
                        mapStringObjectsWithServicableArea.map((mapStringObject, index) => {
                            return (
                                <div key={index} className='MapStringObjectDiv'>
                                    <div className='MapStringObjectMapStringDiv'>
                                        <p className='MapStringObjectMapString'>{mapStringObject.mapString}</p>
                                        <p className='MapStringObjectServicableArea' style={{ color: mapStringObject.servicableArea ? mapStringObject.servicableArea.color : "black" }} >
                                            {mapStringObject.servicableArea && mapStringObject.servicableArea.name}
                                        </p>
                                        {/* {
                                            metaData && metaData.length > 0 && metaData[index].firstName &&
                                            <p>First Name : {metaData[index].firstName}</p>
                                        }
                                        {
                                            metaData && metaData.length > 0 && metaData[index].lastName &&
                                            <p>Last Name : {metaData[index].lastName}</p>
                                        }
                                        {
                                            metaData && metaData.length > 0 && metaData[index].email &&
                                            <p>Email : {metaData[index].email}</p>
                                        }
                                        {
                                            metaData && metaData.length > 0 && metaData[index].phone &&
                                            <p>Phone : {metaData[index].phone}</p>
                                        }
                                        {
                                            metaData && metaData.length > 0 && metaData[index].notes &&
                                            <p>Notes : {metaData[index].notes}</p>
                                        } */}

                                        {/* {
                                            //show if house is duplicate
                                            housesWithSameCoordinates.filter(house => house.coordinates === mapStringObject.coordinates).length > 1 &&
                                            <p className='MapStringObjectMapString' style={{ color: "red" }}>
                                                Duplicate
                                            </p>
                                        } */}
                                    </div>
                                    <div className='MapStringObjectServicableAreaDiv'>

                                        <button onClick={() => {
                                            snapToLocation({
                                                coordinates: {
                                                    latitude: mapStringObject.coordinates[1],
                                                    longitude: mapStringObject.coordinates[0]
                                                }
                                            })
                                        }} style={{ width: "100%" }} >Snap To Location</button>
                                        <button style={{ width: "100%" }} onClick={() => removeEntryFromMapStringObjects(index)} className='remove'>Remove</button>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className='MapStringObjectDiv'>
                            <div className='MapStringObjectMapStringDiv'>
                                <p className='MapStringObjectMapString'>No Map Strings</p>
                            </div>
                            <div className='MapStringObjectServicableAreaDiv'>
                                <p className='MapStringObjectServicableArea'>No Servicable Area</p>
                            </div>
                        </div>

                }

                {
                    mapStringObjectsBad != null && mapStringObjectsBad.length > 0 &&
                    mapStringObjectsBad.map((mapStringObject, index) => {
                        return (
                            <div key={index} className='MapStringObjectDiv'>
                                <div className='MapStringObjectMapStringDiv'>
                                    <p className='MapStringObjectMapString'>{mapStringObject.mapString}</p>
                                </div>
                                <div className='MapStringObjectServicableAreaDiv'>
                                    <p className='MapStringObjectServicableArea' style={{ color: "red" }} >
                                        Bad Address
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default CreateCandidateLead;
