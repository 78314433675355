import React, { useContext, useEffect, useState } from 'react'
import Swal from "sweetalert2"
import UsersApi from '../../../API/UsersApi'
import { UserContext } from '../../../App'
import CreateUserReq from '../../../Requests/Users/CreateUserReq'
import './index.scss'
import Layout from '../../../Layouts/Layout'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const Index = () => {

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const user = useContext(UserContext)
  const SignUp = async () => {
    Swal.fire({
      icon: 'info',
      title: 'Creating User',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    //check if email empty
    if (!email) {
      Swal.fire({
        icon: 'error',
        title: 'Email is required',
        text: 'Please enter email',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check email is valid using regex
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email.match(emailRegex)) {
      console.log("Invalid Email",email);
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check password > 6 characters and confirm password matches
    if (!password || password.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Password must be at least 6 characters',
        text: 'Please enter password',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (password !== cpassword) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please enter password',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }



    // check first name
    if (!firstName) {
      Swal.fire({
        icon: 'error',
        title: 'First Name is required',
        text: 'Please enter first name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check last name
    if (!lastName) {
      Swal.fire({
        icon: 'error',
        title: 'Last Name is required',
        text: 'Please enter last name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check phone
    if (!phone) {
      Swal.fire({
        icon: 'error',
        title: 'Phone is required',
        text: 'Please enter phone number',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check address
    if (!address) {
      Swal.fire({
        icon: 'error',
        title: 'Address is required',
        text: 'Please enter address',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // create user
    let req = new CreateUserReq();
    req.email = email;
    req.password = password;
    req.firstName = firstName;
    req.lastName = lastName;
    req.phone = phone;
    req.address = address;
    req.userType = 'SalesRep';
    req.salesOrgId = user.salesOrgId;

    let res = await UsersApi.CreateUser(req);

    // const addUsersToSalesOrgReq = new AddMemberOrgReq();
    // addUsersToSalesOrgReq.id1 = user.salesOrgId;
    // addUsersToSalesOrgReq.id2 = res.data.id;
    if (res?.status?.success) {
      // SalesOrgApi.AddUsersToSalesOrg(addUsersToSalesOrgReq).then(
      //   (res) => {
      //     if (res?.status?.success) {
      //       Swal.fire({
      //         icon: 'success',
      //         title: 'User Created',
      //         text: "user has been created",
      //         // allowOutsideClick: false,
      //         allowEscapeKey: false,
      //         allowEnterKey: false,
      //         showConfirmButton: false,

      //       });
      //     }
      //     else
      //     {
      //       Swal.fire({
      //         icon: 'error',
      //         title: 'Error',
      //         text: res?.status?.message,
      //         allowOutsideClick: false,
      //         allowEscapeKey: false,
      //         allowEnterKey: false,
      //         showConfirmButton: true,
      //         didOpen: () => {
      //           Swal.hideLoading()
      //         }
      //       });

      //     }
      //   }
      // )

      Swal.fire({
        icon: 'success',
        title: 'User Created',
        text: "user has been created",
        // allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,

      });

      setAddress("")
      setEmail("")
      setFirstName("")
      setLastName("")
      setcPassword("")
      setPhone("")
      setPassword("")
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
    }

  }

  useEffect(
    () => {
      

    }, [user]
  )
  return (
    <Layout>
      <div className='createTeamMember'>
        <div className='form'>
        <h1>Create a team member</h1>
          <input onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type='text' placeholder='FIRST NAME' />
          <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type='text' placeholder='LAST NAME' />
          {/* <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type='tel' placeholder='PHONE' /> */}
          <PhoneInput
            country={'us'}
            value={phone}
            onChange={setPhone}
            excludeCountries={['il']}
          />
          <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='EMAIL' />
          <input onChange={(e) => { setAddress(e.target.value) }} value={address} type="text" placeholder='ADDRESS' />
          <input onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='PASSWORD' />
          <input onChange={(e) => { setcPassword(e.target.value) }} value={cpassword} type="password" placeholder='CONFIRM PASSWORD' />
          <button onClick={() => SignUp()} >CREATE</button>
        </div>
      </div>

    </Layout>
  )
}

export default Index