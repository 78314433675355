/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../../../App'
import anonUser from "./../../../../assets/images/userGrey.png"
// import LoaderConversationReq from '../../../../Requests/Messaging/LoadConversationReq'
import MessagingApi from '../../../../API/MessagingApi'
import { MdDeleteOutline } from "react-icons/md";
import ContextMenu from '../../../../components/ContextMenu'
import Swal from 'sweetalert2'
import AddRemoveUserFromConversation from '../../../../Requests/Messaging/AddRemoveUserFromConversationReq'
import { IoIosOptions } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import { TiUserAdd } from "react-icons/ti";
import { HiUserRemove } from "react-icons/hi";
import AddUsersToConversation from './AddUsersToConversation'
import { GrView } from "react-icons/gr";
import RemoveUsersFromConversation from './RemoveUsersFromConversation'
import { GoDotFill } from 'react-icons/go'
const PrivateChatDisplay = ({ conversation, getUserConversations, ref }) => {

    const user = useContext(UserContext);
    const [conversationName, setConversationName] = React.useState("");
    // const [lastMessage, setLastMessage] = React.useState("");
    const [open, setOpen] = React.useState(null);
    const [openAddUser, setOpenAddUser] = React.useState(false);
    const [openRemoveUser, setOpenRemoveUser] = React.useState(false);
    const [cMenuItems, setCMenuItems] = React.useState([]);
    const [removedUsers, setRemovedUsers] = React.useState([]);
    const [mode, setMode] = React.useState("");
    // const [loadingLastMessage, setLoadingLastMessage] = React.useState(true);
    const navigate = useNavigate()

    const leaveChat = (userId, conId) => {
        const req = new AddRemoveUserFromConversation();
        req.id1 = userId;
        req.id2 = conId;

        Swal.fire({
            title: 'Are you sure to leave?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, leave!',

        }).then(
            (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        icon: 'info',
                        title: 'Leaving Conversation',
                        text: 'Please wait...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    });

                    MessagingApi.RemoveUserFromConversation(req).then(
                        (res) => {
                            if (res?.status?.success) {
                                Swal.fire(
                                    {
                                        title: 'leaved!',
                                        text: 'you leaved successfully.',
                                        icon: 'success',
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    }
                                );
                                getUserConversations();
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: res?.status?.message,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }
                    )
                }
                else {
                    Swal.hideLoading()
                }
            })

    }


    useEffect(() => {
        generateChatName();
    }, [conversation]);

    const generateChatName = () => {
        if (conversation.conversationType === "Private") {
            let otherUser = conversation.conversationUsers.filter((item) => item.userId !== user.id)
            setConversationName(otherUser[0]?.user?.firstName + " " + otherUser[0]?.user?.lastName)
        }
        else {
            setConversationName(conversation.name)
        }
    };

    // function getLastMessage() {
    //     setLoadingLastMessage(true);
    //     const req = new LoaderConversationReq();
    //     req.conversationId = conversation.id;

    //     req.pagingParams.pageNumber = 1;
    //     req.pagingParams.pageSize = 1;
    //     MessagingApi.LoadConversation(req).then(
    //         (res) => {
    //             if (res?.status?.success) {
    //                 setLastMessage(res?.data?.list[0])
    //                 setLoadingLastMessage(false)
    //             }
    //         }
    //     )
    // }

    function handleContextMenu(e) {
        e.preventDefault();
        setOpen(e.currentTarget)
    }

    // useEffect(() => {
    //     getLastMessage()
    // }, []);

    const contextMenuItems = [
        {
            title: 'Leave Conversation',
            icon: (color) => <MdDeleteOutline color={color} size={20} />,
            onClick: () => {
                leaveChat(user.id, conversation.id)
            },
            types: ["Private", "CustomerService", "Group"]
        },
        {
            title: 'Add User',
            icon: (color) => <TiUserAdd color={color} size={20} />,
            onClick: () => {
                console.log("Add User")
                setOpenAddUser(true)
            },
            types: ['Group']
        },
        {
            title: 'Remove User',
            icon: (color) => <HiUserRemove color={color} size={20} />,
            onClick: () => {
                console.log("Remove User")
                setMode("Remove")
                setOpenRemoveUser(true);
            },
            types: ['Group']
        },
        {
            title: 'View Members',
            icon: (color) => <GrView color={color} size={20} />,
            onClick: () => {
                console.log("View Members");
                setMode("View")
                setOpenRemoveUser(true);
            },
            types: ['Group']
        }
    ]

    useEffect(() => {
        let items = []
        contextMenuItems.forEach(element => {
            if ((element.title === "Add User" || element.title === "Remove User") && conversation.creatorId === user.id && conversation.conversationType === "Group") {
                items.push(element)
            }
            else if (element.types.includes(conversation.conversationType) && element.title !== "Add User" && element.title !== "Remove User") {
                items.push(element)
            }
        });
        setCMenuItems(items)
    }, [conversation])

    const getChatImage = () => {
        if (conversation.conversationType === "Private" || conversation.conversationType === "CustomerService") {
            let otherUser = conversation.conversationUsers.filter((item) => item.userId !== user.id)
            let otherUserImage = otherUser[0]?.user?.profileImage?.url
            if (otherUserImage != null) {
                return <img src={otherUserImage} alt="avatar" />
            }
            else {
                return <img src={anonUser} alt="avatar" />
            }
        }
        else {

            if (conversation?.image) {
                return <img src={conversation.image.url} alt="avatar" />
            }
            else {
                return <h2 style={{
                    padding: "1rem",
                    backgroundColor: generateRandomColor(),
                    color: "white",
                    borderRadius: "50%",
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold"
                }}>{conversation.name[0].toUpperCase()}</h2>;
            }
        }
    }

    function generateRandomColor() {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    return (
        <>

            {openRemoveUser && <RemoveUsersFromConversation mode={mode} removedUsers={removedUsers} setRemovedUsers={setRemovedUsers} open={openRemoveUser} conversation={conversation} setOpen={setOpenRemoveUser} />}
            {openAddUser && <AddUsersToConversation open={openAddUser} conversationId={conversation.id} conversationName={conversation.name} setOpen={setOpenAddUser} />}
            <div key={conversation.id} onClick={() => {
                if (!open) navigate('/chat', {
                    state: { conversation: conversation }
                })
            }} className="chat_list_item">
                <div className="chat_list_item_avatar">
                    {getChatImage()}

                </div>
                <div className="chat_list_item_info">
                    <div className="chat_list_item_info_name">

                        {conversationName}
                    </div>
                    {/* <div className="chat_list_item_info_last_message">
                        {
                            loadingLastMessage?
                            "Loading Last Message":
                            lastMessage?
                            (lastMessage?.senderId === user.id ? "You" : conversationName) + ": " + lastMessage?.content:
                            "No Messages"
                        }
                    </div> */}
                </div>
                <div className='chat_list_date'>
                    {
                        // Check if the conversation's last updated date is today
                        new Date(conversation?.lastUpdated).toDateString() === new Date().toDateString() ?
                            // If true, display only the time
                            <div className='chat_list_time'>
                                {
                                    new Date(conversation?.lastUpdated).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                }
                            </div>
                            :
                            // If false, display only the date
                            <div className='chat_list_month'>
                                {
                                    new Date(conversation?.lastUpdated).toLocaleDateString()
                                }
                            </div>
                    }
                    <GoDotFill color={conversation?.conversationUsers.filter(u => u.userId === user.id).length>0 && conversation?.conversationUsers.filter(u => u.userId === user.id)[0].seen? 'transparent' : '#2761D0'} size={20} />
                </div>
            </div>
            {
                conversation.conversationType !== "Global" &&
                <div>
                    <ContextMenu anchorEl={open} handleClose={() => setOpen(null)} menuItems={cMenuItems} />
                    <IoIosOptions color={open ? 'black' : '#ACACAC'} size={30} style={{
                        cursor: 'pointer'

                    }} onClick={handleContextMenu} />
                </div>}
        </>
    )
}

export default PrivateChatDisplay