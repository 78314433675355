import React, { useState } from 'react'
import Swal from 'sweetalert2'
import FiberCompanyApi from '../../../API/FiberCompanyApi'
import './index.scss'
import Layout from '../../../Layouts/Layout'
const Index = () => {
    const [companyName, setCompanyName] = useState('')
    const [description, setDescription] = useState('')
    const [logo, setLogo] = useState(null)

    const createCompany = () => {
        Swal.fire({
            icon: 'info',
            title: 'Creating Fiber Company',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (!companyName) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a company name',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        let formData = new FormData();
        formData.append("name", companyName)
        formData.append("description", description)
        formData.append("logo", logo)
        FiberCompanyApi.CreateFiberCompany(formData).then(res => {
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Fiber Company Created Successfully',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                }).then(() => {
                    setCompanyName('')
                    setDescription('')
                    setLogo(null)
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.message,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                });
            }
        })

    }

    return (
        <Layout>
            <div className='createFiberCompanyContainer'>
                <div className="createFiberCompanyForm" >
                    <h3 className='createFiberCompanyFormTitle'>Create Fiber Company</h3>
                    <input className='createFiberCompanyFormInput'  placeholder="Enter the Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} />
                    <input className="createFiberCompanyFormInput"  placeholder="Enter the Description" value={description} onChange={e => setDescription(e.target.value)} />
                    <input className="" style={{
                        display: "none"
                    }}  type="file" id="fileUpload" onChange={(e) => setLogo(e.target.files[0])} placeholder="Enter image" />
                    <label className='createFiberCompanyUpload' htmlFor="fileUpload" >
                        <div style={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            
                        }} >
                            {logo && (
                                <div style={{
                                    height: "40px",
                                    width: "60px",
                                }} >
                                    <img style={{objectFit:"contain"}} src={URL.createObjectURL(logo)} className='' alt="Uploaded Image" />
                                </div>
                            )}
                            <p className='createFiberCompanyUploadText' >{logo ? logo.name : "Select File"}</p>
                        </div>
                        <svg  xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                            <path d="M5 12.9987H9C9.55 12.9987 10 12.5487 10 11.9987V6.99869H11.59C12.48 6.99869 12.93 5.91869 12.3 5.28869L7.71 0.698694C7.61749 0.60599 7.5076 0.532443 7.38662 0.482261C7.26565 0.43208 7.13597 0.40625 7.005 0.40625C6.87403 0.40625 6.74435 0.43208 6.62338 0.482261C6.5024 0.532443 6.39251 0.60599 6.3 0.698694L1.71 5.28869C1.08 5.91869 1.52 6.99869 2.41 6.99869H4V11.9987C4 12.5487 4.45 12.9987 5 12.9987ZM1 14.9987H13C13.55 14.9987 14 15.4487 14 15.9987C14 16.5487 13.55 16.9987 13 16.9987H1C0.45 16.9987 0 16.5487 0 15.9987C0 15.4487 0.45 14.9987 1 14.9987Z" fill="#2761D0" />
                        </svg>
                    </label>
                    <button className='createFiberCompanyFormButton' onClick={createCompany}>Create</button>
                </div>
            </div>
        </Layout>
    )
}

export default Index