class AssignLeadsToRepsReq{
    constructor(){
        this.fiberHouseIds = [];
          this.leadIds = [];
          this.houseObjects = [];
          this.salesRepFiberHouseIds = [];
          this.repId = 0;

    }
}
export default AssignLeadsToRepsReq;