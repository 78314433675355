import Dashboard from '../Views/Shared/Dashboard'
import ManageIssues from '../Views/CompanyUser/ManageIssues'
import ManageCustomers from '../Views/CompanyUser/ManageCustomers'
import CancelDetailsPage from "../Views/SuperAdmin/ManageIssueTypes/issueCancelDetails";
import RescheduleDetailsPage from "../Views/SuperAdmin/ManageIssueTypes/issueRescheduleDetails";
import RescheduleIssue from '../Views/CompanyUser/ManageCustomers/components/RescheduleIssue';
const CompanyUserRoutes = [
    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        component: <Dashboard />,
    },
    {
        name: "Manage Issues",
        key: "ManageCustomers",
        route: "/manage-issues",
        component: <ManageIssues />
    },
    {
        name:"Manage Customers",
        key:"ManageCustomers",
        route:"/manage-customers",
        component:<ManageCustomers />
    },
    {
        name: "Issue Cancel Details",
        key: "IssueCancelDetails",
        route: "/manage-issues/issue-cancel-details/:id",
        component: <CancelDetailsPage />
    },
    {
        name: "Issue Reschedule Details",
        key: "IssueRescheduleDetails",
        route: "/manage-issues/issue-reschedule-details/:id",
        component: <RescheduleDetailsPage />
    },
    {
        name:"Reschedule",
        key:"Reschedule",
        route:"/manage-customers/reschedule",
        component:<RescheduleIssue />
    }
]

export default CompanyUserRoutes