export default function LightningLoader() {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <div className="relative w-48 h-48 sm:w-64 sm:h-64 md:w-80 md:h-80">
          {/* Lightning bolt */}
          <div className="absolute inset-0 flex items-center justify-center">
            <svg
              className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 text-[#181e4b] animate-pulse"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
          </div>
  
          {/* Orbiting circles */}
          {[0, 1, 2, 3, 4].map((index) => (
            <div
              key={index}
              className="absolute w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4 bg-[#181e4b] rounded-full opacity-60"
              style={{
                animation: `orbitLightning 3s linear infinite`,
                animationDelay: `${index * 0.6}s`,
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          ))}
  
          {/* Pulsing ring */}
          <div className="absolute inset-0 border-2 sm:border-3 md:border-4 border-[#181e4b] rounded-full animate-ping opacity-30" />
  
          {/* Rotating ring */}
          <div
            className="absolute inset-0 border-2 sm:border-3 md:border-4 border-[#181e4b] rounded-full opacity-50"
            style={{
              animation: 'spin 3s linear infinite',
              borderRightColor: 'transparent',
              borderLeftColor: 'transparent',
            }}
          />
        </div>
  
        {/* Loading text */}
        <div className="absolute mt-52 sm:mt-64 md:mt-96 text-[#181e4b] text-lg sm:text-xl md:text-2xl font-bold tracking-wider animate-pulse">
          LightningOS
        </div>
  
        <span className="sr-only">Loading LightningOS...</span>
      </div>
    )
  }