import { IoMdAddCircle } from "react-icons/io";
import { VscReferences } from "react-icons/vsc";
const NoFidiumCustomerMenuItems = [
    {
        name:"Become A Customer",
        key:"CreateCustomer",
        route:"/BecomeACustomer",
        icon: (color,size=40) => <IoMdAddCircle size={size} color={color} />
    },
    {
        name: "Referral Program",
        key: 'referralProgram',
        route: '/referral-program',
        icon:(color,size=40)=> <VscReferences size={size} color={color} />,
    },
]

export default NoFidiumCustomerMenuItems;