import { Modal } from '@mui/material'
import React from 'react'

const Index = ({open,setOpen,dataToExport,fields,excludeFields = []}) => {
  return (
    <Modal open={open} onClose={()=>{
        setOpen(false)
    }}>
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white' style={{
            maxWidth: '80%',
            minWidth: '50%',
        }}>
            <div style={{
                maxHeight: '80vh'
            }} className='p-4 overflow-y-auto'>
                <h2>Export Data</h2>
                <p>Select Fields You want to export</p>
                <div className='grid grid-cols-2'>
                    {
                        fields?.map((field,index) => {
                            if(excludeFields.includes(field.key)){
                                return null
                            }
                            return (
                                <div key={index} className='field'>
                                    <input type='checkbox' id={field.key} name={field.key} />
                                    <label htmlFor={field.key}>{field.name}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </Modal>
  )
}

export default Index