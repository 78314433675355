import { useTabs } from './TabContext'
import { FaFile } from 'react-icons/fa6'
import VersatileLoader from '../../../components/VersatileLoader';
import { BiGroup } from 'react-icons/bi';
import { useState } from 'react';
import ManageAccess from './ManageAccess';


export function FileList() {
  const { openFileInNewTab,files } = useTabs()
  const [selectedFile,setSelectedFile] = useState(null);
  const [openModal,setOpenModal] = useState(false)

  return (
    <div className="flex-1 bg-white">
      <div className="p-6 space-y-6">
        <ManageAccess open={openModal} setOpen={setOpenModal} file={selectedFile} />
        <div className="space-y-2 h-[calc(100vh-20rem)] overflow-y-auto">
          {
            files === null ?
              <VersatileLoader size='large' color='#2761CF' />
              :
              files?.map((file) => (
                <button
                  key={file.id}
                  className="w-full flex items-center justify-between p-4 hover:bg-blue-50 hover:text-[#2761d0] transition-colors rounded-md"
                  onClick={() => openFileInNewTab(file)}
                >
                  <div className="flex items-center space-x-3">
                    <FaFile className="h-5 w-5 text-blue-600" />
                    <span className="font-medium">{file.name}</span>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelectedFile(file)
                      setOpenModal(true)
                    }}
                    className="rounded-full p-2 text-gray-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    <BiGroup className="h-5 w-5" />
                    <span className="sr-only">Manage access for {file.name}</span>
                  </button>
                </button>
              ))
          }
        </div>
      </div>
    </div>
  )
}