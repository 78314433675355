import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import SoftTypography from './../SoftTypography';

import ServicableAreaApi from '../../API/ServicableAreaApi';
import SearchServicableAreaReq from '../../Requests/ServicableArea/SearchServicableAreaReq';
const Index = ({setUser,disabled=false,placeholder="Select a parent Organization",className}) => {
    const [UsersSearch, setUserSearch] = useState()

    const GetOrg = async () => {
        let searchReq = new SearchServicableAreaReq();
        // searchReq.userType = "company"
        if(UsersSearch)
        searchReq.name = UsersSearch;
        searchReq.pagingParams.pageSize = 100;
        searchReq.pagingParams.pageNumber = 1;
        let res = await ServicableAreaApi.SearchServicableArea(searchReq)
        
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.name,id:item.id })
            })
            return optionsArr;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }


    return (
        <div className={className}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
             
                <AsyncSelect
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    onChange={(e) => {setUser(e.id)}}
                    placeholder={placeholder}
                    isDisabled={disabled}
                />
            </SoftTypography>

        </div>
    )
}

export default Index