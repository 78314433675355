import { FaRegAddressCard } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";

const CompanyUserMenuItems = [
    {
        name: "Dashboard",
        key: "Dashboard",
        route: "/",
        icon: (color,size=40)=><MdDashboard size={size} color={color} />,
    },
    {
        name: "Manage Issues",
        key: 'manageIssues',
        route: '/manage-issues', 
        icon: (color,size=40)=><FaRegAddressCard size={size} color={color} />, 
        noCollapse: true
    },
    {
        name: "Manage Customers",
        key: 'manageCustomers',
        route: '/manage-customers', 
        icon: (color,size=40)=><FaRegAddressCard size={size} />, 
        noCollapse: true
    }
]

export default CompanyUserMenuItems;