import { BsPersonBadgeFill } from "react-icons/bs";
import { CiViewList } from 'react-icons/ci';
import { FaRegAddressCard, FaRegCheckSquare } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { GoOrganization } from "react-icons/go";
import { IoBugSharp } from 'react-icons/io5';
import { LuFolderCog, LuLayoutTemplate, LuSheet } from "react-icons/lu";
import { MdAccountBalance, MdDashboard, MdLeaderboard, MdOutlineBugReport, MdOutlineManageAccounts, MdOutlineReportProblem } from "react-icons/md";
import { PiMapTrifoldLight, PiSuitcaseSimpleThin } from "react-icons/pi";
import { RiContactsBookLine } from 'react-icons/ri';
import { SiAwsorganizations, SiPivotaltracker } from "react-icons/si";
import { TbMessage2Bolt, TbReportAnalytics } from "react-icons/tb";
import { TiUserAddOutline } from "react-icons/ti";
import { VscFeedback, VscOrganization, VscSettings } from 'react-icons/vsc';
import { BiSolidOffer, BiSolidSpreadsheet } from 'react-icons/bi';
import { FaCalendarDays } from "react-icons/fa6";
import { HiOutlineCreditCard } from "react-icons/hi2";
import { TfiAnnouncement } from "react-icons/tfi";
import { ViewQuiltOutlined } from "@mui/icons-material";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
const SuperAdminMenuItems = [

    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color,size=40) => <MdDashboard size={size} color={color} />
    },
    {
        name:"General Sales Report",
        key:"GeneralSalesReport",
        route:"/general-sales-reporting-page",
        icon: (color,size=40) => <TbReportAnalytics size={size} color={color} />,
        // disabled:true
    },
    {
        type: "collapse",
        name: "Contact center",
        key: 'ContactCenter', 
        route: '/contact-center', 
        icon:(color,size=40) =>  <RiContactsBookLine size={size} color={color} />, 
        noCollapse: true,
        // disabled: true
    },
    {
        name:"Master Sheet",
        key:"MasterSheet",
        route:"/master-sheet",
        icon: (color,size=40) => <BiSolidSpreadsheet size={size} color={color} />,
    },
    {
        name: "Manage Announcements",
        key: "manageAnnouncements",
        route: "/manage-announcements-board",
        icon: (color,size=40) => <TfiAnnouncement size={size} color={color} />,
    },
    {
        name: "Create User",
        key: "createUser",
        route: "/addUser",
        icon: (color,size=40) => <TiUserAddOutline size={size} color={color} />,

    },
    {
        name: "Map",
        key: "free-install-map",
        route: "/map",
        icon: (color,size=40) => <PiMapTrifoldLight size={size} color={color} />,
    },
    {
        name: "Manage Users",
        key: "manageUsers",
        route: "/searchUser",
        icon: (color,size=40) => <MdOutlineManageAccounts size={size} color={color} />,
    },
    {
        name: "Digital Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center",
        icon: (color,size=40) => <HiOutlineCreditCard size={size} color={color} />,
    },
    {
        name: "Manage Organizations",
        key: 'managOrganization', 
        route: '/organizationList', 
        icon: (color,size=40) => <SiAwsorganizations size={size} color={color} />, 
    },
    {
   
        name: "Chat",
        key: 'privatechat', 
        route: '/chats', 
        icon: (color,size=40) => <TbMessage2Bolt size={size} color={color} />, 
        disabled: true
    },
    // {
    //     name: "Create Training Module",
    //     key: 'createSellingCard', 
    //     route: '/createSellingCard', 
    //     icon: (color,size=40) => <TbFolderBolt size={size} color={color} />, 
    // },
    {
        name: "Manage Training Modules",
        key: 'manageSellingCard', 
        route: '/manage-training-modules', 
        icon: (color,size=40) => <LuFolderCog size={size} color={color} />, 
        // disabled: true
    },
    // {
    //     name: "Create Shop Item",
    //     key: 'createShopItems', 
    //     route: '/createShopItems', 
    //     icon: (color,size=40) => <MdOutlineAddShoppingCart size={size} color={color} />, 
    // },
    // {
    //     name: "Manage Shop Item",
    //     key: 'manageShopItems', 
    //     route: '/manageShopItems', 
    //     icon: (color,size=40) => <TbShoppingCartCog size={size} color={color} />, 
    // },
    // {
    //     name: "customMerchs",
    //     key: 'customMerchs', 
    //     route: '/customMerch', 
    //     icon: (color,size=40) => <TbShoppingCartBolt size={size} color={color} />, 
    // },
    {
        name: "Create Fiber Company",
        key: 'createFiberCompany',
        route: '/createFiberCompany', 
        icon: (color,size=40) => <GoOrganization size={size} color={color} />, 
    },
    {
        name: "Manage Fiber Company",
        key: 'manageFiberCompany',
        route: '/manageFiberCompany', 
        icon: (color,size=40) => <GoOrganization size={size} color={color} />, 
    },
    {
        name: "Manage Leads",
        key: 'manageLeads',
        route: '/manage-leads', 
        icon: (color,size=40) => <BsPersonBadgeFill size={size} color={color} />, 
    },
    {
        name: "Manage Customers",
        key: 'manageCustomers',
        route: '/manage-customers', 
        icon: (color,size=40)=><FaRegAddressCard size={size} color={color} />, 
        noCollapse: true
    },
    {
        name:"Manage Check Availability",
        key: 'manageCheckAvailability',
        route: '/manage-check-availability',
        icon: (color,size=40) => <FaRegCheckSquare size={size} color={color} />,
    },
    {
        name: "Manage Job Applications",
        key: 'manageJobApplications',
        route: '/manage-job-applications',
        icon: (color,size=40) => <PiSuitcaseSimpleThin  size={size} color={color} />,
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        icon: (color,size=40) => <MdOutlineBugReport size={size} color={color} />,
    },
    {
        name: "Manage Bug Tickets",
        key: 'manageBugTickets',
        route: '/manage-bug-tickets',
        icon: (color,size=40) => <IoBugSharp  size={size} color={color} />,
    },
    {
        name:"Create Commission Plan",
        key:"createComissionPlan",
        route:"/create-comission-plan",
        icon: (color,size=40) => <GiReceiveMoney size={size} color={color} />,
    },
    {
        name:"Manage Commission Plans",
        key:"manageCommissionPlans",
        route:"/manage-commission-plans",
        icon: (color,size=40) => <CiViewList size={size} color={color} />,
    },
    {
        name:"Manage Commission Trackers",
        key:"manageCommissionTrackers",
        route:"/manage-commission-trackers",
        icon: (color,size=40) => <SiPivotaltracker size={size} color={color} />,
    },
    {
        name :"Leader Board",
        key:"LeaderBoard",
        route:"/leaderboard",
        icon: (color,size=40) => <MdLeaderboard size={size} color={color} />,
        // disabled: true
    },
    {
        name:"Manage Debt Sheet",
        key:"manageDebtSheet",
        route:"/manage-debt-sheet",
        icon: (color,size=40) => <LuSheet size={size} color={color} />
    },
    {
        name:"Manage Accounting Entries",
        key:"manageAccountingEntries",
        route:"/manage-accounting-entries",
        icon: (color,size=40) => <MdAccountBalance size={size} color={color} />
    },
    {
        name:"Manage Customer Feedbacks",
        key:"manageCustomerFeedbacks",
        route:"/manage-customer-feedbacks",
        icon: (color,size=40) => <VscFeedback size={size} color={color} />
    },
    {
        name:"Manage Issue Types",
        key:"manageIssueTypes",
        route:"/manage-issue-types",
        icon: (color,size=40) => <MdOutlineReportProblem size={size} color={color} />
    },
    {
        name: "My Calendar",
        key: "MyCalendar",
        route: "/my-calendar",
        icon: (color,size=40) => <FaCalendarDays size={size} color={color} />,
    },
    {
        name: "Customer Feedback",
        key: "CustomerFeedbackAnalysis",
        route: "/customer-feedback-analysis",
        icon: (color,size=40) =>  <VscFeedback size={size} color={color} />,
    },
    {
        name: "Manage Recruit Templates",
        key: "ManageRecruitTemplates",
        route: "/manage-recruit-templates",
        icon: (color,size=80) =>  <LuLayoutTemplate size={size} color={color} />,
    },
    {
        name: "Manage Company Consultations",
        key: "ManageCompanyConsultations",
        route: "/manage-company-consultations",
        icon: (color,size=40) =>  <VscOrganization size={size} color={color} />,
    },
    {
        name:"Commission Tree",
        key:"CommissionTree",
        route:"/commission-tree",
        icon: (color,size=40) => <RiMoneyDollarBoxLine size={size} color={color} />,
    }
    // {
    //     name:"Manage Business Card",
    //     key:"manageBusinessCard",
    //     route:"/manage-business-card",
    //     icon: (color,size=40) => <VscSettings size={size} color={color} />,
    // },

];

export default SuperAdminMenuItems;
