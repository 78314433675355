import { Accordion, AccordionDetails, CircularProgress, AccordionSummary as MuiAccordionSummary } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useEffect, useState } from 'react';
import { IoMdArrowDropup } from 'react-icons/io';
import Swal from 'sweetalert2';
import ComissionApi from '../../../API/ComissionApi';
import { SearchFiltersContext, UserContext } from '../../../App';
import Layout from '../../../Layouts/Layout';
import BulkPayCommissionTrackersReq from '../../../Requests/ComissionPlan/BulkPayCommissionTrackersReq';
import StringIntReq from '../../../Requests/StringIntReq';
import './../../Shared/UserCommission/UserCommission.scss';
import { useNavigate } from 'react-router-dom';


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<IoMdArrowDropup color='black' size={20} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, 0)',
    border: "none",
    borderBottom: '1px solid black',
    boxShadow: 'none',
    flexDirection: 'row-reverse',
    padding: "0px",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const Index = () => {


    const [commissionTrackers, setCommissionTrackers] = useState(null);
    const [commissionPlan, setCommissionPlan] = useState([])
    const [sales, setSales] = useState([])
    const [loading, setLoading] = useState(true)
    const searchFilter = useContext(SearchFiltersContext)
    const navigate = useNavigate()
    const user = useContext(UserContext)
    const fetchData = async () => {
        setLoading(true)
        const req = new StringIntReq()

        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 100
        const res = await ComissionApi.GetCommissionTrackersForAllUsers(req);
        if (res?.status?.success) {
            console.log(res.data)
            const commissionPlan = []
            res.data.forEach(item => {
                if (!commissionPlan.find(item2 => item2.id === item.commissionPlanId))
                    commissionPlan.push(item.commissionPlan)
            }
            )

            console.log(commissionPlan)
            setCommissionPlan(commissionPlan)
            const sales = []
            res.data.forEach(item => {
                if (!sales.find(item2 => item2.id === item.saleId))
                    sales.push(item.sale)
            })
            console.log(sales)
            setSales(sales)
            console.log(res.data)
            const groupedData = res.data.reduce((acc, item) => {
                const commissionKey = item.commissionPlanId;
                const salesRepName = item.user.firstName + " " + item.user.lastName
                const saleKey = item.saleId;
                const weekRangeKey = item.weekRange;

                if (!acc[commissionKey]) {
                    acc[commissionKey] = {};
                }

                if (!acc[commissionKey][salesRepName]) {
                    acc[commissionKey][salesRepName] = {}
                }

                if (!acc[commissionKey][salesRepName][weekRangeKey]) {
                    acc[commissionKey][salesRepName][weekRangeKey] = {};
                }


                if (!acc[commissionKey][salesRepName][weekRangeKey][saleKey]) {
                    acc[commissionKey][salesRepName][weekRangeKey][saleKey] = [];
                }
                if (item.type !== "Deduction") {
                    acc[commissionKey][salesRepName][weekRangeKey][saleKey].push(item);
                }

                return acc;
            }, {});
            console.log(groupedData);

            setCommissionTrackers(groupedData)
            let totalSales = Object.key
            setLoading(false)
        }
    }

    useEffect(
        () => {
            fetchData()
        }, []
    )

    function commissionStatus() {

        if (!commissionTrackers) {
            return {
                totalCommission: 0,
                totalPaidCommission: 0,
                totalPendingCommission: 0,
                totalDeductions: 0
            }
        }

        let totalCommission = 0;
        let totalPaidCommission = 0;
        let totalPendingCommission = 0;
        let totalDeductions = 0;
        Object.keys(commissionTrackers).forEach(commissionPlanId => {
            // commission Plan Id

            Object.keys(commissionTrackers[commissionPlanId]).forEach(salesRepName => {
                //Sales Rep Name
                Object.keys(commissionTrackers[commissionPlanId][salesRepName]).forEach(weekRangeKey => {
                    //Week Range Key
                    Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).forEach(saleId => {
                        //Sale Id
                        commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].forEach(item => {
                            totalCommission += item.amount;

                            totalPaidCommission += item.accountingEntries.reduce((acc, entry) => {
                                return acc + entry.amount
                            }, 0)

                            totalPendingCommission += item.amount - item.accountingEntries.reduce((acc, entry) => {
                                return acc + entry.amount
                            }, 0)

                            totalDeductions += item.deductionTrackers.reduce((acc, item) => {
                                return acc + item.amount
                            }, 0)

                        })
                    })

                })
            })
        })

        return { totalCommission, totalPaidCommission, totalPendingCommission, totalDeductions }
    }

    const payBulkCommissionTracker = async (ids, dict) => {
        const selected = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "You want to pay all commission trackers",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        if (selected.isConfirmed) {
            Swal.fire({
                icon: 'info',
                title: 'Please wait',
                text: 'Processing your request',
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false,
                didOpen: async () => {
                    Swal.showLoading()
                }
            })
            const req = new BulkPayCommissionTrackersReq();
            req.commissionTrackerIds = ids;
            req.amounts = dict;
            const res = await ComissionApi.BulkPayCommissionTrackers(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Commission trackers paid successfully',
                    showConfirmButton: false,
                    showCloseButton: true,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    },
                    didClose: () => {
                        fetchData()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    showConfirmButton: true,
                    showCloseButton: true,
                    showCancelButton: false,
                })
            }
        }
    }

    const deleteDeductionTrackers = async (ids) => {
        const selected = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "You want to delete all deduction trackers",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        if (selected.isConfirmed) {
            Swal.fire({
                icon: 'info',
                title: 'Please wait',
                text: 'Processing your request',
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false,
                didOpen: async () => {
                    Swal.showLoading()
                }
            })
            const req = {
                commissionTrackerIds: ids
            }

            const res = await ComissionApi.DeleteDeductionTrackers(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Deduction trackers deleted successfully',
                    showConfirmButton: false,
                    showCloseButton: true,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    },
                    didClose: () => {
                        fetchData()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    showConfirmButton: true,
                    showCloseButton: true,
                    showCancelButton: false,
                })
            }
        }
    }


    const payAndDeleteDeductionTrackers = (ids, object, payAmount, deductionAmount) => {
        return user.userType === "SuperAdmin" &&
            <div className='flex sm:flex-row flex-col gap-4 items-center justify-center'>
                {
                    payAmount > 0 ?
                        <button className='text-sm w-full' onClick={() => {
                            payBulkCommissionTracker(ids, object)
                        }}>Pay ${payAmount}</button>
                        : <p className='paid'>Paid</p>
                }
                {
                    deductionAmount > 0 &&
                    <button className='text-sm w-full' onClick={() => {
                        deleteDeductionTrackers(ids)
                    }}>Delete Deductions</button>
                }
            </div>

    }


    function payableAmount(commissionPlanId, salesRepName, weekRangeKey, saleId) {

        if (commissionTrackers === null) {
            return 0;
        }

        if (commissionPlanId && salesRepName && weekRangeKey && saleId) {

            return commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc, item) => {
                return acc + item.amount - item.accountingEntries.reduce((acc2, entry) => {
                    return acc2 + entry.amount
                }, 0) - item.deductionTrackers.reduce((acc3, item) => {
                    return acc3 + item.amount
                }, 0)
            }, 0)

        }
        else if (commissionPlanId && salesRepName && weekRangeKey) {

            return Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc, saleId) => {
                return acc + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc2, item) => {
                    return acc2 + item.amount - item.accountingEntries.reduce((acc3, entry) => {
                        return acc3 + entry.amount
                    }, 0) - item.deductionTrackers.reduce((acc4, item) => {
                        return acc4 + item.amount
                    }, 0)
                }, 0)
            }, 0)

        }
        else if (commissionPlanId && salesRepName) {

            return Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc, weekRangeKey) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc2, saleId) => {
                    return acc2 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc3, item) => {
                        return acc3 + item.amount - item.accountingEntries.reduce((acc4, entry) => {
                            return acc4 + entry.amount
                        }, 0) - item.deductionTrackers.reduce((acc5, item) => {
                            return acc5 + item.amount
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)

        }
        else if (commissionPlanId) {

            return Object.keys(commissionTrackers[commissionPlanId]).reduce((acc, salesRepName) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + item.amount - item.accountingEntries.reduce((acc5, entry) => {
                                return acc5 + entry.amount
                            }, 0) - item.deductionTrackers.reduce((acc6, item) => {
                                return acc6 + item.amount
                            }, 0)
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
        else {

            return Object.keys(commissionTrackers).reduce((acc, salesRepName) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + item.amount - item.accountingEntries.reduce((acc5, entry) => {
                                return acc5 + entry.amount
                            }, 0) - item.deductionTrackers.reduce((acc6, item) => {
                                return acc6 + item.amount
                            }, 0)
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)

        }
    }

    function deductionAmount(commissionPlanId, salesRepName, weekRangeKey, saleId) {

        if (commissionTrackers === null) {
            return 0;
        }

        if (commissionPlanId && salesRepName && weekRangeKey && saleId) {

            return commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc, item) => {
                return acc + item.deductionTrackers.reduce((acc2, item) => {
                    return acc2 + item.amount
                }, 0)
            }, 0)
        }
        else if (commissionPlanId && salesRepName && weekRangeKey) {

            return Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc, saleId) => {
                return acc + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc2, item) => {
                    return acc2 + item.deductionTrackers.reduce((acc3, item) => {
                        return acc3 + item.amount
                    }, 0)
                }, 0)
            }, 0)
        }
        else if (commissionPlanId && salesRepName) {

            return Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc, weekRangeKey) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc2, saleId) => {
                    return acc2 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc3, item) => {
                        return acc3 + item.deductionTrackers.reduce((acc4, item) => {
                            return acc4 + item.amount
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
        else if (commissionPlanId) {

            return Object.keys(commissionTrackers[commissionPlanId]).reduce((acc, salesRepName) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + item.deductionTrackers.reduce((acc5, item) => {
                                return acc5 + item.amount
                            }, 0)
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
        else {

            return Object.keys(commissionTrackers).reduce((acc, salesRepName) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + item.deductionTrackers.reduce((acc5, item) => {
                                return acc5 + item.amount
                            }, 0)
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
    }

    function paidAmount(commissionPlanId, salesRepName, weekRangeKey, saleId) {

        if (commissionTrackers === null) {
            return 0;
        }

        if (commissionPlanId && salesRepName && weekRangeKey && saleId) {

            return commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc, item) => {
                return acc + item.accountingEntries.reduce((acc2, entry) => {
                    return acc2 + entry.amount
                }, 0)
            }, 0)
        }
        else if (commissionPlanId && salesRepName && weekRangeKey) {

            return Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc, saleId) => {
                return acc + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc2, item) => {
                    return acc2 + item.accountingEntries.reduce((acc3, entry) => {
                        return acc3 + entry.amount
                    }, 0)
                }, 0)
            }, 0)
        }
        else if (commissionPlanId && salesRepName) {

            return Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc, weekRangeKey) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc2, saleId) => {
                    return acc2 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc3, item) => {
                        return acc3 + item.accountingEntries.reduce((acc4, entry) => {
                            return acc4 + entry.amount
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
        else if (commissionPlanId) {

            return Object.keys(commissionTrackers[commissionPlanId]).reduce((acc, salesRepName) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + item.accountingEntries.reduce((acc5, entry) => {
                                return acc5 + entry.amount
                            }, 0)
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
        else {

            return Object.keys(commissionTrackers).reduce((acc, salesRepName) => {
                return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).reduce((acc3, saleId) => {
                        return acc3 + commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].reduce((acc4, item) => {
                            return acc4 + item.accountingEntries.reduce((acc5, entry) => {
                                return acc5 + entry.amount
                            }, 0)
                        }, 0)
                    }, 0)
                }, 0)
            }, 0)
        }
    }

    function goToContactCenterForSingleCustomer(customer) {
        searchFilter.current = {
            ...searchFilter.current,
            contactCenter:{
                customerName: customer.firstName + " " + customer.lastName
            }
        }

        navigate('/contact-center')   
    }

    function goToContactCenterWithWeekKey(weekRange,salesRep){
        let weekRangeSplit = weekRange.split('-')
        let fromDate = new Date(weekRangeSplit[0].trim())
        // -1 day from the from date
        fromDate = new Date(fromDate.setDate(fromDate.getDate() - 1))

        let toDate = new Date(weekRangeSplit[1].trim())

        fromDate = new Date(fromDate.setHours(0,0,0,0))
        toDate = new Date(toDate.setHours(23,59,59,999))

        searchFilter.current = {
            ...searchFilter.current,
            contactCenter:{
                fromDate : formatDateToISOString(fromDate),
                toDate : formatDateToISOString(toDate),
                type: "Completed",
                quickDateValue: "Custom",
                dateType: "InstallDate",
                salesReps : [salesRep],
            }
        }
        navigate("/contact-center")
    }

    function goToContactCenterWithRep (salesRep){
        searchFilter.current = {
            ...searchFilter.current,
            contactCenter:{
                salesReps : [salesRep],
                type:"Completed",
            }
        }
        navigate("/contact-center")
    }

    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    return (
        <Layout>
            <div className='user_copmmission'>

                <h1>All Commissions</h1>
                <div className='commission_stats' >
                    <div className='total'>
                        <h3>Total Commission</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalCommission
                            }
                        </p>
                    </div>
                    <div className='total'>
                        <h3>Paid</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalPaidCommission
                            }
                        </p>
                    </div>
                    <div className='total'>
                        <h3>Pending</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalPendingCommission
                            }
                        </p>
                    </div>
                    <div className='total'>
                        <h3>Deducted</h3>
                        <p>
                            $
                            {
                                commissionStatus().totalDeductions
                            }
                        </p>
                    </div>
                </div>
                {
                    loading ?
                        <div className='spinner' style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                            marginTop: '4rem'
                        }}>
                            <CircularProgress
                                sx={{
                                    color: "var(--color-primary)",
                                    margin: "auto"
                                }}
                            />
                        </div>
                        :
                        <div className='commission_plan'>
                            <h1>Total Customers: {sales.length}</h1>
                            {
                                commissionTrackers &&
                                Object.keys(commissionTrackers).map(commissionPlanId => {
                                    // commission Plan Id
                                    let commissionName = commissionPlan.find(item => item.id == commissionPlanId)?.name
                                    let commissionPayAmount = payableAmount(commissionPlanId)

                                    let idsInCommission = [];
                                    let commissionObject = {};
                                    let commissionDeduction = deductionAmount(commissionPlanId)
                                    console.log(commissionName)
                                    return (
                                        <Accordion key={commissionPlanId}>
                                            <AccordionSummary>
                                                <div className='flex sm:flex-row flex-col sm:items-center items-start justify-between w-full'>
                                                    <div>
                                                        <h3>{commissionName}</h3>
                                                    </div>
                                                    {
                                                        payAndDeleteDeductionTrackers(idsInCommission, commissionObject, commissionPayAmount, commissionDeduction)
                                                    }
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ marginLeft: "20px" }}>
                                                {
                                                    Object.keys(commissionTrackers[commissionPlanId]).map(salesRepName => {
                                                        // Sales Rep Name
                                                        let idsInSalesRep = [];
                                                        let salesRepObject = {};
                                                        let salesRepDeduction = deductionAmount(commissionPlanId, salesRepName)
                                                        let repPayment = payableAmount(commissionPlanId, salesRepName)
                                                        let repPaid = paidAmount(commissionPlanId, salesRepName)
                                                        let noOfRepCustomers = Object.keys(commissionTrackers[commissionPlanId][salesRepName]).reduce((acc, weekRangeKey) => {
                                                            return acc + Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).length
                                                        }, 0)
                                                        let salesRepId = null;
                                                        return (
                                                            <Accordion key={salesRepName}>
                                                                <AccordionSummary>
                                                                    <div className='flex sm:flex-row flex-col sm:items-center items-start justify-between w-full'>
                                                                        <div >
                                                                            <h3>{salesRepName}</h3>
                                                                            <p>Pending Payment : ${repPayment}</p>
                                                                            <p>Paid : ${repPaid}</p>
                                                                            <p>Customers : {noOfRepCustomers}</p>
                                                                            <p onClick={()=>{
                                                                                goToContactCenterWithRep({label:salesRepName,id:salesRepId})
                                                                            }} className='underline font-bold text-[#2761D0]'>Go to Customers</p>
                                                                        </div>
                                                                        {
                                                                            payAndDeleteDeductionTrackers(idsInSalesRep, salesRepObject, repPayment, salesRepDeduction)
                                                                        }
                                                                    </div>
                                                                </AccordionSummary>
                                                                <AccordionDetails sx={{ marginLeft: "20px" }}>
                                                                    {
                                                                        Object.keys(commissionTrackers[commissionPlanId][salesRepName]).map(weekRangeKey => {
                                                                            let noOfCustomers = Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).length
                                                                            let idsinWeekRange = []
                                                                            let weekRangeObject = {}
                                                                            let weekRangeDeduction = deductionAmount(commissionPlanId, salesRepName, weekRangeKey)
                                                                            let weekRangePayment = payableAmount(commissionPlanId, salesRepName, weekRangeKey)
                                                                        
                                                                            return (
                                                                                <Accordion key={weekRangeKey}>
                                                                                    <AccordionSummary>
                                                                                        <div className='flex sm:flex-row flex-col sm:items-center items-start justify-between sm:w-full w-fit'>
                                                                                            <div>
                                                                                                <h3>Week Range : {weekRangeKey}</h3>
                                                                                                <h3>Customers : {noOfCustomers}</h3>
                                                                                                <p onClick={()=>{
                                                                                                   goToContactCenterWithWeekKey(weekRangeKey,{
                                                                                                        id: salesRepId,
                                                                                                        label: salesRepName
                                                                                                   })
                                                                                                }} className='underline font-bold text-[#2761D0]'>Go to Customers</p>
                                                                                            </div>
                                                                                            {
                                                                                                payAndDeleteDeductionTrackers(idsinWeekRange, weekRangeObject, weekRangePayment, weekRangeDeduction)
                                                                                            }
                                                                                        </div>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails sx={{ marginLeft: "20px" }}>
                                                                                        {
                                                                                            Object.keys(commissionTrackers[commissionPlanId][salesRepName][weekRangeKey]).map(saleId => {

                                                                                                let customer = sales.find(item => item.id == saleId)
                                                                                                salesRepId = customer?.salesRepId
                                                                                                let idsInSale = [];
                                                                                                let saleObject = {};
                                                                                                let salesDeduction = deductionAmount(commissionPlanId, salesRepName, weekRangeKey, saleId)
                                                                                                let customerPayment = payableAmount(commissionPlanId, salesRepName, weekRangeKey, saleId)

                                                                                                return (
                                                                                                    <Accordion key={saleId}>
                                                                                                        <AccordionSummary>
                                                                                                            <div className='flex sm:flex-row flex-col sm:items-center items-start justify-between w-full'>
                                                                                                                <div>
                                                                                                                    <h3 onClick={()=>{
                                                                                                                        goToContactCenterForSingleCustomer(customer)
                                                                                                                    }} className='underline cursor-pointer text-[#2761D0]'>{customer?.firstName + " " + customer?.lastName}</h3>
                                                                                                                    <h3>Fiber Plan : {customer?.desc}</h3>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    payAndDeleteDeductionTrackers(idsInSale, saleObject, customerPayment, salesDeduction)
                                                                                                                }
                                                                                                            </div>
                                                                                                        </AccordionSummary>
                                                                                                        <AccordionDetails sx={{ marginLeft: "20px" }}>
                                                                                                            {
                                                                                                                commissionTrackers[commissionPlanId][salesRepName][weekRangeKey][saleId].map(item => {

                                                                                                                    let payableAmount = item.amount - item.accountingEntries.reduce((acc, item) => {
                                                                                                                        return acc + item.amount
                                                                                                                    }, 0) - item.deductionTrackers.reduce((acc, item) => {
                                                                                                                        return acc + item.amount
                                                                                                                    }, 0)

                                                                                                                    let deduction = item.deductionTrackers.reduce((acc, item) => {
                                                                                                                        return acc + item.amount
                                                                                                                    }, 0)

                                                                                                                    idsInCommission.push(item.id)
                                                                                                                    commissionObject[item.id] = payableAmount

                                                                                                                    idsInSalesRep.push(item.id)
                                                                                                                    salesRepObject[item.id] = payableAmount

                                                                                                                    idsinWeekRange.push(item.id)
                                                                                                                    weekRangeObject[item.id] = payableAmount

                                                                                                                    idsInSale.push(item.id)
                                                                                                                    saleObject[item.id] = payableAmount

                                                                                                                    return (
                                                                                                                        <div style={{
                                                                                                                            padding: "0rem",
                                                                                                                            border: "none"
                                                                                                                        }} className='commission_tracker flex sm:flex-row flex-col sm:items-center items-start justify-between w-full' key={item.id}>
                                                                                                                            <div>
                                                                                                                                <h3 >{item.type} {item.commissionScale}</h3>
                                                                                                                                <p>Amount To be Paid: ${payableAmount}</p>
                                                                                                                                <p>Amount Paid : ${item.accountingEntries.reduce(
                                                                                                                                    (acc, item) => {
                                                                                                                                        return acc + item.amount
                                                                                                                                    }, 0
                                                                                                                                )}</p>
                                                                                                                                <p>Status: {payableAmount <= 0 ? 'Paid' : 'Pending'}</p>
                                                                                                                                {
                                                                                                                                    item.deductionTrackers.map((deduction, index) => {
                                                                                                                                        return (
                                                                                                                                            <div key={index} >
                                                                                                                                                <h3 className='deduction_reason'>{deduction.deductionReason} {deduction.permanent?"(Permanent)":""}</h3>
                                                                                                                                                <p className='deduction_amount'>Amount: ${deduction.amount}-</p>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                payAndDeleteDeductionTrackers([item.id], { [item.id]: payableAmount }, payableAmount, deduction)
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </AccordionDetails>
                                                                                                    </Accordion>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            )
                                                                        })
                                                                    }
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    })
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    )

                                })
                            }
                        </div>

                }
            </div>
        </Layout>
    )
}

export default Index