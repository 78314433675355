class EditLeadReq {

    constructor() {
        this.id = null;
        this.name = null;
        this.email = null;
        this.phone = null;
        this.status = null;
        this.notes = null;
        this.assignedConvertedToCustomer = null;
        this.customerInterest = null;
        this.doorKnockingStatus = null;
        this.salesRepId = null;
        this.salesOrgId = null;
        this.leadStatusId = null;
        this.isHotLead = null;
        this.objections = null;
        this.objectionNotes = null;
        this.appointmentType = null;
        this.appointmentDateString = null;
        this.appointmentTimeString = null;
        this.appointmentDateTime = null;
    }
}

export default EditLeadReq;