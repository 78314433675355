import React, { useState } from 'react';
import Swal from 'sweetalert2';
import StringIntReq from '../../../../Requests/StringIntReq';
import MapIconApi from '../../../../API/MapIconApi';
import SoftButton from '../../../../components/SoftButton';

const LeadStatus = ({ leadStatus, SearchLeadStatuses, index }) => {
    const [editing, setEditing] = useState(false);
    const [newLeadStatus, setNewLeadStatus] = useState(leadStatus.name);

    const handleEdit = () => {
        setEditing(true);
    };

    const handleSave = async () => {
        Swal.fire({
            title: 'Creating Lead Status...',
            icon: 'info',   
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        
        if (newLeadStatus.trim() === '') {
            Swal.fire({
                icon: 'error',
                title: 'Lead Status cannot be empty',
                showConfirmButton: true,
                allowOutsideClick: true,
                timer: 1500
            })
            return;
        }

        try {
            let req = new StringIntReq();
            req.string = newLeadStatus;
            req.int = leadStatus.id;

            let res = await MapIconApi.EditLeadStatus(req);
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Lead Status Updated Successfully',
                    showConfirmButton: true,
                    timer: 1500
                })
                SearchLeadStatuses();
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: res?.status?.message,
                    showConfirmButton: true,
                    timer: 1500
                })
            }
            setEditing(false);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error Updating Lead Status',
                showConfirmButton: true,
                timer: 1500
            })
        }
    };

    const handleDelete = async () => {
        Swal.fire({
            title: 'Deleting Lead Status...',
            icon: 'info',   
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            let req = new StringIntReq();
            req.int = leadStatus.id;

            let res = await MapIconApi.DeleteLeadStatus(req);

            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Lead Status Deleted Successfully',
                    showConfirmButton: true,
                    timer: 1500
                })
                setEditing(false);
                SearchLeadStatuses();
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: res?.status?.message,
                    showConfirmButton: true,
                    timer: 1500
                })
            }
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error Deleting Lead Status',
                showConfirmButton: true,
                timer: 1500
            })
        }
    };

    return (
        <div className="LeadStatus">
            <div className="LeadStatusText">
            {editing ? (
                <input
                type="text"
                value={newLeadStatus}
                onChange={(e) => setNewLeadStatus(e.target.value)}
                />
                ) : (
                    <p>{index +1}. {leadStatus.name}</p>
                    )}
            </div>
            <div className="LeadStatusButtons">
            {editing ? (
                // <button onClick={handleSave}>Save</button>
                <SoftButton onClick={handleSave} color="success">Save</SoftButton>
                ) : (
                    // <button onClick={handleEdit}>Edit</button>
                    <SoftButton onClick={handleEdit} color="dark">Edit</SoftButton>
                    )}
            {/* <button onClick={handleDelete}>Delete</button> */}
            <SoftButton onClick={handleDelete} color="error">Delete</SoftButton>
            </div>
        </div>
    );
};

export default LeadStatus;
