import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './ReviewContainer.scss';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import Review from './Review';
// import pointer from '../images/pointer.png'
import WrittenReview from './WrittenReview';
import SpeedTestReview from './SpeedTestReview';
import VideoReview from './VideoReview';
// import SearchCustomerFeedbackReq from '../../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq';
// import CustomerFeedbackApi from '../../../../API/CustomerFeedbackApi';
import  placeholderReviews from './placeholderReviews'
import salesRepReviews from './salesRepReviews';
const ReviewContainer = ({salesRep}) => {



  const [selectedTab, setSelectedTab] = React.useState(1);
  const [selectedReview, setSelectedReview] = React.useState(salesRep?salesRepReviews[0]:placeholderReviews[0]);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
  // const [reviews, setReviews] = React.useState([])



  // async function getReviews() {
  //   const req = new SearchCustomerFeedbackReq();
  //   req.pagingParams.pageSize = 100
  //   req.pagingParams.pageNumber = 1
  //   const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req)
  //   // console.log(res)
  //   if (res?.status?.success) {

  //     setReviews(res?.data?.list)
  //     setSelectedReview(res?.data?.list[0])
  //     if(res?.data?.list[0].feedbackType==="Written"){
  //       setSelectedTab(1)
  //     }
  //     else if(res?.data?.list[0].feedbackType==="SpeedTest"){
  //       setSelectedTab(2)
  //     }
  //     else if(res?.data?.list[0].feedbackType==="Video"){
  //       setSelectedTab(3)
  //     }
  //   }
  // }

  function onSlideChange(slider) {
    // console.log(slider.activeIndex)
    setSelectedReview(placeholderReviews[slider.activeIndex])
    if(placeholderReviews[slider.activeIndex].feedbackType==="Written"){
      setSelectedTab(1)
    }
    if(placeholderReviews[slider.activeIndex].feedbackType==="SpeedTest"){
      setSelectedTab(2)
    }
    if(placeholderReviews[slider.activeIndex].feedbackType==="Video"){
      setSelectedTab(3)
    }
  }

  function onSlideChange2(slider) {
    // console.log(slider.activeIndex)
    setSelectedReview(salesRepReviews[slider.activeIndex])
    if(salesRepReviews[slider.activeIndex].feedbackType==="Written"){
      setSelectedTab(1)
    }
    if(salesRepReviews[slider.activeIndex].feedbackType==="SpeedTest"){
      setSelectedTab(2)
    }
    if(salesRepReviews[slider.activeIndex].feedbackType==="Video"){
      setSelectedTab(3)
    }
  }

  // React.useEffect(() => {
  //   getReviews()
  // }, [])


  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener('resize', () => {
        setScreenWidth(window.innerWidth)
      })
    }
  }
    , [])

  return (
    <>
      <div className='container_for_reviews'>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={false}
          slidesPerView={screenWidth > 1000 ? 3 : 1}
          coverflowEffect={{
            rotate: 1,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false
          }}
          spaceBetween={screenWidth > 1000 ? 150 : 0}
          //  pagination={{ el: '.swiper-pagination', clickable: true }}
          // navigation={{
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          //   clickable: true,
          // }}
          navigation={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
          style={{
            WebkitOverflowScrolling: 'touch',
            WebkitTransformStyle: 'preserve-3d',
            WebkitPerspective: '1000px',
            WebkitTransform: 'translate3d(0,0,0)',
          }}
          onSlideChange={(slider) => {salesRep?onSlideChange2(slider): onSlideChange(slider) }}
        >
          {
            salesRep?
            salesRepReviews.map((review, index) => {
              return (
                <SwiperSlide key={index}>
                  <Review index={index} user={review} />
                </SwiperSlide>
              )
            })
            :
            placeholderReviews.map((review, index) => {
              return (
                <SwiperSlide key={index}>
                  <Review index={index} user={review} />
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>

      {salesRep && salesRepReviews && salesRepReviews.length>0 && <div className='review_preview'>
        {/* <div className='review_buttons'>
          <button className={`review_button ${selectedTab === 1 && 'active'}`}>Written Testimonial
            {selectedTab === 1  && <img src={pointer} alt='pointer' className='pointer' />}
          </button>
          <button className={`review_button ${selectedTab === 2 && 'active'}`}>Speed Test
            {selectedTab === 2 && <img src={pointer} alt='pointer' className='pointer' />}
          </button>
          <button className={`review_button ${selectedTab === 3 && 'active'}`}>Video Testimonial
            {selectedTab === 3 && <img src={pointer} alt='pointer' className='pointer' />}
          </button>      
        </div> */}
        <div className='blue_background'> 

        </div>
       <div style={{
          padding: selectedTab === 3 ? '0' : null
        }} className='preview'>
          {
            selectedTab === 1 && selectedReview && selectedReview.feedbackType==="Written" && <WrittenReview review={selectedReview} />
          }
          {
            selectedTab === 2 &&  selectedReview && selectedReview.feedbackType==="SpeedTest" &&  <SpeedTestReview review={selectedReview} />
          }
          {
            selectedTab === 3 &&  selectedReview && selectedReview.feedbackType==="Video" &&  <VideoReview review={selectedReview}  />
          }
        </div>
      </div>}

     {!salesRep && placeholderReviews && placeholderReviews.length>0 && <div className='review_preview'>
        {/* <div className='review_buttons'>
          <button className={`review_button ${selectedTab === 1 && 'active'}`}>Written Testimonial
            {selectedTab === 1  && <img src={pointer} alt='pointer' className='pointer' />}
          </button>
          <button className={`review_button ${selectedTab === 2 && 'active'}`}>Speed Test
            {selectedTab === 2 && <img src={pointer} alt='pointer' className='pointer' />}
          </button>
          <button className={`review_button ${selectedTab === 3 && 'active'}`}>Video Testimonial
            {selectedTab === 3 && <img src={pointer} alt='pointer' className='pointer' />}
          </button>      
        </div> */}
        <div className='blue_background'> 

        </div>
       <div style={{
          padding: selectedTab === 3 ? '0' : null
        }} className='preview'>
          {
            selectedTab === 1 && selectedReview && selectedReview.feedbackType==="Written" && <WrittenReview review={selectedReview} />
          }
          {
            selectedTab === 2 &&  selectedReview && selectedReview.feedbackType==="SpeedTest" &&  <SpeedTestReview review={selectedReview} />
          }
          {
            selectedTab === 3 &&  selectedReview && selectedReview.feedbackType==="Video" &&  <VideoReview review={selectedReview}  />
          }
        </div>
      </div>}
    </>
  )
}

export default ReviewContainer