class EditCandidateReq {
    constructor() {
        this.id = null;
        this.email = null;
        this.name = null;
        this.country = null;
        this.state = null;
        this.zipCode = null;
        this.city = null;
        this.address = null;
        this.notes = null;
        this.phone = null;
        this.appointmentDateTime = null;
        this.mapIconId = null;
        this.coordinate = null;
        this.salesRepId = null;
    }
}

export default EditCandidateReq;