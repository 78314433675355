import React, { useContext, useEffect } from 'react'
import BizCard from './BizCard'
import { MapContext } from '../..'
import { UserContext } from '../../../../../App'
import { MdOutlineTextsms, MdEmail, MdOutlineContentCopy } from 'react-icons/md'
import { FaWhatsapp } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { IoMdArrowRoundBack } from 'react-icons/io'
import DigitalBusinessCenter from "../../../../../Views/Reps/DigitalBusinessCenter";
const BizCardTab = ({ lead }) => {

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [])


    const map = useContext(MapContext);
    const user = useContext(UserContext);
    console.log(lead)
    const sendCard = async (method) => {
        let message = 'Hi, I am ' + user.firstName + ' ' + user.lastName + '. I am sending you my digital business card. You can view it by clicking the link below. ' + window.location.origin + "/digital-business-center/" + user.id;
        if (method === 'whatsapp') {
            if (lead.phone !== "No Phone") {
                window.open("https://wa.me/" + lead.phone + "?text=" + message)
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'No Phone Number',
                    text: 'This lead does not have a phone number',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            
            }
        }
        else if (method === 'sms') {
            if(lead.phone !== "No Phone"){
            window.location.href = "sms:" + lead.phone + "?body=" + message
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'No Phone Number',
                    text: 'This lead does not have a phone number',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            }
        }
        else if (method === 'email') {
            if(lead.email !== "No Email"){
            window.location.href = "mailto:" + lead.email + "?subject=Digital Business Card&body=" + message
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'No Email',
                    text: 'This lead does not have an email',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            }
        }
    }

    const copyCardLink = () => {
        navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + user.id)
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Link Copied to Clipboard',
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    return (
        <div className='biz_card_tab'>
                <div className='dissappearOnMobile'>
                    <IoMdArrowRoundBack style={{ cursor: "pointer" }} color='black' size={30} onClick={() => map.setIndex(10)} />
                </div>
            <h1 style={{
                textAlign: 'center',
                marginBottom: "1rem",
                fontWeight: 'bold',
                fontSize: '1.5rem'
            }}>Digital Business Card</h1>
            <DigitalBusinessCenter showLayout={false}  isTab={true}/>
            {/* <h3 style={{
                textAlign: 'center',
                marginTop: "1rem",
                marginBottom: "1rem",
                fontWeight: 'bold',
                fontSize: '1.2rem'
            }}>Send Card</h3>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: "1rem",
                placeItems: "center",
                width: "100%",
            }}>
                <button onClick={() => sendCard('sms')}><MdOutlineTextsms className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
                <button onClick={() => sendCard('whatsapp')}><FaWhatsapp className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
                <button onClick={() => sendCard('email')}><MdEmail className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
                <button onClick={() => copyCardLink()}><MdOutlineContentCopy className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
            </div> */}
        </div>
    )
}

export default BizCardTab