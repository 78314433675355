import { CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../../App'
import Datatable from '../../../components/Datatable'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
import ComissionApi from '../../../API/ComissionApi'
import SearchDebtSheetReq from '../../../Requests/ComissionPlan/SearchDebtSheetReq'
import StringIntReq from '../../../Requests/StringIntReq'
import Swal from 'sweetalert2'
import QuickDate from '../../../components/QuickDate'
const Index = () => {

    const user = useContext(UserContext)
    // const [name, setName] = React.useState(null)
    // const [email, setEmail] = React.useState(null)
    // const [phone, setPhone] = React.useState(null)
    // const [address, setAddress] = React.useState(null)
    const [amountFrom, setAmountFrom] = React.useState(null)
    const [amountTo, setAmountTo] = React.useState(null)
    const [timeStampFrom, setTimeStampFrom] = React.useState(null)
    const [timeStampTo, setTimeStampTo] = React.useState(null)
    const [debtSheet, setDebtSheet] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(1)



    const searchDebtSheet = () => {
        setLoading(true)
        const req = new SearchDebtSheetReq()
        req.amountFrom = amountFrom
        req.amountTo = amountTo
        req.timeStampFrom = timeStampFrom
        req.timeStampTo = timeStampTo
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = 20
        ComissionApi.SearchDebtSheet(req).then(
            (res) => {
                
                const l = []
                res?.data?.list.map(
                    (item) => {
                        if (item?.lead?.id)
                            l.push({ ...item.lead, pay: <p onClick={()=>{
                                payDebtEntry(item.id)
                            }} className='text-[#2761D0] underline cursor-pointer' >Pay</p> })
                    }

                )
                console.log(res.data)
                setTotalPages(res?.data?.totalPages)
                setDebtSheet(res?.data?.list)
                setLoading(false)
            }
        )
    }

    const payDebtEntry = async (id) =>{
        Swal.fire({
            icon:"info",
            title:"Processing Payment",
            text:"Please wait while we process your payment",
            showConfirmButton:false,
            showCancelButton:false,
            showCloseButton:false,
            showDenyButton:false,
            showLoaderOnConfirm:true,
            allowOutsideClick:false,
            didOpen: ()=>{
                Swal.showLoading()
            }
        })

        const req = new StringIntReq();
        req.int = id;
        const res = await ComissionApi.PayDebtSheetEntry(req);
        if(res?.status?.success){
            Swal.fire({
                icon:"success",
                title:"Payment Successful",
                text:"Payment has been successfully processed",
                showConfirmButton:true,
                showCancelButton:false,
                showCloseButton:false,
                showDenyButton:false,
                showLoaderOnConfirm:false,
                allowOutsideClick:true,
                didOpen: ()=>{
                    Swal.hideLoading()
                }
            })
            searchDebtSheet();
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Payment Failed",
                text:"Payment has been failed",
                showConfirmButton:true,
                showCancelButton:false,
                showCloseButton:false,
                showDenyButton:false,
                showLoaderOnConfirm:false,
                allowOutsideClick:true,
                didOpen: ()=>{
                    Swal.hideLoading()
                }
            })
        }
    }


    const headers = [
        {
            name: "Debt Entry ID",
            key: "id"
        },
        {
            name: "Amount",
            key: "amount"
        },
        {
            name: "Type",
            key: "type"
        },
        {
            name: "Commission Tracker Id",
            key: "commissionTrackerId"
        },
        {
            name: "Accounting Entry Id",
            key: "accountingEntryId"
        },
        {
            name: "User Id",
            key: "userId"
        },
        {
            name: "Paid",
            key: "paid"
        },
        {
            name: "Timestamp",
            key: "timeStamp"
        },
        {
            name:"Pay",
            key:"pay"
        }
    ]

    useEffect(
        () => {
            searchDebtSheet()
        }, [currentPage]
    )

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchDebtSheet();
        }
    };

    return (
        <Layout>
            <div className='manageLeadsContainer' >
                <div className='manageLeadsHeader'>
                    <h1 className='manageLeadsTitle'>Manage debtSheet</h1>
                    <div className='searchFiltersInput' >
                        <input value={amountFrom} onKeyDown={handleKeyPress} onChange={(e) => { setAmountFrom(e.target.value) }} placeholder='Amount From' />
                        <input value={amountTo} onKeyDown={handleKeyPress} onChange={(e) => { setAmountTo(e.target.value) }} placeholder='Amount To' />
                        <input value={timeStampFrom} onKeyDown={handleKeyPress} onChange={(e) => setTimeStampFrom(e.target.value)} placeholder='Timestamp From' type='date' />
                        <input value={timeStampTo} onKeyDown={handleKeyPress} onChange={(e) => setTimeStampTo(e.target.value)} placeholder='Timestamp To' type='date' />
                        <QuickDate placeholder={'Quick Date'} className={'single'} setFrom={setTimeStampFrom} setTo={setTimeStampTo} />
                    </div>
                    <button onClick={searchDebtSheet} >Search</button>
                </div>
                {
                    loading ?
                        <div className='spinner'>
                            <CircularProgress sx={{color:"var(--color-icon)"}} />
                        </div>
                        :
                        <>
                            <Datatable headers={headers} list={debtSheet} currentPage={currentPage} totalPages={totalPages} />
                        </>
                }
                <div className='pagination'>
                    <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                </div>
            </div>
        </Layout>
    )
}

export default Index