class SearchSellingCardReq {
    constructor() {
      this.name = null;
      this.description = null;
      this.salesOrgId = null;
      this.mySalesOrgId = null;
      this.pagingParams = {
        "pageNumber":1,
        "pageSize":100
    }
    }
  }
  export default SearchSellingCardReq