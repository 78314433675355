import React, { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import { IoCloseCircle } from 'react-icons/io5'
import Swal from 'sweetalert2'
import FiberHouseApi from '../../../../API/FiberHouseApi'
import UpdateLeadAppointmentReq from '../../../../Requests/Customer/UpdateLeadAppointmentReq'
import authorized from './../../Offer/components/authorized.png'
import authorizedFastwyre from './../../Offer/components/authorizedFastwyre.png'
const AppointmentForm = ({ fiberCompanyId,color, open, setOpen, lead }) => {

    const [appointmentDateTime, setAppointmentDateTime] = useState(lead?.appointmentDateTime)

    const generateICalEvent = (appointmentDateTime) => {
        const start = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d+/g, '');
        const end = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d+/g, ''); // Adding 1 hour to end time
        const icsContent = `
        BEGIN:VCALENDAR
        VERSION:2.0
        BEGIN:VEVENT
        SUMMARY:Consultation
        DTSTART:${start}
        DTEND:${end}
        DESCRIPTION:Appointment Details
        LOCATION:Appointment Location
        END:VEVENT
        END:VCALENDAR
    `;
    
        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'appointment.ics';
        link.click();
    
        Swal.fire('iCalendar Event Generated!');
    };

    const addGoogleCalendarEvent = (appointmentDateTime) => {
        const base = 'https://calendar.google.com/calendar/r/eventedit?';
        
        const startTime = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d\d\d/g, '');
        const endTime = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');
    
        // Creating URL parameters
        const params = new URLSearchParams({
            text: `Consultation`,  
            dates: `${startTime}/${endTime}`,  
        });
    
    
        return `${base}${params.toString()}`;
    };
    
    
    const handleAddToCalendar = (appointmentDateTime) => {
        const googleCalendarUrl = addGoogleCalendarEvent(appointmentDateTime);
        window.open(googleCalendarUrl, '_blank', 'noopener,noreferrer'); 
    };
    
    
    const promptAddToCalendar = async (appointmentDateTime) => {
        const { value } = await Swal.fire({
            title: 'Add Appointment to Calendar',
            text: 'Would you like to add this appointment to Google Calendar or iCalendar?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Google Calendar',
            denyButtonText: 'iCalendar',
            cancelButtonText: 'No, thanks',
        });
    
        if (value === true) {
            // User chose Google Calendar
            handleAddToCalendar(appointmentDateTime);
        } else if (value === false) {
            // User chose iCalendar
            generateICalEvent(appointmentDateTime);
        }
    };

    const updateAppointmentDate = async () => {
        setOpen(false)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Appointment Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })
        const req = new UpdateLeadAppointmentReq();
        req.leadId = lead.id;
        req.appointmentDateTime = appointmentDateTime;
        req.appointmentType = null;
        const res = await FiberHouseApi.CreateConsultation(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Appointment Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                willClose: () => {
                    Swal.hideLoading()
                }
            })

            promptAddToCalendar(appointmentDateTime);

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }


    return (
        <Modal setOpen={setOpen} open={open} onClose={() => {
            setOpen(false)
        }}>
            <div className='custom_modal'>
                <IoCloseCircle onClick={() => {
                    setOpen(false)
                }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color={"#2761d0"} />
                {
                    fiberCompanyId === 9 && <img alt="Logo" src={authorizedFastwyre} className='logo' />
                }
                {
                    fiberCompanyId === 2 && <img alt="Logo" src={authorized} className='logo' />
                }
                <h1 >Consultation Date</h1>
                <input type="datetime-local" value={appointmentDateTime} onChange={(e) => {
                    setAppointmentDateTime(e.target.value)
                }} />
                <button
                    onClick={() => {
                        updateAppointmentDate()
                    }
                    }>Schedule</button>
            </div>
        </Modal>
    )
}

export default AppointmentForm