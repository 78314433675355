import React from 'react'
import { useContext, useState } from 'react'
import { UserContext } from '../../../../App'
import EditUserReq from '../../../../Requests/Users/EditUserReq'
import UsersApi from '../../../../API/UsersApi'
import Swal from 'sweetalert2'
import { MdEdit, MdSave } from 'react-icons/md'
const ProfileNameAndBio = () => {

    const user = useContext(UserContext)
    const [editMode, setEditMode] = useState(false)
    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)
    const [newBio, setNewBio] = useState(user.bio?user.bio:"")



    const updateNameAndBio = async () => {
        if(user.firstName === firstName && user.lastName === lastName && user.bio === newBio){
            return;
        }
        Swal.fire({
            icon: 'info',
            title: 'Updating Name and Bio',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const req = new EditUserReq();
        req.firstName = firstName
        req.lastName = lastName
        req.bio = newBio
        req.userId = user.id
        const res = await UsersApi.EditUser(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Updated',
                text: 'Name and Bio Updated Successfully',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            setEditMode(false)
            window.location.reload()
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setNewBio(user.bio)
        }
    }



    return (
        <div className='profileData' >
                    <div className='edit_buttons'>
                {
                    editMode ?
                        null :
                        <button onClick={() => setEditMode(true)}>Edit</button>
                }
                {
                    editMode ? <button onClick={() => {
                        setFirstName(user.firstName)
                        setLastName(user.lastName)

                        setEditMode(false)
                    }} className='cancel'>Cancel</button> : null
                }
                {
                    editMode ? <button onClick={() => {updateNameAndBio()}}>Save</button> : null
                }
            </div>
            <p className='profileDisplayName' >Display Name</p>
            <div className='profileName' >
                {
                    editMode ?
                        <div style={{
                            display:"flex",
                            gap:"1rem",
                        }}>
                            <input placeholder='First Name' onChange={(e) => setFirstName(e.target.value)} value={firstName} type='text' />
                            <input placeholder='Last Name' onChange={(e) => setLastName(e.target.value)} value={lastName} type='text' />
                        </div>    
                    :
                        <h1>{user.firstName + " " + user.lastName}</h1>
                }
              
            </div>
            <div className='profileBio'>
                <p className='bioHeading' >Bio</p>
                <div>
                    {
                        editMode ?
                            <textarea rows={4} onChange={(e) => setNewBio(e.target.value)} value={newBio} ></textarea> :
                            <p >
                                {user.bio}
                            </p>
                    }
                </div>

            </div>
        </div>
    )
}

export default ProfileNameAndBio