import React, { useEffect, useRef, useState } from 'react'
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import OSM from 'ol/source/OSM';
import 'ol/ol.css';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import MapComponent from '@terrestris/react-geo/dist/Map/MapComponent/MapComponent';
import MapContext from '@terrestris/react-geo/dist/Context/MapContext/MapContext';


import { fromLonLat } from 'ol/proj';
import SatelliteLayer from '../../TeamAdmin/MapPage/components/SatelliteLayer';
import { IoArrowBack, IoPersonSharp } from 'react-icons/io5';
import { GrInstall } from 'react-icons/gr';
import { MdCancelPresentation, MdOutlineCancel, MdOutlineReviews } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
const Details = ({ customer, setEdit }) => {

    console.log(customer)

    const position = [customer?.lead?.fiberHouse?.coordinates?.longitude, customer?.lead?.fiberHouse?.coordinates?.latitude];
    const [map, setMap] = useState(null);
    const navigate = useNavigate();
    const [satelliteMode, setSatelliteMode] = useState(false);
    useEffect(() => {
        const marker = new Feature({
            geometry: new Point(fromLonLat(position))
        });


        marker.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 10,
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: '#3399CC',
                    }),
                }),
            })
        );

        setMap(
            new Map({
                target: 'map',
                layers: [
                    new TileLayer({
                        source: new OSM()
                    }),
                    new VectorLayer({
                        source: new VectorSource({
                            features: [marker]
                        }),
                        zIndex: 9999
                    })
                ],
                view: new View({
                    center: fromLonLat(position),
                    zoom: 20
                })
            })
        )

        return () => {
            if (map) {
                map.setTarget(undefined);
            }
        };
    }, []);

    const getReasonForCancellation = (cancellationForms) => {
        let sort = cancellationForms.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
        if (sort.length > 0) {
            if (sort[0].payLess) {
                return 'Paying Less : ' + sort[0].payLessSubAnswer;
            }
            else if (sort[0].bundledService) {
                return 'Bundled Service : ' + sort[0].bundledServiceSubAnswer;
            }
            else if (sort[0].inContract) {
                return 'In Contract : ' + sort[0].inContractSubAnswer;
            }
            else if (sort[0].other) {
                return 'Other : ' + sort[0].otherSubAnswer;
            }
            else if (sort[0].spouse) {
                return 'Spouse is not interested.'
            }
            else if (sort[0].movingSoon) {
                return 'Moving Soon.'
            }
            else if (sort[0].tooBudy) {
                return 'Too Busy.'
            }

        }
    }

    return (
        <div className='CustomerDetailsPage'>
            <div className="TitleDiv">
                <IoArrowBack size={30} onClick={() => {
                    navigate(-1)
                }} style={{cursor:"pointer"}} color='black' />
                <h1>Customer Details</h1>
                {/* <button onClick={() => setEdit(true)} className="EditButton">Edit</button> */}
            </div>
            <div className='DetailsCard'>
                <div className='DetailsCardTitle'>
                    <IoPersonSharp size={30} color='black' />
                    <h2>Personal Information</h2>
                </div>
                <div className='DetailsDiv'>
                    <div className='DetailsDivElement'>
                        <p>Name</p>
                        <span>{customer.firstName} {customer.lastName}</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Address</p>
                        <span>{customer.address}</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Email</p>
                        <span>{
                            customer.email ?
                                customer.email
                                : "No Email"
                        }</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Phone</p>
                        <span>{
                            customer.cellPhone ?
                                customer.cellPhone
                                : "No Phone"
                        }</span>
                    </div>


                </div>
            </div>
            <div className='DetailsCard'>
                <div className='DetailsCardTitle'>
                    <GrInstall size={30} color='black' />
                    <h2>Install Information</h2>
                </div>
                <div className='DetailsDiv'>
                    <div className='DetailsDivElement'>
                        <p>Sales Rep</p>
                        <span>{customer.salesRep.firstName} {customer.salesRep.lastName}</span>
                    </div>

                    <div className='DetailsDivElement'>
                        <p>Sold At</p>
                        <span>{new Date(customer.timeStamp).toLocaleDateString(
                            'en-US',
                            {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',

                            }
                        )}</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Account Status:</p>
                        <span>{customer.accountStatus}</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Install Date </p>
                        <span>{
                            customer.installDateTime ?
                                new Date(customer.installDateTime).toLocaleDateString(
                                    'en-US',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',

                                    }
                                )
                                : "Not Scheduled"
                        }</span>
                    </div>

                    <div className='DetailsDivElement'>
                        <p>Internet Plan </p>
                        <span>{
                            customer.fiberPlan ?
                                customer.fiberPlan.description
                                : "No Fiber Plan"
                        }</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Original Install Date</p>
                        <span>{
                            customer.originallInstallDateTimeFrom ?
                                new Date(customer.originallInstallDateTimeFrom).toLocaleDateString(
                                    'en-US',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',

                                    }
                                )
                                : "Not Scheduled"
                        }</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Original Install Time</p>
                        <span>{
                            customer.originallInstallDateTimeFrom ?
                                new Date(customer.originallInstallDateTimeFrom).toLocaleTimeString(
                                    'en-US',
                                    {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                    }
                                )
                                : "Not Scheduled"
                        }
                            -
                            {
                                customer.originalIntstallDateTimeTo ?
                                    new Date(customer.originalIntstallDateTimeTo).toLocaleTimeString(
                                        'en-US',
                                        {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        }
                                    )
                                    : ""}
                        </span>
                    </div>

                    <div className='DetailsDivElement'>
                        <p>Updated Install Date</p>
                        <span>{
                            customer.updatedInstallDateTimeFrom ?
                                new Date(customer.updatedInstallDateTimeFrom).toLocaleDateString(
                                    'en-US',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',

                                    }
                                )
                                : "Not Scheduled"
                        }</span>
                    </div>

                    <div className='DetailsDivElement'>
                        <p>Updated Install Time</p>
                        <span>{
                            customer.updatedInstallDateTimeFrom ?
                                new Date(customer.updatedInstallDateTimeFrom).toLocaleTimeString(
                                    'en-US',
                                    {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                    }
                                )
                                : "Not Scheduled"
                        }
                            -
                            {
                                customer.updatedInstallDateTimeTo ?
                                    new Date(customer.updatedInstallDateTimeTo).toLocaleTimeString(
                                        'en-US',
                                        {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        }
                                    )
                                    : ""}
                        </span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Resason For Cancellation</p>
                        <span>
                            {
                                customer?.cancellationForms?.length > 0 ?
                                    getReasonForCancellation(customer.cancellationForms)
                                    :
                                    "No Reason"
                            }</span>
                    </div>




                </div>
            </div>
            <div className='DetailsCard'>
                <div className='DetailsCardTitle'>
                    <MdCancelPresentation size={30} color='black' />
                    <h2>Cancel Issue</h2>
                </div>
                {


                    customer?.cancelationForms?.length > 0 ?
                        <div className='DetailsDiv'>
                            <div className='DetailsDivElement'>
                                <p>Reason For Cancellation</p>
                                <span>{getReasonForCancellation(customer.cancelationForms)}</span>
                            </div>
                            <div className='DetailsDivElement'>
                                <p>Issue Time Stamp</p>
                                <span>{new Date(customer.cancelationForms[0].timestamp).toLocaleDateString(
                                    'en-US',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',

                                    }
                                )}</span>
                            </div>

                            <div className='DetailsDivElement'>
                                <p>Issue Resolved</p>
                                <span>{customer.cancelationForms[0].isResolved ?
                                    "Yes"
                                    : "No"
                                }</span>
                            </div>


                        </div>
                        :
                        <div className='DetailsDiv'>
                            <div className='DetailsDivElement'>
                                <p>No Issue</p>
                            </div>
                        </div>
                }

            </div>

            <div className='DetailsCard'>
                <div className='DetailsCardTitle'>
                    <MdOutlineReviews size={30} color='black' />
                    <h2>Reviews</h2>
                </div>
                <div className='DetailsDiv'>
                    <div className='DetailsDivElement'>
                        <p>No. Of Reviews</p>
                        <span>{
                            customer.feedbacks?.length > 0 ?
                                customer.feedbacks.length
                                : "No Reviews"
                        }</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Rating</p>
                        <span>{
                            customer.feedbacks?.length > 0 ?
                                customer.feedbacks.reduce((a, b) => a + b.rating, 0) / customer.feedbacks.length
                                : "No Rating"
                        }</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Written Reviews</p>
                        <span>{
                            customer?.feedbacks?.filter(f => f.feedbackType === "Written").length > 0 ?
                                customer.feedbacks.filter(f => f.feedbackType === "Written").length
                                : "No Written Reviews"
                        }</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Video Reviews</p>
                        <span>{
                            customer?.feedbacks?.filter(f => f.feedbackType === "Video").length > 0 ?
                                customer.feedbacks.filter(f => f.feedbackType === "Video").length
                                : "No Video Reviews"
                        }</span>
                    </div>
                    <div className='DetailsDivElement'>
                        <p>Speed Test</p>
                        <span>{
                            customer?.feedbacks?.filter(f => f.feedbackType === "SpeedTest").length > 0 ?
                                customer.feedbacks.filter(f => f.feedbackType === "SpeedTest").length
                                : "No Speed Test"
                        }</span>
                    </div>
                </div>
            </div>

            {/* {
                customer?.offer &&
                <div className='DetailsCard'>
                    <div className='DetailsCardTitle'>
                        <h2>Offer</h2>
                    </div>
                    <div className='DetailsDiv'>
                        <div className='DetailsDivElement'>
                            <p>Offer Type:</p>
                            <span>{customer.offer?.offerType}</span>
                        </div>
                        <div className='DetailsDivElement'>
                            <p>Offer Status:</p>
                            <span>{customer.offer?.oferStatus}</span>
                        </div>
                        <div className='DetailsDivElement'>
                            <p>Free Install:</p>
                            <span>{customer.offer?.freeInstall ? "Yes" : "No"}</span>
                        </div>
                        <div className='DetailsDivElement'>
                            <p>Free Offer:</p>
                            <span>{customer.offer?.freeOffer ? "Yes" : "No"}</span>
                        </div>
                        <div className='DetailsDivElement'>
                            <p>Fiber Type:</p>
                            <span>{customer.offer?.fiberType}</span>
                        </div>
                        <div className='DetailsDivElement'>
                            <p>Offer Accepted:</p>
                            <span>{customer.offer?.accepted ? "Yes" : "No"}</span>
                        </div>
                    </div>
                </div>
            } */}

        </div>

    )
}

export default Details