import React from "react";
import _ from "lodash";
import { Tree, TreeNode } from "react-organizational-chart";
import Organization from "./Organization";

function Node({ o, collapsed = false, getOrgTree }) {
    const [isCollapsed, setIsCollapsed] = React.useState(collapsed);
  
    const handleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };
  
    const T = o?.parentOrgId
      ? TreeNode
      : (props) => (
        <Tree
          {...props}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
        >
          {props.children}
        </Tree>
      );
  
    return isCollapsed ? (
      <T
        label={
          <Organization
            org={o}
            getOrgTree={getOrgTree}
            onCollapse={handleCollapse}
            collapsed={isCollapsed}
          />
        }
      />
    ) : (
      <T
        label={
          <Organization
            org={o}
            getOrgTree={getOrgTree}
            onCollapse={handleCollapse}
            collapsed={isCollapsed}
          />
        }
      >
        {_.map(o.childOrgs, (c) => (
          <Node getOrgTree={getOrgTree} o={c} parent={o} />
        ))}
      </T>
    );
  }

  export default Node;