import { Camera } from '@mui/icons-material'
import { Pagination } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import FiberCompanyApi from '../../../API/FiberCompanyApi'
import FiberPlanApi from '../../../API/FiberPlanApi'
import Sidebar from '../../../Layouts/Sidebar'
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq'
import DeleteFiberPlanReq from '../../../Requests/FiberPlan/DeleteFiberPlanReq'
import SearchFiberPlanReq from '../../../Requests/FiberPlan/SearchFiberPlansReq'
import Button from '../../../components/Button'
import Datatable from '../../../components/Datatable'
import ServicableAreaSelector from '../../../components/ServicableAreaSelector'
import SoftButton from '../../../components/SoftButton'
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import Modal from "./../../../components/Modal"
import EditCompanyFormReq from '../../../Requests/FiberCompany/EditFiberCompanyForm'
import { IoArrowBack } from 'react-icons/io5'
import CreateOfferTemplateModal from './CreateOfferTemplateModal'
import AddFiberPlanToOfferTemplate from './AddFiberPlanToOfferTemplate'
import OrganizationSelector from '../../../components/OrganizationSelector';
import FiberCompanySalesOrgReq from '../../../Requests/Organizations/FiberCompanySalesOrgReq'
import StringIntReq from '../../../Requests/StringIntReq'
import FiberCompanySalesOrgs from './FiberCompanySalesOrgs'
import FiberCompanySalesReps from './FiberCompanySalesReps'
const Index = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [servicableAreaId, setServicableAreaId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [company, setCompany] = useState(null);
    const location = useLocation();
    const [fiberPlans, setFiberPlans] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [editToggle, setEditToggle] = useState(false)
    const [reload, setReload] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [editForm, setEditForm] = useState(false);
    const [formData, setFormData] = useState(new EditCompanyFormReq());
    const [openModal, setOpenModal] = useState(false);
    const [offerTemplate, setOfferTemplate] = useState(null);
    const [reloadOfferTemplate, setReloadOfferTemplate] = useState(false);
    const [orgIds, setOrgIds] = useState([]);
    const [orgWithName, setOrgWithName] = useState(null);
    const navigate = useNavigate()
    const salesRepsRef = useRef(null);


    const handleSalesOrgAction = () => {
        if (salesRepsRef.current) {
            salesRepsRef.current.refreshSalesReps();
        }
    };
    useEffect(() => {
        if (location.state) {
            setName(location.state.name);
            setDescription(location.state.description);
            setFile(location.state.file);
            setServicableAreaId(location.state.servicableAreaId);
            setCompanyId(location.state.id);
            const req = new SearchFiberCompanyReq();
            req.id = location.state.id;
            FiberCompanyApi.SearchFiberCompany(req).then(res => {
                if (res?.status?.success) {
                    setPageLoading(false)
                    setCompany(res?.data?.list[0]);
                    console.log(res?.data?.list[0])
                }
            })

        }
    }, [location, reload]);




    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };





    const headers = [
        { name: 'Name', key: 'name' },
        { name: 'Description', key: 'description' },
        { name: 'Price', key: 'price' },
        { name: 'Speed', key: 'speed' },
        { name: 'Actions', key: 'actions' }
    ]

    const deletePlan = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this plan!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'

        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please Wait !',
                    html: 'Plan is being deleted.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                const req = new DeleteFiberPlanReq()
                req.int = id
                FiberPlanApi.DeleteFiberPlan(req).then(res => {
                    if (res?.status?.success) {
                        Swal.fire(
                            'Deleted!',
                            'Plan has been deleted.',
                            'success'
                        )
                        searchFiberPlans()
                    }
                    else {
                        Swal.fire(
                            'Error!',
                            'Something went wrong.',
                            'error'
                        )
                    }
                })
            }
        })
    }

    const editPlan = (plan) => {
        navigate('/manageFiberCompany/details/editFiberPlan', {
            state: {
                id: plan.id,
                name: plan.name,
                description: plan.description,
                price: plan.price,
                speed: plan.speed,
                fiberCompanyId: plan.fiberCompanyId,
            }
        })
    }

    const updateCompany = () => {

        if (!editToggle)
            return;
    
        Swal.fire({
            icon: 'info',
            title: 'Updating Fiber Company',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    
        if (!name) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a company name',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            return;
        }
    
        if (!description) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a description',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            return;
        }
    
        const req = new FormData();
        req.append('id', location.state.id);
        req.append('name', name);
        req.append('description', description);
        if (file !== location.state.file) {
            req.append('logo', file);
        }
    
        // Call to update Fiber Company first
        FiberCompanyApi.EditFiberCompany(req).then(res => {
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Fiber Company updated successfully',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
    
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong with updating the Fiber Company',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred during the update',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        });
    
    };
    
    const searchFiberPlans = () => {
        setLoading(true)
        const req = new SearchFiberPlanReq()
        req.fiberCompanyId = location?.state?.id
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = 100
        FiberPlanApi.SearchFiberPlans(req).then(res => {
            if (res?.status?.success) {

                res?.data?.list.forEach(element => {
                    element.actions = <div className='flex gap-2'>
                        <SoftButton onClick={() => { editPlan(element) }}>Edit</SoftButton>
                        <SoftButton onClick={() => { deletePlan(element.id) }}>Delete</SoftButton>
                    </div>
                });
                setFiberPlans(res?.data?.list)
                setTotalPages(res.data.totalPages)
                setLoading(false)
            }
        })
    }
    useEffect(
        () => {
            searchFiberPlans()
        }, [company, currentPage]
    )



    useEffect(
        () => {
            console.log(formData)
        }, [formData]
    )

    return (
        <Layout>
            {
                pageLoading ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%"
                    }} >
                        <CircularProgress sx={{ color: "var(--color-icon)" }} />
                    </div>
                    :
                    <>


                        <IoArrowBack color='black' size={30} onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginLeft: "1rem" }} />
                        <div className='editFiberCompanyContainer' >
                            <div className='editFiberCompanyForm'>
                                <h3 className='editFiberCompanyFormTitle' >Details</h3>
                                <div className='editFiberCompanyFormInput' >
                                    {
                                        editToggle ?
                                            <div className='imageInput'>
                                                <input id="file-input" type="file" accept="image/*" onChange={handleFileChange} />
                                                <label htmlFor="file-input" >
                                                    <Camera />
                                                </label>
                                                {file && (
                                                    <img style={{
                                                        height: "100px"
                                                    }} src={URL.createObjectURL(file)} alt="" />
                                                )}
                                                {
                                                    !file && company?.logo &&
                                                    <img style={{
                                                        height: "100px"
                                                    }} src={"data:image/png;base64," + company?.logo} alt="logo" />
                                                }
                                            </div>
                                            :
                                            <img style={{
                                                height: "100px"
                                            }} src={"data:image/png;base64," + company?.logo} alt="logo" />
                                    }

                                    <div className='inputContainer' >

                                        <h3 className='editFiberCompanyFormInputLabel'>Name</h3>
                                        {
                                            editToggle ?
                                                <input className='editFiberCompanyFormInputField' placeholder="Enter the Name" value={name} onChange={handleNameChange} />
                                                :
                                                <h3 className='editFiberCompanyFormInputLabel' >{company?.name}</h3>
                                        }
                                        <h3 className='editFiberCompanyFormInputLabel'>Description</h3>
                                        {
                                            editToggle ?
                                                <textarea className='editFiberCompanyFormInputField' placeholder="Enter the Description" value={description} onChange={handleDescriptionChange} />
                                                :
                                                <h3 className='editFiberCompanyFormInputLabel'>{company?.description}</h3>
                                        }
                                    </div>
                                </div>

                                <Button onClick={() => { updateCompany(); setEditToggle(!editToggle) }}>{editToggle ? "SAVE" : "EDIT"}</Button>
                                {/* <Button className={'mt-2'} onClick={
                                    () => {
                                        setOpenModal(true)
                                    }
                                }>
                                    Create/Edit Offer Template
                                </Button> */}
                            </div>

                            <h3 className='fiberPlansHeading'>Fiber Plans</h3>

                            <Button onClick={() => {
                                navigate('/manageFiberCompany/details/createFiberPlan', {
                                    state: {
                                        id: company?.id,
                                        name: company?.name
                                    }

                                })
                            }}>Create Fiber Plan</Button>


                            {
                                loading ?
                                    <div className='spinner' >
                                        <CircularProgress sx={{ color: "var(--color-icon)" }} />
                                    </div>
                                    :
                                    <>
                                        <Datatable headers={headers} list={fiberPlans} currentPage={currentPage} totalPages={totalPages} />
                                        
                                    </>
                            }
                            <div className='pagination' >
                                <Pagination color='light' count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                            </div>
                        </div>
                        {
                            company &&
                            <div className='bottom_fiber_company_conatiner'>
                                <CreateOfferTemplateModal reload={reloadOfferTemplate}  offerTemplate={offerTemplate} setOfferTemplate={setOfferTemplate} company={company} />
                                <AddFiberPlanToOfferTemplate setReload={setReloadOfferTemplate} offerTemplate={offerTemplate} company={company} />
                                <FiberCompanySalesOrgs fiberCompanyId={company.id} onSalesOrgAction={handleSalesOrgAction}/>
                                <FiberCompanySalesReps fiberCompanyId={company.id} ref={salesRepsRef} />
                            </div>
                        }
                    </>
            }

        </Layout>
    )
}

export default Index