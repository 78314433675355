import { JSEncrypt } from 'jsencrypt';
class EncryptionHelper {
    constructor() {
        const privateKey = process.env.REACT_APP_PRIVATE_KEY;
        this.jsEncrypt = new JSEncrypt();
        this.jsEncrypt.setPrivateKey(privateKey.replace(/\\n/g, '\n'));
    }

    decrypt(cipherText) {
        const decrypted = this.jsEncrypt.decrypt(cipherText);
        if (!decrypted) {
            throw new Error('Decryption failed');
        }
        return decrypted;
    }
}

export default EncryptionHelper;
