import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
// import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import UsersApi from '../../../API/UsersApi';
import CreateUserReq from '../../../Requests/Users/CreateUserReq';
import PublicLayout from '../../../Layouts/PublicLayout';
import "./index.scss";
import signup_light from './images/signup_light.png'
import {useNavigate} from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
const Index = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("")
  const [userType, setUserType] = useState("Customer");
  const [user] = useAuthState(getAuth());
  const [method, setMethod] = useState(1)
  const navigate = useNavigate()
  useEffect(() => {
    if (user) {
      window.location.href = "/"
    }
  }, [user])

  const SignUp = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: 'info',
      title: 'Creating User',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    //check if email empty
    if (method === 1 && !email) {
      Swal.fire({
        icon: 'error',
        title: 'Email is required',
        text: 'Please enter email',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check email is valid using regex
    let emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;
    if (method === 1 && !email.match(emailRegex)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check password > 6 characters and confirm password matches
    if (method === 1 && (!password || password.length < 6)) {
      Swal.fire({
        icon: 'error',
        title: 'Password must be at least 6 characters',
        text: 'Please enter password',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (method === 1 && password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please enter password',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check user type
    if (!userType) {
      Swal.fire({
        icon: 'error',
        title: 'User Type is required',
        text: 'Please select user type',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // if (!name) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Name is required',
    //     text: 'Please enter name',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     allowEnterKey: false,
    //     showConfirmButton: true,
    //     didOpen: () => {
    //       Swal.hideLoading()
    //     }
    //   });
    //   return;
    // }

    // check first name
    if (!firstName) {
      Swal.fire({
        icon: 'error',
        title: 'First Name is required',
        text: 'Please enter first name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check last name
    if (!lastName) {
      Swal.fire({
        icon: 'error',
        title: 'Last Name is required',
        text: 'Please enter last name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check phone
    // if (!phone) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Phone is required',
    //     text: 'Please enter phone number',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     allowEnterKey: false,
    //     showConfirmButton: true,
    //     didOpen: () => {
    //       Swal.hideLoading()
    //     }
    //   });
    //   return;
    // }

    // check address
    // if (!address) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Address is required',
    //     text: 'Please enter address',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     allowEnterKey: false,
    //     showConfirmButton: true,
    //     didOpen: () => {
    //       Swal.hideLoading()
    //     }
    //   });
    //   return;
    // }

    // create user
    let req = new CreateUserReq();
    req.email = email;
    req.password = password;
    req.firstName = firstName;
    req.lastName = lastName;
    req.phone = phone;
    req.address = address;
    req.userType = userType;
    let res = null;
    if (method === 2)
      res = await UsersApi.CreateUserUsingPhone(req);
    else {
      res = await UsersApi.CreateUser(req);
    }
    if (res?.status?.success) {
      Swal.fire({
        icon: 'success',
        title: 'User Created',
        text: "Logging you in...",
        // allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          if (method === 1)
            Swal.showLoading()
          else {
            Swal.hideLoading()
            navigate('/signin')
          }
        }
      });
      const auth = getAuth();
      if (method === 1)
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in 
            // const user = userCredential.user;
            // ...
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: error.message,
              didOpen: () => {
                Swal.hideLoading()
              }
            });
            // const errorCode = error.code;
            // const errorMessage = error.message;
          });

    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
    }

  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        SignUp();
    }
};
  return (

    <PublicLayout>
      <div className='signup_container'>
        <h1>Welcome to Lightning OS!</h1>
        <h3>You're one step away from seamless connectivity.</h3>
        <div style={{
          position: 'relative'
        }}>
          <img src={signup_light} className="signup_light" alt='signup_light' />
          <div className="signup_form">
            <div className='tabs'>
              <button onClick={(e) => { e.preventDefault(); setMethod(1) }} onKeyDown={handleKeyPress} className={`${method === 1 ? "active" : ""}`}>Using Email</button>
              <button onClick={(e) => { e.preventDefault(); setMethod(2) }} onKeyDown={handleKeyPress} className={`${method === 2 ? "active" : ""}`}>Using Phone Number</button>
            </div>
            <h1>Choose Your User Type</h1>
            <div className="userType">
              <label>
                <input type="radio" name="userType" value="Customer" onChange={(e) => setUserType(e.target.value)} />
                <span>Customer</span>
              </label>
              <label>
                <input type="radio" name="userType" value="SalesRep" onChange={(e) => setUserType(e.target.value)} />
                <span>Fiber Specialist</span>
              </label>
            </div>
            <input type='text' placeholder='FIRST NAME' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            <input type='text' placeholder='LAST NAME' value={lastName} onChange={(e) => setLastName(e.target.value)} />
            <PhoneInput containerStyle={{
                 marginBottom:"1rem"
            }} inputStyle={{
                    padding:"0.5rem 0rem",
                    paddingLeft: "50px",
                  }} country={'us'} excludeCountries={['IL']} value={phone} onChange={setPhone} />
          
            {
              method === 1 &&
              <>
                <input type='email' placeholder='EMAIL' value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type='text' placeholder='ADDRESS' value={address} onChange={(e) => setAddress(e.target.value)} />
                <input type='password' placeholder='PASSWORD' value={password} onChange={(e) => setPassword(e.target.value)} />
                <input type='password' placeholder='CONFIRM PASSWORD' value={confirmPassword} onChange={(e) => setConfirmPassowrd(e.target.value)} />
              </>
            }
            <button onClick={(e) => { SignUp(e) }}>Sign Up</button>
          </div>
        </div>
      </div>
    </PublicLayout>

  )
}

export default Index