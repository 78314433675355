import React, { useState, useEffect, useContext } from 'react'
import { MapContext } from '..'
import ServicableAreaApi from '../../../../API/ServicableAreaApi'
import CreateServicableAreaReq from '../../../../Requests/ServicableArea/CreateServicableAreaReq'
import Swal from 'sweetalert2'
import { Modal } from '@mui/material'
import { UserContext } from '../../../../App'
import { IoCloseCircle } from 'react-icons/io5'
import VectorSource from 'ol/source/Vector'

const SaveServicableArea = () => {

    const map = useContext(MapContext)
    const user = useContext(UserContext)

    const [areaName, setAreaName] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [color, setColor] = useState("#000000")

    async function saveServicableArea() {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are saving the servicable area",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })

        const req = new CreateServicableAreaReq()
        req.coordinates = map.searchArea
        req.name = areaName
        req.color = color
        req.createdById = user.id
        req.notes = ""
        req.salesOrgId = 1 // TODO: Change this so that salesOrgId is nullable
        req.status = "Active";
        req.servicableAreaType = "Tag"
        const res = await ServicableAreaApi.CreateServicableArea(req)
        if (res.status.success) {

            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Servicable Area Saved Successfully",
                showConfirmButton: true,
                showCancelButton: false,
                willClose: () => {
                    // map.clearSearchArea()
                }
            })

            deletePolygon()

            map.setServicableAreas((prev) => {
                return [...prev, {
                    latlngs: res.data.coordinates.map(
                        (point) => {
                            return [point.latitude, point.longitude]
                        }
                    ),
                    color: res.data.color,
                    id: res.data.id,
                    name: res.data.name,
                    fiberCompanyId: res.data.fiberCompanyId,
                    salesOrgId: res.data.salesOrgId,
                    type: res.data.servicableAreaType
                }]
            })

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                showConfirmButton: true,
                showCancelButton: false
            })
        }

    }

    const deletePolygon = () => {
        if (map.searchAreaFeature.length > 0) {


            map.searchAreaFeature.forEach(function (feature) {
                var selectedGeometry = feature.getGeometry(); // Assuming only one feature is selected
                map.mapObject.getLayers().getArray().forEach(layer => {
                    const source = layer.getSource()
                    if (source instanceof VectorSource)
                        source.forEachFeature(function (feature) {
                            var featureGeometry = feature.getGeometry();
                            if (featureGeometry === selectedGeometry) {
                                layer.getSource().removeFeature(feature);
                                map.setSearchAreaFeature([])
                                //     map.setSearchArea(null)
                                //     setSearchAreaFiberHouses([])
                                //       map.setFiberHouseIds([])
                            }
                        });
                });
            })

            map.setUsingLight(true);
            //     map.setIndex(-1);
            // setFiberHouses(lightHouses.current)
            map.setFiberHouses(
                (prev) => {
                    return prev.map(fiberHouse => {
                        return { ...fiberHouse, assigned: null, selected: false, remove: false }
                    })
                }

            )



        }
        else {


        }
    }

    return (
        <>
            <button className='px-4 py-2 rounded-lg bg-blue-500 text-white w-fit ml-auto' onClick={() => {
                setOpenModal(true)
            }}>Create Area</button>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <div style={{ width: "min(500px,80%)" }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  bg-white p-4 rounded-lg'>
                    <div className='flex flex-col p-4 gap-4 justify-center items-center '>
                        <IoCloseCircle onClick={() => {
                            setOpenModal(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        <img alt="Logo" src={'/images/nav/nav-logo.png'} className='object-fit' />
                        <h1 className='text-2xl font-bold'>Create Area</h1>
                        <input
                            className='w-full p-2 border border-gray-300 rounded-lg outline-none'
                            type="text" placeholder="Enter Area Name" value={areaName} onChange={(e) => setAreaName(e.target.value)} />
                        <div className='grid grid-cols-2 gap-4 w-full p-2 border border-gray-300 rounded-lg'>
                            <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                            <input type="text" className=' outline-none' value={color} onChange={(e) => setColor(e.target.value)} />
                        </div>
                        <button
                            className='bg-blue-500 text-white p-2 rounded-lg w-full'
                            onClick={saveServicableArea}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </>

    )
}

export default SaveServicableArea