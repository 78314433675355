class SearchUserReq {
    constructor() {
        this.id = null;
        this.firebaseId = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phoneNumber = "";
        this.address = "";
        this.userType = "";
        this.userTypes = [];
        this.status = "";
        this.smsVerified = null;
        this.createdAtFrom = null;
        this.createdAtTo = null;
        this.nameString = "";
        this.salesOrgId = null;
        this.pagingParams = {
          pageNumber: 1,
          pageSize: 100
        };
    }
}
export default SearchUserReq;