import React, { useState, useContext } from 'react'
import { UserContext } from '../../../App'
import { Rating } from '@mui/material'
import CustomerApi from '../../../API/CustomerApi'
import CustomerFeedbackReq from '../../../Requests/Customer/CustomerFeedbackReq'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'
import { uploadFileToFirebase } from '../../../Services/DisplayFile'

const FeedbackForm = ({ feedbackType, setOpen, isReview, salesRepId }) => {
    const [rating, setRating] = useState(0)
    const [content, setContent] = useState('')
    const [SpeedTestBeforeImage, setSpeedTestBeforeImage] = useState('')
    const [SpeedTestAfterImage, setSpeedTestAfterImage] = useState('')
    const [videoFile, setVideoFile] = useState('')
    const user = useContext(UserContext)


    const submitFeedback = async () => {
        Swal.fire({
            title: 'Submitting Feedback',
            text: 'Please do not close the window',
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (rating === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Please provide a rating',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (feedbackType === 'video' && videoFile === '') {
            Swal.fire({
                icon: 'error',
                title: 'Please provide a video',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (feedbackType === 'written' && content === '') {

            Swal.fire({
                icon: 'error',
                title: 'Please provide content',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (feedbackType === 'speedTest' && (SpeedTestBeforeImage === '' || SpeedTestAfterImage === '')) {
            Swal.fire({
                icon: 'error',
                title: 'Please provide both images',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        let req = new CustomerFeedbackReq()
        req.FeedbackType = feedbackType.charAt(0).toUpperCase() + feedbackType.slice(1)
        req.Rating = rating
        req.FidiumCustomerId = user.fidiumCustomerId
        req.LeadId = user.fidiumCustomer?.LeadId
        req.SalesRepId = user.fidiumCustomer?.SalesRepId
        

        if (feedbackType === 'video') {
            let videoReq = await uploadFileToFirebase(videoFile, `/VideoFeedbacks/${user.firebaseId}/${uuidv4()}`)
            req.Video = videoReq;
        }
        else if (feedbackType === 'written') {
            req.Content = content
        }
        else if (feedbackType === 'speedTest') {
            let speedTest = {
                BeforeImage: await uploadFileToFirebase(SpeedTestBeforeImage, `/SpeedTestFeedbacks/${user.firebaseId}/BeforeImage/${uuidv4()}`),
                AfterImage: await uploadFileToFirebase(SpeedTestAfterImage, `/SpeedTestFeedbacks/${user.firebaseId}/AfterImage/${uuidv4()}`),
                FidiumCustomerId: user.fidiumCustomerId,
                LeadId: user.fidiumCustomer?.LeadId != null ? user.fidiumCustomer?.LeadId : null,
                CustomerFeedbackId: null,
            }
            req.SpeedTest = speedTest

        }

        if(isReview && salesRepId !== null){
            req.FidiumCustomerId = null
            req.LeadId = null
            req.SalesRepId = salesRepId
            let res = await CustomerApi.AddSalesRepReview(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Feedback Submitted',
                    customClass: {
                        container: 'custom-swal'
                    },
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                window.location.reload()
                setOpen(false)
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to submit feedback',
                    text: res?.status?.message,
                    customClass: {
                        container: 'custom-swal'
                    },
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            
            }
        } else 
        {

            let res = await CustomerApi.CreateCustomerFeedback(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Feedback Submitted',
                    customClass: {
                        container: 'custom-swal'
                    },
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                window.location.reload()
                setOpen(false)
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to submit feedback',
                    text: res?.status?.message,
                    customClass: {
                        container: 'custom-swal'
                    },
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            
            }
        }



    }

    const getInput = () => {
        switch (feedbackType) {
            case 'video':
                return (
                    <div>
                        <label>Video</label>
                        <input type='file' accept="video/*" onChange={(e) => setVideoFile(e.target.files[0])} />
                    </div>
                )
            case 'written':
                return (
                    <div>
                        <label>Tell us what you think</label>
                        <textarea onChange={(e) => setContent(e.target.value)}>
                        </textarea>
                    </div>
                )
            case 'speedTest':
                return (
                    <div>
                        <label>Speed Test Before</label>
                        <input type='file' accept="image/*" onChange={(e) => setSpeedTestBeforeImage(e.target.files[0])} />
                        <label>Speed Test After</label>
                        <input type='file' accept="image/*" onChange={(e) => setSpeedTestAfterImage(e.target.files[0])} />
                    </div>
                )
            default:
                return null
        }
    }
    return (
        <div className='FeedbackForm overflow-auto z-50 border-gray-200 border-2 flex flex-col  bg-white p-4 rounded-lg'>
            <div className='AllDiv'>

                <div className='TitleDiv'>
                    <h1>Feedback</h1>
                    <span onClick={() => setOpen(false)}>X</span>
                </div>
                <div className='BodyDiv'>
                    <div className='RatingDiv'>
                        <h3>Rating</h3>
                        <Rating className='mainRating' onChange={(e, num) => setRating(num)} value={rating} size={'large'} />
                    </div>
                    <div className='InputDiv'>
                        {getInput()}
                        <button onClick={submitFeedback}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FeedbackForm