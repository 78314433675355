import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Switch, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Select from 'react-select';
import QuickDate from '../../../../../components/QuickDate';
import './candidate-filters.scss';
import { MapContext } from '../..';
import FiberHouseApi from '../../../../../API/FiberHouseApi';

const CandidateSearchFilter = ({
  candidateName,
  setCandidateName,
  candidateEmail,
  setCandidateEmail,
  candidateLeadType,
  setCandidateLeadType,
  candidateStatus,
  setCandidateStatus,
  candidateIndustries,
  setCandidateIndustries,
  candidateCompanies,
  setCandidateCompanies,
  candidatePositionTitles,
  setCandidatePositionTitles,
  candidateCreatedFrom,
  setCandidateCreatedFrom,
  candidateCreatedTo,
  setCandidateCreatedTo,
  candidateAppointmentFrom,
  setCandidateAppointmentFrom,
  candidateAppointmentTo,
  setCandidateAppointmentTo,
  candidateLastUpdatedFrom,
  setCandidateLastUpdatedFrom,
  candidateLastUpdatedTo,
  setCandidateLastUpdatedTo,
  candidateMapIcons,
  setCandidateMapIcons,
  candidateAssignedQuickDate,
  setCandidateAssignedQuickDate
}) => {
  const map = React.useContext(MapContext);
  const initialRender = React.useRef(true);

  const [dateRangeType, setDateRangeType] = useState('created');
  const [industryOptions, setIndustryOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [positionTitleOptions, setPositionTitleOptions] = useState([]);
  const [leadTypeOptions, setLeadTypeOptions] = useState([
    { value: 'Recruit', label: 'Recruit' },
    { value: 'Company', label: 'Company' },
  ]);
  const [statusOptions, setStatusOptions] = useState([
    { value: 'New', label: 'New' },
    { value: 'Contacted', label: 'Contacted' },
    { value: 'Qualified', label: 'Qualified' },
    { value: 'Invited', label: 'Invited' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Hired', label: 'Hired' },
    { value: 'ShortListed', label: 'ShortList' },
    { value: 'Lost', label: 'Lost' },
  ]);

  //getIndustryOptions
  const getIndustryOptions = async () => {
    let res = await FiberHouseApi.GetCandidateIndustries();
    if (res.status.success) {
      setIndustryOptions(res.data);
    }
  }

  //getCompanyOptions
  const getCompanyOptions = async () => {
    let res = await FiberHouseApi.GetCandidateCompanies();
    if (res.status.success) {
      setCompanyOptions(res.data);
    }
  }

  //getPositionTitleOptions
  const getPositionTitleOptions = async () => {
    let res = await FiberHouseApi.GetCandidatePositionTitles();
    if (res.status.success) {
      setPositionTitleOptions(res.data);
    }
  }

  const dateRangeOptions = [
    { value: 'created', label: 'Created Date' },
    { value: 'appointment', label: 'Appointment Date' },
    { value: 'lastUpdated', label: 'Last Updated Date' },
  ];

  useEffect(() => {
    if (initialRender.current) {
      getIndustryOptions();
      getCompanyOptions();
      getPositionTitleOptions();
      initialRender.current = false;
      return;
    }
    map.getCandidates();
  }, [candidateName, candidateEmail, candidateLeadType, candidateStatus,
    candidateIndustries, candidateCompanies, candidatePositionTitles, candidateCreatedFrom,
    candidateCreatedTo, candidateAppointmentFrom, candidateAppointmentTo,
    candidateLastUpdatedFrom, candidateLastUpdatedTo, candidateMapIcons, dateRangeType]);

    const handleSetFromDate = (from) => {
      console.log('Setting From Date:', dateRangeType, from);

      if (dateRangeType === 'created') {
        setCandidateCreatedFrom(from);
      } else if (dateRangeType === 'appointment') {
        setCandidateAppointmentFrom(from);
      } else if (dateRangeType === 'lastUpdated') {
        setCandidateLastUpdatedFrom(from);
      }
    };
  
    // Function to handle setting the to date
    const handleSetToDate = (to) => {
      console.log('Setting To Date:', dateRangeType, to);
  
      if (dateRangeType === 'created') {
        setCandidateCreatedTo(to);
      } else if (dateRangeType === 'appointment') {
        setCandidateAppointmentTo(to);
      } else if (dateRangeType === 'lastUpdated') {
        setCandidateLastUpdatedTo(to);
      }
    };

  const getDateRangeValues = () => {
    switch (dateRangeType) {
      case 'created':
        return { from: candidateCreatedFrom, to: candidateCreatedTo };
      case 'appointment':
        return { from: candidateAppointmentFrom, to: candidateAppointmentTo };
      case 'lastUpdated':
        return { from: candidateLastUpdatedFrom, to: candidateLastUpdatedTo };
    }
  };

  const handleDateRangeTypeChange = (newType) => {
    // Store the current `from` and `to` values
    const { from, to } = getDateRangeValues();
    console.log(from, to);

    //reset all to null

    setCandidateCreatedFrom(null);
    setCandidateCreatedTo(null);
    setCandidateAppointmentFrom(null);
    setCandidateAppointmentTo(null);
    setCandidateLastUpdatedFrom(null);
    setCandidateLastUpdatedTo(null);


    // Update the date values based on the new type
    if (newType === 'created') {
      setCandidateCreatedFrom(from);
      setCandidateCreatedTo(to);
    } else if (newType === 'appointment') {
      setCandidateAppointmentFrom(from);
      setCandidateAppointmentTo(to);
    } else if (newType === 'lastUpdated') {
      setCandidateLastUpdatedFrom(from);
      setCandidateLastUpdatedTo(to);
    }

    // Update the date range type
    setDateRangeType(newType);
  };

  const showCompanyFilters = candidateLeadType?.filter(type => type.value === 'Company');

  return (
    <div className="candidate-filters">
      <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="candidate-filters-content"
            id="candidate-filters-header"
          >
            <Typography sx={{ flexGrow: 1 }}>Candidate Leads</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '24px' }}>
            <div className="InputsDiv">
              <div className="InputDiv">
                <label>Name</label>
                <input
                  type="text"
                  value={candidateName}
                  onChange={(e) => setCandidateName(e.target.value)}
                  placeholder="Enter name"
                />
              </div>
              <div className="InputDiv">
                <label>Email</label>
                <input
                  type="email"
                  value={candidateEmail}
                  onChange={(e) => setCandidateEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </div>
              <div className="InputDiv">
                <label>Lead Type</label>
                <Select
                  isMulti
                  value={candidateLeadType}
                  defaultValue={leadTypeOptions[0]}
                  onChange={(options) => setCandidateLeadType(options)}
                  options={leadTypeOptions}
                  placeholder="Select Lead Type"
                />
              </div>
              {/* <div className="InputDiv">
                <label>Status</label>
                <Select
                  value={candidateStatus}
                  onChange={(option) => setCandidateStatus(option)}
                  options={statusOptions}
                  placeholder="Select Status"
                  isClearable
                />
              </div> */}
              {showCompanyFilters && (
                <>
                  <div className="InputDiv">
                    <label>Industries</label>
                    <Select
                      isMulti
                      value={candidateIndustries}
                      onChange={(options) => setCandidateIndustries(options)}
                      options={industryOptions}
                      placeholder="Select Industries"
                    />
                  </div>
                  <div className="InputDiv">
                    <label>Companies</label>
                    <Select
                      isMulti
                      value={candidateCompanies}
                      onChange={(options) => setCandidateCompanies(options)}
                      options={companyOptions}
                      placeholder="Select Companies"
                    />
                  </div>
                  <div className="InputDiv">
                    <label>Position Titles</label>
                    <Select
                      isMulti
                      value={candidatePositionTitles}
                      onChange={(options) => setCandidatePositionTitles(options)}
                      options={positionTitleOptions}
                      placeholder="Select Position Titles"
                    />
                  </div>
                </>
              )}
              <div className="InputDiv">
                <label>Date Range Type</label>
                <Select
                  value={dateRangeOptions.find(option => option.value === dateRangeType)}
                  onChange={(option) => handleDateRangeTypeChange(option.value)}
                  options={dateRangeOptions}
                  placeholder="Select Date Range Type"
                />
              </div>
              <div className="DateRangeSection">
                <QuickDate
                  label={`${dateRangeOptions.find(option => option.value === dateRangeType).label} Range`}
                  selectQuickDate={candidateAssignedQuickDate}
                  setQuickDateValue={setCandidateAssignedQuickDate}
                  setFrom={handleSetFromDate}
                  setTo={handleSetToDate}
                  from={getDateRangeValues().from}
                  to={getDateRangeValues().to}
                  future={dateRangeType === 'appointment'}
                />
                <div className="separator"></div>
              </div>
              <div className="MapIconsSection">
                <h1 className='lead_type_title'>Lead Status</h1>
                <div className='select_deselect'>
                  <button className='select' onClick={() => setCandidateMapIcons(map.mapIcons.map(icon => icon.id))}>
                    Select All
                  </button>
                  <button className='deselect' onClick={() => setCandidateMapIcons([])}>
                    Deselect All
                  </button>
                </div>
                <div className='MapIconStats'>
                  {map.mapIcons?.filter((icon) => icon.iconType === "Recruit" || icon.iconType === "Company").map((icon, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        const newMapIcons = candidateMapIcons?.includes(icon.id)
                          ? candidateMapIcons.filter(id => id !== icon.id)
                          : [...candidateMapIcons, icon.id];
                        setCandidateMapIcons(newMapIcons);
                      }}
                      className={`MapIconStat ${candidateMapIcons?.includes(icon.id) ? 'active' : ''}`}
                    >
                      <h3>
                        <img src={`data:image/png;base64,${icon.image}`} alt="Map Icon" />
                      </h3>
                      <p>{icon.abbreviation}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default CandidateSearchFilter;