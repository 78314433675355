/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import { UserContext } from '../../../../App'
import GetUserConversationReq from '../../../../Requests/Messaging/GetUserConversationsReq'
import MessagingApi from '../../../../API/MessagingApi'
import PrivateChatDisplay from './PrivateChatDisplay'
import { CircularProgress } from '@mui/material'
import CreateConversation from './CreateConversation'
import { RiChatNewLine } from "react-icons/ri";
import Modal from '../../../../components/Modal'
const ChatList = ({ type, newChat }) => {

    const user = useContext(UserContext);
    const [conversations, setConversations] = useState([])
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [hasMore, setHasMore] = useState(false)
    const observer = useRef();
    const [openCreateChat, setOpenCreateChat] = useState(false)
    const [search, setSearch] = useState('')
    // const getUserConversations = () => {
    //     const req = new GetUserConversationReq();
    //     req.int = user.id
    //     setLoading(true)
    //     MessagingApi.GetUserConversations(req).then(
    //         (res) => {
    //             if (res?.status?.success) {
    //                 console.log(res)
    //                 let arr = []
    //                 for (let i = 0; i < res.data?.list?.length; i++) {
    //                     if (res.data?.list[i].conversationType === type)
    //                         arr.push(res.data?.list[i])
    //                 }

    //                 setConversations(arr)
    //                 setLoading(false)
    //             }
    //         }
    //     );
    // }


    const lastCard = useCallback(  // (*)
        (node) => {
            if (loading) {
                console.log("Skip due to loading");
                return;
            }
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {

                if (entries[0].isIntersecting && hasMore) {
                    setPageNo((prev) => prev + 1);
                    console.log("visible");
                }
            });
            if (node) observer.current.observe(node);
        },
        [hasMore, loading]
    );


    const getUserConversations = async () => {
        const req = new GetUserConversationReq();
        req.int = user.id
        setLoading(true)
        req.pagingParams.pageNumber = pageNo
        req.pagingParams.pageSize = 10
        if (type === 'All') {
            const res = await MessagingApi.GetUserConversations(req);
            if (res?.status?.success) {
                setHasMore(res?.data?.totalPages > pageNo)
                setConversations(
                    (prev) => {
                        if (pageNo > 1)
                            return [...prev, ...res.data?.list]
                        else
                            return res.data?.list
                    }
                )
                setLoading(false)
            }
        }
        else if (type === "Private") {
            const res = await MessagingApi.GetUserPrivateConversations(req);
            if (res?.status?.success) {
                // let arr = []
                // for (let i = 0; i < res.data?.list?.length; i++) {
                //     if (res.data?.list[i].conversationType === type)
                //         arr.push(res.data?.list[i])
                // }
                setHasMore(res?.data?.totalPages > pageNo)
                console.log(res)
                setConversations(
                    (prev) => {
                        if (pageNo > 1)
                            return [...prev, ...res.data?.list]
                        else
                            return res.data?.list
                    }
                )
                setLoading(false)
            }
        }
        else if (type === "Group") {
            const res = await MessagingApi.GetUserGroupConversations(req);
            if (res?.status?.success) {
                // let arr = []
                // for (let i = 0; i < res.data?.list?.length; i++) {
                //     if (res.data?.list[i].conversationType === type)
                //         arr.push(res.data?.list[i])
                // }

                setHasMore(res?.data?.totalPages > pageNo)
                setConversations(
                    (prev) => {
                        if (pageNo > 1)
                            return [...prev, ...res.data?.list]
                        else
                            return res.data?.list
                    }
                )
                setLoading(false)
            }
        }
        else if (type === "SalesOrg") {
            const res = await MessagingApi.GetUserSalesOrgConversations(req);
            if (res?.status?.success) {
                //     let arr = []
                //     for (let i = 0; i < res.data?.list?.length; i++) {
                //         if (res.data?.list[i].conversationType === type)
                //             arr.push(res.data?.list[i])
                //     }

                setHasMore(res?.data?.totalPages > pageNo)
                setConversations(
                    (prev) => {
                        if (pageNo > 1)
                            return [...prev, ...res.data?.list]
                        else
                            return res.data?.list
                    }
                )
                setLoading(false)
            }
        }
        else if (type === "CustomerService") {


            if (user.userType === "SuperAdmin") {
                const res = await MessagingApi.GetAllCustomerServices(req);
                if (res?.status?.success) {
                    // let arr = []
                    // for (let i = 0; i < res.data?.list?.length; i++) {
                    //     if (res.data?.list[i].conversationType === type)
                    //         arr.push(res.data?.list[i])
                    // }
                    console.log(res)
                    setHasMore(res?.data?.totalPages > pageNo)
                    setConversations(
                        (prev) => {
                            if (pageNo > 1)
                                return [...prev, ...res.data?.list]
                            else
                                return res.data?.list
                        }
                    )
                    setLoading(false)
                }
            }
            else if (user.userType === "SalesOrgAdmin") {
                req.int = user.salesOrgId
                const res = await MessagingApi.GetUserCustomerServiceUsingSalesOrgId(req);
                if (res?.status?.success) {
                    // let arr = []
                    // for (let i = 0; i < res.data?.list?.length; i++) {
                    //     if (res.data?.list[i].conversationType === type)
                    //         arr.push(res.data?.list[i])
                    // }
                    console.log(res)
                    setHasMore(res?.data?.totalPages > pageNo)
                    setConversations(
                        (prev) => {
                            if (pageNo > 1)
                                return [...prev, ...res.data?.list]
                            else
                                return res.data?.list
                        }
                    )
                    setLoading(false)
                }
                return
            }


            const res = await MessagingApi.GetUserCustomerServiceConversations(req);
            if (res?.status?.success) {
                // let arr = []
                // for (let i = 0; i < res.data?.list?.length; i++) {
                //     if (res.data?.list[i].conversationType === type)
                //         arr.push(res.data?.list[i])
                // }
                // console.log(arr)
                setHasMore(res?.data?.totalPages > pageNo)
                setConversations(
                    (prev) => {
                        if (pageNo > 1)
                            return [...prev, ...res.data?.list]
                        else
                            return res.data?.list
                    }
                )
                setLoading(false)
            }
        }
        else if (type === "Global") {
            const res = await MessagingApi.GetGlobalConversations(req);
            if (res?.status?.success) {
                // let arr = []
                // for (let i = 0; i < res.data?.list?.length; i++) {
                //     if (res.data?.list[i].conversationType === type)
                //         arr.push(res.data?.list[i])
                // }

                setHasMore(res?.data?.totalPages > pageNo)
                setConversations(
                    (prev) => {
                        if (pageNo > 1)
                            return [...prev, ...res.data?.list]
                        else
                            return res.data?.list
                    }
                )
                setLoading(false)
            }
        }
    }

    useEffect(
        () => {
            if(conversations.length > 0 || pageNo > 1){
                setConversations([])
                setPageNo(1)
            }
            else{
                getUserConversations()
            }
        }, [type, newChat]
    )

    useEffect(() => {
        getUserConversations()
    }, [pageNo])

    return (

        <>
        <div className='search_input'>

              <input onChange={(e)=>{
                    setSearch(e.target.value)
                }} type="text" placeholder="Search"  />
        </div>
            <Modal open={openCreateChat} setOpen={setOpenCreateChat}>
                <CreateConversation getUserConversations={getUserConversations} type={type} />
            </Modal>
            <div className='chat_list_container'>
                <div onClick={() => {
                    setOpenCreateChat(true)
                }} className='floating_icon'>
                    <RiChatNewLine size={30} color='#fff' />
                </div>
              
                {conversations.length > 0 &&
                    conversations.map((item, index) => {
                        if(item.name.toLowerCase().includes(search.toLowerCase()))
                        return <>
                        <div ref={conversations.length - 1 === index ? lastCard : null} className='item_container' style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                        }}>
                            <PrivateChatDisplay getUserConversations={getUserConversations} key={item.id} conversation={item} />
                        </div>
                        <div className='line'>
                        </div>
                        </>
                        
                    })
                }
                {
                    loading &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <CircularProgress size={40} sx={{
                            color: '#2761CF',
                        }} />
                    </div>
                }
            </div>
        </>

    )
}

export default ChatList