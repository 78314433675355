import React from 'react'
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
const WrittenReview = ({review}) => {
    return (
        <div className='written_review'>
            <RiDoubleQuotesL size={50} color='F1A501' className='quote' />
            <div className='text' >
                <p>{review.content}</p>
            </div>
            <div className='rightQuote'>
                <RiDoubleQuotesR size={50} color='F1A501' />
            </div>
            {/* <h4 className='review_name'>{review.fidiumCustomer.firstName+" "+review.fidiumCustomer.lastName}</h4> */}
            <h4 className='review_name'>{review.firstName+" "+review.lastName}</h4>
            <p>{review.type}</p>
        </div>
    )
}

export default WrittenReview