import CustomerMenuItems from '../MenuItems/CustomerMenuItems';
import AddFeedback from '../Views/Customer/AddFeedback';
import SignUpWithReferral from '../Views/Public/SignUpWithReferral/SignUpWithReferral';
import ChangeReferralCode from '../Views/Shared/AffiliateCode/ChangeReferralCode';
import ReferralCodeStatuses from '../Views/Shared/AffiliateCode/ReferralCodeStatuses';
import ReferralProgram from '../Views/Shared/AffiliateCode/ReferralProgram';
import Dashboard from '../Views/Shared/Dashboard';
import EditAccountDetails from '../Views/Shared/EditAccountDetails';
import ManageSellingCards from '../Views/Shared/ManageSellingCards';
import SellingCardTutorial from '../Views/Shared/SellingCardTutorial';
import MyLightningPlan from '../Views/Customer/MyLightningPlan';
import DigitalBusinessCenter from '../Views/Reps/DigitalBusinessCenter';
import ChatNew from '../Views/Shared/ChatNew';
import ChatList from '../Views/Shared/ChatList/';
import CustomerCancelForm from '../Views/Public/CancelForm';
import CustomerRescheduleForm from '../Views/Public/RescheduleForm';
import CreateBugTicketForm from '../Views/Public/BugTicket';
const CustomerRoutes = [
    {
        name: "Dashboard",
        key: "Dashboard",
        route: "/",
        component: <Dashboard routes={CustomerMenuItems} />,
    },
    {
        name: "Dashboard",
        key: "Dashboard",
        route: "*",
        component: <Dashboard routes={CustomerMenuItems} />,
    },
    {
        name: "My Lightning Plan",
        key: "MyLightningPlan",
        route: "/my-lightning-plan",
        component:<MyLightningPlan />
    },
    {
        name: "Add Feedback",
        key: "AddFeedback",
        route: "/add-feedback",
        component: <AddFeedback />
    },
    {
        name: "Change Referral Code",
        key: "ChangeReferralCode",
        route: "/change-referral-code",
        component: <ChangeReferralCode />
    },
    {
        name: "Referral Code Statuses",
        key: "ReferralCodeStatuses",
        route: "/referral-code-statuses",
        component: <ReferralCodeStatuses />
    },
    {
        name: "Referral Program",
        key: "Referral Program",
        route: "/referral-program",
        component: <ReferralProgram />
    },
    {
        name: "SignUpWithReferral",
        key: "SignUpWithReferral",
        route: "/signup/:AffiliateCode",
        component: <SignUpWithReferral />,
    },
    {
        name: "SignUpWithReferral",
        key: "SignUpWithReferral",
        route: "/CreateNewReferral",
        component: <SignUpWithReferral />,
    },
    {
        name: "Edit Account Details",
        key: "EditAccountDetails",
        route: "/edit-account-details",
        component: <EditAccountDetails />
    },
    {
        name:"My Lightning Rep",
        key:"MyLightningRep",
        route:"/my-lightning-rep",
        component:<DigitalBusinessCenter />
    },
    {
        name: "Support Center",
        key: "SupportCenter",
        route: "/support-center",
        component: <ChatList />
    },
    {
        name: "Chat",
        key:"chat",
        route:"/chat",
        component:<ChatNew />
    },
    {
      name: "Digital Business Center",
      key: "DigitalBusinessCenter",
      route: "/digital-business-center/:id",
      component: <DigitalBusinessCenter />
    },
    {
        name: "Customer Reschedule Form",
        key: "CustomerRescheduleForm",
        route: "/rescheduleform/:phoneNumber?",
        component: <CustomerRescheduleForm />
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        component:<CreateBugTicketForm/>
    },
]

export default CustomerRoutes;