import DisabledUser from "./../Views/DisabledUser"
const DisabledRoutes = [
    {
        route:"/",
        component: <DisabledUser />,
        name:"Disabled User",
        key:"DisabledUser"
    }
]

export default DisabledRoutes