import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Modal } from '@mui/material';
import FiberHouseApi from '../../../../../API/FiberHouseApi';
import './SpeedTestModal.scss';
import { IoCloseCircle } from 'react-icons/io5';

const SpeedTestModal = ({ user, lead, open, setOpen }) => {
    const [beforeImage, setBeforeImage] = useState(null);
    const [afterImage, setAfterImage] = useState(null);
    const [beforeImageUrl, setBeforeImageUrl] = useState('');
    const [afterImageUrl, setAfterImageUrl] = useState('');
    console.log(lead);

    useEffect(() => {
        if (lead?.fidiumCustomer?.speedTest?.beforeImage?.url) {
            setBeforeImageUrl(lead.fidiumCustomer.speedTest.beforeImage.url);
        }
        if (lead?.fidiumCustomer?.speedTest?.afterImage?.url) {
            setAfterImageUrl(lead.fidiumCustomer.speedTest.afterImage.url);
        }
    }, [lead]);

    const uploadFileToFirebase = async (file, path) => {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    Swal.update({
                        text: `Uploading ${file.name} ${progress.toFixed(0)}%`,
                    });
                },
                (error) => {
                    console.error(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Upload Failed',
                        text: `Failed to upload ${file.name}.`,
                        customClass:{
                            container:"custom-swal"
                        },
                        didOpen:() =>{
                            Swal.hideLoading()
                        }
                    });
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(storageRef);
                    resolve({
                        name: file.name,
                        url: downloadURL,
                        path: path,
                        extension: file.name.split('.').pop(),
                        fileSize: file.size.toString(),
                        fileType: file.type,
                        caption: file.name,
                    });
                }
            );
        });
    };

    const handleBeforeImageUpload = async (e) => {
        Swal.fire({
            icon: 'info',
            title: 'Uploading Image',
            text: 'Please wait...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass:{
                container:"custom-swal"
            },
            didOpen: () => Swal.showLoading(),
        });
        const file = e.target.files[0];
        if (file) {
            const path = `/SpeedTestFeedbacks/${user.firebaseId}/BeforeImage/${uuidv4()}`;
            try {
                const uploadedFile = await uploadFileToFirebase(file, path);
                setBeforeImage(uploadedFile);  // Update the state with the new file info
                setBeforeImageUrl(uploadedFile.url);  // Immediately show the new image in the UI
                Swal.fire({
                    icon:"success",
                    title:"File Uploaded",
                    customClass:{
                        container:"custom-swal"
                    },
                    didOpen: ()=> {
                        Swal.hideLoading()
                    }
                })
            } catch (error) {
                console.error('Image upload failed:', error);
            }
        }
    };

    const handleAfterImageUpload = async (e) => {
        Swal.fire({
            icon: 'info',
            title: 'Uploading Image',
            text: 'Please wait...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass:{
                container:"custom-swal"
            },
            didOpen: () => Swal.showLoading(),
        });

        const file = e.target.files[0];
        if (file) {
            const path = `/SpeedTestFeedbacks/${user.firebaseId}/AfterImage/${uuidv4()}`;
            try {
                const uploadedFile = await uploadFileToFirebase(file, path);
                setAfterImage(uploadedFile);  // Update the state with the new file info
                setAfterImageUrl(uploadedFile.url);  // Immediately show the new image in the UI
                Swal.fire({
                    icon:"success",
                    title:"File Uploaded",
                    customClass:{
                        container:"custom-swal"
                    },
                    didOpen: ()=> {
                        Swal.hideLoading()
                    }
                })
            } catch (error) {
                console.error('Image upload failed:', error);
            }
        }
    };

    const saveSpeedTestImages = async () => {
        Swal.fire({
            icon:"info",
            title: 'Saving SpeedTest',
            text: 'Please wait...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,             
            customClass:{
                container:"custom-swal"
            },
            didOpen:()=>{
                Swal.showLoading()
            }
        })
        try {
            const speedTest = {
                BeforeImage: beforeImage,
                AfterImage: afterImage,
                FidiumCustomerId: user.fidiumCustomerId,
                LeadId: lead.id,
                CustomerFeedbackId: null,
            };

            const req = {
                leadId: lead.id,
                userId: user.id,    
                fidiumCustomerId: lead.fidiumCustomerId,
                SpeedTest: speedTest,
            };

            const res = await FiberHouseApi.UpdateSpeedTestImages(req);
            if (res.status.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Speed Test Images Saved',
                    text: 'Speed test images have been successfully updated.',
                    customClass:{
                        container:"custom-swal"
                    },
                    didOpen:() =>{
                        Swal.hideLoading()
                    }
                });
                setOpen(false);
            } else {
                throw new Error(res.status.message);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to Save',
                text: error.message,
            });
        }
    };

    return (
        <Modal setOpen={setOpen} open={open} onClose={() => setOpen(false)}>
            <div className='custom_modal'>
            <IoCloseCircle onClick={() => {
                        setOpen(false)
                    }} size={30} style={{marginLeft:"auto",cursor:"pointer"}} color='#2761d0' />
                <h1>Speed Test Challenge</h1>
                <h3>Earn 10$ Gift Card</h3>
                <div className='modal-content'>
                    <div className='image-container'>
                        <div className='image-box'>
                            {beforeImageUrl ? (
                                <div className='image-wrapper'>
                                    <img src={beforeImageUrl} alt="Before Speed Test" className='image-display' />
                                    <button className='edit-button' onClick={() => document.getElementById('beforeImageUpload').click()}>
                                        <FaEdit />
                                    </button>
                                    <input
                                        type="file"
                                        id="beforeImageUpload"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={handleBeforeImageUpload}
                                    />
                                </div>
                            ) : (
                                <div className='upload-box'>
                                    <label htmlFor="beforeImageUpload" className='upload-label'>Upload Before Image</label>
                                    <input
                                        type="file"
                                        id="beforeImageUpload"
                                        accept="image/*"
                                        onChange={handleBeforeImageUpload}
                                    />
                                </div>
                            )}
                        </div>

                        <div className='image-box'>
                            {afterImageUrl ? (
                                <div className='image-wrapper'>
                                    <img src={afterImageUrl} alt="After Speed Test" className='image-display' />
                                    <button className='edit-button' onClick={() => document.getElementById('afterImageUpload').click()}>
                                        <FaEdit />
                                    </button>
                                    <input
                                        type="file"
                                        id="afterImageUpload"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={handleAfterImageUpload}
                                    />
                                </div>
                            ) : (
                                <div className='upload-box'>
                                    <label htmlFor="afterImageUpload" className='upload-label'>Upload After Image</label>
                                    <input
                                        type="file"
                                        id="afterImageUpload"
                                        accept="image/*"
                                        onChange={handleAfterImageUpload}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <button onClick={saveSpeedTestImages}>Save</button>
            </div>
        </Modal>
    );
};

export default SpeedTestModal;
