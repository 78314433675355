import React, { useState, useEffect } from 'react'
import FreeMonthFormReq from '../../../../../Requests/Customer/FreeMonthFormReq'
import Swal from 'sweetalert2'
import CustomerApi from '../../../../../API/CustomerApi'
import "./FreeMonthForm.scss"
import { MdFileUpload } from "react-icons/md";
import { Rating } from '@mui/material'
import { MdVerifiedUser } from "react-icons/md";
// import { MapContext } from '../..'
import { uploadFileToFirebase } from '../../../../../Services/DisplayFile'


const FreeMonthForm = ({ lead, setIndex, searchFiberHouses, user }) => {
    const [Verified, setVerified] = useState(false);
    const [currentInternetProvider, setCurrentInternetProvider] = useState("");
    const [currentInternetSpeed, setCurrentInternetSpeed] = useState("");
    const [currentInternetPrice, setCurrentInternetPrice] = useState("");
    const [speedTestBeforeImage, setSpeedTestBeforeImage] = useState(null);
    const [isBundle, setIsBundle] = useState(false);
    const [bundleDetails, setBundleDetails] = useState("");
    const [bundlePrice, setBundlePrice] = useState("");
    const [otherInterests, setOtherInterests] = useState("");
    const [productKnowledge, setProductKnowledge] = useState();
    const [communicationSkills, setCommunicationSkills] = useState();
    const [kindAndProfessional, setKindAndProfessional] = useState();
    const [recommendationLikeliness, setRecommendationLikeliness] = useState();
    const [recommendation, setRecommendation] = useState(0);
    // const map = useContext(MapContext)
    useEffect(() => {
        setCurrentInternetProvider(lead?.freeMonthForm?.currentInternetProvider);
        setCurrentInternetSpeed(lead?.freeMonthForm?.currentInternetSpeed);
        setCurrentInternetPrice(lead?.freeMonthForm?.currentInternetPrice);
        setSpeedTestBeforeImage(lead?.freeMonthForm?.speedTestBeforeImage);
        setIsBundle(lead?.freeMonthForm?.isBundle ? lead?.freeMonthForm?.isBundle : false);
        setBundleDetails(lead?.freeMonthForm?.bundleDetails);
        setBundlePrice(lead?.freeMonthForm?.bundlePrice);
        setOtherInterests(lead?.freeMonthForm?.otherInterests);
        setProductKnowledge(lead?.freeMonthForm?.productKnowledge);
        setCommunicationSkills(lead?.freeMonthForm?.communicationSkills);
        setKindAndProfessional(lead?.freeMonthForm?.kindAndProfessional);
        setRecommendationLikeliness(lead?.freeMonthForm?.recommendationLikeliness);
    }, [lead])

    const submitFreeMonthForm = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Submitting Free Month Form',
            timer: 1500,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!Verified && (lead.freeMonthForm?.id == null)) {
            // if (!currentInternetProvider || !currentInternetSpeed || !currentInternetPrice
            //     || !speedTestBeforeImage || !isBundle || !bundleDetails || !bundlePrice || !otherInterests
            //     || !productKnowledge || !communicationSkills ||
            //     !kindAndProfessional || !recommendationLikeliness) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Error',
            //         text: 'Please fill out all required fields',
            //         timer: 1500,
            //         didOpen: () => {
            //             Swal.hideLoading()
            //         }
            //     })
            //     return;
            // }
            if (!currentInternetProvider) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please fill out Current Internet Provider',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!currentInternetSpeed) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please fill out Current Internet Speed Test',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!currentInternetPrice) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please fill out Current Internet Price',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!speedTestBeforeImage) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please upload Speed Test Before Image',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (isBundle === null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please select Is Bundle',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!bundleDetails) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please select Bundle Details',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!bundlePrice) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please select Bundle Price',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!otherInterests) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please select Other Interests',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!productKnowledge) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please fill out Product Knowledge',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!communicationSkills) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please fill out Communication Skills',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!kindAndProfessional) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please fill out Kind and Professional',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
            if (!recommendationLikeliness) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please fill out Recommendation Likeliness',
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                return;
            }
        }

        let req = new FreeMonthFormReq();
        req.Id = lead.freeMonthForm?.id ? lead.freeMonthForm.id : 0;
        req.LeadId = lead.id;
        req.FidiumCustomerId = lead.fidiumCustomerId ? lead.fidiumCustomerId : 0;
        req.CurrentInternetProvider = currentInternetProvider;
        req.CurrentInternetSpeed = currentInternetSpeed;
        req.CurrentInternetPrice = currentInternetPrice;
        req.SpeedTestBeforeImage = await uploadFileToFirebase(speedTestBeforeImage, `freeMonthForm/leads/${lead.id}/SpeedTestBeforeImage/${speedTestBeforeImage.name}`);
        req.IsBundle = isBundle;
        req.BundleDetails = bundleDetails;
        req.BundlePrice = bundlePrice;
        req.OtherInterests = otherInterests;
        req.ProductKnowledge = productKnowledge;
        req.CommunicationSkills = communicationSkills;
        req.KindAndProfessional = kindAndProfessional;
        req.RecommendationLikeliness = recommendationLikeliness;
        console.log(req);

        let res = null;
        if (lead.freeMonthForm?.id) {
            res = await CustomerApi.EditFreeMonthForm(req);
        }
        else {
            res = await CustomerApi.CreateFreeMonthForm(req);
        }
        // let res = await CustomerApi.CreateFreeMonthForm(req);
        if (res?.status?.success) {
            searchFiberHouses();
            Swal.fire({
                icon: 'success',
                title: 'Success',
                timer: 1500,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    const Verify = async () => {
        if (Verified) {
            Swal.fire({
                icon: "info",
                title: "Verified",
                text: "You have already verified this form",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }
        // show an input for a secret code "1234" and a button to submit. 
        // if the code is correct, then the user can proceed to the free month page. (setIndex(12))
        let confirmation = await Swal.fire({
            title: "ENTER 4 DIGIT CODE",
            text: "FOR FIBER SPECIALIST ONLY",
            // input: "password",
            html: `
            <h2 class="CodeInputsHeader">FOR FIBER SPECIALIST ONLY</h2>
            <div class="CodeInputs">
            <input type="password" id="code1" maxlength="1" autofocus>
            <input type="password" id="code2" maxlength="1">
            <input type="password" id="code3" maxlength="1">
            <input type="password" id="code4" maxlength="1">
            </div>
        `,
            showCancelButton: true,
            confirmButtonText: "Submit",
            confirmButtonColor: "#2761D0", // Change the color here
            cancelButtonText: "Cancel",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.hideLoading();
                // Add event listeners to move focus to the next input field
                document.getElementById('code1').addEventListener('input', moveFocus);
                document.getElementById('code2').addEventListener('input', moveFocus);
                document.getElementById('code3').addEventListener('input', moveFocus);
                // Add event listeners to handle backspace key
                document.getElementById('code2').addEventListener('keydown', handleBackspace);
                document.getElementById('code3').addEventListener('keydown', handleBackspace);
                document.getElementById('code4').addEventListener('keydown', handleBackspace);
            },
            willClose: () => {
                // Remove event listeners when the Swal modal closes
                document.getElementById('code1').removeEventListener('input', moveFocus);
                document.getElementById('code2').removeEventListener('input', moveFocus);
                document.getElementById('code3').removeEventListener('input', moveFocus);
                // Remove event listeners for backspace key
                document.getElementById('code2').removeEventListener('keydown', handleBackspace);
                document.getElementById('code3').removeEventListener('keydown', handleBackspace);
                document.getElementById('code4').removeEventListener('keydown', handleBackspace);
            }
        })

        if (!confirmation.isConfirmed) {
            Swal.fire({
                icon: "info",
                title: "Cancelled",
                text: "You have cancelled the process",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return;
        }

        const code1 = document.getElementById("code1").value;
        const code2 = document.getElementById("code2").value;
        const code3 = document.getElementById("code3").value;
        const code4 = document.getElementById("code4").value;

        const enteredCode = code1 + code2 + code3 + code4;

        if (enteredCode === "1234") {
            setVerified(true);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Invalid Code",
                text: "The code you entered is incorrect",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }
    const moveFocus = (event) => {
        const target = event.target;
        if (target.value.length === target.maxLength) {
            const nextInput = target.nextElementSibling;
            if (nextInput) {
                nextInput.focus();
            }
        }
    }
    const handleBackspace = (event) => {
        const target = event.target;
        if (event.key === 'Backspace' && target.value.length === 0) {
            const prevInput = target.previousElementSibling;
            if (prevInput) {
                prevInput.focus();
                prevInput.value = ''; // Clear the value of the previous input
            }
        }
    }
    useEffect(() => {
        console.log(Verified);
    }, [Verified])


    // Function to handle checkbox change
    const handleBundleDetailsChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            // If checkbox is checked, add the value to objectionNotes
            setBundleDetails((prev) => (prev ? `${prev},${value}` : value));
        } else {
            // If checkbox is unchecked, remove the value from objectionNotes
            setBundleDetails((prev) =>
                prev
                    ? prev
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    };

    const bundleDetailsOptions = [
        "Internet",
        "Home Phone",
        "TV",
        "Security",
    ]

    const handleBundlePriceChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setBundlePrice((prev) => (prev ? `${prev},${value}` : value));
        }
        else {
            setBundlePrice((prev) =>
                prev
                    ? prev
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    }

    const bundlePriceOptions = [
        "$300+",
        "$250-$300",
        "$200-$250",
        "$150-$200",
        "$100-$150",
        "Below $100"
    ]

    const handleOtherInterestsChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setOtherInterests((prev) => (prev ? `${prev},${value}` : value));
        }
        else {
            setOtherInterests((prev) =>
                prev
                    ? prev
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    }

    const otherInterestsOptions = [
        "TV",
        "Home Phone",
        "Cell Phone",
        "Home Automation",
        "Security",
    ]


    return (
        <div className="FreeMonthForm">
            <div className="HeaderDiv">
                <h2>Free Month Form</h2>
            </div>
            <div className="CurrentInternetProvider">
                <label htmlFor="currentInternetProvider">Current Internet Provider</label>
                <input type="text" value={currentInternetProvider} onChange={(e) => setCurrentInternetProvider(e.target.value)} />
            </div>
            <div className='internetSpeedAndPrice'>
                <div className="">
                    <label htmlFor="currentInternetSpeed">Current Internet Speed Test</label>
                    <input type="text" value={currentInternetSpeed} onChange={(e) => setCurrentInternetSpeed(e.target.value)} />
                </div>
                <div className="">
                    <label htmlFor="currentInternetPrice">Current Internet Price</label>
                    <input type="text" value={currentInternetPrice} onChange={(e) => setCurrentInternetPrice(e.target.value)} />
                </div>
            </div>
            <div className='speedTestHeading'>
                <h3>Speed Test Before Image</h3>
            </div>
            <label htmlFor="speedTestBeforeImage" className="speedTestImage">
                <MdFileUpload size={30} color='#022780' />
                <input type="file" id="speedTestBeforeImage" onChange={(e) => setSpeedTestBeforeImage(e.target.files[0])} />
            </label>

            <div className="isBundleContainer">
                <label htmlFor="isBundle">Is Bundle</label>
                <div className="IsBundleRadioDiv">
                    <div>
                        <input
                            type="radio"
                            id="isBundleYes"
                            name="isBundle"
                            value="yes"
                            checked={isBundle}
                            onChange={() => setIsBundle(true)}
                        />
                        <label htmlFor="isBundleYes">Yes</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="isBundleNo"
                            name="isBundle"
                            value="no"
                            checked={!isBundle}
                            onChange={() => setIsBundle(false)}
                        />
                        <label htmlFor="isBundleNo">No</label>
                    </div>
                </div>
            </div>
            <div className="bundleoptions">
                {
                    bundleDetailsOptions.map((option, index) => {
                        return (
                            <label className='container' key={index}>
                                {option}
                                <input
                                    type="checkbox"
                                    id={option}
                                    name={option}
                                    value={option}
                                    checked={bundleDetails?.split(',').includes(option)}
                                    onChange={handleBundleDetailsChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        )
                    })
                }
            </div>
            <div className="bundlePrice">
                <h3>Bundle Price</h3>
                <div className='bundlePriceOptions'>
                    {
                        bundlePriceOptions.map((option, index) => {
                            return (
                                <label className='container' key={index}>
                                    {option}
                                    <input
                                        type="checkbox"
                                        id={option}
                                        name={option}
                                        value={option}
                                        checked={bundlePrice?.split(',').includes(option)}
                                        onChange={handleBundlePriceChange}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            )
                        })
                    }
                </div>
            </div>
            <div className="otherInterestContainer">
                <h3>Other Interests</h3>
                <div className='otherInterestOptions'>
                {
                    otherInterestsOptions.map((option, index) => {
                        return (
                            <label className='container' key={index}>
                                {option}
                                <input
                                    type="checkbox"
                                    id={option}
                                    name={option}
                                    value={option}
                                    checked={otherInterests?.split(',').includes(option)}
                                    onChange={handleOtherInterestsChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        )
                    })
                }
                </div>
            </div>
            <div className='survey'>
                <h3>how did our fidium fiber specialist do?</h3>
            </div>
            <div className="survey_rating">
                <Rating size='large' precision={0.5} name="productKnowledge" value={productKnowledge} setValue={(e,value)=>setProductKnowledge(value)} label="Product Knowledge" />
                <h4 htmlFor="productKnowledge">Product Knowledge</h4>
                {/* <input type="number" value={productKnowledge} onChange={(e) => setProductKnowledge(e.target.value)} /> */}
            </div>
            <div className="survey_rating">
                <Rating size="large" precision={0.5} name="communicationSkills" value={communicationSkills} setValue={(e,value)=>setCommunicationSkills(value)} label="Communication Skills" />
                <h4 htmlFor="communicationSkills">Communication Skills</h4>
                {/* <input type="number" value={communicationSkills} onChange={(e) => setCommunicationSkills(e.target.value)} /> */}
            </div>
            <div className="survey_rating">
                <Rating size="large" precision={0.5} name="kindAndProfessional" value={kindAndProfessional} setValue={(e,value)=>setKindAndProfessional(value)} label="Kind and Professional" />
                <h4 htmlFor="kindAndProfessional">Kind and Professional</h4>
                {/* <input type="number" value={kindAndProfessional} onChange={(e) => setKindAndProfessional(e.target.value)} /> */}
            </div>
            <div className="survey_rating">
                <Rating size="large" precision={0.5} name="recommendationLikeliness" value={recommendationLikeliness} setValue={(e,value)=>setRecommendationLikeliness(value)} label="Recommendation Likeliness" />
                <h4 htmlFor="recommendationLikeliness">Recommendation Likeliness</h4>
           
                {/* <input type="number" value={recommendationLikeliness} onChange={(e) => setRecommendationLikeliness(e.target.value)} /> */}
            </div>
            <div className='recommendation'>
                <h3>How Likely is it for you to recommend these services?</h3>
                <div className='scale'>
                    {
                        [1, 2, 3, 4, 5,6,7,8,9,10].map((num, index) => {
                            return (
                                <label htmlFor={`recommend${num}`} key={index} className={`scaleItem ${num==recommendation?'active':''}`}>
                                    <span>{num}</span>

                                    <input id={`recommend${num}`} type="radio" name="recommendation" value={num} onChange={(e)=>{setRecommendation(e.target.value);console.log(e.target.value)}}  />
                                </label>
                            )
                        })
                    }
                    <div className='label'>
                        <h3>I Don't Know</h3>
                    </div>
                    <div className='label second'>
                        <h3>Extremely Likely</h3>
                    </div>
                </div>
                
            </div>
            <div className='buttonGroup'>
                <button style={{ border: Verified ? "1px solid green" : "", padding: "10px", margin: "10px" }}
                    onClick={() => Verify(null)}><MdVerifiedUser size={40} color={Verified?'#20C877':"grey"} /><span>Verified</span></button>
                <button onClick={() => submitFreeMonthForm()}>Submit Free Month Form</button>
            </div>
        </div>
    )
}

export default FreeMonthForm