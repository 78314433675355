/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Cancel } from '@mui/icons-material';
import "./Datatable.scss";

export default function Index({ headers, list, totalPages, currentPage, setCurrentPage }) {
  const [files, setFiles] = React.useState([]);

  useEffect(() => {
    const l = [];

    list?.map((row) => {
      l.push({
        files: row?.files?.map((file, index3) => {
          if (file) { // Add a null check here
            return file
          } else {
            return null;
          }
        }).filter(file => file !== null) // Filter out any null values
      })
    });
    setFiles(l);
  }, [list]);

  return (
    <div className='mainTableContainer'>
      <TableContainer className='tableContainer'>
        <Table aria-label="simple table">
          <TableHead sx={{
            display: "contents",
            width: "max-content",
          }}>
            <TableRow style={{
              width: "max-content",
            }}>
              {
                headers?.map((head, key) => (
                  <TableCell
                    key={key}
                    colSpan={head.name === "Files" ? 3 : 1}
                    style={{ color: "#5E6282", textAlign: head?.type === "number" ? "center" : "initial", fontWeight: "700", fontSize: "1rem", textTransform: "uppercase", width: "max-content" }}
                  >
                    {head.name}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              list?.map((row, index) => {
                const style = {
                  textAlign: "center",
                };
                return (
                  <TableRow key={index}>
                    {headers?.map((head, index1) => {
                      if (head.key !== "files") {
                        return (
                          <TableCell style={head?.type === "number" ? style : null} colSpan={1} key={head.key}>
                            {head.render ? head.render(row) : row[head.key]}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell colSpan={3} key={head.key} className='flex justify-center items-center gap-4'>
                            {
                              files?.length > 0 && files[index]?.files.length > 0 ?
                                files[index].files.map((file, fileIndex) => {
                                  return file.fileType.includes("image") ?
                                    <div key={fileIndex} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "10px", overflow: "hidden", width: "fit-content", position: "relative" }}>
                                      <Cancel style={{ position: "absolute", top: "0", right: "0", color: "white", cursor: "pointer" }} />
                                      <img src={file.url} alt="" style={{ width: "56px", height: "30px" }} />
                                    </div> :
                                    file.fileType.includes("video") ?
                                      <div key={fileIndex} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                        <video src={file.url} controls style={{ width: "100px", height: "100px" }} />
                                        <p>{file.name}</p>
                                      </div> :
                                      file.fileType.includes("pdf") ?
                                        <div key={fileIndex} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                          <img src={"/images/extensions/pdf.png"} alt="" style={{ width: "100px", height: "100px" }} />
                                          <a href={file.url} target="_blank" rel="noreferrer" style={{ color: "black", fontSize: "12px" }}>{file.name}</a>
                                        </div> :
                                        <iframe title='frame' key={fileIndex} src={file.url} style={{ width: "100px", height: "100px" }}></iframe>
                                }) : ""
                            }
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
