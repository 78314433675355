import React, { useEffect } from 'react'
import GenerateAffiliateCodeReq from '../../../Requests/ReferralCode/GenerateAffiliateCodeReq';
import UsersApi from '../../../API/UsersApi';
import Swal from 'sweetalert2';
import CustomerCard from './../../CustomerCard';
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq';
import CustomerApi from '../../../API/CustomerApi';
import { CircularProgress } from '@mui/material';
import { MdOutlineContentCopy, MdOutlineEmail, MdOutlineTextsms, MdOutlineWhatsapp } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';
import CreateLightCustomerReq from '../../../Requests/Customer/CreateLightCustomerReq';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
const Refer = ({ lead }) => {

    const [customer, setCustomer] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const auth = getAuth();
    const [text, setText] = React.useState(`Hey ${lead?.fidiumCustomer?.firstName}! ${customer ? customer.salesRep.firstName : lead.fidiumCustomer.salesRep.firstName} here again, I hope we were able to fulfill our promise of a better internet solution! \n\nI wanted to let you in on our “Refer a Friend Program” that will put a $100 Visa Cash Card in your wallet for everyone you refer. \n\nIt’s easy, fast, and rewarding; just open your referral card, click a button to send a link via text, email, or WhatsApp, and we do the rest! \n\nUnlimited Referrals, and the best part is they all will get a FREE MONTH and/or FREE INSTALL! \n\nClick below to activate your referral link - and refer a friend today!\n\nhttps://lightningos.world/signin`)
    const searchFidiumCustomer = async () => {
        setLoading(true)
        const req = new SearchCustomerReq()
        req.id = lead.fidiumCustomerId
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 1
        const res = await CustomerApi.SearchFidiumCustomers(req)
        if (res?.status?.success) {
            setCustomer(res.data.list[0])
            setFirstName(res.data.list[0]?.firstName)
            setLastName(res.data.list[0]?.lastName)
            setEmail(res.data.list[0]?.email)
            setPhone(res.data.list[0]?.cellPhone)
            setLoading(false)
        }
    }

    useEffect(() => {
        searchFidiumCustomer()
    }, [lead])

    async function UpdateUserCode() {

        Swal.fire({
            title: "Generating Referral Code",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: "custom-swal"
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        let req = new GenerateAffiliateCodeReq();
        req.userId = lead?.fidiumCustomer?.user.id;
        let response = await UsersApi.GenerateAffiliateCode(req);

        console.log(response)
        if (response.status.success) {
            Swal.fire({
                icon: "success",
                title: "Referral Code Generated",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            searchFidiumCustomer()
        }
        else {
            Swal.fire({
                icon: "error",
                title: response.status.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }
    const whtatsApp = () => {
        window.open(`https://wa.me/${lead.fidiumCustomer.cellPhone ? lead.fidiumCustomer.cellPhone : lead.phone}?text=${text}`, '_blank')
    }

    const sendEmail = () => {
        window.open(`mailto:${lead.fidiumCustomer.email}?subject=Feedback Form&body=${text}`, '_blank')
    }

    const sms = () => {
        window.open(`sms:${lead.fidiumCustomer.cellPhone ? lead.fidiumCustomer.cellPhone : lead.phone}?&body=${text}`, '_blank')
    }

    const copy = () => {
        navigator.clipboard.writeText(text)
        Swal.fire({
            icon: 'success',
            title: 'Copied to clipboard',
            customClass: {
                container: 'custom-swal'
            },
            toast: true,
            position: 'top',
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    }


    const createAnAccount = async  () => {
        Swal.fire({
            title: "Creating Account",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: "custom-swal"
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if(firstName === '' || !firstName){
            Swal.fire({
                icon: "error",
                title: "First Name is required",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(lastName === '' || !lastName){
            Swal.fire({
                icon: "error",
                title: "Last Name is required",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(email === '' || !email){
            Swal.fire({
                icon: "error",
                title: "Email is required",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        var pattern = new RegExp(/^\S+@\S+\.\S+$/);
        if(!pattern.test(email)){
            Swal.fire({
                icon: "error",
                title: "Invalid Email",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(phone === '' || !phone){
            Swal.fire({
                icon: "error",
                title: "Phone is required",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(phone.length < 11){
            Swal.fire({
                icon: "error",
                title: "Invalid Phone Number",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateLightCustomerReq()
        req.firstName = firstName
        req.lastName = lastName
        req.email = email
        req.phoneNumber = phone
        req.customerId = customer.id

        console.log(req)

        var res = await CustomerApi.CreateUserAccountForCustomer(req)

        if(res.status.success){
            Swal.fire({
                icon: "success",
                title: "Account Created",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            sendPasswordResetEmail(auth, email)
            .then((value) => {
              // Password reset email sent!
              // ..
      
            //   Swal.fire({
            //     icon: 'success',
            //     title: 'Password reset email sent',
            //     text: 'Please check your email',
            //   });
            })
            .catch((error) => {
              // const errorCode = error.code;
              const errorMessage = error.message;
      
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
              });
            });

            searchFidiumCustomer()
        }
        else{
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }


    return (
        <div className='form' style={{
            paddingRight: '0px',
        }}>
            <h2>Referral Program</h2>
            {
                loading ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px'
                    }}>
                        <CircularProgress size={30} sx={{ color: "var(--primary-color)" }} />
                    </div>
                    :
                    customer?.user ?
                        customer.user?.affiliateCodeId ?
                            <>
                                <textarea style={{ height: "350px" }} value={text} onChange={(e) => {
                                    setText(e.target.value)
                                }} rows={15} />
                                <div className='send_review_options'>
                                    <p>Share the Link</p>
                                    <div className='send_options'>
                                        <MdOutlineEmail onClick={sendEmail} className='icon' color='#84A1C8' size={40} />
                                        <MdOutlineTextsms onClick={sms} className='icon' color='#84A1C8' size={40} />
                                        <MdOutlineWhatsapp onClick={whtatsApp} className='icon' color='#84A1C8' size={40} />
                                        <MdOutlineContentCopy onClick={copy} className='icon' color='#84A1C8' size={40} />
                                    </div>
                                </div>
                                <CustomerCard customer={customer} />

                            </>
                            :
                            <>
                                <p>This Customer does not have a Referral Link.</p>
                                <button onClick={() => {
                                    UpdateUserCode()
                                }}>Generate Referral Link</button>
                                <div className='customer_info'>
                                    <h3>Customer</h3>
                                    <p>{customer?.firstName + " " + customer?.lastName}</p>
                                    <p>{customer?.email}</p>
                                    <p>{customer?.cellPhone}</p>
                                </div>
                                <CustomerCard customer={customer} />

                            </>
                        :
                        <>
                            <p className='note'>Note: This Customer does not have an account.</p>
                            <div className='create_account_form'>
                                <h2>Create an Account</h2>

                                <div className='inputs'>
                                    <input className='input' type='text' placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    <input className='input' type='text' placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    <input className='input' type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <PhoneInput containerStyle={{ width: "100%" }} inputStyle={{ width: "100%" }} country={'us'} value={phone} onChange={setPhone} />
                                    <button onClick={() => {
                                        createAnAccount()
                                    }}>Create Account</button>
                                </div>
                            </div>
                        </>
            }
        </div>
    )
}

export default Refer