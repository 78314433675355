import React, { useContext, useEffect, useState } from 'react'
import {
  Modal,
  Typography,
  TextField,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material'
import Select from 'react-select'
import { Email, Sms, ContentCopy, Send } from '@mui/icons-material'
import './InvitationModal.scss'

// Import your images here
import salesRep1 from '../../../../../assets/images/Sales_Rep_2.png'
import salesRep2 from '../../../../../assets/images/Sales_Rep_3.png'
import salesRep3 from '../../../../../assets/images/Sales_Rep_4.png'
import { set } from 'ol/transform'
import Swal from 'sweetalert2'
import { UserContext } from '../../../../../App'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import FiberCompanyApi from '../../../../../API/FiberCompanyApi'
import SendCandidateInvitationReq from '../../../../../Requests/FiberHouse/SendCandidateInvitationReq'


const InvitationModal = ({ open = false, onClose = () => { }, isStatEmail, isStatSMS, candidateId, filteredCandidateIds, candidateType }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templates, setTemplates] = useState([])
  const [messageText, setMessageText] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const user = useContext(UserContext);


  const fetchRecruitTemplates = async () => {
    try {
      let response = await FiberCompanyApi.GetRecruitTemplates();
      if (response.status.success) {
        const filteredTemplates = response.data.filter(template =>
          template.isVisible === true && template.candidateType === candidateType
        );

        const formattedTemplates = filteredTemplates.map(template => ({
          label: template.templateName,
          value: template.id,
          text: template.templateText,
          imageUrl: template.templateImage?.url,
          positionTitle: template.positionTitle,
          candidateType: template.candidateType,
          jobDescription: template.jobDescription,
          jobRequirements: template.jobRequirements.split('\n').map(req => req),
          fullData: template
        }));
        setTemplates(formattedTemplates);
        console.log(response.data);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRecruitTemplates()
  }, [candidateType])

  const handleTemplateChange = (selected) => {
    setSelectedTemplate(selected)
    setMessageText(selected.text)
  }

  const sendInvitationEmail = async () => {

    //show swal confirm message 
    Swal.fire({
      title: 'Send Invitation',
      text: 'Are you sure you want to send this invitation?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336'
    }).then(async (result) => {
      if (result.isConfirmed) {
        //swal loader
        Swal.fire({
          title: 'Sending Invitation',
          text: 'Please wait...',
          icon: 'info',
          didOpen: () => {
            Swal.showLoading()
          }
        })



        let req = new SendCandidateInvitationReq()
        req.candidateId = candidateId
        req.templateId = selectedTemplate.value
        req.userId = user.id

        console.log(req)

        let res = await FiberHouseApi.SendCandidateInvitation(req)
        if (res.status.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Invitation sent successfully',
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.status.message,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        }

      } else {
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'Invitation not sent',
        })
      }

    })


  }

  const sendBulkInvitationEmails = () => {
    Swal.fire({
      title: 'Send Invitation',
      text: 'Are you sure you want to send these invitations?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336'
    }).then(async (result) => {
      if (result.isConfirmed) {
        //swal loader
        Swal.fire({
          title: 'Sending Invitations',
          text: 'Please wait...',
          icon: 'info',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading()
          }
        })



        let req = new SendCandidateInvitationReq()
        req.candidateIds = filteredCandidateIds
        req.templateId = selectedTemplate.value
        req.userId = user.id

        console.log(req)

        let res = await FiberHouseApi.BulkSendCandidateInvitations(req)
        if (res.status.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: res.status.message,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.status.message,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        }

      } else {
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'Invitation not sent',
        })
      }

    })
  }

  const sendInvitationSMS = () => {
    // Implement SMS functionality here
    console.log("Send SMS")
  }

  const sendBulkInvitationSMS = () => {
    // Implement copy link functionality here
    console.log("Copy link")
  }

  const handleCopyLink = () => {
    // Implement copy link functionality here
    console.log("Copy link")
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="invitation-modal"
    >
      <div className={`invitation-modal ${isMobile ? 'mobile' : ''}`}>
        <div className="scrollable-content">
          <div className="content">
            <Select
              options={templates}
              onChange={handleTemplateChange}
              value={selectedTemplate}
              placeholder="Choose a template"
              className="template-select"
              menuPortalTarget={document.body} // renders the dropdown in the body element
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensure a high z-index for the portal
              }}
            />
            {selectedTemplate && (
              <div className="preview-image">
                <img src={selectedTemplate?.imageUrl} alt="Selected template preview" />
              </div>
            )}



            {selectedTemplate?.candidateType === 'Recruit' && (
              <>
                <TextField
                  label="Position Title"
                  fullWidth
                  value={selectedTemplate?.positionTitle}
                  disabled
                  className="message-field"
                />
              </>
            )}

            {selectedTemplate?.candidateType === 'Recruit' && (
              <>
                <TextField
                  label="Job Description"
                  fullWidth
                  value={selectedTemplate?.jobDescription}
                  disabled
                  className="message-field"
                />
              </>
            )}


            {selectedTemplate?.candidateType === 'Recruit' && (
              <>
                {selectedTemplate?.jobRequirements.map((req, index) => (
                  <div key={index} className="message-field">
                    <TextField
                      label={`Requirement ${index + 1}`}
                      fullWidth
                      value={req}
                      disabled
                      className="message-field"
                    />
                  </div>
                ))}
              </>
            )}

            {selectedTemplate?.candidateType === 'Company' && (
                <textarea
                value={selectedTemplate?.text || ''}
                disabled
                className="message-field"
                rows="4"
                style={{
                  width: '100%',
                  boxSizing: 'border-box', // Ensures padding is included within the width
                  padding: '8px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  resize: 'none' // Prevents resizing by the user to avoid modal overflow
                }}
              />
            )}


            <div className="action-buttons">
              {isStatEmail && (
                <IconButton onClick={sendBulkInvitationEmails} className="action-button" disabled={selectedTemplate === null}>
                  <Send />
                  <Typography variant="caption">Send Emails</Typography>
                </IconButton>
              )}
              {!isStatEmail && (
                <IconButton onClick={sendInvitationEmail} className="action-button" disabled={selectedTemplate === null}>
                  <Email />
                  <Typography variant="caption">Email</Typography>
                </IconButton>
              )}
              {!isStatEmail && (
                <IconButton onClick={sendInvitationSMS} className="action-button" disabled={selectedTemplate === null}>
                  <Sms />
                  <Typography variant="caption">SMS</Typography>
                </IconButton>
              )}
              <IconButton onClick={sendBulkInvitationSMS} className="action-button" disabled={selectedTemplate === null}>
                <ContentCopy />
                <Typography variant="caption">Copy Link</Typography>
              </IconButton>
            </div>
          </div>
        </div>

      </div>
    </Modal>
  )
}

export default InvitationModal