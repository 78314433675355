import React, { useState, useRef, useEffect } from 'react'
import { FaPlay } from "react-icons/fa"
import { FaPause } from "react-icons/fa"
import './AudioPlayer.scss'
const AudioPlayer = ({ src,maxTime }) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(maxTime);
  const [currentTime, setCurrentTime] = useState(0);

  // references
  const audioPlayer = useRef();   // reference our audio component
  const progressBar = useRef();   // reference our progress bar
  const animationRef = useRef();  // reference the animation

  // useEffect(() => {
  //   setCurrentTime(100);
  //   setIsPlaying(true);
  //   audioPlayer.current.play();
  //   setTimeout(() => {
  //     setCurrentTime(0);
  //     setIsPlaying(false);
  //     audioPlayer.current.pause();
  //   }, 2000);
  // }, [])

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationRef.current);
    }
  },[])

  useEffect(() => {
      const seconds = Math.floor(audioPlayer.current.duration);
      // setDuration(parseInt(seconds));
      console.log("seconds", seconds)
      progressBar.current.max = parseInt(duration);
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);


  const onLoadedMetadata = (e) => {
    console.log("onLoadedMetadata", e)
    // const seconds = Math.floor(audioPlayer.current.duration);
    // setDuration(parseInt(seconds));
    // console.log("seconds", seconds)
    // progressBar.current.max = parseInt(seconds);
  }

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }

  const whilePlaying = () => {
    if(audioPlayer && progressBar && audioPlayer.current && progressBar.current)
    {

      progressBar.current.value = audioPlayer.current.currentTime;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }

  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value);
  }

  return (
    <div className={"audioPlayer"}>
      <audio ref={audioPlayer} onLoadedMetadata={onLoadedMetadata} src={src} preload="metadata"></audio>
      <button onClick={togglePlayPause} className={"playPause"}>
        {isPlaying ? <FaPause /> : <FaPlay className={"play"} />}
      </button>


      {/* current time */}
      <div className={"currentTime"}>{calculateTime(currentTime)}</div>

      {/* progress bar */}
      <div style={{
        width: "100%",
      }}>
        <input type="range" className={"progressBar"} defaultValue="0" ref={progressBar} onChange={changeRange} />
      </div>

      {/* duration */}
      <div className={"duration"}>{isNaN(duration)?calculateTime(0):calculateTime(duration)}</div>
    </div>
  )
}

export { AudioPlayer }