import React from 'react'
import "./CustomerCard.scss"
const Index = ({ customer }) => {
  return (
    <div className='customer_card'>
      <div className='card_top'>
        <div className='profile_image'>
          {
            customer?.profileImage ?
              <img src={customer.profileImage.url} alt="profile" />
              :
              <img src="/images/Avatar/profile.jpg" alt="profile" />
          }
        </div>
        < div className='profile_info'>
          <h2>{customer?.firstName + " " + customer?.lastName}</h2>
          <p>Lightning OS Customer</p>
          <p>{customer?.address}</p>
        </div>
      </div>
      <div className='logo'>
        <img src="/images/nav/nav-logo.png" alt="logo" />
      </div>
      <div className='company_plan'>
        <div className='company'>
          <h3>My Connection</h3>
          <div className='company_logo'>
            <img src={"data:image/png;base64," + customer?.fiberCompany?.logo} alt="logo" />
          </div>

        </div>
        <div className='plan'>
          <h3>My Plan</h3>
          <div className='plan_name'>
            <p>{customer?.fiberPlan?.name}</p>
          </div>
        </div>
      </div>
      <div className='coming_soon'>
        <img src='/images/digital_tool_belt/silver_lightning.svg' alt="icon" /> TV BUNDLES COMING SOON
      </div>
      <div className='my_specialist'>
        <h3>My Fiber Specialist</h3>
        <div className='specialist_info'>
          <div className='profile_image'>
            {
              customer?.salesRep?.profileImage ?
                <img src={customer?.salesRep?.profileImage.url} alt="profile" />
                :
                <img src="/images/Avatar/profile.jpg" alt="profile" />
            }
          </div>
          <div className='specialist_name'>
            <h3>{customer?.salesRep?.firstName + " " + customer?.salesRep?.lastName}</h3>
            <p>
              {
                customer?.salesRep?.address ?
                  customer?.salesRep?.address
                  :
                  customer?.salesRep?.email    
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index