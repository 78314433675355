
class CreateComissionPlanReq {
    constructor() {
        this.id = 0;
        this.name = "string";
        this.description = "string";
        this.fiberCompanyId = "string";
        this.dealSavedPercentage = 0;
        this.dealSavedPay = 0;
        this.pagingParams = {
            pageNumber: 0,
            pageSize: 0
        };
    }
}

export default CreateComissionPlanReq;