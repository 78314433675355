class CreateCustomerReq {

    constructor() {
        this.FirstName = null;
        this.LastName = null;
        this.SecondaryAccountHolderFullName = null;
        this.Address = null;
        this.CellPhone = null;
        this.Email = null;
        this.DesiredInstallDateString = null;
        this.DesiredInstallTime = null;
        this.FourDigitPin = null;
        this.SecurityQuestion1 = null;
        this.SecurityQuestion2 = null;
        this.AnswerSecurityQuestion1 = null;
        this.AnswerSecurityQuestion2 = null;
        this.FiberPlanString = null;
        this.TimeZone = null;
        this.NotesToFiberCompanySpecialInstructions = null;
        this.PromoCode = null;
        this.FreeMonth = null;
        this.ConsentChecked = null;
        this.SpeedTestBeforeImage = null;
        this.SalesRepId = null;
        this.SalesOrgId = null;
        this.LeadId = null;
        this.offerId = null;
        this.IsHotLead = null;
        this.AffiliateCode = null;
        this.UserId = null;
        this.VerificationPhrase = null;
    }

}

export default CreateCustomerReq;