import React, { useContext, useEffect, useState } from 'react'
// import Swal from 'sweetalert2'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import EditLeadReq from '../../../../../Requests/FiberHouse/EditLeadReq'
import { Slider } from '@mui/material'
import './HotLeadForm.scss'
import { MapContext } from '../..'
const HotLeadForm = ({ fiberHouse, lead, searchFiberHouses }) => {
    console.log(lead)
    const [objections, setObjections] = useState();
    const [objectionNotes, setObjectionNotes] = useState();
    const [appointmentType, setAppointmentType] = useState();
    const [appointmentDateString, setAppointmentDateString] = useState();
    const [appointmentTimeString, setAppointmentTimeString] = useState();
    const [appointmentDateTime, setAppointmentDateTime] = useState();
    const [customerInterest, setCustomerInterest] = useState(null);
    const [notes, setNotes] = useState(lead?.notes);
    const map = useContext(MapContext)
    useEffect(() => {
        setObjections(lead?.objections);
        setObjectionNotes(lead?.objectionNotes);
        setAppointmentType(lead?.appointmentType);
        setAppointmentDateString(lead?.appointmentDateString);
        setAppointmentTimeString(lead?.appointmentTimeString);
        setAppointmentDateTime(lead?.appointmentDateTime ? new Date(lead?.appointmentDateTime) : null);
        setCustomerInterest(lead?.customerInterest);
        setNotes(lead?.notes);
    }, [lead])

    const updateLead = async (type) => {

        let req = new EditLeadReq();
        req.id = lead.id;

        switch (type) {
            case 'objections':
                req.objections = objections;
                break;
            case 'objectionNotes':
                req.objectionNotes = objectionNotes;
                break;
            case 'appointmentType':
                req.appointmentType = appointmentType;
                break;
            case 'appointmentDateString':
                req.appointmentDateString = appointmentDateString;
                break;
            case 'appointmentTimeString':
                req.appointmentTimeString = appointmentTimeString;
                break;
            case 'appointmentDateTime':
                req.appointmentDateTime = appointmentDateTime;
                break;
            case 'notes':
                req.notes = notes;
                break;
            case 'customerInterest':
                req.customerInterest = customerInterest;
                break;
            default:
                req.objections = objections;
                req.objectionNotes = objectionNotes;
                req.appointmentType = appointmentType;
                req.appointmentDateString = appointmentDateString;
                req.appointmentTimeString = appointmentTimeString;
                req.appointmentDateTime = appointmentDateTime;
                req.notes = notes;
                req.customerInterest = customerInterest;
                req.isHotLead = true;
        }

        req.isHotLead = true
        console.log(req);
        let res = await FiberHouseApi.EditLead(req);
        if (res?.status?.success) {
            searchFiberHouses();
            console.log('Updated')
        }
    }

    // Function to handle checkbox change
    const handleObjectionsChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            // If checkbox is checked, add the value to objectionNotes
            setObjections((prev) => (prev ? `${prev},${value}` : value));
        } else {
            // If checkbox is unchecked, remove the value from objectionNotes
            setObjections((prev) =>
                prev
                    ? prev
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    };

    const handleAppointmentTypeChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setAppointmentType((prev) => (prev ? `${prev},${value}` : value));
        }
        else {
            setAppointmentType((prev) =>
                prev
                    ? prev
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    }

    const handleDateStringChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setAppointmentDateString((prev) => (prev ? `${prev},${value}` : value));
        }
        else {
            setAppointmentDateString((prev) =>
                prev
                    ? prev
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    }

    const handleTimeStringChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setAppointmentTimeString((prev) => (prev ? `${prev},${value}` : value));
        }
        else {
            setAppointmentTimeString((prev) =>
                prev
                    ? prev
                        .split(',')
                        .filter((item) => item !== value)
                        .join(',')
                    : ''
            );
        }
    }



    useEffect(() => {
        console.log(objections);
        console.log(appointmentType);
    }, [objections, appointmentType, appointmentDateString, appointmentTimeString])

    const objectionOptions = [
        'Bundle',
        'Contract',
        'Spouse',
        'Text',
        'HOA',
        'LandLord',
        'Moving',
        'Not Ready',
        'Other'
    ];

    const appointmentTypeOptions = [
        'Comeback',
        'Call',
        'Text',
    ];

    const appointmentDateOptions = [
        'Weekdays',
        'Weekends',
        'ASAP',
        'Thursday',
        'Friday',
        'Saturday',
        'Monday',
        'Tuesday',
        'Wednesday'
    ];

    const appointmentTimeOptions = [
        'Morning',
        'Afternoon',
        'Evening'
    ];

    useEffect(
        () => {
            if (customerInterest) {
                const intervalId = setTimeout(() => {
                    updateLead('customerInterest');
                },1000)
                return () => clearInterval(intervalId)
            }
        }, [customerInterest]
    )

    useEffect(() => {
        if(objections){
            const intervalId = setTimeout(() => {
                updateLead('objections');
            },1000)
            return () => clearInterval(intervalId)
        }
    },[objections])

    useEffect(() => {
        if(objectionNotes){
            const intervalId = setTimeout(() => {
                updateLead('objectionNotes');
            },1000)
            return () => clearInterval(intervalId)
        }
    },[objectionNotes])

    useEffect(() => {
        if(appointmentType){
            const intervalId = setTimeout(() => {
                updateLead('appointmentType');
            },1000)
            return () => clearInterval(intervalId)
        }
    }
    ,[appointmentType])

    useEffect(() => {
        if(appointmentDateString){
            const intervalId = setTimeout(() => {
                updateLead('appointmentDateString');
            },1000)
            return () => clearInterval(intervalId)
        }
    }
    ,[appointmentDateString])

    useEffect(() => {
        if(appointmentTimeString){
            const intervalId = setTimeout(() => {
                updateLead('appointmentTimeString');
            },1000)
            return () => clearInterval(intervalId)
        }
    }
    ,[appointmentTimeString])

    useEffect(() => {
        if(appointmentDateTime){
            const intervalId = setTimeout(() => {
                updateLead('appointmentDateTime');
            },1000)
            return () => clearInterval(intervalId)
        }
    }
    ,[appointmentDateTime])

    useEffect(() => {
        if(notes){
            const intervalId = setTimeout(() => {
                updateLead('notes');
            },1000)
            return () => clearInterval(intervalId)
        }
    },[notes])

    return (
        <div className="HotLeadForm">

            <div className='HeaderDiv'>
                <h2>How Hot Is Your Lead?</h2>
                <div className="slider">
                    {/* <input type="number" value={customerInterest} onChange={(e) => setCustomerInterest(e.target.value)} /> */}
                    <Slider sx={{
                        '& .MuiSlider-thumb': {
                            backgroundColor: "#2761D0",
                            border: "2px solid #2761D0",
                            borderRadius: "0px",
                            outline: "none",
                            height: "45px",
                            width: "6px"
                        },
                        '& .MuiSlider-track': {
                            backgroundImage: "linear-gradient(310deg, #2761D0, #FF0000, #FFA410, #FFF500)",
                            height: "26px",
                            width: "100% !important",
                        },
                        '& .MuiSlider-label': {
                            borderRadius: "50%",
                            color: "white",
                            backgroundColor: "#2761D0",
                        }
                    }} value={customerInterest} min={0} max={10} step={1} onChange={(e, value) => setCustomerInterest(value)} />
                </div>
            </div>
            <div className="InputsDiv">

                <div className="Objection">
                    <h3 >Objections</h3>
                    <div className='objection_options'>
                        {objectionOptions.map((option, index) => {
                            return (
                                <label className='container' key={index}>

                                    {option}
                                    <input type="checkbox" value={option} onChange={handleObjectionsChange} checked={objections?.includes(option)} />
                                    <span class="checkmark"></span>
                                </label>
                            )
                        })}
                    </div>

                </div>
                <div className="ObjectionNotes">
                    <label >Objections Notes</label>
                    <input type="text" value={objectionNotes} onChange={(e) => setObjectionNotes(e.target.value)} />
                </div>
                <div className="LeadData">
                    <label htmlFor='notes'>Lead Data</label>
                    <textarea rows={5} value={notes} onChange={(e) => setNotes(e.target.value)} />
                </div>
                <div className='SubtitleDiv'>
                    <h3>Set Appointment</h3>
                </div>
                <div className="AppointmentType">
                    <label htmlFor="AppointmentType">Type</label>
                    {appointmentTypeOptions.map((option, index) => {
                        return (
                            <label className='container' key={index}>
                                {option}
                                <input type="checkbox" value={option} onChange={handleAppointmentTypeChange} checked={appointmentType?.includes(option)} />
                                <span class="checkmark"></span>
                            </label>
                        )
                    })}
                </div>
                <div className="AppointmentDate">
                    <h3 >Date</h3>
                    <div>
                        {appointmentDateOptions.map((option, index) => {
                            return (
                                <label className='container' key={index}>
                                    {option}
                                    <input type="checkbox" value={option} onChange={handleDateStringChange} checked={appointmentDateString?.includes(option)} />
                                    <span class="checkmark"></span>
                                </label>
                            )
                        })}
                    </div>

                </div>
                <div className="AppointmentTime">
                    <h3 htmlFor="appointmentTimeString">Time</h3>
                    {appointmentTimeOptions.map((option, index) => {
                        return (
                            <label className='container' key={index}>
                                {option}
                                <input type="checkbox" value={option} onChange={handleTimeStringChange} checked={appointmentTimeString?.includes(option)} />
                                <span class="checkmark"></span>
                            </label>
                        )
                    })}
                </div>
                <div className="appointmentDateTime">
                    <label htmlFor="appointmentDateTime">Specific Date and Time {
                        appointmentDateTime ?
                            <span>Current: {appointmentDateTime.toLocaleString()}</span> :
                            null
                    }
                    </label>
                    <input type="datetime-local" value={appointmentDateTime} onChange={(e) => setAppointmentDateTime(e.target.value)} />
                </div>
                {/* <div className='ButtonDiv'>
                    <button onClick={() => updateLead()}>SAVE AS HOT LEAD</button>
                </div> */}

            </div>
        </div>
    )
}

export default HotLeadForm