class AddFiberPlanToCommissionPlan {
    constructor() {
        this.fiberPlanId = null;
        this.CommisionPlanId = null;
        this.Level1Pay = null;
        this.Level2Pay = null;
        this.Level2Count = null;
        this.Overrides = null;
    }
}

export default AddFiberPlanToCommissionPlan;