import React from 'react'
import PublicLayout from "./../../../Layouts/PublicLayout"
import { useState, useEffect } from 'react'
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import Swal from 'sweetalert2'
import "./SignIn.scss"
import { useNavigate } from 'react-router-dom';
import OtpInput from 'otp-input-react';
import PhoneInput from 'react-phone-input-2'
import { CgSpinnerTwo } from "react-icons/cg";
import StringIntReq from '../../../Requests/StringIntReq';
import UsersApi from '../../../API/UsersApi';
const Index = () => {


  const [Email, SetEmail] = useState('');
  const [Password, SetPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const auth = getAuth();
  // auth.settings.appVerificationDisabledForTesting = true;
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [user] = useAuthState(getAuth());
  const [GoHome, SetGoHome] = useState(false);
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false)
  const [method, setMethod] = useState(2)
  useEffect(() => {
    if (user) {
      window.location.href = "/"
    }
  }, [user])


  function onCaptchaVerfiy() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // onSignIn()
        },
        'expired-callback': () => {

        }
      });
    }
  }

  async function onSignIn(e) {
    if(e)
    e.preventDefault();
    setLoading(true)
    onCaptchaVerfiy()
    const appVerifier = window.recaptchaVerifier;
    const formattedPhoneNumber = `+${phoneNumber.replace(/-/g, '')}`;
    // console.log(formattedPhoneNumber);
    const req = new StringIntReq();
    req.string = formattedPhoneNumber;
    const res = await UsersApi.IsPhoneNumberAlreadyRegistered(req);
    if (res?.status?.success) {
      signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier)
        .then((confirmationResult) => {
          // console.log('OTP sent');
          setLoading(false)
          setOtpSent(true);
          window.confirmationResult = confirmationResult;
        }).catch((error) => {
          console.error('Error during sign-in', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message,
          });
          setLoading(false)
        });
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res?.status?.message,
      });
      setLoading(false)
    }
  }

  async function onOtpVerify(e) {
    e.preventDefault();
    setLoading(true)
    await window.confirmationResult.confirm(otp);
    // console.log('User signed in', result.user);

    setLoading(false)
  }
  

  // useEffect(
  //   () =>{
  //     if (!document.getElementById('recaptcha-container').hasChildNodes()){
  //       window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
  //     }
  //   },[auth]
  // )

  //   const handleLogin = async (e) => {
  //     e.preventDefault();

  //     try {
  //       const confirmationResult = await signInWithPhoneNumber(auth, Email, window.recaptchaVerifier);

  //       // Save confirmationResult to use later for confirming the code
  //       window.confirmationResult = confirmationResult;
  //       handleVerifyCode();
  //     } catch (error) {
  //       console.error('Error during sign-in', error);
  //     }
  //  };

  //  const handleVerifyCode = async () => {
  //     // Assuming you have saved confirmationResult from signInWithPhoneNumber
  //     try {
  //       let verificationCode = window.prompt('Please enter the verification ' + 'code that was sent to your mobile device.');
  //       const result = await window.confirmationResult.confirm(verificationCode);
  //       // User is signed in
  //       console.log('User signed in', result.user);
  //     } catch (error) {
  //       console.error('Error during code verification', error);
  //     }
  //  };

  const ForgotPassword = () => {

    var pattern = new RegExp(/^\S+@\S+\.\S+$/);
    if (!pattern.test(Email)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address',
      });

      return;
    }
    sendPasswordResetEmail(auth, Email)
      .then((value) => {
        // Password reset email sent!
        // ..

        Swal.fire({
          icon: 'success',
          title: 'Password reset email sent',
          text: 'Please check your email',
        });
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorMessage,
        });
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: 'info',
      title: 'Logging in...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    var pattern = new RegExp(/^\S+@\S+\.\S+$/);
    if (!pattern.test(Email)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    if (Password.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Password must be at least 6 characters',
        text: 'Please enter password',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    signInWithEmailAndPassword(getAuth(), Email, Password)
      .then((userCredential) => {
        // Signed in 
        // const user = userCredential.user;
        SetGoHome(true)
        // ...
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          text: error.message,
          didOpen: () => {
            Swal.hideLoading()
          }
        });
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        onSubmit();
    }
};

  useEffect(() => {
    if (GoHome) {
      window.location.replace("/")
    }
  }, [GoHome])




  return (
    <PublicLayout>
      <div className='signin_container'>
        <h1>Welcome to Lightning OS!</h1>
        <h3>Login to experience Lightning OS.</h3>
        <p>Not registered yet? <span style={{ cursor: "pointer" }} onClick={() => { navigate('/signup') }} >Sign up now!</span></p>
        <form className='signin_form'>
          <h1>Sign In</h1>
          <div className='tabs'>
            <button onClick={(e) => { e.preventDefault(); setMethod(1) }} onKeyDown={handleKeyPress}  className={`${method === 1 ? "active" : ""}`}>Using Email</button>
            <button onClick={(e) => { e.preventDefault(); setMethod(2) }} onKeyDown={handleKeyPress}  className={`${method === 2 ? "active" : ""}`}>Using Phone Number</button>
          </div>
          {
            method === 2 ?
              <>
                {otpSent ?
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    autoFocus
                    className="opt-container "
                  ></OtpInput> :
                  <PhoneInput inputStyle={{
                    paddingLeft: "50px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }} country={'us'} excludeCountries={['IL']} value={phoneNumber} onChange={setPhoneNumber} />}
                {/* <input value={Password} onChange={(e) => { SetPassword(e.target.value) }} type='password' placeholder='PASSWORD' /> */}
                <div id="recaptcha-container"></div>
                {otpSent ?
                  <button onClick={(e) => onOtpVerify(e)} disabled={Loading} >{Loading ? <CgSpinnerTwo size={30} color='white' className="m-auto animate-spin" /> : "Verify"}</button> :
                  <button id="sign-in-button" onClick={(e) => { onSignIn(e) }} disabled={Loading} type='submit'>{Loading ? <CgSpinnerTwo size={30} color='white' className="m-auto animate-spin" /> : "Send Otp"}</button>}
              </>
              :
              <>
                <input value={Email} onChange={(e) => { SetEmail(e.target.value) }} type='email' placeholder='EMAIL' />
                <input value={Password} onChange={(e) => { SetPassword(e.target.value) }} type='password' placeholder='PASSWORD' />
                <button onClick={(e) => { onSubmit(e) }} disabled={Loading} type='submit'>{Loading ? <CgSpinnerTwo size={30} color='white' className="m-auto animate-spin" /> : "Sign In"}</button>
                <p style={{
                  cursor: "pointer",
                  color:"#007bff",
                  textDecoration:"underline",
                  fontSize:"12px"
                }} onClick={ForgotPassword}
                >Forgot Password?</p>
              </>
          }

        </form>
      </div>
    </PublicLayout>
  )
}

export default Index