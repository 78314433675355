import React from 'react'
import MobileLayoutHeader from './MobileLayoutComponents/MobileLayoutHeader'
import MobileLayoutNavbar from './MobileLayoutComponents/MobileLayoutNavbar'
import './MobileLayout.scss'
import { useLocation } from 'react-router-dom'
const MobileLayout = ({ children }) => {

    const route = useLocation().pathname.split("/").slice(1);
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return (
        <div style={{
            height: screenHeight+"px",
        }} className='MobileLayoutContainer'>
        { route[route.length - 1] === "map" ?null: <MobileLayoutHeader />}
            {/* <MobileLayoutHeader /> */}
            <div style={{
                // paddingBottom: route[route.length - 1] === "map" ? '0' : '6rem',
                // paddingTop: route[route.length - 1] === "map" ? '0' : '4rem'
                height: route[route.length - 1] === "map" ?screenHeight-64+"px":null,
            }} className='MobileLayoutBody' >
                {children}
            </div>
            <MobileLayoutNavbar />
        </div>
    )
}

export default MobileLayout