import React from 'react'
import Datatable from '../../../components/Datatable'
import './index.scss'
import { Pagination } from '@mui/material'
import Layout from '../../../Layouts/Layout'
const Index = () => {

    const headers = [
        {
            name: 'Order ID',
            key: 'orderID'
        },
        {
            name: 'Customer Name',
            key: 'customerName'
        },
        {
            name: 'Order Date',
            key: 'orderDate'
        },
        {
            name: 'Order Status',
            key: 'orderStatus'
        },
        {
            name: 'Order Amount',
            key: 'orderAmount'
        },
        {
            name: 'Payment Status',
            key: 'paymentStatus'
        },
        {
            name: 'Payment Method',
            key: 'paymentMethod'
        },
        {
            name: 'Action',
            key: 'action'
        },
    ]

    const orders = [
        {
            orderID: '1234',
            customerName: 'John Doe',
            orderDate: '12/12/2021',
            orderStatus: 'Pending',
            orderAmount: '1000',
            paymentStatus: 'Pending',
            paymentMethod: 'Cash',
            action: 'View'
        },
        {
            orderID: '1234',
            customerName: 'John Doe',
            orderDate: '12/12/2021',
            orderStatus: 'Pending',
            orderAmount: '1000',
            paymentStatus: 'Pending',
            paymentMethod: 'Cash',
            action: 'View'
        },
        {
            orderID: '1234',
            customerName: 'John Doe',
            orderDate: '12/12/2021',
            orderStatus: 'Pending',
            orderAmount: '1000',
            paymentStatus: 'Pending',
            paymentMethod: 'Cash',
            action: 'View'
        },
        {
            orderID: '1234',
            customerName: 'John Doe',
            orderDate: '12/12/2021',
            orderStatus: 'Pending',
            orderAmount: '1000',
            paymentStatus: 'Pending',
            paymentMethod: 'Cash',
            action: 'View'
        },
        {
            orderID: '1234',
            customerName: 'John Doe',
            orderDate: '12/12/2021',
            orderStatus: 'Pending',
            orderAmount: '1000',
            paymentStatus: 'Pending',
            paymentMethod: 'Cash',
            action: 'View'
        },
        {
            orderID: '1234',
            customerName: 'John Doe',
            orderDate: '12/12/2021',
            orderStatus: 'Pending',
            orderAmount: '1000',
            paymentStatus: 'Pending',
            paymentMethod: 'Cash',
            action: 'View'
        },
        {
            orderID: '1234',
            customerName: 'John Doe',
            orderDate: '12/12/2021',
            orderStatus: 'Pending',
            orderAmount: '1000',
            paymentStatus: 'Pending',
            paymentMethod: 'Cash',
            action: 'View'
        },
    ]

  return (
    <Layout>
        <div className='orderHistory'>
            <h1>Order History</h1>
            <div>
                <Datatable headers={headers} list={orders} />
            </div>
            <div className='pagination'>
                <Pagination count={10}  />
            </div>
        </div>
    </Layout>
  )
}

export default Index