class EditUserReq {
    constructor() {
        this.userId = null;
        this.firstName = null;
        this.lastName = null;
        this.city = null;
        this.state = null;
        this.country = null;
        this.phoneNumber = null;
        this.address = null;
        this.status = "Active";
    }
}
export default EditUserReq;