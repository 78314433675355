export class AffiliateCodeStatuses {
    // static SignedUpbutNotInstalledyet = 1;
    // static InstalledWaitingCustomerRequest = 2;
    // static RequestedByCustomer = 3;
    // static ApprovedByAdmin = 4;
    // // static RejectedByAdmin = 5;

    static SignedUpbutNotInstalledyet = 1;
    static InstalledWaitingCustomerRequest = 2;
    static RequestedByCustomer = 3;
    static ReceivedByCustomerRewardSent = 4;
    static RejectedByAdmin = 5;
    static ErrorDuringSendingReward = 6;
    static CancelledInstallation = 7;

}
