/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomerApi from '../../../API/CustomerApi';
import UsersApi from '../../../API/UsersApi';
import SearchAffiliateCustomerStatusesReq from '../../../Requests/ReferralCode/SearchAffiliateCustomerStatusesReq';
import UpdateReferralCodeStatusReq from '../../../Requests/ReferralCode/UpdateReferralCodeStatusReq';
import UserSelector from '../../../components/UserSelector';
import '../../SuperAdmin/ManageCustomers/index.scss';
import { AffiliateCodeStatuses } from './AffiliateCodeStatuses';
import Layout from '../../../Layouts/Layout';
const ManageRefferalCustomers = () => {
    const [loaded, setLoaded] = useState(true);
    const [code, setCode] = useState("");
    const [status, setStatus] = useState(null);
    const [affiliatedUserId, setAffiliatedUserId] = useState(null);
    const [affiliatedByUserId, setAffiliatedByUserId] = useState(null);
    const [createdAtFrom, setCreatedAtFrom] = useState(null);
    const [createdAtTo, setCreatedAtTo] = useState(null);
    const [installedAt, setInstalledAt] = useState(null);
    const [requestedByCustomerAt, setRequestedByCustomerAt] = useState(null);
    const [approvedByAdminAt, setApprovedByAdminAt] = useState(null);
    const [rejectedByAdminAt, setRejectedByAdminAt] = useState(null);
    const [data, setData] = useState([]);

    const handleSearch = async () => {
        setLoaded(false);
        var req = new SearchAffiliateCustomerStatusesReq();
        req.code = code;
        req.status = status;
        req.affiliatedUserId = affiliatedUserId;
        req.affiliatedByUserId = affiliatedByUserId;
        req.createdAtFrom = createdAtFrom;
        req.createdAtTo = createdAtTo;
        req.installedAt = installedAt;
        req.requestedByCustomerAt = requestedByCustomerAt;
        req.approvedByAdminAt = approvedByAdminAt;
        req.rejectedByAdminAt = rejectedByAdminAt;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 100;

        let res = await CustomerApi.SearchAffiliateCustomerStatuses(req);
        if (res?.status?.success) {
            setData(res?.data?.list);
            setLoaded(true);
        } else {
            setData([]);
            setLoaded(true);
        }
    }

    useEffect(() => {
        handleSearch();
    }, []);

    // static SignedUpbutNotInstalledyet = 1;
    // static InstalledWaitingCustomerRequest = 2;
    // static RequestedByCustomer = 3;
    // static ReceivedByCustomerRewardSent = 4;
    // static RejectedByAdmin = 5;
    // static ErrorDuringSendingReward = 6;
    // static CancelledInstallation = 7;

    const statusOptions = [
       { value: AffiliateCodeStatuses.SignedUpbutNotInstalledyet, label: "Signed Up but Not Installed yet" },
       { value: AffiliateCodeStatuses.InstalledWaitingCustomerRequest, label: "Installed Waiting Customer Request" },
       { value: AffiliateCodeStatuses.RequestedByCustomer, label: "Requested By Customer" },
       { value: AffiliateCodeStatuses.ReceivedByCustomerRewardSent, label: "Received By Customer Reward Sent" },
       { value: AffiliateCodeStatuses.RejectedByAdmin, label: "Rejected By Admin" },
       { value: AffiliateCodeStatuses.ErrorDuringSendingReward, label: "Error During Sending Reward" },
       { value: AffiliateCodeStatuses.CancelledInstallation, label: "Cancelled Installation" },
    ];

    const changeReferralCodeStatus = async (ReferralCodeId, NewStatus) => {
        let req = new UpdateReferralCodeStatusReq();
        req.newStatus = NewStatus;
        req.referralCodeId = ReferralCodeId;
        console.log(req)
        let response = await UsersApi.ChangeReferralCodeStatus(req);

        if (response.status.success) {
            handleSearch();
        }
        else {
            console.log(response.status.message)
        }
    }


    return (
        <Layout>
            <div className='ManageCustomersDiv SearchTableLayout'>
                <Card className='SearchCard'>
                    <div className='SearchDiv'>
                        <h3 className='SearchTitle' >Manage And Search Refferal Customers</h3>
                        <div className='SearchInputs'>

                            <UserSelector setUser={setAffiliatedUserId} userType={"Customer"} placeholder='Affiliated Customer' />

                            <input className='SearchInput' type='text' onChange={(e) => setCode(e.target.value)} placeholder='Code' />

                            <select className='SearchInput' value={status} onChange={(e) => setStatus(parseInt(e.target.value))} placeholder='Status'>
                                <option value="">Select Status</option>
                                {statusOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>

                            <UserSelector setUser={setAffiliatedByUserId} userType={"Customer"} placeholder='Affiliated By' />


                            <div>
                                <label htmlFor="createdAtFrom">Created At From:</label>
                                <input id="createdAtFrom" className='SearchInput' type='date' value={createdAtFrom} onChange={(e) => setCreatedAtFrom(e.target.value)} placeholder='Select Created At From' />
                            </div>

                            <div>
                                <label htmlFor="createdAtTo">Created At To:</label>
                                <input id="createdAtTo" className='SearchInput' type='date' value={createdAtTo} onChange={(e) => setCreatedAtTo(e.target.value)} placeholder='Select Created At To' />
                            </div>

                            <div>
                                <label htmlFor="installedAt">Installed At:</label>
                                <input id="installedAt" className='SearchInput' type='date' value={installedAt} onChange={(e) => setInstalledAt(e.target.value)} placeholder='Select Installed At' />
                            </div>

                            <div>
                                <label htmlFor="requestedByCustomerAt">Requested By Customer At:</label>
                                <input id="requestedByCustomerAt" className='SearchInput' type='date' value={requestedByCustomerAt} onChange={(e) => setRequestedByCustomerAt(e.target.value)} placeholder='Select Requested By Customer At' />
                            </div>

                            <div>
                                <label htmlFor="approvedByAdminAt">Approved By Admin At:</label>
                                <input id="approvedByAdminAt" className='SearchInput' type='date' value={approvedByAdminAt} onChange={(e) => setApprovedByAdminAt(e.target.value)} placeholder='Select Approved By Admin At' />
                            </div>

                            <div>
                                <label htmlFor="rejectedByAdminAt">Rejected By Admin At:</label>
                                <input id="rejectedByAdminAt" className='SearchInput' type='date' value={rejectedByAdminAt} onChange={(e) => setRejectedByAdminAt(e.target.value)} placeholder='Select Rejected By Admin At' />
                            </div>



                        </div>
                        <button className='SearchButton' onClick={() => handleSearch()} >Search</button>
                    </div>
                </Card>
                {
                    !loaded ?
                        <div className='spinner'>
                            <CircularProgress sx={{
                                color: "#181E4B"
                            }} />
                        </div>
                        :
                        <div className='TableDiv'>
                            <table className='Table11'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Affiliated User</th>
                                        <th>Affiliated By</th>
                                        <th>Code</th>
                                        <th>Status</th>
                                        <th>Value</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{`${item.affiliatedUser.firstName} ${item.affiliatedUser.lastName}`}</td>
                                            <td>{`${item.affiliatedBy.firstName} ${item.affiliatedBy.lastName}`}</td>
                                            <td>{item.code}</td>
                                            <td>
                                                {
                                                    item.status === AffiliateCodeStatuses.SignedUpbutNotInstalledyet && (
                                                        <><p>Signed Up but Not Installed yet</p>
                                                            <button onClick={() => changeReferralCodeStatus(item.id, AffiliateCodeStatuses.RejectedByAdmin)} className='py-2 px-4 rounded-lg bg-red-500 hover:bg-red-700 text-white'>Reject</button>
                                                        </>
                                                    )
                                                }


                                                {
                                                    item.status === AffiliateCodeStatuses.InstalledWaitingCustomerRequest && (
                                                        <><p>Installed Waiting Customer request</p>
                                                            <button onClick={() => changeReferralCodeStatus(item.id, AffiliateCodeStatuses.RejectedByAdmin)} className='py-2 px-4 rounded-lg bg-red-500 hover:bg-red-700 text-white'>Reject</button>
                                                        </>
                                                    )
                                                }

                                                {
                                                    item.status === AffiliateCodeStatuses.RequestedByCustomer && (
                                                        <p>Requested, Something went wrong during processing</p>
                                                    )
                                                }

                                                

                                                {
                                                    item.status === AffiliateCodeStatuses.RejectedByAdmin
                                                        && <>
                                                        <p>Rejected By Admin</p>
                                                        <button onClick={() => changeReferralCodeStatus(item.id, AffiliateCodeStatuses.InstalledWaitingCustomerRequest)} className='py-2 px-4 rounded-lg bg-red-500 hover:bg-red-700 text-white'>Make status go back to installed</button>
                                                        </>
                                                        
                                                }

                                                {
                                                    item.status === AffiliateCodeStatuses.ReceivedByCustomerRewardSent && (
                                                        <p>Reward Sent</p>
                                                    )
                                                }

                                                {
                                                    item.status === AffiliateCodeStatuses.ErrorDuringSendingReward && (
                                                        <p>Error During Sending Reward</p>
                                                    )
                                                }

                                                {
                                                    item.status === AffiliateCodeStatuses.CancelledInstallation && (
                                                        <p>Cancelled Installation</p>
                                                    )
                                                }

                                            </td>
                                            <td>{item.value}</td>
                                            <td>{item.createdAt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                }

            </div>
        </Layout>
    );
};
export default ManageRefferalCustomers;
