import { ExpandMore as ExpandMoreIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Card, CardHeader, IconButton, Menu, MenuItem, Modal, Tooltip } from '@mui/material';
import React from 'react';
import { useDrop } from 'react-dnd';
import Swal from 'sweetalert2';
import OrgApi from '../../../../../API/OrgApi';
import CreateOrgReq from '../../../../../Requests/Organizations/CreateOrgReq';
import DeleteOrgReq from '../../../../../Requests/Organizations/DeleteOrgReq';
import ManageMembers from './../../../ManageMembers';

import _ from 'lodash';


export default function Organization({ org, onCollapse, collapsed, getOrgTree }) {

    const [contextMenu, setContextMenu] = React.useState(null);
    const [modelOpen, setModelOpen] = React.useState(false);
    function addMember() {
      setModelOpen(true)
      setContextMenu(null);
    }
  
    const handleClick = (event) => {
      // event.preventDefault();
      
      setContextMenu(
          contextMenu === null
              ? {
                  mouseX: event.clientX + 2,
                  mouseY: event.clientY - 6,
              }
              : null,
      );
  };
    async function deleteOrg(id, name) {
      setContextMenu(null);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'info',
            title: 'Deleting Organization',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
          let req = new DeleteOrgReq();
          req.int = id;
          req.string = name;
          OrgApi.DeleteSalesOrg(req).then(
            (response) => {
              
              if (response.status.success) {
                
                Swal.fire(
                  {
                    title: 'Deleted!',
                    text: 'The Organization has been deleted.',
                    icon: 'success',
                    didOpen: () => {
                      Swal.hideLoading()
                    }
                  }
                );
                getOrgTree()
  
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: response.status.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: true,
                  didOpen: () => {
                    Swal.hideLoading()
                  }
                });
              }
            }
          );
        }
      })
    }
  
    const createOrg = async (id, name) => {
      Swal.fire({
        icon: 'info',
        title: 'Creating Organization',
        text: 'Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
  
      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Name is required',
          text: 'Please enter Name',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading()
          }
        });
        return;
      }
  
      let req = new CreateOrgReq();
  
      req.name = name;
      if (id) {
        req.parentOrgId = id
      }
      let res = await OrgApi.CreateOrg(req)
      
      if (res?.status?.success) {
        Swal.fire({
          icon: 'success',
          title: 'Organization Created',
          text: "Organization has been created",
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
  
        });
        getOrgTree()
  
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res?.status?.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading()
          }
        });
      }
    }
  
  
    const handleClose = () => {
      setContextMenu(null);
    };
  
    const triggerInputAlert = (id) => {
      setContextMenu(null);
      Swal.fire({
        title: `Enter the Organization's Name`,
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Create',
  
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed)
          createOrg(id, result.value)
      })
    };
  
    const [{ canDrop, isOver }, drop] = useDrop({
      accept: "account",
      drop: () => ({ name: org.name }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    });
  
    const isActive = canDrop && isOver;
    let backgroundColor = "white";
  
    if (isActive) {
      backgroundColor = "#ddffd2";
    } else if (canDrop) {
      backgroundColor = "#ffeedc";
    }
  
    return (
  
      <Card
        variant="outlined"
        sx={{ backgroundColor, background: "white", display: "inline-block", borderRadius: 5,border:"3px solid #435873" }}
        ref={drop}
      >
        <Modal open={modelOpen} onClose={()=>setModelOpen(false)}>
            <ManageMembers orgId={org?.id} name={org?.name} setModelOpen={setModelOpen} />
        </Modal>
        <CardHeader
          avatar={
            <Tooltip
              title={`${_.size(
                org?.childOrgs
              )} Sub Organization`}
              arrow
            >
              
            </Tooltip>
          }
          subheader={`${_.size(
            org?.childOrgs
          )} Sub Organization`}
          title={<h1 className="text-2xl">{org?.name}</h1>}
          action={
            <IconButton size="small" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          sx={{ '& .MuiMenu-paper': { backgroundColor: 'white' } }}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => triggerInputAlert(org?.id)}>
            Add an Organization
          </MenuItem>
          
          
          <MenuItem onClick={() => addMember()}>
            Add Members
          </MenuItem>
          {org?.childOrgs?.length === 0 && (
            <MenuItem onClick={() => deleteOrg(org?.id, org?.name)}>
              Delete the Organization
            </MenuItem>
          )}
        </Menu>
        <IconButton
          size="small"
          onClick={onCollapse}
          sx={{
            transform: collapsed ? "rotate(180deg)" : "rotate(0deg)",
            marginLeft: "auto",
            transition: theme => theme.transitions.create("transform", {
              duration: theme.transitions.duration.short
            })
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Card>
  
  
    );
  }
  