class ReportReq {
    constructor() {
        this.salesOrgId = null;
        this.userId = null;
        this.filterBy =null;
        this.fromDate = null;
        this.toDate = null;
        this.salesRepIds = null;
        this.orgIds = null;
        this.pagingParams = {
            pageNumber: 1,
            pageSize: 20
        }
    }
  }
  
  export default ReportReq;