import React from "react";
import _ from "lodash";
import { Box } from "@mui/material";
import {
  ThemeProvider
} from "@mui/material";
import { createTheme } from '@mui/material/styles'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Node from "./components/Node";

const theme = createTheme({
  palette: {
    background: "#ECECF4"
  },
  fontFamily: "Roboto, sans-serif"
});
export default function Index({ org, getOrgTree }) {
  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor="background" padding={4} sx={{ marginTop: "1rem",backgroundColor:'white' }}>
   
        <DndProvider backend={HTML5Backend}>
          <Node getOrgTree={getOrgTree} o={org} />
        </DndProvider>
      </Box>
    </ThemeProvider>
  )
}