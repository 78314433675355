import React, { useState, useEffect } from 'react';
import './RescheduleForm.scss';
import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import CreateRescheduleFormReq from '../../../Requests/Customer/CreateRescheduleFormReq';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import authorized from '../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png';
import authorizedFastwyre from '../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png';
import { CircularProgress } from '@mui/material';
import { IoCheckmarkCircle } from 'react-icons/io5';
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq';
// import FiberCompanySelector from './../../../components/FiberCompanySelector'
// import FiberPlanSelector from './../../../components/FiberPlanSelector'
const CustomerRescheduleForm = () => {
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);
    const [phone, setPhone] = useState("");
    const [routePhone, setRoutePhone] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [selectedTimeRange, setSelectedTimeRange] = useState("AM");
    const [customer, setCustomer] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {
        const url = window.location.href;
        const phoneFromUrl = url.split("/").pop();
        setRoutePhone(phoneFromUrl);
    }, [setIsLoading]);


    useEffect(() => {
        if (routePhone) {
            getCustomer();
        }
    }, [routePhone]);

    const getCustomer = async () => {
        let req = new SearchCustomerReq();
        req.cellPhone = routePhone;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1;
        let res = await CustomerApi.SearchFidiumCustomers(req);
        if (res?.status?.success) {
            setCustomer(res.data.list[0]);
            setIsLoading(false);
        }

    }

    const resetForm = () => {
        setSelectedDateFrom(null);
        setSelectedDateTo(null);
        setPhone("");
    };

    useEffect(() => {
        const getDefaultDate = () => {
            const date = new Date();
            date.setDate(date.getDate() + 7);
            return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        };

        setSelectedDateFrom(getDefaultDate());
        setSelectedDateTo(getDefaultDate());
    }, []);


    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const handleSubmit = async () => {


        Swal.fire({
            title: "Please Wait",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            icon: "info",
            text: "Submitting Your Form",
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading();
            }
        });

        setIsLoading(true);
        if (!selectedDateFrom) {
            Swal.fire({
                icon: "error",
                title: "Please select a date",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!phone && !routePhone) {
            Swal.fire({
                icon: "error",
                title: "Please enter a phone number",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }



        // let selectedFromDate = new Date().setMonth(selectedDateFrom.split('-')[1] - 1)
        // selectedFromDate = new Date(selectedFromDate).setDate(selectedDateFrom.split('-')[2])
        // selectedFromDate = new Date(new Date(selectedFromDate).setFullYear(selectedDateFrom.split('-')[0]))

        const [year, month, day] = selectedDateFrom.split('-').map(Number);

        // Create a new date object with the desired year, month, and day
        const selectedFromDate = new Date(year, month - 1, day);

        const selectedToDate = new Date(selectedDateTo);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + (customer?.fiberCompanyId === 9?13:6));

        if (selectedFromDate < minDate) {
            Swal.fire({
                icon: "error",
                title: "Invalid Date",
                text: `Please select a date that is at least ${customer?.fiberCompanyId === 9?14:7} days from today.`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            setIsLoading(false);
            return;
        }

        let req = new CreateRescheduleFormReq();

        let from = new Date(selectedDateFrom);
        let to = new Date(selectedDateFrom);
        if (selectedTimeRange === "AM") {
            from.setHours(8, 0, 0);
            to.setHours(12, 0, 0);
        }
        else {
            from.setHours(12, 0, 0);
            to.setHours(18, 0, 0);
        }


        req.NewInstallDate = formatDateToISOString(new Date(from));
        req.NewInstallDateTo = formatDateToISOString(new Date(to));
        if (routePhone) {
            if (routePhone.includes("+")) {
                req.PhoneNumber = routePhone;
            } else {
                req.PhoneNumber = "+" + routePhone;
            }
        }
        if (phone) {
            if (phone.includes("+")) {
                req.PhoneNumber = phone;
            } else {
                req.PhoneNumber = "+" + phone;
            }
        }
        let res = await CustomerApi.RescheduleForm(req);

        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Form Submitted",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            resetForm();
            setIsLoading(false);
            setSubmitted(true);
        } else {
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            setIsLoading(false);
        }
    };

    return (
        <div style={{
            // height: "100vh",'
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: "100%",
            height: "100vh"
        }}>
            {
                isLoading ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: "100%",
                        height: "100%"
                    }}>
                        <CircularProgress size={50} sx={{
                            color: "var(--primary-color)"
                        }} />
                    </div>
                    :
                    submitted ?
                        <div className='container_for_reschedule'>
                            <div className='reschedule_form_container_customer'>
                                <img alt="Logo" style={{ marginBottom: "1rem" }} src={authorized} className='logo' />
                                {/* <h1 className='title'>Reschedule Form</h1> */}
                                <IoCheckmarkCircle style={{ color: "#2761D0", fontSize: "5rem" }} />
                                <p style={{
                                    fontSize: "1rem",
                                    textAlign: "center",
                                }} className='note'>
                                    Your reschedule request has been submitted successfully. Our team will contact you soon.
                                </p>
                            </div>
                        </div>
                        :
                        <div className='container_for_reschedule'>
                            <div className='reschedule_form_container_customer'>
                                {
                                    customer?.fiberCompanyId === 2 &&
                                    <img alt="Logo" style={{ marginBottom: "1rem" }} src={authorized} className='logo' />
                                }
                                {
                                    customer?.fiberCompanyId === 9 &&
                                    <img alt="Logo" style={{ marginBottom: "1rem" }} src={authorizedFastwyre} className='logo' />
                                }
                                <h1 className='title'>Reschedule Form</h1>
                                <div className='inputs'>
                                    {!routePhone && (
                                        <>
                                            <label className='inputLabel' htmlFor="phoneNumber">Enter Phone Number</label>
                                            <PhoneInput
                                                country={'us'}
                                                value={phone}
                                                onChange={setPhone}
                                                excludeCountries={['il']}
                                                style={{ width: "100%" }}
                                                inputStyle={{ width: "100%" }}
                                            />
                                        </>
                                    )}
                                    <p className='note'>
                                        Note : Please select a date that is at least {customer?.fiberCompanyId === 9?"14":"7"} days from today.
                                    </p>
                                    <label className='inputLabel' htmlFor="rescheduleDate">Select a New Date</label>
                                    <input
                                        id="createdAtFrom"
                                        className='datePicker'
                                        type='date'
                                        value={selectedDateFrom}
                                        onChange={(e) => setSelectedDateFrom(e.target.value)}
                                        placeholder='Select Installation Date'
                                        style={{
                                            backgroundColor: "white"
                                        }}
                                    />
                                    {/* <label className='inputLabel' htmlFor="rescheduleDate">Select a New Date To</label>
                            <input
                                id="createdAtTo"
                                className='datePicker'
                                type='date'
                                value={selectedDateTo}
                                onChange={(e) => setSelectedDateTo(e.target.value)}
                                placeholder='Select Installation Date'
                            /> */}
                                    <div className='am_pm'>
                                        <p onClick={
                                            () => {
                                                setSelectedTimeRange("AM");
                                            }
                                        } className={`${selectedTimeRange === "AM" ? 'active' : ''}`} >AM</p>
                                        <p onClick={
                                            () => {
                                                setSelectedTimeRange("PM");
                                            }
                                        } className={`${selectedTimeRange === "PM" ? 'active' : ''}`}>PM</p>
                                    </div>
                                    {/* <p className='note'>
                                {
                                    selectedTimeRange === "AM" ?
                                        "Note: Installation time will be between 9:00 AM to 12:00 PM"
                                        :
                                        "Note: Installation time will be between 12:00 PM to 8:00 PM"
                                }
                            </p> */}

                                </div>

                                <button style={{ backgroundColor: "#2761D0" }} className='submitBtn' onClick={handleSubmit}>Confirm</button>
                            </div>
                        </div>
            }
        </div>
    );
};

export default CustomerRescheduleForm;
