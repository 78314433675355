class CreateRescheduleFormReq {

    constructor() {
        this.FidiumCustomerId = null;
        this.PhoneNumber = null;
        this.URLData = null;
        this.Timestamp = null;
        this.OldInstallDate = null;
        this.NewInstallDate = null;
        this.NewInstallDateTo = null;
    }

}

export default CreateRescheduleFormReq;