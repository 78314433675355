import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class CustomerApi {
    baseUrl = EnvSettings.url;
    #CreateFidiumCustomerUrl = "CreateFidiumCustomer";
    #CreateLoggedInFidiumCustomerUrl = "CreateLoggedInFidiumCustomer";
    #GetFidiumCustomerByIdUrl = "GetFidiumCustomerById";
    #CreateCustomerWithReferralCodeUrl = "CreateCustomerWithReferralCode";
    #SearchAffiliateCustomerStatusesUrl = "SearchAffiliateCustomerStatuses";
    #SearchFidiumCustomersUrl = "SearchFidiumCustomers";
    #EditFidiumCustomerUrl = "EditFidiumCustomer";
    #DeleteFidiumCustomerUrl = "DeleteFidiumCustomer"
    #CreateFreeMonthFormUrl = "CreateFreeMonthForm";
    #EditFreeMonthFormUrl = "EditFreeMonthForm";
    #CreateCustomerFeedbackUrl = "CreateCustomerFeedback";
    #SearchCustomerFeedbackUrl = "SearchCustomerFeedbacks";
    #AddSalesRepReview = "AddSalesRepReview";
    #CreateAvailabilityCheckUrl = "CreateAvailabilityCheck";
    #SearchAvailabilityCheckUrl = "SearchAvailabilityChecks";
    #DeleteAvailabilityCheckUrl = "DeleteAvailabilityCheck";
    #EditAvailabilityCheckUrl = "EditAvailabilityCheck";
    #MarkAvailabilityCheckAsSeenUrl = "MarkAvailabilityCheckAsSeen";
    #ApproveCustomerFeedbackRewardUrl = "ApproveCustomerFeedbackReward";
    #RequestFeedbackRewardUrl = "RequestFeedbackReward";
    #ExportReportUrl = "ExportReport";
    #CancelFormUrl = "CreateCancelationForm";
    #EditCustomerInstallDateUrl = "EditCustomerInstallDate";
    #CreateLightCustomerUrl = "LightCreateCustomer";
    #CreateRescheduleFormUrl = "CreateRescheduleForm";
    #GetRescheduleFormByIdUrl = "GetRescheduleFormById";
    #GetCancelationFormByIdUrl = "GetCancelationFormById";
    #ConfirmCancelationUrl = "ConfirmCancelation";
    #ForceResolveIssueUrl = "ForceResolveIssue";
    #CreateCustomerFeedbackBaseOnPhoneUrl = "CreateCustomerFeedbackBasedOnPhone";
    #MarkCustomerAsInvalid  = "MarkCustomerAsInvalid";
    #MarkCustomerAsValid  = "MarkCustomerAsValid";
    #GetFidiumCustomerByAffiliateCodeUrl = "GetFidiumCustomerByAffiliateCode";
    #CreateUserAccountForCustomerUrl = "CreateUserAccountForCustomer";
    AcceptBizCardOfferUrl = "AcceptBizCardOffer";
    SubmitFastwyreCompleteFormUrl = "SubmitFastwyreCompleteForm";
    EditCustomerInfoUrl = "EditCustomerInfo";
    ChangeAccountStatusUrl = "ChangeAccountStatus";
    GetCustomerDataForExportUrl = "GetCustomerDataForExport";
    MarkLeadAsContactedUrl = "MarkLeadAsContacted";
    ChangeOwnershipOfConsultationUrl = "ChangeOwnershipOfConsultation";

    async ChangeOwnershipOfConsultation(req) {
        const res = await fetch(this.baseUrl + this.ChangeOwnershipOfConsultationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async MarkLeadAsContacted(req) {
        const res = await fetch(this.baseUrl + this.MarkLeadAsContactedUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async GetCustomerDataForExport(req) {
        const res = await fetch(this.baseUrl + this.GetCustomerDataForExportUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async ChangeAccountStatus(req) {
        const res = await fetch(this.baseUrl + this.ChangeAccountStatusUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async EditCustomerInfo(req) {
        const res = await fetch(this.baseUrl + this.EditCustomerInfoUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async SubmitFastwyreCompleteForm(req) {
        const res = await fetch(this.baseUrl + this.SubmitFastwyreCompleteFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async AcceptBizCardOffer(req) {
        const res = await fetch(this.baseUrl + this.AcceptBizCardOfferUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async CreateUserAccountForCustomer(req) {
        const res = await fetch(this.baseUrl + this.#CreateUserAccountForCustomerUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async GetFidiumCustomerByAffiliateCode(req) {
        const res = await fetch(this.baseUrl + this.#GetFidiumCustomerByAffiliateCodeUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async MarkCustomerAsValid(req) {
        const res = await fetch(this.baseUrl + this.#MarkCustomerAsValid, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async MarkCustomerAsInvalid(req) {
        const res = await fetch(this.baseUrl + this.#MarkCustomerAsInvalid, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async CreateCustomerFeedbackBaseOnPhone(req) {
        const res = await fetch(this.baseUrl + this.#CreateCustomerFeedbackBaseOnPhoneUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    //AddSalesRepReview

    async AddSalesRepReview(req) {
        const res = await fetch(this.baseUrl + this.#AddSalesRepReview, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async CreateLightCustomer(req) {
        const res = await fetch(this.baseUrl + this.#CreateLightCustomerUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditCustomerInstallDate(req) {
        const res = await fetch(this.baseUrl + this.#EditCustomerInstallDateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RequestFeedbackReward(req) {
        const res = await fetch(this.baseUrl + this.#RequestFeedbackRewardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async ApproveCustomerFeedbackReward(req) {
        const res = await fetch(this.baseUrl + this.#ApproveCustomerFeedbackRewardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async MarkAvailabilityCheckAsSeen(req) {
        const res = await fetch(this.baseUrl + this.#MarkAvailabilityCheckAsSeenUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditAvailabilityCheck(req) {
        const res = await fetch(this.baseUrl + this.#EditAvailabilityCheckUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
            },
            body: req
        })
        const data = await res.json();
        return data;
    }

    async DeleteAvailabilityCheck(req) {
        const res = await fetch(this.baseUrl + this.#DeleteAvailabilityCheckUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchAvailabilityCheck(req) {
        const res = await fetch(this.baseUrl + this.#SearchAvailabilityCheckUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateFidiumCustomer(req) {
        const res = await fetch(this.baseUrl + this.#CreateFidiumCustomerUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateLoggedInFidiumCustomer(req) {
        const res = await fetch(this.baseUrl + this.#CreateLoggedInFidiumCustomerUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                 'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    

    async GetFidiumCustomerById(req) {
        const res = await fetch(this.baseUrl + this.#GetFidiumCustomerByIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFidiumCustomers(req) {
        const res = await fetch(this.baseUrl + this.#SearchFidiumCustomersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteFidiumCustomer(req) {
        const res = await fetch(this.baseUrl + this.#DeleteFidiumCustomerUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditFidiumCustomer(req) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(req)) {

            formData.append(key, value);
        }

        const res = await fetch(this.baseUrl + this.#EditFidiumCustomerUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
            },
            body: formData
        })
        const data = await res.json();
        return data;
    }

    async CreateCustomerWithAffiliateCode(req) {
        
        const res = await fetch(this.baseUrl + this.#CreateCustomerWithReferralCodeUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async SearchAffiliateCustomerStatuses(req) {
        // console.log(req);
        const formData = new FormData();
        for (const [key, value] of Object.entries(req)) {
            formData.append(key, value);
        }
        const res = await fetch(this.baseUrl + this.#SearchAffiliateCustomerStatusesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
            },
            body: formData
        })
        const data = await res.json();
        return data;
    }

    async CreateFreeMonthForm(req) {
        const res = await fetch(this.baseUrl + this.#CreateFreeMonthFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditFreeMonthForm(req) {
        const res = await fetch(this.baseUrl + this.#EditFreeMonthFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateCustomerFeedback(req) {
        const res = await fetch(this.baseUrl + this.#CreateCustomerFeedbackUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async SearchCustomerFeedback(req) {
        const res = await fetch(this.baseUrl + this.#SearchCustomerFeedbackUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateAvailabilityCheck(req) {
 
        const res = await fetch(this.baseUrl + this.#CreateAvailabilityCheckUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
            },
            body: req
        })
        const data = await res.json();
        return data;
    }

    async ExportReport(req) {
        const res = await fetch(this.baseUrl + this.#ExportReportUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.blob();
        return data;
    }

    // async post request to CancelFormUrl 
    async CancelForm(req) {
        const res = await fetch(this.baseUrl + this.#CancelFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    // async post request to reschedule form
    async RescheduleForm(req) {
        const res = await fetch(this.baseUrl + this.#CreateRescheduleFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //GetRescheduleFormById
    async GetRescheduleFormById(req) {
        const res = await fetch(this.baseUrl + this.#GetRescheduleFormByIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    //GetCancelationFormById
    async GetCancelationFormById(req) {
        const res = await fetch(this.baseUrl + this.#GetCancelationFormByIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //ConfirmCancelation
    async ConfirmCancelation(req) {
        const res = await fetch(this.baseUrl + this.#ConfirmCancelationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //ForceResolveIssue
    async ForceResolveIssue(req) {
        const res = await fetch(this.baseUrl + this.#ForceResolveIssueUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

}

export default new CustomerApi();