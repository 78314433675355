import React, { useContext, useEffect } from 'react'
import { IoMdArrowRoundBack } from "react-icons/io";
import { UserContext } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import { MdOutlinePushPin } from 'react-icons/md';
import { ClickAwayListener } from '@mui/material';
import anonUser from '../../../../assets/images/userGrey.png'
export const ConversationHeader = ({ getIcon,conversation, getConvImage, setOpenEditImage, getPinnedMessages, pinnedMessages }) => {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [showPinnedMessages, setShowPinnedMessages] = React.useState(false)
    console.log(conversation)
    useEffect(() => {
        getPinnedMessages()
    }, [])

    const getConvName = () => {
        console.log(conversation)
        if (conversation.conversationType === 'Private') {
            let otherUser = conversation.conversationUsers.filter((item) => item.userId !== user.id)
            if (otherUser[0]?.user) {
                return otherUser[0]?.user?.firstName + " " + otherUser[0]?.user?.lastName
            }
            else {
                if (user.id === conversation.creatorId) {
                    return conversation.name.split(" || ")[1]
                }
                else {
                    return conversation.name.split(" || ")[0]
                }
            }
        }
        else {
            return conversation.name
        }
        // return conversation.name
    }



    return (
        <div className='chat_conversation_header'>
            <IoMdArrowRoundBack style={{
                cursor: 'pointer'
            }} color='#2761D0' onClick={() => { navigate('/chats') }} size={30} />



            <div onClick={() => {
                setOpenEditImage(true)
            }} className='chat_conversation_header_avatar'>
                {/* <img src={selectedProfile} alt="avatar" /> */}
                {getConvImage()}
            </div>
            <div className='chat_conversation_header_info'>
                <div className={conversation.conversationType !== "CustomerService" ? `chat_conversation_header_info_name` : `customer_service`}>
                    {getConvName()}
                    {/* {conversation.name} */}
                </div>
            </div>

            {pinnedMessages.length > 0 && <div onClick={() => {
                setShowPinnedMessages(true)
            }} className='pinned_messages'>
                <MdOutlinePushPin color='red' size={30} />
                <p> {pinnedMessages.length} Messages have been Pinned</p>
            </div>
            }
            {
                showPinnedMessages && <ClickAwayListener onClickAway={() => {
                    setShowPinnedMessages(false)
                }}>
                    <div className='pinned_messages_container'>
                        <div className='pinned_messages_header'>
                            <MdOutlinePushPin color='red' size={30} />
                            <p> Pinned Messages</p>
                            <MdOutlinePushPin color='red' size={30} onClick={() => {
                                setShowPinnedMessages(false)
                            }} />
                        </div>
                        <div className='pinned_messages_body'>
                            {pinnedMessages.map((message, index) => {
                                return (
                                    <div key={index} className='pinned_message'>
                                        <div className='chat_list_item_avatar'>
                                            <img src={message.sender.profileImage ? message.sender.profileImage.url : anonUser} alt="avatar" />
                                        </div>
                                        <div className='chat_list_item_info'>
                                            <p className='chat_list_item_info_name'>{message.sender.firstName + " " + message.sender.lastName}</p>
                                            {message.content && <p className='chat_list_item_info_message'>{message.content}</p>}
                                            {message.files.length>0 && getIcon(message.files[0])}
                                        </div>
                                        <div className='chat_list_date'>
                                            <p>{new Date(message.createdAt).toLocaleDateString()}</p>
                                            <p>{new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
}
