import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";
class MessagingApi{
    baseUrl = EnvSettings.url;
    CreateOrgUrl = "CreateSalesOrg";
    GetUserPublicConversationsUrl = "GetUserPublicConversations";
    GetUserPrivateConversationsUrl = "GetUserPrivateConversations";
    LoadConversationUrl ="LoadConversation";
    SendPrivateMessageUrl = "SendPrivateMessage";
    SendOrgMessageUrl = "SendOrgMessage";
    SendGlobalMessageUrl = "SendGlobalMessage";
    CreateConversationUrl = "CreateConversation";
    GetConversationUsersUrl = "LoadConversationUsers";
    AddUserToConversationUrl = "AddUserToConversation";
    RemoveUserFromConversationUrl = "RemoveUserFromConversation";
    MarkConversationUserAsReadUrl = "MarkConversationUserAsSeen";
    GetUserConversationsUrl = "GetUserConversations";
    SendMessageUrl = "SendMessage";
    GetUserPrivateConversationsUrl = "GetUserPrivateConversations";
    GetUserGroupConversationsUrl = "GetUserGroupConversations";
    GetUserSalesOrgConversationsUrl = "GetUserSalesOrgConversations";
    GetUserCustomerServiceConversationsUrl = "GetUserCustomerServiceConversations";
    GetUserCustomerServiceUsingSalesOrgIdUrl = "GetUserCustomerServiceUsingSalesOrgId";
    GetGlobalConversationsUrl = "GetGlobalConversations";
    GetAllCustomerServicesUrl = "GetAllCustomerServices";
    DeleteMessageUrl = "DeleteMessage";
    EditMessageUrl = "EditMessage";
    UpdateConversationImageUrl = "UpdateConversationImage";
    GetConversationCloudFilesUrl = "GetConversationCloudFiles";
    GetConversationLinksUrl = "GetConversationLinks";
    FavouriteMessageUrl = "FavouriteMessage";
    GetUserFavouriteMessagesUrl = "GetUserFavouriteMessages";
    UnFavouriteMessageUrl = "UnFavouriteMessage";
    GetUserFavouriteMessagesByConversationUrl = "GetUserFavouriteMessagesByConversation";
    PinMessageUrl = "PinMessage";
    UnpinMessageUrl = "UnpinMessage";
    GetConversationPinnedMessagesUrl = "GetConversationPinnedMessages";

    async GetConversationPinnedMessages(req)
    {
        const res = await fetch(this.baseUrl + this.GetConversationPinnedMessagesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async UnPinMessage(req)
    {
        const res = await fetch(this.baseUrl + this.UnpinMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async PinMessage(req)
    {
        const res = await fetch(this.baseUrl + this.PinMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserFavouriteMessagesByConversation(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserFavouriteMessagesByConversationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async UnFavouriteMessage(req)
    {
        const res = await fetch(this.baseUrl + this.UnFavouriteMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserFavouriteMessages(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserFavouriteMessagesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async FavouriteMessage(req)
    {
        const res = await fetch(this.baseUrl + this.FavouriteMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetConversationLinks(req)
    {
        const res = await fetch(this.baseUrl + this.GetConversationLinksUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetConversationCloudFiles(req)
    {
        const res = await fetch(this.baseUrl + this.GetConversationCloudFilesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async UpdateConversationImage(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateConversationImageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserPublicConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserPublicConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserPrivateConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserPrivateConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async LoadConversation(req)
    {
        const res = await fetch(this.baseUrl + this.LoadConversationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    async SendPrivateMessage(req)
    {
        const res = await fetch(this.baseUrl + this.SendPrivateMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async SendOrgMessage(req)
    {
        const res = await fetch(this.baseUrl + this.SendOrgMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SendGlobalMessage(req)
    {
        const res = await fetch(this.baseUrl + this.SendGlobalMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async CreateConversation(req)
    {
        const res = await fetch(this.baseUrl + this.CreateConversationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetConversationUsers(req)
    {
        const res = await fetch(this.baseUrl + this.GetConversationUsersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddUserToConversation(req)
    {
        const res = await fetch(this.baseUrl + this.AddUserToConversationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveUserFromConversation(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveUserFromConversationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async MarkConversationUserAsRead(req)
    {
        const res = await fetch(this.baseUrl + this.MarkConversationUserAsReadUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserPrivateConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserPrivateConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserGroupConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserGroupConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserSalesOrgConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserSalesOrgConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserCustomerServiceConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserCustomerServiceConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetUserCustomerServiceUsingSalesOrgId(req)
    {
        const res = await fetch(this.baseUrl + this.GetUserCustomerServiceUsingSalesOrgIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetGlobalConversations(req)
    {
        const res = await fetch(this.baseUrl + this.GetGlobalConversationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SendMessage(req)
    {
        const res = await fetch(this.baseUrl + this.SendMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteMessage(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditMessage(req)
    {
        const res = await fetch(this.baseUrl + this.EditMessageUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetAllCustomerServices(req)
    {
        const res = await fetch(this.baseUrl + this.GetAllCustomerServicesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
}


export default new MessagingApi;