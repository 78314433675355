// src/components/ClappingHands.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Howl } from 'howler';
import clapSound from '../audio/clapping_hooray.mp3';

// Keyframes for the clapping animation
const clapAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

// Styled components
const HandsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;  // Ensure this is higher than SweetAlert's z-index
`;


const Hand = styled.div`
  font-size: 5rem;
  animation: ${clapAnimation} 0.5s ease-in-out infinite;
`;

// Load the clap sound
const clap = new Howl({
  src: [clapSound],
  volume: 1.0,
});

const ClappingHands = ({ onAnimationEnd }) => {
  React.useEffect(() => {
    clap.play();
    const timer = setTimeout(() => {
      onAnimationEnd();
    }, 2000); // Animation duration (2 seconds)
    return () => clearTimeout(timer);
  }, [onAnimationEnd]);

  return (
    <HandsContainer>
      <Hand>👏</Hand>
      <Hand>👏</Hand>
    </HandsContainer>
  );
};

export default ClappingHands;
