class CreateConsultationReq{
    constructor(){
        this.candidateInvitationId = null;
        this.additionalInfo = null;
        this.phone = null;
        this.name = null;
        this.personalEmail = null;
        this.positionTitle = null;
        this.prefferedDateTime = null;

    }
}

export default CreateConsultationReq;