import React from 'react'
import "./OurServices.scss"
import { IoWifi } from 'react-icons/io5'
import { IoMdTv } from 'react-icons/io'
import { FaPhone } from 'react-icons/fa6'
const OurServices = ({ fiberCompanyId }) => {
        return (
                <div className='offer_services_container'>
                        {
                                fiberCompanyId === 2 ?
                                        <div className='inner_container'>
                                                <h1>Why Lightcurve?</h1>
                                                <div className='services'>
                                                        <div className=' w-28 h-28'>
                                                                <img src='/images/offer/wifi.png' className=' object-contain' alt='1' />
                                                        </div>
                                                        <h3>Ultra-fast, <br />reliable<br />internet<br />connection.</h3>
                                                </div>
                                                <div className='services'>
                                                        <div className=' w-28 h-28'>
                                                                <img src='/images/offer/happy.png' className=' object-contain' alt='2' />
                                                        </div>
                                                        <h3 >No gimmicks,<br />no surprises.</h3>
                                                 </div>
                                                <div className='services'>
                                                        <div className=' w-28 h-28'>
                                                                <img src='/images/offer/headphone.png' className=' object-contain' alt='3' />
                                                        </div>
                                                        <h3>Local 24x7<br />Support</h3>
                                                </div>
                                        </div>
                                        :
                                        <div className='inner_container'>
                                                <h1><span className='services_heading'>Our Services</span><br />Fast, reliable internet.<br /> You want it.<br />we got it.</h1>
                                                <div className='services'>
                                                        <IoWifi color='#1E376D' size={100} />
                                                        <h3>Internet</h3>
                                                        <p>See Plans</p>
                                                </div>
                                                <div className='services'>
                                                        <IoMdTv color='#1E376D' size={100} />
                                                        <h3>TV</h3>
                                                        <p>Start Streaming now</p>
                                                </div>
                                                <div className='services'>
                                                        <FaPhone color='#1E376D' size={100} />
                                                        <h3>Voice</h3>
                                                        <p>Talk More. Pay Less</p>
                                                </div>
                                        </div>
                        }
                </div>
        )
}

export default OurServices