import React, { useContext, useEffect } from 'react'
import { ConnectionContext, NotificationsContext, UserContext } from '../../App';
// import { useLocation } from "react-router-dom";
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import anonUser from '../../assets/images/userGrey.png'
import ContextMenu from '../../components/ContextMenu';
import { Settings } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IoIosNotifications, IoIosWarning } from 'react-icons/io';
import StringIntReq from '../../Requests/StringIntReq';
import UsersApi from '../../API/UsersApi';
import { MdCancel, MdCelebration, MdSchedule } from 'react-icons/md';
import { BiHide } from 'react-icons/bi';
import { ClickAwayListener } from '@mui/material';
import { useLocation } from 'react-router-dom';
import BulkMarkNotificationsAsSeenReq from '../../Requests/Notifications/BulkMarkNotificationsAsSeenReq';
import ManyToManyReq from '../../Requests/ManyToManyReq';
import CustomerApi from '../../API/CustomerApi';
import { FcInfo } from 'react-icons/fc';
import Swal from 'sweetalert2';
const MobileLayoutHeader = () => {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [open, setOpen] = React.useState(null);
    const [themeMode, setThemeMode] = React.useState(localStorage.getItem('theme' + user?.id));
    const { notifications, setNotifications } = useContext(NotificationsContext)
    const [notificationPanel, setNotificationPanel] = React.useState(false);
    const connectionService = useContext(ConnectionContext)
    const route = useLocation().pathname.split("/").slice(1);

    const markNotificationAsSeen = async (notificationId) => {
        const req = new StringIntReq();
        setNotifications(
            (prev) => prev.filter((notification) => notification.id !== notificationId)
        )
        req.int = notificationId;
        const res = await UsersApi.MarkNotificationAsSeen(req);
        if (res?.status?.success) {
            // searchNotifications();
            console.log("Notification marked as seen");
        }
    }


    const bulkMarkNotificationsAsSeen = async () => {
        const req = new BulkMarkNotificationsAsSeenReq();
        req.notificationIds = notifications.filter(notification => !notification.seen).map(notification => notification.id);
        setNotifications(
            (prev) => prev.map((notification) => {
                if (req.notificationIds.includes(notification.id)) {
                    return { ...notification, seen: true }
                }
                return notification;
            })
        )
        const res = await UsersApi.BulkMarkNotificationsAsSeen(req);
        if (res?.status?.success) {
            // searchNotifications();
            console.log("All notifications marked as seen");

        }
    }

    // useEffect(() => {
    //     if (user && connectionService) {
    //         connectionService.newNotification = (notify) => {
    //             // searchNotifications();
    //             setNotifications((prev) => [notify,...prev]);
    //         }
    //     }
    // }, [user,connectionService]);

    const profileMenu = [
        {
            title: "Settings",
            icon: (color) => <Settings fontSize='medium' sx={{ color: color + " !important" }} />,
            onClick: () => {
                navigate("/edit-account-details")
            }
        },
        {
            title: "Logout",
            icon: (color) => <LogoutIcon fontSize='medium' sx={{ color: color + " !important" }} />,
            onClick: () => {
                if (connectionService && typeof connectionService.StopConnection === "function") {
                    console.log("Stopped Notificaiton Connection Service")
                    connectionService.StopConnection();
                }
                localStorage.removeItem("searchFilters");
                localStorage.removeItem("contactCenterLeadsFilter")
                getAuth().signOut()
                navigate("/")
                window.location.reload();
            }
        },

    ]

    useEffect(() => {
        const handleThemeChange = () => {
            setThemeMode(localStorage.getItem('theme' + user?.id) || 'light');
        };

        window.addEventListener('themeChange', handleThemeChange);

        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, [user]);

    const openMap = async (notification) => {
        if (notification.fiberHouse) {
            navigate('/map', {
                state: { fiberHouse: notification.fiberHouse }
            })
        }
        else {
            console.log("No FiberHouse")
        }

    }

    const deleteNotifications = async () => {
        const req = new BulkMarkNotificationsAsSeenReq();
        req.notificationIds = notifications.map(notification => notification.id);
        setNotifications([]);
        const res = await UsersApi.DeleteNotifications(req);
        if (res?.status?.success) {
            // searchNotifications();
            console.log("All notifications deleted");
        }
    }

    const generateICalEvent = (appointmentDateTime) => {
        const start = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d+/g, '');
        const end = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d+/g, ''); // Adding 1 hour to end time
        const icsContent = `
        BEGIN:VCALENDAR
        VERSION:2.0
        BEGIN:VEVENT
        SUMMARY:Consultation
        DTSTART:${start}
        DTEND:${end}
        DESCRIPTION:Appointment Details
        LOCATION:Appointment Location
        END:VEVENT
        END:VCALENDAR
    `;
    
        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'appointment.ics';
        link.click();
    
        Swal.fire('iCalendar Event Generated!');
    };

    const addGoogleCalendarEvent = (appointmentDateTime) => {
        const base = 'https://calendar.google.com/calendar/r/eventedit?';
        
        const startTime = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d\d\d/g, '');
        const endTime = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');
    
        // Creating URL parameters
        const params = new URLSearchParams({
            text: `Consultation`,  
            dates: `${startTime}/${endTime}`,  
        });
    

        return `${base}${params.toString()}`;
    };
    

    const handleAddToCalendar = (appointmentDateTime) => {
        const googleCalendarUrl = addGoogleCalendarEvent(appointmentDateTime);
        window.open(googleCalendarUrl, '_blank', 'noopener,noreferrer'); 
    };
    

    const promptAddToCalendar = async (appointmentDateTime) => {
        const { value } = await Swal.fire({
            title: 'Add Appointment to Calendar',
            text: 'Would you like to add this appointment to Google Calendar or iCalendar?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Google Calendar',
            denyButtonText: 'iCalendar',
            cancelButtonText: 'No, thanks',
        });
    
        if (value === true) {
            // User chose Google Calendar
            handleAddToCalendar(appointmentDateTime);
        } else if (value === false) {
            // User chose iCalendar
            generateICalEvent(appointmentDateTime);
        }
    };

    const changeOwnerShipOfTheConsultation = async (notification) => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Processing your request",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })

        const req = new ManyToManyReq();
        req.id1 = notification.consultation.id;
        req.id2 = user.id;
        const res = await CustomerApi.ChangeOwnershipOfConsultation(req);
        if (res?.status?.success) {
            console.log("Ownership Changed Successfully")
            setNotifications(
                (prev) => prev.map((not) => {
                    if (not.id === notification.id) {
                        return { ...not, consultation: { ...res.data } }
                    }
                    return notification;
                })
            )

            promptAddToCalendar(res?.data?.consultationTime)

        }
    }


    return (
        <div className='MobileLayoutHeader'>

            <>
                {
                    user?.id ?
                        <div className='MobileLayoutProfile' onClick={(e) => setOpen(e.currentTarget)} >
                            <img src={user?.profileImage ? user?.profileImage?.url : anonUser} className="w-12 h-12 rounded-full" />
                        </div>

                        :
                        <div className='w-12 h-12' onClick={() => {
                            navigate('/');
                        }} style={{ cursor: "pointer" }}>
                            {
                                themeMode === 'light' &&
                                <img alt='logo' className="w-full h-full" src="/images/nav/logo.png" />
                            }
                            {
                                themeMode === 'dark' &&
                                <img alt='logo' className="w-full h-full" src="/images/nav/dark-logo.png" />
                            }
                        </div>
                }
                {
                    themeMode === 'dark' &&
                    <div className='h-12'>
                        <img alt='logo' className='w-full h-full object-contain' src="/images/nav/dark-nav-logo.png" />
                    </div>
                }
                {
                    themeMode === 'light' &&
                    <div className='h-12'>
                        <img alt='logo' className='w-full h-full object-contain' src="/images/nav/nav-logo.png" />
                    </div>
                }
            </>




            {user?.id ?
                <div className="w-12 h-12 iconButton  notification_icon" onClick={() => {
                    // navigate('/');
                }} style={{ cursor: "pointer", justifySelf: "end" }}>
                    <div id='not-btn' onClick={(e) => {
                        // e.stopPropagation();
                        // e.preventDefault();
                        setNotificationPanel(!notificationPanel);
                        // if (notificationPanel) {
                        bulkMarkNotificationsAsSeen();
                        // }
                    }}>
                        <IoIosNotifications style={{ color: "var(--color-icon)" }} fontSize="35" />
                    </div>
                    {
                        notifications.filter(n => !n.seen).length > 0 &&
                        <p className="number">{notifications.filter(n => !n.seen).length}</p>
                    }
                    {
                        notificationPanel &&
                        <ClickAwayListener onClickAway={(e) => {
                            let isChild = document.getElementById('not-btn').contains(e.target);
                            if (!isChild) {
                                console.log(e.target)
                                setNotificationPanel(false)
                            }
                        }}>
                            <div className='notification_panel'>
                                <div style={{ borderBottom: "1px solid var(--color-icon)", padding: "0.5rem 0", display: "flex", justifyContent: "space-between" }}>
                                    <h1 style={{
                                        color: "var(--color-icon)",
                                        fontSize: "1.2rem",
                                        fontWeight: "bold"
                                    }}>Notifications</h1>
                                    <button style={{
                                        backgroundColor: notifications?.length > 0 ? "#ff2d2d" : "#ccc",
                                        color: "white",
                                        fontSize: "0.8rem",
                                        padding: "0.5rem 1rem",
                                        borderRadius: "10px",
                                    }}
                                        disabled={notifications?.length === 0}
                                        onClick={() => {
                                            deleteNotifications();
                                        }}>Delete All</button>
                                </div>
                                <div style={{
                                    // left: "0",
                                    minWidth: "90vw",
                                }} className="notification_panel_inner">
                                    {
                                        notifications?.length > 0 ?
                                            notifications.map((notification, index) => {
                                                return (
                                                    <div key={index} className="notification">
                                                        {
                                                            (notification.type === "CustomerCreated" || notification.type === "OfferAccepted") &&
                                                            <MdCelebration style={{ color: "gold" }} size={35} />
                                                        }
                                                        {
                                                            notification.type === "CancelPending" &&
                                                            <IoIosWarning style={{ color: "red" }} size={35} />
                                                        }
                                                        {
                                                            notification.type === "CancelConfirmed" &&
                                                            <MdCancel style={{ color: "red" }} size={35} />
                                                        }
                                                        {
                                                            notification.type === "CustomerScheduled" &&
                                                            <MdSchedule style={{ color: "green" }} size={35} />
                                                        }
                                                        {
                                                            notification.type === "CustomerRescheduled" &&
                                                            <MdSchedule style={{ color: "#ff8a22" }} size={35} />
                                                        }
                                                        {
                                                            notification.type === "Consultation" &&
                                                            <FcInfo style={{ color: "#2761D0" }} size={35} />
                                                        }
                                                        <p style={{
                                                            color: "rgb(113, 131, 155)",
                                                            fontSize: "0.8rem"
                                                        }}>{notification.message} {

                                                                notification.type !== "Consultation" && notification.fiberHouse && route[0] !== "map" && <span onClick={() => { openMap(notification) }} style={{
                                                                    color: "#2761D0",
                                                                    cursor: "pointer",
                                                                    textDecoration: "underline",
                                                                    fontWeight: "bold"
                                                                }}>Open on Map</span>}
                                                            {
                                                                notification.type === "Consultation" && notification?.consultation?.salesRepId == null &&
                                                                <span onClick={() => {
                                                                    changeOwnerShipOfTheConsultation(notification)
                                                                }} className="text-[#2761D0] cursor-pointer underline font-bold">Accept</span>
                                                            }
                                                        </p>
                                                        <BiHide onClick={() => {
                                                            markNotificationAsSeen(notification.id);
                                                        }} size={30} color="black" />
                                                        <p className="date">
                                                            {
                                                                // if timestamp is today, show time only else show date
                                                                new Date(notification.timeStamp).toDateString() === new Date().toDateString() ?
                                                                    new Date(notification.timeStamp).toLocaleTimeString() :
                                                                    new Date(notification.timeStamp).toDateString()
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="notification">
                                                <p>No Notifications</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </ClickAwayListener>
                    }
                </div>
                :
                <div style={{
                    justifySelf: "end",
                    alignSelf: "center",
                }}>
                    <Link to='/' style={{
                        color: "var(--color-text)",
                        fontSize: "1.5rem",
                        fontWeight: "500",
                        textDecoration: "none",
                        textTransform: "uppercase"
                    }}>Sign In</Link>
                </div>
            }
            <ContextMenu menuItems={profileMenu} anchorEl={open} handleClose={() => setOpen(null)} setAnchorEl={setOpen} />
        </div>
    )
}

export default MobileLayoutHeader