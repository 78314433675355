class DeleteShopItem{
    constructor(){
        this.string = null;
        this.int = null;
        this.pagingParams = {
          pageNumber: 0,
          pageSize: 0
        };
    }
}

export default DeleteShopItem