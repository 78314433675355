import React, { useEffect, useState } from 'react'
import "./index.scss"
import MobileVersionBusinessCard from '../../Reps/DigitalBusinessCenter/components/MobileVersionBusinessCard'
import GetOfferByGuidReq from '../../../Requests/Offer/GetOfferByGuidReq'
import OfferApi from '../../../API/OfferApi'
import { useParams } from 'react-router-dom'
import LightningLoader from '../../../components/Loader'
import Plan from './components/Plan'
import OurServices from './components/OurServices'
import { FaPhone } from 'react-icons/fa6'
import LightAcceptOfferForm from './components/LightAcceptOfferForm'
import { Modal } from '@mui/material'
import AppointmentForm from './components/AppointmentForm'
import Swal from 'sweetalert2'
import StringIntReq from '../../../Requests/StringIntReq'
import RequestCall from './components/RequestCall'
import ContactButtons from '../../../Services/ContactButtons'
import OfferModal from './components/OfferModal'
const Index = () => {

    const { id } = useParams();
    const [offer, setOffer] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [reload, setReload] = useState(false)
    const [open, setOpen] = useState(false)
    const [openConsultation, setOpenConsultation] = useState(false)
    const [openRequestCall, setOpenRequestCall] = useState(false)
    const [openShareModal, setOpenShareModal] = useState(false)
    const [color, setColor] = useState("")

    useEffect(() => {
        const getOffer = async () => {
            setLoading(true)
            // console.log(id)
            const req = new GetOfferByGuidReq();
            req.string = id;
            req.int = 1;
            req.pagingParams.pageNumber = 1;
            req.pagingParams.pageSize = 10;
            const res = await OfferApi.GetOfferByGuid(req);
            // console.log(res)
            if (res?.status?.success) {

                let fiberPlans = res.data.offerTemplate.offerTemplateFiberPlans

                if (res?.data?.fiberType === "Fiber") {
                    fiberPlans = fiberPlans.filter(plan => plan.fiberPlan.name.includes("FTTH"))
                }
                else {
                    fiberPlans = fiberPlans.filter(plan => !plan.fiberPlan.name.includes("FTTH"))
                }
                res.data.offerTemplate.offerTemplateFiberPlans = fiberPlans

                setOffer(res.data)
                // setSelectedPlan(res?.data?.offerTemplate?.offerTemplateFiberPlans[1]?.fiberPlan)


                setLoading(false)
                // }

            }
        }
        getOffer()
    }, [reload]);



    return (
        loading && !offer ?
            <LightningLoader />
            :
            <div className='new_offer_page'>
                <Modal minHeight={'80vh'} open={open} onClose={() => {
                    setOpen(false)
                }}>
                    {/* <CustomerForm setReload={setReload} fiberPlan={selectedPlan} offer={offer} setOpen={setCustomerModal} lead={offer.lead} /> */}
                    <LightAcceptOfferForm setOpenRequestCall={setOpenRequestCall} color={color} setReload={setReload} fiberPlan={selectedPlan} offer={offer} setOpen={setOpen} lead={offer?.lead} />
                </Modal>
                <Modal minHeight={'80vh'} open={openConsultation} onClose={() => {
                    setOpenConsultation(false)
                }}>
                    <AppointmentForm fiberCompanyId={offer?.fiberCompanyId} color={color} open={openConsultation} setOpen={setOpenConsultation} lead={offer?.lead} />
                </Modal>
                <Modal minHeight={'80vh'} open={openRequestCall} onClose={() => {
                    setOpenRequestCall(false)
                }}>
                    <RequestCall offer={offer} open={openRequestCall} color={color} setOpen={setOpenRequestCall} />
                </Modal>
                <Modal minHeight={'80vh'} open={openShareModal} onClose={() => {
                    setOpenShareModal(false)
                }}>
                    <OfferModal offer={offer} open={openShareModal} setOpen={setOpenShareModal} />
                </Modal>

                <div className='inner_container'>
                    <div className='banner'>
                        <img style={{ width: "100%", height: "auto" }} src={`/images/offer/${offer?.fiberCompany?.name?.toLowerCase()}_banner.png`} alt='banner' className='banner_image first' />
                        <div className='top_accept_offer'>
                            <div className='inner_div'>
                                <img style={{ width: "250px" }} src={"data:image/png;base64," + offer?.fiberCompany?.logo} alt='logo' className='logo' />
                                {
                                    offer?.fiberType === "Fiber" ?
                                    <h1 className='backpacks_ready'><span>Making your holidays bright</span><br/>with lightning - Fast Fiber internet</h1>
                                    :
                                    <h1 className='backpacks_ready'><span>{offer?.fiberCompany?.description?.split('\n')[0]}</span><br />{offer?.fiberCompany?.description.split('\n')[1]}</h1>
                                }
                                
                                <button className='accept_offer_button' onClick={() => {
                                    document.getElementById("plan1").scrollIntoView({ behavior: 'smooth' });
                                }}>See Plans </button>
                            </div>
                        </div>
                    </div>
                    <div className='company_details'>
                        <p className='limited_offer'>Limited Time Offer</p>
                        <div className='promo_offer_border'>
                            {
                                offer?.fiberCompanyId === 9 ?
                                    <h1 className='promo_offer' >Get Your<br />
                                        <span style={{
                                            textTransform: "uppercase",
                                            fontSize: "2.5rem",
                                            textShadow: "2px 2px 2px #4DD4FF"
                                        }}>FIRST MONTH FREE</span>
                                    </h1>
                                    :
                                    <h1 className='promo_offer' ><span>Get up to $150 Credit &</span><br />A Free Install</h1>
                            }
                        </div>
                        <p className='available'>available with</p>
                        <img src={`/images/offer/${offer?.fiberCompany?.name?.toLowerCase()}_authorized.png`} alt='fastwyre' className='company_logo' />
                        <img src='/images/offer/lightning_logo.png' alt='lightning' className='company_logo' />
                    </div>
                    {
                        offer?.salesRep &&
                        <div style={{
                            padding: "1rem",
                            marginTop: "2rem"
                        }}>
                            <div style={{ backgroundColor: "white", padding: "1rem", borderRadius: "10px", color: "black", display: "flex", flexDirection: "column" }}>
                                <MobileVersionBusinessCard displayContactButtons={false} displayQr={false} reviewButton={false} displayOffer={false} salesRep={offer?.salesRep} salesRepIsUser={false} />
                            </div>
                        </div>
                    }
                    {
                        offer?.offerTemplate &&
                        <div className='banner' id="plan1">
                            <img style={{ width: "100%", height: "auto" }} src="/images/offer/plan1.png" alt='banner' className='banner_image' />
                            <div style={{
                                backgroundColor: offer?.offerTemplate?.offerTemplateFiberPlans[1].color ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color : "#01A7E1",
                                marginTop: "-8rem",
                                padding: "5rem 1rem",
                                // borderRadius:"20px"
                            }}>
                                <Plan offer={offer} setColor={setColor} color={offer?.offerTemplate?.offerTemplateFiberPlans[1].color ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color : "#01A7E1"} setSelectedPlan={setSelectedPlan} setOpen={setOpen} name={offer?.offerTemplate?.offerTemplateFiberPlans[1].promotionalName} plan={offer?.offerTemplate?.offerTemplateFiberPlans[1].fiberPlan} bulletPoints={offer?.offerTemplate?.bulletPoints} />
                            </div>
                        </div>
                    }
                    {
                        offer?.offerTemplate &&
                        <div className='banner'>
                            <img style={{ width: "100%", height: "auto" }} src="/images/offer/plan2.png" alt='banner' className='banner_image' />
                            <div style={{
                                backgroundColor: offer?.offerTemplate?.offerTemplateFiberPlans[0].color ? offer?.offerTemplate?.offerTemplateFiberPlans[0].color : "#01AB52",
                                marginTop: "-8rem",
                                padding: "5rem 1rem",
                                // borderRadius:"20px"
                            }}>
                                <Plan offer={offer} setColor={setColor} color={offer?.offerTemplate?.offerTemplateFiberPlans[0].color ? offer?.offerTemplate?.offerTemplateFiberPlans[0].color : "#01AB52"} setSelectedPlan={setSelectedPlan} setOpen={setOpen} name={offer?.offerTemplate?.offerTemplateFiberPlans[0].promotionalName} plan={offer?.offerTemplate?.offerTemplateFiberPlans[0].fiberPlan} bulletPoints={offer?.offerTemplate?.bulletPoints} />
                            </div>
                        </div>
                    }
                    <div className='banner'>
                        <img style={{ width: "100%", height: "auto" }} src="/images/offer/schedule.png" alt='banner' className='banner_image' />
                        <div style={{ marginTop: "-4rem", width: "100%", backgroundColor: "white", padding: "2rem 0rem" }}>
                            <button onClick={() => setOpenConsultation(true)} className='schedule_button'>Schedule A<br />Consultation</button>
                            <h1 className='customer_service'>Great Customer<br />Service is Built In.</h1>
                            <p className='customer_service_p'>We understand the importance<br /> of the services we provide to <br />your home – that’s why we<br />treat you like family.</p>
                        </div>
                    </div>
                    <div className="banner">
                        <div style={{
                            padding: "5rem 1rem",
                            textAlign: "center",
                        }}>
                            {
                                offer?.fiberCompanyId === 9 ?
                                    <>
                                        <h3 style={{ fontSize: "1.5rem", fontWeight: "bold", textTransform: "uppercase" }}>Refer a Friend</h3>
                                        <h1 style={{ fontSize: "3rem", fontWeight: "bold", textTransform: "uppercase" }}>Get a Free<br />Month &<br />Give A Free<br />Month</h1>
                                        <p style={{ fontSize: "1.3rem" }}>In addition to our<br />current promo!</p>
                                    </>
                                    :
                                    <>
                                        <h3 style={{ fontSize: "1.5rem", fontWeight: "bold", textTransform: "uppercase" }}>Refer a Friend</h3>
                                        <h1 style={{ fontSize: "3rem", fontWeight: "bold", textTransform: "uppercase" }}>Give a Free<br />Month &<br />Get $100<br />visa gift card</h1>
                                    </>
                            }
                        </div>
                        {/* 
                            1. Create an Offer with an empty lead.
                            2. Copy the url of the offer in the clipboard.
                        */}
                        <button onClick={() => {
                            // createSharedOffer()
                            setOpenShareModal(true)
                        }} style={{ marginBottom: "-2rem" }} className='schedule_button' >Share Offer</button>
                    </div>

                    <div className='banner'>
                        <img style={{ width: "100%", height: "auto" }} src='/images/offer/our_services.png' alt='banner' className='banner_image' />
                        <div style={{
                            backgroundColor: offer?.offerTemplate?.offerTemplateFiberPlans[1].color ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color : "#01A7E1",
                            marginTop: "-16rem",
                            padding: "5rem 1rem",
                            // borderRadius: "20px"
                        }}>
                            <OurServices fiberCompanyId={offer?.fiberCompanyId} />
                            {
                                offer?.fiberCompanyId === 9 &&
                                <div className='here_there'>
                                    <h1>We’re growing to serve you better<br />expanding our reach, one step at a time.</h1>
                                    <img style={{ width: "100%", height: "auto" }} src='/images/offer/here_there.png' alt='here_there' className='here_there_image' />
                                </div>
                            }
                        </div>
                    </div>
                    {
                        offer?.fiberCompanyId === 2 &&
                        <div className='banner' >
                            <img style={{ width: "100%", height: "auto" }} src='/images/offer/lightcurve_gamer.png' alt='banner' className='banner_image' />
                            <div style={{
                                backgroundColor: "#00B0BC",
                                marginTop: "-16rem",
                                padding: "5rem 1rem",
                                // zIndex: "1",
                                // borderRadius: "20px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    zIndex: "10",
                                    position: "relative",
                                    padding: "2rem",
                                    borderRadius: "20px"
                                }}>
                                    <h1 style={{ fontSize: "1.5rem", color: "#1A1349", textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }}>Your Ultimate Gaming Companion</h1>
                                    <p style={{ color: "#1A1349", textAlign: "center", fontWeight: "500" }}>
                                        Experience uninterrupted gaming with Lightcurve's 1 Gig speeds, minimizing lag and buffering for a seamless playtime. Say goodbye to dead-zones and jitters. Say hello to better gaming.
                                    </p>
                                    <h1 style={{ fontSize: "1.5rem", color: "#1A1349", textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }}>SAY HELLO TO BETTER GAMING</h1>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='banner'>
                        <div style={{ backgroundColor: "white", borderRadius: "20px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", padding: "2rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h1 className='quick_h1'>Quick Links</h1>
                            <FaPhone size={70} color={"#170F49"} />
                            <h3 className='quick_h3'>Contact</h3>
                            <p className='quick_p'>Lightning Support</p>
                            <p onClick={() => {
                                let contactButton = new ContactButtons();
                                contactButton.call("+12533000442")
                            }} className='quick_p cursor-pointer underline'>253-300-0442</p>
                            <img style={{ margin: "1rem 0rem" }} src={`/images/offer/${offer?.fiberCompany?.name?.toLowerCase()}_white_authorized.png`} alt='fastwyre' className='company_logo' />
                            <img style={{ margin: "1rem 0rem" }} src='/images/offer/logo.png' alt='lightning' className='company_logo' />
                            <p className='quick_copy'>Copyright Lightning OS 2024. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Index