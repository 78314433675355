import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import "./ManageIssues.scss"
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq'
import CustomerApi from '../../../API/CustomerApi'
import { UserContext } from '../../../App'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Pagination, PaginationItem } from '@mui/material'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import Datatable from '../../../components/Datatable'
const Index = () => {

    const user = useContext(UserContext)
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [issueType, setIssueType] = useState('')
    const [orderNumber, setOrderNumber] = useState('')

    const [customers, setCustomers] = useState([]);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [hasSearched, setHasSearched] = useState(false);
    const searchCustomers = async (pageN) => {
        setHasSearched(true);
        setLoading(true);
        const req = new SearchCustomerReq();
        req.firstName = firstName;
        req.lastName = lastName;
        req.email = email;
        req.cellPhone = phone;
        req.issueType = issueType;
        req.orderNumber = orderNumber;
        req.hasIssue = true;
        req.fiberCompanyIds = [user.fiberCompanyId];
        if(pageN)
        {
            req.pagingParams.pageNumber = pageN;
            setCurrentPage(pageN);
        }
        else{
            req.pagingParams.pageNumber = currentPage;
        }
        req.pagingParams.pageSize = 20;
        const res = await CustomerApi.SearchFidiumCustomers(req);
        if (res?.status?.success) {
            const issuesData = res?.data?.list.map(issue => {
                const cancelationForms = issue.cancelationForms || [];
                const rescheduleForms = issue.rescheduleForms || [];
                const numOfIssues = cancelationForms.length + rescheduleForms.length;
                let latestTimeStamp = null;
                if (cancelationForms.length > 0) {
                    latestTimeStamp = cancelationForms[0].timestamp;
                }
                if (rescheduleForms.length > 0) {
                    latestTimeStamp = rescheduleForms[0].timestamp;
                }
                return {
                    ...issue,
                    cancelationForms,
                    rescheduleForms,
                    numOfIssues,
                    timeStamp: new Date(latestTimeStamp).toLocaleString(),
                };
            });

            setCustomers(issuesData);
            setTotalCustomers(res?.data?.totalItems);

            setTotalPages(res?.data?.totalPages);
            setLoading(false);
        }

    }

    useEffect(
        () => {
            searchCustomers();
        }, [currentPage]
    )

    const renderForms = (forms, type) => {
        console.log(type);
        
        return forms.reverse().map((form, index) => {
            const route = type === 'Cancellation' ? `/manage-issues/issue-cancel-details/${form.id}` : `/manage-issues/issue-reschedule-details/${form.id}`;
            return (
                <span
                    key={form.id}
                    onClick={() => navigate(route)}
                    style={{ color: form.isResolved ? 'green' : 'red', cursor: 'pointer', marginBottom: '0.5rem',fontSize:"0.8rem" }}
                >
                    {type} {forms.length -(index )}
                </span>
            );
        });
    };
    const headers = [
        { name: "First Name", key: "firstName" },
        { name: "Last Name", key: "lastName" },
        { name: "Current Issue Type", key: "issueType" },
        { name: "Current Account Status", key: "accountStatus" },
        {
            name: "Time Stamp",
            key: "timeStamp",
        },
        {
            name:"Email",
            key:"email"
        },
        {
            name:"Phone",
            key:"cellPhone"
        },
        {
            name:"Order Number",
            key:"orderNumber"
        },
        { name: "# of Issues", key: "numOfIssues" },
        {
            name: "Issues",
            key: "issues",
            render: (row) => (

                <td style={{ display: 'flex', flexDirection: 'column',overflowY:"auto",maxHeight:"200px" }}>
                    {renderForms(row.cancelationForms, 'Cancellation')}
                    {renderForms(row.rescheduleForms, 'Reschedule')}
                </td>
            )
        }
    ];


    return (
        <Layout>
            <div className='manage_customer_company_user'>
                <h1>Manage Issues</h1>
                <div className='filters'>
                    <div className='filter_input'>
                        <label>First Name</label>
                        <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className='filter_input'>
                        <label>Last Name</label>
                        <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className='filter_input'>
                        <label>Email</label>
                        <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='filter_input'>
                        <label>Phone Number</label>
                        <input type='text' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className='filter_input'>
                        <label>Issue Type</label>
                        <select value={issueType} onChange={(e) => { setIssueType(e.target.value) }}>
                            <option value={''}>Issue Type</option>
                            <option value={'Cancel'} >Cancel</option>
                            <option value={'Reschedule'} >Reschedule</option>
                        </select>
                    </div>
                    <div className='filter_input'>
                        <label>Order Number</label>
                        <input type='text' value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
                    </div>
                    <button onClick={() => searchCustomers(1)}>Search</button>
                </div>
                {loading ? (
                    <div className='spinner'>
                        <CircularProgress sx={{ color: "var(--color-icon)" }} />
                    </div>
                ) : (
                    hasSearched && (
                        <>

                            <Datatable headers={headers} list={customers} currentPage={currentPage} totalPages={totalPages} />
                            <div className='pagination'>
                                <Pagination
                                    color='light'
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{
                                                next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                                previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                            }}
                                            {...item}
                                            sx={{ color: "white" }}
                                        />
                                    )}
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(e, v) => setCurrentPage(v)}
                                />
                            </div>

                        </>
                    )
                )}
            </div>
        </Layout>
    )
}

export default Index