/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import MapIconApi from '../../API/MapIconApi';
import SearchMapIconReq from '../../Requests/MapIcon/SearchMapIconReq';
import SoftTypography from './../SoftTypography';
const Index = ({setAssignedMapIcons,disabled=false,placeholder="Select a parent Organization",className, salesOrgId = null,multiple}) => {
    const [UsersSearch, setUserSearch] = useState()
    const [options, setOptions] = useState([])
    const GetOrg = async () => {
        let searchReq = new SearchMapIconReq();
        
        searchReq.name = UsersSearch; 
        searchReq.pagingParams.pageSize = 100;
        searchReq.pagingParams.pageNumber = 1;
        let res = await MapIconApi.SearchMapIcon(searchReq)
        
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: <div style={{display:"flex",gap:"1rem",alignItems:"center",justifyContent:"flex-start"}}><img src={"data:image/png;base64,"+item.image} width={"50px"} height={"50px"} alt={item.name} /><p>{item.name}</p></div>,id:item.id })
            })
            return optionsArr;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
        };
        getOptions();
    }, []);
    return (
        <div className={className}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
             
                <AsyncSelect
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    onChange={(e) => {setAssignedMapIcons(e)}}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isMulti={multiple}
                    defaultOptions={options}
                />
            </SoftTypography>

        </div>
    )
}

export default Index