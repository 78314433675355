class CustomerFeedbackReq {
    constructor() {
        this.Id = null;
        this.FidiumCustomerId = null;
        this.SalesRepId = null;
        this.Content = null;
        this.Timestamp = null;
        this.Rating = null;
        this.FeedbackType =  null;
        this.VideoId = null;
        this.SpeedTestId = null;
        this.LeadId = null;
        this.Video = null;
        this.SpeedTest = null;
        this.LeadId = null;
        this.PagingParams = {
            PageNumber: 1,
            PageSize: 10,
        };
    }
}

export default CustomerFeedbackReq;