import React, { useState } from 'react'
import './ReferralProgram.scss'
import PublicLayout from './../../../Layouts/PublicLayout';
// import lightning from './images/lightning.png'
import equal from './images/=.png'
import fire from './images/fire.png'
import giftcard from './images/giftcard.png'
import donation from './images/donation.png'
import banner_top_light from './images/banner_top_light.png'
import banner_bottom_light from './images/banner_bottom_light.png'
import donation_light_left from './images/donation_light_left.png'
import become_partner_light from './images/become_partner_light.png'
import Swal from 'sweetalert2';
import CreateUserReq from '../../../Requests/Users/CreateUserReq';
import UsersApi from '../../../API/UsersApi';
import PhoneInput from 'react-phone-input-2'
const Index = () => {


    // become a referral partner form

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cellphone, setCellphone] = useState('')

    const becomeReferralPartner = async () => {
        Swal.fire({
            icon: "info",
            title: "Creating Referral Partner",
            text: "Please wait a moment",
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        if (!firstName) {
            Swal.fire({
                icon: "error",
                title: "First Name is required",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lastName) {
            Swal.fire({
                icon: "error",
                title: "Last Name is required",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!cellphone) {
            Swal.fire({
                icon: "error",
                title: "Cellphone is required",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(cellphone.length < 11){
            Swal.fire({
                icon: "error",
                title: "Cellphone is invalid",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateUserReq();
        req.firstName = firstName;
        req.lastName = lastName;
        req.phone = cellphone;
        req.email = ""
        req.address = ""
        req.password = ""
        req.userType = "Customer"
        const res = await UsersApi.CreateUserUsingPhone(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Referral Partner Created",
                text: "You can Login now",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Failed to create referral partner",
                text: res?.status?.message,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

        }

    }

    return (
        <PublicLayout>
            <div className='referral_program_container'>
                <h1 className='referral_program_title'>Pay-It-Forward<br />Referral Program</h1>
                <div className='referral_program_banner'>
                    <img src={banner_top_light} className='banner_top_light' alt='banner_top' />
                    <img src={banner_bottom_light} className='banner_bottom_light' alt='banner_bottom' />
                    <div className='white_container'>
                        <div className='white_container_top'>
                            <div className='blue_container'>
                                <p>Share</p>
                                <div className='bottom'>
                                    <h3>Free<br />Install</h3>
                                </div>
                            </div>
                            {/* <div className='lightning'>
                                <img src={lightning} alt='lightning' />
                            </div>
                            <div className='blue_container'>
                                <p>Refer</p>
                                <div className='bottom'>
                                    <h3>Free<br />Month</h3>
                                </div>
                            </div> */}
                            <div className='equal'>
                                <img src={equal} alt='equal' />
                            </div>
                            <div className='blue_container'>
                                <p>Reward</p>
                                <div className='bottom'>
                                    <h3 className='price'>$50</h3>
                                    <img className='fire' src={fire} alt='fire' />
                                </div>
                            </div>
                        </div>
                        <div className='white_container_text'>
                            <h1>Create Positive Impact - TOGETHER</h1>
                            <p>Give A <span>Free Install</span> To Receive <span>Cash Rewards</span></p>
                        </div>
                    </div>
                    <div className='border_container'>
                        <div className='custom_border'>
                            <img src={giftcard} alt='giftcard' />
                        </div>
                    </div>
                </div>
                <div className='donation_card_container'>
                    <img src={donation_light_left} className='donation_light_left' alt='donation_light_left' />
                    <div className='donation_card_top'>
                        <h1>For Every Fiber<br />Connection</h1>
                    </div>
                    <div className='donation_card_middle'>
                        <img src={donation} alt='donation' />
                    </div>
                    <div className='donation_card_bottom'>
                        <h1>$50 Is Donated</h1>
                        <h2>Delivering Kindness<br />Curriculum To<br />Local Schools</h2>
                    </div>
                </div>
                <div className='form_wrapper'>
                    <img src={become_partner_light} className='become_partner_light' alt='become_partner_light' />
                    <div className='referral_form'  >
                        <h1>Become A<br />Referral Partner<br />Today</h1>
                        <div className='in_container'>
                            <input className='in' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type='text' placeholder='First Name' />
                            <input className='in' value={lastName} onChange={(e) => { setLastName(e.target.value) }} type='text' placeholder='Last Name' />
                            {/* <input value={cellphone} onChange={(e)=>{setCellphone(e.target.value)}} type='text' placeholder='Cellphone' /> */}
                            <PhoneInput inputStyle={{
                                paddingLeft: "50px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                width: "100%",
                                height: "100%",
                                textAlign:"center",
                                
                            }}
                            containerStyle={{
                                width: "100%",
                                height: "50px",
                                position: "relative",
                            }}

                                country={'us'} excludeCountries={['IL']} value={cellphone} onChange={setCellphone} />
                            <button onClick={becomeReferralPartner}>JOIN NOW</button>
                        </div>
                    </div>
                </div>

            </div>
        </PublicLayout>
    )
}

export default Index