import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";


class ShopItemsApi{
    baseUrl = EnvSettings.url;
    CreateShopItemsUrl = "CreateShopItem"
    SearchShopItemUrl = "SearchShopItems"
    DeleteShopItemsUrl = "DeleteShopItem"
    CreateShopItemsCategoryUrl = "CreateShopCategory"
    SearchShopItemsCategoryUrl = "SearchShopCategories"
    EditShopItemUrl = "EditShopItem"
    RemoveFileUrl = "DeleteCloudFile"
    AddFileUrl = "AddFilesToShopItem"
    async CreateShopItems(req)
    {
        const res = await fetch(this.baseUrl + this.CreateShopItemsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchShopItems(req)
    {
        const res = await fetch(this.baseUrl + this.SearchShopItemUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async DeleteShopItems(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteShopItemsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async CreateShopItemsCategory(req)
    {
        const res = await fetch(this.baseUrl + this.CreateShopItemsCategoryUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async SearchShopItemsCategory(req)
    {
        const res = await fetch(this.baseUrl + this.SearchShopItemsCategoryUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async EditShopItem(req)
    {
        const res = await fetch(this.baseUrl + this.EditShopItemUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async RemoveFile(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveFileUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async AddFile(req)
    {
        const res = await fetch(this.baseUrl + this.AddFileUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
}

export default new ShopItemsApi;