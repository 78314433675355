import React from 'react'
import InputEmoji from "react-input-emoji"
// import Sidebar from '../../../Layouts/Sidebar'
import Layout from '../../../Layouts/Layout'
const Index = ({ routes }) => {
    const [msg, setMsg] = React.useState("")
    const send = () => {
        
        setMsg("")
    }
    return (
        <Layout >
            <div className='flex flex-col gap-5 justify-center align-middle mx-auto'>
                <div className="ChatBox-container">
                    <div className="chat-header">
                        <div className="follower">
                            <div>
                                
                                <img
                                    src={
                                        "/images/Avatar/customer-service.png"
                                    }
                                    alt="Profile"
                                    className="followerImage"
                                    style={{ width: "50px", height: "50px" }}
                                />
                                <div className="name" style={{ fontSize: "0.9rem" }}>
                                    <span>
                                        Customer Support
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr
                            style={{
                                width: "95%",
                                border: "0.1px solid #ececec",
                                marginTop: "20px",
                            }}
                        />
                    </div>
                    <div className="chat-body" >

                    </div>
                    <div className='flex items-center justify-between'>
                        <InputEmoji
                            value={msg}
                            placeholder="Type a message"
                            className="message-input"
                            style={{ width: "100%" }}
                            onEnter={() => send()}
                            onEscape={() => setMsg("")}
                            onTab={() => setMsg("")}

                            onChange={(s) => setMsg(s)}
                        />
                        <div className="send-button button" onClick={() => { send() }}>Send</div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Index