
import React, { useState,useEffect } from 'react';
import './AudioRecorder.scss';
import { FaPlay } from "react-icons/fa6";
import { FaPause } from "react-icons/fa6";
import { IoMdSend } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
const Index = ({onSend,onDiscard,setAudioLength}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const [mr, setMr] = useState(null);
    const [duration, setDuration] = useState(0);
    const startRecording = async () => {
        try {
            // console.log('startRecording')
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    // console.log('ondataavailable')
                    setAudioChunks((prevChunks) => [...prevChunks, e.data]);
                }
            };

            mediaRecorder.start(100); // timeslice of 1000ms
            // console.log("mediaRecorder", mediaRecorder)
            setMr(mediaRecorder);
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting Recording:', error);
        }
    };

    const pauseRecording = () => {
        if (mr) {
            mr.stream.getTracks().forEach(track => track.stop());
            mr.stop();
            setMr(null);

        }
        setIsRecording(false);
    };

    const clearRecording = () => {
        setAudioChunks([]);
        setDuration(0);
        setIsRecording(false);
        if (mr) {
            mr.stream.getTracks().forEach(track => track.stop());
            mr.stop();
            setMr(null);
        }
        onDiscard()
    };



    const downloadRecording = () => {
        
        setAudioLength(duration)
        
        if (mr) {
            mr.stream.getTracks().forEach(track => track.stop());
            mr.stop();
            setMr(null);
        }
        // setTimeout(() => {
        // setDuration(0);
        // }, 500);
        setIsRecording(false);
        const blob = new Blob(audioChunks, { type: 'audio/mp3' });
        setAudioChunks([]); 
        onSend(blob,duration)
    };

    useEffect(() => {
        let interval;
      
        if (isRecording) {
          interval = setInterval(() => {
            setDuration((prevTime) => prevTime + 1);
          }, 1000);
        }
      
        return () => clearInterval(interval);
      }, [isRecording]);
      

    return (
        <div className='audioRecorderContainer'
        >
            <IoMdClose style={{
                cursor: 'pointer'
            }} size={20} onClick={()=>{
                clearRecording()  
            }}  />
          
            {!isRecording ? <button onClick={startRecording} >
                <FaPlay color='#fff' />
            </button>
                :
                <button onClick={pauseRecording} >
                    <FaPause color='#fff' />
                </button>}
            {/* <Visualizer audioChunks={audioChunks} /> */}
            {isRecording &&
                <div style={{
                    height: '20px',
                    width: '20px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    animation: 'blinking 1s infinite'
                }}></div>
            }
            <div>
            {Math.floor(duration / 60)}:{("0" + (duration % 60)).slice(-2)}
            </div>
            <button style={{
                backgroundColor: audioChunks.length === 0 ? 'grey' : 'red'
            }} onClick={clearRecording} disabled={audioChunks.length === 0}
           > 
                <MdDelete color='#fff' />
            </button>
            <button onClick={downloadRecording} disabled={audioChunks.length === 0}>
                <IoMdSend color='#fff' />
            </button>
        </div>
    );
};

export default Index;