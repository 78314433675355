import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class IssueApi {
    baseUrl = EnvSettings.url;
    CreateIssueUrl = 'CreateIssue';
    UpdateIssueUrl = 'UpdateIssue';
    ResolveIssueUrl = 'ResolveIssue';

    async CreateIssue(input) {
        const res = await fetch(this.baseUrl + this.CreateIssueUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(input)
        });
        const data = await res.json();
        return data;
    }

    async UpdateIssue(input) {
        const res = await fetch(this.baseUrl + this.UpdateIssueUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(input)
        });
        const data = await res.json();
        return data;
    }

    async ResolveIssue(input) {
        const res = await fetch(this.baseUrl + this.ResolveIssueUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(input)
        });
        const data = await res.json();
        return data;
    }
}

export default new IssueApi();