/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
// import { RiChatPrivateFill } from "react-icons/ri";
// import { HiUserGroup } from "react-icons/hi";
import { RiCustomerServiceLine } from "react-icons/ri";
// import { GoOrganization } from "react-icons/go";
import { AiOutlineGlobal } from "react-icons/ai";
import Layout from '../../../Layouts/Layout'
import ChatList from './components/ChatList'
import { ConnectionContext, UserContext } from '../../../App'
// import { AiOutlineAppstore } from "react-icons/ai";
import { PrivateChatIcon, GroupChatIcon } from './components/Icon';
import { BsLightningChargeFill } from 'react-icons/bs';

const Index = () => {
    const user = useContext(UserContext)
    const connectionService = useContext(ConnectionContext);
    const [newChat, setNewChat] = useState(0)
    const [conversationType, setConversationType] = useState("Private");
    const items = [
        {
            id: 0,
            name: "Private",
            icon: (color) => <PrivateChatIcon color={color} />,
            title: "Private",
            users: ['SuperAdmin', 'Admin', 'SalesRep', 'SalesOrgAdmin']
        },
        {
            id: 1,
            name: "Group",
            icon: (color) => <GroupChatIcon color={color} />,
            title: "Group",
            users: ['SuperAdmin', 'Admin', 'SalesRep', 'SalesOrgAdmin']
        },
        {
            id: 2,
            name: "CustomerService",
            icon: (color) => <RiCustomerServiceLine color={color} size={40} />,
            title: "Customer Service",
            users: ['SuperAdmin', 'Admin', 'SalesRep', 'SalesOrgAdmin', 'Customer']
        },
        {
            id: 3,
            name: "SalesOrg",
            icon: (color) => <BsLightningChargeFill size={30} color={color} />,
            title: "Sales Org",
            users: ['SuperAdmin', 'Admin', 'SalesRep', 'SalesOrgAdmin']
        },
        {
            id: 4,
            name: "Global",
            icon: (color) => <AiOutlineGlobal color={color} size={40} />,
            title: "Global",
            users: ['SuperAdmin', 'Admin', 'SalesRep', 'SalesOrgAdmin']
        }
    ]

    const reload = (type) => {
        if (type === conversationType) {
            console.log("Reload Called")
            setNewChat(prev => prev + 1)
        }
    }

    useEffect(
        () => {
            connectionService.newChat = reload

            return () => {
                connectionService.newChat = null
            }
        }, []
    )

    return (
        <Layout>
            <div className='chat_container'>
                <div className="chat_nav_icons">
                    {
                        items.map(item => {
                            if (!item.users.includes(user.userType)) {
                                return null;
                            }
                            return <div key={item.id} style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }} onClick={() => setConversationType(item.name)}>
                                {item.icon(conversationType === item.name ? "#2761D0" : "#81848A")}
                                {/* <p className="nav-text" style={{
                                    color: conversationType === item.name ? "#2761D0" : "#81848A",
                                    textAlign: "center"
                                }}>{item.title}</p> */}
                            </div>
                        })
                    }
                </div>
                <div>
                    <h1 style={{
                        color: "var(--primary-color)",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        textAlign: "center"
                    }}>{items.filter(i => i.name === conversationType)[0].title}</h1>
                </div>
                <div>
                    <ChatList newChat={newChat} type={conversationType} />
                </div>
            </div>
        </Layout>
    )
}

export default Index