import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq'
import CustomerApi from '../../../API/CustomerApi'
import { UserContext } from '../../../App'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Pagination, PaginationItem } from '@mui/material'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import Datatable from '../../../components/Datatable'
import Swal from 'sweetalert2'

import StringIntReq from '../../../Requests/StringIntReq'
const Index = () => {

    const user = useContext(UserContext)
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [issueType, setIssueType] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [agreement,setAgreement] = useState('');
    const [account,setAccount] = useState('');

    const [customers, setCustomers] = useState([]);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [hasSearched, setHasSearched] = useState(false);

    

    const searchCustomers = async (pageN) => {
        setHasSearched(true);
        setLoading(true);
        const req = new SearchCustomerReq();
        req.firstName = firstName;
        req.lastName = lastName;
        req.email = email;
        req.cellPhone = phone;
        req.issueType = issueType;
        req.orderNumber = orderNumber;
        req.afgreement = agreement;
        req.account = account;
        // req.accountStatuses = ['Scheduled','Rescheduled']
        req.fiberCompanyIds = [user.fiberCompanyId];
        if (pageN) {
            req.pagingParams.pageNumber = pageN;
            setCurrentPage(pageN);
        }
        else {
            req.pagingParams.pageNumber = currentPage;
        }
        req.pagingParams.pageSize = 20;
        const res = await CustomerApi.SearchFidiumCustomers(req);
        if (res?.status?.success) {
            let buttonStyle = {
                color: "white",
                backgroundColor: "#41B6E6",
                padding: "0.5rem 1rem",
                borderRadius: "10px",
            }
            const customersData = res?.data?.list.map(customer => {
                return {
                    ...customer,
                    installDateTime: customer.installDateTime ? new Date(customer.installDateTime).toLocaleDateString() + " " + new Date(customer.installDateTime).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    }) : null,
                    cancel : <button onClick={()=>cancelCustomer(customer.id)}  style={buttonStyle}>Cancel</button>,
                    reschedule : <button onClick={()=>{
                        navigate('/manage-customers/reschedule',{
                            state:{
                                customerId: customer.id
                            }
                        })
                    }} style={buttonStyle}>Reschedule</button>,
                    accountStatus : customer.soStatus
                }
            }
            )


            setCustomers(customersData);
            setTotalCustomers(res?.data?.totalItems);

            setTotalPages(res?.data?.totalPages);
            setLoading(false);
        }

    }

    const cancelCustomer = async (id) =>{
         const o = await Swal.fire({
            title: 'Are you sure?',
            text: "The customer's installation will be Voided!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Void Installation'
            })

        if(!o.isConfirmed){
            return;
        }
        Swal.fire({
            icon:"info",
            title:"Cancelling Installation",
            text:"Please wait",
            showConfirmButton:false,
            allowOutsideClick:false,
            didOpen: () =>{
                Swal.showLoading()
                
            }
        })
        
        const req = new StringIntReq();
        req.int = id;
        req.string = "Customer"
        const res = await CustomerApi.ConfirmCancelation(req);

        if(res?.status?.success){
            Swal.fire({
                icon:"success",
                title:"Installation Voided",
                text:"The installation has been voided successfully",
                didOpen: () =>{
                    Swal.hideLoading()
                }
            })
            searchCustomers();
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"An error occured while voiding the installation",
                didOpen: () =>{
                    Swal.hideLoading()
                }
            })
        }

    }

    useEffect(
        () => {
            searchCustomers();
        }, [currentPage]
    )


    const headers = [
        {
            name: "First Name",
            key: "firstName"
        },
        {
            name: "Last Name",
            key: "lastName"
        },
        {
            name: "Current SO Status",
            key: "accountStatus"
        },
        {
            name: "Email",
            key: "email"
        },
        {
            name: "Phone",
            key: "cellPhone"
        },
        {
            name: "SO",
            key: "orderNumber"
        },
        {
            name: "Install Date",
            key: "installDateTime"
        },
        {
            name :"Intall Date To",
            key:"originalIntstallDateTimeTo"
        },
        {
            name:"Account",
            key:"account"
        },
        {
            name:"Agreement",
            key:"agreement"
        },
        {
            name:"Reschedule",
            key:"reschedule"
        },
        {
            name:"Cancel",
            key:"cancel"
        },

    ];


    return (
        <Layout>
            <div className='manage_customer_company_user'>
                <h1>Manage Customers</h1>
                <div className='filters'>
                    <div className='filter_input'>
                        <label>First Name</label>
                        <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className='filter_input'>
                        <label>Last Name</label>
                        <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className='filter_input'>
                        <label>Email</label>
                        <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='filter_input'>
                        <label>Phone Number</label>
                        <input type='text' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    {/* <div className='filter_input'>
                        <label>Issue Type</label>
                        <select value={issueType} onChange={(e) => { setIssueType(e.target.value) }}>
                            <option value={''}>Issue Type</option>
                            <option value={'Cancel'} >Cancel</option>
                            <option value={'Reschedule'} >Reschedule</option>
                        </select>
                    </div> */}
                    <div className='filter_input'>
                        <label>Agreement</label>
                        <input type='text' value={agreement} onChange={(e)=>{setAgreement(e.target.value)}} />
                    </div>
                    <div className='filter_input'>
                        <label>Account</label>
                        <input type='text' value={account} onChange={(e)=>{setAccount(e.target.value)}} />
                    </div>
                    <div className='filter_input single'>
                        <label>SO (Order Number)</label>
                        <input type='text' value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
                    </div>
                    <button onClick={() => searchCustomers(1)}>Search</button>
                </div>
                {loading ? (
                    <div className='spinner'>
                        <CircularProgress sx={{ color: "var(--color-icon)" }} />
                    </div>
                ) : (
                    hasSearched && (
                        <>

                            <Datatable headers={headers} list={customers} currentPage={currentPage} totalPages={totalPages} />
                            <div className='pagination'>
                                <Pagination
                                    color='light'
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{
                                                next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                                previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                            }}
                                            {...item}
                                            sx={{ color: "white" }}
                                        />
                                    )}
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(e, v) => setCurrentPage(v)}
                                />
                            </div>

                        </>
                    )
                )}
            </div>
        </Layout>
    )
}

export default Index