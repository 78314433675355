import React, { useContext, useRef } from 'react'
import Layout from '../../../Layouts/Layout'
import './JobApplicationDetails.scss'
import { useParams } from 'react-router-dom'
import SearchJobApplicationReq from '../../../Requests/Users/SearchJobApplicationReq'
import UsersApi from '../../../API/UsersApi'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ContactButtons from '../../../Services/ContactButtons'
import { UserContext } from '../../../App'
import EditJobApplicationReq from '../../../Requests/Users/EditJobApplication'
import { IoArrowBack } from 'react-icons/io5'
const Index = () => {

    const user = useContext(UserContext)
    const { id } = useParams();
    const [jobApplication, setJobApplication] = useState({})
    const navigate = useNavigate()
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('Pending');
    const contactButtons = new ContactButtons(user);
    const initialRender = useRef(true);
    const saveText = useRef(false);
    const getJobApplication = async () => {
        const req = new SearchJobApplicationReq();
        req.id = id;
        const res = await UsersApi.SearchJobApplication(req);
        if (res?.status?.success) {
            console.log(res);
            setJobApplication(res?.data?.list[0]);
            setNotes(res?.data?.list[0].notes);
            setStatus(res?.data?.list[0].status);
        }
    }

    const editApplication = async () => {
        const req = new EditJobApplicationReq();
        req.id = id;
        req.notes = notes;
        const res = await UsersApi.EditJobApplication(req);
    }

    const editStatus = async () => {
        const req = new EditJobApplicationReq();
        req.id = id;
        req.status = status;
        const res = await UsersApi.EditJobApplication(req);
    }

    useEffect(() => {
        editStatus();
    }, [status])

    useEffect(
        () => {
            if (initialRender.current) {
                initialRender.current = false;
            }
            else {
                if (saveText.current) {
                    const timer = setTimeout(() => {
                        editApplication();
                    }, 1000);

                    return () => clearTimeout(timer);
                }
            }

        }, [notes]
    )


    useEffect(() => {
        if (id) {
            getJobApplication();
        }
        else {
            navigate('/manage-job-applications')
        }
    }, [])


    return (
        <Layout>
            <IoArrowBack color='black' size={30} style={{ cursor: 'pointer', marginLeft: "1rem", marginBottom: "1rem" }} onClick={() => navigate(-1)} />
            <div className='job_applicaotion_details'>
                <h1>Job Application Details</h1>
                <div className='details'>
                    <div className='detail'>
                        <p>First Name</p>
                        <p>{jobApplication.firstName}</p>
                    </div>
                    <div className='detail'>
                        <p>Last Name</p>
                        <p>{jobApplication.lastName}</p>
                    </div>
                    <div className='detail'>
                        <p>Email</p>
                        <p className='blue_text' onClick={() => {
                            contactButtons.email(jobApplication.email)
                        }}>{jobApplication.email}</p>
                    </div>
                    <div className='detail'>
                        <p>Phone</p>
                        <p className='blue_text' onClick={() => {
                            contactButtons.call(jobApplication.phone)
                        }}>{jobApplication.phone}</p>
                    </div>
                    <div className='detail'>
                        <p>Address</p>
                        <p>{jobApplication.address}</p>
                    </div>
                    <div className='detail'>
                        <p>Message</p>
                        <p>{jobApplication.message}</p>
                    </div>
                    <div className='detail'>
                        <p>Notes</p>
                        <textarea value={notes} rows={4} onChange={(e) => { setNotes(e.target.value); saveText.current = true }}></textarea>
                    </div>
                    <div className='detail'>
                        <p>Status</p>
                        <select onChange={
                            (e) => {
                                setStatus(e.target.value);
                            }
                        }
                        >
                            <option value='Application Received' selected={jobApplication.status === 'applicationReceived'}>Application Received</option>
                            <option value='Applicant Rated' selected={jobApplication.status === 'applicantRated'}>Applicant Rated</option>
                            <option value='Interview Scheduled' selected={jobApplication.status === 'interviewScheduled'}>Interview Scheduled</option>
                            <option value='Offer Extended' selected={jobApplication.status === 'offerExtended'}>Offer Extended</option>
                            <option value='Applicant Hired' selected={jobApplication.status === 'applicantHired'}>Applicant Hired</option>
                            <option value='Future Consideration' selected={jobApplication.status === 'futureConsideration'}>Future Consideration</option>
                            <option value='rejected' selected={jobApplication.status === 'rejected'}>Rejected</option>

                        </select>
                        {/* <p>{jobApplication.status}</p> */}
                    </div>
                </div>
                <div className='cv'>
                    <h2>CV</h2>
                    <a href={jobApplication.file && jobApplication.file.url} target='_blank'>Download CV</a>
                    {
                        jobApplication && jobApplication.file && jobApplication.file.url &&
                        <iframe src={jobApplication.file.url} title='CV'></iframe>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Index