/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Modal, Pagination } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ShopItemsApi from '../../../API/ShopItemsApi'
import DeleteShopItem from '../../../Requests/ShopItems/DeleteShopItem'
import EditShopItemReq from '../../../Requests/ShopItems/EditShopItemReq'
import SearchShopItem from '../../../Requests/ShopItems/SearchShopItem'
import Datatable from '../../../components/Datatable'
import SoftInput from '../../../components/SoftInput'

import { deleteObject, getStorage, ref } from 'firebase/storage'
// import Sidebar from '../../../Layouts/Sidebar'
import RemoveFileReq from '../../../Requests/CloudFile/RemoveFileReq'
import Button from '../../../components/Button'
import './index.scss'
// import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
const headers = [
  {
    key: "id",
    name: "ID"
  },
  {
    key: "name",
    name: "Name"
  },
  {
    key: "description",
    name: "Description"
  },
  {
    key: "price",
    name: "Price"
  },
  {
    key: "files",
    name: "Files"
  },
  {
    key: "edit",
    name: "Edit"
  },
  {
    key: "delete",
    name: "Delete"
  }
]

const Index = () => {
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [editName, setEditName] = useState(null);
  const [editDescription, setEditDescription] = useState(null);
  const [editPrice, setEditPrice] = useState(null);
  const [editId, setEditId] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const storage = getStorage();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  function handleClose() {
    setOpen(false)
  }

  function deleteShopItems(shopItem) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',

    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          {
            title: "Deleting",
            text: "Please wait",
            allowOutsideClick: false,
            icon: "info",
            didOpen: () => {
              Swal.showLoading()
            }
          }
        )

        if (shopItem.files.length > 0) {
          const deleteFiles = async () => {
            for (const f of shopItem.files) {
              const fileRef = ref(storage, f.path);
              try {
                await deleteObject(fileRef);
                const req = new RemoveFileReq();
                req.int = f.id;
                const res = await ShopItemsApi.RemoveFile(req);
                if (res?.status?.success) {
                  
                }
              } catch (error) {
                console.error('An error occurred!', error);
              }
            }
          };
          deleteFiles().then(() => {
            let req = new DeleteShopItem();
            req.int = shopItem.id;
            req.string = name;
            ShopItemsApi.DeleteShopItems(req).then(
              (response) => {
                
                if (response.status.success) {

                  
                  Swal.fire(
                    {
                      title: 'Deleted!',
                      text: 'The Selling Card has been deleted.',
                      icon: 'success',
                      didOpen: () => {
                        Swal.hideLoading()
                      }
                    }
                  );

                  setReload(!reload);
                }
                else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.status.message,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                      Swal.hideLoading()
                    }
                  });
                }
              }
            );
            fetchData()
          });
        }
        else {
          let req = new DeleteShopItem();
          req.int = shopItem.id;
          req.string = name;
          ShopItemsApi.DeleteShopItems(req).then(
            (response) => {
              
              if (response.status.success) {
                
                Swal.fire(
                  {
                    title: 'Deleted!',
                    text: 'The Selling Card has been deleted.',
                    icon: 'success',
                    didOpen: () => {
                      Swal.hideLoading()
                    }
                  }
                );

                setReload(!reload);
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: response.status.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: true,
                  didOpen: () => {
                    Swal.hideLoading()
                  }
                });
              }
            }
          );
        }

      }
    })
  }

  function editClicked(shopItems) {
    navigate("/manageShopItems/details", {
      state: {
        name: shopItems.name,
        description: shopItems.description,
        id: shopItems.id,
        files: shopItems.files,
        price: shopItems.price
      }
    })
    setEditId(shopItems.id)
    setEditName(shopItems.name)
    setEditDescription(shopItems.description)
    setEditPrice(shopItems.price)
    setOpen(true);
  }
  function editShopItem() {
    setOpen(false)
    Swal.fire({
      icon: 'info',
      title: 'Editing Selling Card',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    if (!editName) {
      Swal.fire({
        icon: 'error',
        title: 'Name is required',
        text: 'Please enter Name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (!editDescription) {
      Swal.fire({
        icon: 'error',
        title: 'description is required',
        text: 'Please enter description',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    if (!editPrice) {
      Swal.fire({
        icon: 'error',
        title: 'Price is required',
        text: 'Please enter Price',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (isNaN(editPrice) || editPrice <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Price',
        text: 'Please enter a valid Price',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    const req = new EditShopItemReq();
    req.id = editId;
    req.name = editName;
    req.description = description;
    req.price = price;
    ShopItemsApi.EditShopItem(req).then(
      (res) => {
        
        if (res?.status?.success) {
          Swal.fire({
            icon: 'success',
            title: 'Shop Item Edited',
            text: "Shop Item has been Edited",
            // allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,

          });

          fetchData()
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res?.status?.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
        }

      }
    )
  }

  const fetchData = () => {
    setLoading(true)
    const req = new SearchShopItem();
    if (name)
      req.name = name;
    if (description)
      req.description = description;
    if (price)
      req.price = price;
    req.pagingParams.pageNumber = currentPage;
    req.pagingParams.pageSize = 10;

    ShopItemsApi.SearchShopItems(req).then(
      (res) => {
        
        if (res?.status?.success) {
          const list = [];
          for (let index = 0; index < res?.data?.list.length; index++) {
            list.push(res?.data?.list[index]);
            list[index].edit = <button sx={{
              color: "white !important",
              backgroundColor: "blue",
              '&:hover': {
                backgroundColor: 'blue',
              },
            }} onClick={() => { editClicked(res?.data?.list[index]) }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1286 3.15961C19.566 2.5972 18.8031 2.28125 18.0076 2.28125C17.2121 2.28125 16.4492 2.5972 15.8866 3.15961L15.1796 3.86761L20.1296 8.81761L20.8356 8.11061C21.1142 7.83203 21.3352 7.50129 21.486 7.13728C21.6368 6.77327 21.7144 6.38312 21.7144 5.98911C21.7144 5.5951 21.6368 5.20495 21.486 4.84094C21.3352 4.47693 21.1142 4.14619 20.8356 3.86761L20.1286 3.15961ZM18.7146 10.2326L13.7646 5.28261L4.67458 14.3736C4.47567 14.5726 4.33671 14.8234 4.27358 15.0976L3.24458 19.5526C3.20624 19.718 3.21064 19.8904 3.25736 20.0536C3.30409 20.2169 3.39159 20.3655 3.51164 20.4855C3.63169 20.6056 3.78033 20.6931 3.94355 20.7398C4.10677 20.7866 4.2792 20.791 4.44458 20.7526L8.90058 19.7246C9.17439 19.6613 9.42491 19.5224 9.62358 19.3236L18.7146 10.2326Z" fill="#71839B" />
              </svg>
            </button>;
            list[index].delete = <button sx={{
              '&:hover': {
                backgroundColor: 'red',
              },
              backgroundColor: "red",
              color: "white !important",

            }} onClick={() => deleteShopItems(list[index])} color="error"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                <path d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V5H1V16Z" fill="#FF472E" />
              </svg></button>

          }
          
          setList(list)
          setTotalPages(res.data.totalPages)
          setLoading(false)

        }
      }
    )
  }

  useEffect(
    () => {
      fetchData();
    }, [currentPage, reload]
  )

  return (
    <Layout>
      <div className='manageShopItemsContainer' >
        <div className='searchFilters' >
          <h1  >Manage Shop Items</h1>
          <div className='searchFiltersInput' >
            <input placeholder="Enter the Name" value={name} onChange={(e) => setName(e.target.value)} />
            <input placeholder="Enter the Description" value={description} onChange={(e) => setDescription(e.target.value)} />
            <input placeholder="Enter the Price" value={price} onChange={(e) => setPrice(e.target.value)} />
          </div>

          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem"
              }}>
                <h1>Edit Selling Card</h1>
                <SoftInput value={editName} onChange={(e) => { setEditName(e.target.value) }} />
                <SoftInput value={editDescription} onChange={(e) => { setEditDescription(e.target.value) }} />
                <SoftInput value={editPrice} onChange={(e) => { setEditPrice(e.target.value) }} />
                <Button onClick={editShopItem} >Edit</Button>
              </div>
            </Box>
          </Modal>

          <button onClick={() => { setCurrentPage(1); fetchData() }}  >{"SEARCH"}</button>
        </div>
        {loading ? <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }} ><CircularProgress sx={{color:"var(--color-icon)"}} size={50} /></div> :

          <Datatable headers={headers} list={list} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        }
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem " }}>
          <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} onChange={(e, pageNo) => {
            setCurrentPage(pageNo)
          }} />
        </div>
      </div>
    </Layout>
  )
}

export default Index