/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PublicLayout from './../../../Layouts/PublicLayout'
import { IoIosCheckmarkCircle } from "react-icons/io";
import './Home.scss'
import flag from './images/flag.png'
import home_banner from './images/home_banner.png'
import everyone from './images/everyone.png'
import map from './images/map.png'
import users from './images/users.png'
import right_bot from './images/rightbot.png'
import left_bot from './images/leftbot.png'
import ReviewContainer from './components/ReviewContainer';
// import welink from './images/welink.png'
import fire from './images/fire.png'
// import fidium from './images/fidium.png'
import refer_right from './images/refer_right.png'
import refer_left from './images/refer_left.png'
// import brightspeed from './images/brightspeed.png'
// import fidium_mini from './images/fidium_mini.png'
// import { FaCaretRight } from "react-icons/fa6";
// import welink_black from './images/welink_black.png'
// import AsyncSelect from 'react-select/async';
import {  useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import package_light from '../OurServices/images/package_light.png'
import giftcard from '../ReferralProgram/images/giftcard.png'
const searchLocation = async (address) => {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;

    try {
        const response = await fetch(geocodingUrl);
        const data = await response.json();

        if (response.status === 200 && data.status === "OK") {
            return data;
        } else {
            // console.log(data.error_message)
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

const Index = () => {
    const [selectedTab, setSelectedTab] = React.useState(2);
    // const allPlansInclude = ['Free Installation', 'Wifi Equipment Included', 'Unlimited Data', 'No Contracts', 'No Hidden Fees'];
    const [search, setSearch] = React.useState("")
    // const navigate = useNavigate()
    const location = useLocation()
    const [locationOptions, setLocationOptions] = React.useState([])

    // check availability states
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [address, setAddress] = React.useState(null);
    const [cellphone, setCellphone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [consentChecked, setConsentChecked] = React.useState(false)
    const services = ['Free Installation', 'Unlimited - No Data Caps', 'No Contracts - Month to Month', '30 Day - Risk Free Trial']
    const checkAvailability = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Submitting Check Availability Request",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!firstName) {
            Swal.fire({
                icon: "error",
                title: "First Name is Required",
                text: "Please enter your first name",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lastName) {
            Swal.fire({
                icon: "error",
                title: "Last Name is Required",
                text: "Please enter your last name",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if (!address?.value && search) {
            setAddress({ value: search })
        }
        if (!address?.value) {
            Swal.fire({
                icon: "error",
                title: "Address is Required",
                text: "Please enter your address",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!cellphone) {
            Swal.fire({
                icon: "error",
                title: "Cellphone is Required",
                text: "Please enter your cellphone",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (cellphone.length !== 10) {
            Swal.fire({
                icon: "error",
                title: "Invalid Cellphone",
                text: "Please enter a valid cellphone number",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!email) {
            Swal.fire({
                icon: "error",
                title: "Email is Required",
                text: "Please enter your email",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        var pattern = new RegExp(/^\S+@\S+\.\S+$/);
        if (!pattern.test(email)) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Email',
            text: 'Please enter a valid email address',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
          return;
        }

        if (!consentChecked) {
            Swal.fire({
                icon: "error",
                title: "Consent is Required",
                text: "Please agree to the terms and conditions",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        // Swal.fire({
        //     icon:"success",
        //     title:"Check Availability Request Submitted",
        //     text:"Our team will contact you soon",
        //     didOpen:()=>{
        //         Swal.hideLoading()
        //     }
        // })
        const req = new FormData();
        req.append("firstName", firstName)
        req.append("lastName", lastName)
        if (address.value) {
            req.append("address", address.value)
        }
        else {
            req.append("address", search)
        }
        req.append("phone", cellphone)
        req.append("email", email)
        req.append('availabilityType', selectedTab === 1 ? "Business" : "Home")
        req.append('consentChecked', consentChecked)
        req.append('notes', "123")
        if (address.coordinates) {
            req.append('coordinates.latitude', address.coordinates.latitude)
            req.append('coordinates.longitude', address.coordinates.longitude)
        }
        else {
            const result = await searchLocation(search)
            if (result.results.length > 0) {
                req.append('coordinates.latitude', result.results[0].geometry.location.lat)
                req.append('coordinates.longitude', result.results[0].geometry.location.lng)
            }
        }
        setFirstName("")
        setLastName("")
        setAddress(null)
        setCellphone("")
        setEmail("")
        setConsentChecked(false)

        const res = await CustomerApi.CreateAvailabilityCheck(req)
        if (res?.status?.success) {

            Swal.fire({
                icon: "success",
                title: "Check Availability Request Submitted",
                text: "Our team will contact you soon",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }


    async function getLocation() {
        if (search === "") {
            return
        }

        const data = await searchLocation(search)
        // console.log(data)
        return data?.results?.map((item) => {
            return { value: item.formatted_address, label: item.formatted_address, coordinates: { latitude: item.geometry.location.lat, longitude: item.geometry.location.lng } }
        })
    }

    useEffect(() => {
        if (location?.state?.redirect) {
            const element = document.getElementById(location.state.redirect);
            element.scrollIntoView({ behavior: "instant" });
        }
    }, [])

    
    async function getOptions() {
        const options = await getLocation()
        // console.log(options)
        setLocationOptions(options)
    }

    useEffect(
        () => {
            const timer = setTimeout(getOptions, 1000);
            return () => clearTimeout(timer);
        }, [search]
    )
    return (
        <PublicLayout>
            <div className='home_container'>
                <div style={{
                    backgroundImage: `url(${flag})`
                }} className='home_banner'>
                    <div className='text_left'>
                        <h1>Connecting<br /> America's New<br /> Fiber Internet<br /> Grid!</h1>
                        <img src={home_banner} className='home_banner_image' alt='home_banner' />
                        <h3>Get On<br />The Free Install <br />Map Today!</h3>
                        <a href="#check_availability" >Get Started</a>
                    </div>

                </div>
                <div className='everyone_joining'>
                    <h1>Join Lightning OS In America's<br /> Fastest Growing Fiber Network</h1>
                    <img src={everyone} alt='everyone' />
                </div>
                <div style={{
                    backgroundImage: `url(${map})`
                }} id="check_availability" className='availability_form'>
                    <div className='form'>
                        <h1>Check Availability</h1>
                        <div className='tabs'>
                            <button onClick={() => { setSelectedTab(2) }} className={`${selectedTab === 2 && 'active'}`}>FOR HOME</button>
                            <button onClick={() => { setSelectedTab(1) }} className={`${selectedTab === 1 && 'active'}`}>FOR BUSINESS</button>
                        </div>
                        <div className='input_container'>
                            <input value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type='text' placeholder='First Name' />
                            <input value={lastName} onChange={(e) => { setLastName(e.target.value) }} type='text' placeholder='Last Name' />
                            {/* <AsyncSelect
                                placeholder='Service Address'
                                loadOptions={getLocation}
                                defaultOptions
                                inputValue={search} // Add this line
                                value={address} // Add this line
                                onInputChange={(value)=>{
                                    setSearch(value)
                                    //setAddress({value:value})

                                }}
                                onChange={(e) => { setAddress(e);setSearch(e.value) }}
                                noOptionsMessage={() => null} // Add this line
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        outline: 'none',
                                        boxShadow: 'none',
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        textAlign: 'center',
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        textAlign: 'center',
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        textAlign: 'center',
                                        padding: "0.5rem 1rem",
                                        margin: "auto"
                                    }),
                                }}
                            /> */}
                            <div className='custom_address_selector'>
                                <input type='text' value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='Service Address' />
                                {locationOptions && locationOptions.length > 0 &&
                                    <div className='location_options'>
                                        {
                                            locationOptions?.map((option, index) => {
                                                return (
                                                    <div key={index} onClick={() => { setAddress(option); setSearch(option.value); setLocationOptions([]) }} className='option'>
                                                        <p>{option.label}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>}
                            </div>
                            <input value={cellphone} maxLength={10} onChange={(e) => { setCellphone(e.target.value) }} type='text' placeholder='Cellphone' />
                            <input value={email} onChange={(e) => { setEmail(e.target.value) }} type='text' placeholder='Email' />
                            <div className='consent'>
                                <input value={consentChecked} onChange={(e) => { setConsentChecked(e.target.checked) }} type='checkbox' id='consent' />
                                <label htmlFor='consent'>By signing up, you agree to receive emails and text messages from Lightning OS, in regards to our offers.</label>
                            </div>
                            <div>
                                <p><span style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem'
                                }}>*</span> Offer may vary depending on your location.</p>
                            </div>
                            <button onClick={() => checkAvailability()} >Check Availability</button>
                        </div>
                    </div>
                </div>
                {/* Testimonial Not Added Currently*/}
                {/* <div> */}
                <ReviewContainer />

                {/* <iframe id='reviews_customer' class='lc_reviews_widget' src='https://services.leadconnectorhq.com/reputation/widgets/review_widget/2bpuwFoxpdxZs2Oq2Z8a' frameBorder='0' scrolling='no' style={{ minWidth: "100%", width: "100%", marginTop: "4rem" }}></iframe> */}
                {/* </div> */}
                <div className='users'>
                    <div className='text'>
                        <p>U.S Homes With Active Fiber Connections</p>
                        <h1>30 Million+</h1>
                        <h2>Together We're<br />Transforming The<br />Internet Experience</h2>
                    </div>
                    <img src={users} alt='users' />
                </div>

                {/* <div className='fiber_offers'> */}
                {/* <h1>Fiber Offers</h1>
                    <div className='fiber_offer_top'>
                        <div className='top_card'>
                            <p>1 Gig</p>
                            <h2>$65</h2>
                        </div>
                        <div className='top_card'>
                            <p>2 Gig</p>
                            <h2>$85</h2>
                        </div>
                    </div> */}
                {/* <div className='fiber_offer_bottom'> */}
                {/* <h3>All Plans Include</h3>
                        <div className='offer_item_container'>
                        {
                            allPlansInclude.map((item, index) => {
                                return (
                                    <div className='offer_item' key={index}>
                                        <IoIosCheckmarkCircle size={20} color='20C877' />
                                        <p>{item}</p>
                                    </div>
                                )
                            })
                        }
                        </div> */}

                {/* <p className='list_item'>WiFi gateway for faster speeds and wider coverage</p>
                        <p className='list_item'>Proactive network monitoring</p>
                        <p className='list_item'>Whole-home Attune™ WiFi app</p>

                        <h3>Optional Add-Ons</h3> */}
                {/* <div className='add-on'>
                            {/* <div style={{
                                cursor: 'pointer'
                            }} onClick={()=>{navigate('/services',{
                                state:{
                                    redirect: 'tv_streaming_package',
                                }
                            })}}  >
                                <p>TV STREAMING PACKAGE</p>
                            </div> */}
                {/* <div style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                navigate('/services', {
                                    state: {
                                        redirect: 'phone_service',
                                    }
                                })
                            }} >
                                <p>HOME PHONE SERVICE</p>
                            </div> */}
                {/* </div> 
                    </div> */}


                {/* </div> */}


                <div className='services_banner'>
                    <div className='banner_top'>
                        <img src={package_light} className='package_light' alt='package_light' />
                        <div className={`banner_card`}>
                            <p>Supreme</p>
                            <h2>1 Gig</h2>
                            <h4>$65/mo*</h4>
                        </div>
                        <div className={`banner_card`}>
                            <p>Futuristic</p>
                            <h2>2 Gig</h2>
                            <h4>$85/mo*</h4>
                        </div>
                    </div>
                    <div className='custom_border'>

                    </div>
                    <div className='banner_bottom'>
                        {
                            services.map((service, index) => {
                                return (
                                    <div key={index} className='service_card'>
                                        <IoIosCheckmarkCircle size={20} color='#2761D0' />
                                        <p>{service}</p>
                                    </div>
                                )
                            })
                        }
                        <div className='service_card'>
                            <p style={{
                                fontSize:"1rem"
                            }}><span style={{
                                fontWeight: 'bold',
                                fontSize: '1.2rem'
                            }}>*</span> Offer may vary depending on your location.</p>
                        </div>
                    </div>
                </div>

                <div className='customer_support_container'>
                    <img src={left_bot} className='left_bot' alt='left_bot' />

                    <div className='text'>
                        <p>Customer Support</p>
                        <h1>Meet our<br />Lightning<br />Agents<br />to help<br />you<br />anytime</h1>
                        <button onClick={() => {
                            window.leadConnector.chatWidget.openWidget()
                        }}>Contact Us</button>
                    </div>

                    <img className='right_bot' src={right_bot} alt='right_bot' />
                </div>
                <div className='refer_friend'>
                    <h1>Refer a friend and earn rewards</h1>
                    <img src={refer_left} className='refer_left' alt='refer_left' />
                    <img src={refer_right} className='refer_right' alt='refer_right' />
                    <div className='cards'>
                        {/* <div className='refer_card'>
                            <img src={welink} alt='welink' />
                        </div> */}
                        <div className='refer_card'>
                            <h3>Hot<br />$50</h3>
                            <img src={fire} alt='fire' />
                        </div>
                        <img className='gift_card' style={{
                            height: "200px !important"
                        }} src={giftcard} alt='giftcard' />
                        <div className='refer_card'>
                            <h3>Hot<br />$50</h3>
                            <img src={fire} alt='fire' />
                        </div>
                        {/* <div className='refer_card'>
                            <img src={fidium} alt='fidium' />
                        </div> */}
                    </div>
                </div>
                {/* <div className='lightning_partner'>
                    <h1>Our Lightning Partners</h1>
                    <div className='partners'>
                        <div>
                            <img src={welink_black} alt='welink' />
                        </div>
                        <div>
                            <img src={fidium_mini} alt='fidium_mini' />
                        </div>
                        <div>
                            <img src={brightspeed} alt='brightspeed' />
                        </div>
                    </div>
                    <button>
                        Become Our Partner <FaCaretRight color='white' size={20} />
                    </button>
                </div> */}
            </div>
        </PublicLayout>
    )
}

export default Index