import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CreateFile from "../Requests/CloudFile/CreateFileReq";

const downloadFile = async (file) => {
    try {

        const link = document.createElement('a');
        link.href = file.url;
        link.target = "_blank";
        link.download = file.name; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.error('Error downloading file:', error.message);
    }
};


const getIconForFirebaseFile = (file) => {
    if (file.fileType.includes('image')) {
        return <img src={file.url} width={300} />
    }
    else if (file.fileType.includes('video')) {
        return <video controls src={file.url+"#t=0.001"} ></video>
    }
    else {
        return <div style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gap: "0.5rem",
            border: "1px solid #ccc",
            padding: "0.5rem",
            borderRadius: "10px",
            cursor: "pointer"
        }} onClick={() => {
            downloadFile(file)
        }}>
            <span className={`fiv-cla fiv-icon-${file.extension}`} ></span>
            <p style={{
                // textAlign: "center",
                fontSize: "0.8rem",
                color: "blue",
                marginTop: "0.5rem"
            }} >{file.name}</p>
        </div>
    }
}


const getIconForUploadedFile = (file) => {
    if (file.type.includes('image')) {
        return <img style={{
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }} src={URL.createObjectURL(file)} alt="" />
    }
    else if (file.type.includes('video')) {
        return <video style={{
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }} src={URL.createObjectURL(file)} controls />
    }
    else {
        let extension = file.name.split('.').pop()
        return <div className='icon' style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <span className={`fiv-cla fiv-icon-${extension}`} ></span>
            <p style={{
                textAlign: "center",
                fontSize: "0.8rem",
                color: "blue",
                marginTop: "0.5rem"
            }} >{file.name}</p>
        </div>
    }
}

const uploadFileToFirebase = async (file, path) => {

    const req = new CreateFile();
    req.name = file.name;
    req.extension = file.name.split('.').pop();
    req.caption = "Speed Test Before Image";
    req.fileSize = file.size.toString();
    req.fileType = file.type;
    const storage = getStorage();
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    req.url = url;
    req.path = path;
    return req;
}

export { getIconForFirebaseFile, getIconForUploadedFile, uploadFileToFirebase }