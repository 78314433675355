class GetCompanyConsultationsReq{
    constructor(){
        this.statuses = [];
        this.industries = [];
        this.pagingParams = {
            pageNumber: 1,
            pageSize: 30
        };
    }
}
export default GetCompanyConsultationsReq;