import React, { useContext, useEffect } from 'react'
import Modal from './../../../../components/Modal'
import Swal from 'sweetalert2'
import { useState } from 'react'
import LinkUserEmailAndPassword from '../../../../Requests/Users/LinkUserEmailAndPasswordReq'
import UsersApi from '../../../../API/UsersApi'
import { UserContext } from '../../../../App'
import LinkUserPhoneNumberReq from '../../../../Requests/Users/LinkUserPhoneNumberReq'
import { MdEdit, MdSave } from 'react-icons/md'
import PhoneInput from 'react-phone-input-2'
import { VscMute, VscUnmute } from 'react-icons/vsc'

const PrivacySettings = () => {
    
    
    const user = useContext(UserContext)
    console.log(user)
    const [open, setOpen] = useState(false)
    const [phone, setPhone] = useState(user.phoneNumber)
    const [editPhoneMode, setEditPhoneMode] = useState(false)
    const [email, setEmail] = useState(user.email)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [volumeEnabled, setVolumeEnabled] = useState(localStorage.getItem('volumeEnabled') === 'false' ? false : true)
    async function updateEmailAndPasword(){
        Swal.fire({
            icon: 'info',
            title: 'Updating Email and Password',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if(!email){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Email can't be empty",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if(!password){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Password can't be empty",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if(password !== confirmPassword){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Passwords don't match",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        const req = new LinkUserEmailAndPassword();
        req.userId = user.id;
        req.email = email;
        req.password = password;
        const res = await UsersApi.LinkUserEmailAndPassword(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Email and Password Updated',
                text: "Your email and password has been updated",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            setOpen(false)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                customClass: {
                    container: 'custom-swal'
                },
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }
    }

    
    const savePhone = async () => {

        if(phone[0] !== "+"){
            setPhone("+"+phone)
        }


        if (user.phoneLinked && phone === user.phoneNumber) {
            setEditPhoneMode(false)
            return;
        }
        Swal.fire({
            icon: 'info',
            title: 'Updating Phone Number',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if(!phone){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Phone number can't be empty",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }
        const req = new LinkUserPhoneNumberReq();
        req.userId = user.id;
        req.phone = phone;
        const res = await UsersApi.LinkUserPhoneNumber(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Phone Number Updated',
                text: "Your Phone Number has been updated",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            setEditPhoneMode(false)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
            });
            setPhone(user.phoneLinked?user.phoneNumber:"")
        }
    }

    useEffect(() => {
        if(volumeEnabled){
            localStorage.setItem('volumeEnabled', 'true')
        }
        else{
            localStorage.setItem('volumeEnabled', 'false')
        }
    }, [volumeEnabled])

    return (
        <div className='UserDataSettings'>
            <Modal open={open} setOpen={setOpen}>
                <div className='update_email_form'>
                    <h2>Update Email And Password</h2>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                    <button onClick={updateEmailAndPasword}>Update</button>
                </div>
            </Modal>
            <div className='edit_buttons'>
                {
                    editMode ?
                        null :
                        <button onClick={() => setEditMode(true)}>Edit</button>
                }
                {
                    editMode ? <button onClick={() => {
                        setPhone(user.phoneNumber)
                        setEditMode(false)
                    }} className='cancel'>Cancel</button> : null
                }
                {
                    editMode ? <button onClick={() => {savePhone()}}>Save</button> : null
                }
            </div>
            <div className='userDataBody'>
                <h3>Phone:</h3>
                <div className='hello'>
                    {
                        editMode ? 
                        <PhoneInput country={'us'} value={phone} onChange={(e) => setPhone(e)} />
                        // <input type="text" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} /> 
                        : <p>{phone}</p>
                    }
                
                </div>
                <h3>Update Email And Password:</h3>
                <button onClick={() => { setOpen(true) }} className='changePassword'>Update</button>
                <h3>Notification Sound:</h3>
                {
                    volumeEnabled?
                    <div className='sound_icon' onClick={()=>{setVolumeEnabled(false)}}>
                        <VscUnmute color='white' size={30} />
                        <p>Sound Enabled</p>
                    </div>
                    :
                    <div className='sound_icon' onClick={()=>{setVolumeEnabled(true)}}>
                        <VscMute color='white' size={30} />
                        <p>Sound Disabled</p>
                    </div>
                }
            </div>

            {/* <p className='privacyHeading' ></p> */}

        </div>
    )
}

export default PrivacySettings