import React, { useContext, useEffect, useState } from 'react';
import './AnnouncementBanner.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import StringIntReq from '../../Requests/StringIntReq';
import UsersApi from '../../API/UsersApi';
import { UserContext } from '../../App';
import { RiAttachmentFill } from 'react-icons/ri';
import { Modal } from '@mui/material';

const AnnouncementBanner = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mediaUrl, setMediaUrl] = useState('');
    const [mediaType, setMediaType] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const user = useContext(UserContext);


    const getDaysAgo = (startDate) => {
        const today = new Date();
        const date = new Date(startDate);
        const diffInTime = today.getTime() - date.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        if (diffInDays === 0) return 'Today';
        if (diffInDays === 1) return 'Yesterday';
        return `${diffInDays}d ago`;
    };


    const isAnnouncementValid = (startDate, endDate) => {
        const today = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Check if the current date is between start date and end date
        return today >= start && today <= end;
    };


    const getBorderColor = (type) => {
        switch (type) {
            case 'Bug Fix':
                return '#ff0000';
            case 'Feature':
                return '#4caf50';
            case 'Announcement':
                return '#2196f3';
            case 'Update':
                return '#ff0000';
            default:
                return '#000';
        }
    };


    const getAnnouncements = async () => {
        setLoading(true);
        let req = new StringIntReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 50;
        if(user.userType === "SalesOrgAdmin")
        {
            req.salesOrgId = user.salesOrgId
            req.userType = "SalesOrgAdmin"
        }
        if(user.userType === "SalesRep")
        {
            req.salesRepId = user.id
            req.salesOrgId = user.salesOrgId
            req.userType = "SalesRep"
        }
        const res = await UsersApi.GetAllAnnouncements(req);
        if (res?.status?.success) {
            const validAnnouncements = res.data.list
                .filter(announcement => 
                    isAnnouncementValid(announcement.startDate, announcement.endDate) && 
                    announcement.isHidden === false
                )
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                console.log(validAnnouncements)
            setAnnouncements(validAnnouncements);
            setLoading(false);
        } else {
            console.log(res?.status?.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnnouncements();
    }, []);


    const handleOpen = (url, type) => {
        if(url && type)
        {

            setMediaUrl(url)
            setMediaType(type)
            setOpenModal(true)
        }
    }
    
    const handleClose = () => {
        setOpenModal(false)
        setMediaType('')
        setMediaUrl('')
    }
    return (
        <div className="announcement-banner-container">
          {announcements.map((announcement) => (
            <div
              key={announcement.id}
              className="announcement-banner"
              style={{ borderLeftColor: getBorderColor(announcement.announcementType) }}
            >
              {/* Title in Row 1, Column 1 */}
              <Typography className="announcement-title">
                {announcement.title}
                {(announcement.announcementImageId || announcement.announcementVideoId) && (
                  <RiAttachmentFill size={20} style={{ marginLeft: "1rem" }} />
                )}
              </Typography>
      
              {/* Announcement Type in Row 1, Column 2 */}
              <Typography className="announcement-type">
                {announcement.announcementType}
              </Typography>
      
              {/* Message in Row 2, Column 1 */}
              <Typography className="announcement-message">
                {announcement.message}
              </Typography>
      
              {/* Announcement Date in Row 2, Column 2 */}
              <Typography className="announcement-date">
                {getDaysAgo(announcement.startDate)}
              </Typography>
            </div>
          ))}
          
          {/* Modal for Media (Image/Video) */}
          <Modal className="modal-wrapper" open={openModal} onClose={handleClose}>
            <div className="modal-content">
              {mediaType === "image" ? (
                <img src={mediaUrl} alt="Announcement Media" style={{ maxWidth: "100%" }} />
              ) : (
                <video controls style={{ maxWidth: "100%" }}>
                  <source src={mediaUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </Modal>
        </div>
      );
      
};

export default AnnouncementBanner;
