class CreateCandidateLeadReq {
    constructor() {
        this.name = null;
        this.email = null;
        this.phone = null;
        this.status = null;
        this.notes = null;
        this.mapString = null;
        this.zipCode = null;
        this.state = null;
        this.city = null;
        this.country = null;
        this.address = null;
        this.mapIconId = null;
        this.coordinate = {
            latitude: null,
            longitude: null,
        };
    }
}

export default CreateCandidateLeadReq;