class SearchSalesOrgLeaderboardReq {
  constructor() {
    this.salesOrgId = null;
    this.salesRepId = null;
    this.from = null;
    this.to = null;
    this.pagingParams = {
        pageNumber: 1,
        pageSize: 20
    }
  }
}

export default SearchSalesOrgLeaderboardReq;