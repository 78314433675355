import { CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import React, { useEffect } from 'react'
import Datatable from '../../../components/Datatable'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
import ComissionApi from '../../../API/ComissionApi'

import SearchAccoutingEntriesReq from '../../../Requests/ComissionPlan/SearchAccoutingEntriesReq'
const Index = () => {

    const [name, setName] = React.useState(null)
    const [description, setDescription] = React.useState(null)

    const [amountFrom, setAmountFrom] = React.useState(null)
    const [amountTo, setAmountTo] = React.useState(null)
    const [accountingEntries, setAccoutingEntries] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(1)



    const searchAccoutingEntries = () => {
        setLoading(true)
        const req = new SearchAccoutingEntriesReq()
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = 20
        req.name = name
        req.description = description
        req.amountFrom = amountFrom
        req.amountTo = amountTo

        ComissionApi.SearchAccountingEntries(req).then(
            (res) => {
                
                console.log(res)
                setTotalPages(res?.data?.totalPages)
                setAccoutingEntries(res?.data?.list)
                setLoading(false)
            }
        )
    }




    const headers = [
        {
            name: "Debt Entry ID",
            key: "id"
        },
        {
            name: "Name",
            key: "name"
        },
        {
            name: "Description",
            key: "description"
        },
        {
            name: "Amount",
            key: "amount"
        },
        {
            name: "Type",
            key: "type"
        },
    ]

    useEffect(
        () => {
            searchAccoutingEntries()
        }, [currentPage]
    )

    return (
        <Layout>
            <div className='manageLeadsContainer' >
                <div className='manageLeadsHeader'>
                    <h1 className='manageLeadsTitle'>Manage Accounting Entries</h1>
                    <div className='searchFiltersInput' >
                        <input value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Name' />
                        <input value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder='Description' />
                        <input value={amountFrom} onChange={(e) => { setAmountFrom(e.target.value) }} placeholder='Amount From' />
                        <input value={amountTo} onChange={(e) => { setAmountTo(e.target.value) }} placeholder='Amount To' />
                    </div>
                    <button onClick={searchAccoutingEntries} >Search</button>
                </div>
                {
                    loading ?
                        <div className='spinner'>
                            <CircularProgress sx={{color:"var(--color-icon)"}} />
                        </div>
                        :
                        <>
                            <Datatable headers={headers} list={accountingEntries} currentPage={currentPage} totalPages={totalPages} />
                        </>
                }
                <div className='pagination'>
                    <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                </div>
            </div>
        </Layout>
    )
}

export default Index