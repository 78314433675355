
class SearchFiberCommissionPlanReq {
    constructor() {
        this.commissionId = null;
        this.pagingParams = {
            pageNumber: null,
            pageSize: null
        };
    }
}

export default SearchFiberCommissionPlanReq;