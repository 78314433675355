class CreateConversationReq {
    constructor() {

        this.name = null;
        this.creatorId = null;
        this.conversationType = null;
        this.salesOrgId = null;
        this.userIds = [];
    }
}

export default CreateConversationReq