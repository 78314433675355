import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Typography,
    IconButton,
    Button,
    Checkbox,
    FormControlLabel,
    Tooltip,
    Divider,
    Modal
} from '@mui/material';
import {
    ContentCopy,
    EmailOutlined,
    PhoneOutlined,
    BusinessOutlined,
    WorkOutlined,
    LinkedIn,
    PersonOutline,
    CheckCircle,
    Cancel,
    SendOutlined,
    DoorFront,
    HistoryOutlined
} from '@mui/icons-material';
import { MdDeleteOutline } from 'react-icons/md';
import { IoCloseCircle } from 'react-icons/io5';
import Call from '../../components/NewLeadCard/Images/Call.png';
import Email from '../../components/NewLeadCard/Images/Email.png';
import Message from '../../components/NewLeadCard/Images/Message.png';
import Directions from '../../components/NewLeadCard/Images/Directions.png';
import wa from '../../components/NewLeadCard/Images/whatsapp.svg';
import './CandidateMainLeadCard.scss';
import MapIconDisplay from '../MapIconDisplay';
import { UserContext } from '../../../../../App';
import { MapContext } from '../..';
import InvitationModal from './InviteForm';
import FiberHouseApi from '../../../../../API/FiberHouseApi';
import StringIntReq from '../../../../../Requests/StringIntReq';
import UpdateCandidateAppointmentReq from '../../../../../Requests/FiberPlan/UpdateCandidateAppointmentReq';
import EditCandidateReq from '../../../../../Requests/FiberHouse/EditCandidateReq';
import Swal from 'sweetalert2';
import VersatileLoader from '../../../../../components/VersatileLoader';
import { FaHireAHelper, FaHistory } from 'react-icons/fa';
import ContactButtons from '../../../../../Services/ContactButtons';
import CandidateJobApplicationReq from '../../../../../Requests/Users/CandidateJobApplicationReq';
import CalendlyUserSelection from '../../../../SuperAdmin/CalendlyUserSelection';
import UsersApi from '../../../../../API/UsersApi';

const CandidateMainLeadCard = ({ selectedCandidate }) => {
    const [notes, setNotes] = useState(selectedCandidate?.notes);
    const [mapIcon, setMapIcon] = useState(null);
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [candidateDetails, setCandidateDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [email, setEmail] = useState(selectedCandidate?.personalEmail);
    const [phone, setPhone] = useState(selectedCandidate?.phone);
    const [name, setName] = useState(selectedCandidate?.name);
    const shouldSaveAddress = useRef(false);
    const shouldSaveNotes = useRef(false);
    const initialRender = useRef(true);
    const [address, setAddress] = useState('');

    const [industry, setIndustry] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [linkedinUrl, setLinkedinUrl] = useState(null);
    const [positionTitle, setPositionTitle] = useState(null);
    const [companyEmail, setCompanyEmail] = useState(null);
    const [doorToDoor, setDoorToDoor] = useState(null);
    const [statusAttachment, setStatusAttachment] = useState(null);

    const User = useContext(UserContext);
    const { mapIcons, setIndex } = useContext(MapContext);
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const getCandidateLeadDetails = async (id) => {
        const req = new StringIntReq();
        req.int = id;
        const res = await FiberHouseApi.GetCandidateLeadDetails(req);
        if (res?.status?.success) {
            setCandidateDetails(res?.data);
            setEmail(res.data.personalEmail);
            setPhone(res.data.phone);
            setName(res.data.name);
            setNotes(res.data.notes);
            setAddress(res.data.mapString);
            setAppointmentDate(res.data.appointmentDateTime);
            if (res.data.leadType === "Company") {
                setIndustry(res.data.industry || '');
                setCompanyName(res.data.companyName || '');
                setLinkedinUrl(res.data.linkedIn || '');
                setPositionTitle(res.data.positionTitle || '');
                setCompanyEmail(res.data.companyEmail || '');
                setEmail(res.data.personalEmail || '');
                setDoorToDoor(res.data.doorToDoor || false);
                setStatusAttachment(res.data.statusAttachment || '');
            }
        }
        setLoading(false);
    };

    const updateAppointmentDate = async () => {
        handleCloseModal();
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Appointment Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });
        const req = new UpdateCandidateAppointmentReq();
        req.candidateId = selectedCandidate.id;
        req.appointmentDateTime = appointmentDate;
        const res = await FiberHouseApi.UpdateCandidateLeadAppointment(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Appointment Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                willClose: () => {
                    Swal.hideLoading();
                }
            });
            setReload(!reload);
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            });
        }
    };

    function copyToClipboard(text, message) {
        navigator.clipboard.writeText(text);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: message,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
    }


    useEffect(() => {
        if (selectedCandidate != null) {
            setLoading(true);
            getCandidateLeadDetails(selectedCandidate.id);
            setMapIcon(selectedCandidate.mapIconId);
        }
    }, [selectedCandidate, reload]);

    const hireCandidate = async () => {
        // Check if email is null or empty
        if (email === null || email === "") {
            Swal.fire({
                icon: 'error',
                title: 'Please Enter Email',
                text: 'Please enter email to hire candidate',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            return;
        }
    
        // Check if name is null or empty
        if (name === null || name === "") {
            Swal.fire({
                icon: 'error',
                title: 'Please Enter Name',
                text: 'Please enter name to hire candidate',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            return;
        }
    
        // Confirmation dialog with a dropdown selector for user type
        const result = await Swal.fire({
            title: 'Are you sure?',
            html: `
                <p>You want to hire <b>${name}</b> with email <b>${email}</b>.</p>
                <label for="userType" style="display: block; margin-top: 10px;">Select User Type:</label>
                <select id="userType" class="swal2-input" style="width: 100%; box-sizing: border-box;">
                    <option value="SalesRep">Sales Rep</option>
                    <option value="SalesOrgAdmin">Sales Org Admin</option>
                </select>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, hire!',
            preConfirm: () => {
                // Get the selected user type
                const userType = document.getElementById('userType').value;
                if (!userType) {
                    Swal.showValidationMessage('Please select a user type.');
                }
                return userType;
            }
        });
    
        if (result.isConfirmed) {
            const userType = result.value; // This is the selected user type
            const req = new CandidateJobApplicationReq();
            req.CandidateId = selectedCandidate.id;
            req.UserId = User.id;
            req.Usertype = userType; // Include the selected user type in the request
    
            const res = await FiberHouseApi.HireCandidate(req);
            setMapIcon(56);
            if (res?.status?.success) {
                Swal.fire(
                    'Hired!',
                    'Candidate has been hired.',
                    'success'
                );
                setIndex(-1);
            } else {
                Swal.fire(
                    'Error!',
                    res?.status?.message || 'Failed to hire candidate.',
                    'error'
                );
            }
        }
    };
    

    const updateLead = async (type) => {
        let req = new EditCandidateReq();
        req.id = selectedCandidate.id;

        switch (type) {
            case 'name':
                req.name = name;
                break;
            case 'email':
                req.personalEmail = email;
                break;
            case 'phone':
                req.phone = phone;
                break;
            case 'address':
                req.mapString = address;
                break;
            case 'notes':
                req.notes = notes;
                break;
            case 'industry':
                req.industry = industry;
                break;
            case 'companyName':
                req.companyName = companyName;
                break;
            case 'linkedinUrl':
                req.linkedIn = linkedinUrl;
                break;
            case 'positionTitle':
                req.positionTitle = positionTitle;
                break;
            case 'companyEmail':
                req.companyEmail = companyEmail;
                break;
            case 'doorToDoor':
                req.doorToDoor = doorToDoor;
                break;
            case 'statusAttachment':
                req.statusAttachment = statusAttachment;
                break;
            default:
                req.name = name;
                req.personalEmail = email;
                req.phone = phone;
                req.mapString = address;
                req.notes = notes;
                req.industry = industry;
                req.companyName = companyName;
                req.linkedIn = linkedinUrl;
                req.positionTitle = positionTitle;
                req.companyEmail = companyEmail;
                req.doorToDoor = doorToDoor;
        }
        req.salesRepId = User.id;
        let res = await FiberHouseApi.EditCandidateLead(req);
        if (res?.status?.success) {
            //setReload(!reload);
        }
    };

    useEffect(() => {
        if (name !== candidateDetails?.name && name !== "") {
            const timer = setTimeout(() => {
                updateLead('name');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [name]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        if (email !== candidateDetails?.personalEmail && email !== "") {
            const timer = setTimeout(() => {
                updateLead('email');
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [email]);

    useEffect(() => {
        if (phone !== candidateDetails?.phone) {
            const timer = setTimeout(() => {
                updateLead('phone');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [phone]);



    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        else if (shouldSaveAddress.current && address !== candidateDetails?.address) {
            const timer = setTimeout(() => {
                updateLead('address');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [address]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        else if (shouldSaveNotes.current && notes !== candidateDetails?.notes) {
            const timer = setTimeout(() => {
                updateLead('notes');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [notes]);

    useEffect(() => {
        if (industry && industry !== candidateDetails?.industry) {
            const timer = setTimeout(() => {
                updateLead('industry');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [industry]);

    useEffect(() => {
        if (companyName && companyName !== candidateDetails?.companyName) {
            const timer = setTimeout(() => {
                updateLead('companyName');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [companyName]);

    useEffect(() => {
        if (linkedinUrl && linkedinUrl !== candidateDetails?.linkedIn) {
            const timer = setTimeout(() => {
                updateLead('linkedinUrl');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [linkedinUrl]);

    useEffect(() => {
        if (companyEmail && companyEmail !== candidateDetails?.companyEmail) {
            const timer = setTimeout(() => {
                updateLead('companyEmail');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [companyEmail]);

    useEffect(() => {
        if (positionTitle && positionTitle !== candidateDetails?.positionTitle) {
            const timer = setTimeout(() => {
                updateLead('positionTitle');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [positionTitle]);

    const deleteCandidate = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            const req = new StringIntReq();
            req.int = selectedCandidate.id;
            const res = await FiberHouseApi.DeleteCandidate(req);

            if (res?.status?.success) {
                Swal.fire(
                    'Deleted!',
                    'Candidate has been deleted.',
                    'success'
                );
                setIndex(-1);
            } else {
                Swal.fire(
                    'Error!',
                    res?.status?.message || 'Failed to delete candidate.',
                    'error'
                );
            }
        }
    };

    const handleScheduleInterview = async (candidateId, calendlyLink) => {
        //swal loader
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Sending Schedule",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
              Swal.showLoading();
            }
          });
        try {
          let req = new CandidateJobApplicationReq();
          req.CandidateId = candidateId;
          req.ScheduleLink = calendlyLink;
          req.UserId = User.id;
          console.log(req)
          const res = await UsersApi.scheduleRecruitInterview(req)
          if (res?.status?.success) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Interview scheduled successfully",
              showConfirmButton: false,
              timer: 1500,
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setIsModalOpen(false)
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: res?.status?.message || "Failed to schedule interview",
              showConfirmButton: true,
                willClose: () => {
                    Swal.hideLoading()
                }
            })
          }
        } catch (error) {
          console.error("Error scheduling interview:", error)
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An unexpected error occurred",
            showConfirmButton: true,
            willClose: () => {
                Swal.hideLoading()
            }
          })
        }
      }

    let contactButtons = new ContactButtons(User);


    if (loading) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%"
            }}>
                <VersatileLoader size='large' color='#2761CF' />
            </div>
        );
    }


    return (
        <div className="candidate-main-lead-card">
            <div className="top-actions">
                {candidateDetails?.leadType === "Recruit" &&  (
                    <IconButton>
                        <FaHireAHelper size={24} color="green" onClick={() => hireCandidate()} />
                    </IconButton>
                )}
                <IconButton>
                    <FaHistory size={24} color="blue" onClick={() => setIndex(20)} />
                </IconButton>
                <IconButton onClick={() => deleteCandidate()}>
                    <MdDeleteOutline size={24} color="red" />
                </IconButton>
            </div>
            <InvitationModal open={isInviteOpen} onClose={() => setIsInviteOpen(false)} candidateType={candidateDetails.leadType} candidateId={candidateDetails.id} />

            <div className="scrollable-content">
                <div className="header-section">
                    <div className="map-icon">
                        <img
                            src={"data:image/png;base64," + mapIcons.find(icon => icon.id === mapIcon)?.image}
                            alt="Map Icon"
                            style={{ width: "50px", height: "50px" }}
                        />
                    </div>
                    <div className="lead-info">
                        <div className="name-container">
                            <input
                                placeholder='No Name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className='LeadName'
                            />
                            {candidateDetails?.doorToDoor && (
                                <div className="door-icon">
                                    <DoorFront />
                                </div>
                            )}
                        </div>
                        <div className="location">
                            <textarea
                                rows={2}
                                placeholder='Address'
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                    shouldSaveAddress.current = true;
                                }}
                            />
                            <IconButton size="small" onClick={() => copyToClipboard(address, 'Address Copied to Clipboard')}>
                                <ContentCopy fontSize="small" />
                            </IconButton>
                        </div>
                    </div>
                </div>

                <Divider className="section-divider" />

                <div className="info-section">
                    <Typography variant="h6" gutterBottom>Contact Information</Typography>

                    <div className="input-field">
                        <EmailOutlined />
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="No Email"

                        />
                        {email && (
                            <IconButton size="small" onClick={() => copyToClipboard(email, 'Email Copied to Clipboard')}>
                                <ContentCopy fontSize="small" />
                            </IconButton>
                        )}
                    </div>

                    <div className="input-field">
                        <PhoneOutlined />
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="No Phone"
                        />
                        {phone && (
                            <IconButton size="small" onClick={() => copyToClipboard(phone, 'Phone Number Copied to Clipboard')}>
                                <ContentCopy fontSize="small" />
                            </IconButton>
                        )}
                    </div>
                </div>

                <Divider className="section-divider" />

                <div className="info-section">
                    <Typography variant="h6" gutterBottom>Interview</Typography>
                    <div className="appointment-section">
                        <Button color="primary" onClick={handleOpenModal}>Set Interview</Button>
                        <Typography variant="body2">
                            {appointmentDate
                                ? `${new Date(appointmentDate).toLocaleDateString()} ${new Date(appointmentDate).toLocaleTimeString()}`
                                : "No Interview Set"
                            }
                        </Typography>
                    </div>
                    <div className="customer-status">
                        {candidateDetails?.candidateInvitation ? (
                            <div className="status-text">
                                <CheckCircle color="primary" />
                                <span>Invitation Sent</span>
                            </div>
                        ) : (
                            <Button startIcon={<SendOutlined />} color="primary" onClick={() => setIsInviteOpen(true)}>
                                Send Invitation
                            </Button>
                        )}
                    </div>
                </div>

                {candidateDetails?.leadType === "Company" && <>
                    <Divider className="section-divider" />
                    <div className="info-section">
                        <Typography variant="h6" gutterBottom>Company Information</Typography>
                        <div className="info-grid">
                            <div className="input-field">
                                <BusinessOutlined />
                                <input
                                    type="text"
                                    value={industry}
                                    onChange={(e) => setIndustry(e.target.value)}
                                    placeholder="Enter industry"
                                />
                                {industry && (
                                    <IconButton size="small" onClick={() => copyToClipboard(industry, 'Industry Copied to Clipboard')}>
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                )}
                            </div>
                            <div className="input-field">
                                <WorkOutlined />
                                <input
                                    type="text"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    placeholder="Enter company name"
                                />
                                {companyName && (
                                    <IconButton size="small" onClick={() => copyToClipboard(companyName, 'Company Name Copied to Clipboard')}>
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                )}
                            </div>
                            <div className="input-field">
                                <LinkedIn />
                                <input
                                    type="text"
                                    value={linkedinUrl}
                                    onChange={(e) => setLinkedinUrl(e.target.value)}
                                    placeholder="Enter LinkedIn URL"
                                />
                                {linkedinUrl && (
                                    <IconButton size="small" onClick={() => copyToClipboard(linkedinUrl, 'LinkedIn URL Copied to Clipboard')}>
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                )}
                            </div>
                            <div className="input-field">
                                <PersonOutline />
                                <input
                                    type="text"
                                    value={positionTitle}
                                    onChange={(e) => setPositionTitle(e.target.value)}
                                    placeholder="Enter position title"
                                />
                                {positionTitle && (
                                    <IconButton size="small" onClick={() => copyToClipboard(positionTitle, 'Position Title Copied to Clipboard')}>
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                )}
                            </div>
                            <div className="input-field">
                                <EmailOutlined />
                                <input
                                    type="text"
                                    value={companyEmail}
                                    onChange={(e) => setCompanyEmail(e.target.value)}
                                    placeholder="Enter company email"
                                />
                                {companyEmail && (
                                    <IconButton size="small" onClick={() => copyToClipboard(companyEmail, 'Company Email Copied to Clipboard')}>
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                )}
                            </div>
                            <div className="info-item checkbox-item">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={doorToDoor}
                                            onChange={(e) => {
                                                setDoorToDoor(e.target.checked);
                                                updateLead('doorToDoor');
                                            }}
                                        />
                                    }
                                    label="Door to Door"
                                />
                            </div>
                        </div>

                    </div>
                </>
                }

                <Divider className="section-divider" />

                <div className="info-section">
                    <Typography variant="h6" gutterBottom>Lead Status</Typography>
                    <div className="status-icons">
                        <MapIconDisplay isCandidate={true} candidateType={candidateDetails?.leadType} removeLightningBolts={true} setMapIcon={setMapIcon} />
                    </div>
                </div>

                <Divider className="section-divider" />

                <div className="info-section">
                    <div className="notes-header">
                        <Typography variant="h6">Notes</Typography>
                        <IconButton onClick={() => copyToClipboard(notes, 'Notes Copied to Clipboard')}>
                            <ContentCopy />
                        </IconButton>
                    </div>
                    <textarea
                        value={notes}
                        ref={shouldSaveNotes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Add notes here..."
                        rows={4}
                    />
                </div>
            </div>

            <div className="communication-buttons">
                <div className="MainLeadCard_Button" onClick={() => contactButtons.email(candidateDetails.personalEmail ? candidateDetails.personalEmail : candidateDetails.companyEmail)}>
                    <img src={Email} alt="Email" />
                    <span>Email</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.sms(candidateDetails.phone)}>
                    <img src={Message} alt="Message" />
                    <span>SMS</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.whatsapp(candidateDetails.phone)}>
                    <img src={wa} alt="Message" />
                    <span>WA</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.call(candidateDetails.phone)}>
                    <img src={Call} alt="Call" />
                    <span>Call</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.directions(candidateDetails.coordinates)}>
                    <img src={Directions} alt="Directions" />
                    <span>Directions</span>
                </div>
            </div>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='custom_modal'>
                    <IoCloseCircle onClick={() => {
                        handleCloseModal()
                    }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                    <h1>Send Interview Calendar</h1>
                    <CalendlyUserSelection
                        candidateId={candidateDetails.id}
                        onSchedule={handleScheduleInterview}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default CandidateMainLeadCard;