import { Chat, Dashboard } from "@mui/icons-material";
import { CardMembership } from "@mui/icons-material";
import { IoBugSharp } from "react-icons/io5";
import { MdOutlineBugReport } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
const AdminMenuItems = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color,size)=><Dashboard />,

        noCollapse: true,
        admin: true,

    },
    {
        type: "collapse",
        name: "Chat",
        key: "chat",
        route: '/chats',
        icon:(color,size)=> <Chat />,
        noCollapse: true,
        admin: true,
    },
    {
        type: "collapse",
        name: "Training Modules",
        key: 'manageSellingCard',
        route: '/manage-training-modules',
        icon:(color,size)=> <CardMembership fontSize='medium' />,
        noCollapse: true
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        icon: (color,size=40) => <MdOutlineBugReport size={size} color={color} />,
    },
]

export default AdminMenuItems;