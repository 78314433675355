import React, { useContext, useEffect, useRef } from 'react'
import LoaderConversationReq from '../../../../Requests/Messaging/LoadConversationReq'
import MessagingApi from '../../../../API/MessagingApi'
import SearchUserReq from '../../../../Requests/Users/SearchUserReq'
import UsersApi from '../../../../API/UsersApi'
import { ConnectionContext, UserContext } from '../../../../App'
import MarkConversationUserAsSeenReq from '../../../../Requests/Messaging/MarkConversationUserAsSeenReq'
import { useCallback } from 'react'
import Message from './Message'
import { CircularProgress } from '@mui/material'
const ConversationBody = ({ messages, setMessages,getPinnedMessages,conversation,setEditMessageId,getUserFavouriteMessages }) => {
    const user = useContext(UserContext)

    const [loading, setLoading] = React.useState(false)
    const [totalPages, setTotalPages] = React.useState(0);
    const [pageNo, setPageNo] = React.useState(1);
    const [userNames, setUserNames] = React.useState({});
    const [currentConversationUser, setCurrentConversationUser] = React.useState(null);
    const ConnectionServiceObj = useContext(ConnectionContext);
    const [hasMore, setHasMore] = React.useState(false);
    const [firstRender, setFirstRender] = React.useState(true)
    const scroll = useRef();
    const observer = useRef();  // (*)
    
    const firstMessageRef = useCallback(  // (*)
        (node) => {
            if (firstRender) {
                console.log("Skip due to first render");
                return;
            }
            if (loading) {
                console.log("Skip due to loading");
                return;
            }
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {

                if (entries[0].isIntersecting && hasMore) {
                    setPageNo((prev) => prev + 1);
                    console.log("visible");
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore, firstRender]  // (*) Add firstRender to the dependency array
    );
  
    const loadConversation = () => {
        setLoading(true)
        const req = new LoaderConversationReq();
        req.conversationId = conversation.id;
        req.pagingParams.pageNumber = pageNo;
        req.pagingParams.pageSize = 10;
        MessagingApi.LoadConversation(req).then(
            (res) => {
                if(res?.status?.success)
                {
                    if (messages?.length > 0) {
                        setMessages((prev) => [
                            ...prev,
                            ...res?.data?.list
                        ])
                    }
                    else {
                        setMessages(res?.data?.list)
                    }
                    setTotalPages(res?.data?.totalPages)
                    setHasMore(res?.data?.totalPages > pageNo)
                    res?.data?.list?.map(
                        (message) => {
                            getUserName(message.senderId)
                        }
                    )
                    console.log(res?.data?.list)
                    setLoading(false)
                }
            }
        )
    }




    async function getUserName(id) {
        // If the user name is already fetched, return it
        if (userNames[id]) {
            return userNames[id];
        }

        // Otherwise, fetch the user name
        const req = new SearchUserReq();
        req.id = id;
        const res = await UsersApi.SearchUser(req);

        // Check if res.data and res?.data?.list are not null
        if (res.data && res?.data?.list && res?.data?.list.length > 0) {
            const name = res?.data?.list[0].firstName + " " + res?.data?.list[0].lastName;

            // Update the state variable
            setUserNames(prevUserNames => ({
                ...prevUserNames,
                [id]: name,
            }));
            return name;
        } else {
            
        }
    }
    // get the latest message
    function reloadFunction(senderId, conversationName, conversationId, message, userName,profileImage) {

  
        console.log(message)
        setMessages(
            (prev) => {
                if(prev?.length>0)
                 return [message, ...prev]
                else
                return [message]
            }
        )
      
        const req3 = new MarkConversationUserAsSeenReq();
        req3.int = currentConversationUser;
        MessagingApi.MarkConversationUserAsRead(req3).then(
            (res) => {

            }
        );
    }

    useEffect(() => {
        console.log(messages)
    }, [messages])

    useEffect(() => {
        loadConversation();
    }, [pageNo]);

    useEffect(
        () => {
            conversation.conversationUsers.map((u) => {
                if (u.userId === user.id) {
                    setCurrentConversationUser(u.id)
                    const req2 = new MarkConversationUserAsSeenReq();
                    req2.int = u.id;
                    console.log(u.id)
                    MessagingApi.MarkConversationUserAsRead(req2).then(
                        (res) => {
                            
                        }
                    )
                }
            })
        }, []
    )

    useEffect(
        () => {
            ConnectionServiceObj.ConversationLoaders = [{ conversationId: conversation.id }];
            ConnectionServiceObj.newMessage = reloadFunction;
            loadConversation();
            return () => {
                ConnectionServiceObj.ConversationLoaders = [];
                const req2 = new MarkConversationUserAsSeenReq();
                req2.int = currentConversationUser;
                MessagingApi.MarkConversationUserAsRead(req2).then(
                    (res) => {

                    }
                )
            }
        }, [conversation]

    );

    useEffect(() => {
        if (pageNo === 1) {
            scroll?.current?.scrollIntoView({ behavior: "instant" });
           setTimeout(() => {
            setFirstRender(false);
           }, 3000);
        }
    }, [messages])

    return (
        <div className='chat_conversation_body'>
            {
                loading && <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                
                }}>
                    <CircularProgress sx={{color:"#2761D0"}} />
                    </div>
            }
            {
                messages?.slice(0)?.reverse()?.map((message, index, arr) => {
                    const prevMessage = arr[index - 1];
                    const showDivider = prevMessage && new Date(message?.createdAt).toDateString() !== new Date(prevMessage?.createdAt).toDateString();
                    const isFirstElement = index === 0;
                    const isLastElement = index === arr?.length - 1;
                    return (
                        <React.Fragment key={index}>
                            {(showDivider || index==0) && <div className="day-divider">{new Date(message?.createdAt).toLocaleDateString()}</div>}
                            <Message getPinnedMessages={getPinnedMessages} getUserFavouriteMessages={getUserFavouriteMessages} setEditMessageId={setEditMessageId} setMessages={setMessages} isLastElement={isLastElement} isFirstElement={isFirstElement} firstMessageRef={firstMessageRef} scroll={scroll} conversationUsers={conversation.conversationUsers} message={message} selectedProfile={""} user={user} userName={userNames[message?.senderId]} />
                        </React.Fragment>
                    )
                })
            }

        </div>
    )
}

export default ConversationBody