import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import OrgApi from '../../../API/OrgApi'
import UsersApi from '../../../API/UsersApi'
import Sidebar from '../../../Layouts/Sidebar'
import AddMemberOrgReq from '../../../Requests/Organizations/AddMemberOrgReq'
import RemoveMemberOrgReq from '../../../Requests/Organizations/RemoveMemberOrgReq'
import SearchSalesOrgReq from '../../../Requests/Organizations/SearchSalesOrgsReq'
import EditUserReq from '../../../Requests/Users/EditUserReq'
import SearchUserReq from '../../../Requests/Users/SearchUserReq'
import OrganizationSelector from '../../../components/OrganizationSelector'
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { IoArrowBack } from 'react-icons/io5'
const Index = () => {
    const [user, setUser] = useState({})
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [userType, setUserType] = useState('')
    const [status, setStatus] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [organizationId, setOrganizationId] = useState(null)
    const [prevSalesOrgId, setPrevSalesOrgId] = useState(null)
    const [salesOrgName, setSalesOrgName] = useState('')
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const [loading2, setLoading2] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        setLoading2(true)
        const req = new SearchUserReq()
        req.id = location.state.userId
        UsersApi.SearchUser(req).then(res => {

            if (res?.status?.success) {
                
                setFirstName(res?.data?.list[0].firstName)
                setLastName(res?.data?.list[0].lastName)
                setEmail(res?.data?.list[0].email)
                setPhoneNumber(res?.data?.list[0].phoneNumber)
                setAddress(res?.data?.list[0].address)
                setUserType(res?.data?.list[0].userType)
                setStatus(res?.data?.list[0].status)
                setOrganizationId(res?.data?.list[0].salesOrgId)
                setUser(res?.data?.list[0])
                setPrevSalesOrgId(res?.data?.list[0].salesOrgId)
                setLoading2(false)
                if (res?.data?.list[0].salesOrgId) {
                    const req1 = new SearchSalesOrgReq()
                    req1.id = res?.data?.list[0].salesOrgId
                    OrgApi.SearchOrg(req1).then(res => {
                        if (res?.status?.success) {
                            
                            setSalesOrgName(res?.data?.list[0].name)
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: res?.status?.message,
                            })
                        }
                    })
                }

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                })
            }
        })
    }
        , [loading])



    const updateUser = () => {
        if (disabled) {
            setDisabled(!disabled)
            return
        }
        setLoading(true)
        Swal.fire({
            icon: 'info',
            title: 'Updating User',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        

        if (!firstName) {
            Swal.fire({
                icon: 'error',
                title: 'Enter First Name',
                text: 'First Name is required!',
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if (!lastName) {
            Swal.fire({
                icon: 'error',
                title: 'Enter Last Name',
                text: 'Last Name is required!',
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if (!email) {
            Swal.fire({
                icon: 'error',
                title: 'Enter Email',
                text: 'Email is required!',
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if (!phoneNumber) {
            Swal.fire({
                icon: 'error',
                title: 'Enter Phone Number',
                text: 'Phone Number is required!',
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        if (!address) {
            Swal.fire({
                icon: 'error',
                title: 'Enter Address',
                text: 'Address is required!',
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!userType) {
            Swal.fire({
                icon: 'error',
                title: 'Enter Role',
                text: 'Role is required!',
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!organizationId) {
            Swal.fire({
                icon: 'error',
                title: 'Enter Sales Organization',
                text: 'Sales Organization is required!',
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new EditUserReq()
        req.userId = location.state.userId
        req.firstName = firstName
        req.lastName = lastName
        req.phoneNumber = phoneNumber
        req.address = address
        req.status = status


        setDisabled(!disabled)
        
        UsersApi.EditUser(req).then(res => {
            if (res?.status?.success) {
                
                if (prevSalesOrgId) {
                    const req2 = new RemoveMemberOrgReq()
                    req2.int = location.state.userId
                    OrgApi.RemoveUsersFromOrg(req2).then(res => {
                        if (res?.status?.success) {
                            const req1 = new AddMemberOrgReq()
                            req1.id1 = organizationId
                            req1.id2 = location.state.userId
                            OrgApi.AddUsersToSalesOrg(req1).then(res => {
                                if (res?.status?.success) {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'User Updated Successfully',
                                        text: 'User has been updated successfully!',
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    })
                                    setLoading(false)
                                }
                                else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: res?.status?.message,
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    })
                                }
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: res?.status?.message,
                                didOpen: () => {
                                    Swal.hideLoading()
                                }
                            })
                        }
                    })
                }
                else {
                    const req1 = new AddMemberOrgReq()
                    req1.id1 = organizationId
                    req1.id2 = location.state.userId
                    OrgApi.AddUsersToSalesOrg(req1).then(res => {
                        if (res?.status?.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'User Updated Successfully',
                                text: 'User has been updated successfully!',
                                didOpen: () => {
                                    Swal.hideLoading()
                                }
                            })
                            setLoading(false)
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: res?.status?.message,
                                didOpen: () => {
                                    Swal.hideLoading()
                                }
                            })
                        }
                    })
                }
                setLoading(false)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            }
        })
    }


    return (
        <Layout>
            <IoArrowBack size={30} color='black' style={{ cursor: 'pointer',marginLeft:"1rem" }} onClick={() => navigate(-1)} />
            <div className='userDetailsContainer'>
                <div className='form' >
                    <h1>User Details</h1>
                    {!loading2 ? <div className='inputs' >
                        <label >First Name</label>
                        <input value={firstName} disabled={disabled} label="First Name" onChange={(e) => setFirstName(e.target.value)} />
                        <label >Last Name</label>
                        <input value={lastName} disabled={disabled} label="Last Name" onChange={(e) => setLastName(e.target.value)} />
                        <label >Email</label>
                        <input value={email} disabled={true} label="Email" onChange={(e) => setEmail(e.target.value)} />
                        <label >Phone Number</label>
                        <input value={phoneNumber} disabled={disabled} label="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
                        <label >Address</label>
                        <input value={address} disabled={disabled} label="Address" onChange={(e) => setAddress(e.target.value)} />
                        <label >Role</label>
                        {disabled ? <input value={userType} disabled={disabled} label="Role" onChange={(e) => setUserType(e.target.value)} /> :
                            <select onChange={(e) => setUserType(e.target.value)} value={userType}>
                                <option value="SuperAdmin">Super Admin</option>
                                <option value="Admin">Admin</option>
                                <option value="SalesOrgAdmin">Team Admin</option>
                                <option value="SalesRep">Sales Rep</option>
                                <option value="Customer">Customer</option>
                            </select>
                        }
                        <label >Sales Organization</label>
                        {disabled ? <input value={salesOrgName} disabled={disabled} label="Sales Organization Name" onChange={(e) => setUser({ ...user, salesOrgName: e.target.value })} /> : <OrganizationSelector placeholder={salesOrgName} setUser={setOrganizationId} />}
                        <label>Status</label>
                        <input value={status} disabled={disabled} label="Status" onChange={(e) => setStatus(e.target.value)} />
                    </div> :
                        <div className='spinner'>
                            <CircularProgress sx={{color:"var(--color-icon)"}} />
                        </div>
                    }
                    <button onClick={() => updateUser()} >{disabled ? "Edit" : "Save"}</button>
                </div>
            </div>
        </Layout>
    )
}

export default Index