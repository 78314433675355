import Layout from '../../../Layouts/Layout'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import CustomerFeedbackReq from '../../../Requests/Customer/CustomerFeedbackReq'
import { uploadFileToFirebase } from '../../../Services/DisplayFile'
import CustomerApi from '../../../API/CustomerApi'
import { CircularProgress, Rating } from '@mui/material'
import { useParams } from 'react-router-dom'
import './Publicfeedback.scss'
import { v4 as uuidv4 } from 'uuid'
import PhoneInput from 'react-phone-input-2'
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq'
import authorizedLightCurve from "./../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png"
import authorizedFastwyre from "./../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png"
import { FaGoogle } from 'react-icons/fa6'
const Index = () => {

    const { phoneNumber } = useParams()
    const [reviewType, setReviewType] = React.useState('Written')
    const [phone, setPhone] = React.useState(phoneNumber)
    const [speedTestOld, setSpeedTestOld] = React.useState(null)
    const [speedTestNew, setSpeedTestNew] = React.useState(null)
    const [consent, setConsent] = React.useState(false)
    const [video, setVideo] = React.useState(null)
    const [review, setReview] = React.useState('')
    const [rating, setRating] = React.useState(5);
    const [customer, setCustomer] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [submitted, setSubmitted] = React.useState(false)
    const [url, setUrl] = React.useState('')
    const [unsatisfied, setUnsatisfied] = React.useState(false)
    const searchCustomer = async () => {
        const req = new SearchCustomerReq()
        req.cellPhone = phone
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 1
        const res = await CustomerApi.SearchFidiumCustomers(req)
        if (res.status.success) {
            setCustomer(res.data.list[0]);
            setLoading(false)
        }
    }

    useEffect(() => {
        searchCustomer()
    }, [])

    const submitFeedback = async () => {
        Swal.fire({
            title: 'Submitting Feedback',
            text: 'Please do not close the window',
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (rating === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Please provide a rating',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (reviewType === 'Video' && !video) {
            Swal.fire({
                icon: 'error',
                title: 'Please provide a video',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        // if (reviewType === 'Written' && review === '') {

        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Please provide content',
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return
        // }

        if (reviewType === 'SpeedTest' && (!setSpeedTestOld || !speedTestNew)) {
            Swal.fire({
                icon: 'error',
                title: 'Please provide both images',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        

        if (rating >= 4) {
            let placeId = customer?.fiberCompanyId === 2 ? "ChIJ1_x-R3tVkFQRwwsl2VbiIiI" : customer.fiberCompanyId === 9 ? "ChIJawLxLbWEO4YRfbJ_aC3FiFg" : "ChIJ04e61A4A46MRyWgqKx7icd4"
            let url = 'https://search.google.com/local/writereview?placeid=' + placeId
            setUrl(url)
        }
        else if(!review){
            Swal.fire({
                icon: 'error',
                title: 'Please provide a review',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        let req = new CustomerFeedbackReq()
        req.FeedbackType = reviewType
        req.Rating = rating
        req.CellPhone = phone
        // req.FidiumCustomerId = lead.fidiumCustomerId
        // req.LeadId = lead.fidiumCustomer?.LeadId
        // req.SalesRepId = lead.fidiumCustomer?.SalesRepId


        if (reviewType === 'Video') {
            let videoReq = await uploadFileToFirebase(video, `/VideoFeedbacks/${phone}/${uuidv4()}`)
            req.Video = videoReq;
        }
        else if (reviewType === 'Written') {
            req.Content = review
        }
        else if (reviewType === 'SpeedTest') {
            let speedTest = {
                BeforeImage: await uploadFileToFirebase(speedTestOld, `/SpeedTestFeedbacks/${phone}/BeforeImage/${uuidv4()}`),
                AfterImage: await uploadFileToFirebase(speedTestNew, `/SpeedTestFeedbacks/${phone}/AfterImage/${uuidv4()}`),
                // FidiumCustomerId: lead.fidiumCustomerId,
                // LeadId: lead.fidiumCustomer?.LeadId != null ? lead.fidiumCustomer?.LeadId : null,
                CustomerFeedbackId: null,
            }
            req.SpeedTest = speedTest

        }


        let res = await CustomerApi.CreateCustomerFeedbackBaseOnPhone(req)

        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Thank you for your feedback',
                customClass: {
                    container: 'custom-swal'
                },
                showConfirmButton: true,
                showCancelButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setSubmitted(true)


            if (rating <= 5) {
                setUnsatisfied(true)
            }

            // window.location.reload()
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to submit feedback',
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

        }

    }

    useEffect(
        () => {
            if(rating>=4 && url){
                // open the link in the current tab
                window.location.href = url

            }
        },[url]
    )

    return (
        <div className='main_container'>
            {
                loading ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }} >
                        <CircularProgress size={60} sx={{ color: "var(--primary-color)" }} />
                    </div>
                    :
                    customer ?
                        !submitted ?
                            <div className='public_feedback_container'>
                                <div className='digital_tool_belt_container'>
                                    {
                                        customer?.fiberCompanyId === 2 &&
                                        <img src={authorizedLightCurve} alt='Authorized Light Curve' />
                                    }
                                    {
                                        customer?.fiberCompanyId === 9 &&
                                        <img src={authorizedFastwyre} alt='Authorized Fastwyre' />
                                    }
                                    <div className='form'>
                                        <h2>Submit A Review</h2>
                                        {/* <div className='input_div'>
                                            <div className='priority'>
                                                <p className={reviewType === "Written" ? 'active' : ""} onClick={() => { setReviewType('Written') }}>Written</p>
                                                <p className={reviewType === "Video" ? 'active' : ""} onClick={() => { setReviewType("Video") }}>Video</p>
                                                <p className={reviewType === "SpeedTest" ? 'active' : ""} onClick={() => { setReviewType("SpeedTest") }}>Speed Test</p>
                                            </div>
                                        </div> */}
                                        <div className='input_div'>
                                            <label>Phone Number</label>
                                            {/* <input type='text' value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
                                            <PhoneInput country={'us'} value={phone} onChange={(e) => setPhone(e)} containerStyle={{ width: "100%" }} inputStyle={{ width: "100%", paddingLeft: "48px" }} />
                                        </div>
                                        {
                                            reviewType === 'SpeedTest' &&
                                            <div className='input_div'>
                                                <label>Speed Test Old</label>
                                                {
                                                    customer?.speedTest && customer?.speedTest?.beforeImage ?
                                                        <div className='image'>
                                                            <img src={customer?.speedTest?.beforeImage.url} alt='Speed Test Old' />
                                                        </div>
                                                        :
                                                        <input type='file' value={speedTestOld} onChange={(e) => setSpeedTestOld(e.target.value)} />
                                                }
                                            </div>
                                        }
                                        {
                                            reviewType === 'SpeedTest' &&
                                            <div className='input_div'>
                                                <label>Speed Test New</label>
                                                <input type='file' value={speedTestNew} onChange={(e) => setSpeedTestNew(e.target.value)} />
                                            </div>
                                        }
                                        {
                                            reviewType === 'Video' &&
                                            <div className='input_div'>
                                                <label>Upload Video</label>
                                                <input type='file' value={video} onChange={(e) => setVideo(e.target.value)} />
                                            </div>
                                        }
                                        <div className='input_div'>
                                            <label>Rating (1-5 Stars)</label>
                                            <Rating name="simple-controlled" value={rating} onChange={(e, number) => { setRating(number) }} sx={{
                                                fontSize: '3rem'
                                            }} />
                                        </div>
                                        {/* {
                                            reviewType === 'Written' &&
                                            <div className='input_div'>
                                                <label>Review Comments</label>
                                                <textarea placeholder='' value={review} onChange={(e) => setReview(e.target.value)} rows={3} />
                                            </div>
                                        } */}
                                        <div className='consent'>
                                            <input type='checkbox' checked={consent} onChange={() => setConsent(!consent)} />
                                            <p style={{ textTransform: "uppercase" }}>I agree to Share my information with the referred party.</p>
                                        </div>
                                        <button onClick={() => {
                                            if (rating <= 4) {
                                                setSubmitted(true)
                                            }
                                            else {
                                                submitFeedback()
                                            }
                                        }}>Leave Google Review</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='public_feedback_container'>
                                <div className='digital_tool_belt_container'>
                                    {
                                        rating >= 4 ?
                                            <p style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }} className='not_found'>
                                                <>
                                                    <FaGoogle size={100} style={{ color: 'var(--primary-color)' }} />
                                                    <a style={{
                                                        color: 'var(--primary-color)',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }} href={url}   >
                                                        Leave us a review on Google
                                                    </a>
                                                </>
                                            </p>
                                            :
                                            !unsatisfied ?
                                                <>
                                                    {
                                                        customer?.fiberCompanyId === 2 &&
                                                        <img src={authorizedLightCurve} alt='Authorized Light Curve' />
                                                    }
                                                    {
                                                        customer?.fiberCompanyId === 9 &&
                                                        <img src={authorizedFastwyre} alt='Authorized Fastwyre' />
                                                    }
                                                    <div className='form'>
                                                        <div className='input_div'>
                                                            <label>Review Comments</label>
                                                            <textarea placeholder='' value={review} onChange={(e) => setReview(e.target.value)} rows={3} />
                                                        </div>
                                                        <button onClick={() => {
                                                            submitFeedback()
                                                        }}>Submit</button>
                                                    </div>
                                                </>
                                                :
                                                <p className='not_found'>
                                                    <span>Thank You! </span>
                                                    We will try to improve our Services.
                                                </p>
                                    }
                                </div>
                            </div>
                        :
                        <div className='public_feedback_container'>
                            <div className='digital_tool_belt_container'>
                                <p className='not_found'>
                                    <span>Sorry!</span>
                                    We couldn't find the customer with the provided phone number.
                                </p>
                            </div>
                        </div>

            }
        </div>
    )
}

export default Index