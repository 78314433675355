class CreateShopItem{
    constructor()
    {
        
            this.name = null;
            this.description = null;
            this.shopCategoryId= null;
            this.price = null;
            this.files = [
           
            ];
          
    }
};
export default CreateShopItem;