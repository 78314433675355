import React, { useContext, useState } from 'react';
import './index.scss';
import Swal from 'sweetalert2';
import UsersApi from '../../../API/UsersApi';
import { CircularProgress } from '@mui/material';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import CreateBugTicketReq from '../../../Requests/Users/CreateBugTicketReq';
import { UserContext } from '../../../App';
import Layout from '../../../Layouts/Layout';

const CreateBugTicketForm = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const storage = getStorage();
    const user = useContext(UserContext);

    const handleSubmit = async () => {
        setIsLoading(true);

        if (!title) {
            Swal.fire({
                icon: 'error',
                title: 'Title is required',
                text: 'Please enter a title for the bug ticket.',
            });
            setIsLoading(false);
            return;
        }

        if (!description) {
            Swal.fire({
                icon: 'error',
                title: 'Description is required',
                text: 'Please enter a description for the bug ticket.',
            });
            setIsLoading(false);
            return;
        }

        let req = new CreateBugTicketReq();
        req.title = title;
        req.description = description;
        req.userId = user.id;

        if (image) {
            try {
                const uniqueId = uuidv4();
                const storageRef = ref(storage, `/bug-tickets/${uniqueId}/${image.name}`);
                await uploadBytes(storageRef, image);
                const downloadURL = await getDownloadURL(storageRef);

                req.image = {
                    name: image.name,
                    path: `/bug-tickets/${uniqueId}/${image.name}`,
                    url: downloadURL,
                    extension: image.name.split('.').pop(),
                    fileSize: image.size.toString(),
                    fileType: image.type,
                };
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Image Upload Failed',
                    text: 'There was an error uploading the image. Please try again.',
                });
                setIsLoading(false);
                return;
            }
        }

        const res = await UsersApi.CreateBugTicket(req);

        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Bug Ticket Submitted',
                text: 'Your bug ticket has been submitted successfully.',
            });
            setTitle('');
            setDescription('');
            setImage(null);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Submission Failed',
                text: res?.status?.message || 'There was an error submitting the bug ticket. Please try again.',
            });
        }

        setIsLoading(false);
    };

    return (
        <Layout>

        <div className='bug_ticket_form_container'>
            {isLoading ? (
                <div className='loading_spinner'>
                    <CircularProgress size={50} sx={{ color: 'var(--primary-color)' }} />
                </div>
            ) : (
                <div className='bug_ticket_form'>
                    <h1>Create Bug Ticket</h1>
                    <div className='input_group'>
                        <label htmlFor='title'>Title *</label>
                        <input
                            type='text'
                            id='title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder='Enter the title of the bug'
                            />
                    </div>
                    <div className='input_group'>
                        <label htmlFor='description'>Description *</label>
                        <textarea
                            id='description'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder='Describe the bug'
                            rows={4}
                            />
                    </div>
                    <div className='input_group'>
                        <label htmlFor='image'>Image (optional)</label>
                        <input
                            type='file'
                            id='image'
                            onChange={(e) => setImage(e.target.files[0])}
                            />
                    </div>
                    <button className='submit_button' onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            )}
        </div>
        </Layout>
    );
};

export default CreateBugTicketForm;
