import React, { useContext, useEffect, useState } from 'react';
import { Button, Switch } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './RepTrackingList.scss';
import StringIntReq from '../../../../Requests/StringIntReq';
import UsersApi from '../../../../API/UsersApi';
import CustomSwiper from './CustomSwiper';
import { MapContext } from '..';
import { Input } from 'antd';
import { ZoomIn, ZoomOut } from '@mui/icons-material';

const RepList = ({
    reps,
    setTargetUserId,
    setUserPathColor,
    updateLocationHistories,
    setToggleState,
    toggleState,
    locationFromDate,
    setActivityCoords,
    distanceRef,
}) => {
    const [expandedRep, setExpandedRep] = useState(() => {
        const savedState = JSON.parse(localStorage.getItem('repListState')) || {};
        return savedState.expandedRep || null;
    });

    const [colors, setColors] = useState({});
    const { mapIcons, mapObject } = useContext(MapContext);
    const speedText = ["x0.5", "x1", "x2", "x4", "x6", "x8"];
    const speedValue = [0.5, 1, 2, 4, 6, 10];

    const [speedIndex, setSpeedIndex] = useState(0);
    const [speed, setSpeed] = useState(speedValue[0]);
    const [isPanning, setIsPanning] = useState(true)
    const [zoom, setZoom] = useState(20);
    const [panValue, setPanValue] = useState(1);


    const handleSpeedChange = () => {
        // Calculate the next index and update the speed
        const nextIndex = (speedIndex + 1) % speedText.length;
        setSpeedIndex(nextIndex);
        setSpeed(speedValue[nextIndex]);
    };

    const handleZoomChange = (symbol) => {
        if (symbol === "-" && !(zoom <= 5)) {
            setZoom((prev) => prev -= 1)
            console.log(zoom)
        }
        if (symbol === "+" && !(zoom >= 25)) {
            setZoom((prev) => prev += 1)
            console.log(zoom)
        }
    };

    const handlePanSwitch = () => {
        setIsPanning((prev) => !prev)
        if (panValue === 1) {
            setPanValue(0)
        } else {
            setPanValue(1)
        }

        console.log(isPanning);
    };

    const handleDistanceChange = (event) => {
        distanceRef.current = event.target.value
    }


    useEffect(() => {
        const savedState = JSON.parse(localStorage.getItem('repListState')) || {};
        if (savedState.expandedRep) {
            setTargetUserId(savedState.expandedRep);
        }
        if (savedState.toggleState) {
            setToggleState(savedState.toggleState);
        }
    }, [setTargetUserId, setToggleState]);

    useEffect(() => {
        const initialColors = {};
        reps.forEach((rep) => {
            initialColors[rep.userId] =
                rep.pathColor || `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        });
        setColors(initialColors);
    }, [reps]);

    const saveStateToLocalStorage = (expandedRep, toggleState) => {
        const state = {
            expandedRep,
            toggleState,
            selectedDate: new Date(locationFromDate).toISOString().slice(0, 10),
        };
        localStorage.setItem('repListState', JSON.stringify(state));
    };

    useEffect(() => {
        saveStateToLocalStorage(expandedRep, toggleState);
    }, [expandedRep, toggleState]);


    const handleColorChange = (repId, newColor) => {
        setColors((prev) => ({ ...prev, [repId]: newColor }));
    };

    const saveUserPathColor = async (repId) => {
        const req = new StringIntReq();
        req.int = repId;
        req.string = colors[repId];

        const res = await UsersApi.saveRepPathColor(req);
        if (res.status.success) {
            setUserPathColor(colors[repId]);
            updateLocationHistories((prev) =>
                prev.map((rep) =>
                    rep.userId === repId ? { ...rep, pathColor: colors[repId] } : rep
                )
            );
        }
    };

    const handleCardClick = (repId) => {
        const newExpandedRep = expandedRep === repId ? null : repId;
        setExpandedRep(newExpandedRep);
        setTargetUserId(newExpandedRep);

        if (newExpandedRep) {
            const newColor =
                colors[repId] || `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            setUserPathColor(newColor);
        }
    };

    const handleToggleChange = (selectedToggle) => {
        setToggleState((prev) => {
            const newToggles = {
                currentLocation: false,
                path: false,
                activity: false,
            };

            newToggles[selectedToggle] = !prev[selectedToggle];
            const playTime = newToggles[selectedToggle] ? 100 : 1;

            return { ...newToggles, playTime };
        });
    };

    const handleSlideChange = (swiper) => {
        console.log('Slide changed to:', swiper.activeIndex);
    };

    const disableInputs =
        !toggleState.path;


    const goToActivity = (long, lat) => {
        console.log(long, lat)
        setActivityCoords({ long: long, lat: lat })
    }
    return (
        <div className="rep-list">
            {reps.map((rep) => (
                <div
                    key={rep.userId}
                    className={`rep-card ${expandedRep === rep.userId ? 'expanded' : ''}`}
                >
                    <div className="rep-header" onClick={() => handleCardClick(rep.userId)}>
                        <img src={rep.profile} alt={rep.name} className="rep-profile-image" />
                        <h2>{rep.name}</h2>
                        <Switch
                            className="rep-switch"
                            checked={expandedRep === rep.userId}
                            onChange={() => handleCardClick(rep.userId)}
                        />
                    </div>
                    {expandedRep === rep.userId && (
                        <div className="rep-content">
                            {(toggleState.path) &&
                                <div>
                                    <div className="slider-container">
                                        <Button
                                            id="PlayPauseBtn"
                                            variant="outlined"
                                        >
                                            Start
                                        </Button>
                                        <input
                                            id="seeker"
                                            type="range"
                                            min="0"
                                            max="1"
                                            step="0.000000000000000001"
                                        //value={distanceRef}
                                        //onChange={handleDistanceChange}
                                        />
                                        <Button
                                            id='speedBtn'
                                            variant="outlined"
                                            type='button'
                                            value={speed} // The actual numeric value for use in other components
                                            onClick={handleSpeedChange}
                                        >{speedText[speedIndex]}</Button>

                                    </div>
                                    <div className="color-picker-container">
                                        <div style={{ display: 'flex', gap: "0.5rem" }}>

                                            <Button
                                                style={{ padding: '0' }}
                                                type='button'
                                                id="zoomInBtn"
                                                variant="outlined"
                                                value={zoom}
                                                onClick={() => handleZoomChange("+")}
                                            >
                                                <ZoomIn style={{ width: '25px', height: '25px' }} />
                                            </Button>
                                            <Button
                                                style={{ padding: '0' }}
                                                type='button'
                                                id="zoomOutBtn"
                                                variant="outlined"
                                                value={zoom}
                                                onClick={() => handleZoomChange("-")}
                                            >
                                                <ZoomOut style={{ width: '25px', height: '25px' }} />
                                            </Button>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: "center", gap: "0.5rem" }}>

                                            <input
                                                type="color"
                                                className="color-input"
                                                value={colors[rep.userId]}
                                                disabled={disableInputs}
                                                onChange={(e) => handleColorChange(rep.userId, e.target.value)}
                                            />
                                            <Button
                                                variant="contained"
                                                disabled={disableInputs}
                                                style={{ color: 'white' }}
                                                onClick={() => saveUserPathColor(rep.userId)}
                                            >
                                                Set Color
                                            </Button>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: "0.5rem", justifyContent: "center", alignItems: "center" }}>
                                            <label>Panning</label>
                                            <Switch
                                                id='panSwitch'
                                                checked={isPanning}
                                                value={panValue}
                                                onChange={() => handlePanSwitch()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="toggle-switches">
                                <div>
                                    <span>Show Last Known Location</span>
                                    <Switch
                                        checked={toggleState.currentLocation}
                                        onChange={() => handleToggleChange('currentLocation')}
                                    />
                                </div>
                                <div>
                                    <span>Show Path</span>
                                    <Switch
                                        checked={toggleState.path}
                                        onChange={() => handleToggleChange('path')}
                                    />
                                </div>
                                <div>
                                    <span>Show Activity History</span>
                                    <Switch
                                        checked={toggleState.activity}
                                        onChange={() => handleToggleChange('activity')}
                                    />
                                </div>
                            </div>
                            <>
                                {console.log(rep.locations.filter((location) => location.activityHistory !== null))}
                            </>
                            {toggleState.activity &&

                                <Swiper
                                    effect={'coverflow'}
                                    grabCursor={true}
                                    centeredSlides={true}
                                    slidesPerView={'auto'}
                                    coverflowEffect={{
                                        rotate: 50,
                                        stretch: 0,
                                        depth: 100,
                                        modifier: 1,
                                        slideShadows: true,
                                    }}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        dynamicBullets: true,
                                    }}
                                    navigation={true}
                                    modules={[EffectCoverflow, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    {rep.locations.filter(location => location.activityHistory !== null).map((location, index) => {
                                        const iconFrom = "data:image/png;base64," + (mapIcons.find(icon => icon.id === location.activityHistory.iconFromId)?.image || '');
                                        const iconTo = "data:image/png;base64," + (mapIcons.find(icon => icon.id === location.activityHistory.iconToId)?.image || '');

                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="activity-card">
                                                    <p>{new Date(location.timestamp).toLocaleString()}</p>
                                                    <div className="icon-container">
                                                        <img className="icon" src={iconFrom} alt="From Icon" />
                                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-icon">
                                                            <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <img className="icon" src={iconTo} alt="To Icon" />
                                                    </div>
                                                    <p className="go-to-activity" onClick={() => goToActivity(location.lon, location.lat)}>
                                                        Go To Activity
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            }
                            {toggleState.activity && rep.locations.filter(location => location.activityHistory !== null).length === 0 && (
                                <p className="no-activities">No activity histories available</p>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RepList;