import React from 'react'

const MissionBox = () => {

    const [tab, setTab] = React.useState(0)

    const content = [
        {
            title: "Our Mission",
            content: "Our mission at Lightning OS is to revolutionize connectivity across America by providing lightning-fast fiber internet solutions and digital home innovations. We strive to empower individuals and communities with seamless access to high-speed internet, fostering productivity, creativity, and connection in the digital age. Through our commitment to innovation, reliability, and customer satisfaction, we aim to be the leading provider of next-generation internet services, enhancing lives and driving progress nationwide."
        },
        {
            title: "Core Values",
            content: "Our core values are at the heart of everything we do at Lightning OS. We are committed to excellence, integrity, and innovation in all aspects of our business, from product development to customer service. We believe in fostering a culture of collaboration, respect, and diversity, where every team member is valued and empowered to succeed. Our dedication to sustainability, social responsibility, and community engagement drives us to make a positive impact on the world around us, creating a brighter future for all."
        },
        {
            title: "Our Inspiration",
            content: "Our inspiration comes from the limitless potential of technology to transform lives and communities for the better. We are driven by a vision of a world where everyone has access to high-speed internet, enabling them to learn, work, and connect in ways never before possible. By harnessing the power of innovation and digital connectivity, we seek to create a more inclusive, equitable, and sustainable future for all. Our passion for progress and our commitment to excellence inspire us to push the boundaries of what is possible, making a difference one connection at a time."
        }
    ]

    return (
        <div className='mission_box'>
            <div className='top_buttons'>
                <button className={`${tab==0 && 'active'}`} onClick={() => setTab(0)} >Our Mission</button>
                <button className={`${tab==1 && 'active'}`} onClick={() => setTab(1)} >Core Values</button>
                <button className={`${tab==2 && 'active'}`} onClick={() => setTab(2)} >Our Inspiration</button>
            </div>
            <div className='mission_box_body'>
                <h2>{content[tab].title}</h2>
                <p>{content[tab].content}</p>
            </div>
        </div>
    )
}

export default MissionBox