import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import SoftButton from '../../../components/SoftButton'
import SoftInput from '../../../components/SoftInput'
import { UserContext } from './../../../App'

import UsersApi from '../../../API/UsersApi'
import Sidebar from '../../../Layouts/Sidebar'
import EditUserReq from '../../../Requests/Users/EditUserReq'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
const Index = () => {
  const [userLoaded, setUserLoaded] = useState(false)
  const user = useContext(UserContext)
  const [editUser,setEditUser] = useState(user)
  const list = [
    {
      name: "First Name",
      key: "firstName",
      value: user.firstName
    },
    {
      name: "Last Name",
      key: "lastName",
      value: user.lastName
    },
    {
      name: "Email",
      key: "email",
      value: user.email
    },
    {
      name: "Phone",
      key: "phone",
      value: user.phone
    },
    {
      name: "Address",
      key: "address",
      value: user.address
    }
  ]
  
  const [edit, setEdit] = useState(true)
  
  const save = () => {
    Swal.fire({
      icon: 'info',
      title: 'Updating User',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    if(!getInput("firstName")){
      Swal.fire({
        icon: 'error',
        title: 'First Name is required',
        text: 'Please enter First Name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if(!getInput("lastName")){
      Swal.fire({
        icon: 'error',
        title: 'Last Name is required',
        text: 'Please enter Last Name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if(!getInput("email")){
      Swal.fire({
        icon: 'error',
        title: 'Email is required',
        text: 'Please enter Email',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if(!getInput("phone")){
      Swal.fire({
        icon: 'error',
        title: 'Phone is required',
        text: 'Please enter Phone',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if(!getInput("address")){
      Swal.fire({
        icon: 'error',
        title: 'Address is required',
        text: 'Please enter Address',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    let req = new EditUserReq();
    req.userId = user.id;
    req.firstName = getInput("firstName");
    req.lastName = getInput("lastName");
    req.phoneNumber = getInput("phone");
    req.address = getInput("address");
    UsersApi.EditUser(req).then((res) => {
        
        if(res?.status?.success)
        {
          Swal.fire({
            icon: 'success',
            title: 'User Updated Successfully',
            text: 'User Updated Successfully',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
          setEdit(true)
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'User Update Failed',
            text: res?.status?.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        }
    })
  }

  const handleInputChange = (key, value) => {
    setEditUser({ ...editUser, [key]: value })
  }

  const getInput = (key) => {
    return editUser[key]
  }

  return (
    <Layout>
      <div style={{
        borderRadius: "36px",
        border: "1px solid #E5E5E5",
        background: "#FFF",
        padding: "2rem",
      }}>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem"
        }}>
          <h1 className='text-3xl'>Profile</h1>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}>
            {
              list.map((item) => {
                return (
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem"
                  }}>
                    <p style={{
                      width: "100%"
                    }}>{item.name}</p>
                    <SoftInput value={getInput(item.key)} disabled={edit} onChange={(e) => handleInputChange(item.key, e.target.value)} />

                  </div>
                )
              })
            }
          </div>
          {edit ? <SoftButton onClick={() => { setEdit(false) }}>Edit</SoftButton> : <SoftButton onClick={() => {save()}}>Save</SoftButton>}
        </div>
      </div>
    </Layout>
    // <Sidebar menuItems={TeamAdminMenuItems} />
  )
}

export default Index
