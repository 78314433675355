import React from 'react'

const VideoReview = ({review}) => {
  return (
    <div className='video_review'>
        <video controls>
            <source src={review.video.url+"#t=0.001"} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
  )
}

export default VideoReview