
class SearchCommissionTrackersReq {
    constructor() {
        this.id = null;
        this.userId = null;
        this.amountFrom = null;
        this.amountTo = null;
        this.commissionPlanId = null;
        this.accountingEntryId = null;
        this.type = null;
        this.paid = true;
        this.saleId = null;
        this.timeKeyFrom = null;
        this.timeKeyTo = null;
        this.timeKeyType = null;
        this.timeKeyVolume = null;
        this.commissionScale = null;
        this.weekRange = null;
        this.recruitId = null;
        this.debtSheetEntryId = null;
        this.pagingParams = {
            pageNumber: null,
            pageSize: null
        };
    }
}

export default SearchCommissionTrackersReq;