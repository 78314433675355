import React,{useState} from 'react'
import { IoMdCloseCircle } from 'react-icons/io';
import authorized from './../../Offer/components/authorized.png'
import authorizedFastwyre from './../../Offer/components/authorizedFastwyre.png'
import StringIntReq from '../../../../Requests/StringIntReq';
import Swal from 'sweetalert2';
import FiberHouseApi from '../../../../API/FiberHouseApi';
const RequestCall = ({offer,open,setOpen,color}) => {

    const [lightConsent, setLightConsent] = useState(false)
    const [timeSlot, setTimeSlot] = useState('9:00 AM - 11:00 AM')

    const setAppointmentTime = async () => {
        
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Setting Appointment Time",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!lightConsent) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Consent is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        
        console.log('Setting Appointment Time')
        const req = new StringIntReq()
        req.int = offer?.leadId
        req.string = timeSlot
        const res = await FiberHouseApi.SetAppointmentTimeForRequestCall(req)
        
        if(res.status.success){
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Expect a call from a representative in the selected time slot",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setOpen(false)
        }
        else{
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

  return (
    <div className='custom_modal'>
            <div style={{ width: "100%" }}>
                <IoMdCloseCircle style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => {
                    setOpen(false)
                }} size={30} color={color} />
            </div>
            {
                offer?.fiberCompanyId === 9 && <img alt="Logo" src={authorizedFastwyre} className='logo' />
            }
            {
                offer?.fiberCompanyId === 2 && <img alt="Logo" src={authorized} className='logo' />
            }
            <div className='inputs'>
               
                <p className='font-bold text-center'>Select the Time Slot in which you would like to receive a call from Rep.</p>
                <select onChange={(e)=>{
                    setTimeSlot(e.target.value)
                }} >
                    <option value="9:00 AM - 11:00 AM" >9:00 AM - 11:00 AM</option>
                    <option value="11:00 AM - 1:00 PM">11:00 AM - 1:00 PM</option>
                    <option value="1:00 PM - 3:00 PM">1:00 PM - 3:00 PM</option>
                    <option value="3:00 PM - 5:00 PM">3:00 PM - 5:00 PM</option>
                    <option value="5:00 PM - 7:00 PM">5:00 PM - 7:00 PM</option>
                </select>
                <div className='consent'>
                    <input type="checkbox"
                        checked={lightConsent}
                        onChange={(e) => {
                            setLightConsent(e.target.checked)
                        }}
                    />
                    <label>I agree to receive email and/or sms messages from Lightning OS LLC.</label>
                </div>
                <button onClick={setAppointmentTime} style={{ backgroundColor: color }}>Request a Call</button>
            </div>
        </div>
  )
}

export default RequestCall