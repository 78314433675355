class EditShopItemReq{
    constructor()
    {
        this.id = null;
        this.name = null;
        this.description = null;
        this.price = null;
    }
}

export default EditShopItemReq