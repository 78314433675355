import React from 'react'
import './index.scss'
import ProfileNameAndBio from './components/ProfileNameAndBio'
import UserDataSettings from './components/UserDataSettings'
import ProfileImageVideo from './components/ProfileImageVideo'
import PrivacySettings from './components/PrivacySettings'
import Layout from '../../../Layouts/Layout'
const Index = () => {
  

  return (
    <Layout>
      <div className='editAccountDetails'>
        <h1 className='editAccountDetails__title' >Edit Account Details</h1>
        <div className='innerContainer' >
          <div className='editAccountpart1' >
            <ProfileNameAndBio />
            <UserDataSettings />
          </div>
          <div className='editAccountpart2' >
            <ProfileImageVideo />
            <PrivacySettings />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index