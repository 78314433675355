class FreeMonthFormReq {
  constructor() {
    this.Id = 0;
    this.LeadId = null;
    this.FidiumCustomerId = 0;
    this.CurrentInternetProvider = null;
    this.CurrentInternetSpeed = null;
    this.CurrentInternetPrice = null;
    this.SpeedTestBeforeImage = null;
    this.IsBundle = null;
    this.BundleDetails = null;
    this.BundlePrice = null;
    this.OtherInterests = null;
    this.ProductKnowledge = null;
    this.CommunicationSkills = null;
    this.KindAndProfessional = null;
    this.RecommendationLikeliness = null;
  }
}
export default FreeMonthFormReq;