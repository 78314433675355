class CreateOrEditOfferReq {
    constructor() {
        this.id = null;
        this.fidiumCustomerId = null;
        this.leadId = null;
        this.salesRepId = null;
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phone = null;
        this.fiberType = null;
        this.offerType = null;
        this.offerStatus = null;
        this.freeOffer = null;
        this.freeInstall = null;
        this.offerGuid = null;

        this.pagingParams = {
            "pageNumber": null,
            "pageSize": null
        };
    }
}

export default CreateOrEditOfferReq;