/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import SellingCardApi from '../../../API/SellingCardApi'
import SearchSellingCardReq from '../../../Requests/SellingCard/SearchSellingCardReq'
import SellingCard from './components/SellingCard'
import "./index.scss"
import { UserContext } from '../../../App'
import Modal from '../../../components/Modal'
import VersatileLoader from '../../../components/VersatileLoader'
import CreateThumbnail from '../../SuperAdmin/CreateSellingCard/components/CreateThumbnail'

// Import Swiper core and required modules
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import Layout from '../../../Layouts/Layout'

const Index = () => {
    const [name, setName] = useState("");
    // const [organizationId, setOrganizationId] = useState(null);
    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [fetchingMore, setFetchingMore] = useState(false);
    const user = useContext(UserContext);

    function onSearch() {
        const req = new SearchSellingCardReq();
        setLoading(true);
        if (name.length > 0) {
            req.name = name;
        }
        // if (organizationId) {
        //     req.salesOrgId = organizationId;
        // }
        if (user.userType === "SalesRep" || user.userType === "SalesOrgAdmin") {
            req.mySalesOrgId = user.salesOrgId;
        }
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = 10;
        console.log(req)
        SellingCardApi.SearchSellingCard(req).then(
            (res) => {
                console.log(res)
                if (res?.status?.success) {
                    setList(currentPage === 1 ? res?.data?.list : [...list, ...res?.data?.list]);
                    setLoading(false);
                    setFetchingMore(false);
                    setTotalPages(res.data.totalPages);
                }
            }
        );
    }

    useEffect(() => {
        onSearch();
    }, [currentPage, reload]);

    useEffect(() => {
        setCurrentPage(1);
    }, [reload]);

    return (
        <Layout>
            <Modal setOpen={setOpen} open={open}>
                <CreateThumbnail />
            </Modal>
            <div className='manageSellingCardsContainer'>
                <div className='manageSellingCardsSearchFilter'>
                    <h1>{user?.userType === "SuperAdmin" && "Manage"} Training Modules</h1>
                    <div className='manageSellingCardsSearchFilterInputs'>
                        <input placeholder="Enter the Name" onChange={(e) => { setName(e.target.value) }} value={name} />
                        <button onClick={onSearch}>Search</button>
                    </div>
                    {
                        (user?.userType === "SuperAdmin" || user?.userType === "SalesOrgAdmin") &&
                        <button onClick={() => {
                            setOpen(true)
                        }}>Create Training Module</button>
                    }
                </div>
                {loading && currentPage === 1 ?
                    <div className='spinner'> <VersatileLoader size='large' color='var(--color-primary)' /></div>
                    :
                    <div className='sellingCards'>
                        <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={(swiper) => {
                                if (swiper.isEnd && currentPage < totalPages) {
                                    setCurrentPage(currentPage + 1);
                                    setFetchingMore(true);
                                }
                            }}
                            className='swiper-container'
                        >
                            {
                                list?.length > 0 ?
                                    list.map((sellingCard, index) => (
                                        <SwiperSlide key={index} className='swiper-slide'>
                                            <SellingCard reload={reload} setReload={setReload} sellingCard={sellingCard} />
                                        </SwiperSlide>
                                    ))
                                    :
                                    <SwiperSlide className='swiper-slide'>
                                        <div className='notFound'>
                                            <h3>No Training Modules Found</h3>
                                        </div>
                                    </SwiperSlide>
                            }
                            {fetchingMore && <SwiperSlide className='swiper-slide'>
                                <div className='spinner'>
                                    <VersatileLoader size='large' color='var(--color-primary)' />
                                </div>
                            </SwiperSlide>}
                        </Swiper>
                    </div>
                }
            </div>
        </Layout>
    );
}

export default Index;
