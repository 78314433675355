class SearchOrgReq {
    constructor() {
        this.name = null;
        this.parentOrgId = null;
        this.pagingParams = {
            "pageNumber":1,
            "pageSize":1000
        }
    }
}
export default SearchOrgReq;