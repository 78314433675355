import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ShopItemsApi from '../../../API/ShopItemsApi';
import { UserContext } from '../../../App';
import AddFilesToSellingCard from '../../../Requests/SellingCard/AddFilesToSellingCard';
import EditShopItemReq from '../../../Requests/ShopItems/EditShopItemReq';
// import ShopCategorySelector from '../../../components/ShopCategorySelector';

import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import Swal from 'sweetalert2';
import CreateFile from './../../../Requests/CloudFile/CreateFileReq';
import RemoveFileReq from './../../../Requests/CloudFile/RemoveFileReq';

import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { FilePond, registerPlugin } from 'react-filepond';
// import Sidebar from '../../../Layouts/Sidebar';
import './index.scss';
// import MobileLayout from '../../../Layouts/MobileLayout';
import Layout from '../../../Layouts/Layout';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
registerPlugin(FilePondPluginFilePoster)




const Index = () => {
  const location = useLocation();
  // const [editedCartegoryId, setEditedCategoryId] = useState()
  const [editedName, setEditedName] = useState(location.state.name);
  const [editedPrice, setEditedPrice] = useState(location.state.price);
  const [editedDescription, setEditedDescription] = useState(location.state.description);
  const [editedFiles, setEditedFiles] = useState([])
  const [filesToBeRemoved, setFilesToBeRemoved] = useState([]);
  const [filesToBeAdded, setFilesToBeAdded] = useState([]);
  const user = useContext(UserContext)
  const storage = getStorage()
  async function editShopItem() {
    Swal.fire({
      icon: 'info',
      title: 'Editing Selling Card',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    if (!editedName) {
      Swal.fire({
        icon: 'error',
        title: 'Name is required',
        text: 'Please enter Name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (!editedDescription) {
      Swal.fire({
        icon: 'error',
        title: 'description is required',
        text: 'Please enter description',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    if (!editedPrice) {
      Swal.fire({
        icon: 'error',
        title: 'Price is required',
        text: 'Please enter Price',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (isNaN(editedPrice) || editedPrice <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Price',
        text: 'Please enter a valid Price',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }
    const req = new EditShopItemReq();
    req.id = location.state.id;
    req.name = editedName;
    req.description = editedDescription;
    req.price = editedPrice;
    ShopItemsApi.EditShopItem(req).then(
     async (res) => {
        console.log(res);
        if (res?.status?.success) {

          filesToBeRemoved.map(
            (f) => {
              const fileRef = ref(storage, f.path);
              deleteObject(fileRef)
                .then(() => {
                  const req = new RemoveFileReq()
                  req.int = f.id;
                  ShopItemsApi.RemoveFile(req).then(
                    (res) => {
                      if (res?.status?.success) {
                        console.log("success")
                      }
                    }
                  )
                })
                .catch((error) => {
                  console.error('An error occurred!', error);
                 
                });

            }
          )
          const request = new AddFilesToSellingCard();
          let downloadUrls = [];
          let filePaths = [];
          request.id = location.state.id
          const uploadAndGetData = async (file, type) => {
            // Generate a unique ID 
            const uniqueId = uuidv4();

            const storageRef = ref(storage, `/ShopItems/${uniqueId}`);
            await uploadBytes(storageRef, file);
            // Get download URL and push to downloadURLs array
            const downloadURL = await getDownloadURL(storageRef);
            downloadUrls.push(downloadURL);
            console.log(downloadURL);
            filePaths.push(`/ShopItems/${uniqueId}`)
          };

          for (let index = 0; index < filesToBeAdded.length; index++) {
              await uploadAndGetData(filesToBeAdded[index], "image");
              const createFile = new CreateFile();
              createFile.name = filesToBeAdded[index].name;
              createFile.extension = createFile.name.split('.').pop();
              createFile.url = downloadUrls[0];
              createFile.path = filePaths[0];
              createFile.fileSize = filesToBeAdded[index].size.toString();
              createFile.userId = user.id;
              createFile.sellingCardId = location.state.id;
              createFile.caption = "123";
              createFile.fileType = filesToBeAdded[index].type
              request.files.push(createFile);
            
          }

          ShopItemsApi.AddFile(request).then(
            (response)=>{
              if(response.status.success)
              {
                console.log(response.data)
                Swal.fire({
                  icon: 'success',
                  title: 'Shop Item Edited',
                  text: "Shop Item has been Edited",
                  // allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: false,
      
                });
              }else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: res?.status?.message,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: true,
                  didOpen: () => {
                    Swal.hideLoading()
                  }
                });
              }
            }
          ) 
          
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res?.status?.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          });
        }

      }
    )
  }

  useEffect(
    () => {
      console.log(location.state)

      setEditedName(location.state.name);
      setEditedDescription(location.state.description);
      setEditedPrice(location.state.price);


      const newFiles = location.state.files.map(f => ({
        source: f.url,
        options: {
          type: 'local',
          file: {
            path: f.path,
            name: f.name,
            type: f.fileType,
            size: f.fileSize,
            id: f.id
          },
          metadata: {
            poster: f.url,
          },
        },

      }))
      setEditedFiles(newFiles);
    }, []
  )

  const removeFile = (path, id) => {
    setFilesToBeRemoved(
      (prev) => [...prev, { path: path, id: id }]
    )

  }

  const addFile = (file) => {
    if(!file.file.id)
    {
      console.log("Add FIle",file.file)
    setFilesToBeAdded(
      (prev) => [...prev, file.file]
    )
    }
    
  }
  useEffect(() => {
    console.log(filesToBeAdded)
  }, [filesToBeAdded])

  useEffect(
    ()=>{
      console.log(filesToBeRemoved);
    },[filesToBeRemoved]
  )
  return (
    <Layout >
      <div className='shopItemDetailsContainer' >

          <h3 >Edit Form</h3>

          <div className='inputs' >
            <input placeholder="Enter the Name" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
            <input placeholder="Enter the Description" value={editedDescription} onChange={(e) => setEditedDescription(e.target.value)} />
            <input placeholder="Enter the Price" value={editedPrice} onChange={(e) => setEditedPrice(e.target.value)} />
            {/* <ShopCategorySelector placeholder={location.state.description?location.state.description:"Select a Category"} setUser={setEditedCategoryId} /> */}
            <FilePond
              files={editedFiles}
              allowReorder={true}
              onremovefile={(error, file) => {
                if (file.file.path && file.file.id) {
                  removeFile(file.file.path, file.file.id);
                }
                else{
                  const filteredFiles = filesToBeAdded.filter(f => f.id !== file.file.id);
                  setFilesToBeAdded(filteredFiles);
                }
              }}
              onaddfile={(error, file) => addFile(file)}
              allowMultiple={true}
              onupdatefiles={setEditedFiles}
              onerror={error => console.log('Error', error)}
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              allowFilePoster={true}
            />
            <button onClick={() => { editShopItem() }}  >Update</button>
          </div>

      </div>
    </Layout>
  )
}

export default Index
