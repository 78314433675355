import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import EditLeadReq from '../../../../../Requests/FiberHouse/EditLeadReq'
import { useContext } from 'react'
import { UserContext } from '../../../../../App'
const LeadEdit = ({ lead, setEdit, searchFiberHouses }) => {
    const User = useContext(UserContext)
    const [leadName, setLeadName] = useState(lead?.name);
    const [leadEmail, setLeadEmail] = useState(lead?.email);
    const [leadPhone, setLeadPhone] = useState(lead?.phone);

    useEffect(() => {
        setLeadName(lead?.name);
        setLeadEmail(lead?.email);
        setLeadPhone(lead?.phone);
    }, [lead])

    const updateLead = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Updating Lead',
            timer: 1500,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        let req = new EditLeadReq();
        req.id = lead.id;
        req.name = leadName;
        req.email = leadEmail;
        req.phone = leadPhone;
        req.salesRepId = User.id;
        let res = await FiberHouseApi.EditLead(req);
        if (res?.status?.success) {
            searchFiberHouses();
            Swal.fire({
                icon: 'success',
                title: 'Success',
                timer: 1500,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setEdit(false);
        }
    }
    return (
        <div className="LeadEditDiv">
            <div className='HeaderDiv'>
                <h2>Edit Contact Info</h2>
            </div>
            <div className="InputsDiv">
                <div className="InputDiv">
                    <label htmlFor="name">Name</label>
                    <input type="text" value={leadName} onChange={(e) => setLeadName(e.target.value)} />
                </div>
                <div className="InputDiv">
                    <label htmlFor="email">Email</label>
                    <input type="text" value={leadEmail} onChange={(e) => setLeadEmail(e.target.value)} />
                </div>
                <div className="InputDiv">
                    <label htmlFor="phone">Phone</label>
                    <input type="text" value={leadPhone} onChange={(e) => setLeadPhone(e.target.value)} />
                </div>

            </div>
            <div className="Buttons">
                <button className="saveButton" onClick={() => updateLead()}>Save</button>
                <button className="cancelButton" onClick={() => setEdit(false)}>Cancel</button>
            </div>
        </div>
    )
}

export default LeadEdit