class EnvSettings {
    constructor() {

        if(window.location.href.includes('localhost')){
            this.env = 'development';
             this.url = 'https://localhost:7251/';
             //this.url = 'https://playitforward-001-site2.btempurl.com/';
        }
        else{
            this.env = 'Live';
            this.url = 'https://playitforward-001-site2.btempurl.com/';
        }
    }
}
const env = new EnvSettings();
export default env;