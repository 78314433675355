import EditAccountDetails from "./../Views/Shared/EditAccountDetails"
import CreateCustomer from "./../Views/Customer/CreateCustomer"
import SignUpWithReferral from '../Views/Public/SignUpWithReferral/SignUpWithReferral';
import ChangeReferralCode from '../Views/Shared/AffiliateCode/ChangeReferralCode';
import ReferralCodeStatuses from '../Views/Shared/AffiliateCode/ReferralCodeStatuses';
import ReferralProgram from '../Views/Shared/AffiliateCode/ReferralProgram';
import Dashboard from '../Views/Shared/Dashboard';
import NoFidiumCustomerMenuItems from "../MenuItems/NoFidiumCustomerMenuItems";
const NoFidiumCustomerRoutes = [
    {
        name: "Dashboard",
        key: "Dashboard",
        route: "/",
        component: <Dashboard routes={NoFidiumCustomerMenuItems} />,
    },
  
    {
        name:"Create Customer",
        key:"CreateCustomer",
        route:"/BecomeACustomer",
        component:<CreateCustomer />
    },

    {
        name: "Change Referral Code",
        key: "ChangeReferralCode",
        route: "/change-referral-code",
        component: <ChangeReferralCode />
    },
    {
        name: "Referral Code Statuses",
        key: "ReferralCodeStatuses",
        route: "/referral-code-statuses",
        component: <ReferralCodeStatuses />
    },
    {
        name: "Referral Program",
        key: "Referral Program",
        route: "/referral-program",
        component: <ReferralProgram />
    },
    {
        name: "SignUpWithReferral",
        key: "SignUpWithReferral",
        route: "/signup/:AffiliateCode",
        component: <SignUpWithReferral />,
    },
    {
        name: "SignUpWithReferral",
        key: "SignUpWithReferral",
        route: "/CreateNewReferral",
        component: <SignUpWithReferral />,
    },
    {
        name:"Edit Account Details",
        key:"EditAccountDetails",
        route:"/edit-account-details",
        component:<EditAccountDetails />
    }
]

export default NoFidiumCustomerRoutes