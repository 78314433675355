import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:'white',
    backgroundColor: 'var(--color-sidebar) !important',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: 'var(--color-icon) !important',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function MobileMenuMoreOptions({anchorEl,setAnchorEl,menuItems,handleClose}) {

const navigate = useNavigate();


  return (
    <div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={ Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
            menuItems.map((item,index) => {
                return <MenuItem sx={{
                    color:'var(--color-icon) !important',
                    marginBottom:"5px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"flex-start",
                    gap:"10px",
                    '&:hover':{
                        backgroundColor: 'rgba(255,255,255,0.1)'
                    }
                
                }} key={index} onClick={()=>{
                    item.onClick?item.onClick():navigate(item.route)
                }} disableRipple>
                {/* {item?.icon('var(--color-icon)')} */}
                {item.name}
                </MenuItem>
            })
        }
      </StyledMenu>
    </div>
  );
}