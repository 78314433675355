import React from 'react'
import { ImFire } from 'react-icons/im'

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const SuccessStories = () => {

    const [currentIndex, setCurrentIndex] = React.useState(0)

    const stories = [
        {
            text: "Being part of the Lightning OS team and seeing the difference our service makes is incredibly rewarding. This program was a highlight of my career. The skills I learned and the connections I made were invaluable.",
            name: "Jason M. (Fiber Specialist)",
            image:'/images/summer/success_story_1.png'
        },
        {
            text:"Working for Lightning OS, LLC as a fiber sales rep is an absolute blast! The money? It's great, with solid commissions and bonuses that make hitting targets feel like winning the lottery. The team support is top-notch, with colleagues who feel more like family, always there to lend a hand or share a tip. And the culture? It's vibrant and infectious, driven by a passion for excellence and a commitment to making a positive impact. Lightning OS isn't just a workplace; it's a place where you'll thrive and feel positively electrified!",
            name:"Jessica M. (Fiber Specialist)",
            image:'/images/summer/success_story_2.png'
        },
        {
            text:"Just a few days ago, I was a high school grad with zero sales experience. Fast forward to now, and I'm crushing it at Lightning OS, LLC! I took a big leap, drove 12 hours to join the team, and it's been the best decision ever. The support from my colleagues is unreal, and the vibe here is electric! I'm beyond thankful for this opportunity. Lightning OS, you're awesome! ⚡️",
            name:"Mark D. (Fiber Specialist)",
            image:'/images/summer/success_story_3.png'
        },
        {
            text:"Fresh out of college, I never imagined myself doing door-to-door sales. But Lightning OS, LLC changed all that! Thanks to a tip from a former teacher-turned-colleague, I took the plunge—and boy, am I glad I did! In just three weeks, I've pocketed over $5300.The support and training here are top-notch, making even a newbie like me feel like a pro. Lightning OS, you're the real deal! 💫",
            name:"Grant G. (Fiber Specialist)",
            image:'/images/summer/success_story_4.png'
        }
    ]

    return (
        <div style={{
            // backgroundImage: "url('/images/summer/success_story.png')"
        }} className='success_stories'>
          
            <h1>Succes Stories</h1>
            {
                    <img className='back' src={stories[currentIndex].image} alt='back' />
            }
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={false}
                slidesPerView={1}
                coverflowEffect={{
                    rotate: 1,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false
                }}
                spaceBetween={100}
                navigation={true}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"
                style={{
                    WebkitOverflowScrolling: 'touch',
                    WebkitTransformStyle: 'preserve-3d',
                    WebkitPerspective: '1000px',
                    WebkitTransform: 'translate3d(0,0,0)',
                }}
                onSlideChange={(slider) => {
                    setCurrentIndex(slider.activeIndex)
                }}
            >
                {/* <SwiperSlide >
                    <div  className='story'>
                        <div className='text'>
                            <p>"Being part of the Lightning OS team <br/>and seeing the difference our service makes <br/>is incredibly rewarding. This program was <br/>a highlight of my career. The skills I <br/>learned and the connections I made <br/>were invaluable."</p>
                            <div className='salesrep'>
                                <ImFire size={30} color='#FC1C0F' />
                                <p>Jason M. (Fiber Specialist)</p>
                            </div>
                        </div>
                    </div>

                </SwiperSlide> */}
                {
                    stories.map((story, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className='story'>
                                    <div className='text'>
                                        <p>{story.text}</p>
                                        <div className='salesrep'>
                                            <ImFire size={30} color='#FC1C0F' />
                                            <p>{story.name}</p>
                                        </div>
                                    </div>
                                </div>
                            
                            </SwiperSlide>
                        )
                    })
                }
       
            </Swiper>
        </div>
    )
}

export default SuccessStories