
class SearchAccoutingEntriesReq {
    constructor() {
        this.id = null;
        this.creatorId = null;
        this.name = null;
        this.description = null;
        this.amountFrom = null;
        this.amountTo = null;
        this.type = null;
        this.subjectId = null;
        this.isCost = true;
        this.commissionPlanId = null;
        this.commissionTrackerId = null;
        this.debtSheetEntryId = null;
        this.pagingParams = {
            pageNumber: null,
            pageSize: null
        };
    }
}

export default SearchAccoutingEntriesReq;