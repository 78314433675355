class DeleteFiberHouseReq{
    constructor(){
        this.string = null;
        this.int = null;
        this.pagingParams = {
          pageNumber: 1,
          pageSize: 12
        };
    }
}
export default DeleteFiberHouseReq;