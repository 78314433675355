import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Footer.scss'
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import footer_lightning from "./../images/footer_lightning.png"
import lightLogo from "./../images/light_footer_logo.png"
import footerLogo from "./../images/footer_logo.png"
import footerSideLightning from "./../images/footer_side_lightning.png"
import { useLocation } from 'react-router-dom';
import ContactButtons from '../../../Services/ContactButtons';
const Footer = ({ lightTheme }) => {

    const contactButtons = new ContactButtons();

    const navigate = useNavigate();
    const route = useLocation().pathname.split("/").slice(1);
    return (
        <>
            <div className='footer_logo'>
                <img src={footerSideLightning} alt='footer_side_lightning' className='side_light' />
                <img src={lightTheme ? lightLogo : footerLogo} alt='footer_logo' />
            </div>
            <div className='footer_container'>
                <div className='footer_item'>
                    <h2>Contact</h2>
                    <div onClick={() => contactButtons.email("info@lightningos.world")} className='contact_item'>
                        <MdEmail color='#51BAFE' />
                        <p>info@lightningos.world</p>
                    </div>
                    <div onClick={() => contactButtons.call("+12818689760")} className='contact_item'>
                        <FaPhoneAlt color='#51BAFE' />
                        <p>+1 281 868 9760</p>
                    </div>
                </div>
                <div className='footer_item'>
                    <h2>Pages</h2>
                    <Link className={`link ${'' == route[0] && 'active'}`} to='/'>Home</Link>
                    <Link className={`link ${'services' == route[0] && 'active'}`} to='/services'>Our Services</Link>
                    <Link className={`link ${'referral-program' == route[0] && 'active'}`} to='/referral-program'>Referral Program</Link>
                    <Link className={`link ${'fiber-technology' == route[0] && 'active'}`} to='/fiber-technology'>Fiber Technology</Link>
                    <Link className={`link ${'opportunity' == route[0] && 'active'}`} to='/opportunity'>Opportunity</Link>
                </div>
                <div className='footer_item'>
                    <h2>Join Now</h2>
                    <Link className={`link ${'signup' == route[0] && 'active'}`} to='/signup'>Sign Up</Link>
                    <Link className={`link ${'signin' == route[0] && 'active'}`} to='/signin'>Sign In</Link>
                </div>
                <div className='footer_item'>
                    <h2>Product</h2>
                    <Link className='link' onClick={() => {
                        window.leadConnector.chatWidget.openWidget()
                    }} >Live Chat</Link>
                    <button style={{
                        padding:"0rem",
                        width:"fit-content"
                    }} className='link' onClick={() => {

                        const element = document.getElementById('reviews_customer');
                        if (element) {
                            element.scrollIntoView({ behavior: 'instant' });
                        }
                        else {
                            // console.log("not found")
                            navigate('/', {
                                state: {
                                    redirect: 'reviews_customer'
                                }
                            })
                        }
                    }}  >Reviews</button>
                </div>
                <div className='footer_item'>
                    <h2>Find Fiber In Your Area</h2>
                    <p>Enter Your Area Name/Address</p>
                    <div className='area_input'>
                        <button onClick={() => {

                            const element = document.getElementById('check_availability');
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                            }
                            else {
                                
                                navigate('/', {
                                    state: {
                                        redirect: "check_availability"
                                    }
                                })
                            }
                        }}  >CHECK AVAILABILITY</button>
                    </div>
                </div>
            </div>
            <p style={{
                textAlign: 'center',
                color: '#FFFFFF',
                fontWeight: 'medium',
                fontSize: '1rem',
                marginTop: '2rem'
            }}>Copyright 2024 Lightning OS, all rights reserved</p>
            <div className='footer_image'>
                <img src={footer_lightning} alt='footer_lightning' />
            </div>
        </>
    )
}

export default Footer