class GetOfferByGuidReq {
    constructor(){
        this.string = "";
        this.int = "";
        this.pagingParams = {
            "pageNumber": null,
            "pageSize": null
        };
    }
}

export default GetOfferByGuidReq;