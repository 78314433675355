import React, { useEffect, useState } from 'react'
import { HiChevronUpDown } from 'react-icons/hi2'
import { Close } from '@mui/icons-material'

const options = [
  { value: "red", label: "Red", color: "bg-red-500" },
  { value: "blue", label: "Blue", color: "bg-blue-500" },
  { value: "green", label: "Green", color: "bg-green-500" },
  { value: "yellow", label: "Yellow", color: "bg-yellow-500" },
  { value: "purple", label: "Purple", color: "bg-purple-500" },
  { value: "pink", label: "Pink", color: "bg-pink-500" },
  { value: "indigo", label: "Indigo", color: "bg-indigo-500" },
  { value: "teal", label: "Teal", color: "bg-teal-500" },
]

export function MultiColorSelector({options,save,header,value,id}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState(value || [])
  const [searchTerm, setSearchTerm] = useState('')

  const toggleOption = (value) => {
    let newSelectedValues = [...selectedValues]
    if(newSelectedValues.includes(value)){
      newSelectedValues = newSelectedValues.filter(item => item !== value)
    }
    else{
      newSelectedValues.push(value)
    }

    setSelectedValues(newSelectedValues)

    // join the selected values with a \n character
    save(newSelectedValues.join('\n'),id,header)

  }


  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const selectedOptions = options.filter(option => selectedValues.includes(option.value))

  return (
    <div className="relative w-full max-w-md">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center flex-wrap gap-2 w-full min-h-[2.5rem] px-3 py-2 text-sm bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {selectedOptions.length > 0 ? (
          selectedOptions.map((option) => (
            <span
              key={option.value}
              className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium text-white ${option.color}`}
            >
              {option.label}
              <button
                type="button"
                className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
                onClick={(e) => {
                  e.stopPropagation()
                  toggleOption(option.value)
                }}
              >
                <span className="sr-only">Remove {option.label}</span>
                <Close className="h-3 w-3" aria-hidden="true" />
              </button>
            </span>
          ))
        ) : (
          <span className="text-gray-500">Select options...</span>
        )}
        <HiChevronUpDown className="w-5 h-5 ml-auto text-gray-400" aria-hidden="true" />
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
          {/* <div className="px-3 py-2">
            <input
              type="text"
              className="w-full px-3 py-2 text-sm leading-5 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              placeholder="Search options..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div> */}

          <ul className="py-1 overflow-auto text-base max-h-60 focus:outline-none sm:text-sm" role="listbox">
            {filteredOptions.length === 0 ? (
              <li className="px-3 py-2 text-sm text-gray-900">No options found.</li>
            ) : (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  className={`relative px-3 py-2 cursor-pointer select-none ${
                    selectedValues.includes(option.value) ? 'bg-indigo-100' : 'hover:bg-gray-100'
                  }`}
                  onClick={() => toggleOption(option.value)}
                >
                  <div className="flex items-center">
                    <span className={`flex-shrink-0 inline-block h-4 w-4 rounded-full ${option.color}`} />
                    <span className={`ml-3 block truncate ${selectedValues.includes(option.value) ? 'font-semibold' : 'font-normal'}`}>
                      {option.label}
                    </span>
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  )
}