import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import EditFiberPlanReq from '../../../Requests/FiberPlan/EditFiberPlanReq'
import FiberPlanApi from '../../../API/FiberPlanApi'
import Sidebar from '../../../Layouts/Sidebar'
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { IoArrowBack } from 'react-icons/io5'
const Index = () => {
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [fiberCompanyId, setFiberCompanyId] = useState('')
    const [speed, setSpeed] = useState('')
    const [price, setPrice] = useState('')
    const location = useLocation()
    const navigate = useNavigate();
    useEffect(
        ()=>{
            if(location.state){
                setId(location.state.id)
                setName(location.state.name)
                setDescription(location.state.description)
                setFiberCompanyId(location.state.fiberCompanyId)
                setSpeed(location.state.speed)
                setPrice(location.state.price)
            }
        },[]
    )

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
    }

    const handleSpeedChange = (e) => {
        setSpeed(e.target.value)
    }

    const handlePriceChange = (e) => {
        setPrice(e.target.value)
    }

    const updateCompany = () => {
        // Handle update company logic here
        Swal.fire({
            icon: 'info',
            title: 'Updating Fiber Plan',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (!name) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a company name',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!description) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a description',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!fiberCompanyId) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a fiber company',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!speed) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a speed',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!price) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a price',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        const req = new EditFiberPlanReq()
        req.id = id
        req.name = name
        req.description = description
        req.fiberCompanyId = fiberCompanyId
        req.speed = parseInt(speed)
        req.price = parseInt(price)

        FiberPlanApi.EditFiberPlan(req).then(res => {
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Fiber Company updated successfully',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            }
        })
        
    }

    return (
        <Layout>
              <IoArrowBack color='black' size={30} style={{ cursor: 'pointer',marginLeft:"1rem" }} onClick={() => navigate(-1)} />
            <div className='editFiberPlanContainer' >
                <div className='form' >
                    <h3 className='formHeading' >Edit Fiber Plan</h3>
                    <input placeholder="Enter the Name" value={name} onChange={handleNameChange} />
                    <input placeholder="Enter the Description" value={description} onChange={handleDescriptionChange} />
                    <input placeholder="Enter the Speed" value={speed} onChange={handleSpeedChange} />
                    <input placeholder="Enter the Price" value={price} onChange={handlePriceChange} />
                    <button onClick={updateCompany} >Save</button>
                </div>
            </div>
        </Layout>
    )
}

export default Index