class CreateBugTicketReq{
    constructor(){
        this.title = "";
        this.userId = "";
        this.description = "";
        this.image = null;

    }
}

export default CreateBugTicketReq;