import React from 'react'
import './index.scss'
import Layout from '../../../Layouts/Layout'
const Index = () => {
    return (
        <Layout>
            <div className='comissionPlanMakerContainer'>
                <h1 >Customer Plan Details</h1>
                <div className='inputContainer' >
                    <h1 >Comission Plan Details</h1>
                    <div className='inputs1'>
                        <div >
                            <label >Plan Name</label>
                            <input type="text" />
                        </div>
                        <div >
                            <label >Effective Date</label>
                            <input type="date" />
                        </div>
                        <div >
                            <label >Expiry Date</label>
                            <input type="date" />
                        </div>
                        <div >
                            <label >Description</label>
                            <input type="text" />
                        </div>
                        <div >
                            <label >Status</label>
                            <input type="text" />
                        </div>
                        <div >
                            <label >Comments</label>
                            <input type="text" />
                        </div>
                    </div>
                    <h1  >Comission Levels</h1>
                    <div className='inputs2'>
                        <div >
                            <h1 >Sales Level 1</h1>
                            <div >
                                <label>Commision  Type</label>
                                <input type="text" />
                            </div>
                            <div >
                                <label>Commision  Value</label>
                                <input type="text" />
                            </div>
                        </div>
                        <div >
                            <h1 >Sales Level 2</h1>
                            <div >
                                <label>Commision  Type</label>
                                <input type="text" />
                            </div>
                            <div >
                                <label>Commision  Value</label>
                                <input type="text" />
                            </div>
                        </div>
                        <div >
                            <h1>Sales Level 3</h1>
                            <div>
                                <label>Commision  Type</label>
                                <input type="text" />
                            </div>
                            <div>
                                <label>Commision  Value</label>
                                <input type="text" />
                            </div>
                        </div>

                    </div>
                    <div className='button-container'>
                        <button >Create Comission Plan</button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Index