class SendPrivateMessageReq {
    constructor() {

        this.content = null;
        this.conversationId = null;
        this.senderId = null;
        this.salesOrgId = 0;
        this.files = [
        ];
        this.voiceNote = null;
    }
}

export default SendPrivateMessageReq