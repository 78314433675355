class CreateSellingCardBlockReq {

    constructor() {
        this.sellingCardId = null;
        this.blockNumber = null;
        this.content = null;
        this.title = null;
        this.file = null;
    }
}

export default CreateSellingCardBlockReq;