import React, { useEffect } from 'react'
import Swal from 'sweetalert2'

import { MdOutlineContentCopy, MdOutlineEmail, MdOutlineTextsms, MdOutlineWhatsapp } from 'react-icons/md'
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq'
import FiberCompanyApi from '../../../API/FiberCompanyApi'
import { CircularProgress } from '@mui/material'

const ReviewForm = ({ lead, disabled }) => {


    const [loading, setLoading] = React.useState(true)
    const [fiberCompany, setFiberCompany] = React.useState(null)

    const getFiberCompanies = async () => {
        const req = new SearchFiberCompanyReq()
        req.id = lead.fidiumCustomer.fiberCompanyId
        const res = await FiberCompanyApi.SearchFiberCompany(req);
        if (res.status.success) {
            setFiberCompany(res.data.list[0])
            message(lead?.fidiumCustomer?.salesRep?.firstName + " " + lead?.fidiumCustomer.salesRep?.lastName, res.data.list[0].name)
            setLoading(false)
        }
    }

    const [copyText, setCopyText] = React.useState("")

    const message = (repName, companyName) => {
        setCopyText(`Its ${repName} with ${companyName}. It looks like our technician completed your free installation. I hope your experience has been great, and would appreciate any feedback. If you\'re happy with our service, please take 30 seconds to leave a 5-Star Review.\n \nThank you for helping us get the good word out about a better internet solution. We appreciate your business, and are immensely grateful for sharing your time. \n\nCLICK the link below to leave a review:\n\n https://lightningos.world/feedbackform/${lead.fidiumCustomer.cellPhone ? lead.fidiumCustomer.cellPhone : lead.phone}`)
    }

    const whtatsApp = () => {
        window.open(`https://wa.me/${lead.fidiumCustomer.cellPhone ? lead.fidiumCustomer.cellPhone : lead.phone}?text=${copyText}`, '_blank')
    }

    const email = () => {
        window.open(`mailto:${lead.fidiumCustomer.email}?subject=Feedback Form&body=${copyText}`, '_blank')
    }

    const text = () => {
        window.open(`sms:${lead.fidiumCustomer.cellPhone ? lead.fidiumCustomer.cellPhone : lead.phone}?&body=${copyText}`, '_blank')
    }

    const copy = () => {
        navigator.clipboard.writeText(copyText)
        Swal.fire({
            icon: 'success',
            title: 'Copied to clipboard',
            customClass: {
                container: 'custom-swal'
            },
            toast: true,
            position: 'top',
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    }

    useEffect(() => {
        getFiberCompanies()
    }, [])

    return (
        <div className='form'>
            {
                loading?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px'
                }}>
                    <CircularProgress size={60} sx={{color:"var(--primary-color)"}} />
                </div>
                :
                <>
                    <h2>Ask For Review</h2>
                    <textarea className='review_textarea' value={copyText} onChange={(e) => setCopyText(e.target.value)} rows={13} />
                    <div className='send_review_options'>
                        <p>Send the Review Link</p>
                        <div className='send_options'>
                            <MdOutlineEmail onClick={email} className='icon' color='#84A1C8' size={40} />
                            <MdOutlineTextsms onClick={text} className='icon' color='#84A1C8' size={40} />
                            <MdOutlineWhatsapp onClick={whtatsApp} className='icon' color='#84A1C8' size={40} />
                            <MdOutlineContentCopy onClick={copy} className='icon' color='#84A1C8' size={40} />
                        </div>
                    </div>
                </>
            }
        </div>
    )
}


export default ReviewForm

//  (INSTALL DATE != NULL || (OGDATE )  && (!updateTime && !updateDate)