/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, PaginationItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React, { useEffect } from 'react';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ComissionApi from '../../../API/ComissionApi';
import Datatable from '../../../components/Datatable';
import Layout from '../../../Layouts/Layout';
import GetCommissionPlanReq from '../../../Requests/ComissionPlan/GetCommissionPlanReq';
import StringIntReq from '../../../Requests/StringIntReq';
import './ManageCommissionPlan.scss';

const Index = () => {
    // search filter states
    const [name, setName] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const navigate = useNavigate();
    const [list, setList] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

    const search = async () => {
        setLoading(true);
        const req = new GetCommissionPlanReq();
        req.id = null;
        req.name = name;
        req.description = description;
        req.pagingParams.pageNumber = pageNumber;
        req.pagingParams.pageSize = 20;

        const res = await ComissionApi.SearchCommissionPlans(req);
        if (res?.status?.success) {
            res?.data?.list.forEach((item) => {
                item.details = <p onClick={() => { navigate(`/manage-commission-plan/details/${item.id}`) }} className='text-[#4e80df] underline cursor-pointer'>Details</p>;
                item.delete = <p onClick={() => { deleteCommissionPlan(item.id) }} className='text-[#ce4843] underline cursor-pointer'>Delete</p>;
                item.companyName = item.fiberCompany?.name
            });

            setList(res?.data?.list);
            console.log(res?.data?.list); // Log the data to check its structure
            setTotalPages(res.data.totalPages);
        }
        setLoading(false);
    };

    const deleteCommissionPlan = async (id) => {
        const selected = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (selected.isConfirmed) {
            Swal.fire({
                icon: "info",
                title: 'Deleting...',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            const req = new StringIntReq();
            req.int = id;
            const res = await ComissionApi.DeleteCommissionPlan(req);
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Commission Plan Deleted',
                    showConfirmButton: false,
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                search();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        }
    };

    useEffect(() => {
        search();
    }, [pageNumber]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    const headers = [
        { name: "ID", key: "id" },
        { name: "Name", key: "name" },
        { name: "Description", key: "description" },
        { name: "Fiber Company", key: "companyName"},
        { name: "Deal Saved Percentage", key: "dealSavedPercentage" },
        { name: "Deal Saved Pay", key: "dealSavedPay" },
        { name: "Details", key: "details" },
        { name: "Delete", key: "delete" }
    ];

    return (
        <Layout>
            <div className='manageLeadsContainer'>
                <div className='manageLeadsHeader'>
                    <h1 className='manageLeadsTitle'>Manage Commission Plans</h1>
                    <div className='searchFiltersInput'>
                        <input value={name} onKeyDown={handleKeyPress} onChange={(e) => setName(e.target.value)} placeholder='Name' />
                        <input value={description} onKeyDown={handleKeyPress} onChange={(e) => setDescription(e.target.value)} placeholder='Description' />
                    </div>
                    <button onClick={() => { setPageNumber(1); search() }}>Search</button>
                </div>
                {loading ? (
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <CircularProgress size={40} sx={{ color: "var(--primary-color)" }} />
                    </div>
                ) : (
                    <Datatable headers={headers} list={list} />
                )}
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    width: "100%"
                }}>
                    <Pagination
                        color='light'
                        renderItem={(item) => (
                            <PaginationItem
                                components={{
                                    next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                    first: (props) => <button {...props}>First</button>,
                                    previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                }}
                                {...item}
                                sx={{ color: "white" }}
                            />
                        )}
                        count={totalPages}
                        page={pageNumber}
                        onChange={(e, v) => { setPageNumber(v) }}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default Index;
