class SearchJobApplicationReq{
    constructor(){
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phone = null;
        this.address = null;
        this.message = null;
        this.notes  = null;
        this.status = null;
        this.searchString = null;
        this.pagingParams ={
            pageNumber: 1,
            pageSize: 10
        }
    }
}

export default SearchJobApplicationReq;