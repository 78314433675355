import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


export default function Index({open,setOpen,children,height,minHeight}) {

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth:screenWidth>1000 ?500:300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // height:"50vh",
    overflow:"auto",
    borderRadius:"10px",
    maxHeight:height ? height : "auto",
    minHeight :minHeight ? minHeight : "auto"
  };
  
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {children}
        </Box>
      </Modal>
    </div>
  );
}