import React from 'react'
import Swal from 'sweetalert2'
import CreateIssue from '../../../Requests/Issues/CreateIssue'
import IssueApi from '../../../API/IssueApi'
import { uploadFileToFirebase } from '../../../Services/DisplayFile'
import { v4 as uuidv4 } from 'uuid'
const IssueTicket = ({lead}) => {


    const [priority, setPriority] = React.useState('')
    const [issueDescription, setIssueDescription] = React.useState('')
    const [screenshot, setScreenshot] = React.useState('')
    const [issueType, setIssueType] = React.useState('')
    

    const submitIssue = async () => {
        Swal.fire({
            icon:"info",
            title:"Submitting Issue",
            text:"Please wait",
            showConfirmButton:false,
            allowOutsideClick:false,
            allowEscapeKey:false,
            didOpen: () =>{
                Swal.showLoading()
            }
        })

        if(issueDescription === ''){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Please enter issue description",
            })
            return
        }



        if(priority === ''){
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Please select priority level",
            })
            return
        }

        const req = new CreateIssue();
        req.description = issueDescription;
        req.priorityLevel = priority;
        req.saleId = lead.fidiumCustomer?.id
        req.issueType = issueType

        if(screenshot !== ''){
            const file = await uploadFileToFirebase(screenshot,`/Issues/${lead.fidiumCustomer?.id}/${uuidv4()}`);
            req.file = file;
        }


        const res = await IssueApi.CreateIssue(req);

        if(res?.status?.success){
            Swal.fire({
                icon:"success",
                title:"Success",
                text:"Issue submitted successfully",
                didOpen: () =>{
                    Swal.hideLoading()
                }
            })
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Error",
                text:"Failed to submit issue",
                didOpen: () =>{
                    Swal.hideLoading()
                }
            })
        }
    }

  return (
    <div className='form'>
        <h2>Issue Ticket</h2>

        <div className='input_div'>
            <label>Issue Type</label>
            <select onChange={(event)=>{
                setIssueType(event.target.value)
            }}>
                <option value={''}></option>
                <option value={'General'}>General</option>
                <option value={'Installation'}>Installation</option>
            </select>
        </div>
        <div className='input_div'>
            <label>Issue Description</label>
            <textarea placeholder='' value={issueDescription} onChange={(e) => setIssueDescription(e.target.value)} rows={3} />
        </div>
        <div className='input_div'>
            <label>Upload Screenshot/Document (Optional)</label>
            <input type='file'  onChange={(e) => setScreenshot(e.target.files[0])} />
        </div>
        <div className='input_div'>
            <label>Priority Level</label>
            <div className='priority'>
                <p  onClick={()=>setPriority("Low")} className={priority==="Low"?'active':''}>Low</p>
                <p  onClick={()=>setPriority("Medium")} className={priority==="Medium"?'active':''}>Medium</p>
                <p  onClick={()=>setPriority("High")} className={priority==="High"?'active':''}>High</p>
            </div>
        </div>
        <button onClick={()=>{
            submitIssue()
        }}>Submit</button>
    </div>
  )
}

export default IssueTicket