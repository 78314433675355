import React, { useState, useEffect, useContext } from 'react';
import QuickDate from '../../../components/QuickDate';
import { UserContext } from '../../../App';
import "./index.scss";
import OrganizationSelector from '../../../components/OrganizationSelector';
import UserSelector from '../../../components/UserSelector';
import OrgApi from '../../../API/OrgApi';
import Layout from '../../../Layouts/Layout';
import { CircularProgress } from '@mui/material';
import ReportReq from '../../../Requests/Users/SalesReportReq';
import { BsFillSendCheckFill } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
import { GrInstallOption } from 'react-icons/gr';
import { TbFreeRights } from 'react-icons/tb';
import { FaPercentage } from 'react-icons/fa';
import { MdSell } from 'react-icons/md';

const OffersReport = () => {
    const user = useContext(UserContext);
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [salesReps, setSalesReps] = useState([]);
    const [orgIds, setOrgIds] = useState([]);
    const [filterBy, setFilterBy] = useState('');

    const getOffersReportData = async () => {
        setLoading(true);
        let req = new ReportReq(); 
        req.salesOrgId = user.salesOrgId
        req.userId= user.id
        req.filterBy = filterBy
        req.salesRepIds= salesReps
        req.orgIds= orgIds

        console.log(req);
        if (user.userType === 'SuperAdmin' && user.salesOrgId != null && (salesReps.length === 0 && orgIds.length === 0)) {
            req.filterBy = 'Me';
        }

        if (user.userType === 'SalesRep') {
            req.filterBy = 'Me';
        }
        if(fromDate && toDate){
            req.fromDate = fromDate;
            req.toDate = toDate;
        }

        try {
            const res = await OrgApi.getOffersReportData(req);
            if (res?.status?.success) {
                setReportData(res.data);
                console.log(res.data);
            }
        } catch (err) {
            console.error('Error fetching offers report data:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user.userType === 'SalesRep' || user.userType === 'SalesOrgAdmin') {
            setFilterBy('Me');
        }
    }, []);
    useEffect(() => {
        getOffersReportData();
    }, [filterBy]);

    return (
        <Layout>
            <div className="offers-report-container">
                <div className="filter-container">
                    {user.userType === "SalesOrgAdmin" &&
                        <div className="toggle-switch">
                            <label>Viewing: {filterBy}</label>
                            <input
                                type="checkbox"
                                checked={filterBy === 'Team'}
                                onChange={() => setFilterBy(filterBy === 'Me' ? 'Team' : 'Me')}
                            />
                        </div>
                    }
                    
                    <QuickDate setFrom={setFromDate} setTo={setToDate} />
                    {user.userType === "SuperAdmin" &&
                        <div className="selector_con">
                            <OrganizationSelector multiple={true} setUser={setOrgIds} placeholder='Select Organizations' />
                            <UserSelector SalesOrgId={user.salesOrgId} setUser={setSalesReps} placeholder='Select SalesReps' />
                        </div>
                    }
                    <button onClick={getOffersReportData}>Fetch Report</button>
                </div>

                <div className="report-summary">
                    {loading ? (
                        <CircularProgress />
                    ) : reportData ? (
                        <div className="summary-cards">
                            <div className="summary-card">
                                <div className="card-value">{reportData.conversionPercent}%</div>
                                <MdSell className='img' />
                                <div className="card-label">Offers Sold</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">{reportData.totalOffers}</div>
                                <BsFillSendCheckFill className='img' />
                                <div className="card-label">Sent Offers</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">{reportData.acceptedOffers}</div>
                                <GiConfirmed className='img' />
                                <div className="card-label">Accepted Offers</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">{reportData.freeInstallOffers}</div>
                                <GrInstallOption className='img' />
                                <div className="card-label">Free Install Offers</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">{reportData.freeOfferOffers}</div>
                                <TbFreeRights className='img' />
                                <div className="card-label">Free Offers</div>
                            </div>
                        </div>
                    ) : (
                        <div className="summary-cards">
                        <div className="summary-card">
                            <div className="card-value">0</div>
                            <div className="card-label">Sent Offers</div>
                        </div>
                        <div className="summary-card">
                            <div className="card-value">0</div>
                            <div className="card-label">Accepted Offers</div>
                        </div>
                        <div className="summary-card">
                            <div className="card-value">0</div>
                            <div className="card-label">Free Install Offers</div>
                        </div>
                        <div className="summary-card">
                            <div className="card-value">0</div>
                            <div className="card-label">Free Offers</div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default OffersReport;
