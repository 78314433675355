import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage"
import React, { useEffect } from 'react'
import SellingCardApi from '../../../../API/SellingCardApi'
import { UserContext } from '../../../../App'
import CreateSellingCardBlockReq from '../../../../Requests/SellingCard/CreateSellingCardBlockReq'
import Swal from 'sweetalert2'
import CreateFile from '../../../../Requests/CloudFile/CreateFileReq'
import { v4 as uuidv4 } from 'uuid'
import EditSellingCardBlockReq from '../../../../Requests/SellingCard/EditSellingCardBlockReq';
import { deleteObject } from "firebase/storage";
import RemoveFileReq from '../../../../Requests/CloudFile/RemoveFileReq'
import ShopItemsApi from '../../../../API/ShopItemsApi'
import FileUpload from "./../../../../components/FileUpload"
import { getIconForFirebaseFile, getIconForUploadedFile } from "../../../../Services/DisplayFile"
import { MdCancel, MdDelete, MdEdit, MdEditDocument } from 'react-icons/md'
import { FaEdit, FaSave } from "react-icons/fa"
import '../index.scss'

const StepCard = ({ index,card, blockNumber, update,setUpdate, blockId, searchTutorial, title = 'Title', description = "Description", removeStep, files, create, totalSteps, cardId }) => {

    const [stepTitle, setStepTitle] = React.useState("")
    const [stepDescription, setStepDescription] = React.useState("")

    const [newFiles, setNewFiles] = React.useState(null);
    const user = React.useContext(UserContext)
    const storage = getStorage();

    const addStep = async () => {

        Swal.fire({
            icon: 'info',
            title: 'Creating Step',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (!stepTitle) {
            Swal.fire({
                icon: 'error',
                title: 'Title is required',
                text: 'Please enter Title',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!stepDescription) {
            Swal.fire({
                icon: 'error',
                title: 'Description is required',
                text: 'Please enter Description',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!newFiles) {
            Swal.fire({
                icon: 'error',
                title: 'File is required',
                text: 'Please select a File',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        const req = new CreateSellingCardBlockReq();
        req.sellingCardId = cardId;
        req.blockNumber = totalSteps + 1;
        req.title = stepTitle;
        req.content = stepDescription;
        let downloadURLs = [];
        let filePaths = [];
        let index = 0;
        // Function to upload file and get download URL
        const uploadAndGetData = async (file, type) => {
            // Generate a unique ID 

            const uniqueId = uuidv4();

            // Update storageRef to use the unique ID


            const storageRef = ref(storage, `/sellingCardsBlock/${cardId}/${user.firebaseId}/${uniqueId}`);
            const task = uploadBytesResumable(storageRef, file);
            task.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    Swal.update({
                        text: `Uploading File ${progress.toFixed(0)}%`,
                        customClass: { container: 'custom-swal' }
                    })
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    console.log(error)
                },
                async () => {
                    const downloadURL = await getDownloadURL(storageRef);
                    downloadURLs.push(downloadURL);

                    filePaths.push(`/sellingCardsBlock/${cardId}/${user.firebaseId}/${uniqueId}`)
                    const createFile = new CreateFile();

                    createFile.name = newFiles.name;
                    createFile.extension = createFile.name.split('.').pop();
                    createFile.url = downloadURLs[0];
                    createFile.path = filePaths[0];
                    createFile.fileSize = newFiles.size.toString();
                    createFile.userId = user.id;
                    createFile.sellingCardId = cardId;
                    createFile.caption = "123";
                    createFile.fileType = newFiles.type
                    req.file = createFile;
                    SellingCardApi.CreateSellingCardBlock(req).then(
                        (response) => {

                            if (response.status.success) {

                                Swal.fire(
                                    {
                                        title: "Step Created",
                                        text: "Step Created Successfully",
                                        icon: "success",
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    }
                                );
                                searchTutorial();
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.status.message,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }
                    );
                    setStepTitle('')
                    setStepDescription('')
                    setNewFiles(null)
                    searchTutorial();
                }
            )
            // Get download URL and push to downloadURLs array


        };
        await uploadAndGetData(newFiles, newFiles.type);

        // if (list)
        //     setList((prev) => [...prev, { id: list.length + 1, title: stepTitle, description: stepDescription, files: newFiles }]);
        // else
        //     setList([{ id: 1, title: stepTitle, description: stepDescription, files: newFiles }])
        // // setList((prev) => [...prev, { id: list.length + 1, title: stepTitle, description: stepDescription, files: newFiles }]);

    }


    const onDrop = (acceptedFiles) => {
        console.log(acceptedFiles)
        setNewFiles(acceptedFiles[0])
    }

    const onCancel = (file) => {
        setNewFiles(null)
    }

    useEffect(
        () => {
            setUpdate(false)
            setStepTitle("");
            setStepDescription("");
        }, [index]
    )


    const updateStep = async () => {
        if (stepTitle === title && stepDescription === description && !newFiles)
            return;

        Swal.fire({
            icon: 'info',
            title: 'Updating Step',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        const req = new EditSellingCardBlockReq();
        req.id = blockId;
        req.sellingCardId = cardId;
        if (stepTitle !== title && stepTitle !== '') {
            req.title = stepTitle;
        }
        if (stepDescription !== description && stepDescription !== '') {
            req.content = stepDescription;
        }
        if (newFiles) {
            const deleteFiles = async () => {

                const fileRef = ref(storage, files.path);
                try {
                    await deleteObject(fileRef);
                    const req = new RemoveFileReq();
                    req.int = files.id;
                    const res = await ShopItemsApi.RemoveFile(req);
                    if (res?.status?.success) {
                        // console.log('File Deleted');
                    }
                } catch (error) {
                    console.error('An error occurred!', error);
                }

            };

            if (files) {
                await deleteFiles();
            }

            let downloadURLs = [];
            let filePaths = [];
            let index = 0;
            // Function to upload file and get download URL
            const uploadAndGetData = async (file, type) => {
                // Generate a unique ID 

                const uniqueId = uuidv4();

                // Update storageRef to use the unique ID


                const storageRef = ref(storage, `/sellingCardsBlock/${cardId}/${user.firebaseId}/${uniqueId}`);
                await uploadBytes(storageRef, file);
                // Get download URL and push to downloadURLs array
                const downloadURL = await getDownloadURL(storageRef);
                downloadURLs.push(downloadURL);
                console.log(downloadURL)
                filePaths.push(`/sellingCardsBlock/${cardId}/${user.firebaseId}/${uniqueId}`)

            };
            const createFile = new CreateFile();
            await uploadAndGetData(newFiles, newFiles.type);
            createFile.name = newFiles.name;
            createFile.extension = createFile.name.split('.').pop();
            createFile.url = downloadURLs[0];
            createFile.path = filePaths[0];
            createFile.fileSize = newFiles.size.toString();
            createFile.userId = user.id;
            createFile.sellingCardId = cardId;
            createFile.caption = "123";
            createFile.fileType = newFiles.type
            req.file = createFile;
        }
        // console.log(req)
        const res = await SellingCardApi.EditSellingCardBlock(req);
        if (res?.status?.success) {
            Swal.fire(
                {
                    title: "Step Updated",
                    text: "Step Updated Successfully",
                    icon: "success",
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                }
            );
            setUpdate(false);
            setNewFiles(null);

            searchTutorial();
            // setStepTitle(title);
            // setStepDescription(description);

        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }

    }


    return (
        <div className='stepDetails'>

            {
                create ?
                    <>
                        <input value={stepTitle} onChange={(e) => { setStepTitle(e.target.value) }} placeholder='Title' />
                        <textarea value={stepDescription} onChange={(e) => { setStepDescription(e.target.value) }} placeholder='Description' />
                        <FileUpload multiple={false} file={newFiles} setFile={setNewFiles} />
                        <button onClick={addStep}>Create Step</button>
                    </>
                    :
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                <div style={{ flex: 1, minWidth: '200px', marginBottom: update ? '10px' : '0' }}>
                                    {update ? (
                                        <input
                                            value={stepTitle}
                                            onChange={(e) => setStepTitle(e.target.value)}
                                            placeholder='Title'
                                            style={{ padding: '8px', fontSize: '16px', width: '100%', borderRadius: '4px', border: '1px solid #ccc' }}
                                        />
                                    ) : (
                                        <h1 style={{ margin: 0, fontSize: '20px', color: '#333', wordBreak: 'break-word' }}>{title}</h1>
                                    )}
                                </div>
                                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                    {(user.userType === "SuperAdmin" || (user.userType === "SalesOrgAdmin" && user.salesOrgId === card.salesOrgId)) && !update && (
                                        <div className='action_buttons' style={{ display: 'flex', gap: '10px' }}>
                                            <button onClick={() => { setUpdate(true); setStepTitle(title); setStepDescription(description); }}>
                                                <MdEditDocument color="white" size={20} />
                                            </button>
                                            <button onClick={() => removeStep(blockId)}>
                                                <MdDelete color="white" size={20} />
                                            </button>
                                        </div>
                                    )}

                                    {(user.userType === "SuperAdmin" || (user.userType === "SalesOrgAdmin" && user.salesOrgId === card.salesOrgId)) && update && (
                                        <div className='action_buttons' style={{ display: 'flex', gap: '10px' }}>
                                            <button onClick={() => updateStep()}>
                                                <FaSave color="white" size={20} />
                                            </button>
                                            <button onClick={() => { setUpdate(false); setNewFiles(null); setStepTitle(""); setStepDescription(""); }}>
                                                <MdCancel color="white" size={20} />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div style={{ position: 'relative' }} className='updateImage'>
                            {!newFiles && files?.fileType.includes('image') && (
                                <img
                                src={files.url}
                                alt="Uploaded file"
                                // style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                                />
                            )}
                            {
                                !newFiles && !files && (
                                <div>
                                    <p>No Image To Show</p>
                                </div>
                                )
                            }
                            {
                                newFiles && getIconForUploadedFile(newFiles)
                            }

                            {update && (
                                <label
                                style={{
                                    position: "absolute",
                                    height: "fit-content",
                                    bottom: "10%",
                                    right: "10%",
                                    backgroundColor: "#2761D0",
                                    borderRadius: "50%",
                                    padding: "0.5rem",
                                    cursor: "pointer"
                                }}
                                htmlFor='fileUpload'
                                >
                                <MdEdit color='white' size={40} />
                                <input
                                    onChange={(e) => {
                                    if (e.target.files.length > 0)
                                        setNewFiles(e.target.files[0]);
                                    }}
                                    type='file'
                                    id='fileUpload'
                                    style={{ display: 'none' }}
                                />
                                </label>
                            )}
                            </div>


                        <div>
                            {update ?
                                <textarea value={stepDescription} onChange={(e) => { setStepDescription(e.target.value) }} placeholder='Description' />
                                :
                                <p className='desc'>
                                    {description}
                                </p>
                            }
                        </div>
     

                    </>
            }
                    
        </div>
    )
}

export default StepCard