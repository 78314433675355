class EditCompanyFormReq {
    constructor() {
        this.Id = null;
        this.CompanyId = null;

        // FORM INPUTS
        this.SchedulingNotes = null;
        this.SchedulingNotesRequired = null;
        this.TimeZone = null;
        this.TimeZoneRequired = null;
        this.AccountNotes = null;
        this.AccountNotesRequired = null;
        this.SecondaryPhoneNumber = null;
        this.SecondaryPhoneNumberRequired = null;
        this.FirstName = null;
        this.FirstNameRequired = null;
        this.LastName = null;
        this.LastNameRequired = null;
        this.SecondaryAccountHolderFullName = null;
        this.SecondaryAccountHolderFullNameRequired = null;
        this.Email = null;
        this.EmailRequired = null;
        this.Address = null;
        this.AddressRequired = null;
        this.CellPhone = null;
        this.CellPhoneRequired = null;
        this.DesiredInstallTimeString = null;
        this.DesiredInstallTimeStringRequired = null;
        this.DesiredInstallDateString = null;
        this.DesiredInstallDateStringRequired = null;
        this.FourDigitPin = null;
        this.FourDigitPinRequired = null;
        this.SecurityQuestion1 = null;
        this.SecurityQuestion1Required = null;
        this.SecurityQuestion2 = null;
        this.SecurityQuestion2Required = null;
        this.AnswerSecurityQuestion1 = null;
        this.AnswerSecurityQuestion1Required = null;
        this.AnswerSecurityQuestion2 = null;
        this.AnswerSecurityQuestion2Required = null;
        this.FiberPlanString = null;
        this.FiberPlanStringRequired = null;
        this.FiberPlanId = null;
        this.FiberPlanIdRequired = null;

        this.NotesToFiberCompanySpecialInstructions = null;
        this.NotesToFiberCompanySpecialInstructionsRequired = null;
        this.PromoCode = null;
        this.PromoCodeRequired = null;
        this.FreeMonth = null;
        this.FreeMonthRequired = null;
        this.FreeInstall = null;
        this.FreeInstallRequired = null;
        this.FreeOffer = null;
        this.FreeOfferRequired = null;
        this.ConsentChecked = null;
        this.ConsentCheckedRequired = null;
        this.SpeedTestBeforeImage = null;
        this.speedTestBeforeImageRequired = null;
        // ******************************************************* LIGHT CURVE *******************************************************
        this.SteetAddress = null;
        this.SteetAddressRequired = null;
        this.City = null;
        this.CityRequired = null;
        this.Appartment = null;
        this.AppartmentRequired = null;
        this.FreeLeasedDeviceWithPromo = null;
        this.FreeLeasedDeviceWithPromoRequired = null;
        this.ServiceAgreement = null;
        this.ServiceAgreementRequired = null;
        this.FiberONTLease = null;
        this.FiberONTLeaseRequired = null;
        this.BoostRouterLease = null;
        this.BoostRouterLeaseRequired = null;
        this.InstallServiceCharge = null;
        this.InstallServiceChargeRequired = null;
        this.VerificationPhrase = null;
        this.VerificationPhraseRequired = null;
        this.AddressLine2 = null;
        this.AddressLine2Required = null;
        this.ZipCode = null;
        this.ZipCodeRequired = null;
        this.State = null;
        this.StateRequired = null;
        this.GoPaperless = null;
        this.GoPaperlessRequired = null;
    }
}

export default EditCompanyFormReq;