import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect } from 'react';
export default function Index({ headers, list,onSave,onDelete,refresh}) {

    const [editIndex, setEditIndex] = React.useState(-1);
    const [prevRow,setPrevRow] = React.useState()
    const [rows, setRows] = React.useState([])

    useEffect(() => {
        setRows(list)
    }, [list])


    const editHandler = (index) => {
       cancelHandler(index)
       setEditIndex(index)
       setPrevRow({...rows[index]})
    }

    const cancelHandler = () => {
        const l = []
        
        for (let index = 0; index < rows.length; index++) {
           if(index!==editIndex)
           l.push(rows[index])
           else
           l.push(prevRow) 
        }
        setRows(l)
        setEditIndex(-1);
    };

    const saveHandler = (customer) =>{
        onSave(customer)
        setEditIndex(-1)
    }

    const handleInputChange = (event, rowIndex, key) => {
        const updatedRows = [...rows];
        updatedRows[rowIndex][key] = event.target.value;
        setRows(updatedRows);
    };
    return (
        <>
            <TableContainer  >
                <Table aria-label="simple table">
                    <TableHead sx={{
                        display: "contents"
                    }}>
                        <TableRow>
                            {
                                headers?.map(
                                    (head, key) => {

                                        return <TableCell key={key} colSpan={1} style={{ color: "#00AFFF", textAlign: head.name === "Files" ? "center" : "initial" }}>{head.name}</TableCell>
                                    }
                                )
                            }

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows?.map(
                                (row, index) => {
                                    return <TableRow key={index}>
                                        {headers?.map(
                                            (head, index1) => {
                                                if (head.key !== "actions")
                                                    return <TableCell colSpan={1} key={head.key}><input disabled={!(index===editIndex)} className="disabled:bg-transparent enabled:border-2 enabled:border-[#00AAFF] p-2" value={row[head.key]} onChange={(e)=>{handleInputChange(e,index,head.key)}} /></TableCell>
                                                else
                                                    if(index!==editIndex)
                                                    return <TableCell colSpan={1} key={head.key}>
                                                        <div className='flex justify-center items-center'>
                                                            <button onClick={()=>{editHandler(index)}} className='bg-blue-500 text-white rounded-md px-2 py-1'>Edit</button>
                                                            <button onClick={()=>{onDelete(row['id']);refresh()}} className='bg-red-500 text-white rounded-md px-2 py-1 ml-2'>Delete</button>
                                                        </div>
                                                    </TableCell>
                                                    else
                                                    return <TableCell colSpan={1} key={head.key}>
                                                        <div className='flex justify-center items-center'>
                                                            <button onClick={()=>{saveHandler(row)}}  className='bg-blue-500 text-white rounded-md px-2 py-1'>Save</button>
                                                            <button onClick={()=>{cancelHandler()}} className='bg-red-500 text-white rounded-md px-2 py-1 ml-2'>Cancel</button>
                                                        </div>
                                                    </TableCell>
                                            }
                                        )

                                        }
                                    </TableRow>
                                }
                            )
                        }

                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

