class SearchMapIconReq{
    constructor(){
        this.id = null;
        this.name = null;
        this.salesOrgId = null;
        this.pagingParams = {
          pageNumber: 0,
          pageSize: 0
        };
    }
}
export default SearchMapIconReq;