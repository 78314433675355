class CreateFile{
    constructor(){
      this.name = "";
      this.url = "";
      this.fileType = "";
      this.fileSize = "";
      this.extension = "";
      this.caption = "";
      this.path = "";
      this.userId = null;
      this.shopItemId = null;
      this.sellingCardId = null;
    }
  }
export default CreateFile