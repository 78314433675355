import DrawButton from '@terrestris/react-geo/dist/Button/DrawButton/DrawButton';
import MapContext from '@terrestris/react-geo/dist/Context/MapContext/MapContext';
import { toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { MapContext as MP } from '..';
import ServicableAreaApi from '../../../../API/ServicableAreaApi';
import { UserContext } from '../../../../App';
// import AddFiberHousesToServicableAreaReq from '../../../../Requests/ServicableArea/AddFiberHousesToServicableAreaReq';
import CreateServicableAreaReq from '../../../../Requests/ServicableArea/CreateServicableAreaReq';
import Input from '../../../../components/Input';
// import OrganizationSelector from '../../../../components/OrganizationSelector';
import FiberCompanySelector from '../../../../components/FiberCompanySelector'
import OfferTemplateSelector from "../../../../components/OfferTemplateSelector"
const CreateServicableAreaForm = ({ layers, setLayers, color = "#000000", setColor, fiberHouses, searchServicableAreas }) => {
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const user = useContext(UserContext);
    const map = useContext(MP);
    const [servicableAreaLayer, setServicableAreaLayer] = useState(null);
    const [shape, setShape] = useState(null);
    const [mapObject, setMapObject] = useState(map.mapObject);
    const [salesOrgId, setSalesOrgId] = useState();
    const [fiberCompanyId, setFiberCompanyId] = useState(null);
    const [active, setActive] = useState(false)
    const [type, setType] = useState("FiberHouse");
    const [offerTemplateId, setOfferTemplateId] = useState(null)

    useEffect(() => {
        if (map?.mapObject) {
            setMapObject(map.mapObject)
        }
    }, [map])



    // const clearFormData = () => {
    //     setName('');
    //     setNotes('');
    //     setColor('#000000');
    //     setLayers(null);
    //     setServicableAreaLayer(null);
    //     setShape(null);
    // }

    // function checkIfFiberHouseIsInsideServicableArea(fiberHouse) {
    //     const lat = fiberHouse.coordinates.latitude;
    //     const lng = fiberHouse.coordinates.longitude;

    //     let inside = false;
    //     for (let i = 0, j = shape.length - 1; i < shape.length; j = i++) {
    //         const xi = shape[i].lat;
    //         const yi = shape[i].lng;
    //         const xj = shape[j].lat;
    //         const yj = shape[j].lng;

    //         const intersect = ((yi > lng) !== (yj > lng)) && (lat < (xj - xi) * (lng - yi) / (yj - yi) + xi);
    //         if (intersect) {
    //             inside = !inside; // Toggle the inside flag on each intersection
    //         }
    //     }
    //     return inside;
    // }

    // function checkIfFiberHouseIsInsideServicableArea2(fiberHouse) {
    //     const lat = fiberHouse.coordinates.latitude;
    //     const lng = fiberHouse.coordinates.longitude;

    //     let inside = false;
    //     for (let i = 0, j = map.searchArea.length - 1; i < map.searchArea.length; j = i++) {
    //         const xi = map.searchArea[i].latitude;
    //         const yi = map.searchArea[i].longitude;
    //         const xj = map.searchArea[j].latitude;
    //         const yj = map.searchArea[j].longitude;

    //         const intersect = ((yi > lng) !== (yj > lng)) && (lat < (xj - xi) * (lng - yi) / (yj - yi) + xi);
    //         if (intersect) {
    //             inside = !inside; // Toggle the inside flag on each intersection
    //         }
    //     }
    //     return inside;
    // }

    function createServicableArea() {
        // implementation
        Swal.fire({
            icon: 'info',
            title: 'Creating Servicable Area',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        if (!name) {
            Swal.fire({
                icon: 'error',
                title: 'Name is required',
                text: 'Please enter Name',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        // if (!notes) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Notes is required',
        //         text: 'Please enter Notes',
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         allowEnterKey: false,
        //         showConfirmButton: true,
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return;
        // }

        if (!color) {
            Swal.fire({
                icon: 'error',
                title: 'Color is required',
                text: 'Please enter Color',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (type === "Company" && !fiberCompanyId) {
            Swal.fire({
                icon: 'error',
                title: 'Fiber Company is required',
                text: 'Please select Fiber Company',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (type === "Offer" && !offerTemplateId) {
            Swal.fire({
                icon: "error",
                title: "Offer Template is Required",
                text: "Please select Offer Template",
                allowEnterKey: true,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (layers?.length === 0 && !map.searchArea) {
            Swal.fire({
                icon: 'error',
                title: 'Servicable Area is required',
                text: 'Please draw Servicable Area',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        let orgId = null;
        if (user?.userType == "SuperAdmin" || user?.userType == "Admin") {
            orgId = salesOrgId;
        }
        else {
            orgId = user.salesOrgId;
        }
        const req = new CreateServicableAreaReq();
        req.name = name;
        req.color = color;
        req.notes = notes;
        req.salesOrgId = 1;
        req.status = "Active";
        req.createdById = user.id;
        req.fiberCompanyId = fiberCompanyId;
        req.servicableAreaType = type;
        req.notes = notes
        if (!map.searchArea)
            shape.map((latlng) => {
                req.coordinates.push({
                    latitude: latlng.lat,
                    longitude: latlng.lng,
                    popUpColor: color
                })
            });
        else {
            req.coordinates = map.searchArea;
        }
        ServicableAreaApi.CreateServicableArea(req).then(
            (res) => {
                if (res?.status?.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Servicable Area Created!",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                    searchServicableAreas()
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                }
            }
        )
    }

    const onDrawEnd = (e) => {

        const feature = e.feature;
        const geometry = feature.getGeometry();
        const coordinates = geometry.getCoordinates();
        setServicableAreaLayer(feature)
        let points = []
        for (let index = 0; index < coordinates[0].length; index++) {
            const [longitude, latitude] = toLonLat(coordinates[0][index])
            points.push({
                lat: latitude,
                lng: longitude
            })

        }
        setShape(points)
        feature.setStyle(
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 3
                }),
                fill: new Fill({
                    color: makeTransparent(color, 0.50)
                }),

            })
        )
    }

    function makeTransparent(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    const deletePolygon = () => {
        if (servicableAreaLayer) {

            var selectedGeometry = servicableAreaLayer.getGeometry(); // Assuming only one feature is selected
            mapObject.getLayers().getArray().forEach(layer => {
                const source = layer.getSource()
                if (source instanceof VectorSource)
                    source.forEachFeature(function (feature) {
                        var featureGeometry = feature.getGeometry();
                        if (featureGeometry === selectedGeometry) {
                            layer.getSource().removeFeature(feature);
                            setServicableAreaLayer(null)

                        }
                    });
            });
        }
        // else if (map.searchArea.length > 0 && map.seachAreaFeature.length>0) {
        //     map.seachAreaFeature.forEach(function (feature1) {
        //         var selectedGeometry = feature1.getGeometry(); // Assuming only one feature is selected
        //         mapObject.getLayers().getArray().forEach(layer => {
        //             const source = layer.getSource()
        //             if (source instanceof VectorSource)
        //                 source.forEachFeature(function (feature) {
        //                     if (feature.getGeometry() === selectedGeometry) {
        //                         layer.getSource().removeFeature(feature);
        //                         map.setSearchArea(null)
        //                         map.setSearchAreaFeature(null)
        //                     }
        //                 });
        //         });
        //     })
        // }
        setActive(false)
    }

    //Update servicable area color on change
    useEffect(() => {
        if (servicableAreaLayer) {
            servicableAreaLayer.setStyle(
                new Style({
                    stroke: new Stroke({
                        color: color,
                        width: 3
                    }),
                    fill: new Fill({
                        color: makeTransparent(color, 0.50)
                    }),

                })
            )
        }
    }, [color])


    useEffect(
        () => {
            return () => {
                if (servicableAreaLayer) {
                    mapObject.removeLayer(servicableAreaLayer)
                }
            }
        }, []
    )

    // <button onClick={() => { map.closeTab() }} className='CloseButton'>
    //                 X
    //  </button> 
    // close tab button


    return (
        // <div className=''>
        <div className='flex flex-col gap-4 w-full  px-4 ServicableAreaFormDiv'>
            <div className='TitleDiv'>
                <h1 >Create a Servicable Area</h1>
            </div>
            <div className='ServicableAreaFormInputs'>
                <MapContext.Provider value={mapObject}>

                    {servicableAreaLayer ? <button onClick={
                        () => {
                            deletePolygon()
                        }
                    } className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-4'>
                        Delete Polygon
                    </button>

                        :
                        <DrawButton
                            tooltip='Draw a polygon'
                            className={`mx-auto rounded-lg ${active ? "bg-[#4DD4FF]" : "bg-[#181E4B]"} px-12 py-2 text-white w-fit font-extrabold text-center sm:text-lg text-sm`}
                            name="drawPolygon"
                            drawType="Polygon"
                            drawInteractionConfig={{
                                freehand: true,
                            }}
                            onToggle={(pressed) => { setActive(pressed) }}
                            size='small'
                            // onClick={() => { 
                            onDrawEnd={onDrawEnd}
                        >
                            Draw polygon
                        </DrawButton>}
                </MapContext.Provider>
                <Input placeholder="Enter the Name" value={name} onChange={(e) => setName(e.target.value.slice(0, 32))} />
                <div className='flex rounded-lg border-2 border-[#C4C4C4] py-2 px-2'>
                    <input type="text" className='text-center outline-none' value={color} onChange={(e) => setColor(e.target.value.slice(0, 32))} />
                    <input type="color" value={color} onChange={(e) => setColor(e.target.value.slice(0, 32))} />
                </div>

            </div>
            {/* {
                (user?.userType == "SuperAdmin" || user?.userType == "Admin") && <OrganizationSelector placeholder="Select Sales Org" setUser={setSalesOrgId} />
            } */}
            <div style={{
                width: "100%"
            }}>
                <p style={{
                    color: "#5E6282",
                    fontWeight: "500",
                    fontSize: "1.2rem"
                }}>Notes :</p>
                <textarea style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "0.5rem 1rem",
                    outline: "none",
                    width: "100%"
                }} value={notes} onChange={(e) => {
                    setNotes(e.target.value);
                }} rows={3} />
            </div>

            <div style={{
                width: "100%"
            }}>
                <p style={{
                    color: "#5E6282",
                    fontWeight: "500",
                    fontSize: "1.2rem"
                }} >Servicable Area Type :</p>
                <select style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "0.5rem 1rem",
                    outline: "none",
                    width: "100%"
                }} onChange={(e) => {
                    setType(e.target.value)
                }}>
                    <option value="FiberHouse">FiberHouse</option>
                    <option value="Company">Company</option>
                    <option value="Offer">Offer</option>
                    <option value="Tag">Tag</option>
                </select>
            </div>
            {
                type === "Company" &&
                <FiberCompanySelector setUser={setFiberCompanyId} placeholder='Select a Fiber Company' />
            }
            {
                type === "Offer" &&
                <OfferTemplateSelector setUser={setOfferTemplateId} placeholder='Select an Offer Template' />
            }
            <button onClick={createServicableArea} className='GreenCreateButton'> Create</button>
        </div>
    );
};

export default CreateServicableAreaForm;
