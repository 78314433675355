class SearchCustomerReq{
        constructor(){
                this.id = null;
                this.salesRepId = null;
                this.salesOrgId = null;
                this.issueType = null;
                this.orderNumber = null;
                this.installDateTimeFrom = null;
                this.installDateTimeTo = null;
                this.installCopy = null;
                this.timeZone = null;
                this.accountStatus = null;
                this.accountNotes = null;
                this.submittedToEmbarcForm = null;
                this.secondaryPhoneNumber = null;
                this.timeStampFrom = null;
                this.timeStampTo = null;
                this.firstName = null;
                this.lastName = null;
                this.secondaryAccountHolderFullName = null;
                this.email = null;
                this.address = null;
                this.cellPhone = null;
                this.installASAP = null;
                this.desiredInstallDateFrom = null;
                this.desiredInstallDateTo = null;
                this.desiredInstallTime = null;
                this.fourDigitPin = null;
                this.securityQuestion1 = null;
                this.securityQuestion2 = null;
                this.answerSecurityQuestion1 = null;
                this.answerSecurityQuestion2 = null;
                this.fiberPlanString = null;
                this.fiberPlanId = null;
                this.notesToFiberCompanySpecialInstructions = null;
                this.promoCode = null;
                this.freeMonth = null;
                this.consentChecked = null;
                this.currentInternetSpeedFrom = null;
                this.currentInternetSpeedTo = null;
                this.userId = null;
                this.leadId = null;
                this.isHotLead = null;
                this.userIds = [];
                this.salesOrgIds = [];
                this.fiberCompanyIds = [];
                this.hasIssue = null;
                this.pagingParams = {
                    pageNumber: null,
                    pageSize: null
                };
        }
}

export default SearchCustomerReq;