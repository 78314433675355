import { Rating } from '@mui/material'
import React from 'react'

const ReviewCard = ({ review }) => {
    return (
        <div className='card'>
            <div className='cardHeader'>
                <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                <div>
                    <h4>{review.name}</h4>
                    <p>{review.address}</p>
                </div>
            </div>
            <p>{review.review}</p>
            <Rating name="read-only" value={review.rating} readOnly />
            <div className=''>
                <img src="/images/NetworkTeam/brightSpeed.png" alt="" />
            </div>
        </div>
    )
}

export default ReviewCard