import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import OrganizationSelector from '../../../components/OrganizationSelector'; // Assuming this is the component you're using for selecting sales orgs
import { MdDelete } from 'react-icons/md';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import StringIntReq from '../../../Requests/StringIntReq';
import FiberCompanySalesOrgReq from '../../../Requests/Organizations/FiberCompanySalesOrgReq';
import { get } from 'sortablejs';

const FiberCompanySalesOrgs = ({ fiberCompanyId, onSalesOrgAction }) => {
    const [orgWithName, setOrgWithName] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [assignedOrgs, setAssignedOrgs] = useState([]);
    const [orgIds, setOrgIds] = useState([]);

    useEffect(() => {


        fetchAssignedSalesOrgs();
    }, [fiberCompanyId]);

    const fetchAssignedSalesOrgs = async () => {
        try {
            const req = new StringIntReq();
            req.int = fiberCompanyId;
            const res = await FiberCompanyApi.GetSalesOrgsForFiberCompany(req);
            if (res?.status?.success) {
                const mappedOrgs = res.data.map((org) => ({
                    id: org.id,
                    label: org.name,
                }));
                setAssignedOrgs(mappedOrgs);
            } else {
                console.error('Failed to fetch sales orgs');
            }
        } catch (error) {
            console.error('Error fetching sales orgs:', error);
        }
    };

    const addSalesOrg = () => {

        Swal.fire({
            icon: 'info',
            title: 'Assigning Sales Org',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const salesOrgReq = new FiberCompanySalesOrgReq();

        salesOrgReq.fiberCompanyId = fiberCompanyId;
        salesOrgReq.salesOrgId = selectedOrg;

        console.log(salesOrgReq)

        FiberCompanyApi.UpdateSalesOrgsForFiberCompany(salesOrgReq).then(salesOrgRes => {
            if (salesOrgRes?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sales Org assigned successfully',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                fetchAssignedSalesOrgs();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: salesOrgRes?.status?.message || 'Failed to Assign Sales Orgs',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        });
    };

    const removeSalesOrg = async (orgId) => {

        Swal.fire({
            icon: 'info',
            title: 'Removing Sales Org',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const salesOrgReq = new FiberCompanySalesOrgReq();

        salesOrgReq.fiberCompanyId = fiberCompanyId;
        salesOrgReq.salesOrgId = orgId;


        console.log(salesOrgReq)

        FiberCompanyApi.RemoveSalesOrgFromFiberCompany(salesOrgReq).then(salesOrgRes => {
            if (salesOrgRes?.status?.success) {
                fetchAssignedSalesOrgs();
                onSalesOrgAction();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sales Org removed successfully',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: salesOrgRes?.status?.message || 'Failed to Assign Sales Orgs',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        });

    };

    return (
        <div className="add_plan_to_template">
            <div className='form'>
                <h2>Assign Sales Organizations</h2>

                <OrganizationSelector
                    allOption={false}
                    orgWithName={orgWithName}
                    setOrgWithName={setOrgWithName}
                    multiple={false}
                    setUser={setSelectedOrg}
                    placeholder="Select Sales Organization"
                />

                {selectedOrg && (
                    <button onClick={addSalesOrg} className="add-btn">
                        Add Sales Org
                    </button>
                )}

            </div>



            <div className="fiber_plans">
                {assignedOrgs.length > 0 ? (
                    assignedOrgs.map((org) => (
                        <div key={org.id} className="fiber_plan">
                            <span>{org.label}</span>
                            <button
                                className="delete-btn"
                                onClick={() => removeSalesOrg(org.id)}
                            >
                                <MdDelete size={20} color="white" />
                            </button>
                        </div>
                    ))
                ) : (
                    <p>No sales organizations assigned yet.</p>
                )}
            </div>
        </div>
    );
};

export default FiberCompanySalesOrgs;
