import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import FiberPlanApi from '../../../API/FiberPlanApi'
import CreateFiberPlanReq from '../../../Requests/FiberPlan/CreateFiberPlanReq'
import { useLocation, useNavigate } from 'react-router-dom'
import Sidebar from '../../../Layouts/Sidebar'
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { IoArrowBack } from 'react-icons/io5'
const Index = () => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [fiberCompanyId, setFiberCompanyId] = useState('')
  const [speed, setSpeed] = useState('')
  const [price, setPrice] = useState('')
  const location = useLocation()
  const navigate = useNavigate();
  useEffect(
    () => {
      console.log(location)
      if (location.state) {
        setFiberCompanyId(location.state.id)
      }
    }, [location.state]
  )


  const handleCreate = () => {
    Swal.fire({
      title: "info",
      text: "Creating Fiber Plan",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    if (!name) {
      Swal.fire({
        title: "error",
        text: "Please Enter Name",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
      return
    }

    if (!description) {
      Swal.fire({
        title: "error",
        text: "Please Enter Description",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
      return
    }

    if (!speed) {
      Swal.fire({
        title: "error",
        text: "Please Enter Speed",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
      return
    }

    if (!price) {
      Swal.fire({
        title: "error",
        text: "Please Enter Price",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
      return
    }
    const req = new CreateFiberPlanReq()
    req.name = name
    req.description = description
    req.fiberCompanyId = fiberCompanyId
    req.speed = speed
    req.price = price
    req.fiberItemType = "plan"
    req.fiberPlanString = name
    FiberPlanApi.CreateFiberPlan(req).then(
      (res) => {
        if (res?.status?.success) {
          Swal.fire({
            title: "success",
            text: "Fiber Plan Created Successfully",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        }
        else {
          Swal.fire({
            title: "error",
            text: res.message,
            icon: "error",
            showCancelButton: false,
            showConfirmButton: true,
            didOpen: () => {
              Swal.hideLoading()
            }
          })
        }
      }
    )


  }

  return (
    <Layout>
      <IoArrowBack color='black' size={30} style={{ cursor: 'pointer',marginLeft:"1rem" }} onClick={() => navigate(-1)} />
      <div className='createFiberPlanContainer' >
        <div>
          <h3 >Create Fiber Plan</h3>
          <input placeholder="Enter Name" value={name} onChange={e => setName(e.target.value)} />
          <input placeholder="Enter Description" value={description} onChange={e => setDescription(e.target.value)} />
          <input placeholder="Enter Speed" type="number" value={speed} onChange={e => setSpeed(e.target.value)} />
          <input placeholder="Enter Price" type="number" value={price} onChange={e => setPrice(e.target.value)} />
          <button onClick={handleCreate}>Create</button>
        </div>

      </div>
    </Layout>
  )
}

export default Index