import React, { useEffect } from 'react'
import { MdDownload, MdUpload } from "react-icons/md";
import anonUser from "./../../../../assets/images/userGrey.png"
// import AudioPlayer from 'react-h5-audio-player';
import { AudioPlayer } from './AudioPlayer';
import 'react-h5-audio-player/lib/styles.css';
import { FaRegClock } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import Swal from 'sweetalert2';
import ContextMenu from '../../../../components/ContextMenu';
import DeleteMessageReq from '../../../../Requests/Messaging/DeleteMessageReq';
import MessagingApi from '../../../../API/MessagingApi';
import { ref, deleteObject, getStorage } from "firebase/storage";
import "file-icon-vectors/dist/file-icon-vectors.min.css"
import { FaHeart } from 'react-icons/fa6';
import FavouriteMessageReq from '../../../../Requests/Messaging/FavouriteMessageReq';
import StringIntReq from '../../../../Requests/StringIntReq';
import { CircularProgress } from '@mui/material';
const Message = React.memo(function ({ message, setEditMessageId, getPinnedMessages, getUserFavouriteMessages, selectedProfile, user, conversationUsers, scroll, isFirstElement, isLastElement, firstMessageRef, setMessages }) {
    const [senderImage, setSenderImage] = React.useState(anonUser);
    const [senderName, setSenderName] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editable, setEditable] = React.useState(false);
    const storage = getStorage();
    const [msg, setMsg] = React.useState(message.content)
    const [expandImage, setExpandImage] = React.useState(false);


    const expandedImage = () => {
        return <div style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "100"

        }} onClick={() => {
            setExpandImage(false)
        }} >
            <img src={message.files[0].url} />
        </div>
    }

    const getIcon = (file) => {
        if (file.fileType.includes('image')) {
            return <img onClick={() => {
                setExpandImage(true)
            }} src={file.url} style={{ borderRadius: "10px" }} width={200} />
        }
        else if (file.fileType.includes('video')) {
            return <video controls src={file.url+"#t=0.001"} ></video>
        }
        else {
            return <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <span className={`fiv-cla fiv-icon-${file.extension}`} ></span>
                <p style={{
                    textAlign: "center",
                    fontSize: "0.8rem",
                    color: "blue",
                    marginTop: "0.5rem"
                }} >{file.name}</p>
            </div>
        }
    }


    const downloadFile = async (file) => {
        try {

            const link = document.createElement('a');
            link.href = file.url;
            link.target = "_blank";
            link.download = file.name; // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error downloading file:', error.message);
        }
    };

    async function deleteMessage() {
        const choice = await Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete this message?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"

        })
        if (choice.isConfirmed) {
            Swal.fire(
                {
                    icon: "info",
                    title: "Deleting message...",
                    didOpen: () => {
                        Swal.showLoading()
                    }
                }
            )
            const req = new DeleteMessageReq();
            req.int = message.id;
            if (message?.files?.length > 0) {

                try {
                    const storageRef = ref(storage, message.files[0].path);
                    await deleteObject(storageRef);
                }
                catch (e) {
                    console.log(e)
                }

            }

            if(message.voiceNote){
                try {
                    const storageRef = ref(storage, message.voiceNote.path);
                    await deleteObject(storageRef);
                }
                catch (e) {
                    console.log(e)
                }
            }

            const res = await MessagingApi.DeleteMessage(req);
            if (res?.status?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Message deleted successfully",
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                setMessages(
                    (prev) => prev.filter((item) => item.id !== message.id)
                )
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "An error occurred",
                    text: res?.status?.message,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            }
        }

    }

    async function editMesage() {
        // setEditMessageId(message.id)
        if (msg == message.content) {
            setEditable(false)
            return
        }
        Swal.fire({
            icon: "info",
            title: "Editing message...",
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new DeleteMessageReq();
        req.int = message.id;
        req.string = msg;
        const res = await MessagingApi.EditMessage(req);
        if (res?.status?.success) {
            setEditable(false)
            setMessages(
                (prev) => prev.map((item) => {
                    if (item.id === message.id) {
                        return res.data
                    }
                    return item
                })
            )
            Swal.fire({
                icon: "success",
                title: "Message edited successfully",
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                icon: "error",
                title: "An error occurred",
                text: res?.status?.message,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    useEffect(() => {
        if (conversationUsers) {
            console.log("conversationUsers", conversationUsers)
            conversationUsers.map(
                (item, index1) => {

                    if (message.senderId === user.id) {
                        setSenderImage(user.profileImage?.url ? user.profileImage.url : anonUser)
                        setSenderName("")
                    }
                    else if (message.senderId === item.userId) {
                        console.log("item", message.senderId, item.userId)
                        setSenderImage(item?.user?.profileImage?.url ? item?.user?.profileImage?.url : anonUser)
                        setSenderName(item?.user?.firstName + " " + item?.user?.lastName)
                    }
                }
            )
        }
    }, [message])

    async function pinMessage() {
        const req = new StringIntReq();
        req.int = message.id;
        const res = await MessagingApi.PinMessage(req);
        if (res?.status?.success) {
            console.log("Message pinned")
            getPinnedMessages()
            setMessages(
                (prev) => prev.map((item) => {
                    if (item.id === message.id) {
                        return {
                            ...item,
                            isPinned: true
                        }
                    }
                    return item
                }
                )
            )
        }
        else {
            console.log(res?.status?.message)
        }
    }

    async function unPinMessage() {
        const req = new StringIntReq();
        req.int = message.id;
        const res = await MessagingApi.UnPinMessage(req);
        if (res?.status?.success) {
            console.log("Message unpinned")
            getPinnedMessages()
        }
        else {
            console.log(res?.status?.message)
        }
    }

    function getRef() {
        if (isFirstElement) {
            return firstMessageRef;
        }
        else if (isLastElement) {
            return scroll;
        }
        else {
            return null;
        }
    }

    const MI = [
        {
            title: "Delete",
            onClick: deleteMessage,
            display: message.senderId === user.id
        },
        {
            title: "Edit",
            onClick: () => { setEditable(true) },
            display: message.senderId === user.id && message?.files?.length === 0 && !message.voiceNote
        },
        {
            title: message.isPinned ? "UnPin Message" : "Pin Message",
            onClick: () => { message.isPinned ? unPinMessage() : pinMessage() },
            display: message.senderId === user.id
        },
        {
            title: message?.favouritedBy?.filter((item) => item.userId === user?.id).length > 0 ? 'Mark as Unfavourite' : 'Mark as Favourite',
            onClick: () => { markAsFavourite() },
            display: true
        }
    ]

    const markAsFavourite = async () => {
        if (message?.favouritedBy?.filter((item) => item.userId === user?.id).length > 0) {
            const req = new StringIntReq();
            req.int = message.id;
            const res = await MessagingApi.UnFavouriteMessage(req);
            if (res?.status?.success) {
                setMessages(
                    (prev) => prev.map((item) => {
                        if (item.id === message.id) {
                            return {
                                ...item,
                                favouritedBy: null
                            }
                        }
                        return item
                    })
                )
                getUserFavouriteMessages()
            }
        }
        setMessages(
            (prev) => prev.map((item) => {
                if (item.id === message.id) {
                    return {
                        ...item,
                        favouritedBy: [{
                            userId: user?.id
                        }]
                    }
                }
                return item
            })
        )
        const req = new FavouriteMessageReq();
        req.id1 = user?.id;
        req.id2 = message.id;
        const res = await MessagingApi.FavouriteMessage(req);
        if (res?.status?.success) {
            console.log("Message favourited")
            getUserFavouriteMessages()
        }
        else {
            setMessages(
                (prev) => prev.map((item) => {
                    if (item.id === message.id) {
                        return {
                            ...item,
                            favouritedBy: null
                        }
                    }
                    return item
                })
            )
        }
    }

    return (
        <div ref={getRef()} className={message.senderId === user.id ? "chat_message_right" : "chat_message_left"}>
            <ContextMenu handleClose={() => { setAnchorEl(null) }} anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={MI.filter(item => item.display)} />
            {expandImage && expandedImage()}
            <div className='avatarAndTime'>
                <div className='avatar'>
                    <img style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }} src={senderImage} />
                </div>

                <div className='time'>
                    {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </div>
                {message?.notSent ? <FaRegClock style={{
                    marginRight: "auto"
                }} /> : <FaCheck style={{
                    marginRight: "auto"
                }} color='#2761D0' />}
            </div>

            <div className='chat_message' style={{
                width: message?.voiceNote?.url ? "100%" : "auto"
            }}>
                {
                    message?.notSent && (message?.files?.length > 0 || message?.voiceNote?.url) &&
                    <>
                        <div className='progress' style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            zIndex: "100",
                            backgroundColor:"#2761D0",
                            // backgroundColor: "rgba(0,0,0,0.5)",
                            padding: "0.5rem",
                            borderRadius: "10px",
                        }}>

                            <MdUpload size={30} color='white' />
                            <p style={{
                                color:"white"
                            }} id={message.id}></p>
                        </div>
                    </>
                }
                <div className='name' style={{ color: '#' + Math.floor(Math.random() * 16777215).toString(16), fontSize: "0.8rem", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", marginBottom: "1rem", gap: "1rem" }}>
                    {/* <FaHeart onClick={()=>{
                        markAsFavourite()
                    }} color={message?.favouritedBy?.filter((item)=>item.userId===user?.id).length>0?'red':'grey'} size={20} /> */}

                    {senderName && senderName}
                    <IoIosSettings onClick={(e) => { setAnchorEl(e.target) }} color='black' size={20} style={{
                        cursor: "pointer"
                    }} />
                </div>
                {(message?.files?.length > 0) && getIcon(message?.files[0])}

                {message?.voiceNote &&
                    <AudioPlayer
                        src={message?.voiceNote?.url}
                        maxTime={parseInt(message?.voiceNote?.caption)}
                    />
                }
                {!editable ? <p>{message.content}</p> : <div className='edit_msg_input'><input value={msg} onChange={(e) => { setMsg(e.target.value) }} /><IoMdSend color='2761D0' style={{
                    cursor: "pointer"
                }} onClick={() => { editMesage() }} /></div>}
            </div>
            {
                message?.files?.length > 0 && <div onClick={() => { downloadFile(message?.files[0]) }} className='download_icon'> <MdDownload color='white' size={20} /></div>
            }
        </div>
    )
}
)
export default Message