import { FaPlus } from 'react-icons/fa6'
import { useTabs } from './TabContext'
import { IoMdClose } from 'react-icons/io'


export function Tabs() {
  const { tabs, activeTabId, setActiveTab, closeTab, openNewTab } = useTabs()

  return (
    <div className="bg-[#2761d0] text-white overflow-x-auto whitespace-nowrap">
      <div className="flex">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`px-6 py-3 relative text-white hover:bg-blue-300 ${activeTabId === tab.id ? 'bg-blue-300' : ''
              }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.openFile ? tab.openFile.name : 'New Tab'}
            {
              tabs.length > 1 && <button
                className="absolute top-1 right-1 p-1 hover:bg-blue-700 rounded-full"
                onClick={(e) => {
                  e.stopPropagation()
                  closeTab(tab.id)
                }}
              >
                <IoMdClose className="h-3 w-3" />
              </button>
            }
          </button>
        ))}
        <button
          className="px-4 text-white hover:bg-blue-300"
          onClick={openNewTab}
        >
          <FaPlus className="h-4 w-4" />
        </button>
      </div>
    </div>
  )
}
