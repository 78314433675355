
import React, { useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import './index.scss'
import SearchCheckAvailabilityReq from '../../../Requests/CheckAvailability/SearchCheckAvailabilityReq'
import CustomerApi from '../../../API/CustomerApi'
import Datatable from '../../../components/Datatable'
import CircularProgress from '@mui/material/CircularProgress'
import { PaginationItem } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi'
import Swal from 'sweetalert2'
import StringIntReq from '../../../Requests/StringIntReq'
import Modal from './../../../components/Modal'
import EditCheckAvailability from './components/EditCheckAvailability'
import { useNavigate } from 'react-router-dom'
import SearchFiberHousesReq from '../../../Requests/FiberHouse/SearchFiberHousesReq'
import FiberHouseApi from '../../../API/FiberHouseApi'
const Index = () => {
  const [availabilityChecks, setAvailabilityChecks] = useState([])
  const [loading, setLoading] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [notes, setNotes] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [open, setOpen] = useState(false)
  const [selectedCheck, setSelectedCheck] = useState({})

  const navigate = useNavigate()

  const getAvailabilityChecks = async () => {
    setLoading(true)
    const req = new SearchCheckAvailabilityReq()
    req.firstName = firstName
    req.lastName = lastName
    req.email = email
    req.phone = phone
    req.address = address
    req.notes = notes
    console.log(req)
    req.pagingParams = {
      pageNumber: currentPage,
      pageSize: 10
    }
    const res = await CustomerApi.SearchAvailabilityCheck(req);
    if (res?.status?.success) {
      console.log(res.data)
      setTotalPages(res.data.totalPages)

      const list = [];
          for (let index = 0; index < res?.data?.list.length; index++) {
            list.push(res?.data?.list[index]);
            list[index].details = <button style={{
              color: "white",
              backgroundColor: "rgb(65, 182, 230)",
              padding:"0.5rem 1rem",
              borderRadius:"5px",
            }} onClick={() => {  navigate(`/manage-check-availability/details/${res?.data?.list[index].id}`) }} >
              Details
            </button>;
            list[index].delete = <button sx={{
              '&:hover': {
                backgroundColor: 'red',
              },
              backgroundColor: "red",
              color: "white !important",

            }} onClick={() => {deleteCheckAvailability(res?.data?.list[index].id)}} color="error"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                <path d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V5H1V16Z" fill="#FF472E" />
              </svg></button>

            list[index].timeStamp = new Date(res?.data?.list[index].timeStamp).toLocaleString(
              'en-US', { 
                year: 'numeric', 
                month: 'numeric', 
                day: 'numeric', 
                hour: 'numeric', 
                minute: 'numeric', 
                hour12: true 
              }
            )

          }

      setAvailabilityChecks(res?.data?.list)
      setLoading(false)

    }
    else {
      console.log(res?.status?.message)
    }
  }

  useEffect(() => {
    getAvailabilityChecks()
  }, [currentPage])


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        getAvailabilityChecks();
    }
};

  async function deleteCheckAvailability(id) {
    const selected = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (selected.isConfirmed) {
      Swal.fire({
        icon:"info",
        title: "Deleting...",
        text  : "Please wait",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      const req = new StringIntReq()
      req.int = id
      const res = await CustomerApi.DeleteAvailabilityCheck(req);
      if(res?.status?.success){
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Check Availability deleted successfully",
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          }
        });
        getAvailabilityChecks();
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message,
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          }
        });
      
      }
    }
  }

  const headers = [
    {
      name:"Time Stamp",
      key:"timeStamp"
    },
    {
      key: "id",
      name: "ID"
    },
    {
      key: "firstName",
      name: "First Name"
    },
    {
      key: "lastName",
      name: "Last Name"
    },
    {
      key: "email",
      name: "Email"
    },
    {
      key: "phone",
      name: "Phone"
    },
    {
      key: "address",
      name: "Address"
    },
    {
      key: "notes",
      name: "Notes"
    },
    {
      key: "seen",
      name: "Seen"
    },
    {
      key: "contacted",
      name: "Contacted"
    },
    {
      key: "availabilityType",
      name: "Availability Type"
    },
    {
      key: "consentChecked",
      name: "Consent Checked"
    },
    {
      key: "isAvailable",
      name: "Is Available"
    },
    {
      key: "details",
      name: "Details"
    },
    {
      key: "delete",
      name: "Delete"
    }
  ]


  const test = async () => {
    let req = new SearchFiberHousesReq()
    // req.mapString = ""
    // req.salesOrgId = null
    // req.salesRepIds = [78, 13, 14, 535, 4664, 575]
    // req.mapIconIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    req.pagingParams = {
      pageNumber: 1,
      pageSize: 10
    }

    let res = await FiberHouseApi.SearchFiberHousesOptimized(req)

    console.log(res)
  }


  return (
    <Layout>
      <div className='manageLeadsContainer'>
        <Modal open={open} setOpen={setOpen}>
            <EditCheckAvailability setOpen={setOpen} reload={getAvailabilityChecks} checkAvailability={selectedCheck} />
        </Modal>
        <div className='manageLeadsHeader'>
          {/* <button onClick={() => {
            test()
          }}>test</button> */}
          <h1 className='manageLeadsTitle'>Manage Availability Checks</h1>
          <div className='searchFiltersInput'>
            <input type='text' placeholder='First Name' onKeyDown={handleKeyPress} value={firstName} onChange={e => setFirstName(e.target.value)} />
            <input type='text' placeholder='Last Name' onKeyDown={handleKeyPress} value={lastName} onChange={e => setLastName(e.target.value)} />
            <input type='text' placeholder='Email' onKeyDown={handleKeyPress} value={email} onChange={e => setEmail(e.target.value)} />
            <input type='text' placeholder='Phone' onKeyDown={handleKeyPress} value={phone} onChange={e => setPhone(e.target.value)} />
            <input type='text' placeholder='Address' onKeyDown={handleKeyPress} value={address} onChange={e => setAddress(e.target.value)} />
            <input type='text' placeholder='Notes' onKeyDown={handleKeyPress} value={notes} onChange={e => setNotes(e.target.value)} />
          </div>
          <button onClick={()=>{
            setCurrentPage(1)
            getAvailabilityChecks()
          }}>Search</button>
        </div>

        {
          loading ?
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop:"4rem"
            }} >
              <CircularProgress sx={{ color: "var(--color-icon)" }} size={50} />
            </div>
            :
            <Datatable headers={headers} list={availabilityChecks} />
        }
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem " }}>
          <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} onChange={(e, pageNo) => {
            setCurrentPage(pageNo)
          }} />
        </div>

      </div>
    </Layout>
  )
}

export default Index
