import React from 'react'
// import reviewBackground from '../images/reviewBackground.png'
import './Review.scss'
// import blue1 from '../images/blue1.png'
// import greyUser from '../../../../assets/images/userGrey.png'

const Review = ({index,user}) => {
 
    const getImage = () =>{
        if(user.profileImage){
            return <img  src={user?.profileImage} alt='user' className='profile' />
        }
        else {
            return <h4   style={{
                backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
                  Math.random() * 256
                )}, ${Math.floor(Math.random() * 256,0.4)})`,
              }} className='profile_h4'>{user?.firstName[0]}</h4>
        }
        
    }
 
    return (
        <div className='review'>
         {/* <img className='profile' src={user.profileImage?user.profileImage:greyUser} /> */}
            {getImage()}
            <div id={`bbg_${index%3}`}  className='blue_background'>
            {/* {index%3===1 &&    <img src={blue1} alt='blue1' className='blue1' />} */}
            </div>
            <div className='testimonial_card'>
                <div id={`test_${index%3}`} className='home_testmonail'>
             
                    <h1>{user.firstName+" "+user.lastName}</h1>
                    <p>{user.type}</p>
                </div>

                <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <filter id="flt_tag">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="18" result="blur" />
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                            <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default Review