import React, { useContext, useState } from 'react'
import { AttachFile, InsertEmoticon } from '@mui/icons-material';
import EmojiPicker from 'emoji-picker-react'
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import MessagingApi from '../../../../API/MessagingApi';
import CreateFile from '../../../../Requests/CloudFile/CreateFileReq';
import SendPrivateMessageReq from '../../../../Requests/Messaging/SendPrivateMessageReq';
import { UserContext } from '../../../../App';
import Audio from './../../../../components/AudioRecorder'
import { FaMicrophone } from "react-icons/fa";
const ChatSender = ({ messages, setMessages, conversation, editMessageId, setEditMessageId, getConversationLinks, getCloudFiles }) => {

    const user = useContext(UserContext);
    const [msg, setMsg] = useState("");
    const [emojiClicked, setEmojiClicked] = useState(false)
    const storage = getStorage();
    const [audioMode, setAudioMode] = useState(false)
    const [audioLength, setAudioLength] = useState(0)
    async function send(e) {


        if (msg.trim() === "") return;
        const req = new SendPrivateMessageReq();
        const id = uuidv4();
        if (messages.length > 0)
            setMessages(prev => [{
                id: id,
                content: msg,
                senderId: user.id,
                createdAt: new Date().toISOString(),
                notSent: true
            }, ...prev])
        else
            setMessages([{
                id: id,
                content: msg,
                senderId: user.id,
                createdAt: new Date().toISOString(),
                notSent: true
            }])
        req.content = msg;
        setMsg("")
        req.conversationId = conversation.id;
        req.senderId = user.id
        req.files = []
        console.log(conversation.type)
        console.log(req)

        MessagingApi.SendMessage(req).then(
            (res) => {
                console.log(res)
                if (res?.status?.success) {
                    console.log(res.data)
                    setMessages(prev => {
                        let temp = prev.filter((item) => item.id !== id)

                        return [res.data, ...temp]
                    })
                    getCloudFiles()
                    getConversationLinks()
                }
                else {
                    console.log(res?.status?.message)
                }
            }

        )
    }
    const discardAudio = () => {
        setAudioMode(false)
    }
    const addAudioElement = async (blob, duration) => {
        const id = uuidv4();
        console.log(blob);
        setAudioMode(false)
        if (blob.size === 0) return;
        const file = new File([blob], Date.now() + ".mp3", { type: blob.type });
        console.log("FILE", file);
        const req = new SendPrivateMessageReq();
        if (messages.length > 0)
            setMessages(prev => [{
                id: id,
                content: msg,
                senderId: user.id,
                createdAt: new Date().toISOString(),
                notSent: true,
                voiceNote: {
                    url: URL.createObjectURL(file),
                    name: file.name,
                    extension: file.name.split('.').pop(),
                    fileSize: file.size.toString(),
                    userId: user.id,
                    sellingCardId: 1,
                    caption: duration.toString(),
                    fileType: file.type
                }

            }, ...prev])
        else
            setMessages([{
                id: id,
                content: msg,
                senderId: user.id,
                createdAt: new Date().toISOString(),
                notSent: true,
                voiceNote: {
                    url: URL.createObjectURL(file),
                    name: file.name,
                    extension: file.name.split('.').pop(),
                    fileSize: file.size.toString(),
                    userId: user.id,
                    sellingCardId: 1,
                    caption: duration.toString(),
                    fileType: file.type
                }
            }])
        req.content = msg;
        setMsg("")
        req.conversationId = conversation.id;
        req.senderId = user.id
        let downloadUrls = [];
        let filePaths = [];
        let index = 0;
        // Function to upload file and get download URL
        const uploadAndGetData = async (file, type) => {
            // Generate a unique ID 
            const uniqueId = uuidv4();

            const storageRef = ref(storage, `/Conversations/${conversation.name}/${user.firebaseId}/${uniqueId}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            // Get download URL and push to downloadURLs array
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    const progressElement = document.getElementById(id);
                    progressElement.innerHTML = progress + "%";
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    console.log(error)
                },
                async () => {

                    const downloadURL = await getDownloadURL(storageRef);

                    downloadUrls.push(downloadURL);
                    console.log(downloadURL);
                    filePaths.push(`/Conversations/${conversation.name}/${user.firebaseId}/${uniqueId}/${file.name}`)

                    const createFile = new CreateFile();
                    createFile.name = file.name;
                    createFile.extension = createFile.name.split('.').pop();
                    createFile.url = downloadUrls[0];
                    createFile.path = filePaths[0];
                    createFile.fileSize = file.size.toString();
                    createFile.userId = user.id;
                    createFile.sellingCardId = 1;
                    console.log(duration)
                    createFile.caption = duration.toString();
                    createFile.fileType = file.type
                    req.voiceNote = createFile;
                    MessagingApi.SendMessage(req).then(
                        (res) => {
                            console.log(res)
                            if (res?.status?.success) {
                                console.log(res.data)
                                setMessages(prev => {
                                    let temp = prev.filter((item) => item.id !== id)

                                    return [res.data, ...temp]
                                })
                                getCloudFiles()
                                getConversationLinks()
                            }
                            else {
                                console.log(res?.status?.message)
                            }
                        }

                    )
                });

        };
        await uploadAndGetData(file, "audio");

    };

    const sendFile = async (e) => {
        const id = uuidv4();
        const req = new SendPrivateMessageReq();
        if (messages.length > 0)
            setMessages(prev => [{
                id: id,

                content: msg,
                senderId: user.id,
                createdAt: new Date().toISOString(),
                notSent: true,
                files: [{
                    url: URL.createObjectURL(e.target.files[0]),
                    name: e.target.files[0].name,
                    extension: e.target.files[0].name.split('.').pop(),
                    fileSize: e.target.files[0].size.toString(),
                    userId: user.id,
                    sellingCardId: 1,
                    caption: "123",
                    fileType: e.target.files[0].type
                }]

            }, ...prev])
        else
            setMessages([{
                id: id,
                content: msg,
                senderId: user.id,
                createdAt: new Date().toISOString(),
                notSent: true,
                files: [{
                    url: URL.createObjectURL(e.target.files[0]),
                    name: e.target.files[0].name,
                    extension: e.target.files[0].name.split('.').pop(),
                    fileSize: e.target.files[0].size.toString(),
                    userId: user.id,
                    sellingCardId: 1,
                    caption: "123",
                    fileType: e.target.files[0].type
                }]
            }])
        req.content = msg;
        setMsg("")
        req.conversationId = conversation.id;
        req.senderId = user.id
        if (e.target.files[0]) {

            let downloadUrls = [];
            let filePaths = [];
            let index = 0;
            // Function to upload file and get download URL
            const uploadAndGetData = async (file, type) => {
                // Generate a unique ID 
                const uniqueId = uuidv4();

                const storageRef = ref(storage, `/Conversations/${conversation.name}/${user.firebaseId}/${uniqueId}/${e.target.files[0].name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        const progressElement = document.getElementById(id);
                        progressElement.innerHTML = progress.toFixed(0) + "%";
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        console.log(error)
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(storageRef);
                        downloadUrls.push(downloadURL);
                        console.log(downloadURL);
                        filePaths.push(`/Conversations/${conversation.name}/${user.firebaseId}/${uniqueId}/${e.target.files[0].name}`)
                        const createFile = new CreateFile();
                        createFile.name = e.target.files[0].name;
                        createFile.extension = createFile.name.split('.').pop();
                        createFile.url = downloadUrls[0];
                        createFile.path = filePaths[0];
                        createFile.fileSize = e.target.files[0].size.toString();
                        createFile.userId = user.id;
                        createFile.sellingCardId = null;
                        createFile.shopItemId = null;
                        createFile.caption = "123";
                        createFile.fileType = e.target.files[0].type
                        req.files.push(createFile);
                        MessagingApi.SendMessage(req).then(
                            (res) => {
                                console.log(res)
                                if (res?.status?.success) {
                                    console.log(res.data)
                                    setMessages(prev => {
                                        let temp = prev.filter((item) => item.id !== id)

                                        return [res.data, ...temp]
                                    })
                                    getCloudFiles()
                                    getConversationLinks()
                                }
                                else {
                                    console.log(res?.status?.message)
                                }
                            }

                        )
                    });
                // Get download URL and push to downloadURLs array

            };
            await uploadAndGetData(e.target.files[0], "image");


        }
        console.log(conversation.type)
        console.log(req)


    }

    // useEffect(
    //     () => {
    //         if(editMessageId){
    //             setMsg(messages.filter((item)=>item.id===editMessageId)[0].content)
    //         }
    //     },[editMessageId]
    // )

    return (
        <>
            <div className='flex items-center justify-between relative px-4'>
                {emojiClicked && <div style={{
                    position: 'absolute',
                    bottom: "0",
                    right: "0",
                    zIndex: "100"
                }}>
                    <button onClick={() => setEmojiClicked(false)} style={{ width: "100%", backgroundColor: "#181E4B", fontWeight: "700", color: "white", padding: "0.5rem 1rem", borderRadius: "10px" }}>Close</button>


                    <EmojiPicker onEmojiClick={(emojiObject) => { setMsg(msg + emojiObject.emoji); }} />


                </div>}


                <div className='flex justify-center items-center border-2 border-[#2761D0] rounded-lg mx-auto w-full'>

                    {/* <AudioRecorder
                        onRecordingComplete={addAudioElement}
                        audioTrackConstraints={{
                            noiseSuppression: true,
                            echoCancellation: true,
                            
                        }}
                        borderColor='transparent'
                        downloadOnSavePress={false}
                        downloadFileExtension="webm"
                        recorderControls={recorderControls}
                        // showVisualizer={true}
                        
                    /> */}
                    {!audioMode ?
                        <>
                            <FaMicrophone size={20} onClick={() => { setAudioMode(true) }} style={{ cursor: "pointer", margin: "0rem 0.3rem" }} />
                            <input className='h-full w-full outline-none' value={msg} onChange={(e) => { setMsg(e.target.value) }} onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    send();
                                }
                            }} />
                            <input type="file" id="fileUpload" style={{ display: "none" }} onChange={(e) => { sendFile(e) }} placeholder="" />
                            <label htmlFor='fileUpload' style={{ cursor: "pointer", margin: "0rem 0.3rem" }} >
                                <AttachFile fontSize='medium' />
                            </label>
                            <InsertEmoticon style={{ cursor: "pointer", margin: "0rem 0.3rem" }} onClick={() => { setEmojiClicked(!emojiClicked) }} fontSize='medium' />
                            <button className='sendButton' onClick={() => { send() }}>Send</button>
                        </>
                        : <Audio setAudioLength={setAudioLength} onDiscard={discardAudio} onSend={addAudioElement} />
                    }
                </div>

            </div>
        </>
    )
}

export default ChatSender