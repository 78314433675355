import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";
class OfferApi{
    baseUrl = EnvSettings.url;
    CreateOrEditOfferUrl = "CreateOrEditOffer";
    GetOfferByGuidUrl = "GetOfferByGuid";
    AcceptOfferUrl = "AcceptOffer";
    AcceptSharedOfferUrl = "AcceptSharedOffer";
    LightAcceptOfferUrl = "LightAcceptOffer";
    CreateOfferTemplateUrl = "CreateOfferTemplate";
    SearchOfferTemplateUrl = "SearchOfferTemplates";
    EditOffertemplateUrl = "EditOfferTemplate";
    CreateOfferTemplateFiberPlanUrl = "CreateOfferTemplateFiberPlan";
    DeleteOfferTemplateFiberPlanUrl = "DeleteOfferTemplateFiberPlan";
    CreateSharedOfferUrl = "CreateSharedOffer";

    async CreateSharedOffer(req)
    {
        const res = await fetch(this.baseUrl + this.CreateSharedOfferUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async DeleteOfferTemplateFiberPlan(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteOfferTemplateFiberPlanUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async CreateOfferTemplateFiberPlan(req)
    {
        const res = await fetch(this.baseUrl + this.CreateOfferTemplateFiberPlanUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async EditOfferTemplate(req)
    {
        const res = await fetch(this.baseUrl + this.EditOffertemplateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async SearchOfferTemplate(req)
    {
        const res = await fetch(this.baseUrl + this.SearchOfferTemplateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async CreateOfferTemplate(req)
    {
        const res = await fetch(this.baseUrl + this.CreateOfferTemplateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })

        const data = await res.json();
        return data;
    }

    async AcceptSharedOffer(req)
    {
        const res = await fetch(this.baseUrl + this.AcceptSharedOfferUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    // async CreateEditOffer(req)
    // {
    //     const formData = new FormData();
    //     for (const [key, value] of Object.entries(req)) {
    //         formData.append(key, value);
    //     }
    //     const res = await fetch(this.baseUrl + this.CreateOrEditOfferUrl, {
    //         method: 'POST',
    //         headers: {
    //             Authorization: `Bearer ${UsersApi.GetToken()}`,
    //         },
    //         body: formData
    //     })
    //     const data = await res.json();
    //     return data;
    // }


    async CreateEditOffer(req)
    {
        const res = await fetch(this.baseUrl + this.CreateOrEditOfferUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetOfferByGuid(req)
    {
        const res = await fetch(this.baseUrl + this.GetOfferByGuidUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AcceptOffer(req)
    {
       
        const res = await fetch(this.baseUrl + this.AcceptOfferUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                 'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async LightAcceptOffer(req)
    {
        const res = await fetch(this.baseUrl + this.LightAcceptOfferUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
 
}


export default new OfferApi;