import React from 'react'
import before from '../images/before.png'
import after from '../images/after.png'
const SpeedTestReview = ({review}) => {
    return (
        <div className='before_after_images'>
            <div className='con'>
                <div className='before'>
                <img src={"data:image/png;base64,"+review?.speedTest?.beforeImage} />
                </div>
                <p>Before</p>
            </div>
            <div className='con'>
                <div className='after'>
                <img src={"data:image/png;base64,"+review?.speedTest?.afterImage} />
                </div>
                <p>After</p>
            </div>
        </div>
    )
}

export default SpeedTestReview