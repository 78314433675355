class SearchCheckAvailabilityReq {
    contructor(){
        this.id = null;
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phone = null;
        this.address = null;
        this.notes = null;
        this.isAvailable = null;
        this.seen = null;
        this.contacted = null;
        this.availabilityType = null;
        this.consentChecked = null;
        this.pagingParams = {
            pageNumber: null,
            pageSize: null
        }
    }
}

export default SearchCheckAvailabilityReq;