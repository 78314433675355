import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from "sweetalert2"
// import Sidebar from '../../../Layouts/Sidebar'
import OrgApi from "./../../../API/OrgApi"
import CreateOrgReq from "./../../../Requests/Organizations/CreateOrgReq"
import OrganizationSelector from "./../../../components/OrganizationSelector"
import VersatileLoader from '../../../components/VersatileLoader'
import OrgCard from './OrgCard'
import "./index.scss"
// import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
const Index = () => {
    const [name, setName] = useState("");
    const [parentOrgId, setParentOrgId] = useState(null);
    const [orgs, setOrgs] = useState(null);
    const [loading, setLoading] = useState(false)
    function getOrgTree() {
        setLoading(true)
        OrgApi.GetOrgTree().then(
            (res) => {
                if (res?.status?.success) {
                    setOrgs(res.data)
                    console.log(res.data)
                    setLoading(false)
                }
                else {
                    
                }
            }
        )
    }

    useEffect(
        () => {
            getOrgTree()
        }, []
    )

    const createOrg = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Creating Organization',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (!name) {
            Swal.fire({
                icon: 'error',
                title: 'Name is required',
                text: 'Please enter Name',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        let req = new CreateOrgReq();

        req.name = name;
        if (parentOrgId) {
            req.parentOrgId = parentOrgId
        }
        let res = await OrgApi.CreateOrg(req)
        
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Organization Created',
                text: "Organization has been created",
                // allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,

            });
            OrgApi.GetOrgTree().then(
                (res) => {
                    if (res?.status?.success) {
                        setOrgs(res.data)
                        
                    }
                    else {
                        
                    }
                }
            )
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }
    }
    return (
        <Layout>
            <div className='manageOrganizations' >
                <div className='filters' >
                    <h1 >Create an Organization</h1>
                    <div className='inputs' >
                        <input onChange={(e) => { setName(e.target.value) }} value={name} type="text" placeholder='Enter the Name' />
                        <OrganizationSelector placeholder='SELECT PARENT ORG' setUser={setParentOrgId} />
                        <button className={"w-full sm:w-fit"} onClick={() => { createOrg() }}>Create</button>
                    </div>
                </div>
                {loading ? <div className='spinner'>
                        <VersatileLoader size='large' color='var(--color-primary)' />
                </div>
                    :
                    <div className='orgGraphs'> 
                        {orgs && orgs.map(
                            (org, index) => {
                                return (
                                    <OrgCard getOrgTree={getOrgTree} key={index} org={org} />
                                )
                            }
                        )}
                    </div>}
            </div>
        </Layout>
    )
}

export default Index;

