import React, { useEffect } from 'react'
import Navbar from './components/Navbar'
import "./PublicLayout.scss"
import Footer from './components/Footer'
import { useLocation } from 'react-router-dom'
const Index = ({ children }) => {

  const [lightTheme, setLightTheme] = React.useState(document.body.classList.contains('light-theme') ? true : false);
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.getElementById('nav');
    element.scrollIntoView({ behavior: 'instant' });
  }, [pathname]);
  
  useEffect(() => {
    if (lightTheme && !document.body.classList.contains('light-theme')) {
      if (document.body.classList.contains('dark-theme')) {
        document.body.classList.remove('dark-theme');
      }
      document.body.classList.add('light-theme');
    }
    else if (!lightTheme && !document.body.classList.contains('dark-theme')) {
      if (document.body.classList.contains('light-theme')) {
        document.body.classList.remove('light-theme');
      }
      document.body.classList.add('dark-theme');
    }
  }, [lightTheme])
  // console.log("Render")
  return (

      <div className={`publicLayoutContainer`}>
        <Navbar lightTheme={lightTheme} setLightTheme={setLightTheme} />
        {children}

        <Footer lightTheme={lightTheme} />
        {/* <div className='floating_icon'> */}
        {/* <PiChatsCircleBold color='white' size={30} /> */}
        <chat-widget
          location-id="2bpuwFoxpdxZs2Oq2Z8a"
          agency-name="Lightning OS, LLC"
          chat-type="liveChat"
          live-chat-user-inactive-msg=" Looks like it’s taking too long. Please leave your contact details. We will get back to you shortly"
          live-chat-feedback-note="Thank you, for taking your time."
          show-consent-checkbox="true" >
        </chat-widget>

        {/* </div> */}
      </div>


  )
}

export default Index