import React from 'react'
import SoftButton from '../../components/SoftButton'
import { getAuth } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
const NoSalesOrgId = () => {

    const navigate = useNavigate();
  return (
    <div 
        style={{
            display:"flex",
            width:"100vw",
            height:"100vh",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column",
            gap:"1rem"
        }}
    >
        <h1>No Sales Organization has been assigned to You</h1>
        <SoftButton onClick = {() => {
        getAuth().signOut();
        navigate("/");
      }} style={{ backgroundImage: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))", color: "white", marginTop: "1rem" }}>Logout</SoftButton>
    </div>
  )
}

export default NoSalesOrgId