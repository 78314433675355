class CreateFiberHouseReq {
  constructor() {
    this.country = null;
    this.unit = null;
    this.state = null;
    this.zipCode = null;
    this.city = null;
    this.address = null;
    this.mapString = null;
    this.notes = null;
    this.status = null;
    this.mapIconId = null;
    this.servicableAreaId = null;
    this.coordinate = {
      latitude: null,
      longitude: null,
      popUpColor: null
    };
    this.salesOrgId = null;
    this.lead = {
      name: null,
      email: null,
      phone: null,
      status: null,
      notes: null,
      assignedConvertedToCustomer: null,
      customerInterest: null,
      doorKnockingStatus: null,
      fiberHouseId: null,
      salesOrgId: null
    }
  }
}

export default CreateFiberHouseReq;