class DeleteOrgReq {
    constructor() {
        this.string = null;
        this.int = null;
        this.pagingParams = {
            "pageNumber":1,
            "pageSize":1000
        }
    }
}
export default DeleteOrgReq;