import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import CancelationFormReq from '../../../Requests/Customer/CancelFormReq'
import CustomerApi from '../../../API/CustomerApi'

const CancelForm = ({ lead }) => {

    const [reason, setReason] = React.useState('')
    const [otherReason, setOtherReason] = React.useState('')
    const reasons = [
        {
            title: 'Paying Less w/ Another Internet Provider (ISP)',
            subQuestion: 'It’s possible we can beat their price! Who is your ISP? How many mbps is your plan? And finally, what is your monthly cost?'
        },
        {
            title: 'Bundled Service w/ Another ISP',
            subQuestion: 'Lightcurve also has bundled options, and can most likely beat their price! Who is your ISP? How many mbps is your plan? And finally, what is your monthly cost?'
        },
        {
            title: 'We Are in Contract w/ Another ISP',
            subQuestion: 'We often assist with early termination fees! What ISP are you in contract with? How many months are remaining on your contract? Do you know the early termination fee? If yes, how much to end your contract early?'
        },
        {
            title: 'Spouse or Another Descision Maker Doesn`t Want to Switch Providers',
        },
        {
            title: 'Moving Soon',
        },
        {
            title: 'Too Busy, Not Worth The Time',
        },
        {
            title: 'Other Reason',
            subQuestion: 'Please specify the reason(s):'
        }

    ]

    const handleCheckboxChange = (item) => {
        if (reason === item) {
            setReason(''); // Uncheck if the same item is clicked
        } else {
            setReason(item); // Check the new item
        }
    }


    const showEnterAnswerSwal = () => {
        Swal.fire({
            icon: "error",
            title: "Please Enter an Answer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.hideLoading();
            }
        });
    }


    const handleSubmit = async () => {


        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Submitting Cancelation Form",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        let req = new CancelationFormReq();
        req.phoneNumber = lead.phone


        switch (reason) {
            case 'Paying Less w/ Another Internet Provider (ISP)':
                req.payLess = true;
                if (otherReason === "") {
                    showEnterAnswerSwal();
                    return;
                }
                req.payLessSubAnswer = otherReason;
                break;
            case "Bundled Service w/ Another ISP":
                req.bundledService = true;
                if (otherReason === "") {
                    showEnterAnswerSwal();
                    return;
                }
                req.bundledServiceSubAnswer = otherReason;
                break;
            case 'We Are in Contract w/ Another ISP':
                req.inContract = true;
                if (otherReason === "") {
                    showEnterAnswerSwal();
                    return;
                }
                req.inContractSubAnswer = otherReason;
                break;
            case 'Other Reason':
                req.other = true;
                if (otherReason === "") {
                    Swal.fire({
                        icon: "error",
                        title: "Please Enter a Reason",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        customClass: {
                            container: 'custom-swal'
                        },
                        didOpen: () => {
                            Swal.hideLoading();
                        }
                    });
                    return;
                }
                req.otherSubAnswer = otherReason;
                break;
            case 'Too Busy, Not Worth The Time':
                req.tooBusy = true;
                break;
            case 'Moving Soon':
                req.movingSoon = true;
                break;
            case 'Spouse or Another Descision Maker Doesn`t Want to Switch Providers':
                req.decisionMaker = true;
                break;
            default:
                Swal.fire({
                    icon: "error",
                    title: "Please Select a Reason",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    customClass: {
                        container: 'custom-swal'
                    },
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                return;
        }

        let res = await CustomerApi.CancelForm(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Form Submitted",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            ;
        } else {
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            })
        }
    };

    useEffect(() => {
        setOtherReason('')
    }, [reason])

    return (
        <div className='form'>
            <h2>Cancel Installation Form</h2>
            {
                reasons.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className='reason_div'>
                            <input
                                type='checkbox'
                                value={item}
                                checked={reason === item.title}
                                onChange={() => handleCheckboxChange(item.title)}
                            />
                            <label>{item.title}</label>
                        </div>
                        {
                            reason === item.title && item.subQuestion &&
                            <div className='sub_question'>
                                <p>{item.subQuestion}</p>
                                <textarea placeholder='' value={otherReason} onChange={(e) => setOtherReason(e.target.value)} rows={3} />
                                <button onClick={() => {
                                    setOtherReason('I prefer not to say.')
                                }}>I prefer not to say.</button>
                            </div>
                        }
                    </React.Fragment>
                ))
            }

            <button onClick={() => {
                handleSubmit()
            }}>Submit</button>
        </div>
    )
}

export default CancelForm