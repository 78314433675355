/* eslint-disable array-callback-return */
import React from 'react'
import anonUser from './../../../../assets/images/userGrey.png'
import Swal from 'sweetalert2'
import AddRemoveUserFromConversation from '../../../../Requests/Messaging/AddRemoveUserFromConversationReq'
import MessagingApi from '../../../../API/MessagingApi'
import { Close } from '@mui/icons-material'
import './index.scss'
const RemoveUsersFromConversation = ({conversation,open,setOpen,removedUsers, setRemovedUsers,mode}) => {
    


    const removeUser =async (userId) =>{
        Swal.fire({
            icon:"info",
            title:"Removing User",
            text:"Please wait...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        const req = new AddRemoveUserFromConversation();
        req.id1 = userId;
        req.id2 = conversation.id;
        const res = await MessagingApi.RemoveUserFromConversation(req);
        if(res?.status?.success){
            Swal.fire({
                icon: 'success',
                title: 'User Removed',
                text: 'User removed successfully',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            })
            setRemovedUsers([...removedUsers,userId])
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading();
                }
            })
        }
    }

    return (
    <div className='view_members' style={{
        position: "absolute",

        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        backgroundColor: "white",
        zIndex: 1000,
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
        borderRadius: "10px",
        padding: "20px",
        height:"fit-content"
    }}>
        <Close sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer"
        }} onClick={()=>setOpen(false)} />
        <h1 style={{
                    textAlign: "center",
                    color: "black",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    marginBottom: "20px",
                    textTransform: "uppercase"
                }}>{mode==="Remove"?"Remove  USERs From ":""}{conversation.name}</h1>
                <div>
                    {
                        conversation.conversationUsers.map((item,index)=>{
                            if( !removedUsers.includes(item.user.id))
                            return <div key={index} style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <img src={item.user.profileImage?.url?item.user.profileImage?.url:anonUser} alt="avatar" style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        marginRight: "10px"
                                    }} />
                                    <div>
                                        <div style={{
                                            fontSize: "1.2rem",
                                            fontWeight: "bold"
                                        }}>{item.user.firstName + " " + item.user.lastName}</div>
                                        <div style={{
                                            fontSize: "1rem",
                                            color: "grey"
                                        }}>{item.user.email}</div>
                                    </div>
                                </div>
                                <div>
                                    {item?.user?.id === conversation.creatorId && <h2>ADMIN</h2>}
                                  {item?.user?.id === conversation.creatorId || mode==="View"?  

                                    ""
                                
                                  :<button onClick={()=>removeUser(item.user.id)} style={{
                                        padding: "10px",
                                        backgroundColor: "red",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                    }}>Remove</button>}
                                </div>
                            </div>
                        })
                    }
                </div>
    </div>
  )
}

export default RemoveUsersFromConversation