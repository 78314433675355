class SearchShopItem{
    constructor()
    {
        
        this.id = null;
        this.name = null;
        this.description = null;
        this.price = null;
        this.pagingParams = {
          pageNumber: 0,
          pageSize: 0
        };      
    }
};
export default SearchShopItem;